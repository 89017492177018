import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { getFileTypeFromMimeType } from './file';
import { FileOpener, type FileOpenerOptions } from '@capacitor-community/file-opener';
import { isNativeAndroid } from '@/utils/crossPlatform';
import * as Sentry from '@sentry/react';

const DEFAULT_EXTENSION = 'txt';

export const downloadFile = async (
  data: Blob | string,
  fileName: string,
  fileType: string,
  directory: Directory = Directory.Documents
): Promise<void> => {
  try {
    let dataString = typeof data === 'string' ? data : await data.text();

    const timestamp = Date.now().toString();
    const filenameParts = fileName.split('.');
    const extension =
      filenameParts.length > 1
        ? filenameParts.pop()!
        : getFileTypeFromMimeType(fileType)?.toLowerCase() || DEFAULT_EXTENSION;
    const baseFileName = filenameParts.join('.');
    const filePath = `${baseFileName}_${timestamp}.${extension}`;

    const result = await Filesystem.writeFile({
      path: filePath,
      data: dataString,
      directory,
      recursive: true,
      encoding: Encoding.UTF8,
    });

    if (result.uri) {
      const options: FileOpenerOptions = { filePath: result.uri };
      if (isNativeAndroid() && extension.toLowerCase().includes('csv')) {
        options.contentType = 'text/plain';
      }
      await FileOpener.open(options);
    } else {
      captureSentry(fileName, fileType, new Error('No URI returned after file write.'));
    }
  } catch (error) {
    console.error('Error downloading native file:', error);
    captureSentry(fileName, fileType, error);
  }
};
const captureSentry = (fileName: string, fileType: string, error: any) => {
  Sentry.addBreadcrumb({
    message: 'Native file download operation error',
    data: { fileName, fileType },
  });
  Sentry.captureException(error);
};
