import { Icon, Illustration } from '@letsdeel/ui';
import ActionArrowIcon from '../assets/images/icons/common/action-arrow.svg?react';
import AnalyticsIcon from '../assets/images/icons/common/analytics.svg?react';
import ArchiveIcon from '../assets/images/icons/common/archive.svg?react';
import ArrowCollapseAll from '../assets/images/icons/common/arrow-collapse-all.svg?react';
import ArrowExpandAll from '../assets/images/icons/common/arrow-expand-all.svg?react';
import ArrowNewIcon from '../assets/images/icons/common/arrow-new.svg?react';
import ArrowZoomToFit from '../assets/images/icons/common/arrow-zoom-to-fit.svg?react';
import ArrowIcon from '../assets/images/icons/common/arrow.svg?react';
import AttachmentIcon from '../assets/images/icons/common/attachment.svg?react';
import BankIcon from '../assets/images/icons/common/bank.svg?react';
import BarcodeIcon from '../assets/images/icons/common/barcode.svg?react';
import BellOutline from '../assets/images/icons/common/bell-outline.svg?react';
import Bell from '../assets/images/icons/common/bell.svg?react';
import BenefitsIcon from '../assets/images/icons/common/benefits.svg?react';
import BillIcon from '../assets/images/icons/common/bill.svg?react';
import BoldIcon from '../assets/images/icons/common/bold.svg?react';
import CalendarIcon from '../assets/images/icons/common/calendar.svg?react';
import CancelIcon from '../assets/images/icons/common/cancel.svg?react';
import CenterFocus from '../assets/images/icons/common/center-focus.svg?react';
import ChartBarsIcon from '../assets/images/icons/common/chart-bars.svg?react';
import ChatNewIcon from '../assets/images/icons/common/chat-new.svg?react';
import ChatWhiteIcon from '../assets/images/icons/common/chat-white.svg?react';
import ChatIcon from '../assets/images/icons/common/chat.svg?react';
import ChatBubble from '../assets/images/icons/common/chat-bubble.svg?react';
import CheckInProgress from '../assets/images/icons/common/check-in-progress.svg?react';
import CheckNewIcon from '../assets/images/icons/common/check-new.svg?react';
import CheckOutlineIcon from '../assets/images/icons/common/check-outline.svg?react';
import CheckStar from '../assets/images/icons/common/check-star.svg?react';
import CheckIcon from '../assets/images/icons/common/check.svg?react';
import ChevronCircle from '../assets/images/icons/common/chevron-circle.svg?react';
import ChevronUp from '../assets/images/icons/common/chevron-up.svg?react';
import Chevron from '../assets/images/icons/common/chevron.svg?react';
import ChildCare from '../assets/images/icons/common/child-care.svg?react';
import CircledStarIcon from '../assets/images/icons/common/circled-star.svg?react';
import ClientProfileSmall from '../assets/images/icons/common/client-profile-small.svg?react';
import ClockOutlineIcon from '../assets/images/icons/common/clock-outline.svg?react';
import ClockIcon from '../assets/images/icons/common/clock.svg?react';
import CloseOutlineIcon from '../assets/images/icons/common/close-outline.svg?react';
import CloseIcon from '../assets/images/icons/common/close.svg?react';
import ComputerIcon from '../assets/images/icons/common/computer.svg?react';
import ConfigureIcon from '../assets/images/icons/common/configure.svg?react';
import ContractorProfileSmall from '../assets/images/icons/common/contractor-profile-small.svg?react';
import ContractsNewIcon from '../assets/images/icons/common/contracts-new.svg?react';
import ContractsIcon from '../assets/images/icons/common/contracts.svg?react';
import ConversionIcon from '../assets/images/icons/common/conversion.svg?react';
import CopyIcon from '../assets/images/icons/common/copy.svg?react';
import CoworkingIcon from '../assets/images/icons/common/coworking.svg?react';
import CreateIcon from '../assets/images/icons/common/create.svg?react';
import CreditCard from '../assets/images/icons/common/credit-card.svg?react';
import Deposits from '../assets/images/icons/common/deposits.svg?react';
import Devices from '../assets/images/icons/common/devices.svg?react';
import DirectionsColoredIcon from '../assets/images/icons/common/directions-colored.svg?react';
import DirectionsIcon from '../assets/images/icons/common/directions.svg?react';
import DollarIcon from '../assets/images/icons/common/dollar.svg?react';
import DoneIcon from '../assets/images/icons/common/done.svg?react';
import DownloadNewIcon from '../assets/images/icons/common/download-new.svg?react';
import DownloadIcon from '../assets/images/icons/common/download.svg?react';
import DropdownArrowIcon from '../assets/images/icons/common/dropdown-arrow.svg?react';
import EditIcon from '../assets/images/icons/common/edit.svg?react';
import EmailLineIcon from '../assets/images/icons/common/email-line.svg?react';
import EmailIcon from '../assets/images/icons/common/email.svg?react';
import EmotionBadWhiteIcon from '../assets/images/icons/common/emotion-bad-white.svg?react';
import EmotionBadIcon from '../assets/images/icons/common/emotion-bad.svg?react';
import EmotionGoodWhiteIcon from '../assets/images/icons/common/emotion-good-white.svg?react';
import EmotionGoodIcon from '../assets/images/icons/common/emotion-good.svg?react';
import EmotionNeutralWhiteIcon from '../assets/images/icons/common/emotion-neutral-white.svg?react';
import EmotionNeutralIcon from '../assets/images/icons/common/emotion-neutral.svg?react';
import EquipmentLaptopIcon from '../assets/images/icons/common/equipment-laptop.svg?react';
import EquipmentMobileIcon from '../assets/images/icons/common/equipment-mobile.svg?react';
import EquipmentTabletIcon from '../assets/images/icons/common/equipment-tablet.svg?react';
import EquipmentIcon from '../assets/images/icons/common/equipment.svg?react';
import EyeIcon from '../assets/images/icons/common/eye.svg?react';
import FAQIcon from '../assets/images/icons/common/faq.svg?react';
import FeedbackIcon from '../assets/images/icons/common/feedback.svg?react';
import FileImagesIcon from '../assets/images/icons/common/file-images.svg?react';
import FileIcon from '../assets/images/icons/common/file.svg?react';
import FilterIcon from '../assets/images/icons/common/filter.svg?react';
import FlagDE from '../assets/images/icons/common/flag-de.svg?react';
import FlagUK from '../assets/images/icons/common/flag-uk.svg?react';
import FolderIcon from '../assets/images/icons/common/folder.svg?react';
import GreetingIcon from '../assets/images/icons/common/greeting.svg?react';
import HamburgerIcon from '../assets/images/icons/common/hamburger.svg?react';
import HammerNewIcon from '../assets/images/icons/common/hammer-new.svg?react';
import HammerIcon from '../assets/images/icons/common/hammer.svg?react';
import HandshakeOutlinedIcon from '../assets/images/icons/common/handshake-outlined.svg?react';
import HandshakeIcon from '../assets/images/icons/common/handshake.svg?react';
import HealthShieldIcon from '../assets/images/icons/common/health-shield.svg?react';
import Heart from '../assets/images/icons/common/heart.svg?react';
import HelpOutlineIcon from '../assets/images/icons/common/help-outline.svg?react';
import HelpIcon from '../assets/images/icons/common/help.svg?react';
import HomeIcon from '../assets/images/icons/common/home.svg?react';
import Image from '../assets/images/icons/common/image.svg?react';
import InboundIcon from '../assets/images/icons/common/inbound.svg?react';
import InfinityIcon from '../assets/images/icons/common/infinity.svg?react';
import InfoOutlineIcon from '../assets/images/icons/common/info-outline.svg?react';
import InfoIcon from '../assets/images/icons/common/info.svg?react';
import InvoiceIcon from '../assets/images/icons/common/invoice.svg?react';
import ItalicIcon from '../assets/images/icons/common/italic.svg?react';
import Key from '../assets/images/icons/common/key.svg?react';
import LinkIcon from '../assets/images/icons/common/link.svg?react';
import ListBulletsIcon from '../assets/images/icons/common/list-bullets.svg?react';
import ListNumberIcon from '../assets/images/icons/common/list-number.svg?react';
import LocationIcon from '../assets/images/icons/common/location.svg?react';
import LockOutlined from '../assets/images/icons/common/lock-outlined.svg?react';
import LockIcon from '../assets/images/icons/common/lock.svg?react';
import LogoutIcon from '../assets/images/icons/common/logout.svg?react';
import Magnifier from '../assets/images/icons/common/magnifier.svg?react';
import MinusIcon from '../assets/images/icons/common/minus.svg?react';
import Monetize from '../assets/images/icons/common/monetize.svg?react';
import MoneyBagIcon from '../assets/images/icons/common/money-bag.svg?react';
import MoreTime from '../assets/images/icons/common/more-time.svg?react';
import More from '../assets/images/icons/common/more.svg?react';
import MoveIcon from '../assets/images/icons/common/move.svg?react';
import NavigationIcon from '../assets/images/icons/common/navigation-arrows.svg?react';
import NewTab from '../assets/images/icons/common/new-tab.svg?react';
import OfficeBuilding from '../assets/images/icons/common/office-building.svg?react';
import OutboundIcon from '../assets/images/icons/common/outbound.svg?react';
import PaletteIcon from '../assets/images/icons/common/palette.svg?react';
import Password from '../assets/images/icons/common/password.svg?react';
import PenColoredIcon from '../assets/images/icons/common/pen-colored.svg?react';
import PencilNewIcon from '../assets/images/icons/common/pencil-new.svg?react';
import PencilIcon from '../assets/images/icons/common/pencil.svg?react';
import PendingBadgedIcon from '../assets/images/icons/common/pending-red-badge.svg?react';
import PendingIcon from '../assets/images/icons/common/pending.svg?react';
import PercentFilledIcon from '../assets/images/icons/common/percent-filled.svg?react';
import PercentOutlineIcon from '../assets/images/icons/common/percent-outline.svg?react';
import PercentIcon from '../assets/images/icons/common/percent.svg?react';
import PiggyBankIcon from '../assets/images/icons/common/piggy-bank.svg?react';
import PlaneIcon from '../assets/images/icons/common/plane.svg?react';
import PlusCircledIcon from '../assets/images/icons/common/plus-circled.svg?react';
import PlusIcon from '../assets/images/icons/common/plus.svg?react';
import ProductTour from '../assets/images/icons/common/product-tour.svg?react';
import QuizIcon from '../assets/images/icons/common/quiz.svg?react';
import RedPlayButtonIcon from '../assets/images/icons/common/red-play-button.svg?react';
import ReportsPeopleSummaryIcon from '../assets/images/icons/common/reports-people-summary.svg?react';
import ReportsIcon from '../assets/images/icons/common/reports.svg?react';
import RevertIcon from '../assets/images/icons/common/revert.svg?react';
import Roots from '../assets/images/icons/common/roots.svg?react';
import RoundedDollar from '../assets/images/icons/common/rounded_dollar.svg?react';
import SaveIcon from '../assets/images/icons/common/save.svg?react';
import SearchIcon from '../assets/images/icons/common/search.svg?react';
import SettingsIcon from '../assets/images/icons/common/settings.svg?react';
import ShieldLightIcon from '../assets/images/icons/common/shield-light.svg?react';
import ShieldIcon from '../assets/images/icons/common/shield.svg?react';
import SickIcon from '../assets/images/icons/common/sick.svg?react';
import SignatureIcon from '../assets/images/icons/common/signature.svg?react';
import SnowIcon from '../assets/images/icons/common/snow.svg?react';
import StampIcon from '../assets/images/icons/common/stamp.svg?react';
import StartIcon from '../assets/images/icons/common/star.svg?react';
import StatementIcon from '../assets/images/icons/common/statement.svg?react';
import SupportIcon from '../assets/images/icons/common/support.svg?react';
import SyncIcon from '../assets/images/icons/common/sync.svg?react';
import TaxesIcon from '../assets/images/icons/common/taxes.svg?react';
import TeamColoredIcon from '../assets/images/icons/common/team-colored.svg?react';
import TeamIcon from '../assets/images/icons/common/team.svg?react';
import TerminateRound from '../assets/images/icons/common/terminate-round.svg?react';
import TerminateIcon from '../assets/images/icons/common/terminate.svg?react';
import ToolsIcon from '../assets/images/icons/common/tools.svg?react';
import TrashNewIcon from '../assets/images/icons/common/trash-new.svg?react';
import TrashIcon from '../assets/images/icons/common/trash.svg?react';
import Underline from '../assets/images/icons/common/underline.svg?react';
import UndoIcon from '../assets/images/icons/common/undo.svg?react';
import UnionIcon from '../assets/images/icons/common/union.svg?react';
import UploadIcon from '../assets/images/icons/common/upload.svg?react';
import UserOutlined from '../assets/images/icons/common/user-outlined.svg?react';
import UserWaveIcon from '../assets/images/icons/common/user-wave.svg?react';
import UserIcon from '../assets/images/icons/common/user.svg?react';
import VacationIcon from '../assets/images/icons/common/vacation.svg?react';
import VerifiedBlackIcon from '../assets/images/icons/common/verified-black.svg?react';
import VerifiedIcon from '../assets/images/icons/common/verified.svg?react';
import WalletColoredIcon from '../assets/images/icons/common/wallet-colored.svg?react';
import WandIcon from '../assets/images/icons/common/wand.svg?react';
import WarningTriangleIcon from '../assets/images/icons/common/warning-triangle.svg?react';
import WarningIcon from '../assets/images/icons/common/warning.svg?react';
import WhitePlayButtonIcon from '../assets/images/icons/common/white-play-button.svg?react';
import WorldIcon from '../assets/images/icons/common/world.svg?react';
import MenuAccountingActiveIcon from '../assets/images/icons/menu/accounting-active.svg?react';

/** Menu icons */
import AddNotesIcon from '../assets/images/icons/common/add-notes.svg?react';
import AllInclusive from '../assets/images/icons/common/all-inclusive.svg?react';
import AssignmentInd from '../assets/images/icons/common/assignment-ind.svg?react';
import Diversity from '../assets/images/icons/common/diversity.svg?react';
import Inventory2 from '../assets/images/icons/common/inventory2.svg?react';
import Payslips from '../assets/images/icons/common/payslips.svg?react';
import QAShield from '../assets/images/icons/common/qa-shield.svg?react';
import RocketLaunchFilled from '../assets/images/icons/common/rocket-launch-filled.svg?react';
import Workspaces from '../assets/images/icons/common/workspaces.svg?react';
import RocketLaunch from '../assets/images/icons/common/rocket-launch.svg?react';
import Termination from '../assets/images/icons/common/termination.svg?react';
import ViewWeek from '../assets/images/icons/common/view-week.svg?react';
import MenuAccountingIcon from '../assets/images/icons/menu/accounting.svg?react';
import MenuAdjustmentsActive from '../assets/images/icons/menu/adjustments-active.svg?react';
import MenuAdjustments from '../assets/images/icons/menu/adjustments.svg?react';
import MenuAppsAndPerksActiveIcon from '../assets/images/icons/menu/apps-and-perks-active.svg?react';
import MenuAppsAndPerksIcon from '../assets/images/icons/menu/apps-and-perks.svg?react';
import MenuArticleActiveIcon from '../assets/images/icons/menu/article-active.svg?react';
import MenuArticleIcon from '../assets/images/icons/menu/article.svg?react';
import MenuBenefitsActive from '../assets/images/icons/menu/benefits-active.svg?react';
import MenuBenefits from '../assets/images/icons/menu/benefits.svg?react';
import MenuCommunityIcon from '../assets/images/icons/menu/community.svg?react';
import MenuComplianceActiveIcon from '../assets/images/icons/menu/compliance-active.svg?react';
import MenuComplianceIcon from '../assets/images/icons/menu/compliance.svg?react';
import MenuContractIcon from '../assets/images/icons/menu/contract.svg?react';
import MenuContractsActiveIcon from '../assets/images/icons/menu/contracts-active.svg?react';
import MenuContractsIcon from '../assets/images/icons/menu/contracts.svg?react';
import MenuCreateContractActiveIcon from '../assets/images/icons/menu/create-active.svg?react';
import MenuCreateContractIcon from '../assets/images/icons/menu/create.svg?react';
import MenuFlexiblePaymentsActiveIcon from '../assets/images/icons/menu/flexible-payments-active.svg?react';
import MenuFlexiblePaymentsIcon from '../assets/images/icons/menu/flexible-payments.svg?react';
import MenuDeelCardActiveIcon from '../assets/images/icons/menu/deel-card-active.svg?react';
import MenuDeelCardIcon from '../assets/images/icons/menu/deel-card.svg?react';
import MenuDeelAI from '../assets/images/icons/menu/deel-ai.svg?react';
import MenuDocumentsActive from '../assets/images/icons/menu/documents-active.svg?react';
import MenuDocuments from '../assets/images/icons/menu/documents.svg?react';
import EmojiPeopleIcon from '../assets/images/icons/menu/emoji-people.svg?react';
import MenuEmployeeListActiveIcon from '../assets/images/icons/menu/employee-list-active.svg?react';
import MenuEmployeeListIcon from '../assets/images/icons/menu/employee-list.svg?react';
import MenuExpensesActive from '../assets/images/icons/menu/expenes-active.svg?react';
import MenuExpenses from '../assets/images/icons/menu/expenses.svg?react';
import MenuHomeActiveIcon from '../assets/images/icons/menu/home-active.svg?react';
import MenuHomeIcon from '../assets/images/icons/menu/home.svg?react';
import IncentivePlan from '../assets/images/icons/menu/incentive-plan.svg?react';
import {
  default as MenuInsuranceActiveIcon,
  default as MenuInsuranceIcon,
} from '../assets/images/icons/menu/insurance.svg?react';
import MenuInvoicesActiveIcon from '../assets/images/icons/menu/invoices-active.svg?react';
import MenuInvoicesIcon from '../assets/images/icons/menu/invoices.svg?react';
import MenuEORCostCalculatorActive from '../assets/images/icons/menu/menu-eor-cost-calculator-active.svg?react';
import MenuEORCostCalculator from '../assets/images/icons/menu/menu-eor-cost-calculator.svg?react';
import MenuPayrollActiveIcon from '../assets/images/icons/menu/payroll-outline-active.svg?react';
import MenuPayrollIcon from '../assets/images/icons/menu/payroll-outline.svg?react';
import MenuPeopleActiveIcon from '../assets/images/icons/menu/people-active.svg?react';
import MenuPeopleIcon from '../assets/images/icons/menu/people.svg?react';
import MenuPerksActiveIcon from '../assets/images/icons/menu/perks-active.svg?react';
import MenuPerksIcon from '../assets/images/icons/menu/perks.svg?react';
import MenuReferralsActiveIcon from '../assets/images/icons/menu/referrals-active.svg?react';
import MenuReferralsIcon from '../assets/images/icons/menu/referrals.svg?react';
import MenuReportsActiveIcon from '../assets/images/icons/menu/reports-active.svg?react';
import MenuReportsIcon from '../assets/images/icons/menu/reports.svg?react';
import MenuServiceHubActiveIcon from '../assets/images/icons/menu/service-hub-active.svg?react';
import MenuServiceHubIcon from '../assets/images/icons/menu/service-hub.svg?react';
import MenuSettingsActiveIcon from '../assets/images/icons/menu/settings-active.svg?react';
import MenuSettingsIcon from '../assets/images/icons/menu/settings.svg?react';
import MenuTaxAdviceActiveIcon from '../assets/images/icons/menu/tax-advice-active.svg?react';
import MenuTaxAdviceIcon from '../assets/images/icons/menu/tax-advice.svg?react';
import MenuTaxFormsActiveIcon from '../assets/images/icons/menu/taxforms-active.svg?react';
import MenuTaxFormsIcon from '../assets/images/icons/menu/taxforms.svg?react';
import MenuTeamSettingsActiveIcon from '../assets/images/icons/menu/team-settings-active.svg?react';
import MenuTeamSettingsIcon from '../assets/images/icons/menu/team-settings.svg?react';
import {
  default as MenuTimeAttendanceActiveIcon,
  default as MenuTimeAttendanceIcon,
} from '../assets/images/icons/menu/time-attendance.svg?react';
import MenuHiringToolkit from '../assets/images/icons/menu/toolkit-outlined.svg?react';
import MenuHiringToolkitActive from '../assets/images/icons/menu/toolkit.svg?react';
import MenuTrackerActive from '../assets/images/icons/menu/tracker-active.svg?react';
import MenuTracker from '../assets/images/icons/menu/tracker.svg?react';
import { default as MenuTransactionsActiveIcon } from '../assets/images/icons/menu/transactions-active.svg?react';
import { default as MenuTransactionsIcon } from '../assets/images/icons/menu/transactions.svg?react';
import MenuProfileActiveIcon from '../assets/images/icons/menu/user-profile-active.svg?react';
import MenuProfileIcon from '../assets/images/icons/menu/user-profile.svg?react';
import MenuWalletActive from '../assets/images/icons/menu/wallet-active.svg?react';
import MenuWallet from '../assets/images/icons/menu/wallet.svg?react';
import DPAIcon from '../assets/images/icons/misc/dpa-icon.svg?react';
import WifiOff from '../assets/images/icons/common/wifi-off.svg?react';
import WifiOn from '../assets/images/icons/common/wifi-on.svg?react';
import WorkOutlined from '../assets/images/icons/menu/work-outlined.svg?react';
import MenuSensorOccupiedOutlined from '../assets/images/icons/menu/sensor-occupied-outlined.svg?react';
import MenuSensorOccupiedOutlinedActive from '../assets/images/icons/menu/sensor-occupied-outlined.svg?react';

const ICONS = {
  ['dpa-icon']: <DPAIcon />,
  ['client-profile']: <ClientProfileSmall />,
  ['contractor-profile']: <ContractorProfileSmall />,
  archive: <ArchiveIcon />,
  arrow: <ArrowIcon />,
  ['arrow-new']: <ArrowNewIcon />,
  ['action-arrow']: <ActionArrowIcon />,
  attachment: <AttachmentIcon />,
  benefits: <BenefitsIcon />,
  bank: <BankIcon />,
  ['background-check']: <VerifiedBlackIcon />,
  bell: <Bell />,
  ['bell-outline']: <BellOutline />,
  bill: <BillIcon />,
  bold: <BoldIcon />,
  barcode: <BarcodeIcon />,
  calendar: <CalendarIcon />,
  cancel: <CancelIcon />,
  chat: <ChatIcon />,
  ['chat-new']: <ChatNewIcon />,
  ['chat-white']: <ChatWhiteIcon />,
  ['chat-bubble']: <ChatBubble />,
  check: <CheckIcon />,
  chevron: <Chevron />,
  ['child-care']: <ChildCare />,
  ['chevron-circle']: <ChevronCircle />,
  ['chevron-up']: <ChevronUp />,
  devices: <Devices />,
  done: <DoneIcon />,
  ['check-new']: <CheckNewIcon />,
  ['circled-star']: <CircledStarIcon />,
  ['check-outline']: <CheckOutlineIcon />,
  ['check-star']: <CheckStar />,
  clock: <ClockIcon />,
  ['clock-outline']: <ClockOutlineIcon />,
  close: <CloseIcon />,
  ['close-outline']: <CloseOutlineIcon />,
  computer: <ComputerIcon />,
  configure: <ConfigureIcon />,
  contracts: <ContractsIcon />,
  ['contracts-new']: <ContractsNewIcon />,
  conversion: <ConversionIcon />,
  copy: <CopyIcon />,
  create: <CreateIcon />,
  ['credit-card']: <CreditCard />,
  ['deposits']: <Deposits />,
  directions: <DirectionsIcon />,
  ['directions-colored']: <DirectionsColoredIcon />,
  dollar: <DollarIcon />,
  download: <DownloadIcon />,
  ['download-new']: <DownloadNewIcon />,
  ['dropdown-arrow']: <DropdownArrowIcon />,
  edit: <EditIcon />,
  ['edit-status']: <Illustration illustration="info" />,
  ['emotion-bad']: <EmotionBadIcon />,
  ['emotion-good']: <EmotionGoodIcon />,
  ['emotion-neutral']: <EmotionNeutralIcon />,
  ['emotion-bad-white']: <EmotionBadWhiteIcon />,
  ['emotion-good-white']: <EmotionGoodWhiteIcon />,
  ['emotion-neutral-white']: <EmotionNeutralWhiteIcon />,
  eye: <EyeIcon />,
  feedback: <FeedbackIcon />,
  folder: <FolderIcon />,
  ['file']: <FileIcon />,
  ['file-images']: <FileImagesIcon />,
  filter: <FilterIcon />,
  greeting: <GreetingIcon />,
  hamburger: <HamburgerIcon />,
  hammer: <HammerIcon />,
  ['hammer-new']: <HammerNewIcon />,
  quiz: <QuizIcon />,
  help: <HelpIcon />,
  ['help-outline']: <HelpOutlineIcon />,
  home: <HomeIcon />,
  inbound: <InboundIcon />,
  infinity: <InfinityIcon />,
  info: <InfoIcon />,
  ['info-outline']: <InfoOutlineIcon />,
  invoice: <InvoiceIcon />,
  italic: <ItalicIcon />,
  key: <Key />,
  link: <LinkIcon />,
  ['list-bullets']: <ListBulletsIcon />,
  ['list-numbers']: <ListNumberIcon />,
  location: <LocationIcon />,
  logout: <LogoutIcon />,
  lock: <LockIcon />,
  magnifier: <Magnifier />,
  minus: <MinusIcon />,
  ['money-bag']: <MoneyBagIcon />,
  move: <MoveIcon />,
  more: <More />,
  ['navigation']: <NavigationIcon />,
  ['new-tab']: <NewTab />,
  outbound: <OutboundIcon />,
  palette: <PaletteIcon />,
  password: <Password />,
  pencil: <PencilIcon />,
  ['pencil-new']: <PencilNewIcon />,
  ['pen-colored']: <PenColoredIcon />,
  percent: <PercentIcon />,
  ['percent-filled']: <PercentFilledIcon />,
  ['percent-outline']: <PercentOutlineIcon />,
  ['piggy-bank']: <PiggyBankIcon />,
  plane: <PlaneIcon />,
  ['red-play-button']: <RedPlayButtonIcon />,
  plus: <PlusIcon />,
  ['plus-circled']: <PlusCircledIcon />,
  revert: <RevertIcon />,
  ['rounded_dollar']: <RoundedDollar />,
  save: <SaveIcon />,
  search: <SearchIcon />,
  settings: <SettingsIcon />,
  shield: <ShieldIcon />,
  ['shield-light']: <ShieldLightIcon />,
  sick: <SickIcon />,
  ['health-shield']: <HealthShieldIcon />,
  signature: <SignatureIcon />,
  star: <StartIcon />,
  support: <SupportIcon />,
  sync: <SyncIcon />,
  stamp: <StampIcon />,
  snow: <SnowIcon />,
  taxes: <TaxesIcon />,
  team: <TeamIcon />,
  ['team-colored']: <TeamColoredIcon />,
  terminate: <TerminateIcon />,
  ['terminate-round']: <TerminateRound />,
  trash: <TrashIcon />,
  ['trash-new']: <TrashNewIcon />,
  underline: <Underline />,
  upload: <UploadIcon />,
  user: <UserIcon />,
  ['user-wave']: <UserWaveIcon />,
  vacation: <VacationIcon />,
  warning: <WarningIcon />,
  ['warning-triangle']: <WarningTriangleIcon />,
  wand: <WandIcon />,
  ['wallet-colored']: <WalletColoredIcon />,
  ['white-play-button']: <WhitePlayButtonIcon />,
  reports: <ReportsIcon />,
  chartBars: <ChartBarsIcon />,
  analytics: <AnalyticsIcon />,
  ['reports-people-summary']: <ReportsPeopleSummaryIcon />,
  faq: <FAQIcon />,
  ['office-building']: <OfficeBuilding />,
  undo: <UndoIcon />,
  ['lock-outlined']: <LockOutlined />,
  ['user-outlined']: <UserOutlined />,
  roots: <Roots />,
  heart: <Heart />,
  ['more-time']: <MoreTime />,
  ['monetize']: <Monetize />,
  ['product-tour']: <ProductTour />,
  ['arrow-collapse-all']: <ArrowCollapseAll />,
  ['arrow-expand-all']: <ArrowExpandAll />,
  ['center-focus']: <CenterFocus />,
  ['arrow-zoom-to-fit']: <ArrowZoomToFit />,
  ['handshake']: <HandshakeIcon />,
  ['handshake-outlined']: <HandshakeOutlinedIcon />,

  ['menu-accounting']: <MenuAccountingIcon />,
  ['menu-accounting-active']: <MenuAccountingActiveIcon />,
  ['menu-home']: <MenuHomeIcon />,
  ['menu-home-active']: <MenuHomeActiveIcon />,
  ['menu-create']: <MenuCreateContractIcon />,
  ['menu-create-active']: <MenuCreateContractActiveIcon />,
  ['menu-community']: <MenuCommunityIcon />,
  ['menu-contracts']: <MenuContractsIcon />,
  ['menu-contracts-active']: <MenuContractsActiveIcon />,
  ['menu-compliance']: <MenuComplianceIcon />,
  ['menu-compliance-active']: <MenuComplianceActiveIcon />,
  ['menu-contract']: <MenuContractIcon />,
  ['menu-reports']: <MenuReportsIcon />,
  ['menu-reports-active']: <MenuReportsActiveIcon />,
  ['menu-invoices']: <MenuInvoicesIcon />,
  ['menu-invoices-active']: <MenuInvoicesActiveIcon />,
  ['menu-expenses']: <MenuExpenses />,
  ['menu-expenses-active']: <MenuExpensesActive />,
  ['menu-taxforms']: <MenuTaxFormsIcon />,
  ['menu-taxforms-active']: <MenuTaxFormsActiveIcon />,
  ['menu-taxadvice']: <MenuTaxAdviceIcon />,
  ['menu-taxadvice-active']: <MenuTaxAdviceActiveIcon />,
  ['menu-time-attendance']: <MenuTimeAttendanceIcon />,
  ['menu-time-attendance-active']: <MenuTimeAttendanceActiveIcon />,
  ['menu-settings']: <MenuSettingsIcon />,
  ['menu-settings-active']: <MenuSettingsActiveIcon />,
  ['menu-team-settings']: <MenuTeamSettingsIcon />,
  ['menu-team-settings-active']: <MenuTeamSettingsActiveIcon />,
  ['menu-apps']: <MenuAppsAndPerksIcon />,
  ['menu-apps-active']: <MenuAppsAndPerksActiveIcon />,
  ['menu-service-hub']: <MenuServiceHubIcon />,
  ['menu-service-hub-active']: <MenuServiceHubActiveIcon />,
  ['tools']: <ToolsIcon />,
  ['menu-transactions']: <MenuTransactionsIcon />,
  ['menu-transactions-active']: <MenuTransactionsActiveIcon />,
  ['menu-perks']: <MenuPerksIcon />,
  ['menu-perks-active']: <MenuPerksActiveIcon />,
  ['menu-insurance']: <MenuInsuranceIcon />,
  ['menu-insurance-active']: <MenuInsuranceActiveIcon />,
  ['menu-deel-ai']: <MenuDeelAI />,
  ['menu-deel-card']: <MenuDeelCardIcon />,
  ['menu-deel-card-active']: <MenuDeelCardActiveIcon />,
  ['menu-employee-list']: <MenuEmployeeListIcon />,
  ['menu-employee-list-active']: <MenuEmployeeListActiveIcon />,
  ['menu-referrals']: <MenuReferralsIcon />,
  ['menu-referrals-active']: <MenuReferralsActiveIcon />,
  ['menu-payroll']: <MenuPayrollIcon />,
  ['menu-payroll-active']: <MenuPayrollActiveIcon />,
  ['menu-flexible-payments']: <MenuFlexiblePaymentsIcon />,
  ['menu-flexible-payments-active']: <MenuFlexiblePaymentsActiveIcon />,
  ['menu-people']: <MenuPeopleIcon />,
  ['menu-people-active']: <MenuPeopleActiveIcon />,
  ['menu-profile']: <MenuProfileIcon />,
  ['menu-profile-active']: <MenuProfileActiveIcon />,
  ['check-in-progress']: <CheckInProgress />,
  ['menu-adjustments']: <MenuAdjustments />,
  ['menu-adjustments-active']: <MenuAdjustmentsActive />,
  ['menu-wallet']: <MenuWallet />,
  ['menu-wallet-active']: <MenuWalletActive />,
  ['menu-article']: <MenuArticleIcon />,
  ['menu-article-active']: <MenuArticleActiveIcon />,
  statement: <StatementIcon />,
  equipment: <EquipmentIcon />,
  ['equipment-mobile']: <EquipmentMobileIcon />,
  ['equipment-tablet']: <EquipmentTabletIcon />,
  ['equipment-laptop']: <EquipmentLaptopIcon />,
  email: <EmailIcon />,
  ['email-line']: <EmailLineIcon />,
  pending: <PendingIcon />,
  pendingBadged: <PendingBadgedIcon />,
  coworking: <CoworkingIcon />,
  ['image']: <Image />,
  ['menu-benefits']: <MenuBenefits />,
  ['menu-benefits-active']: <MenuBenefitsActive />,
  ['menu-hiring-toolkit']: <MenuHiringToolkit />,
  ['menu-hiring-toolkit-active']: <MenuHiringToolkitActive />,
  verified: <VerifiedIcon />,
  ['verified-black']: <VerifiedBlackIcon />,
  flagUK: <FlagUK />,
  flagDE: <FlagDE />,
  ['menu-documents']: <MenuDocuments />,
  ['menu-documents-active']: <MenuDocumentsActive />,
  ['menu-tracker']: <MenuTracker />,
  ['menu-tracker-active']: <MenuTrackerActive />,
  ['menu-incentive-plan']: <IncentivePlan />,
  ['menu-immigration']: <Icon.VisaDocumentOutline />,
  ['menu-immigration-active']: <Icon.VisaDocumentFilled />,
  ['union']: <UnionIcon />,
  ['menu-eor-cost-calculator']: <MenuEORCostCalculator />,
  ['menu-eor-cost-calculator-active']: <MenuEORCostCalculatorActive />,
  fillings: <Icon.Edit />,
  ['menu-bank-details']: <BankIcon />,
  ['menu-learning']: <Icon.BookOutlined />,
  ['menu-learning-active']: <Icon.Book />,
  ['emoji-people']: <EmojiPeopleIcon />,
  ['add-notes']: <AddNotesIcon />,
  world: <WorldIcon />,
  ['view-week']: <ViewWeek />,
  ['wifi-off']: <WifiOff />,
  ['wifi-on']: <WifiOn />,
  ['all-inclusive']: <AllInclusive />,
  ['termination']: <Termination />,
  ['assignment-ind']: <AssignmentInd />,
  ['inventory2']: <Inventory2 />,
  ['diversity']: <Diversity />,
  ['payslips']: <Payslips />,
  ['qa-shield']: <QAShield />,
  ['rocket-launch']: <RocketLaunch />,
  ['rocket-launch-filled']: <RocketLaunchFilled />,
  ['work-outlined']: <WorkOutlined />,
  workspaces: <Workspaces />,
  ['menu-sensor-occupied-outlined']: <MenuSensorOccupiedOutlined />,
  ['menu-sensor-occupied-outlined-active']: <MenuSensorOccupiedOutlinedActive />,
};

export default ICONS;

export const TEAM_ICON_COLORS = {
  svg: {
    color: 'gray',
    '.st-1': {
      color: 'black',
    },
  },
};
