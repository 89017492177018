import dataLayer, { type PushEventObject } from '@/utils/dataLayer';
import { EventCategories } from '@/utils/analytics/constants';
import { Capacitor } from '@capacitor/core';

export const addDataLayerMobile = (props: PushEventObject) => {
  dataLayer.push({
    event_category: EventCategories.Mobile,
    device_family: Capacitor.getPlatform(),
    ...props,
  });
};
