import { Button, HStack } from '@letsdeel/ui';
import { API_REDIRECT_URL } from '@/utils/environment';
import Logo from '../Logo';
import './azure-login-button.less';
import { useTranslation } from 'react-i18next';

const AzureLoginButton = ({ children, onComplete }) => {
  const { t } = useTranslation();
  const handleClick = () => {
    localStorage.setItem('AzureLoginOnComplete', onComplete);
    const query = new URLSearchParams();
    query.append('client_id', window.deelConfig?.data?.ssoProviderClientId);
    query.append('redirect_uri', `${API_REDIRECT_URL}/webhooks/azure`);
    query.append('state', `${window.deelConfig?.data?.ssoProviderClientId},${window.location.origin}`);
    query.append('response_type', 'code');
    query.append('scope', 'openid');
    window.location.href = `${window.deelConfig?.data?.ssoProviderDomain}/oauth2/v2.0/authorize?${query.toString()}`;
  };

  return (
    <Button
      className="text-center position-relative azure-login-button"
      fullWidth
      variant="outlined"
      size="large"
      onClick={handleClick}
    >
      <HStack gap={1} align="center" className="justify-content-center">
        <Logo type="azure" size="md" color="#00297A" className="d-flex align-items-center" />
        {children || t('common.apps.azure.loginUsingAzure')}
      </HStack>
    </Button>
  );
};

export default AzureLoginButton;
