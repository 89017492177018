import React, { useEffect, useState } from 'react';
import { Alert, CodeInput, Stack, styled, H2, H4, Illustration } from '@letsdeel/ui';
import { uuid } from '@/utils/main';
import { useTranslation } from 'react-i18next';

const StyledStep = styled(Stack)`
  padding-top: 16px;
  height: 400px;
  overflow: hidden;
`;

interface Props {
  onCodeChange: (code: string[]) => void;
  error?: string;
  code: string[];
  isLoading: boolean;
}

const TFASetupEnable = ({ error, isLoading, code, onCodeChange }: Props) => {
  const [codeKey, setCodeKey] = useState<string>(uuid());
  const { t } = useTranslation();

  useEffect(() => {
    setCodeKey(uuid());
    onCodeChange(Array(6).fill(''));
  }, [onCodeChange]);

  return (
    <StyledStep direction="column" spacing={2}>
      <Stack direction="column" spacing={2}>
        <H2>{t('settings.authenticator.setupPopup.setupAuthenticatorApp.enterCode')}</H2>
        <H4 regular color="neutral.darker">
          {t('settings.authenticator.setupPopup.setupAuthenticatorApp.description')}
        </H4>
      </Stack>
      {error ? <Alert severity="error">{error}</Alert> : null}
      <Stack direction="column" spacing={2} className={error ? 'mt-9' : 'mb-3 mt-10'}>
        <CodeInput
          key={codeKey}
          className="small-device-gap"
          disabled={isLoading}
          isLoading={isLoading}
          autoFocus={true}
          values={code}
          onChange={onCodeChange}
        />
        {!error && (
          <H4 regular color="neutral.darker">
            e.g. 123 456
          </H4>
        )}
      </Stack>
      <Stack direction="row" justifyContent="center">
        <Illustration illustration={'authenticator'} width={150} />
      </Stack>
    </StyledStep>
  );
};

export default TFASetupEnable;
