import dataLayer from '@/utils/dataLayer';
import { EventCategories, EventProducts } from '../constants';

export enum OTPCodeEventActions {
  ModalVisualised = 'Modal visualised',
  SendNewCode = 'Click on Send a New Code',
  AuthenticationCTA = 'Click on Authentication CTA',
  AuthenticationSuccessful = 'Authentication Successful',
  FiredErrors = 'Fired Errors',
  ClosePopUp = 'Close Pop up',
  ContactSupport = 'Click on Contact Support',
}

export enum OTPCodeProducts {
  Login = 'Login',
  Withdrawal = 'Withdrawal',
}

export enum OTPCodeCTA {
  CloseIcon = 'CloseIcon',
  CancelButton = 'CancelButton',
}

interface paramsInterface {
  cta_name?: OTPCodeCTA;
  error?: string;
  enabled?: boolean;
}

export const trackMfaEvent = (action: OTPCodeEventActions, product: OTPCodeProducts, params?: paramsInterface) => {
  dataLayer.push({
    event_category: EventCategories.Click,
    event_product: EventProducts.MultifactorAuthentication,
    event_action: action,
    product: product,
    ...params,
  });
};
