import type { ValueOf } from '@/types/utils';
import type { WidgetsContainerGrid } from '@/scenes/Home/components/CustomizableHomePage/types';
import type { HomeFeatureCSATStorage } from '@/scenes/Home/hooks/useHomeFeatureCSAT/types';

export const ThemeVariants = {
  Base: 'base',
  Light: 'light',
  Dark: 'dark',
  SystemDefault: 'system_default',
} as const;
export type ThemeVariant = ValueOf<typeof ThemeVariants>;
export type KeyboardHintStorageItem = {
  displayed?: number | null;
  hidden?: boolean;
};
export type ActiveThemeStorageItem = {
  [StorageKeysEnum.ACTIVE_THEME]: ThemeVariant;
};

export type CSMWidgetHiddenStorageItem = {
  csmWidget?: boolean;
};

export type HRXWidgetHiddenStorageItem = {
  hrxManagerWidgetHidden?: boolean;
};

export type HRXClientWidgetHiddenStorageItem = {
  hrxManagerClientWidgetHidden?: boolean;
};

export type TopNavGuidedTourStorageItem = {
  skipCount?: number;
  finishedTour?: boolean;
  forcedExit?: boolean;
};

export type DocumentsEorEaTemplatesStorageItem = {
  downloadPreviewTemplateModalHidden?: boolean;
  eaTemplateEducationalModalHidden?: boolean;
};

export enum StorageKeysEnum {
  ACTIVE_THEME = 'activeTheme',
  KEYBOARD_NAVIGATION_HINT_KEY = 'keyboardNavigationHint',
  CLIENT_DASHBOARD_WIDGETS = 'clientDashboardWidgets',
  CSM_WIDGET_HIDDEN = 'csmWidgetHidden',
  TOP_NAV_SWITCH_BAR = 'topNavSwitchBar',
  MOBILITY_IMMIGRATION_NEW_VIEW = 'mobilityNewImmigrationView',
  TOP_NAV_CSAT = 'topNavCsat',
  TOP_NAV_SEEN_COUNT = 'topNavSeenCount',
  TOP_NAV_GUIDED_TOUR = 'topNavGuidedTour',
  HRX_MANAGER_WIDGET_HIDDEN = 'hrxManagerWidgetHidden',
  DOCUMENTS_EOR_EA_TEMPLATES = 'documentsEorEaTemplates',
  HOME_FEATURE_CSAT_COUNTERS = 'homeFeatureCSATCounters',
  HRX_MANAGER_CLIENT_WIDGET_HIDDEN = 'hrxManagerClientWidgetHidden',
  UI_DENSITY_SETTING = 'uiDensitySetting',
}

export type TopNavSwitchBarStorageItem = {
  visible?: boolean;
  closedByUser?: boolean;
};

export type UIDensitySettingStorageItem = {
  zoom?: Zoom;
};

export type MobilityImmigrationStorageItem = {
  view: 'OLD' | 'NEW' | 'SWITCH';
};

export type TopNavCsatStorageItem = {
  lastSessionDate?: string;
  notification?: {
    seenAt: string;
  };
};

export type TopNavSeenCountStorageItem = {
  count?: number;
};

export type StorageKeysValues = {
  [StorageKeysEnum.ACTIVE_THEME]: ActiveThemeStorageItem;
  [StorageKeysEnum.KEYBOARD_NAVIGATION_HINT_KEY]: KeyboardHintStorageItem;
  [StorageKeysEnum.TOP_NAV_SWITCH_BAR]: TopNavSwitchBarStorageItem;
  [StorageKeysEnum.CLIENT_DASHBOARD_WIDGETS]: WidgetsContainerGrid<any>;
  [StorageKeysEnum.CSM_WIDGET_HIDDEN]: CSMWidgetHiddenStorageItem;
  [StorageKeysEnum.MOBILITY_IMMIGRATION_NEW_VIEW]: MobilityImmigrationStorageItem;
  [StorageKeysEnum.TOP_NAV_CSAT]: TopNavCsatStorageItem;
  [StorageKeysEnum.TOP_NAV_SEEN_COUNT]: TopNavSeenCountStorageItem;
  [StorageKeysEnum.HRX_MANAGER_WIDGET_HIDDEN]: HRXWidgetHiddenStorageItem;
  [StorageKeysEnum.TOP_NAV_GUIDED_TOUR]: TopNavGuidedTourStorageItem;
  [StorageKeysEnum.DOCUMENTS_EOR_EA_TEMPLATES]: DocumentsEorEaTemplatesStorageItem;
  [StorageKeysEnum.HOME_FEATURE_CSAT_COUNTERS]: HomeFeatureCSATStorage;
  [StorageKeysEnum.HRX_MANAGER_CLIENT_WIDGET_HIDDEN]: HRXClientWidgetHiddenStorageItem;
  [StorageKeysEnum.UI_DENSITY_SETTING]: UIDensitySettingStorageItem;
};

export type GetStorageValue<T extends StorageKeysEnum> = {
  key: T;
  value?: StorageKeysValues[T];
};

export type StorageKeys = {
  keys: StorageKeysEnum[];
};

export type Zoom = 0.75 | 1;
