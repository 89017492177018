import { Trans, useTranslation } from 'react-i18next';
import Button from '@/components/Button';
import PasswordPolicy from '@/components/PasswordPolicy';
import { getPasswordSchema } from '@/components/PasswordPolicy/helpers';
import { parseJwt } from '@/utils/main';
import { Alert, Box, Icon, TextField, VStack, WizardFormik, H1, H6 } from '@letsdeel/ui';
import { Field } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import Footer from './Footer';
import userApi from '@/utils/api/user';
import { PASSWORD_POLICY_LIST } from '@/components/PasswordPolicy/helpers';
import useCheckResetToken from '@/scenes/Settings/scenes/SecuritySettings/hooks/useCheckResetToken';

interface FormValues {
  password: string;
}

const RESET_PASSWORD_INITIAL_VALUES: FormValues = {
  password: '',
};

interface Props {
  token: string;
  isLoading: boolean;
  customDomainHostName?: string;
  afterSubmitResetPassword: (error?: any) => void;
}

const TypeNewPasswordForm = ({ token, isLoading, customDomainHostName, afterSubmitResetPassword }: Props) => {
  const { t } = useTranslation();
  const [isShowingPassword, setIsShowingPassword] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const formRef = useRef<WizardFormik.FormikProps<FormValues> | null>(null);
  const { checkToken } = useCheckResetToken();
  const { email: emailFromToken, isNewPassword }: { email?: string; isNewPassword?: boolean } = useMemo(() => {
    if (!token) return {};
    const parsedToken = parseJwt(token);
    if (!parsedToken) return {};
    return {
      email: parsedToken.email,
      isNewPassword: parsedToken.scope === 'org_from_admin',
    };
  }, [token]);

  const toggleShowPassword = () => setIsShowingPassword(!isShowingPassword);

  const onSubmitResetPassword = async () => {
    try {
      if (!token) throw new Error('token is required');
      await userApi.reset({ token, password: formRef.current?.values.password });
      return afterSubmitResetPassword();
    } catch (error) {
      const err = error as any;

      if (err.response?.status === 409) {
        formRef.current?.setErrors({ password: t('authentication.resetPassword.passwordWasRecentlyUsed').toString() });
        return false;
      }

      return afterSubmitResetPassword(err);
    }
  };

  useEffect(() => {
    checkToken(token, false);
  }, [token, checkToken]);

  return (
    <>
      <WizardFormik.Formik
        innerRef={formRef}
        key="reset-form"
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={RESET_PASSWORD_INITIAL_VALUES}
        validationSchema={Yup.object({
          password: getPasswordSchema({ email: emailFromToken, prevPassword: '' }),
        })}
        onSubmit={onSubmitResetPassword}
      >
        {({ values, isSubmitting, errors }) => (
          <WizardFormik.Form>
            <VStack spacing={24}>
              <H1 className="semi-bold text-center mt-2">
                {t(
                  isNewPassword
                    ? 'authentication.resetPassword.setPassword'
                    : 'authentication.resetPassword.enterNewPassword'
                ).toString()}
              </H1>
              {customDomainHostName ? (
                <H6 className="text-center">
                  <Trans
                    i18nKey="authentication.resetPassword.deelPortalExt"
                    components={[<span className="capitalize" key="60a112e6e5_0" />]}
                    values={{ v0: customDomainHostName }}
                  />
                </H6>
              ) : null}

              <VStack spacing={8}>
                {isNewPassword ? null : (
                  <Alert severity="warning">
                    <span>
                      <Trans
                        i18nKey="authentication.resetPassword.noPreviousPasswords"
                        components={[<b key="c49ef1b47d_0" />]}
                      />
                    </span>
                  </Alert>
                )}
                <Field
                  error={!!errors.password}
                  helperText={t(errors.password as string).toString()}
                  name="password"
                  autoComplete="new-password"
                  as={TextField}
                  label={t('authentication.resetPassword.newPassword').toString()}
                  type={isShowingPassword ? 'text' : 'password'}
                  endIcon={
                    isShowingPassword ? (
                      <Icon.Hide
                        color={errors.password ? 'danger' : 'primary'}
                        size={18}
                        onClick={toggleShowPassword}
                      />
                    ) : (
                      <Icon.Show
                        color={errors.password ? 'danger' : 'primary'}
                        size={18}
                        onClick={toggleShowPassword}
                      />
                    )
                  }
                />
              </VStack>

              <Box>
                <PasswordPolicy
                  hidePreviousPasswordsWarning
                  password={values.password}
                  onPasswordValidated={(state) => setIsValidPassword(state.isValid)}
                  passwordPolicyList={PASSWORD_POLICY_LIST}
                />
              </Box>

              <Button type="submit" full size="lg" disabled={!isValidPassword} loading={isSubmitting || isLoading}>
                {t(
                  isNewPassword ? 'authentication.resetPassword.createPassword' : 'authentication.resetPassword.submit'
                )}
              </Button>

              <Footer />
            </VStack>
          </WizardFormik.Form>
        )}
      </WizardFormik.Formik>
    </>
  );
};

export default TypeNewPasswordForm;
