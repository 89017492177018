import { useMemo } from 'react';
import type { INotification } from '@/utils/api/notifications/notifications';
import { useUnisureEnrollment } from '../benefits/useUnisureEnrollment';
import useUserStore from '../useUserStore';

export interface IStaticNotificationItem extends Pick<INotification, 'actionText' | 'title' | 'description' | 'icon'> {
  onAction: () => void | Promise<void>;
  shouldDismiss?: () => void;
}

export const useNotificationOptions = () => {
  const user = useUserStore();
  const { unisureEnrollment, openEnrollmentForm, triggerText, descriptionText, activeFormState } =
    useUnisureEnrollment();

  const notificationOptions = useMemo(
    () => ({
      'unisure-enrollment':
        user.isEmployee && unisureEnrollment && !activeFormState?.isTaskDismissed
          ? {
              actionText: triggerText,
              description: descriptionText,
              icon: 'Bell',
              title: 'Health insurance enrollment',
              onAction: openEnrollmentForm,
            }
          : null,
      // ... new local custom notifications can be added here
    }),
    [
      user.isEmployee,
      unisureEnrollment,
      activeFormState?.isTaskDismissed,
      triggerText,
      descriptionText,
      openEnrollmentForm,
    ]
  );

  return notificationOptions as Record<keyof typeof notificationOptions, IStaticNotificationItem | null>;
};
