import type { History } from 'history';

export const getLocationParams = () => {
  return window.location.search
    .slice(1)
    .split('&')
    .map((p) => p.split('='))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
};

export const getQueryValue = (name: string) => {
  const query = new URLSearchParams(location.search);
  return query.get(name);
};

export const parseQueryValue = (name: string) => {
  const val: string | null = getQueryValue(name);
  try {
    if (typeof val === 'string') {
      return JSON.parse(val);
    }
  } catch (e) {}
};

export const navigateWithoutScroll = (
  history: History,
  path: string,
  { state, offsetY }: { state?: any; offsetY?: number } = {}
) => {
  const { scrollX, scrollY } = window;
  const initialOnScroll = window.onscroll;
  window.onscroll = () => window.scrollTo(scrollX, scrollY + (offsetY || 0));
  history.push(path, state);
  setTimeout(() => (window.onscroll = initialOnScroll), 0);
};
