import instance from '@/utils/api/instance';

type FinalPaymentPayload = {
  calculationType: string;
  workWeekStart?: number | string;
  workWeekEnd?: number | string;
  customAmount?: number | string;
};

export default {
  create: async (id: string, payload: FinalPaymentPayload) => {
    const { data } = await instance.post(`/contracts/${id}/final-payments`, payload);
    return data;
  },

  edit: async (id: string, payload: FinalPaymentPayload) => {
    const { data } = await instance.put(`/contracts/${id}/final-payments`, payload);
    return data;
  },

  addReport: async (id: string, payload: any) => {
    const { data } = await instance.post(`/contracts/${id}/final-payments/reports`, payload);
    return data;
  },

  calculate: async (id: string, endDate: any, calculationType: any, workWeekStart: any, workWeekEnd: any) => {
    const { data } = await instance.get(
      `/contracts/${id}/final-payments/calculate?endDate=${endDate}${
        calculationType ? `&calculationType=${calculationType}` : ''
      }${workWeekStart || workWeekStart === 0 ? `&workWeekStart=${workWeekStart}` : ''}${
        workWeekEnd ? `&workWeekEnd=${workWeekEnd}` : ''
      }`
    );
    return data;
  },

  deleteReport: async (id: string, reportId: string) => {
    const { data } = await instance.delete(`contracts/${id}/final-payments/reports/${reportId}`);
    return data;
  },
};
