import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

export const initNativeGoogleAuth = async () => {
  await GoogleAuth.initialize({
    scopes: ['profile', 'email', 'openid'],
  });
  await signOutNativeGoogle();
};

export const signInNativeGoogle = async () => {
  try {
    const response = await GoogleAuth.signIn();
    return response.authentication.accessToken;
  } catch (e) {
    return null;
  }
};

export const signOutNativeGoogle = async () => {
  try {
    await GoogleAuth.signOut();
  } catch {}
};
