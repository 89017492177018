import type { SWRConfiguration } from 'swr';
import instance from '@/utils/api/instance';
import type { ContractMissingUpdatesData, ContractMissingUpdatesType } from '@/types/PEO';
import useFetch from '../useFetch';

export default function useFetchContractMissingUpdates(
  contractOid?: string,
  type?: ContractMissingUpdatesType,
  options: SWRConfiguration = {}
) {
  const { data, isLoading, error, mutate } = useFetch<ContractMissingUpdatesData>(
    getKey(contractOid, type),
    options,
    customFetcher
  );

  return { data, isLoading, error, mutate };
}

export function getKey(contractOid?: string, type?: ContractMissingUpdatesType) {
  if (!contractOid) return null;

  const params = type ? `?${new URLSearchParams({ type }).toString()}` : '';
  return `peo_integration/contract/${contractOid}/missing_updates${params}`;
}

function customFetcher(url: string) {
  return instance.get(url, { hideErrorMessageOnNetworkError: true, suppressSnackbar: true }).then((res) => res.data);
}
