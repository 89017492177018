export enum BenefitProviderEligibilityRules {
  NUMBER_OF_DAYS = 'NUMBER_OF_DAYS',
  NUMBER_OF_MONTHS = 'NUMBER_OF_MONTHS',
  FIRST_OF_MONTH_AFTER_DAYS = 'FIRST_OF_MONTH_AFTER_DAYS',
}

export enum BenefitsPlanRateStructure {
  TIERED_PRICE = 'TIERED_PRICE',
  FIXED_ANNUAL_CONTRIBUTION = 'FIXED_ANNUAL_CONTRIBUTION',
  PREMIUM_BASED_RATE = 'PREMIUM_BASED_RATE',
  ANNUAL_SALARY_MULTIPLIER = 'ANNUAL_SALARY_MULTIPLIER',
  PRICE_PER_PERSON = 'PRICE_PER_PERSON',
  PRICE_PER_PERSON_EXTENDED_COVERAGE_AS_MINOR = 'PRICE_PER_PERSON_EXTENDED_COVERAGE_AS_MINOR',
  FIXED_PRICE_AND_POLICY_AMOUNT = 'FIXED_PRICE_AND_POLICY_AMOUNT',
  FIXED_POLICY_AMOUNT_WITH_AGE_BANDED_RATES = 'FIXED_POLICY_AMOUNT_WITH_AGE_BANDED_RATES',
  WEEKLY_SALARY_MULTIPLIER = 'WEEKLY_SALARY_MULTIPLIER',
  MONTHLY_SALARY_MULTIPLIER = 'MONTHLY_SALARY_MULTIPLIER',
  MULTIPLE_RATED_ANNUAL_SALARY_MULTIPLIER = 'MULTIPLE_RATED_ANNUAL_SALARY_MULTIPLIER',
  TIERED_SINGLE_AND_FAMILY_PRICE = 'TIERED_SINGLE_AND_FAMILY_PRICE',
  PREMIUM_RATED_FAMILY_GROUP_ASSIGNED_VALUES = 'PREMIUM_RATED_FAMILY_GROUP_ASSIGNED_VALUES',
}

export enum BenefitsPlanContributionType {
  PERCENTAGE = 'PERCENTAGE',
  FIXED_AMOUNT = 'FIXED_AMOUNT',
}

export enum PlanBillingFrequency {
  MONTHLY = 'MONTHLY',
  BIMONTHLY = 'BIMONTHLY',
  BIWEEKLY = 'BIWEEKLY',
}

export enum PlanBillingFrequencyInWeeks {
  MONTHLY = 12,
  BIMONTHLY = 24,
  BIWEEKLY = 26,
}

export enum BenefitPlanCoverageGroup {
  EMPLOYEE_ONLY = 'EMPLOYEE',
  EMPLOYEE_AND_SPOUSE = 'EMPLOYEE_AND_SPOUSE',
  EMPLOYEE_AND_DOMESTIC_PARTNER = 'EMPLOYEE_AND_DOMESTIC_PARTNER',
  EMPLOYEE_AND_CHILD = 'EMPLOYEE_AND_CHILD',
  EMPLOYEE_AND_FAMILY = 'EMPLOYEE_AND_FAMILY',
}

export enum BenefitProviderBillingPeriodRules {
  NEXT_MONTH = 'NEXT_MONTH',
  RETROACTIVE = 'RETROACTIVE',
  IMMEDIATELY = 'IMMEDIATELY',
}

export enum BenefitProviderPlanPremiumScheme {
  SALARY_BASED = 'SALARY_BASED',
  EMPLOYEE_BENEFIT = 'EMPLOYEE_BENEFIT',
}

export enum BenefitParticipation {
  MANDATORY = 'MANDATORY',
  OPTIONAL = 'OPTIONAL',
  DISABLED = 'DISABLED',
}

export enum EnrollmentStatuses {
  AWAITING_CONTRACT_ACTIVATION = 'AWAITING_CONTRACT_ACTIVATION',
  AWAITING_ENROLLMENT = 'AWAITING_ENROLLMENT',
  ENROLLED = 'ENROLLED',
  OPTED_OUT = 'OPTED_OUT',
  EXPIRED = 'EXPIRED',
  COVERAGE_DECLINED = 'COVERAGE_DECLINED',
  REQUIRE_HEALTHCARE_ENROLLMENT = 'REQUIRE_HEALTHCARE_ENROLLMENT',
  UPCOMING = 'UPCOMING',
  NOT_ENROLLED = 'NOT_ENROLLED',

  // Transitory statuses
  ENROLLED_PENDING_PROCESS = 'ENROLLED_PENDING_PROCESS',
  OPTED_OUT_PENDING_PROCESS = 'OPTED_OUT_PENDING_PROCESS',
}

export enum RelationTypes {
  EMPLOYEE = 'EMPLOYEE',
  SPOUSE = 'SPOUSE',
  CHILD = 'CHILD',
  DOMESTIC_PARTNER = 'DOMESTIC_PARTNER',
}

export enum GenderType {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum DependentTypes {
  DEPENDENT = 'DEPENDENT',
  BENEFICIARY = 'BENEFICIARY',
}

export enum BeneficiaryTypes {
  PRIMARY = 'PRIMARY',
  CONTINGENT = 'CONTINGENT',
}

// TODO: MVP will only support monthly contribution periods, but this will change in the future to support other periods
export enum BenefitPeriods {
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
}

export enum BenefitContributionMethods {
  PERCENTAGE = 'PERCENTAGE',
  FIXED_AMOUNT = 'FIXED_AMOUNT',
}

export enum BenefitTypes {
  HEALTHCARE = 'HEALTHCARE',
  DENTAL = 'DENTAL',
  VISION = 'VISION',
  HSA = 'HSA',
  FSA = 'FSA',
  LIFE_INSURANCE = 'LIFE_INSURANCE',
  VOLUNTARY_LIFE_INSURANCE = 'VOLUNTARY_LIFE_INSURANCE',
  K_401 = '401_K',
  LONG_TERM_DISABILITY = 'LONG_TERM_DISABILITY',
  SHORT_TERM_DISABILITY = 'SHORT_TERM_DISABILITY',
  PENSION = 'PENSION',
  MEAL_VOUCHER = 'MEAL_VOUCHER',
  ACCIDENT_INSURANCE = 'ACCIDENT_INSURANCE',
  CRITICAL_ILLNESS = 'CRITICAL_ILLNESS',
  ACCIDENT_AND_DISABILITY = 'ACCIDENT_AND_DISABILITY',
  FSA_DEPENDENT_CARE = 'FSA_DEPENDENT_CARE',
  FSA_TRANSIT = 'FSA_TRANSIT',
  FSA_PARKING = 'FSA_PARKING',
}

export enum QualifyingLifeEventTypes {
  MARRIAGE = 'MARRIAGE',
  DIVORCE = 'DIVORCE',
  CHILD_BIRTH = 'BIRTH',
  CHILD_ADOPTION = 'ADOPTION',
  CHILD_GUARDIANSHIP = 'GUARDIANSHIP',
  DEATH_OF_POLICY_HOLDER = 'DEATH_OF_POLICY_HOLDER',
  CHANGES_IN_RESIDENCE = 'CHANGES_IN_RESIDENCE',
  LOSE_EXISTING_HEALTH_COVERAGE = 'LOSE_EXISTING_HEALTH_COVERAGE',
  LOSE_GOVERNMENT_PROVIDED_COVERAGE = 'LOSE_GOVERNMENT_PROVIDED_COVERAGE',
  AGING_OFF_PARENTS_PLAN = 'AGING_OFF_PARENTS_PLAN',
  OTHER = 'OTHER',
}

export enum QualifyingLifeEventStatuses {
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum PlanSelectionOptions {
  ALLOW_UPGRADE = 'ALLOW_UPGRADE',
  ALLOW_DOWNGRADE = 'ALLOW_DOWNGRADE',
  ALLOW_BOTH = 'ALLOW_BOTH',
  ALLOW_NONE = 'ALLOW_NONE',
}

export enum EnrollmentExpirationPolicies {
  OPT_OUT = 'OPT_OUT',
  ENROLL = 'ENROLL',
  NONE = 'NONE',
  FORCE_ENROLL = 'FORCE_ENROLL',
}

export enum ProvidersWithCustomTerms {
  KAISER = 'KAISER',
}

export enum DependentMaritalStatus {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  DOMESTIC_PARTNERSHIP = 'DOMESTIC_PARTNERSHIP',
}

export enum EnrollmentFormStepLabel {
  PERSONAL_INFO = 'Personal info',
  DEPENDENTS = 'Dependents',
  BENEFITS = 'Benefits',
  REVIEW_AND_CONFIRM = 'Review and confirm',
}

export enum EnrollmentFormStepValue {
  PERSONAL_INFO = 'personal-info',
  DEPENDENTS = 'dependents',
  BENEFITS = 'benefits',
  REVIEW_AND_CONFIRM = 'review-and-confirm',
}
