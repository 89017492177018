// eslint-disable-next-line no-restricted-imports
import type moment from 'moment';
import type { AxiosInstance } from 'axios';
import instance from '@/utils/api/instance';
import type {
  EorMileageDistanceUnit,
  EorPerDiemTimeUnit,
  EorPerDiemTripType,
} from '@/hooks/api/eor/useFetchEorMileageAndPerDiemSettings';

export type AdjusterType = 'CLIENT' | 'EMPLOYEE';
export type AdjustmentStatusType = 'NEEDS_APPROVAL' | 'APPROVED' | 'DECLINED' | 'AWAITING_PAYMENT' | 'UNDER_REVIEW';
export type AdjustmentType = 'BONUS' | 'ALLOWANCE' | 'EXPENSE' | 'SALARY_ADVANCE';
export interface IAdjustableType {
  id: string;
  type: AdjustmentType;
  isAdjustmentRequired: boolean;
  canBeSubmitted: boolean;
}
interface IAdjustmentPayrollEvent {
  id: string;
}
interface IS3File {
  id: string;
  name: string;
  fileType: string;
}
export type AdjustmentActorType = 'client' | 'eor' | 'employee';
export interface IAdjustmentActor {
  type: AdjustmentActorType;
  id: number;
  name: string;
  picUrl: string | null;
}

export type PayrollVerificationStatus =
  | 'AWAITING_REVIEW'
  | 'APPROVED'
  | 'REQUIRES_CLARIFICATION'
  | 'ESCALATED'
  | 'PROCESSED_WITHOUT_REVIEW'
  | 'RECLASSIFIED_AS_ALLOWANCE';

export interface PayrollVerificationDetails {
  id: string;
  status: PayrollVerificationStatus;
  reason?: string;
  description?: string;
  reviewedAt: string;
  reviewedByUserId: number;
}

export interface IBaseAdjustment {
  id: string;
  value: string;
  adjusterProfile: IAdjustmentActor;
  reviewerProfile: IAdjustmentActor | null;
  adjusterProfileId: number;
  adjusterType: AdjusterType;
  status: AdjustmentStatusType;
  s3File: IS3File | null;
  payrollReportColumnId: string;
  description: string;
  eorContractId: number;
  contractOid: string;
  isRecurring: boolean;
  recurringStatus?: AdjustmentStatusType;
  reviewedAt: string | null;
  denialReason: string | null;
  PayrollEvents: IAdjustmentPayrollEvent[];
  payrollEventId: string | null;
  createdAt: string;
  adjustmentRequestReviewers: {
    adjustmentRequestId: string;
    reviewerProfileId: number;
    adjustmentStatus: string;
    createdAt: string;
    updatedAt: string;
    reviewerProfileType: string;
  }[];
  when: string;
  title: string;
  adjustmentCategory?: {
    id: string;
    label: string;
    limit: number;
    organizationId?: number;
    questions?: ('RECURRING' | 'BUSINESS_TRIP')[];
    adjustmentCategoryGroup: {
      id: string;
      label: string;
    };
  };
  fixedAdjustmentId?: string[] | null;
  originalCurrency?: string;
  currency?: string;
  originalValue?: string;
  tags?: ('RECURRING' | 'BUSINESS_TRIP')[];
  invoiceId?: number;
  invoice?: {
    id: number;
    invoiceId: string;
    status: string;
    dueDate: string;
    total: string;
    label: string;
    paidAt: string | null;
  };
  payrollVerificationStatus: PayrollVerificationStatus;
  payrollVerificationDetails: PayrollVerificationDetails[];
  adjustmentVendor?: string;
  country?: string;

  mileage?: {
    vehicleType?: string | null;
    startingDestination?: string;
    endingDestination?: string;
    totalDistance?: string;
    unit?: EorMileageDistanceUnit;
    rateValuePerUnit?: string;
  };

  perDiem?: {
    tripType?: EorPerDiemTripType;
    tripDestination?: string;
    dateDeparture?: string;
    dateReturning?: string;
    totalDays?: number;
    unit?: EorPerDiemTimeUnit;
    rateValuePerUnit?: string | null;
    hotelRateValuePerUnit?: string | null;
    foodRateValuePerUnit?: string | null;
  };
  businessTrip?: {
    departingCountry: string;
    destinationCountry: string;
    startDate: string;
    endDate: string;
    name: string;
    reason: string;
  };
  migratedAdjustmentId?: string;
}
export interface IPendingAdjustment extends IBaseAdjustment {
  status: 'NEEDS_APPROVAL' | 'UNDER_REVIEW' | 'AWAITING_PAYMENT';
  reviewedAt: null;
  declineReason: null;
  reviewerProfileId: null;
}
export interface IApprovedAdjustment extends IBaseAdjustment {
  status: 'APPROVED';
  declineReason: null;
}
export interface IAwaitingPaymentAdjustment extends IBaseAdjustment {
  status: 'AWAITING_PAYMENT';
  declineReason: null;
}
export interface IDeclinedAdjustment extends IBaseAdjustment {
  status: 'DECLINED';
  declineReason: string;
}

// Mass getMassApproval
export interface IEORMassApprovalTeam {
  id: number;
  name: string;
  count: number;
  contracts: IEORMassApprovalContract[];
}
export interface IEORMassApprovalEmpoyee {
  id: number;
  name: string;
  picUrl: string | null;
}
export interface IEORMassApprovalContract {
  id: string;
  eorContractId: number;
  name: string;
  currency: string;
  amount: string;
  adjustments: IEORMassApprovalAdjustment[];
  hasAttachments: boolean;
  employee: IEORMassApprovalEmpoyee;
  lockDate: string | null;
}
export interface IEORMassApprovalAdjustment {
  id: string;
  payrollReportColumnId: string;
  amount: string;
  s3FileId: string;
  dueDate?: moment.Moment;
}

export type IGetEORMassApprovalAdjustments = IEORMassApprovalTeam[];

interface ISingleMassApprovalAdjustmentApprover {
  id: number;
  name: string;
  email: string;
  approved: boolean;
}
export interface ISingleMassApprovalAdjustment
  extends Omit<IAdjustment, 'adjusterProfile' | 'reviewerProfile' | 'PayrollEvents' | 'payrollEventIds'> {
  submittedBy: string;
  dueDate?: moment.Moment;
}
export interface ISingleMassApprovalPayrollEvent {
  createdAt: string;
  deletedAt: string | null;
  end: string;
  id: string;
  lock: string;
  payrollScheduleId: string;
  payrollSettingsId: string;
  reference: string;
  start: string;
  state: 'OPEN' | 'LOCKED';
  updatedAt: string;
}
export interface IEORSingleMassApprovalContract {
  name: string;
  id: string;
  eorContractId: number;
  adjustments: ISingleMassApprovalAdjustment[];
  approvers: ISingleMassApprovalAdjustmentApprover[];
  payrollEvents: ISingleMassApprovalPayrollEvent[];
}

export interface IAddEditPerDiemAdjustmentPayload {
  tripType: EorPerDiemTripType;
  tripDestination: string;
  dateDeparture: string;
  dateReturning: string;
}

export interface IAddEditMileageAdjustmentPayload {
  vehicleType: string | null;
  startingDestination: string;
  endingDestination: string;
  totalDistance: string;
}

// Add adjustment
export interface IAddAdjustmentPayload {
  payrollReportColumnId: string;
  value: string;
  description?: string;
  isRecurring?: boolean;
  s3FileKey?: string;
  fileName?: string;
  fileType?: string;
  adjustmentCategoryId?: string;
  title?: string;
  when?: string;
  adjustmentType?: string;
  originalValue?: number;
  originalCurrency?: string;
  tags?: ('RECURRING' | 'BUSINESS_TRIP')[];
  adjustmentVendor?: string;
  country?: string;
  aboveSalaryClientConfirmed?: boolean;
  mileage?: IAddEditMileageAdjustmentPayload;
  perDiem?: IAddEditPerDiemAdjustmentPayload;
}

export interface IEditAdjustmentPayload {
  payrollReportColumnId: string;
  value: string;
  description: string;
  s3FileKey?: string;
  s3FileId?: string;
  fileName?: string;
  fileType?: string;
  isRecurring?: boolean;
  adjustmentCategoryId?: string;
  title?: string;
  when?: string;
  originalValue?: number;
  originalCurrency?: string;
  tags?: ('RECURRING' | 'BUSINESS_TRIP')[];
  adjustmentVendor?: string;
  country?: string;
  aboveSalaryClientConfirmed?: boolean;
  mileage?: IAddEditMileageAdjustmentPayload;
  perDiem?: IAddEditPerDiemAdjustmentPayload;
}

export interface IUpdateRequireClarificationPayload {
  description?: string;
  s3FileKey?: string;
  fileName?: string;
  fileType?: string;
  skipDocument?: boolean;
}
export interface IBulkAdjustmentItem {
  rowNumber: number;
  oid: string;
  name: string;
  email: string;
  amount: string;
  currency: string;
  overThreshold: boolean;
  salaryMultiplier: number;
  vendorName: string;
  countryOfExpense: string;
  receiptFileName: string;
}

export interface IBusinessTrip {
  id: string;
  departingCountry: string;
  destinationCountry: string;
  startDate: Date;
  endDate: Date;
  name: string;
  reason: string;
}

export interface ICreateMultipleAdjustmentsPayload {
  adjustments: IAdjustment[];
  businessTrip?: IBusinessTrip;
}

export type IAdjustment = IPendingAdjustment | IApprovedAdjustment | IDeclinedAdjustment | IAwaitingPaymentAdjustment;
type AddAdjustmentResponseType = IAdjustment;
const add = (instance: AxiosInstance) => async (contractOid: string, payload: IAddAdjustmentPayload) => {
  return (await instance.post(`/eor-experience/adjustment-requests/${contractOid}`, payload))
    .data as AddAdjustmentResponseType;
};

// Review single adjustment
interface IReviewSingleAdjustmentPayload {
  isApproved: boolean;
  declineReason?: string;
  adjustmentType?: string;
}
type ReviewSingleAdjustmentResponseType = IAdjustment;
const reviewSingle =
  (instance: AxiosInstance) => async (adjustmentRequestId: string, payload: IReviewSingleAdjustmentPayload) => {
    return (await instance.put(`/eor-experience/adjustment-requests/${adjustmentRequestId}/review`, payload))
      .data as ReviewSingleAdjustmentResponseType;
  };

export interface IUpdateAdjustmentStatusPayload {
  isApproved: boolean;
  removeRecurring?: boolean;
  declineReason?: string;
}

const updateStatus =
  (instance: AxiosInstance) => async (adjustmentRequestId: string, payload: IUpdateAdjustmentStatusPayload) => {
    return await instance.put(`/eor-experience/adjustment-requests/${adjustmentRequestId}/update-status`, payload);
  };

// Review multiple adjustments
interface IReviewMultipleAdjustmentsPayload {
  isApproved: boolean;
  adjustmentRequestIds: (string | number)[];
  status?: 'approved'; // can be extended
  declineReason?: string;
}
type ReviewMultipleAdjustmentsResponseType = IAdjustment[];
const reviewMultiple = (instance: AxiosInstance) => async (payload: IReviewMultipleAdjustmentsPayload) => {
  return (await instance.put(`/eor-experience/adjustment-requests/review`, payload))
    .data as ReviewMultipleAdjustmentsResponseType;
};

interface BulkAdjustmentPayload {
  title: string;
  description: string;
  adjustmentType: string;
  adjustmentCategoryId: string;
}

interface IBulkAdjustmentPayload extends BulkAdjustmentPayload {
  file: {
    s3FileKey: string;
    fileName: string;
    fileType: string;
  };
}
interface IBulkAdjustmentExpensePayload extends BulkAdjustmentPayload {
  adjustments: IBulkAdjustments[];
  file: {
    s3FileKey: string;
    fileName: string;
    fileType: string;
  };
}

interface IBulkAdjustments {
  file: {
    s3FileKey: string;
    fileName: string;
    fileType: string;
  };
  employeeId: string;
}

// delete adjustment
type DeleteAdjustmentResponseType = {
  success: true;
};
const remove =
  (instance: AxiosInstance) =>
  async (adjustmentRequestId: string, removeAllRecurring = false) => {
    const url = `/eor-experience/adjustment-requests/${adjustmentRequestId}`;
    let params: Record<string, unknown> | undefined;

    if (removeAllRecurring) params = { removeRecurring: true };
    return (await instance.delete(url, { params })).data as DeleteAdjustmentResponseType;
  };

// edit adjustment
const editAdjustment =
  (instance: AxiosInstance) => async (adjustmentRequestId: string, payload: IEditAdjustmentPayload) => {
    try {
      return (await instance.put(`/eor-experience/adjustment-requests/${adjustmentRequestId}/edit`, payload))
        .data as IAdjustment;
    } catch {
      throw new Error('An error occurred while editing the payment item');
    }
  };

// update adjustment
const updateRequiresClarification =
  (instance: AxiosInstance) => async (adjustmentRequestId: string, payload: IUpdateRequireClarificationPayload) => {
    return (
      await instance.put(
        `/eor-experience/adjustment-requests/${adjustmentRequestId}/update-requires-clarification`,
        payload
      )
    ).data as IAdjustment;
  };

// Mass approval
const getEORMassApprovalContracts =
  (instance: AxiosInstance) => async (params?: { [key: string]: string[] | string }, signal?: AbortSignal) => {
    return (await instance.get('/eor-experience/mass-approve', { params, signal }))
      .data as IGetEORMassApprovalAdjustments;
  };

const getEORMassApprovalContract = (instance: AxiosInstance) => async (contractOid: string) => {
  return (await instance.get(`/eor-experience/mass-approve/${contractOid}`)).data as IEORSingleMassApprovalContract;
};

const getAdjustableTypes = (instance: AxiosInstance) => async () => {
  return (await instance.get('/eor-experience/adjustment-requests/adjustable-types')).data as IAdjustableType[];
};

const checkDuplicates = (instance: AxiosInstance) => async (contractOid: string, payload: any) => {
  return (await instance.post(`/eor-experience/adjustment-requests/${contractOid}/check-duplicates`, payload))
    .data as AddAdjustmentResponseType[];
};

const updateFixedAdjustments = (instance: AxiosInstance) => async (contractId: string, payload: any) => {
  return (await instance.put(`/eor-experience/fixed-adjustments/${contractId}/bulk`, payload)).data as any;
};

const bulkAdjustmentsPreview = (instance: AxiosInstance) => async (s3FileKey: string, fileType: string) => {
  return (
    await instance.post(
      `/eor-experience/adjustment-bulk-upload/preview`,
      { s3FileKey, fileType },
      { suppressSnackbarForStatuses: [400] }
    )
  ).data as IBulkAdjustmentItem[];
};

const bulkAdjustmentsUpload = (instance: AxiosInstance) => async (payload: IBulkAdjustmentPayload) => {
  return (
    await instance.post(`/eor-experience/adjustment-bulk-upload`, payload, { suppressSnackbarForStatuses: [400] })
  ).data as any;
};

const bulkAdjustmentsExpenseUpload = (instance: AxiosInstance) => async (payload: IBulkAdjustmentExpensePayload) => {
  return (
    await instance.post(`/eor-experience/adjustment-bulk-upload/expense`, payload, {
      suppressSnackbarForStatuses: [400],
    })
  ).data as any;
};

interface BulkAdjustmentsValidatePayload {
  contractOidList: string[];
}

const bulkAdjustmentsValidateOid = (instance: AxiosInstance) => async (payload: BulkAdjustmentsValidatePayload) => {
  const { contractOidList } = payload;
  return (
    await instance.get(`/eor-experience/adjustment-bulk-upload/allowed-contracts`, {
      params: { contractOidList },
      suppressSnackbarForStatuses: [400],
    })
  ).data as any;
};

export default {
  add: add(instance),
  reviewSingle: reviewSingle(instance),
  reviewMultiple: reviewMultiple(instance),
  remove: remove(instance),
  edit: editAdjustment(instance),
  getEORMassApprovalContracts: getEORMassApprovalContracts(instance),
  getEORMassApprovalContract: getEORMassApprovalContract(instance),
  getAdjustableTypes: getAdjustableTypes(instance),
  checkDuplicates: checkDuplicates(instance),
  updateStatus: updateStatus(instance),
  updateFixedAdjustments: updateFixedAdjustments(instance),
  updateRequiresClarification: updateRequiresClarification(instance),
  bulkAdjustmentsPreview: bulkAdjustmentsPreview(instance),
  bulkAdjustmentsUpload: bulkAdjustmentsUpload(instance),
  bulkAdjustmentsExpenseUpload: bulkAdjustmentsExpenseUpload(instance),
  bulkAdjustmentsValidateOid: bulkAdjustmentsValidateOid(instance),
};

interface GetAdjustmentsEndpoints {
  contractId?: string | number;
}

export const getAdjustmentsEndpoints = ({ contractId }: GetAdjustmentsEndpoints) => {
  const prefix = '/eor-experience';

  return {
    getAdjustableTypes: `${prefix}/adjustment-requests/adjustable-types`,
    getMassApprovalContracts: `${prefix}/mass-approve`,
    getAdjustments: `${prefix}/adjustment-requests/${contractId}`,
    getFixedAdjustments: `${prefix}/fixed-adjustments/${contractId}`,
  };
};
