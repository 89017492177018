import type { ChangeRequestFieldType } from '@/constants/hrisChangeRequests';
import type { DeepPartial } from '@/types/DeepPartial';
import type { NewDirectEmployeePayload } from '@/types/GlobalPayroll/CreateGlobalPayrollContract';
import instance from '@/utils/api/instance';

interface ChangeRequestsTotalResponse {
  count: number;
  fields: number;
}

export enum ChangeRequestStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export interface ChangeRequestCreator {
  id: number;
  name: string;
  email: string;
}

export type ChangeRequestFieldValue = string | number | { id: number; label: string } | null;

export interface ChangeRequestField {
  id: string;
  name: ChangeRequestFieldType;
  value: ChangeRequestFieldValue;
  previousValue: string | number | null;
  currency?: string;
  createdAt: string;
  updatedAt: string;
  creator: ChangeRequestCreator;
}

export interface ChangeRequestTargetPerson {
  id: string;
  name: string;
  picUrl: string | null;
}

export interface DirectEmployeeChangeRequest {
  status: ChangeRequestStatus;
  hrisProfile: ChangeRequestTargetPerson;
  fields: ChangeRequestField[];
}

interface GetDirectEmployeeChangeRequestResponse {
  activeChangeRequest: DirectEmployeeChangeRequest | null;
}

export type ApproveDirectEmployeeChangeRequestPayload = { hrisProfileId: string; fieldIds?: string[] }[];

export default {
  createDirectEmployeeChangeRequest: async (
    personId: string,
    payload: DeepPartial<NewDirectEmployeePayload>
  ): Promise<DirectEmployeeChangeRequest> => {
    const { data } = await instance.post<DirectEmployeeChangeRequest>(
      `/hris/change_requests/profile/${personId}`,
      payload
    );
    return data;
  },

  getDirectEmployeeChangeRequestTotal: async (): Promise<ChangeRequestsTotalResponse> => {
    const { data } = await instance.get<ChangeRequestsTotalResponse>('/hris/change_requests/total');
    return data;
  },

  getDirectEmployeeChangeRequest: async (personId: string): Promise<DirectEmployeeChangeRequest | null> => {
    const { data } = await instance.get<GetDirectEmployeeChangeRequestResponse>(
      `/hris/change_requests/profile/${personId}`
    );
    return data?.activeChangeRequest || null;
  },

  cancelDirectEmployeeChangeRequest: async (params: {
    fieldIds: string[];
  }): Promise<DirectEmployeeChangeRequest | null> => {
    const { fieldIds } = params;
    const { data } = await instance.delete<DirectEmployeeChangeRequest>(`/hris/change_requests`, {
      data: {
        fieldIds,
      },
    });
    return data.status === ChangeRequestStatus.PENDING ? data : null;
  },

  denyDirectEmployeeChangeRequest: async (params: {
    personId: string;
    fieldIds?: string[];
    comment?: string;
  }): Promise<DirectEmployeeChangeRequest | null> => {
    const { personId, fieldIds, comment } = params;
    const { data } = await instance.put<DirectEmployeeChangeRequest>(`/hris/change_requests/deny`, {
      hrisProfileId: personId,
      fieldIds,
      comment,
    });
    return data.status === ChangeRequestStatus.PENDING ? data : null;
  },

  approveDirectEmployeeChangeRequests: async (
    payload: ApproveDirectEmployeeChangeRequestPayload
  ): Promise<(DirectEmployeeChangeRequest | null)[]> => {
    const { data } = await instance.put<DirectEmployeeChangeRequest[]>(`/hris/change_requests/approve_batch`, payload);
    return data.map((changeRequest) => (changeRequest.status === ChangeRequestStatus.PENDING ? changeRequest : null));
  },
};
