import {
  BulletPointCell,
  Button,
  FeedbackPopupContent,
  Link,
  ListItem,
  Stack,
  useFeedbackPopup,
  useModal,
  useQueryString,
  useTheme,
  H3,
  H4,
} from '@letsdeel/ui';
import healthInsuranceApi from '@/utils/api/benefits/healthInsurance';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFetchUnisureEnrollment } from '../api/eor/healthCare/useFetchUnisureEnrollment';
import { useFetchEmployeeBenefits } from '../api/eor/useFetchEmployeeBenefits';
import useLookupsStore from '../useLookupsStore';
import { isObject } from '@/utils/main';
import { useTranslation } from 'react-i18next';

const UNISURE_FORM_KEY = 'unisure_form_completed';

export enum UnisureFormTaskState {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export type UnisureFormState = {
  isTaskDismissed?: boolean;
  state?: UnisureFormTaskState;
};

interface ContractUnisureFormState {
  [contractOid: string]: UnisureFormState | undefined;
}

export const useUnisureEnrollment = () => {
  const lookups = useLookupsStore();
  const { t } = useTranslation();
  const { data: contractBenefits } = useFetchEmployeeBenefits();
  const { openFeedbackPopup, closeFeedbackPopup } = useFeedbackPopup();
  const [unisureFormState, setUnisureFormState] = useState<ContractUnisureFormState>(() => {
    const storageState = localStorage.getItem(UNISURE_FORM_KEY);
    const contractsFormState = storageState && JSON.parse(storageState);

    return isObject(contractsFormState) ? contractsFormState : {};
  });

  useEffect(() => {
    if (!contractBenefits || Object.keys(unisureFormState).length) return;
    const storageState = localStorage.getItem(UNISURE_FORM_KEY);

    const contractsFormState = contractBenefits.reduce((acc, contractBenefit) => {
      if (!contractBenefit.benefits.find((benefit) => benefit.clientSelectedProvider?.isUnisure)) return acc;

      // This check is to support deprecated value type that users may already have, from previous interaction
      const initialState = storageState === 'true' ? UnisureFormTaskState.COMPLETED : UnisureFormTaskState.NOT_STARTED;

      return { ...acc, [contractBenefit.contractId]: { state: initialState } };
    }, {} as ContractUnisureFormState);

    if (Object.keys(contractsFormState).length) {
      localStorage.setItem(UNISURE_FORM_KEY, JSON.stringify(contractsFormState));
      setUnisureFormState(contractsFormState);
    }
  }, [contractBenefits, unisureFormState]);

  const updateFormTaskState = useCallback((contractOid: string, state: UnisureFormState) => {
    setUnisureFormState((prevState) => {
      const nextState = { ...prevState, [contractOid]: { ...(prevState[contractOid] || {}), ...state } };
      localStorage.setItem(UNISURE_FORM_KEY, JSON.stringify(nextState));
      return nextState;
    });
  }, []);

  const { openModal, closeModal, getState } = useModal();

  const { query, removeQuery } = useQueryString<{ unisure_form: string }>();

  const { unisureContract, triggerText, descriptionText, activeFormState } = useMemo(() => {
    if (!contractBenefits) return {};
    for (const benefitContract of contractBenefits) {
      if (
        unisureFormState[benefitContract.contractId]?.state === UnisureFormTaskState.COMPLETED ||
        benefitContract.contractStatus === 'cancelled'
      )
        continue;
      const unisureProvider = benefitContract.benefits.find((benefit) => benefit.clientSelectedProvider?.isUnisure);
      const isUnisureAwaitingEnrollment =
        unisureProvider && ['AWAITING_CONTRACT_ACTIVATION', 'AWAITING_ENROLLMENT'].includes(unisureProvider.status);
      const isEnrolled = unisureProvider && unisureProvider.status === 'ENROLLED';

      if (isUnisureAwaitingEnrollment && lookups.getFeatureFlag('benefits.showUnisureEnrollmentFlow')) {
        const existingState = unisureFormState[benefitContract.contractId];
        const triggerText =
          !existingState || existingState.state === UnisureFormTaskState.NOT_STARTED ? 'Start' : 'Continue';

        return {
          unisureContract: benefitContract,
          triggerText,
          descriptionText: `${triggerText}${t('tasks.tasks.unisureEnrollmentTask.description')}`,
          activeFormState: existingState,
        };
      } else if (isEnrolled) {
        updateFormTaskState(benefitContract.contractId, { state: UnisureFormTaskState.COMPLETED });
      }
    }

    return {};
  }, [t, contractBenefits, lookups, unisureFormState, updateFormTaskState]);

  const { data: unisureEnrollment } = useFetchUnisureEnrollment({ contractOid: unisureContract?.contractId });

  useEffect(() => {
    if (query.unisure_form && !getState(UNISURE_FORM_KEY)?.isOpen && unisureContract?.contractId) {
      updateFormTaskState(unisureContract.contractId, { state: UnisureFormTaskState.COMPLETED });
      openModal({
        state: { closeButton: false, isOpen: true },
        id: UNISURE_FORM_KEY,
        content: (
          <FeedbackPopupContent
            title="Enrollment form successfully completed"
            text="Unisure team will review the information you provided to complete the process. This process takes one business day. Your membership becomes active once you received your electronic welcoming pack."
            variant="success"
            button={{
              children: 'OK',
              onClick: () => {
                removeQuery('unisure_form');
                closeModal();
              },
            }}
          />
        ),
      });
    }
  }, [
    closeModal,
    getState,
    openModal,
    query.unisure_form,
    removeQuery,
    unisureContract?.contractId,
    updateFormTaskState,
  ]);

  const redirectToUnisureForm = useCallback(async () => {
    if (!unisureEnrollment || !unisureContract?.contractId) return;
    try {
      updateFormTaskState(unisureContract.contractId, { state: UnisureFormTaskState.IN_PROGRESS });
      await healthInsuranceApi.sendUnisureFormEnrollment(unisureEnrollment?.url, unisureEnrollment?.body);
    } catch (error) {
      updateFormTaskState(unisureContract.contractId, { state: UnisureFormTaskState.NOT_STARTED });
      console.error(error);
    }
  }, [unisureContract?.contractId, unisureEnrollment, updateFormTaskState]);

  const theme = useTheme();

  const openEnrollmentForm = useCallback(async () => {
    if (
      unisureContract?.contractId &&
      unisureFormState[unisureContract.contractId]?.state !== UnisureFormTaskState.NOT_STARTED
    ) {
      await redirectToUnisureForm();
      return;
    }

    openFeedbackPopup({
      title: 'About Unisure Health Insurance',
      text: (
        <Stack spacing={3}>
          <H3 style={{ color: theme.palette.text.disabled }}>
            {
              "Health benefits via Unisure help with cover for doctor's visits, in-patient, and out-patient costs. \nEnroll by following the below steps:"
            }
          </H3>
          <BulletPointCell ordered>
            {[
              'Add Health insurance details',
              'Review & confirm the organization’s selection, Enrollment details, and terms and conditions',
              'Add additional information about an emergency contact next of kin',
              'Answer on medical questionnaire',
            ].map((step) => (
              <ListItem style={{ backgroundColor: theme.palette.neutral?.lighter }} key={step}>
                <H4 regular>{step}</H4>
              </ListItem>
            ))}
          </BulletPointCell>
        </Stack>
      ),
      closeButton: true,
      buttons: [],
      feedbackFooter: (
        <Button
          fullWidth
          onClick={async () => {
            closeFeedbackPopup();
            await redirectToUnisureForm();
          }}
        >
          Start enrollment
        </Button>
      ),
      variant: 'info',
    });
  }, [
    closeFeedbackPopup,
    openFeedbackPopup,
    redirectToUnisureForm,
    theme.palette.neutral?.lighter,
    theme.palette.text.disabled,
    unisureContract?.contractId,
    unisureFormState,
  ]);

  const DismissTaskTrigger = useCallback(
    ({ onClick }: { onClick: () => Promise<void> }) => (
      <Link
        onClick={() => {
          if (!unisureContract?.contractId) return;
          openFeedbackPopup({
            onClick: async () => {
              updateFormTaskState(unisureContract.contractId, { isTaskDismissed: true });
              closeFeedbackPopup();
              await onClick();
            },
            title: 'You can still complete enrollment with Unisure via the Benefits Tab',
            variant: 'info',
          });
        }}
      >
        {"Don't remind me again"}
      </Link>
    ),
    [closeFeedbackPopup, openFeedbackPopup, unisureContract?.contractId, updateFormTaskState]
  );

  const getFormState = useCallback(
    (contractId?: string) => (contractId && unisureFormState[contractId]) || undefined,
    [unisureFormState]
  );

  return {
    unisureEnrollment,
    openEnrollmentForm,
    DismissTaskTrigger,
    activeFormState,
    getFormState,
    triggerText,
    descriptionText,
  };
};
