 
export const DATE_FNS_DATE_HOUR_FORMAT = 'MMM do, yyyy, h:mm a';
export const DATE_FNS_DATE_ONLY = 'MM/dd/yyyy';
export const DATE_FNS_FORMAT = 'MMM do yyyy';
export const DATE_FNS_FORMAT_DATE = 'MMM do';
export const DATE_FNS_FORMAT_DATE_ONLY = 'yyyy-MM-dd';
export const DATE_FNS_FORMAT_MONTH_ONLY = 'LLL';
export const DATE_FNS_HOUR_MINUTE_FORMAT = 'HH:mm';
export const DATE_FNS_HOUR_MINUTE_FORMAT_AM_PM = 'hh:mm a';
export const DATE_FNS_HOUR_MINUTE_SECONDS_FORMAT = 'HH:mm:ss';
export const DATE_FNS_PLAIN_DATE_AMERICAN_FORMAT = 'MM-dd-yyyy';
export const DATE_FNS_WEEK_MONTH_DAY_YEAR_FORMAT = 'EEE MMM dd, yyyy';
export const DATE_FNS_DAY_DATE_MONTH_YEAR_FORMAT = 'EEE, dd MMM yyyy';

export const MOMENT_24_HOUR_MINUTE_FORMAT = 'HH:mm';
export const MOMENT_DATE_FORMAT = 'MMM Do, YYYY';
export const MOMENT_FORMAT_DATE_ONLY = 'YYYY-MM-DD';
 
