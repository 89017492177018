import { getKey } from '@/hooks/api/peo/useFetchContractMissingUpdates';
import type {
  DocsCollectionFileType,
  DocsCollectionFileUploadResponse,
  PatchPeoDocsCollectionFormPayload,
  StateComplianceResponse,
} from '@/scenes/PeoDocsCollection/types';
import type { CensusCompanyResponse, CensusCompanyValues } from '@/scenes/PeoGHQ/types';
import {
  type CensusPeople,
  type CensusPeopleForm,
  type CensusPeopleListResponse,
  type CensusResponseBody,
  type CensusTemplateFileResponse,
  type PatchPeoGHQFormPayload,
  type PatchPeoGHQFormResponse,
  type PeoGHQForm,
  type SignatureResponse,
  type SignatureValues,
  type UploadCensusCSVValues,
} from '@/scenes/PeoGHQ/types';
import type {
  PatchPeoRiskUnderwritingFormPayload,
  PatchPeoRiskUnderwritingFormResponse,
  SignArbitrationAgreementResponse,
  SignRiskUnderwritingFormPayload,
} from '@/scenes/PeoRiskUnderwriting/types';
import type { PeoContractTermination } from '@/scenes/People/PersonView/Offboarding/utils';
import type {
  ContractMissingUpdatesData,
  ContractMissingUpdatesType,
  CreateJobTitlePayload,
  CreateJobTitleResponse,
  GHQSDocumentsResponse,
  PEOBenefitWindow,
  PEONotificationType,
  PEONotificationVariant,
  PeoAdditionalDetailsPayload,
  PeoApplicationPayload,
  PeoContractDetails,
  PeoContractFieldPayload,
  PeoContractRequirementsResponse,
  PeoCreateContractPayload,
  PeoDocumentFieldsToValidate,
  PeoDocumentResponse,
  PeoGHQSubmitDocumentPayload,
  PeoSubmitFilePayload,
  SignArbitrationAgreementPayload,
  SignEmployeeCommunicationsPayload,
  UpdateDraftAgreementsPayload,
  UpdateEmployeeCommunicationsProfilePayload,
} from '@/types/PEO';
import instance from '@/utils/api/instance';
import type { AxiosResponse } from 'axios';

export default {
  createContract: async (payload: PeoCreateContractPayload) => {
    const { data } = await instance.post('peo_integration/create_contract', payload);
    return data;
  },

  addAdditionalDetails: async (payload: PeoAdditionalDetailsPayload) => {
    const { data } = await instance.patch('profiles/me', payload);
    return data;
  },

  getContractDetails: async (contractOid: string): Promise<PeoContractDetails> => {
    const { data } = await instance.get(`peo_integration/contract/${contractOid}/details`);
    return data;
  },

  getContractMissingUpdates: async (contractOid: string, type?: ContractMissingUpdatesType) => {
    const url = getKey(contractOid, type);
    if (!url) throw new Error('Invalid contractOid provided');

    const { data } = await instance.get<ContractMissingUpdatesData>(url);

    return data;
  },

  getElectronicAgreementPdf: async () => {
    const { data } = await instance.get<PeoDocumentResponse | undefined>(
      'peo_integration/compliance_documents/signup/electronic_agreement'
    );
    return data;
  },

  getDocuments: async (contractId?: string) => {
    const { data } = await instance.get<PeoContractRequirementsResponse | undefined>(
      `peo_integration/contract/requirements${contractId ? `/${contractId}` : ''}`
    );
    return data;
  },

  submitFile: async (payload: PeoSubmitFilePayload) => {
    const { data } = await instance.post('peo_integration/compliance_documents/submit_file', payload);
    return data;
  },

  getGroupHealthQuestionnaireValues: async (legalEntityId: string) => {
    const { data } = await instance.get<PeoGHQForm | undefined>(
      `peo_integration/group_health_questionnaire/${legalEntityId}`
    );
    return data;
  },

  updateGroupHealthQuestionnaireValues: async (legalEntityId: string, payload: PatchPeoGHQFormPayload) => {
    const { data } = await instance.patch<PatchPeoGHQFormResponse | undefined>(
      `peo_integration/group_health_questionnaire/${legalEntityId}`,
      payload,
      { suppressSnackbar: true }
    );
    return data;
  },

  submitGroupHealthQuestionnaireValues: async (legalEntityId: string) => {
    const { data } = await instance.post(
      `/peo_integration/group_health_questionnaire/${legalEntityId}/submit`,
      undefined,
      { suppressSnackbar: true }
    );
    return data;
  },

  getBlankCensusFileTemplate: async () => {
    const { data } = await instance.get<CensusTemplateFileResponse | undefined>(
      'peo_integration/group_health_questionnaire/census/blank_template',
      {
        suppressSnackbar: true,
      }
    );
    return data;
  },

  getFilledCensusFileTemplate: async () => {
    const { data } = await instance.get<CensusTemplateFileResponse | undefined>(
      'peo_integration/group_health_questionnaire/census/filled_template',
      {
        suppressSnackbar: true,
      }
    );
    return data;
  },

  verifyCensusFile: async (legalEntityId: string, file: File) => {
    const form = new FormData();
    form.append('file', file);
    const { data } = await instance.post(
      `peo_integration/group_health_questionnaire/${legalEntityId}/census/verify`,
      form,
      { suppressSnackbarForStatuses: [400] }
    );
    return data;
  },

  importCensusFile: async (legalEntityId: string, file: File) => {
    const form = new FormData();
    form.append('file', file);
    const { data } = await instance.post(
      `peo_integration/group_health_questionnaire/${legalEntityId}/census/import`,
      form,
      { suppressSnackbarForStatuses: [400] }
    );
    return data;
  },

  submitCensusFile: async (
    legalEntityId: string,
    payload: {
      document: { uuid: string; filename: string };
    }
  ): Promise<{ id?: string }> => {
    const { data } = await instance.post(`peo_integration/group_health_questionnaire/${legalEntityId}/census`, payload);
    return data;
  },

  getCensusFile: async (legalEntityId: string): Promise<UploadCensusCSVValues['censusFile'] | undefined> => {
    const { data }: AxiosResponse<CensusResponseBody> = await instance.get(
      `peo_integration/group_health_questionnaire/${legalEntityId}/census`
    );
    if (!Array.isArray(data)) {
      // invalid/unexpected data
      console.error(data);
    } else if (data[0]) {
      return {
        publicId: data[0].id,
        uuid: data[0].peoFile.filenames[0].uuid,
        filename: data[0].peoFile.filenames[0].filename,
      };
    }

    return undefined;
  },

  deleteCensusFile: async (legalEntityId: string, publicId: string) => {
    const { data } = await instance.delete(
      `peo_integration/group_health_questionnaire/${legalEntityId}/census/${publicId}`
    );
    return data;
  },

  deleteCensusData: async (legalEntityId: string) => {
    const { data } = await instance.delete(`peo_integration/group_health_questionnaire/${legalEntityId}/census`);
    return data;
  },

  getGHQDocuments: async (legalEntityId: string) => {
    const { data } = await instance.get<GHQSDocumentsResponse>(
      `peo_integration/group_health_questionnaire/${legalEntityId}/proposal_request_file`
    );
    return data;
  },

  postGHQDocument: async (payload: PeoGHQSubmitDocumentPayload, legalEntityId: string) => {
    const { data } = await instance.post<{ id: string }>(
      `peo_integration/group_health_questionnaire/${legalEntityId}/proposal_request_file`,
      payload
    );
    return data;
  },

  deleteGHQDocument: async (legalEntityId: string, documentId: string, fileKey: string) => {
    const { data } = await instance.delete(
      `peo_integration/group_health_questionnaire/${legalEntityId}/proposal_request_file/${documentId}/document/${fileKey}`
    );
    return data;
  },

  getGHQDocumentDownloadUrl: async (peoFileId: string, fileKey: string) => {
    const { data } = await instance.get<{ url: string }>(
      `/peo_integration/peo_file/${peoFileId}/document/${fileKey}/url`
    );
    return data;
  },

  signGHQForm: async (legalEntityId: string, payload: SignatureValues) => {
    const { data } = await instance.post(`peo_integration/group_health_questionnaire/${legalEntityId}/sign`, payload);
    return data;
  },

  getGHQSignature: async (legalEntityId: string): Promise<SignatureValues> => {
    const { data }: { data: SignatureResponse } = await instance.get(
      `peo_integration/group_health_questionnaire/${legalEntityId}/sign`
    );
    return { signature: data?.signature?.signature || '' };
  },

  markWseNoticeAsRead: async () => {
    const { data } = await instance.post('peo_integration/peo_documents/wse_notice/acknowledge');
    return data;
  },

  signArbitrationAgreement: async (payload: SignArbitrationAgreementPayload) => {
    const { data } = await instance.post('peo_integration/peo_documents/arbitration_agreement/sign', payload);
    return data;
  },

  signEmployeeCommunicationsConsent: async (payload: SignEmployeeCommunicationsPayload) => {
    const { data } = await instance.post('peo_integration/peo_documents/employee_comms_consent/sign', payload);
    return data;
  },

  updateEmployeeCommunicationsConsentProfile: async (payload: UpdateEmployeeCommunicationsProfilePayload) => {
    const { data } = await instance.patch('peo_integration/peo_documents/employee_comms_consent/profile', payload);
    return data;
  },

  getEmployeeDocumentDownloadUrl: async (
    publicId: string,
    uuid: string,
    contractOid: string,
    otp?: string,
    otpProvider?: string
  ) => {
    const url = `/peo_integration/peo_file/${publicId}/document/${uuid}/contract/${contractOid}/url`;
    const { data } = await instance.get<{ url: string }>(
      otp && otpProvider ? `${url}?code=${otp}&otpProvider=${otpProvider}` : url
    );
    return data;
  },

  getTerminatePeoEmployee: async (contractId: string): Promise<PeoContractTermination> => {
    const { data } = await instance.get(`peo_integration/employee_termination/${contractId}`);
    return data;
  },

  terminatePeoEmployee: async (
    contractId: string,
    payload: PeoContractTermination
  ): Promise<PeoContractTermination> => {
    const { data } = await instance.post(`peo_integration/employee_termination/${contractId}`, payload);
    return data;
  },

  // TODO move it to other api when it becomes more generic
  validateDocument: async (
    contractId: string,
    payload: Partial<PeoDocumentFieldsToValidate>
  ): Promise<PeoContractFieldPayload[]> => {
    const { data } = await instance.post(
      `peo_integration/compliance_documents/data_validator?contractOid=${contractId}`,
      payload
    );
    return data;
  },

  updateContract: async (contractId: string, payload: Partial<PeoDocumentFieldsToValidate>): Promise<null> => {
    const { data } = await instance.patch(
      `peo_integration/compliance_documents/employee/update_data?contractOid=${contractId}`,
      payload
    );
    return data;
  },

  updatePeoEntity: async (entityPublicId: string, isPeo: boolean): Promise<void> => {
    return instance.patch(`/legal_entities/${entityPublicId}`, { isPeo });
  },

  updatePeoApplication: async (organizationId: number, payload: PeoApplicationPayload): Promise<void> => {
    return instance.patch(`peo_integration/organizations/${organizationId}`, payload);
  },

  compliance: {
    i9: {
      approve: async (contractOid: string) => {
        await instance.patch('/compliance/i9/peo/approve', { contractOid });
      },
      reject: async (contractOid: string, rejectionReason: string) => {
        await instance.patch('/compliance/i9/peo/reject', { contractOid, rejectionReason });
      },
    },
  },

  getRiskUnderwritingValues: async (legalEntityPublicId: string) => {
    const { data } = await instance.get<PatchPeoRiskUnderwritingFormResponse | undefined>(
      `peo_integration/risk_underwriting/${legalEntityPublicId}`,
      { suppressSnackbar: true }
    );
    return data;
  },

  updateRiskUnderwritingValues: async (legalEntityPublicId: string, payload: PatchPeoRiskUnderwritingFormPayload) => {
    const { data } = await instance.patch<PatchPeoRiskUnderwritingFormResponse | undefined>(
      `peo_integration/risk_underwriting/${legalEntityPublicId}`,
      payload,
      { suppressSnackbar: true }
    );
    return data;
  },

  deleteRUFile: async (peoLegalEntityId: string, riskUnderwritingFileId: string, documentId: string) => {
    return instance.delete(
      `peo_integration/risk_underwriting/${peoLegalEntityId}/file/${riskUnderwritingFileId}/document/${documentId}`
    );
  },

  signRiskUnderwritingForm: async (legalEntityPublicId: string, payload: SignRiskUnderwritingFormPayload) => {
    const { data } = await instance.post<SignArbitrationAgreementResponse | undefined>(
      `peo_integration/risk_underwriting/${legalEntityPublicId}/sign`,
      payload,
      { suppressSnackbar: true }
    );
    return data;
  },

  submitRiskUnderwritingForm: async (legalEntityPublicId: string) => {
    return instance.post<void>(`peo_integration/risk_underwriting/${legalEntityPublicId}/submit`);
  },

  createJobTitle: (entityPublicId: string, payload: CreateJobTitlePayload) => {
    return instance.post<CreateJobTitleResponse>(
      `peo_integration/peo_legal_entity/${entityPublicId}/create_position`,
      payload
    );
  },

  postCensusPeople: async (legalEntityId: string, payload: CensusPeopleForm) => {
    return instance.post<CensusPeople>(
      `peo_integration/group_health_questionnaire/${legalEntityId}/census-employee`,
      payload,
      { suppressSnackbar: true }
    );
  },

  patchCensusPeople: async (legalEntityId: string, personId: string, payload: Partial<CensusPeopleForm>) => {
    return instance.patch<CensusPeople>(
      `peo_integration/group_health_questionnaire/${legalEntityId}/census-employee/${personId}`,
      payload,
      { suppressSnackbar: true }
    );
  },

  deleteCensusPeople: async (legalEntityId: string, personId: string) => {
    return instance.delete<CensusPeople>(
      `peo_integration/group_health_questionnaire/${legalEntityId}/census-employee/${personId}`,
      { suppressSnackbar: true }
    );
  },

  fetchCensusPeople: async (legalEntityId: string, lastIndex = 0, pageSize = 10) => {
    return instance.get<CensusPeopleListResponse>(
      `peo_integration/group_health_questionnaire/${legalEntityId}/census-employee?limit=${pageSize}&lastIndex=${lastIndex}`
    );
  },

  fetchCensusPerson: async (legalEntityId: string, personId: string) => {
    return instance.get<CensusPeople>(
      `peo_integration/group_health_questionnaire/${legalEntityId}/census-employee/${personId}`
    );
  },

  fetchAllCensusActiveEmployees: async (legalEntityId: string) => {
    return instance.get<CensusPeopleListResponse>(
      `peo_integration/group_health_questionnaire/${legalEntityId}/census-employee?activeDependentOrCobra=ACTIVE`
    );
  },

  postCensusCompany: async (legalEntityId: string, payload: CensusCompanyValues) => {
    return instance.post<CensusCompanyResponse>(
      `peo_integration/group_health_questionnaire/${legalEntityId}/census-company`,
      payload,
      { suppressSnackbar: true }
    );
  },

  deleteCensusCompany: async (legalEntityId: string) => {
    return instance.delete<CensusCompanyResponse>(
      `peo_integration/group_health_questionnaire/${legalEntityId}/census-company`
    );
  },

  getGarnishmentDocDownloadUrl: async (peoFilePublicId: string, uuid: string, contractOid: string) => {
    const { data } = await instance.get<{ url: string }>(
      `/peo_integration/garnishment/file/${peoFilePublicId}/document/${uuid}/contract/${contractOid}/url`
    );
    return data;
  },

  signCSASupportingDocument: async (contractId: string, clientSignature: string) => {
    return instance.post(`peo_integration/contract/${contractId}/csa_supporting_document/sign`, { clientSignature });
  },

  signPayrollAgreement: async (entityPublicId: string, clientSignature: string) => {
    return instance.post(`peo_integration/peo_legal_entity/${entityPublicId}/auto_payroll_agreement/sign`, {
      clientSignature,
    });
  },

  dismissPEONotification: async (variant: PEONotificationVariant, id: string, type: PEONotificationType) => {
    return instance.post(`peo_integration/notification/${variant}/${id}/type/${type}/dismiss`, undefined, {
      suppressSnackbar: true,
    });
  },
  deleteDocsCollectionFile: async (legalEntityId: string, peoEntityFilePublicId: string) => {
    return instance.delete(`peo_integration/peo_file/${legalEntityId}/file/${peoEntityFilePublicId}`);
  },

  getDocsCollectionFiles: async (legalEntityId: string, fileTypes?: DocsCollectionFileType[]) => {
    const qs = fileTypes ? `?fileType[]=${fileTypes.join('&')}` : '';
    return instance.get<DocsCollectionFileUploadResponse[]>(`peo_integration/peo_file/${legalEntityId}/file${qs}`);
  },

  getDocsCollectionStateFiles: async (legalEntityId: string) => {
    return instance.get<StateComplianceResponse>(`/peo_integration/peo_file/${legalEntityId}/state-files`);
  },

  patchDocsCollectionStateForm: async (legalEntityId: string, values: PatchPeoDocsCollectionFormPayload) => {
    return instance.patch(`/peo_integration/peo_legal_entity/${legalEntityId}/state-compliance-questionnaire`, values);
  },

  submitDocsCollectionForm: async (legalEntityId: string) => {
    return instance.post(`peo_integration/peo_file/${legalEntityId}/finish_required_documents_submission`);
  },

  updateDraftAgreements: async (contractId: string, payload: UpdateDraftAgreementsPayload) => {
    return instance.post(`peo_integration/hris_integration/contract/${contractId}/update`, payload, {
      suppressSnackbar: true,
      hideErrorMessageOnNetworkError: true,
    });
  },

  deleteTerminationAdditionalValues: async (itemId: string) =>
    instance.delete(`/peo_integration/employee_termination/additional_payments/${itemId}`, {
      suppressSnackbar: true,
      hideErrorMessageOnNetworkError: true,
    }),

  fetchBenefitWindows: async (contractOid: string) => {
    return instance.get<PEOBenefitWindow[] | null>(`/peo_integration/benefits/benefit_windows/${contractOid}`);
  },
};
