import i18n from 'i18next';
import AppStore from '@/stores/AppStore';
import { useMediaQuery, useTheme } from '@letsdeel/ui';
import { useEffect } from 'react';

class Zendesk {
  showState = true;
  isAdmin = false;

  async login({
    jwt,
    profileId,
    email,
    name,
    profileType,
    riskScore,
    tags,
    country,
    accountCustomerTier,
    accountCsmSegment,
    countries,
  }) {
    try {
      const service = await this.tryInit();
      switch (service) {
        case 'cohere':
          window.CohereInit();

          window.Cohere.identify(profileId, {
            displayName: name,
            firstName: name.split(' ')[0],
            email: email,
            user_role: profileType,
            country,
            token: jwt,
            riskScore,
            tags: tags ? tags.map(({ Tag }) => (Tag ? Tag.name : '')) : '',
            accountCustomerTier,
            accountCsmSegment,
            countries,
          });

          if (this.showState) {
            await this.show();
          }
          break;
        case 'smooch':
          await window.Smooch.login(profileId, jwt);
          await window.Smooch.updateUser({ email, givenName: name });
          document.getElementById('web-messenger-container').classList.add('fadeInUp');
          setTimeout(() => document.getElementById('web-messenger-container').classList.remove('fadeInUp'), 500);
          break;
        default:
          return;
      }
    } catch (err) {
      console.error(err);
    }
  }

  async logout() {
    try {
      const service = await this.tryInit();
      switch (service) {
        case 'smooch':
          await window.Smooch.logout();
          break;
        case 'cohere':
          await window.Cohere.logout();
          break;
        default:
          return;
      }
    } catch (e) {
      console.error(e);
    }
  }

  async tryInit() {
    if (window.Cohere) return 'cohere';
    if (window.Smooch || window.SmoochInitPromise) {
      await window.SmoochInitPromise;
      return 'smooch';
    }
    return false;
  }

  async open() {
    try {
      const service = await this.tryInit();
      switch (service) {
        case 'smooch':
          window.Smooch.open();
          break;
        case 'cohere':
          window.Cohere?.openChatWindow();
          break;
        default:
          return;
      }
    } catch (e) {
      AppStore.setErrorMessage(
        this.isAdmin
          ? i18n.t('temp.unknown.utils.Zendesk.js.1c900a38dd')
          : i18n.t('temp.unknown.utils.Zendesk.js.3ad5bacc0c')
      );
    }
  }

  setIsAdmin(isAdmin) {
    this.isAdmin = isAdmin;
  }

  async close() {
    const service = await this.tryInit();
    switch (service) {
      case 'smooch':
        window.Smooch.close();
        break;
      default:
        return;
    }
  }

  waitForCohereToLoad = async (querySelector, timeout = 0) => {
    const startTime = new Date().getTime();
    return new Promise((resolve, reject) => {
      const timer = setInterval(() => {
        const now = new Date().getTime();
        if (document.getElementById(querySelector)) {
          const cohereOverlay = document.getElementById(querySelector);

          const darkIcon = 'https://s3.amazonaws.com/media.letsdeel.com/zendesk/Chat-Bubble-black@3x.png';
          const lightIcon = 'https://s3.amazonaws.com/media.letsdeel.com/zendesk/Chat-Bubble@3x.png';

          const sheet = new CSSStyleSheet();
          sheet.replaceSync(
            `:host,:host>* {z-index: 1001 !important;} .cohere-generic-pill-styles{bottom: calc(27px + env(safe-area-inset-bottom) + 0px); right: calc(10px); border-radius: 50%;box-sizing:border-box;position:relative;} .cohere-pill-button { background-color: ${
              zendesk.theme === 'dark' ? '#f2f2f2' : '#3f3f3f'
            } !important; background-image: url(${
              zendesk.theme === 'dark' ? darkIcon : lightIcon
            }) !important; box-shadow: 0 2px 5px #00000042; background-repeat: no-repeat !important; background-position: center; background-size: 37px; height: 64px; width: 64px; display: flex; align-items:center; justify-content: center; box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);} .cohere-pill-button:hover {transition: all 0.3s ease 0s important!; filter: brightness(0.7) !important; } .cohere-pill-button svg { display: none !important; }`
          );
          const elemStyleSheets = cohereOverlay.shadowRoot.adoptedStyleSheets;
          cohereOverlay.shadowRoot.adoptedStyleSheets = [...elemStyleSheets, sheet];

          clearInterval(timer);
          window.Cohere.widget('show');
          resolve();
        } else if (timeout && now - startTime >= timeout) {
          clearInterval(timer);
          reject();
        }
      }, 1000);
    });
  };

  async show() {
    this.showState = true;
    if ((await this.tryInit()) === 'cohere') {
      await this.waitForCohereToLoad('cohere-overlay');
    }
  }

  async hide() {
    this.showState = false;
    if ((await this.tryInit()) === 'cohere') {
      window.Cohere.widget('hide');
    }
  }

  async loadConversation(id) {
    const service = await this.tryInit();
    switch (service) {
      case 'smooch':
        await window.Smooch.loadConversation(id);
        break;
      default:
        return;
    }
  }

  async createNewConversation() {
    const service = await this.tryInit();
    switch (service) {
      case 'smooch':
        return await window.Smooch.createConversation();
      default:
        return;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async sendMessage(message) {
    return this.open(); // for now we are just opening the chat window, see ONB-283 for details
    /*
    if (!(await this.tryInit())) return;
    const newConversation = await this.createNewConversation();
    const conversationId = newConversation.id;
    await this.loadConversation(conversationId);
    this.open();
    window.Smooch.sendMessage(message, conversationId);
     */
  }
}

export const optimizeZendeskArticleMarkup = (html) => {
  html = html.replace(/<(p|h1|h2)[^>]*>(&nbsp;|\u00a0| )+<\/\1>/gi, '');
  html = html.replace(/<(p|h1|h2)>([\S]*<br ?\/?>[\S]*)+<\/\1>/is, '');
  html = html.replace(/<table[\s\S]+?>([\s\S]+?)<\/table>/gi, (tableHTML) => {
    const rows = [...tableHTML.matchAll(/<td[\s\S]+?>([\s\S]+?)<\/td>/gi)];
    if (rows?.length === 1) return `<div class="note">${rows[0][1]}</div>`;
    if (tableHTML.match(/<table[^>]*border=/i)) {
      if (tableHTML.match(/<table[^>]*class=/i))
        tableHTML = tableHTML.replace(/(<table[^>]*class=["']?)/i, '$1with-border ');
      else tableHTML = tableHTML.replace(/(<table )/i, '$1class="with-border"');
    }
    return tableHTML;
  });

  html = html.replace(
    /<div ([^>]*)id="article-([a-zA-Z]+)"([^>]*)>/g,
    '<div $1$3 class="option-text-block article-default article-default-$2">'
  );
  html = html.replace(/<script>([\s\S]*?)<\/script>/gi, '');
  html = html.replace(
    /<div [^<]+<img [^>]*zendesk\/Info.png[^>]+>\s*<div>(.+?)<\/div>\s*<\/div>/gs,
    '<div class="info-note">$1</div>'
  );

  html = html.replace(/<form([\s\S]*?)<\/form>/g, (item) => {
    try {
      const domElement = document.createElement('DIV');
      domElement.innerHTML = item;
      const select = domElement.querySelector('select');
      if (select) {
        const label = domElement.querySelector('label')?.innerHTML;
        const options = [];
        for (const option of domElement.querySelectorAll('OPTION')) {
          options.push({ value: option.getAttribute('value')?.toLowerCase(), label: option.innerText });
        }

        const replace = document.createElement('DIV');
        replace.className = 'inner-select';
        replace.setAttribute('start', JSON.stringify({ code: select.id, options, label }));
        return replace.outerHTML;
      }
    } catch (e) {
      console.error(e);
    }

    return '';
  });

  return html;
};

const zendesk = new Zendesk();

export const ChatComponent = () => {
  const isTablet = useMediaQuery(() => '(max-width: 767px)');
  const theme = useTheme();
  zendesk.theme = theme.palette.mode;

  useEffect(() => {
    if (isTablet) {
      zendesk.hide();
    } else {
      zendesk.show();
    }
  }, [isTablet]);

  useEffect(() => {
    zendesk.show();
  }, [theme.palette.mode]);
  return null;
};

export default zendesk;
