import i18n from 'i18next';
import { Alert, CellValue, Flag, MenuItem, Select, Stack, VStack, H4 } from '@letsdeel/ui';
import type { FormikBag, FormikHandlers } from 'formik';
import { useFetchPhoneNumbers } from '@/components/ResetAuthenticator/useFetchPhoneNumbers';
import { useEffect } from 'react';

interface Props {
  getFieldProps: FormikHandlers['getFieldProps'];
  setFieldValue: FormikBag<any, any>['setFieldValue'];
  setFieldTouched: FormikBag<any, any>['setFieldTouched'];
}

export const fieldName = 'phoneNumber';
const ChoosePhoneNumber = ({ getFieldProps, setFieldValue, setFieldTouched }: Props) => {
  const { data: phoneNumbers } = useFetchPhoneNumbers();

  useEffect(() => {
    if (phoneNumbers?.length === 1) {
      setFieldTouched(fieldName, true);
      setFieldValue(fieldName, phoneNumbers[0].number);
    }
    return () => {
      setFieldTouched(fieldName, false);
      setFieldValue(fieldName, null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumbers?.length]);

  if (!phoneNumbers) return null;

  return (
    <VStack spacing={24}>
      <Alert>{i18n.t('temp.unknown.components.ResetAuthenticator.b9b3c22163').toString()}</Alert>
      {phoneNumbers?.length > 1 ? (
        <Select
          {...getFieldProps(fieldName)}
          onChange={(e) => {
            setFieldTouched(fieldName, true);
            setFieldValue(fieldName, e.target.value);
          }}
          label={i18n.t('temp.unknown.components.ResetAuthenticator.5e707c03bd').toString()}
        >
          {phoneNumbers?.map((option) => (
            <MenuItem key={option.number} value={option.number}>
              {option.number}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <CellValue label={i18n.t('temp.unknown.components.ResetAuthenticator.1f8261d174').toString()}>
          <Stack direction="row" spacing={1} alignItems="center">
            <H4>{phoneNumbers?.[0].number}</H4>
            <Flag country={phoneNumbers?.[0].country} size={24} />
          </Stack>
        </CellValue>
      )}
    </VStack>
  );
};

export default ChoosePhoneNumber;
