import type { IUserStore } from '@/types/User';
import instance from '@/utils/api/instance';
import omitBy from 'lodash/omitBy';

export interface IOrganization {
  id: number;
}

export interface ITeam {
  id: number;
  name: string;
  picUrl: string | null;
  organization: IUserStore['organization'];
  approvesRequired: number | null;
  isHrisApproveEnabled: boolean;
  teamPublicId: string;
}

export interface TeamMember {
  id: number;
  email: string;
  name: string;
  username: string;
  roleId: number;
  approver: boolean;
  adjuster: boolean;
  hrisApprover: boolean;
  picUrl: string;
  owner: boolean;
  profilePublicId: string;
}
export interface ITeamWithDetails extends ITeam {
  members: TeamMember[];
}

export type RestoreGroupPayload = {
  teamId: string;
  addOrganizationOwnersAsTeamAdmins?: boolean;
  newName?: string;
};

interface AssignTeamBody {
  profileId: number;
  permissions: {
    teamId: number;
    roleId: number;
  }[];
}

export default {
  create: async (name: string, company: any, options?: Record<string, any>, config = {}) => {
    const { data } = await instance.post('team', { name, company, ...(options || {}) }, { ...config });
    return data;
  },

  getAll: async () => {
    const { data } = await instance.get('team', { withCredentials: true });
    return data;
  },

  autopilot: async (id: string, payload: any) => {
    const { data } = await instance.post(`team/${id}`, payload);
    return data;
  },

  deleteAvatar: async (id: string) => {
    const { data } = await instance.delete(`team/${id}/avatar`);
    return data;
  },

  getTeam: async (id: string) => {
    const { data } = await instance.get(`team/${id}`);
    return data;
  },

  deleteTeam: async (id: string) => {
    const { data } = await instance.delete(`team/${id}`);
    return data;
  },

  restoreTeam: async (payload: RestoreGroupPayload) => {
    const { data } = await instance.post(`team/restore`, payload);
    return data;
  },

  leave: async (id: string) => {
    const { data } = await instance.post(`team/${id}/leave`);
    return data;
  },

  switch: async (id: string | number) => {
    const { data } = await instance.post(`team/${id}/switch`, {}, { withCredentials: true });
    return data;
  },

  update: async (id: string | number, name: string, company?: any, invoiceReviewDays?: any) => {
    const { data } = await instance.post(`team/${id}`, { name, company, invoiceReviewDays });
    return data;
  },

  updateRaw: async (id: string | number, body: any) => {
    const { data } = await instance.post(`team/${id}`, body);
    return data;
  },

  patch: async (id: string | number, body: any) => {
    const { data } = await instance.patch(`team/${id}`, body);
    return data;
  },

  inviteManager: async (body: any) => {
    const { data } = await instance.post(`team/invite-manager`, body);
    return data;
  },

  assignTeam: async (body: AssignTeamBody) => {
    const { data } = await instance.post(`team/assign-manager`, body);
    return data;
  },

  inviteOrgOwner: async (email: string, suppressSnackbar = true) => {
    const { data } = await instance.post(
      '/team/invite-org-owner',
      { email },
      {
        suppressSnackbar,
      }
    );
    return data;
  },

  updateAvatar: async (id: string, avatar: any) => {
    const { data } = await instance.post(`team/${id}`, { avatar });
    return data;
  },

  getSchedule: async (id: string) => {
    const { data } = await instance.get(`/team/${id}/schedules`);
    return data;
  },

  member: {
    save: async (teamId: string | string, updatedMember: any) => {
      const { id } = updatedMember;

      // Create new
      if (!id) {
        return instance.patch(`team/${teamId}/role`, [updatedMember]);
      }
      const omitKeys = new Set(['name', 'id']);
      const payload = omitBy(updatedMember, (value, key) => {
        if (value === null) return true;
        return omitKeys.has(key);
      });
      await instance.patch(`/team/${teamId}/role`, [payload]);
    },
    remove: (teamId: number | string, email: string) => instance.patch(`team/${teamId}/role`, [{ email }]),
  },
};

export const getTeamEndpoints = () => {
  const prefix = '/team';

  return {
    getAllTeams: prefix,
  };
};
