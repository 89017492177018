import instance from '@/utils/api/instance';
import type { PayrollReportColumn } from '@/types/PayrollReportColumn';

export default {
  getEntitySpecificG2NItems: async (legalEntityId: number | string): Promise<PayrollReportColumn[]> => {
    const { data } = await instance.get(
      `/employment/payroll-report-columns?payrollLegalEntityId=${legalEntityId}&supportedInPayrollReport=true`
    );
    return data;
  },
};
