import type { Theme } from '@letsdeel/ui';
import { Grid, useMediaQuery } from '@letsdeel/ui';
import { LoginText, BackgroundContainer } from '@/scenes/Login/components';
import './EmailConfirmed.less';
import type { GraphicType } from '@/components/Graphic';
import Graphic from '@/components/Graphic';
import { AccountTypes } from '@/scenes/Signup/constants';

interface Props {
  emailConfirmedType: AccountTypes;
}

const clientOptions = {
  normalizedType: AccountTypes.Client,
  subtitle: 'Get started with Deel to start hiring internationally with complete confidence',
};
const contractorOptions = {
  normalizedType: AccountTypes.Contractor,
  subtitle:
    'Work compliantly from 150 countries, automate your invoicing, and get paid in any currency, including Crypto.',
};

export const EmailConfirmed = ({ emailConfirmedType }: Props) => {
  const options =
    {
      [AccountTypes.Client]: clientOptions,
      [AccountTypes.Contractor]: contractorOptions,
      [AccountTypes.DirectEmployee]: contractorOptions,
      [AccountTypes.Employee]: contractorOptions,
      [AccountTypes.HRISDirectEmployee]: contractorOptions,
      [AccountTypes.PeoEmployee]: contractorOptions,

      [AccountTypes.Icp]: clientOptions,
      [AccountTypes.Eor]: clientOptions,
      [AccountTypes.TaxAdvisor]: clientOptions,
      [AccountTypes.VmsVendor]: clientOptions,
    }[emailConfirmedType] || clientOptions;

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  if (isTablet)
    return (
      <BackgroundContainer className={'email-confirmed-login-background ' + options.normalizedType}>
        {' '}
      </BackgroundContainer>
    );

  return (
    <BackgroundContainer className={'email-confirmed-login-background ' + options.normalizedType}>
      <Grid item className="email-confirmed-image">
        <Graphic type={`email-confirmed-${options.normalizedType}` as GraphicType} />
      </Grid>
      <Grid item className="email-confirmed-login-text">
        <LoginText
          title="Log in to finish creating your account."
          subtitle={options.subtitle}
          position={{ left: '9%', top: '25%' }}
        />
      </Grid>
    </BackgroundContainer>
  );
};
