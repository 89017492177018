import type { TCOLOR_MODE } from '@/scenes/Settings/components/WhiteLabel/constants';
import type { UserType } from '@/types/User';

export const WhiteLabelFeatures = {
  deelAI: 'deelAI',
  poweredByDeel: 'poweredByDeel',
};

export type IWhiteLabelFeatures = keyof typeof WhiteLabelFeatures;

export type WhiteLabelFeaturesType = Partial<Record<IWhiteLabelFeatures, Partial<Record<UserType, boolean>>>>;

export type WhiteLabelContextBody = {
  isWhiteLabelEnabled: boolean;
  isWhiteLabelLoading: boolean;
  showCircularProgress: boolean;
  primaryColor: Record<TCOLOR_MODE, string | undefined> | null;
  logos: Record<TCOLOR_MODE, Record<'sm' | 'md', string | undefined>> | null;
  businessName?: string;
  organizationId?: number;
  publicOrganizationId?: string;
  isWhiteLabelEmpty?: boolean;
  features: WhiteLabelFeaturesType;
};

export const contextForWhiteLabel = {
  EMPLOYEE: 'employee',
  EMPLOYEE_LOGGED_IN: 'employee_logged_in',
  LOGIN: 'login',
  CONTRACTOR_INVITE: 'contractor_invite',
} as const;

export type ContextForWhiteLabel = (typeof contextForWhiteLabel)[keyof typeof contextForWhiteLabel];
