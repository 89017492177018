import loginRoutes from '@/scenes/Login/login.routes';
import createProfileRoutes from '@/scenes/CreateProfile/create-profile.routes';
import some from 'lodash/some';
import forEach from 'lodash/forEach';
import { matchPath } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import type { FC, PropsWithChildren } from 'react';
import { useRef } from 'react';

const SENSITIVE_ROUTES = [...loginRoutes, ...createProfileRoutes];

export const addScript = (src: string, attributes: { [key: string]: string }, onload: any, key?: string) => {
  if (key && document.head.querySelector(`[data-key="${key}"]`)) return;
  const script = document.createElement('script');
  forEach(attributes, (attributeValue, attributeKey) => script.setAttribute(attributeKey, attributeValue));
  script.onload = onload;
  script.onerror = () => console.error(`Failed to load script ${src}`);
  script.src = src;
  if (key) script.setAttribute('data-key', key);
  document.head.appendChild(script);
};

export const addStyle = (href: string) => {
  const style = document.createElement('link');
  style.href = href;
  style.rel = 'stylesheet';
  style.onerror = () => console.error(`Failed to load style ${href}`);
  document.head.appendChild(style);
};

export const getIsSensitivePathname = (pathname: string) =>
  some(SENSITIVE_ROUTES, (loginRoute) => matchPath(pathname, loginRoute));

// Reloads page when page switches from non-sensitive to sensitive and vice versa
export const ExternalScriptsHandler: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const location = useLocation();
  const isSensitivePathname = getIsSensitivePathname(location.pathname);
  const isSensitivePathnameRef = useRef(isSensitivePathname);
  const isChanged = isSensitivePathnameRef.current !== isSensitivePathname;
  if (isChanged) {
    isSensitivePathnameRef.current = isSensitivePathname;
    window.location.reload();
    return null;
  }
  return <>{children}</>;
};
