import i18n from 'i18next';
import capitalize from 'lodash/capitalize';
import { toPercentage } from '@/utils/numbers';
import { dateFormatSimple as dateFormat, toReportFormat } from '@/utils/time';
//import UserStore from '@/stores/UserStore';
import LookupStore from '@/stores/LookupStore';
import { MAPPED_EOR_FIELDS } from '@/constants/contract';
import { dateFormatAsUTC } from '@/utils/time';
import { toPrice } from '@/utils/numbers';

export const EOR_AMENDMENT_TYPES = {
  LEGAL: 'LEGAL',
  AUTO_CLIENT: 'AUTO_CLIENT',
  INSTANT: 'INSTANT',
  AUTO_ALL: 'AUTO_ALL',
  OFF_PLATFORM: 'OFF_PLATFORM',
  CUSTOM: 'CUSTOM',
};

export const EOR_AMENDMENT_STATUSES = {
  UNDER_REVIEW: {
    index: 1,
    instantRequestIndex: 1,
    label: i18n.t('temp.eor.scenes.Contract.619a0f74c2'),
    value: 'UNDER_REVIEW',
    color: 'black',
  },
  PREPARING_CUSTOM: {
    index: 1,
    instantRequestIndex: 1,
    label: i18n.t('temp.eor.scenes.Contract.2729600916'),
    value: 'PREPARING_CUSTOM',
    color: 'black',
  },
  WAITING_FOR_CLIENT_SIGN: {
    index: 1,
    label: i18n.t('temp.eor.scenes.Contract.c8ac60a2d3'),
    value: 'WAITING_FOR_CLIENT_SIGN',
    color: 'black',
  },
  WAITING_FOR_EMPLOYEE_CONTRACT: {
    index: 2,
    label: i18n.t('temp.eor.scenes.Contract.3c9938cd00'),
    value: 'WAITING_FOR_EMPLOYEE_CONTRACT',
    color: 'black',
  },
  WAITING_FOR_EMPLOYEE_SIGN: {
    index: 2,
    label: i18n.t('temp.eor.scenes.Contract.3efd0189ed'),
    value: 'WAITING_FOR_EMPLOYEE_SIGN',
    color: 'black',
  },
  WAITING_FOR_EOR_SIGN: {
    index: 3,
    label: i18n.t('temp.eor.scenes.Contract.c723e2e769'),
    value: 'WAITING_FOR_EOR_SIGN',
    color: 'black',
  },
  ACTIVE: {
    index: 4,
    instantRequestIndex: 2,
    label: i18n.t('temp.eor.scenes.Contract.4d3d769b81'),
    value: 'ACTIVE',
    color: 'green',
  },
  OUTDATED: { index: -1, label: i18n.t('temp.eor.scenes.Contract.6f64ae857a'), value: 'OUTDATED', color: 'black' },
  UPCOMING: {
    index: 3,
    instantRequestIndex: 1,
    label: i18n.t('temp.eor.scenes.Contract.14ab5b8597'),
    value: 'UPCOMING',
    color: 'green',
  },
  REJECTED: { index: -1, label: i18n.t('temp.eor.scenes.Contract.d37b1f6c05'), value: 'REJECTED', color: 'error' },
  CANCELLED: { index: -1, label: i18n.t('temp.eor.scenes.Contract.0e22fe7d45'), value: 'CANCELLED', color: 'error' },
  VOID: { index: -1, label: i18n.t('temp.eor.scenes.Contract.81ceb48a97'), value: 'VOID', color: 'error' },
};

export const EOR_AMENDMENT_PENDING_STATUSES = new Set([
  EOR_AMENDMENT_STATUSES.UNDER_REVIEW.value,
  EOR_AMENDMENT_STATUSES.WAITING_FOR_CLIENT_SIGN.value,
  EOR_AMENDMENT_STATUSES.WAITING_FOR_EMPLOYEE_SIGN.value,
  EOR_AMENDMENT_STATUSES.WAITING_FOR_EMPLOYEE_CONTRACT.value,
  EOR_AMENDMENT_STATUSES.WAITING_FOR_EOR_SIGN.value,
]);

const EOR_AMENDMENT_FIELDS = {
  employerCost: { ...MAPPED_EOR_FIELDS.employerCost },
  managementFee: { ...MAPPED_EOR_FIELDS.managementFee },
  monthlyEmployerCost: { ...MAPPED_EOR_FIELDS.monthlyEmployerCost },
  monthlyManagementFee: { ...MAPPED_EOR_FIELDS.monthlyManagementFee },
  depositDifference: { ...MAPPED_EOR_FIELDS.depositDifference },
};

export const EMPLOYEE_QUALIFICATION = {
  EMPLOYEE: i18n.t('temp.eor.scenes.Contract.f8c8b903cb'),
  MANAGER: i18n.t('temp.eor.scenes.Contract.ae94be3cd5'),
};

// For create and update APIS
export const buildAmendedItemsPayload = (amendedItems, isUserEOR = false) => {
  if (!amendedItems || !amendedItems?.length) return {};

  if (isUserEOR) {
    amendedItems = amendedItems.filter(({ key }) => !!EOR_AMENDMENT_FIELDS[key]);
  } else {
    amendedItems = amendedItems.filter(({ key }) => !EOR_AMENDMENT_FIELDS[key]);
  }

  let fixedAdjustments = [];
  amendedItems.filter((item) => {
    if (item.key === 'fixedAdjustments') {
      item.values.map((adjustment) => {
        const { id, description, value, isRecurring, parentFixedAdjustmentId, isDeleted } = adjustment.value[0];
        const fixedAdjustment = {
          id,
          description,
          value,
          isRecurring,
          parentFixedAdjustmentId,
          isDeleted,
        };
        fixedAdjustments.push(fixedAdjustment);
      });
    }
  });

  let variableCompensations = [];
  amendedItems.filter((item) => {
    if (item.key === 'variableCompensations') {
      item.values.map((adjustment) => {
        const { id, amount, type, description, timeline, effectiveDate, isAdd, isEdited, parentId, isDeleted } =
          adjustment.value[0];
        const varComp = {
          ...(!isAdd || isEdited ? { id: id || parentId } : {}),
          amount,
          type,
          title: description,
          timeline,
          effectiveDate: dateFormatAsUTC(effectiveDate),
          ...(parentId && { parentId }),
          isDeleted,
        };
        variableCompensations.push(varComp);
      });
    }
  });

  return {
    items: amendedItems?.reduce((acc, item) => {
      let value = item.value;

      if (!value && value !== 0) value = null;

      if (item.key === 'fixedAdjustments') value = fixedAdjustments;
      if (item.key === 'variableCompensations') value = variableCompensations;

      return { ...acc, [item.key]: value };
    }, {}),
  };
};

// For UI
export const getAmendedItemsList = (
  amendment,
  contractData,
  excludeItemsKeys = ['effectiveDate', 'employeeLegalName']
) => {
  if (!amendment || !amendment.amendedItems || !contractData) return [];

  const amendedItems = amendment.amendedItems.filter((key) => {
    if (EOR_AMENDMENT_FIELDS[key]) return false;
    if (Array.isArray(excludeItemsKeys) && excludeItemsKeys.includes(key)) return false;

    return true;
  });

  const res = amendedItems?.map((key) => {
    const isAdjustmentOrVC = ['fixedAdjustmentIds', 'variableCompensationIds'].includes(key);
    const newKey = key === 'fixedAdjustmentIds' ? 'fixedAdjustments' : 'variableCompensations';
    if (isAdjustmentOrVC && Array.isArray(amendment[newKey])) {
      return {
        key: newKey,
        values: amendment[newKey]
          .filter((item) => item.isAmended || item.isDeleted)
          .map((item) =>
            getAmendedItemObject(newKey, item, contractData, false, contractData.currency, isAdjustmentOrVC)
          )
          .filter(({ key }) => !!key),
      };
    } else {
      return getAmendedItemObject(
        key,
        amendment[key],
        { ...contractData, seniority: amendment.seniority },
        true,
        isAdjustmentOrVC
      );
    }
  });
  return res.filter(({ key }) => !!key);
};

export const getAmendedItemObject = (key, value, contractData, shouldBeTimezoned, currency, isAdjustmentOrVC) => {
  const item = MAPPED_EOR_FIELDS[key];

  if (!item) {
    return {};
  }

  const eorContract = contractData.eorContract;
  const oldValue = eorContract?.[key];
  const isPercentage = value?.type && value?.type === 'PERCENTAGE';

  const res = {
    key: !isAdjustmentOrVC ? key : value.parentId || value.id,
    label: !isAdjustmentOrVC ? item.label : value.description || value.title,
    uiValue: !isAdjustmentOrVC
      ? getAmendedItemUIValue({ key, ...item }, value, contractData, shouldBeTimezoned)
      : isPercentage
      ? toPercentage(value.value || value.amount)
      : toPrice(value.value || value.amount, currency),
    type: item.type,
    customComponent: item.customComponent,
    value: isAdjustmentOrVC ? [value] : value && item.type === 'date' ? toReportFormat(value) : value,
    shouldBeTimezoned,
    oldValue: !isAdjustmentOrVC
      ? getAmendedItemUIValue({ key, ...item }, oldValue, contractData, shouldBeTimezoned, true)
      : isPercentage
      ? toPercentage(oldValue)
      : toPrice(oldValue, currency),
  };

  return !res.uiValue && !res.customComponent ? {} : res;
};

const getAmendedItemUIValue = (item, value, contractData, shouldBeTimezoned, isOldValue) => {
  const { timezone, currency, seniorityOptions, seniority, healthAllowanceCurrency, employmentCountry, eorContract } =
    contractData;

  switch (item.type) {
    case 'money':
      return toPrice(value, currency);
    case 'date':
      if (!value) {
        if (item.key === 'endDate') return i18n.t('temp.eor.scenes.Contract.f55dbf1424');
        if (item.key === 'effectiveDate') return i18n.t('temp.eor.scenes.Contract.d69c5f40be');
        return i18n.t('temp.eor.scenes.Contract.26760a3e69', { v0: item.label?.toLowerCase() });
      }
      if (typeof value === 'string') {
        return dateFormat(value, shouldBeTimezoned ? timezone : undefined);
      }
      return dateFormat(value);
    case 'country':
      return LookupStore.countries[value]?.label;
    case 'province':
      return employmentCountry
        ? LookupStore.countries[employmentCountry]?.provinces?.find((province) => province.value === value)?.label
        : value;
    case 'text':
    default:
      if (item.key === 'timeOffType') return `${capitalize(value)}`;
      if (item.key === 'sickLeaveDays')
        return !value
          ? i18n.t('temp.eor.scenes.Contract.eb6d8ae6f2')
          : i18n.t('temp.eor.scenes.Contract.9067ae1805', { v0: value });
      if (item.key === 'workHoursPerWeek' && value) {
        return i18n.t('temp.eor.scenes.Contract.9e950e2b38', { v0: value });
      }
      if (item.key === 'seniorityId') {
        if (isOldValue && eorContract?.['seniority']) {
          return eorContract?.['seniority']?.name;
        }
        return seniorityOptions?.find((option) => option.value === value)?.label || seniority?.name || value;
      }

      if (item.key === 'employeeQualification') return EMPLOYEE_QUALIFICATION[value];
      if (item.key === 'healthAllowance') return toPrice(value, healthAllowanceCurrency);
      if (item.key === 'probationPeriod')
        return value
          ? value
          : value === 0
          ? i18n.t('temp.eor.scenes.Contract.333ed1d0c0')
          : i18n.t('temp.eor.scenes.Contract.e681a3b459');
      return value;
  }
};

export const getAmendment = (eorAmendmentHistory, amendmentId) => {
  if (!eorAmendmentHistory || !eorAmendmentHistory?.length || !amendmentId) return null;
  return eorAmendmentHistory.find(({ id }) => id.toString() === amendmentId.toString()) || null;
};

export const getEorTemplateObject = ({ amendment, contractType = 'eor' }) => {
  if (!amendment) return {};
  if (contractType === 'eor') {
    return amendment.clientTemplate ? amendment.clientTemplate : { title: amendment.sowTitle };
  } else {
    return amendment.employeeTemplate ? amendment.employeeTemplate : { title: amendment.eaTitle };
  }
};

export const getAmendmentTitle = ({ amendment, contractType = 'eor' }) => {
  if (!amendment) return '';
  return getEorTemplateObject({ amendment, contractType })?.title || i18n.t('temp.eor.scenes.Contract.49aa794ab6');
};

export const getAmendmentStatusByIndex = (index) => {
  const res = Object.keys(EOR_AMENDMENT_STATUSES).find((key) => EOR_AMENDMENT_STATUSES[key].index === index);
  return res;
};

export const buildViewAmendmentPageLink = (contract, amendmentId) => {
  return `/contract-preview/${contract?.id}?title=${contract.name}&eor-amendment-id=${amendmentId}&contract-type=${contract.type}`;
};

export const getDeletedAmendedItemsList = (amendedItems, newAmendedItems) => {
  const filteredAmendedItems = amendedItems?.filter((key) => !!MAPPED_EOR_FIELDS[key]);
  const diff = filteredAmendedItems.filter((key) => !newAmendedItems.find((item) => item.key === key));

  return diff || [];
};
