import { Grid } from '@letsdeel/ui';
import type { ReactNode} from 'react';
import { useEffect } from 'react';
import './background-container.less';

export const BackgroundContainer = ({ children, className }: { children: ReactNode; className: string }) => {
  useEffect(() => {
    // This is a fix for a really strange bug that is happening only on firefox
    if (navigator.userAgent.indexOf('Firefox') !== -1) {
      const html = document.querySelector('html');
      if (html) {
        html.setAttribute('style', 'z-index: -1;');
      }
    }
  }, []);

  return (
    <Grid container className={['login-background-container', className].join(' ')}>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};
