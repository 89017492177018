import './anchor.less';
import type { MouseEventHandler, ReactNode, ForwardedRef, KeyboardEventHandler } from 'react';
import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { cn } from '@/utils/main';
import { isNative } from '@/utils/crossPlatform';

interface Props {
  noDefaultClass?: boolean;
  className?: string;
  children: ReactNode;
  to?:
    | string
    | Partial<{
        pathname: string;
        search: string;
        hash: string;
        state: object;
      }>;
  download?: string;
  href?: string;
  newTab?: boolean;
  style?: React.CSSProperties;
  tabIndex?: number;
  onClick?: MouseEventHandler;
  onKeyDown?: KeyboardEventHandler;
}

const Anchor = (
  { noDefaultClass, className, children, to, href, newTab, onClick, ...rest }: Props,
  ref: ForwardedRef<HTMLAnchorElement>
) => {
  const newTabProps = newTab && !isNative() ? { target: '_blank', rel: 'noopener noreferrer' } : {};
  return (
    <>
      {to ? (
        <Link
          ref={ref}
          to={to}
          onClick={onClick}
          className={cn(noDefaultClass ? '' : 'anchor', className)}
          {...newTabProps}
          {...rest}
        >
          {children}
        </Link>
      ) : href ? (
        <a
          ref={ref}
          href={href}
          {...newTabProps}
          className={cn(noDefaultClass ? '' : 'anchor', className)}
          onClick={onClick}
          {...rest}
        >
          {children || href}
        </a>
      ) : (
        children
      )}
    </>
  );
};

export default forwardRef(Anchor);
