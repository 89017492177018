import { action, computed, makeObservable, observable } from 'mobx';
import type { ScopedMutator } from 'swr/_internal';
import type { RoleWithPermissions } from '@letsdeel/ui/dist/shared/NewRolesPermissionsList/RolesPermissionsList.props';
import { isBetaEnabled, removeFromBeta } from '@/constants/localStorage';
import type { ShieldProvider } from '@/utils/api/entities';
import entitiesApi, { IdentifierCategory, IdentifierFillType } from '@/utils/api/entities';
import organizationsApi from '@/utils/api/organizations';
import LookupStore from '@/stores/LookupStore';
import type { CountryCode } from '@letsdeel/ui';
import type { LegalEntity } from '@/types/LegalEntity';
import type { Organization } from '@/types/User';
import UserStore from './UserStore';
import { RoleId } from '@/constants/roleNameToIdMap';

export const DEEL_ORG_ID = 3536;
export const DEEL_ORG_PUBLIC_ID = 'a791b0d2-a87a-4e81-89c8-36908f7bd193';

const defaultLegalEntityId = 886;
const deelFinanceTeam = 111642;

export class OrganizationsStore {
  constructor() {
    makeObservable(this, {
      profiles: observable,
      roles: observable,
      legalEntities: observable,
      shieldProviders: observable,
      agreements: observable,
      unsignedAgreements: observable,
      isShieldMsaOn: observable,
      setShieldAndMsaOn: action,
      legalEntitiesByOrgId: observable,
      loadProfiles: action,
      loadEntities: action,
      addEntity: action,
      filteredLegalEntities: computed,
      isDeelUser: computed,
      defaultEntity: computed,
      loadUnsignedAgreements: action,
      initialToggleShield: observable,
      loadToggleShieldConfig: action,
    });
  }

  profiles = [];
  roles: Array<RoleWithPermissions> = [];
  legalEntities: Array<LegalEntity> | null = null;
  shieldProviders: Array<ShieldProvider> = [];
  agreements: Array<any> | null = null;
  unsignedAgreements: Array<any> | null = null;
  legalEntitiesByOrgId: { [key: string]: Array<any> } = {};
  activeOrgId = null;
  isShieldMsaOn = false;
  initialToggleShield = false;

  mutate: ScopedMutator | undefined = undefined;

  setMutate = (mutate: ScopedMutator) => (this.mutate = mutate);

  hasMissingDetailsLabel(a: LegalEntity) {
    const { vatIdError, vatId, vatCountry, optedOutVatId, sicNumber, companyIdentifiers } = a;

    const hasVatError =
      vatIdError || (!vatId && LookupStore.isEUVatRequired(vatCountry as CountryCode) && !optedOutVatId);

    return Boolean(
      companyIdentifiers?.some(
        ({ fieldCategory, error, fillRequiredType }) =>
          fieldCategory === IdentifierCategory.REGISTRATION && error && fillRequiredType !== IdentifierFillType.BYPASS
      ) ||
        Boolean(hasVatError) ||
        !sicNumber
    );
  }

  updateLegalEntitiesByOrgId() {
    if (!this.activeOrgId) return;
    this.legalEntitiesByOrgId[this.activeOrgId || ''] = [...(this.legalEntities || [])];
    this.legalEntitiesByOrgId[this.activeOrgId || ''].forEach((legalEntity) =>
      this.mutate?.(`legal_entities/${legalEntity.id}`)
    );
  }

  async loadProfiles(id = UserStore?.organization?.id) {
    if (!id) {
      console.error('No organization id specified');
      return;
    }

    this.profiles = await organizationsApi.loadProfiles(id);
  }

  async loadEntitiesRaw(id: number, withoutFilter?: boolean) {
    return (await entitiesApi.loadList(id, withoutFilter))?.sort((a: LegalEntity, b: LegalEntity) => {
      const aHasError = this.hasMissingDetailsLabel(a);
      const bHasError = this.hasMissingDetailsLabel(b);

      if (aHasError === bHasError) return a.name.localeCompare(b.name);
      return aHasError ? -1 : 1;
    });
  }

  async loadEntities(
    // @ts-ignore
    id = UserStore?.organization?.id,
    updateOrgIdListOnly = false,
    withoutFilter?: boolean,
    allowReloadActiveOrg?: boolean
  ) {
    if (!id) {
      this.legalEntities = [];
      console.error('No organization id specified');
      return;
    }

    if (!updateOrgIdListOnly) {
      if (!allowReloadActiveOrg && String(this.activeOrgId) === String(id)) return;

      this.legalEntities = null;
      this.legalEntities = await this.loadEntitiesRaw(id, withoutFilter);
      // @ts-ignore
      this.activeOrgId = id;
      this.updateLegalEntitiesByOrgId();
    } else {
      this.legalEntitiesByOrgId[id] = await this.loadEntitiesRaw(id, withoutFilter);
    }
  }

  async loadShieldProviders() {
    const data = await entitiesApi.shieldProviders();
    if (data.length) {
      this.shieldProviders = data;
    }
  }

  async loadAgreements() {
    this.agreements = (await organizationsApi.loadAgreements()).rows;
    const isThereAgreements = !!this.agreements?.length;

    this.setShieldAndMsaOn(isThereAgreements);
  }

  async loadToggleShieldConfig() {
    await this.loadAgreements();
    const orgContracts = await organizationsApi.getContractsInfo();

    const { hasFixedContract, hasMilestoneContract, hasShieldContract } = orgContracts || {};
    if (this.isShieldMsaOn) {
      const hasICContracts = hasFixedContract || hasMilestoneContract;

      if (hasShieldContract || !hasICContracts) {
        this.initialToggleShield = true;
      }
    }
  }

  async loadUnsignedAgreements() {
    const unsignedAgreements = await organizationsApi.loadUnsignedAgreements();

    if (!unsignedAgreements) return;
    this.unsignedAgreements = unsignedAgreements?.rows;
  }

  async loadRoles(id = UserStore?.organization?.organizationPublicId) {
    if (!id) {
      console.error('No organization id specified');
      return;
    }

    const data = await organizationsApi.loadRoles(id);
    this.roles = [
      data.find((item: RoleWithPermissions) => item.id === RoleId.OrganizationAdmin),
      ...(data
        ?.filter(
          (item: RoleWithPermissions) =>
            item.id !== RoleId.OrganizationAdmin && item.id !== RoleId.EntityRoleReportsAccess
        )
        .sort(
          (a: RoleWithPermissions, b: RoleWithPermissions) =>
            a.scope?.localeCompare(b.scope) || a.name?.localeCompare(b.name)
        ) ?? []),
    ];
  }

  addEntity(newEntity: LegalEntity) {
    this.legalEntities = [...(this.legalEntities || []), newEntity].sort((a: LegalEntity, b: LegalEntity) => {
      const aHasError = this.hasMissingDetailsLabel(a);
      const bHasError = this.hasMissingDetailsLabel(b);

      if (aHasError === bHasError) return a.name.localeCompare(b.name);
      return aHasError ? -1 : 1;
    });
    this.updateLegalEntitiesByOrgId();
  }

  deleteEntity(idToDelete: string | number) {
    this.legalEntities = this.legalEntities?.filter(({ id }) => String(id) !== String(idToDelete)) || null;
    this.updateLegalEntitiesByOrgId();
  }

  updateEntity(updatedEntity: LegalEntity) {
    this.legalEntities =
      this.legalEntities
        ?.map((entity) => {
          if (updatedEntity.publicId === entity.publicId) {
            return { ...entity, ...updatedEntity };
          }
          return entity;
        })
        .sort((a: any, b: any) => a.name.localeCompare(b.name)) || null;

    this.updateLegalEntitiesByOrgId();
  }
  isEntitySupportingDeelPremium = (legalEntity: any): boolean => {
    if (!legalEntity || !legalEntity.address?.country) return false;
    return true;
  };
  isSupportingDeelPremium = (): boolean => {
    return this.legalEntities?.some((entity) => this.isEntitySupportingDeelPremium(entity)) || false;
  };

  get isDeelUser() {
    return UserStore.isDeelUser;
  }

  setShieldAndMsaOn(value: boolean) {
    this.isShieldMsaOn = value;
  }

  filterEntitiesForDeel = (legalEntities: any[], organizationId: number | undefined) => {
    if (!this.isDeelUser) return legalEntities;
    const isFinanceTeam = UserStore.teams.some(({ id }) => +id === deelFinanceTeam);
    // TODO: https://letsdeel.atlassian.net/browse/XTR-1124 Refactor check for Deel employee
    if (organizationId !== DEEL_ORG_ID || isFinanceTeam) return legalEntities;
    const deelUserAndEntities = LookupStore.getFeatureFlag('extraDeelHQEntitiesForDeelUser');
    const userEmail = UserStore?.email;
    const deelUserExtraEntitiesIds = deelUserAndEntities?.[userEmail];
    if (this.isDeelUser && deelUserExtraEntitiesIds) {
      return (
        legalEntities.filter(({ id }) => [defaultLegalEntityId, ...deelUserExtraEntitiesIds].includes(id)) ||
        legalEntities[0]
      );
    }
    return [this.defaultEntityForDeel(legalEntities)];
  };

  get filteredLegalEntities() {
    const legalEntities = this.legalEntities || [];
    return this.filterEntitiesForDeel(legalEntities, UserStore.organization?.id);
  }

  get legalEntitiesList() {
    return this.legalEntities || [];
  }

  defaultEntityForDeel = (legalEntities: any[] | null) => {
    if (!legalEntities) return null;
    if (this.isDeelUser) {
      return legalEntities.find(({ id }) => +id === defaultLegalEntityId) || legalEntities[0];
    }
    return legalEntities[0];
  };

  get defaultEntity() {
    return this.defaultEntityForDeel(this.legalEntities);
  }

  tryEnableApiUsingBetas = async (organization: Organization) => {
    if (!organization) return;

    const API_BETA = 'api';

    const isApiEnabled = isBetaEnabled(API_BETA);

    if (organization && !organization.isApiEnabled && isApiEnabled) {
      await organizationsApi.update(organization.id, {
        isApiEnabled: true,
        name: organization.name, //backend required
      });

      organization.isApiEnabled = true;
    }

    removeFromBeta(API_BETA);
  };
}

const organizationStoreInstance = new OrganizationsStore();
export default organizationStoreInstance;
