import type { HomeBackendFeatureFlags } from '@/constants/featureFlags/home';
import useUserStore from '@/hooks/useUserStore';
import instance from '@/utils/api/instance';
import useFetch from '../useFetch';

export const customFetcher = (url: string) => instance.get(url, { suppressSnackbar: true }).then((res) => res.data);

export function useFetchHomeTeamFeatureFlags() {
  const user = useUserStore();
  const BASE_URL = '/dashboard/home/features';

  return useFetch<Record<HomeBackendFeatureFlags, boolean>>(
    user?.isLoggedIn && user?.verifiedAt && (user.isClient ? !!user.organization : true) ? BASE_URL : null,
    {},
    customFetcher
  );
}
