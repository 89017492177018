import employee from '@/utils/api/hris/employee';
import timeOff from '@/utils/api/hris/time-off';
import workflows from '@/utils/api/hris/workflows';
import variableCompensations from '@/scenes/People/PersonView/VariableCompensation/VariableCompensation.api';

import bulkEdit from './bulk-edit';
import changeRequests from './changeRequests';
import deelHRAccess from './deelHRAccess';
import scheduledEdits from './scheduledEdits';
import task from './task';
import workerRelations from './workerRelations';
import orgChart from './orgChart';

export default {
  employee,
  timeOff,
  task,
  workflows,
  workerRelations,
  orgChart,
  bulkEdit,
  deelHrAccess: deelHRAccess,
  scheduledEdits,
  changeRequests,
  variableCompensations,
};
