import useFetch from '@/hooks/api/useFetch';
import type { CountryCode } from '@letsdeel/ui';
import useUserStore from '@/hooks/useUserStore';

interface PhoneNumber {
  number: string;
  country: CountryCode;
}
export const useFetchPhoneNumbers = () => {
  const user = useUserStore();
  const { data, mutate, isValidating } = useFetch<{ phoneNumbers: PhoneNumber[] }>(
    user.isLoggedIn && '/user/phone_numbers',
    { dedupingInterval: 0 }
  );
  return { data: data?.phoneNumbers ?? null, mutate, isValidating };
};
