import i18n from 'i18next';
import { Trans } from 'react-i18next';
import Button from '@/components/Button/Button';
import Flex from '@/components/Flex/Flex';
import { Icon, Alert, VStack, Link, H1, H4, P, Illustration } from '@letsdeel/ui';
import Recaptcha from '@/components/Recaptcha/Recaptcha';
import { useEmailVerification } from '@/scenes/Login/components/EmailVerification/useEmailVerification';
import type { EmailVerificationProps } from '@/scenes/Login/components/EmailVerification/types';

export const EmailVerification = ({
  goBackAction,
  resend,
  mainTitle,
  subTitle,
  enableRecaptcha,
  hasBackupEmail,
  alertInfo,
  setShowAlternativeOptions,
  setIsBackupEmailReset,
}: EmailVerificationProps) => {
  const { onRecaptcha, isResending, clearAndGoBack, confirmationWasSent, resendActionTimer } = useEmailVerification({
    resend,
    setIsBackupEmailReset,
    goBackAction,
  });

  return (
    <>
      <VStack spacing={16}>
        {goBackAction ? (
          <Button theme="link" onClick={clearAndGoBack} className="text-decoration-none">
            <Flex>
              <Icon.ArrowNew className="mr-5" size={15} />
              {i18n.t('temp.platform-fe.scenes.Login.0557fa923d').toString()}
            </Flex>
          </Button>
        ) : null}
        <H1 className="semi-bold text-center">
          {mainTitle || i18n.t('temp.platform-fe.scenes.Login.82ecb08832').toString()}
        </H1>
        <H4 regular textAlign="center" color="text.secondary">
          {subTitle}
        </H4>
      </VStack>
      <Flex className="justify-content-center flex-column align-items-center">
        <Illustration illustration="message-sent" />
      </Flex>
      <VStack spacing={24}>
        <Alert
          variant="outlined"
          severity={confirmationWasSent ? 'success' : 'info'}
          title={confirmationWasSent ? 'Confirmation email sent' : alertInfo || "Didn't receive an email?"}
          action={
            <>
              {enableRecaptcha ? <Recaptcha onRecaptcha={onRecaptcha} onError={console.error} /> : null}
              <Button
                theme="secondary"
                type="submit"
                size="sm"
                loading={isResending}
                disabled={confirmationWasSent || isResending}
                onClick={() => resendActionTimer()}
              >
                Resend Email
              </Button>
            </>
          }
        >
          {i18n.t('temp.platform-fe.scenes.Login.8ac9b0c5d0').toString()}
        </Alert>
        {hasBackupEmail ? (
          <P className="text-center">
            <Trans
              i18nKey="temp.platform-fe.scenes.Login.f0c3c78a27"
              components={[
                <Link className="mt-8" onClick={setShowAlternativeOptions} variant="h5" key="94d34a20a7_0" />,
              ]}
            />
          </P>
        ) : (
          <P className="text-center">
            <Trans
              i18nKey="temp.platform-fe.scenes.Login.ac006e936a"
              components={[
                <a
                  href="https://help.letsdeel.com/hc/en-gb/requests/new?ticket_form_id=360001676798"
                  rel="noreferrer"
                  target="_blank"
                  key="014198b76f_0"
                />,
              ]}
            />
          </P>
        )}
      </VStack>
    </>
  );
};
