import { Trans } from 'react-i18next';
import { Grid } from '@letsdeel/ui';

const Footer = () => {
  return (
    <Grid container item justifyContent="center">
      <p className="text-center">
        <Trans
          i18nKey="temp.platform-fe.scenes.ResetPassword.29d8e3b832"
          components={[
            <a
              href="https://help.letsdeel.com/hc/en-gb/requests/new?ticket_form_id=360001676798"
              target="_blank"
              rel="noreferrer"
              key="7825056045_0"
            />,
          ]}
        />
      </p>
    </Grid>
  );
};

export default Footer;
