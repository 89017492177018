import i18n from 'i18next';
import { VStack, H4, H6 } from '@letsdeel/ui';
import { ProbationPeriodTypeForDefinite } from '@/types';

interface Props {
  contract: any;
  amendedItems: any;
  item: any;
}
export const ProbationAmendedItemCell = ({ contract, item, amendedItems }: Props) => {
  if (!contract?.eor?.endDate) return <H4>{`${item?.value} days`}</H4>;
  const amendedType = amendedItems?.find((item: { key: string }) => item.key === 'probationPeriodTypeForDefinite');
  const typeValue = amendedType?.value || contract?.eor?.probationPeriodTypeForDefinite;
  const typeLabel =
    typeValue === ProbationPeriodTypeForDefinite.CUSTOM
      ? i18n.t('temp.eor.scenes.Contract.90589c47f0').toString()
      : i18n.t('temp.eor.scenes.Contract.eb6d8ae6f2').toString();
  return (
    <VStack align="end">
      {typeLabel}
      <H6>{`${item?.value} days`}</H6>
    </VStack>
  );
};
