import { showConfirmDialog } from '@/utils/nativeDialog';
import { showNativeKeyboard } from '@/utils/nativeKeyboard';
import { Keyboard } from '@capacitor/keyboard';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addPinCodeDataLayer } from '@/native/AppPin/utils';
import { EventActions } from '@/utils/analytics/constants';
import { type PluginListenerHandle } from '@capacitor/core';

export function useInitRequestPin(closeModal: (token: string | undefined) => void) {
  const { t } = useTranslation();

  const [isKeyboardVisible, setKeyboardVisible] = useState<boolean>(false);
  const handleKeyboardWillShow = useCallback(() => setKeyboardVisible(true), []);
  const handleKeyboardWillHide = useCallback(() => {
    showNativeKeyboard(); // This is to make sure the keyboard is always visible on Request Pin screen
    setKeyboardVisible(false);
  }, []);

  useEffect(() => {
    let keyboardWillShowListener: PluginListenerHandle | undefined;
    let keyboardWillHideListener: PluginListenerHandle | undefined;
    const addKeyboardListeners = async () => {
      keyboardWillShowListener = await Keyboard.addListener('keyboardWillShow', handleKeyboardWillShow);
      keyboardWillHideListener = await Keyboard.addListener('keyboardWillHide', handleKeyboardWillHide);
    };
    addKeyboardListeners();
    return () => {
      if (keyboardWillShowListener) keyboardWillShowListener.remove();
      if (keyboardWillHideListener) keyboardWillHideListener.remove();
    };
  }, [handleKeyboardWillShow, handleKeyboardWillHide]);

  const handleForgotPin = useCallback(
    (pinAttempts: number) => {
      addPinCodeDataLayer(EventActions.ForgotPinCode, { pin_attempts: pinAttempts.toString() });
      {
        showConfirmDialog({
          title: t('authentication.nativeAppPin.request.forgotPinDialog.title'),
          message: t('authentication.nativeAppPin.request.forgotPinDialog.message'),
          okButtonTitle: t('authentication.nativeAppPin.request.forgotPinDialog.yes'),
          cancelButtonTitle: t('authentication.nativeAppPin.request.forgotPinDialog.no'),
        }).then((result) => {
          if (result.value) {
            closeModal(undefined);
          }
        });
      }
    },
    [t, closeModal]
  );

  const handleCloseDialog = useCallback(() => {
    showConfirmDialog({
      title: t('authentication.nativeAppPin.request.closeDialog.title'),
      message: t('authentication.nativeAppPin.request.closeDialog.message'),
      okButtonTitle: t('authentication.nativeAppPin.request.closeDialog.yes'),
      cancelButtonTitle: t('authentication.nativeAppPin.request.closeDialog.no'),
    }).then((result) => {
      if (result.value) {
        closeModal(undefined);
      }
    });
  }, [t, closeModal]);

  return {
    isKeyboardVisible,
    handleForgotPin,
    handleCloseDialog,
  };
}
