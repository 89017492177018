import instance from '@/utils/api/instance';

export default {
  create: async (id: string, payload: any) => {
    const { data } = await instance.post(`/contracts/${id}/timeoff`, payload);
    return data;
  },

  approve: async (id: string, timeOffId: string, payload: any) => {
    const { data } = await instance.post(`/contracts/${id}/timeoff/${timeOffId}/approve`, payload);
    return data;
  },

  deny: async (id: string, timeOffId: string, payload: any) => {
    const { data } = await instance.post(`/contracts/${id}/timeoff/${timeOffId}/deny`, payload);
    return data;
  },

  delete: async (id: string, timeOffId: string) => {
    const { data } = await instance.delete(`/contracts/${id}/timeoff/${timeOffId}`);
    return data;
  },
};
