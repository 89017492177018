import i18n from 'i18next';
import LookupStore from '@/stores/LookupStore';
import { parseMixedDate } from '@/utils/date';
import { addDays, addMonths, addYears, differenceInDays, differenceInMonths, differenceInYears } from 'date-fns';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import capitalize from 'lodash/capitalize';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment-timezone';
import { MILLISECONDS_IN_MINUTE } from '../constants/time';
import { createDate, DateFormat } from '@letsdeel/ui';
import { SimpleDate } from './SimpleDate';

export const BASE_DATE_ONLY_FORMAT = 'YYYY-MM-DD';

export const dateFormats = {
  birth: 'MM/DD/YYYY', // 05/16/2020
  pretty: 'MMMM Do, YYYY', // September 16th, 2020
  prettyShort: 'MMM Do YYYY', // Sep 16th 2020
  selected: 'dddd, Do of MMM', // Saturday, 16th of May
  short: 'Do MMM', // 16th May
  shortWithYear: 'MMM Do YYYY', // May 16th 2020
  monthDay: 'MMMM Do', //September 22nd
  dayMonth: 'Do MMMM', //22nd September
  shortMonthDay: 'MMM Do', //Sep 22nd
  fullDate: 'MMM Do, YYYY hh:mm A z', // May 16th, 2020 18:34 AM UTC
  time: 'h:mm A', // 8:34 AM
  gmt: '[GMT]ZZ', // GMT-0300 or GMT+0300,
  monthYear: 'MMMM YYYY', // September 2022
  month: 'MMMM', // September
  shortTimeWithTimezone: 'HH:mm z', // 18:34 UTC
};

// Takes in a date format and returns a function that takes in a date and returns it formatted based on the format
export const createDateFormatter = (format) => {
  return (date, tz) => (tz ? moment(date).tz(tz) : moment(date)).format(format);
};

// Takes a timezone eg: Ameria/Sao_Paulo and returns GMT-03
export const buildGmtTimezoneText = (tz) => {
  const text = moment().tz(tz).parseZone().format(dateFormats.gmt);
  return text.substring(0, text.length - 2);
};

// Takes a timezone eg: Ameria/Sao_Paulo and returns 03:35 PM
export const buildLocalTimeFromTimezone = (tz) => {
  const text = moment().tz(tz).parseZone().format(dateFormats.time);
  return text;
};

// added this declaration for ts lint
const returnStringFunc = (date, timezone) => date + timezone + '';
const returnStringUTC = (date) => date + '';

export const addDaysToDate = (date, days) => moment.utc(date).add(days, 'days');

export const subtractDaysFromDate = (date, days) => moment.utc(date).subtract(days, 'days');

export const formatDate = {
  birth: returnStringFunc, // 05/16/2020
  pretty: returnStringFunc, // May 16th, 2020
  prettyShort: returnStringFunc, // May 16th, 2020
  selected: returnStringFunc, // Saturday, 16th of May
  short: returnStringFunc, // 16th May
  shortWithYear: returnStringFunc, // May 16th, 2020
  monthDay: returnStringFunc, // September 22nd
  shortMonthDay: returnStringFunc, // Sep 22nd
  fullDate: returnStringFunc, // May 16th, 2020 18:34 AM
  time: returnStringFunc, // 6:34 AM
  monthYear: returnStringFunc, // September 2022
  month: returnStringFunc, // September
};

export const formatUTCDate = {
  birth: returnStringUTC, // 05/16/2020
  pretty: returnStringUTC, // May 16th, 2020
  prettyShort: returnStringUTC, // May 16th, 2020
  selected: returnStringUTC, // Saturday, 16th of May
  short: returnStringUTC, // 16th May
  shortWithYear: returnStringUTC, // May 16th, 2020
  monthDay: returnStringUTC, //September 22nd
  shortMonthDay: returnStringUTC, //Sep 22nd
  fullDate: returnStringUTC, // May 16th, 2020 18:34 AM
  time: returnStringUTC, // 6:34 AM
  monthYear: returnStringUTC, // September 2022
  month: returnStringUTC, // September
};

// For each date format in dateFormats, add a new function to formatDate that converts a date to the given format
Object.entries(dateFormats).forEach(([key, value]) => {
  formatDate[key] = createDateFormatter(value);
  formatUTCDate[key] = (date) => dateFormatAsUTC(date, value);
});

export const timestampToUTC = (timestamp) => new Date(Date.parse(timestamp)).toUTCString();

export const getUserTimezone = () => {
  if (typeof Intl === 'object' && typeof Intl.DateTimeFormat === 'function') {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  console.warn('The browser doesnt support Intl');
  return '';
};

export const isValidTimezone = (timezone) =>
  !!timezone && !Number.isNaN(zonedTimeToUtc(new Date(), timezone).getTime());

export const toReportFormat = (date, tz) => {
  return (tz ? moment(date).tz(tz) : moment(date)).format('YYYY-MM-DD');
};
export const toReportFormatSimple = (date, tz) => {
  return SimpleDate.parseMixed(date, tz)?.toISODateString();
};

export const getSelectDateOptions = (fromDate, toDate) => {
  const firstDate = moment(fromDate).startOf('month');
  const lastDate = moment(toDate).startOf('month');
  const options = [{ label: lastDate.format('MMMM, YYYY'), value: lastDate?.toISOString() }];

  lastDate.subtract(1, 'months');

  while (lastDate.isSameOrAfter(firstDate, 'month')) {
    options.push({ label: lastDate.format('MMMM, YYYY'), value: lastDate?.toISOString() });

    lastDate.subtract(1, 'months');
  }

  return options;
};

export const getDateDiffWithTZ = (from, to, tz, scale = 'days') => {
  // if no timezone return diff without conversion to prevent crash
  if (!tz) return moment(to)?.diff(moment(from), scale);
  return moment(to).tz(tz).diff(moment(from).tz(tz), scale);
};

export const dateToFromNow = (date, timezone, format = 'MMMM Do, YYYY') => {
  const newDate = moment(date);

  if (timezone) {
    newDate.tz(timezone);
  }
  return newDate.calendar({
    // when the date is closer, specify custom values
    sameDay: i18n.t('temp.unknown.utils.time.js.13b5bb384d'),
    nextDay: i18n.t('temp.unknown.utils.time.js.a35db9a33d'),
    nextWeek: format,
    lastDay: i18n.t('temp.unknown.utils.time.js.a06c51fed7'),
    lastWeek: format,
    sameElse: format,
  });
};

export const CUSTOM_RELATIVE_DATES = [
  i18n.t('temp.unknown.utils.time.js.1dd1c5fb7f'),
  i18n.t('temp.unknown.utils.time.js.ff9eb8bd8c'),
  i18n.t('temp.unknown.utils.time.js.ebfe9ce86e'),
];

const DIFF_TYPES_INDEXES = {
  days: 0,
  months: 1,
};

/**
 * Returns a diff text aggregated by years months days.
 * e.g: 1 year 2 months and 9 days
 * @param {Date | Moment | string} from
 * @param {Date | Moment | string} to
 * @returns {string}
 */
export const getDatesDiffText = (from, to, inclusive) => {
  const a = moment(to);
  const b = moment(from);

  const years = a.diff(b, 'year');
  b.add(years, 'years');

  const months = a.diff(b, 'months');
  b.add(months, 'months');

  const days = inclusive ? a.add(1, 'days').diff(b, 'days') : a.diff(b, 'days');

  // Show 'and' before the last diff type
  const diffTypesAmount = [years, months, days].filter((type) => type !== 0).length;
  const showAndIndex = diffTypesAmount <= 1 ? -1 : DIFF_TYPES_INDEXES[days === 0 ? 'months' : 'days'];

  return `${years ? `${years} year${years > 1 ? 's' : ''} ` : ''}${
    showAndIndex === DIFF_TYPES_INDEXES.months ? 'and ' : ''
  }${months ? `${months} month${months > 1 ? 's' : ''} ` : ''}${
    showAndIndex === DIFF_TYPES_INDEXES.days ? 'and ' : ''
  }${days ? `${days} day${days > 1 ? 's' : ''}` : ''}`.trim();
};

/**
 * Calculates the difference between two dates and returns a formatted text representation.
 * @param {string | number | Date} from - The starting date.
 * @param {string | number | Date} to - The ending date.
 * @param {boolean} inclusive - Whether to include the last day in the calculation.
 * @returns {string} A string representing the difference between the two dates.
 */

export const getDatesDiffTextFns = (from, to, inclusive) => {
  let a = new Date(to);
  let b = new Date(from);

  const years = differenceInYears(a, b);
  b = addYears(b, years);

  const months = differenceInMonths(a, b);
  b = addMonths(b, months);

  const days = inclusive ? differenceInDays(addDays(a, 1), b) : differenceInDays(a, b);

  // Show 'and' before the last diff type
  const diffTypesAmount = [years, months, days].filter((type) => type !== 0).length;
  const showAndIndex = diffTypesAmount <= 1 ? -1 : DIFF_TYPES_INDEXES[days === 0 ? 'months' : 'days'];

  return `${years ? `${years} year${years > 1 ? 's' : ''} ` : ''}${
    showAndIndex === DIFF_TYPES_INDEXES.months ? 'and ' : ''
  }${months ? `${months} month${months > 1 ? 's' : ''} ` : ''}${
    showAndIndex === DIFF_TYPES_INDEXES.days ? 'and ' : ''
  }${days ? `${days} day${days > 1 ? 's' : ''}` : ''}`.trim();
};

export const dateRangeFormat = (from, to, timezone, separator = '-') => {
  if (!to) return dateFormat(from, timezone);

  const fromYear = moment(from).year();
  const toYear = moment(to).year();
  const isSameYear = fromYear === toYear;
  const fromFormat = isSameYear ? 'MMM Do' : 'MMM Do, YYYY';
  const toFormat = 'MMM Do, YYYY';
  const days = moment(from).diff(moment(to), 'days');

  return `${
    days ? `${parseMixedDate(moment.utc(from), timezone).format(fromFormat)} ${separator} ` : ''
  }${parseMixedDate(moment.utc(to), timezone).format(toFormat)}`;
};

export const dateFormat = (date, tz) => {
  return parseMixedDate(date, tz).format(dateFormats.shortWithYear);
};

export const dateFormatSimple = (date, tz) => {
  return SimpleDate.parseMixed(date, tz)?.formatPretty();
};

export const dateFormatAsUTC = (date, format = 'YYYY-MM-DD') => {
  if (!date) return undefined;
  return moment.utc(moment(date)).format(format);
};

export const toPlainDate = (date, timezone) => {
  return (timezone ? moment(date).tz(timezone) : moment(date)).format('YYYY-MM-DD');
};

export const convertTZ = (date, timeZone) => {
  // Try create date with timezone
  // If timezone is incorrect, create date without timezone option
  try {
    return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone }));
  } catch (error) {
    return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US'));
  }
};

export const convertTZEOD = (date, timeZone) => {
  let parsedDate = createDate(date);

  try {
    return new Date(format(parsedDate, DateFormat.END_OF_DAY, { timeZone }));
  } catch (error) {
    if (!parsedDate || isNaN(parsedDate.getTime())) {
      return new Date(format(new Date(), DateFormat.END_OF_DAY));
    }
    return new Date(format(parsedDate, DateFormat.END_OF_DAY));
  }
};

export const getNewDateWithoutTimezoneOffset = (date) => {
  const userTimezoneOffset = date.getTimezoneOffset() * MILLISECONDS_IN_MINUTE;
  return new Date(date.getTime() + userTimezoneOffset);
};

export const getTerminatingWarningText = (
  completionDate,
  tz,
  prefix = i18n.t('temp.unknown.utils.time.js.3f517470fb'),
  inclusive = false
) => {
  let futureDate = moment(completionDate);
  if (tz) {
    futureDate = futureDate.tz(tz);
  }

  if (inclusive) {
    futureDate.add(1, 'd');
  }

  const days = futureDate.diff(moment(), 'd');

  let text = `${prefix} ${days} days`;

  days < 0 &&
    (text = i18n.t('temp.unknown.utils.time.js.68727a25ac', { v0: moment.utc(completionDate).format('MMM Do, YYYY') }));
  days === 0 && (text = i18n.t('temp.unknown.utils.time.js.b363039281'));
  days === 1 && (text = i18n.t('temp.unknown.utils.time.js.a6640cd309'));

  return days <= 45 ? text : '';
};

export const getDateToFormat = (date, timezone, format = 'MMMM Do, YYYY') => {
  const ret = moment(date).tz(timezone).format(format);
  if (ret.toLowerCase() === 'invalid date') {
    return '-';
  }
  return ret;
};

export const getUtcTimestamp = (date = null) => {
  const dateObject = date ? new Date(date) : new Date();
  return Math.floor(dateObject?.getTime() / 1000);
};

export const pickDate = (dateA, dateB, getBiggest = true) => {
  const comparison = getBiggest ? moment(dateA) > moment(dateB) : moment(dateA) < moment(dateB);
  return comparison ? dateA : dateB;
};

export const genMonthRange = (date) => {
  const fromDate = moment(date)
    .startOf('month')
    .startOf('day')
    // .subtract(new Date().getTimezoneOffset(), 'm')
    .toISOString();
  const toDate = moment(date)
    .endOf('month')
    .endOf('day')
    // .subtract(new Date().getTimezoneOffset(), 'm')
    .toISOString();
  return { fromDate, toDate };
};

export const countryNeedsMonthlyAndBimonthlyCycle = (countryKey) => countryKey === 'US' || countryKey === 'CA';

export function getWorkCycleOptions(countryKey, needBoth = false, monthsBefore = 12, monthsAfter = 0) {
  // should be replaced with an API call
  let cycles = [];
  const hasCountry = !!countryKey;
  const isSemiCountry = LookupStore.isBiMonthlyCountry(countryKey);

  needBoth = needBoth || countryNeedsMonthlyAndBimonthlyCycle(countryKey);

  const startDate = new moment.utc();
  for (let i = -monthsAfter; i < monthsBefore; i++) {
    const cycleMonth = moment(startDate).subtract(i, 'months');
    const firstDayOfMonth = cycleMonth.startOf('month').toDate();
    const lastDayOfMonth = cycleMonth.endOf('month').toDate();
    if (!hasCountry || !isSemiCountry || needBoth) {
      cycles.push({
        from: firstDayOfMonth,
        to: lastDayOfMonth,
      });
    }
    if (!hasCountry || isSemiCountry || needBoth) {
      cycles.push(
        {
          from: moment(cycleMonth).set('date', 16).toDate(),
          to: lastDayOfMonth,
        },
        {
          from: firstDayOfMonth,
          to: moment(cycleMonth).set('date', 15).toDate(),
        }
      );
    }
  }

  return cycles.map((cycle) => ({
    label: dateRangeFormat(cycle.from, cycle.to, 'UTC'),
    value: cycle,
  }));
}

export const getCycleByDate = (date, biWeekly = false, timezone = undefined) => {
  let from = moment.tz(date, timezone).startOf('month');
  let to = moment.tz(date, timezone).endOf('month');

  if (biWeekly) {
    const endOfFirstHalf = from.add(15, 'days');
    if (moment.tz(date, timezone).isAfter(endOfFirstHalf)) {
      from = endOfFirstHalf.add(1, 'days');
    } else {
      to = endOfFirstHalf;
    }
  }

  return { from, to };
};

export const getTimeAgo = (date) => {
  if (!date || date.getTime() === new Date(null).getTime()) return '';
  return moment(date).fromNow();
};

export const getTimeFrom = (date, compareDate, hidePrefix = true) => {
  if (!date || moment(date).valueOf() === moment().valueOf()) return '';
  if (!compareDate) {
    return moment().from(date, hidePrefix);
  }
  return moment(compareDate).from(date, hidePrefix);
};

export const genWorkDuration = (val) => {
  const hours = parseInt(String(val));
  const mins = parseInt(String(Number((val % 1).toFixed(2)) * 100));
  const minsStr = !mins ? '' : `${mins} min${mins === 0 || mins > 1 ? 's' : ''}`;
  if (!hours) return minsStr;
  const hoursStr = `${hours} hour${hours === 0 || hours > 1 ? 's' : ''}`;
  return `${hoursStr} ${minsStr}`;
};

export const genHumanTerm = (term) => {
  // @ts-ignore
  const amount = Number(term?.match?.(/(\d+)/)[0]);
  if (term === `P${amount}M`) {
    return i18n.t('temp.unknown.utils.time.js.58643eface', { v0: amount, v1: amount === 0 || amount > 1 ? 's' : '' });
  }
  return capitalize(moment.duration(term).humanize());
};

export const getDaysTillEndDate = (endDate, timezone) =>
  getDateDiffWithTZ(new Date(), moment(endDate).endOf('day'), timezone);

export const hasDatePassed = (isoDateString) => moment().utc().startOf('day').diff(moment(isoDateString)) > 0;

export const isDateTomorrow = (isoDateString = '') => {
  const tomorrow = moment().utc().add(1, 'day').endOf('day');
  return tomorrow.diff(moment(isoDateString).endOf('day'), 'day') === 0;
};

export const isPlainDate = (date = '') => {
  return moment(date, 'YYYY-MM-DD', true).isValid();
};
