export const MAX_KYC_SCREENING_ATTEMPTS = 2;

export enum KycStatus {
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum ClientKycStatus {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export enum ShareholderKycStatus {
  Approved = 'approved',
  Rejected = 'rejected',
  PendingReview = 'pending_review',
}

export enum CddStatus {
  PendingReview = 'pending_review',
  Approved = 'approved',
  Rejected = 'rejected',
}
