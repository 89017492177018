import type { CSATRating } from '@/components/CustomerSatisfaction/CustomerSatisfaction';
import { ROUTES } from '@/constants/routesV2';
import type { UserStore } from '@/stores/UserStore';
import { matchPath } from 'react-router-dom';

export enum CSATMassOnboardingActions {
  directEmployee = 'direct-employee-mass-onboarding',
  hrisDirectEmployee = 'hris-direct-employee-mass-onboarding',
  fixed = 'fixed-mass-onboarding',
  payg = 'payg-mass-onboarding',
  milestone = 'milestone-mass-onboarding',
}

export enum CSATProduct {
  contractManagement = 'Contract management',
  reports = 'Reports',
  compliance = 'Compliance documents and verification',
  documents = 'Doc Repository',
  invoicing = 'Invoicing',
  paymentMethods = 'Payment methods',
  organizationSettings = 'Organization Settings',
  taxes = 'Taxes',
  taxRegistration = 'Tax registration',
  taxAdvice = 'tax advice',
  expenses = 'Expenses',
  appsAndPerks = 'Apps & Perks',
  payroll = 'Payroll and Payslips',
  healthInsurance = 'Health insurance & Pension',
  timeTracking = 'time_tracking',
  timeOff = 'Time-off',
  useProfileSetup = 'Use profile setup',
  insurance = 'Insurance',
  teamManagement = 'Group Management',
  transactions = 'Transactions',
  onboarding = 'Onboarding',
  onboardingTracker = 'Onboarding Tracker',
  globalOnboardingTracker = 'Global Onboarding Tracker',
  globalOffboardingTracker = 'Global Offboarding Tracker',
  massOnboarding = 'Mass Onboarding',
  onboardingSteps = 'Onboarding Steps',
  quote = 'Quote',
  homePage = 'Home page',
  topNav = 'Navigation',
  customDomain = 'Custom Domain',
  entity = 'Entity',
  organizationManagement = 'Organization Management',
  organizationTeams = 'Organization Groups',
  terminations = 'Terminations',
  // the only difference with the one above is that first letter is in lower
  // case. Should it be used at all?
  terminationsLC = 'terminations',
  adjustments = 'Adjustments',
  amendments = 'Amendments',
  payment = 'Payment',
  withdrawal = 'withdrawal',
  benefits = 'Benefits',
  contractEnding = 'Contract ending tracker',
  hofy = 'Hofy',
  extra = 'extra',
  wework = 'WeWork',
  travelInsurance = 'Travel Insurance',
  productTour = 'Product tour',
  helpCenter = 'Help Center',
  engagePlugins = 'deel_engage',
  deelHr = 'deel_hr',
  netsuite = 'Netsuite',
  quickbooks = 'Quickbooks',
  xero = 'Xero',
  bamboohr = 'BambooHR',
  expensify = 'Expensify',
  slack = 'Slack',
  security = 'Security Issue',
  nda = 'Non disclosure agreement',
  visaImmigration = 'Visa Immigration',
  developerCenter = 'Developer Center',
  mfa = 'Mfa',
  workflow = 'Workflow',
  icpPortal = 'ICP Portal',
  hrisMassApproval = 'HRIS Mass Approvals',
  backgroundChecks = 'Background Checks',
  i9 = 'i9',
  complianceAndInsights = 'Compliance and Insights',
  kyc = 'KYC',
  equity = 'Equity',
  ownedEquipment = 'owned_equipment',
  equipmentInventory = 'equipment_inventory',
  globalPayroll = 'Global Payroll',
  peo = 'PEO',
  notificationsSettings = 'Notifications Settings',
  manageWorkScopes = 'Manage Work Scopes',
  masspay = 'masspay',
  payIn = 'pay_in',
  customAgreements = 'Custom Agreements',
  documentsPage = 'documents',
  coworking = 'coworking',
  accessManagement = 'Access Management',
  localization = 'Translation',
  bankDetails = 'Bank Details',
  activationOnboarding = 'onboarding',
}

export enum CSATDeveloperCenterCategory {
  Apps = 'Apps',
  Sandbox = 'Sandbox',
  Tokens = 'Tokens',
  Webhooks = 'Webhooks',
}

export enum CSATDeveloperCenterAction {
  CreateApps = 'Create an OAuth app',
  CreateSandbox = 'Create a sandbox',
  CreateTokens = 'Create a token',
  CreateWebhooks = 'Create webhooks',
}

export enum CSATTeam {
  contracts = 'Contracts',
  contractorExperience = 'Contractor Experience',
  eor = 'EOR',
  onboarding = 'Onboarding',
  payIns = 'Pay-ins',
  payouts = 'Payouts',
  platform = 'Platform',
  hris = 'HRIS',
  extra = 'Extra',
  knowledge = 'Knowledge',
  invoice = 'Invoice',
  hrCore = 'HR Core',
  hrPmorg = 'deel_hr_org_chart',
  hrJourneys = 'deel_hr_journeys',
  hrActivation = 'deel_hr_activation',
  hrCoreData = 'deel_hr_core_data',
  integrations = 'Integrations',
  mobility = 'mobility',
  engagePlugins = 'Engage',
  timeTracking = 'time_tracking',
  timeOff = 'time_off',
  globalPayroll = 'global_payroll',
  home = 'Home',
  notifications = 'notifications',
  documents = 'Documents',
  localization = 'Localization',
  peo = 'PEO',
}

export enum CSATProductVertical {
  connectivity = 'Connectivity',
  data = 'Data',
  fintech = 'Fintech',
  knowledge = 'Knowledge',
  embeddedPayroll = 'Embedded Payroll',
  globalPayroll = 'Global Payroll',
  eor = 'EOR',
  deelHR = 'Deel HR',
  platform = 'Platform',
  contractors = 'Contractors',
  localization = 'Localization',
  peo = 'PEO',
}

export enum CSATCategory {
  fullTimeEmployee = 'full_time_employee',
  peopleList = 'people_list',
  profileClient = 'profile_client',
  profileEmployee = 'profile_employee',
  analyticsReportsClient = 'analytics_reports_client',
  analyticsReportsWorker = 'analytics_reports_worker',
  addEmployeeClient = 'add_employee_client',
  offboardingClient = 'offboarding_client',
  settingUpIntegration = 'setting_up_integration',
  settingUpExpenseAccountMapping = 'setting_up_expense_account_mapping',
  contractor = 'contractor',
  customField = 'custom_field',
  slackNotificationsDrawer = 'slack_notifications_drawer',
  direct_employee = 'direct_employee',
  approvals = 'approvals',
  approvals_requester = 'Requester',
  approvals_approver = 'Approver',
  admin_policies = 'Admin Policies',
  admin_requests = 'Admin Requests',
  workerProfile = 'workerProfile',
  hrisDirectEmployee = 'hris_direct_employee',
  demographicInformation = 'demographic_information',
  dataChange = 'data_change',
  orgChart = 'org_chart',
  orgStructure = 'org_structure',
  workerRelation = 'worker_relation',
  getStartedPage = 'get_started_page_hr',
  globalPayroll = 'global_payroll',
  fillableForm = 'fillable_form',
}

export enum CSATDataChangetActions {
  approval = 'data_change_approval',
  filterLog = 'filter_data_change_history',
  approvalsFeedback = 'approvals_feedback',
}

export enum CSATDemographicFieldsActions {
  enableDemographicField = 'enable_demographic_field',
}

export enum CSATCustomFieldActions {
  customFieldCreatedClient = 'custom_field_created_client',
}

export enum CSATPeopleActions {
  customiseColumnsClient = 'customise_columns_client',
  profileVisited = 'profileVisited',
  terminationScheduled = 'termination_scheduled',
  employeeAdded = 'employee_added',
  employeeAgreementAdded = 'ea_added',
  employeeAgreementDeleted = 'ea_deleted',
  analyticsReportsVisited = 'analytics_reports_visited',
  onboardingCompleted = 'onboarding_completed',
  ptoEmployee = 'time_off_requested',
  searchCompletedClient = 'search_completed_client',
  searchCompletedWorker = 'search_completed_worker',
}

export enum CSATIntegrationActions {
  setupComplete = 'setup_complete',
  mappingSaved = 'mapping_saved',
  openedSlackNotificationsDrawer = 'opened_slack_notifications_drawer',
  setupGetStartedComplete = 'finished_setup_get_started_hr',
}

export enum CSATContractorExperienceActions {
  externalInvoiceCreated = 'external_invoice_created',
  externalExpenseCreated = 'external_expense_created',
  externalInvoiceUploaded = 'external_invoice_uploaded',
  taxRegistrationFormSubmit = 'tax_registration_form_submit',
  taxAdviceRequested = 'tax_advice_requested',
}

export enum CSATOrgChartActions {
  experienceClient = 'org_chart_experience_client',
  experienceWorker = 'org_chart_experience_worker',
}

export enum CSATOrgStructureActions {
  setup = 'setup_org_structure',
  assignWorker = 'assign_worker_org_structure',
  assignWorkerRelation = 'assign_worker_worker_relation',
  setupWorkerRelation = 'setup_worker_relation',
}

export enum CSATWorkerProfileActions {
  dataUpdateByWorker = 'data_change_profile_worker',
  dataUpdateByClient = 'data_change_profile_client',
  directEmployeeTermination = 'direct_employee_termination',
  directEmployeeCreation = 'onboarding_direct_employee_with_rcc2',
  massImportDirectEmployee = 'mass_import_direct_employee',
}

export const CSATGlobalPayrollActions = {
  VIEW_PAYROLL_EVENTS_TABLE: 'view_payroll_events_table',
  SUBMIT_PAYROLL_REPORT: 'submit_payroll_report',
  APPROVE_PAYROLL_REPORT: 'approve_payroll_report',
  SET_PAYROLL_EVENTS_TABLE_FILTERS: 'set_payroll_events_table_filters',
  VIEW_GP_CALENDAR: 'view_gp_calendar',
} as const;

export const CSATAnalyticsReportsActions = {
  VIEW_EOR_VARIANCE_REPORT: 'view_eor_variance_report',
  VIEW_COST_CENTER_ALLOCATION_REPORT: 'view_cost_center_allocation_report',
  VIEW_GROSS_TO_NET_REPORT: 'view_gross_to_net_report',
  VIEW_GLOBAL_GROSS_TO_NET_REPORT: 'view_global_gross_to_net_report',
  VIEW_EMPLOYEE_CONTRIBUTIONS_REPORT: 'view_employee_contributions_report',
  VIEW_EMPLOYEE_DEDUCTIONS_REPORT: 'view_employee_deductions_report',
  VIEW_PEOPLE_SUMMARY_REPORT: 'view_people_summary_report',
  VIEW_PENDING_ADJUSTMENTS_REPORT: 'view_pending_adjustments_report',
  VIEW_EMPLOYER_COST_REPORT: 'view_employer_cost_report',
  VIEW_TOTAL_PAYROLL_SUMMARY_REPORT: 'view_total_payroll_summary_report',
  VIEW_PAYROLL_SUMMARY_REPORT: 'view_payroll_summary_report',
} as const;

export enum CSATWorkflowActions {
  workflowCreated = 'workflow_creation_completed',
  workflowVisited = 'workflow_visited',
}

export enum CSATBulkEditActions {
  downloadFlowCompleted = 'download_flow_completed',
  uploadFlowCompleted = 'upload_flow_completed',
  uploadFlowExitedAfterDownload = 'upload_flow_exited_after_download',
}

export const product2Category: Partial<Record<CSATProduct, CSATCategory>> = {
  [CSATProduct.adjustments]: CSATCategory.fullTimeEmployee,
  [CSATProduct.amendments]: CSATCategory.fullTimeEmployee,
  [CSATProduct.benefits]: CSATCategory.fullTimeEmployee,
  [CSATProduct.healthInsurance]: CSATCategory.fullTimeEmployee,
  [CSATProduct.onboardingTracker]: CSATCategory.fullTimeEmployee,
  [CSATProduct.timeOff]: CSATCategory.fullTimeEmployee,
};

export const product2Team: Partial<Record<CSATProduct, CSATTeam>> = {
  [CSATProduct.adjustments]: CSATTeam.eor,
  [CSATProduct.amendments]: CSATTeam.eor,
  [CSATProduct.contractManagement]: CSATTeam.contracts,
  [CSATProduct.compliance]: CSATTeam.onboarding,
  [CSATProduct.invoicing]: CSATTeam.invoice,
  [CSATProduct.paymentMethods]: CSATTeam.payIns,
  [CSATProduct.organizationSettings]: CSATTeam.platform,
  [CSATProduct.taxes]: CSATTeam.contracts,
  [CSATProduct.appsAndPerks]: CSATTeam.extra,
  [CSATProduct.payroll]: CSATTeam.eor,
  [CSATProduct.healthInsurance]: CSATTeam.eor,
  [CSATProduct.timeOff]: CSATTeam.eor,
  [CSATProduct.useProfileSetup]: CSATTeam.eor,
  [CSATProduct.insurance]: CSATTeam.eor,
  [CSATProduct.teamManagement]: CSATTeam.platform,
  [CSATProduct.transactions]: CSATTeam.contracts,
  [CSATProduct.onboarding]: CSATTeam.onboarding,
  [CSATProduct.onboardingTracker]: CSATTeam.onboarding,
  [CSATProduct.globalOnboardingTracker]: CSATTeam.onboarding,
  [CSATProduct.onboardingSteps]: CSATTeam.onboarding,
  [CSATProduct.quote]: CSATTeam.eor,
  [CSATProduct.homePage]: CSATTeam.home,
  [CSATProduct.customDomain]: CSATTeam.extra,
  [CSATProduct.entity]: CSATTeam.platform,
  [CSATProduct.organizationManagement]: CSATTeam.platform,
  [CSATProduct.organizationTeams]: CSATTeam.platform,
  [CSATProduct.terminations]: CSATTeam.eor,
  [CSATProduct.payment]: CSATTeam.payIns,
  [CSATProduct.withdrawal]: CSATTeam.payouts,
  [CSATProduct.benefits]: CSATTeam.eor,
  [CSATProduct.hofy]: CSATTeam.extra,
  [CSATProduct.wework]: CSATTeam.extra,
  [CSATProduct.travelInsurance]: CSATTeam.extra,
  [CSATProduct.helpCenter]: CSATTeam.knowledge,
  [CSATProduct.visaImmigration]: CSATTeam.mobility,
  [CSATProduct.taxRegistration]: CSATTeam.contractorExperience,
  [CSATProduct.expenses]: CSATTeam.contractorExperience,
  [CSATProduct.equity]: CSATTeam.extra,
  [CSATProduct.globalPayroll]: CSATTeam.globalPayroll,
  [CSATProduct.localization]: CSATTeam.localization,
};

export const team2Vertical: Partial<Record<CSATTeam, CSATProductVertical>> = {
  [CSATTeam.platform]: CSATProductVertical.platform,
  [CSATTeam.contracts]: CSATProductVertical.contractors,
  [CSATTeam.contractorExperience]: CSATProductVertical.contractors,
  [CSATTeam.eor]: CSATProductVertical.eor,
  [CSATTeam.onboarding]: CSATProductVertical.platform,
  [CSATTeam.payIns]: CSATProductVertical.fintech,
  [CSATTeam.payouts]: CSATProductVertical.fintech,
  [CSATTeam.extra]: CSATProductVertical.contractors,
  [CSATTeam.knowledge]: CSATProductVertical.knowledge,
  [CSATTeam.hris]: CSATProductVertical.deelHR,
  [CSATTeam.invoice]: CSATProductVertical.fintech,
  [CSATTeam.hrCore]: CSATProductVertical.deelHR,
  [CSATTeam.hrPmorg]: CSATProductVertical.deelHR,
  [CSATTeam.integrations]: CSATProductVertical.connectivity,
  [CSATTeam.mobility]: CSATProductVertical.platform,
  [CSATTeam.engagePlugins]: CSATProductVertical.deelHR,
  [CSATTeam.timeOff]: CSATProductVertical.deelHR,
  [CSATTeam.globalPayroll]: CSATProductVertical.globalPayroll,
  [CSATTeam.home]: CSATProductVertical.platform,
  [CSATTeam.localization]: CSATProductVertical.localization,
};

export type CSATProductTopic = { value: string; label: string };

type CSATFeedbackOption = {
  value: string;
  label: string;
  forRating?: CSATRating[];
};

export const FEEDBACK_OPTIONS: CSATFeedbackOption[] = [
  {
    value: 'SHARE_WHAT_WENT_GREAT',
    label: 'customerRating.customerSatisfaction.feedbackOptions.shareWhatWentGreat',
    forRating: ['neutral'],
  },
  {
    value: 'SUGGEST_AN_IMPROVEMENT',
    label: 'customerRating.customerSatisfaction.feedbackOptions.suggestImprovement',
    forRating: ['neutral', 'bad'],
  },
  {
    value: 'REPORT_A_BUG',
    label: 'customerRating.customerSatisfaction.feedbackOptions.reportABug',
    forRating: ['neutral', 'bad'],
  },
  {
    value: 'SHARE_A_COMPLAINT',
    label: 'customerRating.customerSatisfaction.feedbackOptions.shareAComplaint',
    forRating: ['neutral', 'bad'],
  },
];

const feedbackProducts = {
  client: [
    CSATProduct.homePage,
    {
      product: CSATProduct.accessManagement,
      show: () =>
        matchPath(window.location.pathname, {
          path: [ROUTES.settings.managers, ROUTES.settings.organizationSecurity, ROUTES.settings.roles],
        }),
    },
    CSATProduct.contractManagement,
    CSATProduct.reports,
    CSATProduct.compliance,
    CSATProduct.invoicing,
    CSATProduct.organizationSettings,
    CSATProduct.taxes,
    CSATProduct.appsAndPerks,
    CSATProduct.teamManagement,
    CSATProduct.paymentMethods,
    CSATProduct.quote,
    CSATProduct.globalOnboardingTracker,
    CSATProduct.terminations,
    CSATProduct.adjustments,
    CSATProduct.amendments,
    CSATProduct.benefits,
    CSATProduct.security,
    CSATProduct.visaImmigration,
    CSATProduct.notificationsSettings,
    CSATProduct.localization,
  ],
  employee: [
    CSATProduct.homePage,
    CSATProduct.contractManagement,
    CSATProduct.compliance,
    CSATProduct.payroll,
    CSATProduct.appsAndPerks,
    CSATProduct.healthInsurance,
    CSATProduct.timeOff,
    CSATProduct.useProfileSetup,
    CSATProduct.taxes,
    CSATProduct.onboarding,
    CSATProduct.onboardingSteps,
    CSATProduct.adjustments,
    CSATProduct.payment,
    CSATProduct.benefits,
    CSATProduct.security,
    CSATProduct.notificationsSettings,
    CSATProduct.localization,
  ],
  other: [
    CSATProduct.homePage,
    CSATProduct.contractManagement,
    CSATProduct.compliance,
    CSATProduct.invoicing,
    CSATProduct.appsAndPerks,
    CSATProduct.taxes,
    CSATProduct.insurance,
    CSATProduct.transactions,
    CSATProduct.withdrawal,
    CSATProduct.security,
    CSATProduct.notificationsSettings,
    CSATProduct.topNav,
    CSATProduct.localization,
  ],
};

export const getProductsForUserType = (user: UserStore, currentLanguage: string = 'en') => {
  const productsList = user.isEmployee
    ? feedbackProducts.employee
    : user.isClient
    ? feedbackProducts.client
    : feedbackProducts.other;

  return productsList
    .map((item) => (typeof item === 'string' ? item : item?.show() && item.product))
    .filter((item) => (item === CSATProduct.localization ? currentLanguage !== 'en' : item))
    .filter(Boolean) as CSATProduct[];
};

export const mapProducts = (pathname: string): CSATProduct | undefined => {
  switch (pathname) {
    case ROUTES.documents.main:
    case ROUTES.employeeComplianceDocs:
      return CSATProduct.compliance;
    case ROUTES.contracts:
    case ROUTES.contract:
    case ROUTES.create.main:
    case ROUTES.create.fixed:
    case ROUTES.contractPreview:
      return CSATProduct.contractManagement;
    case ROUTES.reports.reportsList:
    case ROUTES.reports.report:
    case ROUTES.reports.main:
      return CSATProduct.reports;
    case ROUTES.invoices:
      return CSATProduct.invoicing;
    case ROUTES.settings.organization:
    case ROUTES.settings.entities:
    case ROUTES.settings.entity:
    case ROUTES.settings.billing:
    case ROUTES.settings.teams:
    case ROUTES.settings.managers:
      return CSATProduct.organizationSettings;
    case ROUTES.settings.paymentMethods:
    case ROUTES.settings.refundMethods:
      return CSATProduct.paymentMethods;
    case ROUTES.transactionHistory:
      return CSATProduct.transactions;
    case ROUTES.taxDocuments:
    case ROUTES.taxes.main:
    case ROUTES.taxes.taxRegistration:
    case ROUTES.taxes.taxRegisterPersonalDetails:
    case ROUTES.taxes.taxRegisterBusinessInfo:
    case ROUTES.taxes.taxRegisterTaxSetup:
    case ROUTES.taxes.taxRegisterTaxEstimation:
    case ROUTES.taxes.taxRegisterBankAccount:
    case ROUTES.taxes.file:
      return CSATProduct.taxes;
    case ROUTES.team.main:
      return CSATProduct.teamManagement;
    case ROUTES.appStore.main:
    case ROUTES.appStore.roots:
    case ROUTES.appsAndIntegrations.main:
    case ROUTES.integrations.integrationAbout:
    case '/perks':
      return CSATProduct.appsAndPerks;
    case ROUTES.globalPayroll.main:
    case ROUTES.globalPayroll.mainOld:
    case ROUTES.globalPayroll.createOffcyclePayrollEvent:
    case ROUTES.globalPayroll.editOffcyclePayrollEvent:
    case ROUTES.globalPayroll.payrollEventOld:
    case ROUTES.globalPayroll.payrollEventGeneral:
    case ROUTES.globalPayroll.payrollEventReport:
    case ROUTES.globalPayroll.payrollEventPayroll:
    case ROUTES.globalPayroll.payrollEventPayment:
    case ROUTES.globalPayroll.payrollEventPreview:
    case ROUTES.globalPayroll.payrollEventCorrection:
    case ROUTES.globalPayroll.payrollEventIssues:
    case ROUTES.globalPayroll.payrollEventInputs:
    case ROUTES.payslips:
      return CSATProduct.payroll;
    case ROUTES.insurance:
      return CSATProduct.insurance;
    case '/':
      return CSATProduct.homePage;
    default:
      return undefined;
  }
};

export enum CSATActionOwnedEquipment {
  Created = 'created',
  Provided = 'provided',
  MarkedReceived = 'marked_received',
  CustomStatusSet = 'custom_status_set',
}

export enum CSATActionEquipmentInventory {
  MassImport = 'mass_import',
  CreateLeasedEquipment = 'create_leased_equipment',
}

export enum CSATActionCoworkingInventory {
  AddFromHub = 'add_from_hub',
  ShowNonEmptyState = 'show_non_empty_state',
}

export type ReviewResponse = { id: number };
