import { addScript, addStyle, getIsSensitivePathname } from '@/external/utils';
import { envShortKey } from '@/utils/environment';
import UserStore from '@/stores/UserStore';
import csatApi from '@/utils/api/csat';
import zendeskApi from '@/utils/api/zendesk';
import instance from '@/utils/api/instance';
import * as Sentry from '@sentry/react';
import { CSATProduct, CSATProductVertical, CSATTeam } from '@/components/CustomerSatisfaction/products';

export const loadExternalScripts = () => {
  if (getIsSensitivePathname(window.location.pathname)) return;

  // <!-- Google Tag Manager -->
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    j.onerror = (error) => {
      console.error(error);
    };
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-5KK3DK6');
  // <!-- End Google Tag Manager -->

  // <!-- Calendly link widget begin -->
  addStyle('https://assets.calendly.com/assets/external/widget.css');
  addScript('https://assets.calendly.com/assets/external/widget.js', { async: 'true' });

  // <!-- Stripe -->
  addScript('https://js.stripe.com/v3/', { id: 'stripe-js', async: 'true' });
  addStyle('https://media.letsdeel.com/css/cookiebot.css');

  // <!-- Zapier -->
  addScript('https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js', {
    type: 'module',
    async: 'true',
  });
  addStyle('https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css');

  // <!-- ChiliPiper -->
  addScript('https://js.chilipiper.com/marketing.js');
};

export const loadZendeskExternalScript = (shouldLoadCohere, callback) => {
  if (getIsSensitivePathname(window.location.pathname)) return;

  if (shouldLoadCohere) {
    if (envShortKey !== 'prod' && envShortKey !== 'demo') {
      window.CohereIntegrationId = 'svpjfqAyMjmd3dKqVp3CQGVH';
    }
    addScript('/externalScripts/cohere.js', {}, callback);
  } else {
    if (envShortKey !== 'prod' && envShortKey !== 'demo') {
      window.SmoochIntegrationId = '615adc0943996700e0678092';
    }
    addScript('/externalScripts/zendesk.js', {}, callback);
  }
};

export const loadZowieExternalScript = (token, instanceId, isNative) => {
  if (getIsSensitivePathname(window.location.pathname)) return;

  if (token) {
    window.zowie = {
      token,
      instanceId:
        instanceId ||
        (envShortKey === 'prod' ? '3ddcda078b504487aac15228c7cb38f0' : '4429aeb98a1b40c48b7bbe8ac3e920c5'),
      isNative,
    };
    addScript('/externalScripts/zowie.js');
  }
};

export const zendeskOpenPostMessage = async () => {
  if (UserStore?.token) {
    try {
      const profileId = JSON.parse(window.atob(UserStore.token?.split('.')[1])).profile;
      const admin = !!JSON.parse(window.atob(UserStore.token?.split('.')[1])).deel;
      if (admin) return;
      instance.defaults.headers['x-auth-token'] = UserStore.token;

      const shouldLoadCohere = localStorage.getItem(`frontend.chat.shouldLoadCohere`);
      const { token, provider, riskScore, tags, accountCustomerTier } = await zendeskApi.getToken(
        shouldLoadCohere === 'true'
      );
      await UserStore.getMyProfile();

      if (token && profileId) {
        const target = import.meta.env.VITE_HELP_DESK_ALLOWED_DOMAIN;
        const { email, name, profileType, company } = UserStore;
        const userData = {
          profileId,
          token,
          provider,
          email,
          riskScore,
          tags: tags ? tags.map(({ Tag }) => (Tag ? Tag.name : '')) : '',
          name,
          profileType,
          accountCustomerTier,
          country: company?.personal?.taxResidence || company?.personal?.citizen,
        };

        parent.postMessage(userData, target);

        window.addEventListener('message', async (e) => {
          const { data, origin } = e;
          if (origin !== target) return;

          switch (data.type) {
            case 'rating': {
              const { rating, article, reasons, message } = data;
              try {
                await csatApi.submit({
                  product: CSATProduct.helpCenter,
                  action: 'rating',
                  rating: rating === 1 ? 'bad' : rating === 5 ? 'good' : 'neutral',
                  message,
                  data: { article, rating, reasons },
                  teamName: CSATTeam.knowledge,
                  productVertical: CSATProductVertical.knowledge,
                });
                parent.postMessage({ ...data, type: 'rating_success' }, target);
              } catch (e) {
                parent.postMessage({ ...data, type: 'rating_error', error: 'Error submitting' }, target);
              }
              break;
            }
            default:
              break;
          }
        });
      }
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          issue_type: 'zendesk-error',
        },
        extra: {
          tokenPart: UserStore.token?.split('.')?.[1],
        },
      });
    }
  }
};
