import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import instance from './api/instance';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const makeHeadRequestWithRetry = async (
  url: string,
  config: AxiosRequestConfig<any>,
  retries = 20,
  delayMs = 1000
): Promise<AxiosResponse> => {
  try {
    // Attempt the HEAD request
    const response = await instance.head(url, {
      responseType: 'text',
    });
    return response;
  } catch (error) {
    if (retries > 0) {
      // Wait for the specified delay before retrying
      await delay(delayMs);

      // Retry the HEAD request
      return makeHeadRequestWithRetry(url, config, retries - 1, delayMs);
    } else {
      // If no retries are left, throw the error
      throw error;
    }
  }
};
