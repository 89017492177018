import libphonenumber from 'google-libphonenumber';

const PNF = libphonenumber.PhoneNumberFormat;
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

/**
 * Parse phone number into e164 format
 * @param {string | number | object} phone
 * @returns {string}
 */
export const getE164String = (phone) => {
  if (!phone) return '';
  if (isNewFormat(phone)) return phone;

  try {
    const number = phoneUtil.parse(phone.number?.toString(), phone.country);
    return phoneUtil.format(number, PNF.E164);
  } catch (error) {
    if (phone.code === '+599') return `${phone.code}${phone.number}`;
    return '';
  }
};

/**
 * Returns the number part of the phone (Without dial code)
 * @param {string | number | object} phone
 * @returns {string}
 */
export const getPhoneNumber = (phone) => {
  if (!phone) return '';
  if (!isNewFormat(phone)) return phone.number;

  try {
    const number = phoneUtil.parse(phone, '');
    return number.getNationalNumber();
  } catch (error) {
    return '';
  }
};

/**
 * Returns the dial code part of the phone (Without number)
 * @param {string | number | object} phone
 * @returns {string}
 */
export const getPhoneCode = (phone) => {
  if (!phone) return '';
  if (!isNewFormat(phone)) return phone.code;

  try {
    const number = phoneUtil.parse(phone, '');
    return `+${number.getCountryCode()}`;
  } catch (error) {
    return '';
  }
};

/**
 * Returns the country of a given phone
 * @param {string | number | object} phone
 * @returns {string}
 */
export const getPhoneCountry = (phone) => {
  if (!phone) return '';
  if (!isNewFormat(phone)) return phone.country;

  try {
    const number = phoneUtil.parse(phone, '');
    return phoneUtil.getRegionCodeForNumber(number);
  } catch (error) {
    return '';
  }
};

/**
 * Check if a given phone is valid for a given country
 * @param {string | number | object} phone
 * @param {string } country
 * @returns {bool}
 */
export const isValidNumberForRegion = (phone, country) => {
  try {
    if (!phone) return false;
    if (!isNewFormat(phone)) {
      const number = phoneUtil.parse(phone.number?.toString(), phone.country);
      return phoneUtil.isValidNumberForRegion(number, country);
    }

    const number = phoneUtil.parse(phone.toString(), country);
    return phoneUtil.isValidNumberForRegion(number, country);
  } catch (error) {}
};

/**
 * Check if a given phone is valid
 * @param {string | number | object} phone
 * @returns {bool}
 */
export const isValidNumber = (phone) => {
  try {
    if (!phone) return false;
    if (!isNewFormat(phone)) {
      const number = phoneUtil.parse(phone.number?.toString(), phone.country);
      return phoneUtil.isValidNumber(number);
    }

    const number = phoneUtil.parse(phone.toString());
    return phoneUtil.isValidNumber(number);
  } catch (error) {
    return false;
  }
};

/**
 * Check if a given phone is in the new format
 * @param {string | number | object} phone
 * @returns {bool}
 */
export const isNewFormat = (phone) => {
  return typeof phone === 'string';
};

/**
 * Check if 2 phones are equal
 * @param {string | number | object} a
 * @param {string | number | object} b
 * @returns {bool}
 */
export const isEqualPhoneNumber = (a, b) => {
  return getE164String(a) === getE164String(b);
};

export const getSupportedCountries = () => {
  return phoneUtil.getSupportedRegions();
};

export const getDialCodeByCountryCode = (country) => {
  return `+${phoneUtil.getCountryCodeForRegion(country)}`;
};

/**
 * Format phone number to present it,  return it as it is if there's no country code.
 * @param {string} phone
 * @param {{countryFormat?: boolean, includeFlag: boolean, countries?: Record<string, {emoji?: string> | {[key: string]: any}}}?} options
 * @returns {string}
 */
export const formatPhoneNumber = (phone, { countryFormat, includeFlag, countries } = {}) => {
  if (!phone) return '';

  let flag = '';

  if (includeFlag && countries) {
    const phoneCountry = getPhoneCountry(phone);

    flag = countries[phoneCountry]?.emoji || '';
  }

  if (countryFormat) {
    try {
      const numberProto = phoneUtil.parse(phone);
      const formattedPhone = phoneUtil.format(numberProto, PNF.INTERNATIONAL);

      return `${flag} ${formattedPhone}`.trim();
    } catch (err) {}
  }
  if (!getPhoneCode(phone) && !flag) {
    return phone;
  }

  return `${flag} ${getPhoneCode(phone)} ${getPhoneNumber(phone)}`.trim();
};
