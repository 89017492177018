import instance from '@/utils/api/instance';

export const BASE_URL = 'global_payroll/mass_termination_xls';
export default {
  preValidateMassTerminationFile: async (fileKey: string): Promise<{ isValid: boolean }> => {
    const endpoint = `${BASE_URL}/pre_validate_mass_termination_xls`;
    const { data } = await instance.post(endpoint, { fileKey });
    return data;
  },
};
