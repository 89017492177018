import { DAYS_OF_WEEK } from '@/constants/time';
import { DateFormat, formatInUtc } from '@letsdeel/ui';
import { captureException, withScope } from '@sentry/react';
import { formatInTimeZone, getTimezoneOffset, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import startOfDay from 'date-fns/startOfDay';
import isValid from 'date-fns/isValid';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { getUserTimezone } from './time';
import { addDays, getHours, isAfter, isBefore, isEqual, isWeekend, parseISO, toDate } from 'date-fns';
import * as Sentry from '@sentry/react';
import { SimpleDate } from './SimpleDate';
export type DateType = string | number | Date;
/**
 *
 * @param offsetDate A date
 * @returns A date where the timezone offset has been removed
 */
export const removeTzOffset = (offsetDate: Date) =>
  new Date(offsetDate.getTime() - getTimezoneOffset(getUserTimezone()));

export const removeDateStringTimestamp = (date: string) => date.split('T')[0].replaceAll('/', '-');

/**
 *
 * @param dateStr A date in string format
 * @returns A UTC date. It ignores the time or timezone information provided and returns a UTC date based on the current date portion only
 */
export function parseDateOnly(dateStr: string) {
  if (!dateStr) return null;

  const dateOnlyStr = removeDateStringTimestamp(dateStr);
  const splitDate = dateOnlyStr.split('-');

  // check date format is YYYY-MM-DD
  if (splitDate.length === 3 && splitDate[0].length === 4 && splitDate[1].length === 2 && splitDate[2].length === 2)
    return parseISO(dateOnlyStr);

  // fallback: remove tz offset manually
  return removeTzOffset(new Date(dateStr));
}

/**
 *
 * @param date A moment input (anything that moment accepts)
 * @param timezone The timezone to be used. Only needed if the date is not in UTC
 * @returns It checks if the date is in UTC. If it is, it parses using moment UTC, if not it returns the date in the timezone. It returns a moment instance
 */
export const parseMixedDate = (date: moment.MomentInput, timezone?: string, returnJsDate = false): moment.Moment => {
  const time = moment.utc(date).hour();
  let toReturn;

  const isUtc = time === 0;

  if (isUtc) {
    toReturn = moment.utc(date);
  } else {
    toReturn = timezone ? moment.tz(date, timezone) : moment(date);
  }
  if (!returnJsDate) return toReturn;
  // @ts-expect-error returning date type for components where moment is being removed
  // but changing the type causes lint failure in other files, even though they'll never reach
  // this code due to the 'returnJsDate' flag being false by default.
  return isUtc
    ? utcToZonedTime(new Date(date as string), 'UTC')
    : timezone
    ? zonedTimeToUtc(new Date(date as string), timezone)
    : new Date(date as string);
};

export const parseMixedDateSimple = (date: string | null | undefined, timezone?: string) =>
  SimpleDate.parseMixed(date, timezone);

const DIFF_TYPES_INDEXES = {
  days: 0,
  months: 1,
};

export const getDatesDiff = (
  from: moment.MomentInput,
  to: moment.MomentInput,
  inclusive: boolean = false,
  timezone?: string
) => {
  const a = parseMixedDate(to, timezone);
  const b = parseMixedDate(from, timezone);

  const totalDays = inclusive ? a.add(1, 'days').diff(b, 'days') : a.diff(b, 'days');

  const years = a.diff(b, 'year');
  b.add(years, 'years');

  const months = a.diff(b, 'months');
  b.add(months, 'months');

  const days = inclusive ? a.add(1, 'days').diff(b, 'days') : a.diff(b, 'days');
  return [days, months, years, totalDays];
};

export const getDatesDiffText = (
  from: moment.MomentInput,
  to: moment.MomentInput,
  inclusive: boolean = false,
  timezone?: string
) => {
  const [days, months, years] = getDatesDiff(from, to, inclusive, timezone);
  // Show 'and' before the last diff type
  const diffTypesAmount = [years, months, days].filter((type) => type !== 0).length;
  const showAndIndex = diffTypesAmount <= 1 ? -1 : DIFF_TYPES_INDEXES[days === 0 ? 'months' : 'days'];

  return `${years ? `${years} year${years > 1 ? 's' : ''} ` : ''}${
    showAndIndex === DIFF_TYPES_INDEXES.months ? 'and ' : ''
  }${months ? `${months} month${months > 1 ? 's' : ''} ` : ''}${
    showAndIndex === DIFF_TYPES_INDEXES.days ? 'and ' : ''
  }${days ? `${days} day${days > 1 ? 's' : ''}` : ''}`.trim();
};

export const getDateAfterBusinessDays = (date: moment.MomentInput, businessDays: number, exclusive = false) => {
  const calculatedDate = date ? moment(date) : moment();
  // if the start date is on the weekend and we want to consider it on monday
  if (exclusive) {
    while (!isBusinessDay(calculatedDate)) {
      calculatedDate.add(1, 'days');
    }
  }

  while (businessDays) {
    calculatedDate.add(1, 'days');
    if (isBusinessDay(calculatedDate)) businessDays--;
  }
  return calculatedDate;
};

export const isBusinessDay = (date: moment.Moment): boolean => date.isoWeekday() !== 6 && date.isoWeekday() !== 7;

export const isValidDate = (date?: any): date is Date => {
  return date && isValid(date);
};

/**
 * Generates hours in a moment recognizable format
 */
export const generateHourOptions = () => {
  const items: string[] = [];
  new Array(24).fill('').forEach((_, index) => {
    items.push(moment({ hour: index }).format('HH:mm'));
  });
  return items.map((hour) => hour);
};

export const isDateBeforeNow = (date: string) => {
  const now = new Date();
  const contractStartingDate = new Date(date);

  return contractStartingDate.getTime() <= now.getTime();
};

export const getDateOnly = (date: string) => format(new Date(date), DateFormat.DATE_ONLY);

/**
 * Get week day by number;
 * @param dayNumber number with range from 1 to 7
 * @returns string
 */
export const getWeekDays = (dayNumber: number): string => {
  if (dayNumber > 7 && dayNumber <= 365) return getWeekDays(dayNumber - 7);
  if (dayNumber > 365) {
    console.warn("dayNumber shouldn't bee more 365");
    return '';
  }
  return DAYS_OF_WEEK[dayNumber - 1];
};

/**
 * When formatting an invalid date, date-fns throws an error.
 * The behavior is different from the previous lib (moment), which returns "Invalid Date".
 * This wrapper was added during the migration to keep the same behavior on existing pages
 * and log the error to sentry so we take action to fix it.
 */
export const wrapDateFn = <A extends (...args: any) => any>(fn: A) => {
  return (...args: Parameters<A>) => {
    try {
      return fn(...args) as ReturnType<A>;
    } catch (err) {
      if (err instanceof RangeError) {
        withScope((scope) => {
          args.forEach((arg: any, i: number) => {
            if (arg instanceof Date) {
              scope.setExtra(`date-received-${i}`, arg);
            }
          });
          captureException(new Error(`The helper '${fn.name}' has invalid format`));
        });
      }

      // intentionally returns empty string instead of "Invalid Date"
      return '';
    }
  };
};

export function isSameOrBefore(date1: DateType, date2: DateType): boolean {
  return isEqual(new Date(date1), new Date(date2)) || isBefore(new Date(date1), new Date(date2));
}

export function isSameOrAfter(date1: DateType, date2: DateType): boolean {
  return isEqual(new Date(date1), new Date(date2)) || isAfter(new Date(date1), new Date(date2));
}

/**
 * Formats a date according to the specified format token. It's a wrapper around the date-fns format function.
 * @param input The date to format. Can be a Date object, a string in ISO 8601 format, or a timestamp.
 * @param formatToken The format token to use for formatting the date.
 * @returns The formatted date as a string.
 * @throws {Error} If the input date is invalid.
 */
export function formatDate(input: DateType, formatToken: DateFormat): string {
  if (!(input && isValid(new Date(input)))) {
    Sentry.withScope((scope) => {
      scope.setTag('issue-context', 'moment-migration');
      Sentry.captureException(new Error('Invalid date'));
    });
  }
  let date: Date;
  let formattedDate: string = '';

  if (typeof input === 'string') {
    date = parseISO(input);
  } else if (typeof input === 'number') {
    date = new Date(input);
  } else {
    date = input;
  }

  try {
    formattedDate = format(date, formatToken);
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTag('issue-context', 'moment-migration');
      Sentry.captureException(error);
    });
  }

  return formattedDate;
}

/**
 * Warning: this function could give wrong results in some cases. Make sure to test well.
 * Format a date in UTC. This function handles multiple timezones.
 * @param input The date to format. Can be a Date object, an ISO8601 string, or timestamp.
 * @param formatToken The format token to be used.
 * @returns The formatted date as string.
 * @throws {Error} If the input date is invalid.
 */
export function formatDateInTimezone(input: DateType, formatToken: DateFormat): string {
  if (!(input && isValid(new Date(input)))) {
    Sentry.withScope((scope) => {
      scope.setTag('issue-context', 'moment-migration');
      Sentry.captureException(new Error('Invalid date'));
    });
  }

  let date: Date;
  let result = '';

  try {
    date = input instanceof Date ? input : new Date(input);

    // This is the best solution I've come so far to handle multiple date formats
    // and also handle mult time timezones.
    // Maybe in the future we can use: https://tc39.es/proposal-temporal/docs/
    // It's going to be part of JavaScript to handle dates in multiple timezones.
    const stamp = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    );
    result = format(stamp, formatToken);
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTag('issue-context', 'moment-migration');
      Sentry.captureException(error);
    });
  }

  return result;
}

export const safeToDate = (date: DateType | null | undefined): Date | undefined => {
  if (!date) return undefined;
  if (typeof date === 'string') return parseISO(date);
  return toDate(date);
};

/**
 * Parses a mixed date value into a JavaScript Date object.
 * @param date - The date value to parse. It can be a string, number, or Date object.
 * @param timezone - Optional. The timezone to apply to the parsed date.
 * @param returnJsDate - Optional. Specifies whether to return the parsed date as a JavaScript Date object.
 *                       If set to true, the parsed date will be converted to UTC and returned as a JavaScript Date object.
 *                       If set to false (default), the parsed date will be returned as is.
 * @returns The parsed date as a JavaScript Date object.
 */
export function parseMixedDateFns(date: DateType, timezone?: string, returnJsDate = false): Date {
  let parsedDate;

  if (typeof date === 'string') {
    parsedDate = parseISO(date);
  } else {
    parsedDate = new Date(date);
  }

  const hour = getHours(parsedDate);
  let toReturn = parsedDate;

  if (hour !== 0) {
    if (timezone) {
      toReturn = utcToZonedTime(parsedDate, timezone);
    }
  }

  if (returnJsDate) {
    if (hour === 0) {
      toReturn = utcToZonedTime(toReturn, 'UTC');
    } else if (timezone) {
      toReturn = utcToZonedTime(toReturn, timezone);
    }
    toReturn = zonedTimeToUtc(toReturn, 'UTC');
  }

  return toReturn;
}

/**
 * Returns a valid Date object if the input date string is valid, otherwise returns the input date string.
 * @param dateString - The input date string to be converted to a Date object.
 * @returns A valid Date object if the input date string is valid, otherwise returns the input date string.
 */
export function getValidDateOrDefault(dateString: DateType): Date {
  const date = new Date(dateString);
  return !(dateString && isValid(date)) ? new Date() : date;
}

/**
 * Calculates the date after a specified number of business days.
 * @param date - The starting date. If not provided, the current date is used.
 * @param businessDays - The number of business days to add.
 * @param exclusive - Determines whether the starting date is included in the calculation. Defaults to false.
 * @returns The date after the specified number of business days.
 */
export const getDateAfterBusinessDaysFns = (date: Date | number, businessDays: number, exclusive = false): Date => {
  let calculatedDate = date ? new Date(date) : new Date();

  /**
   * Checks if a given date is a business day (not a weekend).
   * @param date - The date to check.
   * @returns True if the date is a business day, false otherwise.
   */
  const isBusinessDay = (date: Date) => !isWeekend(date);

  if (exclusive) {
    while (!isBusinessDay(calculatedDate)) {
      calculatedDate = addDays(calculatedDate, 1);
    }
  }

  while (businessDays) {
    calculatedDate = addDays(calculatedDate, 1);
    if (isBusinessDay(calculatedDate)) businessDays--;
  }
  return calculatedDate;
};
export const wrappedFormat = wrapDateFn(format);
export const wrappedFormatInUtc = wrapDateFn(formatInUtc);
export const wrappedFormatDistance = wrapDateFn(formatDistance);
export const wrappedFormatInTimeZone = wrapDateFn(formatInTimeZone);

export const getUTCStartOfDay = (localDate: Date) => {
  const localStartDay = startOfDay(localDate);
  return zonedTimeToUtc(localStartDay, 'UTC');
};
