import { getLoggedInUserInformation, type LoggedInUserInformation } from '@/native/AppPin/appPin';
import { useEffect, useState } from 'react';

export function useLoggedInUserInfo() {
  const [userInfo, setUserInfo] = useState<LoggedInUserInformation | null>(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfo = await getLoggedInUserInformation();
      setUserInfo(userInfo);
    };

    fetchUserInfo();
  }, []);

  return userInfo;
}
