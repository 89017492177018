import { NoticePeriodTimeUnit } from '@/types/EOR/NoticePeriod';
import {
  add,
  addDays,
  format as dateFnsFormat,
  differenceInDays as differenceInDaysFns,
  differenceInBusinessDays as differenceInBusinessDaysFns,
  formatDistance as formatDistanceFns,
  isAfter,
  isBefore,
  parseISO,
  isEqual,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { DATE_FNS_FORMAT } from './dates';
import * as yupSchema from 'yup';

//same as date-fns duration
export enum SimpleDateTimeUnit {
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
}

/**
 * For working with dates, without worrying about times and timezones
 * You only need to specify a timezone when you want to know what date it was at
 * a given instant, for example what date is it now, could be different depending where you are.
 */
export class SimpleDate {
  _date: Date;

  static isoDateRegex = /^(\d\d\d\d)-(\d\d)-(\d\d)$/;
  static naiveDateRegex = /^(\d\d\d\d)-(\d\d)-(\d\d)(T00:00:00(\.0+)?(Z|\+00:00))?$/;
  static naiveDateMixedRegex = /^(\d\d\d\d)-(\d\d)-(\d\d)(T\d\d:\d\d:\d\d(\.\d+)?(Z|\+00:00))?$/;
  /**
   * Constructor can be used with a string in the format YYYY-MM-DD or YYYY-MM-DDT00:00:00Z or a Date.
   * The constructor treats the date as a naive date, which means it will take only the date information
   * and discard the time.
   *
   */
  constructor(date: Date | string | SimpleDate) {
    if (typeof date === 'string') {
      let match = SimpleDate.naiveDateRegex.exec(date);
      if (!match) {
        match = SimpleDate.naiveDateMixedRegex.exec(date);
        if (!match) throw new Error('Invalid date format: ' + date);
      }
      this._date = new Date(parseInt(match[1]), parseInt(match[2]) - 1, parseInt(match[3]));
    } else if (this.isSimpleDate(date)) {
      this._date = date._date;
    } else {
      this._date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
  }

  isSimpleDate = (dt: any): dt is SimpleDate => !!(dt as SimpleDate)._date;

  /**
   *
   * @returns a SimpleDate representing the current day in the local timezone
   */
  static TodayLocal() {
    return new SimpleDate(new Date());
  }

  /**
   *
   * @param tz
   * @returns a SimpleDate representing the current day at a specific timezone.
   * For example what day is it now in Australia? It could be a day ahead of
   * the local date.
   */
  static TodayInTZ(tz: string) {
    const now = new Date();
    return new SimpleDate(utcToZonedTime(now, tz));
  }

  /**
   *
   * @returns a SimpleDate representing the current day at UTC.
   * For example if you are in Brazil (UTC-3), it will usually be the current date
   * but after 21:00 it will be the following date.
   */
  static TodayInUTC() {
    const now = new Date();
    return new SimpleDate(utcToZonedTime(now, 'UTC'));
  }

  /**
   * What date was it at a given instant, in a given time zone.
   * For example the server sends a UTC string representing when something was created:
   * '2024-06-27T03:34:22Z' which means it was 2024-06-27 at UTC, but maybe
   * at the contract timezone (UTC-4) it was 2024-06-26
   * So DateForInstantInTZ(created_at, contract.timezone) = 2024-06-26
   * @returns what day it was at that instant in a specific timezone
   * @param date the Date object or UTC string representing the instant something happened
   * @param tz the TimeZone
   */
  static DateForInstantInTZ(date: Date | string, tz: string) {
    if (typeof date === 'string') {
      return new SimpleDate(utcToZonedTime(parseISO(date), tz));
    } else {
      //Date
      return new SimpleDate(utcToZonedTime(date, tz));
    }
  }

  /**
   * What date was it at a given instant, in the browser's (or environment) local time zone.
   * For example the server sends a UTC string representing when something was created:
   * '2024-06-27T03:34:22Z' which means it was 2024-06-27 at UTC, but maybe
   * at the local timezone (UTC-4) it was still 2024-06-26
   * So DateForInstantLocal(created_at) = 2024-06-26
   * @returns what day it was at that instant in the local time zone.
   * @param date the Date object or UTC string representing the instant something happened
   */
  static DateForInstantLocal(date: Date | string) {
    if (typeof date === 'string') {
      return new SimpleDate(parseISO(date));
    } else {
      //Date
      return new SimpleDate(date);
    }
  }
  static parseOptional(date: string | null | undefined) {
    if (!date) return null;
    return new SimpleDate(date);
  }

  /**
   * Good for using with a DatePicker
   * @param date
   * @returns
   */
  static fromDate(date: Date | null | undefined) {
    if (!date) return null;
    return new SimpleDate(date);
  }

  static parseMixed(date: string | null | undefined, timezone?: string) {
    if (!date) return null;
    let match = SimpleDate.naiveDateRegex.test(date);
    let isUtc = true;
    if (!match) {
      match = SimpleDate.naiveDateMixedRegex.test(date);
      if (!match) throw new Error('Invalid date format: ' + date);
      isUtc = false;
    }

    if (isUtc) {
      return new SimpleDate(date);
    } else {
      return timezone ? SimpleDate.DateForInstantInTZ(date, timezone) : new SimpleDate(date);
    }
  }

  addDays(days: number) {
    const newDate = addDays(this._date, days);
    return new SimpleDate({ _date: newDate } as SimpleDate);
  }

  add(value: number, unit: SimpleDateTimeUnit | NoticePeriodTimeUnit) {
    if (unit === NoticePeriodTimeUnit.DAY) unit = SimpleDateTimeUnit.DAYS;
    else if (unit === NoticePeriodTimeUnit.WEEK) unit = SimpleDateTimeUnit.WEEKS;
    else if (unit === NoticePeriodTimeUnit.MONTH) unit = SimpleDateTimeUnit.MONTHS;

    const newDate = add(this._date, { [unit]: value });
    return new SimpleDate({ _date: newDate } as SimpleDate);
  }

  isAfter(other: SimpleDate) {
    return isAfter(this._date, other._date);
  }

  isBefore(other: SimpleDate) {
    return isBefore(this._date, other._date);
  }

  isSame(other: SimpleDate) {
    return isEqual(this._date, other._date);
  }

  isBeforeOrSame(other: SimpleDate): any {
    return isBefore(this._date, other._date) || isEqual(this._date, other._date);
  }

  isDifferent(other: SimpleDate | null) {
    if (!other) return false;
    return !isEqual(this._date, other._date);
  }

  format(format: string) {
    return dateFnsFormat(this._date, format);
  }

  formatPretty() {
    if (isNaN(this._date as any)) return '';
    return dateFnsFormat(this._date, DATE_FNS_FORMAT);
  }

  toString() {
    return this._date.toDateString();
  }

  /**
   *
   * @returns The date in YYYY-MM-DD format
   */
  toISODateString() {
    if (isNaN(this._date as any)) return '';
    let year = this._date.getFullYear().toString();
    if (year.length < 4) year = year.padStart(4, '0');
    const _month = this._date.getMonth() + 1;
    const month = _month < 10 ? '0' + _month : _month.toString();
    const _day = this._date.getDate();
    const day = _day < 10 ? '0' + _day : _day.toString();
    return year + '-' + month + '-' + day;
  }

  /**
   * @returns a Date object with the current date at midnight
   * (remember Date objects are always local)
   * good for using with a datepicker
   */
  toDate() {
    return new Date(this._date.valueOf());
  }

  static yup() {
    return yupSchema.object().shape({ _date: yupSchema.date() });
  }
}

export const differenceInDays = (left: SimpleDate, right: SimpleDate) => differenceInDaysFns(left._date, right._date);
export const differenceInBusinessDays = (left: SimpleDate, right: SimpleDate) =>
  differenceInBusinessDaysFns(left._date, right._date);
export const formatDistance = (left: SimpleDate, right: SimpleDate) => formatDistanceFns(left._date, right._date);
