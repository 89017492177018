/* Validation function for Australian Business Numbers (ABNs)
 * Reference: https://abr.business.gov.au/Help/AbnFormat
 **/
export const validateABN = (abn?: string): boolean => {
  /* ABN validation algorithm:
   * This function removes any non-numeric characters from the input ABN, checks if the
   * resulting string is 11 digits long, performs the ABN validation algorithm, and
   * returns true if the ABN is valid and false otherwise.
   *
   * To get test ABNs: https://abr.business.gov.au/Search/ResultsAll?SearchText=testing
   * Samples:
   * Valid ABNs:
   *  - 30 601 871 400
   *  - 42 005 008 524
   *  - 21 129 725 426
   * Invalid ABNs:
   *  - 53 004 085 617
   *  - 53 004 085 610
   **/
  if (!abn || typeof abn !== 'string') {
    return false;
  }

  // Remove any non-numeric characters from the ABN
  const cleanedABN = abn.replace(/\D/g, '');

  // Check if the cleaned ABN is 11 digits long
  if (cleanedABN.length !== 11) {
    return false;
  }

  const weightingFactors = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

  // Convert the ABN string to an array of digits
  const abnDigits = cleanedABN.split('').map(Number);

  // Subtract 1 from the first (left-most) digit
  abnDigits[0] = abnDigits[0] - 1;

  // Multiply each of the digits in this new number by a "weighting factor" based on its position
  // and sum the resulting 11 products
  let weightedSum = 0;
  for (let i = 0; i <= 10; i++) {
    weightedSum += abnDigits[i] * weightingFactors[i];
  }

  // Divide the sum total by 89, noting the remainder
  const remainder = weightedSum % 89;

  // If the remainder is zero the number is a valid ABN
  if (remainder === 0) {
    return true;
  }

  return false;
};
