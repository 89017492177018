import type { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { PermissionProvider } from '@letsdeel/ui/dist/shared';
import type { PermissionsMap } from '@letsdeel/ui/dist/shared/PermissionProvider/PermissionProvider.props';
import useUserStore from '@/hooks/useUserStore';
import CurrentTeamRoleProvider from './CurrentTeamRoleProvider';

interface Props {
  children: ReactNode;
}

export const PERMISSIONS_DEBUG_KEY = 'permissionDebug';

const AuthorizationProvider = ({ children }: Props) => {
  const user = useUserStore();
  const permissionsToRoles = user.permissionsToRoles;
  const debugModes = localStorage.getItem(PERMISSIONS_DEBUG_KEY)
    ? JSON.parse(localStorage.getItem(PERMISSIONS_DEBUG_KEY)!)
    : false;

  return (
    <PermissionProvider
      permissionMapping={permissionsToRoles as PermissionsMap}
      debug={Boolean(debugModes)}
    >
      <CurrentTeamRoleProvider>{children}</CurrentTeamRoleProvider>
    </PermissionProvider>
  );
};

export default observer(AuthorizationProvider);
