import instance from '@/utils/api/instance';

export default {
  addVat: async ({ id, percentage, recurring, description, paymentCycleId }: any) => {
    const { data } = await instance.post(`/contracts/${id}/percentage-adjustment`, {
      percentage,
      recurring,
      description,
      paymentCycleId,
    });
    return data;
  },
};
