import { action, observable, makeObservable } from 'mobx';
import { parseQueryValue } from '@/utils/location';

import {
  getNewContractStateFilter,
  getNewContractTypeOptions,
  getNewFilterOptions,
  REFUND_STATUS_OPTIONS,
} from '../components/ContractsTable/components/ContractsFilters/helpers';

import eorApi from '@/utils/api/eor';
import contractApi from '@/utils/api/contract';

import OrganizationsStore from './OrganizationsStore';
import type { UserStore } from './UserStore';
import type { LookupStore } from './LookupStore';
import type { TeamStore } from './TeamStore';
import type { LookupsCurrencies } from '@/types/Lookups';
import type { ContractorStoreFilters, Options, ContractorScopes } from '../types/Contract';

const GET_CONTRACTS_LIMIT = 20;

const FILTER_OPTIONS_KEY_NAMES = {
  team_id: {
    label: 'team',
    optionField: 'team_id',
  },
  client_legal_entity_id: {
    label: 'entity',
    optionField: 'client_legal_entity_id',
  },
  contract_type: {
    label: 'contractType',
    optionField: 'contract_type',
  },
  status: {
    label: 'contractStatus',
    optionField: 'status',
  },
  country: {
    label: 'country',
    optionField: 'country',
  },
  currency: {
    label: 'currency',
    optionField: 'currency',
  },
  refundStatus: {
    label: 'refundStatus',
    optionField: 'refundStatus',
  },
  payment_due: {
    label: 'paymentDue',
    optionField: 'payment_due',
  },
};

export class ContractsStore {
  constructor() {
    makeObservable(this, {
      filterOptionsKeyNames: true,
      filters: true,
      initContractStateOption: true,
      initEntityOptions: true,
      initTeamOptions: true,
      initCountryOptions: true,
      initCurrencyOptions: true,
      initCustomFields: true,
      resetFilters: true,
      initFilters: true,
      modifyOptions: true,
      initFiltersFromUrl: true,
      getFilters: true,
      deleteDraftContract: true,
      findIndexById: true,
      findItemById: true,
      items: observable,
      count: observable,
      cursor: observable,
      hasMore: observable,
      payBeforeTermination: observable,
      scopes: observable,
      getList: action,
      getAndSaveContracts: action,
      archiveContract: action,
      deleteContract: action,
      rejectContract: action,
      deleteQuote: action,
      update: action,
      clearContracts: action,
      setPayBeforeTermination: action,
    });
  }

  items: any[] = [];
  count = 0;
  cursor = null;
  // Pagination state
  hasMore = true;

  filterOptionsKeyNames = FILTER_OPTIONS_KEY_NAMES;
  //contracts Filters State
  filters: ContractorStoreFilters = {
    filtersOptions: getNewFilterOptions(),
    contractTypeOptions: getNewContractTypeOptions(),
    contractStateOptions: [],
    teamOptions: [],
    entityOptions: [],
    countryOptions: [],
    currencyOptions: [],
    refundStatusOptions: REFUND_STATUS_OPTIONS,
    customFields: [],
  };

  initContractStateOption(isEor: boolean) {
    const contractStateFilter = getNewContractStateFilter();
    this.filters.contractStateOptions = isEor ? contractStateFilter.eor : contractStateFilter.client;
  }

  initEntityOptions = () => {
    this.filters.entityOptions = [
      { value: 'all', label: 'All entities', type: 'all' },
      ...(OrganizationsStore.legalEntities?.map?.(({ id, name }) => ({ value: String(id), label: name })) || []),
    ];
    return this.filters.entityOptions;
  };

  initTeamOptions = (teams: { id: string; name: string }[], user: UserStore) => {
    this.filters.teamOptions = [
      { value: 'all', label: 'All groups', type: 'all' },
      ...(teams?.map((team) => ({ value: team.id, label: team.name })) ?? []),
    ];

    this.filters.filtersOptions = this.filters.filtersOptions.map((option) =>
      option.value === 'team' ? { ...option, checked: true } : option
    );

    if (user?.team?.id) {
      this.filters.teamOptions = this.filters.teamOptions?.map((option: Options) =>
        // @ts-ignore
        option.value === user.team.id ? { ...option, checked: true } : { ...option, checked: false }
      );
    }
  };

  initCountryOptions = (allowedCountriesList: Options[]) => {
    this.filters.countryOptions = allowedCountriesList.map(({ label, value }) => ({ value, label }));
  };

  initCurrencyOptions = (currencies: LookupsCurrencies) => {
    this.filters.currencyOptions = Object.values(currencies)
      .filter(({ isContract }) => isContract)
      .map(({ label, name, value }) => ({
        value,
        label: `${label} - ${name}`,
      }));
  };

  initCustomFields = async () => {
    try {
      const customFields = await contractApi.loadCustomFields();

      this.filters.customFields = (customFields || []).map((field) => ({
        ...field,
        options: [
          { value: 'all', label: 'All values', type: 'all' },
          ...(field.options || []).map((option) => {
            if (!option.value) return { ...option, value: '' };
            return option;
          }),
        ],
      }));
    } catch (error) {
      console.error(error);
    }
  };

  resetFilters = () => {
    // eslint-disable-next-line no-console
    console.log('Contracts Store initFilter never called');
  };

  initFilters = ({
    UserStore,
    LookupStore,
    TeamStore,
  }: {
    UserStore: UserStore;
    LookupStore: LookupStore;
    TeamStore: TeamStore;
  }) => {
    //implemented that way to expose a resetFilter func without params.
    this.resetFilters = () => {
      this.filters.filtersOptions = getNewFilterOptions().filter(
        ({ value }) =>
          !(value === 'entity' && OrganizationsStore.legalEntities && OrganizationsStore.legalEntities?.length < 2)
      );
      this.filters.contractTypeOptions = getNewContractTypeOptions(UserStore.isGPEnabled);
      this.filters.refundStatusOptions = REFUND_STATUS_OPTIONS;
      if (UserStore.isLoggedIn) this.initCustomFields();
      this.initTeamOptions(TeamStore.teams, UserStore);
      this.initEntityOptions();
      this.initContractStateOption(UserStore.isEor);
      this.initCountryOptions(LookupStore.allowedCountriesList);
      this.initCurrencyOptions(LookupStore.currencies);
    };

    this.resetFilters();
  };

  modifyOptions = (keys: string[], options: Options[]) => {
    let newOptions = [...options];

    keys.forEach((key) => {
      newOptions = newOptions.map((option) => (option.value === key ? { ...option, checked: true } : option));
    });

    return newOptions;
  };

  initFiltersFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);

    const contractTypes = urlParams.getAll('types[]');
    const contractStatues = urlParams.getAll('statuses[]');
    const expiringStatus = urlParams.get('expiring');
    const onProbation = urlParams.get('onProbation');

    // Supports legacy code
    const parsedStatuses = parseQueryValue('status');

    if (contractTypes?.length) {
      this.filters.contractTypeOptions = this.modifyOptions(contractTypes, this.filters.contractTypeOptions);
    }

    // Support legacy code
    if (parsedStatuses?.length) {
      this.filters.contractStateOptions = this.filters.contractStateOptions.map((option) => {
        return {
          ...option,
          checked: !!parsedStatuses?.includes(option?.value),
        };
      });
    } else if (contractStatues?.length) {
      this.filters.contractStateOptions = this.modifyOptions(contractStatues, this.filters.contractStateOptions);
    } else if (expiringStatus) {
      this.filters.contractStateOptions = this.filters.contractStateOptions.map((option) => {
        if (option.value === 'expiring') {
          return { ...option, checked: true };
        }
        return option;
      });
    }

    if (onProbation) {
      this.filters.contractStateOptions = this.filters.contractStateOptions.map((option) => {
        if (option.value === 'onProbation') {
          return { ...option, checked: true };
        }

        return option;
      });
    }
  };

  getFilters = () => {
    this.initFiltersFromUrl();
    return this.filters;
  };

  getList = async (
    options: { search?: string; get_all?: boolean; team_id?: number[] } = {},
    clearData = false,
    componentName: string
  ) => {
    const cursor = clearData ? null : this.cursor;

    if (!options.search?.length) {
      delete options.search;
    }

    const params = clearData ? { ...options, limit: GET_CONTRACTS_LIMIT } : { cursor };

    let data = await contractApi.getList(options.get_all ? options : params, componentName);

    this.items = clearData ? data?.contracts : [...this.items, ...(data?.contracts ?? [])];
    this.count = data?.total;
    this.cursor = data?.cursor;
    this.hasMore = data?.contractsLength === GET_CONTRACTS_LIMIT;
  };

  getAndSaveContracts = (componentName: string) => {
    return this.getList({ get_all: true }, true, componentName);
  };

  archiveContract = async (contractId: string) => {
    const item = this.findItemById(contractId);

    if (item) {
      await contractApi.archive(contractId);
      item.archived = true;
    }
  };

  deleteContract = async (contract: any, force?: boolean) => {
    const contractId = contract.id;
    const index = this.findIndexById(contractId);

    if (index >= 0 || force) {
      await contractApi.delete({ id: contractId });
      if (!force) this.items.splice(index, 1);
    }
  };

  deleteDraftContract = async (contractId: string) => {
    const index = this.findIndexById(contractId);

    if (index >= 0) {
      await contractApi.deleteDraft({ id: contractId });
    }
  };

  rejectContract = async (contractId: string) => {
    await contractApi.rejectUnsignedContract(contractId);
  };

  findIndexById = (contractId: string) => this.items.findIndex(({ id }) => id === contractId);
  findItemById = (contractId: string) => this.items[this.findIndexById(contractId)];

  async deleteQuote(contractId: string) {
    const contract = this.findItemById(contractId);

    if (!contract) return;

    await eorApi.deleteQuote(contract.id);
  }

  update(contract: any) {
    const index = this.findIndexById(contract.id);

    if (index >= 0) this.items[index] = contract;
  }

  clearContracts() {
    this.items = [];
  }

  scopes: ContractorScopes[] = [];

  payBeforeTermination = false;

  setPayBeforeTermination = (value: boolean) => {
    this.payBeforeTermination = value;
  };
}

export default new ContractsStore();
