import instance from '@/utils/api/instance';
import type { STATUS_FAILED, STATUS_FAILED_LEGACY, STATUS_NEW } from '@/constants/payments';
import type { BalanceTransactionType, BeneficiaryDetails, PaymentMethodType } from '@/types/MassPay';
import type { PeoBankDetailsPayload } from '@/types/PEO';
import type { AxiosRequestConfig } from 'axios';
import type { Validation, AdditionalInfo } from '@/types/User';
import type { PaymentIssueType } from '@/scenes/TransactionHistory/TransactionDetailsNew/types';

export interface IBankDetails {
  id: number;
  status: string; // TODO: Add union type
  payload: Record<string, any>;
  createdAt: string;
  updatedAt?: string;
  methodChainId: string;
  fundsDistribution: number;
  sensitiveFields?: string[];
  validations?: Validation[];
  additionalInfo?: AdditionalInfo;
}

export type GetBankDetailsResponse = Array<IBankDetails>;

export interface ClientMethodAddress {
  city: string;
  country: string;
  postCode: string;
  firstLine: string;
  state?: string;
}

export interface BrexAccount {
  name: string;
  accountNumber: string;
  id: string;
}

export interface BrexAccountsPayload {
  payload: {
    accountNumber: string;
    accountName: string;
  };
  currency: string;
  auth: {
    accessToken: string;
    refreshToken: string;
    sourceAccount: BrexAccount;
  };
}

export interface BrexAccountsResponsePayload {
  accessToken: string;
  refreshToken: string;
}

export interface BrexAccountsResponse {
  accounts: BrexAccount[];
  payload: BrexAccountsResponsePayload;
  type: 'ERROR' | 'SUCCESS';
  code: 'insufficient_permission' | 'session_expired' | 'unknown_error';
}

export interface GetBrexResponse {
  url: string;
}

export interface BannerItem {
  id: number;
  placement: string;
  severity: 'empty' | 'info' | 'success' | 'warning' | 'error';
  conditions: object;
  content: string;
  validFrom: string;
}
export interface GetBannersResponse {
  banners: BannerItem[];
}

export interface IClientMethodDetails {
  accountHolderName?: string;
  name: string;
  type: string;
  email: string;
  address: ClientMethodAddress;
  currency: string;
  legalType: string;
  selectedMethod: string;
  accountNumber?: string;
  bank_account?: string;
  iban?: string;
  IBAN?: string;
  usePaymentCurrency?: boolean;
}

export interface IClientMethod {
  id: number;
  isDefault: boolean;
  payload: IClientMethodDetails;
  paymentMethod: PaymentMethodType;
  selectedOption: string;
  userId?: string;
}

export interface PaymentMethodsResponse {
  methods: IPaymentMethod[];
  teams: PaymentMethodTeam[];
  users: PaymentMethodUser[];
}

export interface IPaymentMethodV2 extends IPaymentMethod {
  teams?: PaymentMethodTeamV2[];
}

export interface PaymentMethodTeamV2 {
  id: number;
  name: string;
  isAutoPayEnabled: boolean;
  preferred: boolean;
}

export type PaymentMethodStatus = typeof STATUS_FAILED_LEGACY | typeof STATUS_FAILED | typeof STATUS_NEW;

export interface IPaymentMethod {
  id: number;
  type: PaymentMethodType;
  attemptsRemaining: number;
  canBeUsedToPay: boolean;
  isCrossBorder: boolean;
  disabledReason?: string;
  notEnabledTeams?: { id: number; name: string }[];
  name?: string;
  last4?: string;
  bankName?: string;
  status?: PaymentMethodStatus;
  owner: boolean;
  email?: string;
  accountNumber?: string;
  accountName?: string;
  sortCode?: number;
  isManual?: boolean;
  iban?: string;
  exp_month?: number;
  exp_year?: number;
  country?: string;
  currency?: string;
  preferred?: boolean;
  savable?: boolean;
  disabled?: boolean;
  methodName?: string;
  paymentProcessingFee?: string;

  // @deprecated verifyAttempts
  verifyAttempts: number;
}

export interface PaymentMethodTeam {
  id: number;
  name: string;
  picUrl: any;
  paymentMethods?: number[];
}

export interface PaymentMethodUser {
  id: number;
  email: string;
  name: string;
  picUrl: any;
}

export interface AddMethodPayload {
  payload: { [p: string]: any };
  paymentMethod: PaymentMethodType;
  teamIds?: string[];
  paymentStatementPublicId?: string;
}

export interface AddMethodResponse {
  createdAt: string;
  id: number;
  name: string;
  type: PaymentMethodType;
  status?: 'verified'; // @todo: add more statuses.
  attemptsRemaining: number;
  [field: string]: any;

  // @deprecated verifyAttempts
  verifyAttempts?: number;
}

export interface FieldExtensionsPayload {
  field: string;
  value: string;
  extensions: string[];
}

export interface FieldExtensionsResponse {
  [key: string]: {
    data: Record<string, string> | null;
    validationMessage: string | null;
  };
}

export interface BillingRequestFlowResponse {
  id: string;
  links: {
    billing_request: string;
  };
}

export type AvailableCurrenciesForCountriesResponse = Record<string, string[]>;

export type GetClientMethodsResponse = Array<IClientMethod>;

export type TransactionStatus = 'PENDING' | 'COMPLETED' | 'PROCESSING';

export interface BalanceTransaction {
  oid: string;
  title: string;
  date: string;
  availableAt: string;
  currency: string;
  amount: string;
  description: string | null;
  status: TransactionStatus;
  type: BalanceTransactionType;
}

export interface BalanceTransactionsPagination {
  hasMore: boolean;
  limit: number;
  offset: number;
  total: number;
}

export interface GetBalanceTransactionResponse {
  transactions: BalanceTransaction[];
  pagination: BalanceTransactionsPagination;
}

export interface ClientBalance {
  currency: string;
  balance: string;
  count: number;
}

export interface WithdrawClientBalancePayload {
  methodId: number;
  amount: number;
  currency: string;
}

export interface EstimateFeeParams {
  id: number;
  amount: number;
  currency: string;
}

export interface CreateWithdrawalIssueParams {
  transactionId: string;
  issueType: PaymentIssueType;
  fileS3Key: string;
  notes?: string;
}

export interface MethodFee {
  amount: string;
  currency: string;
  totalFee: string;
  feeCurrency: string;
  estimatedArrival: string;

  refundAmount: string;
  refundCurrency: string;
  providerFee: string;
  providerFeeCurrency: string;
  rate: string;
  exchangedAmount?: string;
}

export interface MethodFeeError {
  error: string;
}

export interface PaymentOption {
  paymentMethod: PaymentMethodType;
  options: { country: string; currencies: string[] }[];
}

export interface PaymentOptionsResponse {
  paymentOptions: PaymentOption[] | null;
}

export interface BeneficiaryDetailsResponse {
  beneficiaryDetails: BeneficiaryDetails;
}

export interface EarlyPayDayResponse {
  optedIn: boolean | null;
  displayFirstPhaseBanner: boolean;
  displaySecondPhaseBanner: boolean;
}

export interface PayStatementPayload {
  paymentIntentId?: string;
}

export interface PrepareStatementsPayload {
  invoicesIds?: string[];
}

export interface PrepareStatementsResponse {
  paymentStatements: { id: string }[];
}

export default {
  /**
   * @deprecated Use getPaymentMethodsV2 instead
   */
  // Remove comment when it will be fixed
   
  getPaymentMethods: async () => {
    const { data } = await instance.get<PaymentMethodsResponse>('payments/account/payment_methods');
    return data;
  },

  getPaymentMethodsV2: async (rootInvoiceId?: string) => {
    const { data } = await instance.get<IPaymentMethodV2[]>('/client_payments/payment_methods', {
      params: { rootInvoiceId },
    });
    return data;
  },

  getBankDetails: async (): Promise<GetBankDetailsResponse> => {
    const { data } = await instance.get('payments/account/bank_info');
    return data;
  },

  /**
   * @deprecated Use addMethodV2 instead
   */
  // Remove comment when it will be fixed
   
  addMethod: async (payload: AddMethodPayload, config?: AxiosRequestConfig) => {
    const { data } = await instance.post<AddMethodResponse>('payments/account/payment_methods', payload, config);
    return data;
  },

  addMethodV2: async (payload: AddMethodPayload, config?: AxiosRequestConfig) => {
    const { data } = await instance.post<AddMethodResponse>('/client_payments/payment_methods', payload, config);
    return data;
  },

  addBankDetails: async (payload: any) => {
    const { data } = await instance.post('payments/account/bank_info', payload);
    return data;
  },

  removeBankDetail: async (methodChainId: string) => {
    const { data } = await instance.delete(`payments/account/bank_info/${methodChainId}`);
    return data;
  },

  addPeoBankDetails: async (payload: PeoBankDetailsPayload) => {
    const { data } = await instance.post('payments/account/bank_info', { payload });
    return data;
  },

  updatePeoBankDetails: async (methodChainId: string, payload: PeoBankDetailsPayload) => {
    const { data } = await instance.post('payments/account/bank_info', { methodChainId, payload });
    return data;
  },

  distributeFunds: async (settings: { methodChainId: string; fundsDistribution: number }[]) => {
    const { data } = await instance.patch(`payments/settings`, { settings });
    return data;
  },

  verifyAch: async (id: string, amounts: any) => {
    const { data } = await instance.post(`payments/account/payment_methods/${id}/verify_ach`, amounts);
    return data;
  },

  /**
   * @deprecated Use setDefaultV2 instead
   */
  // Remove comment when it will be fixed
   
  setDefault: async (id: string | number, teamId: string | number) => {
    const { data } = await instance.patch(`payments/account/payment_methods/${id}`, { preferred: true, teamId });
    return data;
  },

  setDefaultV2: async (id: string | number, teamId: string | number, preferred: boolean) => {
    const { data } = await instance.put<{ preferred: true }>(
      `/client_payments/payment_methods/${id}/team/${teamId}/preferred`,
      {
        preferred,
      }
    );
    return data;
  },

  /**
   * @deprecated Use deleteMethodV2 instead
   */
  // Remove comment when it will be fixed
   
  deleteMethod: async (id: string | number) => {
    await instance.delete(`payments/account/payment_methods/${id}`);
  },

  deleteMethodV2: async (id: string | number) => {
    await instance.delete(`/client_payments/payment_methods/${id}`);
  },

  /**
   * @deprecated Use addMethodToTeamV2 instead
   */
  // Remove comment when it will be fixed
   
  addMethodToTeam: async ({
    teamId,
    paymentMethodId,
  }: {
    teamId: string | number;
    paymentMethodId: string | number;
  }) => {
    await instance.post(`/team/${teamId}/payment_method/${paymentMethodId}`);
  },

  addMethodToTeamV2: async ({
    teamId,
    paymentMethodId,
  }: {
    teamId: string | number;
    paymentMethodId: string | number;
  }) => {
    await instance.post(`/client_payments/payment_methods/${paymentMethodId}/team/${teamId}`);
  },

  /**
   * @deprecated Use deleteMethodFromTeamV2 instead
   */
  // Remove comment when it will be fixed
   
  deleteMethodFromTeam: async ({
    teamId,
    paymentMethodId,
  }: {
    teamId: string | number;
    paymentMethodId: string | number;
  }) => {
    await instance.delete(`/team/${teamId}/payment_method/${paymentMethodId}`);
  },

  deleteMethodFromTeamV2: async ({
    teamId,
    paymentMethodId,
  }: {
    teamId: string | number;
    paymentMethodId: string | number;
  }) => {
    await instance.delete(`/client_payments/payment_methods/${paymentMethodId}/team/${teamId}`);
  },

  updatePaymentMethod: async (id: string | number, payload: { methodName: string }) => {
    return await instance.patch<void>(`/client_payments/payment_methods/${id}`, payload);
  },

  addMethodToUser: async ({ userId, paymentMethodId }: { userId: string; paymentMethodId: string }) => {
    await instance.post(`payments/account/payment_methods/${paymentMethodId}/user/${userId}`);
  },

  deleteMethodForUser: async ({ userId, paymentMethodId }: { userId: string; paymentMethodId: string }) => {
    await instance.delete(`payments/account/payment_methods/${paymentMethodId}/user/${userId}`);
  },

  deleteClientMethod: async (clientMethodId: number) => {
    await instance.delete(`payments/account/client_methods/${clientMethodId}`);
  },

  setDefaultClientMethod: async (clientMethodId: number) => {
    await instance.put(`payments/account/client_methods/${clientMethodId}/default`);
  },

  disapprove: async (invoiceId: string) => {
    const { data } = await instance.post(`/payments/disapprove/${invoiceId}`);
    return data;
  },

  validateIban: async (iban: string, config?: AxiosRequestConfig) => {
    const { data } = await instance.get(`/payments/account/payment_methods/sepa/${iban}/verify`, config);
    return data;
  },

  getFieldExtensions: async (payload: FieldExtensionsPayload): Promise<FieldExtensionsResponse> => {
    const { data } = await instance.post<FieldExtensionsResponse>(`/payments/extensions/bank_details`, {
      queries: [payload],
    });
    return data;
  },

  coinbase: {
    getAuthUrl: async () => {
      const { data } = await instance.get(`/coinbase`);
      return data;
    },
  },

  prepareStatements: async (payload: PrepareStatementsPayload) => {
    const { data } = await instance.post<PrepareStatementsResponse>('/client_payments/payment_statements', payload, {
      // increase timeout to 5 minutes
      timeout: 5 * 60 * 1000,
    });
    return data;
  },

  editWithdrawalMethodName: async ({ methodName, methodId }: { methodName: string; methodId: string }) => {
    const { data } = await instance.patch(`/payments/withdraw_methods/${methodId}`, { methodName });
    return data;
  },

  setupBillingRequestFlow: async () => {
    const { data } = await instance.post<BillingRequestFlowResponse>(
      '/client_payments/gocardless/billing_request_flow'
    );
    return data;
  },

  getBrex: async () => {
    const { data } = await instance.get<GetBrexResponse>('/client_payments/brex');
    return data;
  },

  getClientBalances: async () => {
    const { data } = await instance.get<ClientBalance[]>('client_payments/client_balances');
    return data;
  },

  estimateMethodFees: async ({ id, currency, amount }: EstimateFeeParams, config?: AxiosRequestConfig) => {
    const { data } = await instance.get<MethodFee | MethodFeeError>(
      `/payments/account/client_methods/${id}/estimate?currency=${currency}&amount=${amount}`,
      config
    );
    return data;
  },

  getBalanceTransactions: async (currency: string, offset: number, limit: number) => {
    const url = `/client_payments/client_balances/transactions?currency=${currency}&offset=${offset}&limit=${limit}`;

    const { data } = await instance.get<GetBalanceTransactionResponse>(url);
    return data;
  },

  withdrawClientBalance: async (payload: WithdrawClientBalancePayload, config?: AxiosRequestConfig) => {
    const { data } = await instance.post<void>('/client_payments/client_balances/withdraw', payload, config);
    return data;
  },

  getPaymentOptions: async (invoiceId: string) => {
    const { data } = await instance.get<PaymentOptionsResponse>(`/payment_statements/${invoiceId}/payment_options`);
    return data;
  },

  getBeneficiaryDetails: async ({
    invoiceId,
    paymentMethod,
    country,
    currency,
  }: {
    invoiceId: string;
    paymentMethod: PaymentMethodType;
    country: string;
    currency: string;
  }) => {
    const { data } = await instance.get<BeneficiaryDetailsResponse>(
      `/payment_statements/${invoiceId}/beneficiary_details?paymentMethod=${paymentMethod}&country=${country}&currency=${currency}`
    );
    return data;
  },

  deleteAutoBillingConfiguration: async (id: string) => {
    await instance.delete(`/client_payments/auto_billing_configurations/${id}`);
  },

  // Only for dev tools. Will not be used in production.
  postUpcomingStatement: async (payload: { paymentMethodId: string }) => {
    await instance.post<void>('/client_payments/dev_tools/auto_billing', payload);
  },

  postEarlyPayOutStatus: async (body: { optIn: boolean }) => {
    const { data } = await instance.post<void>('/christmas_early_payout', body);
    return data;
  },

  createWithdrawalIssue: async (body: CreateWithdrawalIssueParams) => {
    const { data } = await instance.post<void>(`/payments/withdrawal/issue`, body);
    return data;
  },

  closeWithdrawalIssue: async (id: string) => {
    const { data } = await instance.post<void>(`/payments/withdrawal/issue/${id}/close`);
    return data;
  },
};

export const getPaymentsEndpoints = () => {
  const prefix = '/payments';

  return {
    getBankDetails: `${prefix}/account/bank_info`,
    getBankTransferFields: `${prefix}/account/bank_info`,
    getBrex: `/client_payments/brex`,
    getClientMethods: `${prefix}/account/client_methods`,
    getBanners: '/banners',
    getDisabledCurrencies: `${prefix}/account/withdraw_methods/bank_transfer_supported_routes`,
    getPaymentMethods: `${prefix}/account/payment_methods`,
    getSupportedRoutesForInstantCardTransfers: `${prefix}/account/withdraw_methods/instant_card_transfer_supported_routes`,
    getSupportedRoutesForTransferwise: `${prefix}/account/withdraw_methods/transferwise_supported_routes`,
    getWithdrawalTour: `${prefix}/should_show_withdrawal_tour`,
    getEarlyPayOutInfo: `/christmas_early_payout`,
    getFundBalanceTransactions: `/client_payments/client_balances/transactions`,
    getPayment: `/payment`,
  };
};
