import instance from '@/utils/api/instance';
import type { AxiosRequestConfig } from 'axios';

export const GP_BASE_URL = '/global_payroll';
export const GP_MASS_UPDATE_BASE_URL = `${GP_BASE_URL}/mass_update`;

type TFieldsObject = {
  [key: string]:
    | boolean
    | {
        [key: string]: boolean;
      };
};

export type TGenerateTemplateParams = {
  organizationId: number;
  legalEntityId: string;
  contractOids: string[];
  selectedFields: TFieldsObject;
  countryCode: string;
};

export type ValidateCsvPayload = {
  country: string;
  selectedFields: TFieldsObject;
  fileKey: string;
  legalEntityId?: string;
};

export type ValidateCsvDataPayload = {
  selectedFields: TFieldsObject;
  fileKey: string;
  legalEntityId: string;
  organizationId: number;
  country: string;
  contractOids: string[];
  shouldUpdate?: boolean;
};

export type ContractualData = {
  contractOid: string;
  fullDataObj: {
    key: string;
    label: string;
    fields: { current: string; key: string; label: string; update: string }[];
  }[];
};

type ValidateCsvDataResponse = {
  contractualData: ContractualData[];
  creationResult?: {
    errors: string[];
    success: string[];
  }[];
  isValid: boolean;
};

export default {
  getAllOids: async (entityId: string) => {
    const queryItems = [`entityId=${entityId}`];

    const { data } = await instance.get(`${GP_BASE_URL}/contracts/legal_entity/list/all_oids?${queryItems.join('&')}`);

    return data;
  },

  getContracts: async (entityId: string, limit: number, offset: number, searchText?: string) => {
    const queryItems = [`entityId=${entityId}`, `limit=${limit}`, `offset=${offset}`];

    if (searchText) queryItems.push(`searchText=${searchText}`);

    const { data } = await instance.get(`${GP_BASE_URL}/contracts/legal_entity/list?${queryItems.join('&')}`);
    return data;
  },

  getFieldsTree: async () => {
    const { data } = await instance.get(`${GP_MASS_UPDATE_BASE_URL}/fields`);
    return data;
  },

  generateTemplate: async (props: TGenerateTemplateParams) => {
    const { data } = await instance.post(`${GP_MASS_UPDATE_BASE_URL}/csv_template`, {
      selectedFields: props.selectedFields,
      organizationId: props.organizationId,
      legalEntityId: props.legalEntityId,
      contractOids: props.contractOids,
      country: props.countryCode,
    });

    return data;
  },

  validateCsv: async (payload: ValidateCsvPayload): Promise<any> => {
    const { data } = await instance.post(`${GP_MASS_UPDATE_BASE_URL}/validate_csv_data`, payload);
    return data;
  },

  validateCsvData: async (
    payload: ValidateCsvDataPayload,
    config?: AxiosRequestConfig
  ): Promise<ValidateCsvDataResponse> => {
    const { data } = await instance.post(GP_MASS_UPDATE_BASE_URL, payload, config);
    return data;
  },

  submitAsyncCsvData: async (
    payload: ValidateCsvDataPayload,
    config?: AxiosRequestConfig
  ): Promise<ValidateCsvDataResponse> => {
    const { data } = await instance.post(`${GP_MASS_UPDATE_BASE_URL}/async_mass_update_csv`, payload, config);
    return data;
  },
};
