import { type CSSProperties, useMemo } from 'react';
import { useCheckIfShouldShowBottomNav } from './useCheckIfShouldShowBottomNav';
import { useBottomNavViewGap } from '@/layouts/AppLayout/hooks/ViewGaps/useBottomNavViewGap';

export type StyleType = 'bottom' | 'paddingBottom' | 'marginBottom';

export const useBottomNavStyles = (styleType: StyleType = 'paddingBottom') => {
  const shouldShowBottomNav = useCheckIfShouldShowBottomNav();
  const bottomNavPadding = useBottomNavViewGap();

  const bottomNavHeight = useMemo(() => {
    return shouldShowBottomNav ? bottomNavPadding : 0;
  }, [shouldShowBottomNav, bottomNavPadding]);

  const bottomNavStyle: CSSProperties = useMemo((): CSSProperties => {
    if (bottomNavHeight === 0) return {};
    const value = `${bottomNavHeight}px`;
    switch (styleType) {
      case 'bottom':
        return { bottom: value };
      case 'paddingBottom':
        return { paddingBottom: value };
      case 'marginBottom':
        return { marginBottom: value };
      default:
        return { paddingBottom: value };
    }
  }, [styleType, bottomNavHeight]);

  return { bottomNavStyle, bottomNavHeight };
};
