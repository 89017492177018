import { useMobile, useTheme } from '@letsdeel/ui';
import { useMemo } from 'react';
import { useWhiteLabelContext } from './WhiteLabelContext';

export const useWhiteLabelLogos = (forceMobile: boolean = false) => {
  const { palette } = useTheme();
  const isDarkModeEnabled = palette.mode === 'dark';

  const { isMobile } = useMobile();
  const { logos, isWhiteLabelEnabled } = useWhiteLabelContext();

  const whiteLabelLogo = useMemo(() => {
    if (isMobile || forceMobile) {
      return isDarkModeEnabled ? logos?.darkMode?.sm : logos?.lightMode?.sm;
    }

    return isDarkModeEnabled ? logos?.darkMode?.md : logos?.lightMode?.md;
  }, [isDarkModeEnabled, isMobile, logos, forceMobile]);

  return {
    whiteLabelLogo,
    showWhiteLabelLogo: isWhiteLabelEnabled && !!whiteLabelLogo,
  };
};
