export const getUserInitials = (name) => {
  let initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  return initials;
};

export const getInitials = (name) => {
  if (typeof name !== 'string') return;
  const [[first = ''] = '', [second = ''] = ''] = name.replace(/\W/g, ' ').replace(/\s+/g, ' ').split(' ');
  return `${first}${second}`.toUpperCase();
};

export const getUserAddress = (data = {}, lookupStore) => {
  const address = [];
  const { street, address1 = '', address2, country, city, zipCode, zip, province, state } = data;
  address.push(street || `${address1}${address2 ? ', ' + address2 : ''}`);
  address.push(city);

  if (country) {
    if (lookupStore.countries[country]?.provinces) {
      address.push(lookupStore.countries[country].provinces.find((p) => p.value === (province || state))?.label);
    }
    address.push(lookupStore.countries[country]?.label);
  }
  address.push(zip || zipCode);
  return address
    .filter((o) => o)
    .join(', ')
    .replace(/,,/g, ',');
};

const DEEL_DOMAINS = ['@letsdeel.com', '@deel.com']; // removed '@deel.wtf' for QA purposes

export const isDeelUser = (email) => DEEL_DOMAINS.some((domain) => email.endsWith(domain));
