import type { ReactNode } from 'react';
import useTeamRoles from '@/context/Authorization/useTeamRoles';
import useEntityRoles from './useEntityRoles';
import { Permission } from '@letsdeel/ui/dist/shared';
import { PERMISSIONS_DEBUG_KEY } from '@/context/Authorization/AuthorizationProvider';
import OwnerContextProvider from './OwnerContext';
import React from 'react';
import type { RequireAtLeastOne } from '@/types/utils';
import useUserStore from '@/hooks/useUserStore';
import isEqual from 'lodash/isEqual';

export const ORG_SCOPE_PREFIX = 'ORG_SCOPE:';
export const ENTITY_SCOPE_PREFIX = 'ENTITY_SCOPE:';
export const TEAM_SCOPE_PREFIX = 'TEAM_SCOPE:';

type Sdb = {
  type: 'sdb';
  hrisProfileId: string;
};

type Client = RequireAtLeastOne<{
  organizationId: string | number;
  entityId: string | null;
  teamId: string | number | null;
}> & { type: 'client' };

export type Owner = Client | Sdb;

interface PermissionOwnerProviderProps {
  children: ReactNode;
  owner: Owner;
}

const PermissionOwnerProvider = ({ children, owner }: PermissionOwnerProviderProps) => {
  const { getEntityPermissionSets } = useEntityRoles();
  const { getTeamRolesAndSets } = useTeamRoles();
  const user = useUserStore();

  if (owner.type === 'client') {
    const permissionSets = user.organizationProfile?.permissionSets || [];

    let allRoles: string[] = [];
    let orgRoles = user.isLoggedIn
      ? [
          ...((user.organizationProfile?.role !== 'ORGANIZATION_ADMIN'
            ? [`ORG:${user.organizationProfile?.role}`]
            : []) as unknown as string),
          user.userType as string,
          ...permissionSets,
        ].map((role) => `${ORG_SCOPE_PREFIX}${role}`)
      : [];
    let entitySets: string[] = [];
    let teamRolesAndSets: string[] = [];

    allRoles = [...allRoles, ...orgRoles];

    entitySets = getEntityPermissionSets(owner?.entityId ?? null).map((role) => `${ENTITY_SCOPE_PREFIX}${role}`);
    allRoles = [...allRoles, ...entitySets];

    teamRolesAndSets = getTeamRolesAndSets(owner?.teamId ?? null).map((role) => `${TEAM_SCOPE_PREFIX}${role}`);
    allRoles = [...allRoles, ...teamRolesAndSets];

    const debugModes = localStorage.getItem(PERMISSIONS_DEBUG_KEY)
      ? JSON.parse(localStorage.getItem(PERMISSIONS_DEBUG_KEY)!)
      : false;
    const showOrgSets =
      orgRoles.length && (debugModes?.includes?.('orgSets') || debugModes?.includes?.('allSets'))
        ? `ORG-LEVEL: ${orgRoles.join(', ')}`
        : '';
    const showTeamSets =
      teamRolesAndSets.length && (debugModes?.includes?.('teamSets') || debugModes?.includes?.('allSets'))
        ? `TEAM-LEVEL[teamId: ${owner.teamId || 'ALL TEAMS MODE'}]: ${teamRolesAndSets.join(', ')}`
        : '';
    const showEntitySets =
      entitySets.length && (debugModes?.includes?.('entitySets') || debugModes?.includes?.('allSets'))
        ? `ENTITY-LEVEL[entityId: ${owner.entityId}]: ${entitySets.join(', ')}`
        : '';

    return (
      <Permission.RoleProvider
        addRoles={allRoles}
        removeRoles="*"
        {...(debugModes
          ? {
              ['data-debug']:
                showEntitySets || showTeamSets || showOrgSets
                  ? [showOrgSets, showTeamSets, showEntitySets].filter(Boolean).join('\n\n')
                  : null,
            }
          : [])}
      >
        {children}
      </Permission.RoleProvider>
    );
  }

  if (owner.type === 'sdb' && owner.hrisProfileId) {
    return <OwnerContextProvider owner={owner}>{children}</OwnerContextProvider>;
  }

  return <>{children}</>;
};

const MemoizedOwnerProvider = React.memo(PermissionOwnerProvider, isEqual);

export default MemoizedOwnerProvider;
