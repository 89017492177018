import { isAfter, isSameDay, format, startOfDay, addDays, parseISO, subBusinessDays } from 'date-fns';
import Axios from 'axios';
import { DateFormat } from '@letsdeel/ui';

import organizationStore from '@/stores/OrganizationsStore';
import { getPayrollCycleText } from '@/scenes/Payroll/Components/utils';
import { EmploymentTermType } from '@/types/EmploymentTerm';
import type { useFetchEmployeeStartDates } from '@/hooks/api/global-payroll-on-off/useFetchEmployeeStartDates';
import type { useFetchEntityStartDates } from '@/hooks/api/global-payroll-on-off/useFetchEntityStartDates';
import type { EmploymentTerm } from '@/types/EmploymentTerm';
import type { LegalEntity } from '@/types/LegalEntity';
import type { LookupsCountries } from '@/types/Lookups';
import type { AxiosError } from 'axios';

import { EmploymentTermAmendedScope } from './contract/globalPayrollContract';

type EmployeeStartDates = ReturnType<typeof useFetchEmployeeStartDates>['data'];
type EntityStartDates = ReturnType<typeof useFetchEntityStartDates>['data'];

interface GetContractStartDateMessageParams {
  entityStartDates: EntityStartDates;
  employeeStartDates: EmployeeStartDates;
  selectedStartDate: string | null;
}

type GetContractStartDateMessageResult = {
  main: string;
  secondary?: string;
} | null;

type FilterEntitiesByIntegrationsParams = {
  entities: Array<LegalEntity> | null;
  withIntegrations: boolean;
};

export const getActiveEmploymentTermLeaveEndDate = (
  activeEmploymentTerm: EmploymentTerm,
  upcomingEmploymentTerms?: EmploymentTerm[]
): Date | null => {
  let leaveEndDate = null;
  if (activeEmploymentTerm?.type === EmploymentTermType.ON_LEAVE) {
    const [postLeaveEmploymentTerm] =
      upcomingEmploymentTerms
        ?.sort((x, y) => new Date(x.effectiveDate).getTime() - new Date(y.effectiveDate).getTime())
        .filter((term) => term.amendedItems.includes(EmploymentTermAmendedScope.employment)) || [];

    if (postLeaveEmploymentTerm)
      leaveEndDate = startOfDay(addDays(new Date(postLeaveEmploymentTerm.effectiveDate), -1));
  }

  return leaveEndDate;
};

export function filterGPActivatedEntities(entities: LegalEntity[] | null) {
  if (!entities) {
    return [];
  }

  return entities.filter((entity) => entity.isGpActive);
}

export function filterGpAvailableCountries(countries: LookupsCountries, gpActivatedEntities: LegalEntity[]) {
  const gpAvailableCountries: LookupsCountries = {};

  gpActivatedEntities.forEach((entity) => {
    gpAvailableCountries[entity.vatCountry] = countries[entity.vatCountry];
  });

  return gpAvailableCountries;
}

export function sortCountries(countries: LookupsCountries) {
  return Object.fromEntries(
    Object.entries(countries).sort(([, country], [, nextCountry]) => {
      return country.label.localeCompare(nextCountry.label);
    })
  );
}

export function getPostGoLiveContractStartDateMessage(employeeStartDates: NonNullable<EmployeeStartDates>) {
  const onboardingDueDate = format(
    parseISO(getISODateStringWithoutTimezone(employeeStartDates.onboardingDueDate)),
    DateFormat.DATE_YEAR
  );
  const preCutoffDueDate = format(
    subBusinessDays(parseISO(getISODateStringWithoutTimezone(employeeStartDates.expectedEmploymentCycle.lock)), 1),
    DateFormat.DATE_YEAR
  );
  const expectedEmploymentCycleLabel = getPayrollCycleText(employeeStartDates.expectedEmploymentCycle);

  return {
    main: `Please make sure your employee is fully onboarded on Deel by ${onboardingDueDate}, so we can register with the local authorities. Failing to do that, Deel payroll start date may be pushed forward.`,
    secondary: `To be paid in the ${expectedEmploymentCycleLabel} cycle, your employee needs to be onboarded by ${preCutoffDueDate}`,
  };
}

export function getPreGoLiveContractStartDateMessage(
  entityStartDates: NonNullable<EntityStartDates>,
  selectedStartDate: string
) {
  const entityGoLiveDate = parseISO(getISODateStringWithoutTimezone(entityStartDates.firstEntityEvent.start));
  const employeeStartDate = parseISO(getISODateStringWithoutTimezone(selectedStartDate));

  const isStartDateAfterGoLiveDate =
    isSameDay(employeeStartDate, entityGoLiveDate) || isAfter(employeeStartDate, entityGoLiveDate);

  const firstEntityEventLabel = getPayrollCycleText(entityStartDates.firstEntityEvent, false);

  return {
    main: `The first payroll cycle Deel will run for this entity will be ${firstEntityEventLabel}. ${
      isStartDateAfterGoLiveDate
        ? 'Deel will be responsible for onboarding this employee.'
        : 'Please make sure you or your current payroll provider process onboarding and payroll for this employee until then.'
    }`,
  };
}

export function getContractStartDateMessage({
  entityStartDates,
  employeeStartDates,
  selectedStartDate,
}: GetContractStartDateMessageParams): GetContractStartDateMessageResult {
  if (!entityStartDates || !entityStartDates.earliestPossibleEEStartDate) {
    return null;
  }

  if (!selectedStartDate || !employeeStartDates) {
    return {
      main: `The earliest possible start date due to onboarding requirements is ${format(
        parseISO(getISODateStringWithoutTimezone(entityStartDates.earliestPossibleEEStartDate)),
        DateFormat.DATE_YEAR
      )}.`,
    };
  }

  const isNowPostGoLiveDate =
    isSameDay(new Date(), parseISO(getISODateStringWithoutTimezone(entityStartDates.firstEntityEvent.start))) ||
    isAfter(new Date(), parseISO(getISODateStringWithoutTimezone(entityStartDates.firstEntityEvent.start)));

  return isNowPostGoLiveDate
    ? getPostGoLiveContractStartDateMessage(employeeStartDates)
    : getPreGoLiveContractStartDateMessage(entityStartDates, selectedStartDate);
}

export function checkIfAllGPEntitiesHaveIntegrations() {
  return !!organizationStore.legalEntities
    ?.filter((entity) => !!entity.isGpActive)
    .every((entity) => !!entity.hasGpIntegrationActive);
}

export function filterEntitiesByIntegrations({ entities, withIntegrations }: FilterEntitiesByIntegrationsParams) {
  if (!entities) {
    return [];
  }

  return entities.filter((entity) =>
    withIntegrations ? !!entity.hasGpIntegrationActive : !entity.hasGpIntegrationActive
  );
}

export const getISODateStringWithoutTimezone = (date?: string | Date | null) => {
  if (!date) {
    return new Date().toISOString().slice(0, -1);
  }

  if (date instanceof Date) {
    return date.toISOString().slice(0, -1);
  }

  if (date.endsWith('Z')) {
    return date.slice(0, -1);
  }

  return new Date(date.includes('T') ? date.split('T')[0] : date).toISOString().slice(0, -1);
};

export const formatDateWithoutTimezoneShift = (
  date?: string | Date | null,
  dateFormat: DateFormat = DateFormat.DATE_YEAR
) => {
  try {
    if (!date) {
      throw new Error('INVALID DATE');
    }

    let formattedString = '';
    let parsedDateObject = null;

    if (date instanceof Date) {
      parsedDateObject = date;
      formattedString = format(date, dateFormat);
    } else {
      const parsedDateYear = new Date(date).getUTCFullYear();
      const parsedDateMonth = new Date(date).getUTCMonth();
      const parsedDate = new Date(date).getUTCDate();

      parsedDateObject = new Date(parsedDateYear, parsedDateMonth, parsedDate);
      formattedString = format(parsedDateObject, dateFormat);
    }

    return { formatted: formattedString, date: parsedDateObject };
  } catch (error) {
    return {
      formatted: '',
      date: null,
    };
  }
};

export const getLatestDate = (dateArray?: Array<string>) => {
  if (!dateArray || !dateArray.length) {
    return undefined;
  }

  const latestDate = dateArray.reduce((maxDate, date) => {
    const currentDate = formatDateWithoutTimezoneShift(date, DateFormat.DATE_ONLY).formatted;
    return currentDate > maxDate ? currentDate : maxDate;
  }, '');

  return latestDate;
};

export const getApiErrorMessage = (err: AxiosError | unknown): string | undefined => {
  if (Axios.isAxiosError(err)) {
    if (Array.isArray(err.response?.data)) {
      return err.response.data.map((error: { message: string }) => error.message).join(', ');
    }

    if (typeof err.response?.data?.error === 'string') return err.response.data.error;

    if (Array.isArray(err.response?.data?.errors))
      return err.response.data.errors.map((error: { message: string }) => error.message).join(', ');
  }

  return undefined;
};

export const getDateFnsFormat = (dateFormat: string) => {
  return dateFormat.replace(/Y/g, 'y').replace(/D/g, 'd').replace(/m/g, 'M');
};

export const getActiveEmploymentTerm = (employmentTerms?: EmploymentTerm[]) => {
  return employmentTerms?.find((item) => item.status === 'ACTIVE');
};
