import type { BenefitAbout } from '@/scenes/Benefits/types/interfaces';

import HSABanner from '@/assets/images/graphics/blobs/benefits-admin-hsa.svg';
import LifeInsuranceBanner from '@/assets/images/graphics/blobs/benefits-admin-life-insurance.svg';
import K401Banner from '@/assets/images/graphics/blobs/benefits-admin-401-k.svg';
import FeatureOneIcon from '@/assets/images/graphics/blobs/benefits-admin-feature-one.svg';
import FeatureTwoIcon from '@/assets/images/graphics/blobs/benefits-admin-feature-two.svg';
import FeatureThreeIcon from '@/assets/images/graphics/blobs/benefits-admin-feature-three.svg';
import { QualifyingLifeEventTypes } from '@/scenes/Benefits/types/enums';

export const HSA_BENEFIT_TITLE = 'Health Savings Account (HSA)';
export const FSA_BENEFIT_TITLE = 'FSA - Healthcare';
export const FSA_DEPENDENT_CARE_BENEFIT_TITLE = 'FSA - Dependent Care';
export const FSA_TRANSIT_BENEFIT_TITLE = 'FSA - Transit';
export const FSA_PARKING_BENEFIT_TITLE = 'FSA - Parking';
export const SHORT_TERM_BENEFIT_TITLE = 'Short-Term Disability';
export const LONG_TERM_BENEFIT_TITLE = 'Long-Term Disability';

export const hsaAbout: BenefitAbout = {
  title: HSA_BENEFIT_TITLE,
  description: `A ${HSA_BENEFIT_TITLE} is a tax-advantaged investment account you can contribute to if you have a high-deductible health insurance plan.`,
  type: 'hsa',
  image: HSABanner,
  features: [
    {
      title: 'Tax Savings',
      description:
        'HSAs offer tax savings through tax-deductible contributions and tax-free withdrawals for medical expenses.',
      image: FeatureOneIcon,
    },
    {
      title: 'Flexibility',
      description: 'HSAs offer flexibility to save and spend healthcare dollars, with year-to-year fund rollover.',
      image: FeatureTwoIcon,
    },
    {
      title: 'Ownership',
      description:
        'HSAs are owned by the individual, meaning you can take your account with you if you change jobs or retire.',
      image: FeatureThreeIcon,
    },
  ],
};

export const lifeInsuranceAbout: BenefitAbout = {
  title: 'Life Insurance',
  description:
    'Healthcare benefits provide coverage for medical expenses and resources to support overall health and wellbeing.',
  type: 'life-insurance',
  image: LifeInsuranceBanner,
  features: [
    {
      title: 'Secure Your Financial Future',
      description:
        'Get peace of mind knowing that your debts and expenses will be covered if something happens to you.',
      image: FeatureOneIcon,
    },
    {
      title: 'Protect Your Loved Ones',
      description: 'Ensure that your family is taken care of in the event of your unexpected passing.',
      image: FeatureTwoIcon,
    },
    {
      title: 'Life Insurance for Every Budget',
      description:
        'Choose from a variety of policies and coverage options to find a plan that fits your needs and budget.',
      image: FeatureThreeIcon,
    },
  ],
};

export const fsaAbout: BenefitAbout = {
  title: FSA_BENEFIT_TITLE,
  description:
    'A health Flexible Spending Account (FSA) allows individuals to use pre-tax dollars to pay for medical expenses not covered by insurance. Individuals elect to contribute a portion of their paychecks to either a health FSA or dependent care FSA and save 25% to 40% in taxes.',
  type: 'fsa',
  image: HSABanner,
  features: [
    {
      title: 'Tax Savings',
      description: 'Take advantage of using pre-tax dollars for eligible health expenses.',
      image: FeatureOneIcon,
    },
    {
      title: 'Accessible Healthcare',
      description: 'Healthcare expenses are now more accessible and affordable.',
      image: FeatureTwoIcon,
    },
    {
      title: 'Precise Budgeting',
      description: 'FSA offers flexibility to save and spend through the budget you planned.',
      image: FeatureThreeIcon,
    },
  ],
};

export const k401About: BenefitAbout = {
  title: '401K',
  description:
    'A 401(k) is an employer-sponsored savings plan that allow you to set aside a portion of your paycheck for your retirement',
  type: '401k',
  image: K401Banner,
  features: [
    {
      title: 'Secure Your Future with 401k',
      description: 'Invest in your future with our 401k program. Start building a secure retirement today!',
      image: FeatureOneIcon,
    },
    {
      title: 'Take Advantage of Employer Contributions!',
      description: 'Maximize your savings with employer matching contributions. Join our 401k program now!',
      image: FeatureTwoIcon,
    },
  ],
};

export const healthCareAbout: BenefitAbout = {
  title: 'Healthcare',
  description:
    'Healthcare benefits provide coverage for medical expenses and resources to support overall health and wellbeing.',
  type: 'healthcare',
  image: LifeInsuranceBanner,
  features: [
    {
      title: 'Comprehensive Healthcare Coverage',
      description: 'Enjoy comprehensive healthcare coverage that meets your medical needs.',
      image: FeatureOneIcon,
    },
    {
      title: 'Coverage for Preventative Care',
      description:
        'Stay on top of your health with coverage for preventative care services like check-ups and screenings.',
      image: FeatureTwoIcon,
    },
    {
      title: 'Mental Health and Wellness Support',
      description:
        'Take care of your mental health with access to wellness programs and resources that support overall wellbeing.',
      image: FeatureThreeIcon,
    },
  ],
};

export const dentalAbout: BenefitAbout = {
  title: 'Dental',
  description:
    'Dental benefit provides support and resources for your expected or unexpected dental expenses to maintain your wellbeing and quality of life.',
  type: 'dental',
  image: LifeInsuranceBanner,
  features: [
    {
      title: 'Find the expert that meets your needs',
      description:
        'There are thousands of participating dentists to choose from. So you are sure to find one that meets your needs.',
      image: FeatureOneIcon,
    },
    {
      title: 'Enhanced process experience',
      description: 'You can track your claims online and even receive email alerts when a claim has been processed.',
      image: FeatureTwoIcon,
    },
    {
      title: 'Estimate your out-of-pocket expenses',
      description:
        'You can ask for a pretreatment estimate. The estimate helps you prepare for the cost of dental services.',
      image: FeatureThreeIcon,
    },
  ],
};

export const visionAbout: BenefitAbout = {
  title: 'Vision',
  description:
    'Vision benefits provide comprehensive eye care, including eye exams, prescription eyewear, and necessary vision treatments, promoting optimal vision and supporting overall eye health.',
  type: 'vision',
  image: LifeInsuranceBanner,
  features: [
    {
      title: 'Prioritize Your Vision',
      description: 'Make sure your eye health remains a top priority for clear and comfortable vision.',
      image: FeatureOneIcon,
    },
    {
      title: 'Enjoy Cost Savings',
      description: 'Save on prescription glasses, contact lenses and other vision correction options.',
      image: FeatureTwoIcon,
    },
    {
      title: 'Streamlined Experience',
      description:
        'Get provided with a convenient appointment scheduling and a network of trusted eye care professionals.',
      image: FeatureThreeIcon,
    },
  ],
};

export const shortTermDisability: BenefitAbout = {
  title: SHORT_TERM_BENEFIT_TITLE,
  description: `${SHORT_TERM_BENEFIT_TITLE} Benefit provide benefit for medical expenses and resources to support overall health and wellbeing.`,
  type: 'short-term-disability',
  image: LifeInsuranceBanner,
  features: [
    {
      title: `Comprehensive ${SHORT_TERM_BENEFIT_TITLE} Coverage`,
      description: `Enjoy comprehensive ${SHORT_TERM_BENEFIT_TITLE} coverage that meets your medical needs.`,
      image: FeatureOneIcon,
    },
    {
      title: 'Coverage for Preventative Care',
      description:
        'Stay on top of your health with coverage for preventative care services like check-ups and screenings.',
      image: FeatureTwoIcon,
    },
    {
      title: 'Mental Health and Wellness Support',
      description:
        'Take care of your mental health with access to wellness programs and resources that support overall wellbeing.',
      image: FeatureThreeIcon,
    },
  ],
};

export const longTermDisability: BenefitAbout = {
  title: 'Long-Term Disability',
  description:
    "This benefit provides income protection when you're unable to work due to illness, injury, or pregnancy-related conditions, ensuring financial stability during temporary setbacks.",
  type: 'long-term-disability',
  image: LifeInsuranceBanner,
  features: [
    {
      title: 'Secure your income',
      description:
        'Gain peace of mind and financial stability during periods of illness or injury with our income protection coverage.',
      image: FeatureOneIcon,
    },
    {
      title: 'Support when you need it most',
      description:
        'Receive essential assistance during your recovery journey with our comprehensive Long-term disability benefits.',
      image: FeatureTwoIcon,
    },
    {
      title: 'Preserve your financial well-being',
      description: 'Protect your finances during temporary work interruptions with reliable income protection.',
      image: FeatureThreeIcon,
    },
  ],
};

export const accidentInsurance: BenefitAbout = {
  title: 'Accident Insurance',
  description:
    'Accident Insurance will help minimize the financial impact if you, your spouse, or your child(ren) experiences a covered accident off the job.',
  type: 'accident-insurance',
  image: LifeInsuranceBanner,
  features: [
    {
      title: 'A coverage for you and your family',
      description:
        'Your family would benefit from not having to dip into savings to pay for expenses not covered by your health insurance as you or a family member recovers from a covered injury.',
      image: FeatureOneIcon,
    },
    {
      title: 'Receive cash to help pay medical costs',
      description:
        'You would benefit from receiving cash to help pay for co-pays, your deductible, or other medical costs not covered by your health insurance.',
      image: FeatureTwoIcon,
    },
    {
      title: 'Keep the pulse of an active lifestyle',
      description:
        'You are a parent of active children (perhaps involved in organized sports) or your spouse has an active lifestyle. You are a weekend warrior or avid do-it-yourselfer.',
      image: FeatureThreeIcon,
    },
  ],
};

export const criticalIllness: BenefitAbout = {
  title: 'Critical Illness',
  description:
    'If you are diagnosed with a serious medical condition, critical illness insurance helps reduce the daily stress about money, so you can focus on getting better. If your claim is approved following a diagnosis of a covered condition, Critical illness coverage provides a lump sum cash benefit directly to you.',
  type: 'critical-illness',
  image: LifeInsuranceBanner,
  features: [
    {
      title: 'Maintain your financial well-being',
      description: `If you aren’t financially prepared for an emergency or unforeseen illness, Critical Illness coverage will make sure you're covered.`,
      image: FeatureOneIcon,
    },
    {
      title: 'Keep your focus on your recovery',
      description:
        'You don’t need to get concerned about being diagnosed with a critical illness based on your family history or other reasons.',
      image: FeatureTwoIcon,
    },
    {
      title: 'Feel safe with enhanced coverage',
      description:
        'Critical Illness coverage will also help you pay for expenses not covered by your health insurance— such as copays.',
      image: FeatureThreeIcon,
    },
  ],
};

export const fsaDependentCareAbout: BenefitAbout = {
  title: FSA_DEPENDENT_CARE_BENEFIT_TITLE,
  description:
    'A dependent care FSA, allows individuals to use pre-tax dollars for daycare or dependent care expenses. The dependent care FSA (DCAP) cannot be used to pay for medical expenses. Individuals elect to contribute a portion of their paychecks to either a health FSA or dependent care FSA and save 25% to 40% in taxes.',
  type: 'fsa-dependent-care',
  image: HSABanner,
  features: [
    {
      title: 'Tax Savings',
      description: 'Take advantage of using pre-tax dollars for eligible dependent care expenses.',
      image: FeatureOneIcon,
    },
    {
      title: 'Accessible Healthcare',
      description: 'Daycare and dependent care expenses are now more accessible and affordable.',
      image: FeatureTwoIcon,
    },
    {
      title: 'Precise Budgeting',
      description: 'FSA offers flexibility to save and spend through the budget you planned.',
      image: FeatureThreeIcon,
    },
  ],
};

export const fsaTransitAbout: BenefitAbout = {
  title: FSA_TRANSIT_BENEFIT_TITLE,
  description:
    'A Pre-Tax way to pay for transit expenses (while you are employed) before Federal, State and FICA taxes. Your money is deducted from each paycheck pre-tax (before Federal, State, and FICA taxes).\n' +
    'Review',
  type: 'fsa-transit',
  image: HSABanner,
  features: [
    {
      title: 'Tax Savings',
      description: 'Take advantage of using pre-tax dollars for eligible transit expenses.',
      image: FeatureOneIcon,
    },
    {
      title: 'Accessible Healthcare',
      description: 'Transit expenses are now more accessible and affordable.',
      image: FeatureTwoIcon,
    },
    {
      title: 'Precise Budgeting',
      description: 'FSA offers flexibility to save and spend through the budget you planned.',
      image: FeatureThreeIcon,
    },
  ],
};

export const fsaParkingAbout: BenefitAbout = {
  title: FSA_PARKING_BENEFIT_TITLE,
  description:
    'A Pre-Tax way to pay for parking expenses (while you are employed) before Federal, State and FICA taxes. Your money is deducted from each paycheck pre-tax (before Federal, State, and FICA taxes).',
  type: 'fsa-parking',
  image: HSABanner,
  features: [
    {
      title: 'Tax Savings',
      description: 'Take advantage of using pre-tax dollars for eligible parking expenses.',
      image: FeatureOneIcon,
    },
    {
      title: 'Accessible Healthcare',
      description: 'Parking expenses are now more accessible and affordable.',
      image: FeatureTwoIcon,
    },
    {
      title: 'Healthcare Budgeting',
      description: 'FSA offers flexibility to save and spend through the budget you planned.',
      image: FeatureThreeIcon,
    },
  ],
};

export const voluntaryLifeInsuranceAbout: BenefitAbout = {
  title: 'Voluntary Life Insurance',
  description: 'Additional Life Insurance for yourself and your dependents for additional financial protection.',
  type: 'voluntary-life-insurance',
  image: LifeInsuranceBanner,
  features: [
    {
      title: 'Secure Your Financial Future',
      description:
        'Get peace of mind knowing that your debts and expenses will be covered if something happens to you.',
      image: FeatureOneIcon,
    },
    {
      title: 'Protect Your Loved Ones',
      description: 'Ensure that your family is taken care of in the event of your unexpected passing.',
      image: FeatureTwoIcon,
    },
    {
      title: 'Life Insurance for Every Budget',
      description:
        'Choose from a variety of policies and coverage options to find a plan that fits your needs and budget.',
      image: FeatureThreeIcon,
    },
  ],
};

export const accidentAndDisabilityAbout: BenefitAbout = {
  title: 'Accident and disability',
  description: '',
  type: 'accident-and-disability',
  image: null,
  features: [
    {
      title: '',
      description: '',
      image: null,
    },
  ],
};

export const mealVoucherAbout: BenefitAbout = {
  title: 'Meal Voucher',
  description: '',
  type: 'meal-voucher',
  image: null,
  features: [
    {
      title: '',
      description: '',
      image: null,
    },
  ],
};

export const pensionAbout: BenefitAbout = {
  title: 'Pension',
  description: '',
  type: 'pension',
  image: null,
  features: [
    {
      title: '',
      description: '',
      image: null,
    },
  ],
};

export const getQleSupportDocuments = (eventType: QualifyingLifeEventTypes) => {
  switch (eventType) {
    case QualifyingLifeEventTypes.MARRIAGE:
    case QualifyingLifeEventTypes.DIVORCE:
      return [
        'Marriage license (include proof of coverage for at least one, subscriber or spouse).',
        `Divorce or annulment papers including the ending of health care responsibility and proof of prior qualifying health coverage within the last 60 days.`,
      ];
    case QualifyingLifeEventTypes.CHILD_BIRTH:
    case QualifyingLifeEventTypes.CHILD_ADOPTION:
    case QualifyingLifeEventTypes.CHILD_GUARDIANSHIP:
      return [
        'Birth certificate or application for a birth certificate.',
        'Adoption record or placement for adoption.',
        'Legal guardianship document.',
        'Court order or child support order.',
      ];
    case QualifyingLifeEventTypes.LOSE_GOVERNMENT_PROVIDED_COVERAGE:
      return [
        'Medicaid: Documentation from Michigan Department of Health and Human Services indicating the reason for loss and when coverage ended or will end.',
        `Medicaid: Letter from Medicaid or Children's Health Insurance Plan (CHIP) stating when coverage ended or will end.`,
      ];
    case QualifyingLifeEventTypes.LOSE_EXISTING_HEALTH_COVERAGE:
      return [
        'Letter or document from employer stating the employer changed, dropped or will drop coverage or benefits for the employee, spouse or dependent, including the date coverage ended or will end.',
        'Letter from health insurance company showing coverage termination date.',
        'COBRA documentation showing length of coverage with beginning and end dates.',
      ];
    case QualifyingLifeEventTypes.DEATH_OF_POLICY_HOLDER:
      return [
        'Death certificate or public notice of death and proof of prior qualifying health coverage within the last 60 days.',
      ];
    case QualifyingLifeEventTypes.AGING_OFF_PARENTS_PLAN:
      return ['Proof of prior qualifying health coverage within the last 60 days.'];
    case QualifyingLifeEventTypes.OTHER:
      return [
        'Letter from school stating when student health coverage ended or will end.',
        'Dated copy of military discharge papers or Certificate of Release including the date coverage ended or will end due to no longer active military service.',
        'Letter or notice from government program, like TRICARE, Peace Corps, AmeriCorps, or Medicare stating when coverage ended or will end.',
        'Document from the Department of Corrections, jail or prison indicating release or parole, including an order of parole, order of release or an address certification',
        `Documentation from the Health Insurance Marketplace or state-based exchange showing determination of eligibility and date for the following events:
    ⚬ Newly eligible for Advanced Premium Tax Credit.
    ⚬ Materials violation of the plan contract.
    ⚬ Unintentional, inadvertent or erroneous enrollment.`,
        'Decertified or plan discontinuance letter.',
      ];
    case QualifyingLifeEventTypes.CHANGES_IN_RESIDENCE:
      return [
        'Lease or rental agreement.',
        'Mortgage deed if showing owners use the property as primary residence.',
        `Front and back of driver's license or state identification card.`,
        'U.S. Postal Service change of address confirmation.',
        'Official school documentation, including enrollment or housing documentation.',
        'Letter from current or future employer that you relocated for work.',
        'Green card, education certificate or visa if you moved to the U.S. from another country.',
      ];
    default:
      return [];
  }
};

export const defaultTermsAndConditions = `By proceeding with your benefit enrollment selections, \
you acknowledge that you have read, understood, and agree to the terms and conditions outlined in \
this disclaimer, below. You also acknowledge that you have had the opportunity to ask questions and \
seek clarification regarding your benefit options, and that you consent to enroll in such benefits electronically. \
The information contained herein presents an overview of certain benefits, but it is not a contract. \
This selection portal does not include all plan rules and details and is not considered a summary plan \
description or a certificate of coverage. The terms of your benefits are governed by legal plan documents, \
including insurance contracts. If there are any differences between the benefit descriptions herein and \
the legal plan documents and insurance contracts, the legal plan documents and insurance contracts are \
the final authority. The benefit descriptions herein are not a guarantee of current or future employment. \
We reserve the right to change, discontinue or terminate the benefit plans at any time, to the extent \
legally permissible. You understand that it is your responsibility to carefully review all benefit plan \
details, including coverage options, deductibles, co-pays, limitations, and exclusions. If you have \
questions about any aspect of the benefit plans, it is your responsibility to seek clarification \
from the HR department or the benefit plan administrator before making your selections. You \
acknowledge that there is a specific enrollment deadline. Failure to complete your enrollment \
selections within the specified timeframe may result in a delay of coverage or default to certain \
plan options chosen by the employer. Once the enrollment period is closed, changes to your benefit \
selections may be limited to qualifying life events. You understand that any changes made outside of \
these qualifying events may not be allowed until the next open enrollment period. You are responsible \
for providing accurate and up-to-date information during the benefit enrollment process. \
Any inaccuracies or omissions in the information provided are your sole responsibility and may affect \
your benefits coverage. It is your responsibility to maintain accurate contact information with the HR \
department to ensure you receive important communications related to your benefits.`;

export const kaiserProviderTermsAndConditions = `<strong>I understand that (except for Small Claims Court cases, \
claims subject to a Medicare appeals procedure or the ERISA claims procedure regulation, and any other \
claims that cannot be subject to binding arbitration under governing law) any dispute between myself, \
my heirs, relatives, or other associated parties on the one hand and Kaiser Foundation Health Plan, Inc. \
(KFHP), any contracted health care providers, administrators, or other associated parties on the other hand, \
for alleged violation of any duty arising out of or related to membership in KFHP, including any claim for \
medical or hospital malpractice (a claim that medical services were unnecessary or unauthorized or were \
improperly, negligently, or incompetently rendered), for premises liability, or relating to the coverage \
for, or delivery of, services or items, irrespective of legal theory, must be decided by binding \
arbitration under California law and not by lawsuit or resort to court process, except as applicable \
law provides for judicial review of arbitration proceedings. I agree to give up our right to a jury trial \
and accept the use of binding arbitration. I understand that the full arbitration provision is contained in the Evidence of Coverage.</strong>\n
By clicking the <strong>Accept</strong> button below and enrolling in a Kaiser health plan, I understand that this action \
will serve as my electronic signature of agreement to the conditions provided in the<strong> Kaiser Foundation \
Health Plan Arbitration Agreement (above)</strong> and that by law this electronic signature will have the same \
effect as a signature on a paper form. \n
Note: If you do not wish to accept the arbitration agreement above you must click on the <strong>Exit</strong> button \
in the upper right corner to go back to the plan selection screen and make a new Health Plan selection.\n
*Disputes arising from the following fully-insured Kaiser Permanente Insurance Company coverages are \
not subject to binding arbitration: 1) the Preferred Provider Organization (PPO) and the Out-of-Network \
portion of the Point-of- Service (POS) plans; 2) Preferred Provider Organization (PPO) plans; 3) \
Out-of-Area Indemnity (OOA) plans; and 4) KPIC Dental plans.`;
