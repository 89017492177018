import i18n from 'i18next';
import { useNativeMobileWeb } from '@/hooks/native/useNativeMobileWeb';
import useStores from '@/hooks/useStores';
import useUserStore from '@/hooks/useUserStore';
import zendesk from '@/utils/Zendesk';
import zowieApi from '@/utils/api/zowie';
import { useSnackbar } from '@letsdeel/ui';

const addSafeAreaStyle = () => {
  const zowieWrapper = document.getElementById('zowieChatbotWrapper');
  if (zowieWrapper) {
    zowieWrapper.classList.add('zowie-safe-area');
  }
};

export function useOpenChatbot(conversationId?: number): () => Promise<void> {
  const { lookups } = useStores();
  const { isNativeAndMobileWeb } = useNativeMobileWeb();
  const { showSnackbar } = useSnackbar();
  const user = useUserStore();
  const admin = user?.deel;

  return async (): Promise<void> => {
    const shouldLoadZowie = lookups.getFeatureFlag('chat.shouldLoadZowie');
    const { tags } = await zowieApi.getToken(conversationId);

    if (admin && !localStorage.getItem('SHOW_ZENDESK')) {
      showSnackbar(i18n.t('temp.unknown.hooks.support.4e075b38f5').toString());
    }

    if (shouldLoadZowie && tags.some((tag: any) => tag.Tag.name === 'zowie beta launch')) {
      if (isNativeAndMobileWeb) {
        addSafeAreaStyle();
      }
      window.Chatbotize?.open();
    } else {
      zendesk.open();
    }
  };
}
