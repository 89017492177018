import { toDownload } from '@/utils/download';
import instance from '@/utils/api/instance';

export default {
  addReport: async (contractId: string, payload: any) => {
    const { data } = await instance.post(`employee/${contractId}/adjustment`, payload);
    return data;
  },

  deleteReport: async (reportId: number, payload: any) => {
    const { data } = await instance.delete(`employee/adjustment/${reportId}`, payload);
    return data;
  },

  updateReport: async (reportId: number | undefined, payload: any) => {
    const { data } = await instance.patch(`employee/adjustment/${reportId}`, payload);
    return data;
  },

  approveAdditionalInfo: async (id: string, payload: any) => {
    const { data } = await instance.put(`contracts/${id}/employee-info/approve`, payload);
    return data;
  },

  rejectAdditionalInfo: async (id: string, payload: any) => {
    const { data } = await instance.put(`contracts/${id}/employee-info/reject`, payload);
    return data;
  },

  sendToEmployee: async (id: string) => {
    const { data } = await instance.put(`eor/contracts/${id}/ea_send`);
    return data;
  },

  revoke: async (id: string) => {
    const { data } = await instance.put(`contracts/${id}/revoke`);
    return data;
  },

  getEmployeeAdditionalInfoPDF: (oid: string) => toDownload(`contracts/${oid}/employee-additional-info-pdf-url`),
};
