import instance from '@/utils/api/instance';
import type { AxiosRequestConfig } from 'axios';
import { makeHeadRequestWithRetry } from '../axiosHelpers';

export default {
  check: async (key: string) => {
    const { data } = await instance.get('/upload/check', { params: { key } });
    return data;
  },
  getFile: async (url: string, config?: AxiosRequestConfig<any>) => {
    const { data } = await instance.get(url, config);
    return data;
  },

  getFileUsingPostRequest: async (url: string, body: Record<string, unknown>) => {
    const { data } = await instance.post(url, body);
    return data;
  },

  getFileLocation: async (url: string) => {
    const { data } = await instance.get(url);

    return data?.url || data?.downloadUrl;
  },

  getFileBlob: async (url: string, onProgress?: (percentCompleted?: number) => void) => {
    const { data } = await instance.get(url, {
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent || {};
        if (loaded && total) {
          onProgress?.(Math.round((loaded * 100) / total));
        }
      },
    });
    return data;
  },

  getPDFPreview: async (url: string, config?: AxiosRequestConfig<any>) => {
    const { data } = await instance.get(url, { ...(config || {}), responseType: 'text' });
    return data;
  },

  getFileNoRedirect: async (url: string, wait = false) => {
    const { data } = await instance.get(url, { params: { noredirect: 1, ...(wait ? { wait: 1 } : undefined) } });
    return data;
  },

  exists: async (key: string) => {
    const { data } = await instance.get('/upload/exists', {
      params: { key },
      suppressSnackbar: false,
      hideErrorMessageOnNetworkError: true,
    });

    return data;
  },

  existsMobility: async (key: string) => {
    const { data } = await instance.get('mobility/upload/exists', {
      params: { key },
      hideErrorMessageOnNetworkError: true,
    });

    return data;
  },

  existsSorted: async (key: string) => {
    const { data } = await instance.get('sorted/upload/exists', {
      params: { key },
      hideErrorMessageOnNetworkError: true,
    });

    return data;
  },

  existsEorExperience: async (key: string) => {
    const { data } = await instance.get('eor-experience/upload/exists', {
      params: { key },
      suppressSnackbar: false,
    });

    return data;
  },

  existsFormBuilder: async (key: string) => {
    const response = await makeHeadRequestWithRetry(`form_builder/uploads/${key}`, {
      responseType: 'text',
    });

    return Promise.resolve({ exists: response.status === 204 });
  },

  existsFormPayrollInitRun: async (key: string) => {
    const { data } = await instance.get('embedded_payroll/init_run/upload/exists', {
      params: { key },
      hideErrorMessageOnNetworkError: true,
    });

    return data;
  },

  copy: async (body: { sourceKey: string; targetKey: string }) => {
    const { data } = await instance.post('/upload/copy', body);
    return data;
  },
};
