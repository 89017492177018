import clsx from 'clsx';
import { getCurrencySymbolFromStore, getCurrencySymbolPosition, isCryptoCurrency } from '@/utils/numbers';
import BigNumber from 'bignumber.js';
import { useContext } from 'react';
import { SensitiveDataContext } from '@/context/SensitiveDataContext';
import { useSensitiveConvertToPriceFormatFactory } from '@/hooks/numbers/useSensitiveConvertToPriceFormat';
import { SENSITIVE_DATA_PLACEHOLDER } from '@/scenes/Settings/scenes/SecuritySettings/utils/SensitiveData';
import useStores from '@/hooks/useStores';
import { observer } from 'mobx-react';

type fontClasses = 'font-32' | 'font-28' | 'font-24' | 'font-20' | 'font-14' | 'font-12';

export interface PriceLabelProps {
  currency?: string | null;
  customCurrencySybol?: string;
  amount?: string | number | BigNumber;
  className?: string;
  fractionClassName?: string;
  numberClassName?: string;
  currencySymbolClassName?: string;
  showSign?: boolean;
  strikeThrough?: boolean;
  fontClass?: fontClasses;
  overdue?: boolean;
  regularFontWeight?: boolean;
  disabledAutoformating?: boolean;
  alwaysShowDecimals?: boolean;
  alwaysShowPrice?: boolean;
  decimalPlace?: number;
  hideDecimal?: boolean;
  'data-qa'?: string;
  currencyColor?: string;
  showCurrencyCode?: boolean;
  /**
   * @deprecated use fontClass="font-32" instead of large
   */
  large?: boolean; // we use fontClass, because of extended sizes table for this component.
  Wrapper?: keyof JSX.IntrinsicElements;
  useShortSymbol?: boolean;
  shortenNumber?: boolean;
  forceDataHidden?: boolean;
  color?: string;
}

const DECIMAL_FONTS: { [font in fontClasses]: string } = {
  'font-12': 'font-12',
  'font-14': 'font-12',
  'font-20': 'font-18',
  'font-24': 'font-18',
  'font-32': 'font-24',
  'font-28': 'font-28',
};

const shortenNumberHelper = (number: number) => {
  if (Math.abs(number) >= 1e6) {
    // Convert to millions and append 'M' suffix
    return (number / 1e6).toFixed(1) + 'M';
  } else if (Math.abs(number) >= 1e3) {
    // Convert to thousands and append 'K' suffix
    return (number / 1e3).toFixed(1) + 'K';
  } else {
    // Return the original number if it is less than 1000
    return number.toString();
  }
};

const getPrice = (
  disabledAutoformating: boolean,
  isZero: boolean,
  isSensitiveDataHidden: boolean,
  alwaysShowPrice: boolean,
  amount: string | number | BigNumber | undefined,
  decimalPlace: number,
  isSidebarHiddenOnFocusView: boolean | undefined,
  toPriceFormat: (
    amount: number,
    decimal: number,
    forceFractionDigit: boolean,
    alwaysShowPrice: boolean,
    isSidebarHiddenOnFocusView?: boolean
  ) => string
) => {
  if (!disabledAutoformating && !isZero) {
    return toPriceFormat(Math.abs(amount as number), decimalPlace, true, alwaysShowPrice, isSidebarHiddenOnFocusView);
  }
  if (isSensitiveDataHidden && !alwaysShowPrice) {
    return SENSITIVE_DATA_PLACEHOLDER;
  }
  if (isZero) return '0.00';
  return String(amount);
};

const PriceLabel = ({
  currency,
  amount,
  className,
  fractionClassName,
  numberClassName,
  currencySymbolClassName,
  showSign,
  strikeThrough = false,
  fontClass = 'font-14',
  overdue = false,
  regularFontWeight = false,
  disabledAutoformating = false,
  decimalPlace = 2,
  alwaysShowDecimals = false,
  alwaysShowPrice = false,
  hideDecimal = false,
  Wrapper = 'p',
  customCurrencySybol,
  currencyColor,
  showCurrencyCode,
  useShortSymbol = false,
  shortenNumber = false,
  forceDataHidden = false,
  color,
  ...props
}: PriceLabelProps) => {
  const { sidebar } = useStores();
  const { isSensitiveDataHidden } = useContext(SensitiveDataContext);
  const toPriceFormat = useSensitiveConvertToPriceFormatFactory();
  const needShowSign = showSign === undefined ? new BigNumber(amount || 0).lt(0) : showSign;
  const isZero = Number(amount) === 0 || isNaN(Number(amount));
  const currencySymbol = currency ? getCurrencySymbolFromStore(currency, useShortSymbol) : customCurrencySybol;
  const positionRight = currency ? getCurrencySymbolPosition(currency) : true;
  const price = getPrice(
    disabledAutoformating,
    isZero,
    forceDataHidden || isSensitiveDataHidden,
    alwaysShowPrice,
    amount,
    decimalPlace,
    sidebar?.isSidebarHiddenOnFocusView,
    toPriceFormat
  );
  const priceParts = price?.split('.');

  const currencySymbolMarkup = () => (
    <span
      className={clsx(
        strikeThrough && 'line-through color-gray-dark',
        fontClass,
        overdue && 'color-error-medium',
        !regularFontWeight && 'semi-bold',
        currencySymbolClassName,
        isCryptoCurrency(currency) && 'mr-2'
      )}
      style={{
        color: currencyColor,
      }}
    >
      {currencySymbol}
    </span>
  );

  const currencyCodeMarkup = () => (
    <span
      className={clsx(DECIMAL_FONTS[fontClass], !regularFontWeight && 'semi-bold')}
      style={{ fontSize: 'min(86%, 14px)', display: 'inline-block', marginLeft: '4px' }}
    >
      {currency}
    </span>
  );

  const isDecimalsVisible =
    (!isSensitiveDataHidden || alwaysShowPrice) && (alwaysShowDecimals || !isZero) && !hideDecimal;
  const decimals = isDecimalsVisible && (!disabledAutoformating ? priceParts[1] || '00' : priceParts[1]);

  return (
    <Wrapper
      className={clsx('no-wrap', className)}
      data-qa={props['data-qa'] || 'amount'}
      style={{ color: color ? `${color} !important` : '' }}
    >
      <span
        translate="no"
        className={clsx(
          strikeThrough && 'line-through color-gray-dark',
          fontClass,
          overdue && 'color-error',
          !regularFontWeight && 'semi-bold',
          numberClassName
        )}
      >
        {!needShowSign ? '' : Number(amount) < 0 ? '- ' : Number(amount) > 0 ? '+ ' : ''}
        {!positionRight ? currencySymbolMarkup() : null}
        {positionRight && showCurrencyCode ? currencyCodeMarkup() : null}
        {shortenNumber && disabledAutoformating && !isSensitiveDataHidden
          ? shortenNumberHelper(Number(priceParts[0]))
          : priceParts[0]}
        {(!disabledAutoformating || priceParts.length > 1) && isDecimalsVisible ? '.' : ''}
      </span>
      <span
        translate="no"
        className={clsx(
          strikeThrough && 'line-through color-gray-dark',
          DECIMAL_FONTS[fontClass],
          overdue && 'color-error',
          !regularFontWeight && 'semi-bold',
          fractionClassName
        )}
      >
        {decimals}
      </span>
      {positionRight ? currencySymbolMarkup() : null}
      {!positionRight && showCurrencyCode ? currencyCodeMarkup() : null}
    </Wrapper>
  );
};

export default observer(PriceLabel);
