import { lazy } from 'react';

import { Illustration } from '@letsdeel/ui';

// PNG imports
import SignupSideClient from '../assets/images/graphics/common/signup-side-client.png';
import SignupSideContractor from '../assets/images/graphics/common/signup-side-contractor.png';
import SignupSideEmployeeInfo from '../assets/images/graphics/common/signup-side-employee-info.png';
import TopNavBannerMedia from '../assets/images/graphics/common/top-nav-banner.png';
import Reports from '../assets/images/graphics/large/reports.png';
import ExpensePdfMock from '../assets/images/graphics/other/global-payroll/expense-pdf-mock.png';

// Logos
import LOGOS from './logos';
import i18next from 'i18next';

// Blob
const InfoDefaultAlert = lazy(() => import('../assets/images/graphics/blobs/info_default.svg?react'));
const InfoErrorAlert = lazy(() => import('../assets/images/graphics/blobs/info_error.svg?react'));
const GeneralError = lazy(() => import('../assets/images/graphics/blobs/error.svg?react'));
const InviteSentNewBlobGraphic = lazy(() => import('../assets/images/graphics/blobs/invite-sent-new.svg?react'));
const NoContentBlobGraphic = lazy(() => import('../assets/images/graphics/blobs/no-content.svg?react'));
const ThumbPrintBlobGraphic = lazy(() => import('../assets/images/graphics/blobs/thumb-print.svg?react'));
const ContractEnding = lazy(() => import('../assets/images/graphics/blobs/contract-ending-2.svg?react'));
const InsightsConversationBG = lazy(() => import('../assets/images/graphics/blobs/insights-conversation-bg.svg?react'));
const DeelWebhooks = lazy(() => import('../assets/images/graphics/blobs/deel-webhooks-small.svg?react'));

const TimeDefaultGraphic = lazy(() => import('../assets/images/graphics/blobs/time-default.svg?react'));
const JourneyDefaultThumbnail = lazy(
  () => import('../assets/images/graphics/blobs/journey-default-thumbnail.svg?react')
);
const NBoxEmptyStateBlob = lazy(() => import('../assets/images/graphics/blobs/n-box-empty-state.svg?react'));
const HeatMapEmptyStateBlob = lazy(() => import('../assets/images/graphics/blobs/heat-map-empty-state.svg?react'));

// Common
const MailDeliveringGraphic = lazy(() => import('../assets/images/graphics/common/mail-delivering.svg?react'));

const NoSearchResultsGrahic = lazy(() => import('../assets/images/graphics/common/no-search-result.svg?react'));

const BriefcaseBlueGraphic = lazy(() => import('../assets/images/graphics/common/briefcase-blue.svg?react'));
const PuzzleGraphic = lazy(() => import('../assets/images/graphics/common/puzzle.svg?react'));
const StarGraphic = lazy(() => import('../assets/images/graphics/common/star.svg?react'));
const GlobeGraphic = lazy(() => import('../assets/images/graphics/common/globe.svg?react'));
const CardPhysical = lazy(() => import('../assets/images/graphics/common/physical-card.svg?react'));
const CardVirtual = lazy(() => import('../assets/images/graphics/common/virtual-card.svg?react'));
const CalculatorCircle = lazy(() => import('../assets/images/graphics/common/calculator-circle.svg?react'));
const PiggyBankCircle = lazy(() => import('../assets/images/graphics/common/piggy-bank-circle.svg?react'));
const CashCircle = lazy(() => import('../assets/images/graphics/common/cash-circle.svg?react'));
const SubdomainSmall = lazy(() => import('../assets/images/graphics/common/subdomain-small.svg?react'));
const CardGraphic = lazy(() => import('../assets/images/graphics/common/card-graphic.svg?react'));
const ClickMoney = lazy(() => import('../assets/images/graphics/common/click-money.svg?react'));
const ShieldUpselling = lazy(() => import('../assets/images/graphics/common/shield-upselling-card.svg?react'));

const ShieldUpsellingBanner = lazy(() => import('../assets/images/graphics/common/shield-upselling-banner.svg?react'));

const HelpCenter = lazy(() => import('../assets/images/graphics/common/help-center.svg?react'));
const Blog = lazy(() => import('../assets/images/graphics/common/blog.svg?react'));
const Faq = lazy(() => import('../assets/images/graphics/common/faq.svg?react'));

const DeelEnvelope = lazy(() => import('../assets/images/graphics/common/deel-envelope.svg?react'));
const CashReward = lazy(() => import('../assets/images/graphics/common/cash-reward.svg?react'));
const CreditCard = lazy(() => import('../assets/images/graphics/common/credit-card.svg?react'));

const CallIcon = lazy(() => import('../assets/images/graphics/common/call-icon.svg?react'));
const GiftIcon = lazy(() => import('../assets/images/graphics/common/gift-icon.svg?react'));
const InviteIcon = lazy(() => import('../assets/images/graphics/common/invite-icon.svg?react'));

const Chat = lazy(() => import('../assets/images/graphics/common/chat.svg?react'));
const Equipment = lazy(() => import('../assets/images/graphics/common/equipment.svg?react'));
const TFADevice = lazy(() => import('../assets/images/graphics/common/tfa-device.svg?react'));
const LocalAdvice = lazy(() => import('../assets/images/graphics/common/local-advice.svg?react'));
const QuestionAnswer = lazy(() => import('../assets/images/graphics/common/question-answer.svg?react'));

const DeelProductsAgreement = lazy(() => import('../assets/images/graphics/common/deel-products-agreement.svg?react'));

// Large
const DeelCardInfo = lazy(() => import('../assets/images/graphics/large/deel-card-info.svg?react'));
const DeelCardNew = lazy(() => import('../assets/images/graphics/large/deel-card-new.svg?react'));
const DeelCardGet = lazy(() => import('../assets/images/graphics/large/deel-card-get.svg?react'));
const DeelCardGetSmall = lazy(() => import('../assets/images/graphics/large/deel-card-get-small.svg?react'));
const DeelCardBg = lazy(() => import('../assets/images/graphics/large/deel-card-bg.svg?react'));
const DeelCardBgMC = lazy(() => import('../assets/images/graphics/large/deel-card-bg-mc.svg?react'));
const DeelCardPhysical = lazy(() => import('../assets/images/graphics/large/deel-mastercard-physical.svg?react'));
const DeelCardPhysicalMobile = lazy(
  () => import('../assets/images/graphics/large/deel-mastercard-physical-mobile.svg?react')
);
const DeelCardVirtual = lazy(() => import('../assets/images/graphics/large/deel-mastercard-virtual.svg?react'));
const DeelCardVirtualMobile = lazy(
  () => import('../assets/images/graphics/large/deel-mastercard-virtual-mobile.svg?react')
);
const DeelCardVirtualFrozen = lazy(
  () => import('../assets/images/graphics/large/deel-mastercard-virtual-frozen.svg?react')
);
const DeelCardVirtualFrozenMobile = lazy(
  () => import('../assets/images/graphics/large/deel-mastercard-virtual-frozen-mobile.svg?react')
);
const DeelCardPhysicalFrozen = lazy(
  () => import('../assets/images/graphics/large/deel-mastercard-physical-frozen.svg?react')
);
const DeelCardPhysicalFrozenMobile = lazy(
  () => import('../assets/images/graphics/large/deel-mastercard-physical-frozen-mobile.svg?react')
);
const DeelCardCanceled = lazy(() => import('../assets/images/graphics/large/deel-mastercard-canceled.svg?react'));
const DeelCardCanceledMobile = lazy(
  () => import('../assets/images/graphics/large/deel-mastercard-canceled-mobile.svg?react')
);
const DeelCard2023VisaMiniatureVirtual = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2023-visa-miniature-virtual.svg?react')
);
const DeelCard2023VisaMiniatureVirtualFrozen = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2023-visa-miniature-virtual-frozen.svg?react')
);
const DeelCard2023VisaMiniatureCanceled = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2023-visa-miniature-canceled.svg?react')
);
const DeelCard2023VisaVirtual = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2023-visa-virtual.svg?react')
);
const DeelCard2023VisaVirtualFrozen = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2023-visa-virtual-frozen.svg?react')
);
const DeelCard2023VisaCanceled = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2023-visa-canceled.svg?react')
);
const DeelCard2023VisaVirtualMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2023-visa-virtual-mobile.svg?react')
);
const DeelCard2023VisaVirtualFrozenMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2023-visa-virtual-frozen-mobile.svg?react')
);
const DeelCard2023VisaVirtualCanceledMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2023-visa-canceled-mobile.svg?react')
);
const DeelVisaCardPhysical = lazy(() => import('../assets/images/graphics/large/deel-visa-physical.svg?react'));
const DeelVisaCardVirtual = lazy(() => import('../assets/images/graphics/large/deel-visa-virtual.svg?react'));
const DeelVisaCardVirtualFrozen = lazy(
  () => import('../assets/images/graphics/large/deel-visa-virtual-frozen.svg?react')
);
const DeelVisaCardPhysicalFrozen = lazy(
  () => import('../assets/images/graphics/large/deel-visa-physical-frozen.svg?react')
);
const DeelVisaCardCanceled = lazy(() => import('../assets/images/graphics/large/deel-visa-canceled.svg?react'));
const DeelVisaCardCanceledMobile = lazy(
  () => import('../assets/images/graphics/large/deel-visa-canceled-mobile.svg?react')
);
const DeelCardMiniatureVirtual = lazy(
  () => import('../assets/images/graphics/large/deel-card-miniature-virtual.svg?react')
);
const DeelCardMiniatureVirtualFrozen = lazy(
  () => import('../assets/images/graphics/large/deel-card-miniature-virtual-frozen.svg?react')
);
const DeelCardMiniaturePhysical = lazy(
  () => import('../assets/images/graphics/large/deel-card-miniature-physical.svg?react')
);
const DeelCardMiniaturePhysicalFrozen = lazy(
  () => import('../assets/images/graphics/large/deel-card-miniature-physical-frozen.svg?react')
);
const DeelCardMiniatureCanceled = lazy(
  () => import('../assets/images/graphics/large/deel-card-miniature-canceled.svg?react')
);
const DeelVisaCardMiniaturePhysical = lazy(
  () => import('../assets/images/graphics/large/deel-visa-miniature-physical.svg?react')
);
const DeelVisaCardMiniaturePhysicalFrozen = lazy(
  () => import('../assets/images/graphics/large/deel-visa-miniature-physical-frozen.svg?react')
);
const DeelVisaCardMiniatureVirtual = lazy(
  () => import('../assets/images/graphics/large/deel-visa-miniature-virtual.svg?react')
);
const DeelVisaCardMiniatureVirtualFrozen = lazy(
  () => import('../assets/images/graphics/large/deel-visa-miniature-virtual-frozen.svg?react')
);
const DeelVisaCardMiniatureCanceled = lazy(
  () => import('../assets/images/graphics/large/deel-visa-miniature-canceled.svg?react')
);
const Perks = lazy(() => import('../assets/images/graphics/large/perks.svg?react'));
const DeelVirtualCardImg = lazy(() => import('../assets/images/graphics/large/deel-virtual-card-img.svg?react'));
const DeelPhysicalCardImg = lazy(() => import('../assets/images/graphics/large/deel-physical-card-img.svg?react'));
const DeelPhysicalCardDisabledImg = lazy(
  () => import('../assets/images/graphics/large/deel-physical-card-disabled-img.svg?react')
);
const DeelCard2024VisaAwx = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-visa-awx.svg?react')
);
const DeelCard2024VisaAwxMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-visa-awx-mobile.svg?react')
);
const DeelCard2024VisaAwxFrozen = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-visa-awx-frozen.svg?react')
);
const DeelCard2024VisaAwxFrozenMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-visa-awx-mobile-frozen.svg?react')
);
const DeelCard2024VisaAwxCancelled = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-visa-awx-cancelled.svg?react')
);
const DeelCard2024VisaAwxCancelledMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-visa-awx-mobile-cancelled.svg?react')
);
const DeelCard2024MasterCardAwx = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-mc-awx.svg?react')
);
const DeelCard2024MasterCardAwxMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-mc-awx-mobile.svg?react')
);
const DeelCard2024MasterCardAwxFrozen = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-mc-awx-frozen.svg?react')
);
const DeelCard2024MasterCardAwxFrozenMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-mc-awx-mobile-frozen.svg?react')
);
const DeelCard2024MasterCardAwxCancelled = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-mc-awx-cancelled.svg?react')
);
const DeelCard2024MasterCardAwxCancelledMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-mc-awx-mobile-cancelled.svg?react')
);

const DeelCard2024VisaNiumPhysical = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-visa-nium-physical.svg?react')
);
const DeelCard2024VisaNiumPhysicalMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-visa-nium-physical-mobile.svg?react')
);
const DeelCard2024VisaNiumPhysicalFrozen = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-visa-nium-physical-frozen.svg?react')
);
const DeelCard2024VisaNiumPhysicalFrozenMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-visa-nium-physical-mobile-frozen.svg?react')
);
const DeelCard2024VisaNiumPhysicalCancelled = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-visa-nium-physical-cancelled.svg?react')
);
const DeelCard2024VisaNiumPhysicalCancelledMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-visa-nium-physical-mobile-cancelled.svg?react')
);
const DeelCard2024MasterCardNiumPhysical = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-mc-nium-physical.svg?react')
);
const DeelCard2024MasterCardNiumPhysicalMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-mc-nium-physical-mobile.svg?react')
);
const DeelCard2024MasterCardNiumPhysicalFrozen = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-mc-nium-physical-frozen.svg?react')
);
const DeelCard2024MasterCardNiumPhysicalFrozenMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-mc-nium-physical-mobile-frozen.svg?react')
);
const DeelCard2024MasterCardNiumPhysicalCancelled = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-mc-nium-physical-cancelled.svg?react')
);
const DeelCard2024MasterCardNiumPhysicalCancelledMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-mc-nium-physical-mobile-cancelled.svg?react')
);
const DeelCard2024VisaNiumVirtual = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-visa-nium-virtual.svg?react')
);
const DeelCard2024VisaNiumVirtualMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-visa-nium-virtual-mobile.svg?react')
);
const DeelCard2024VisaNiumVirtualFrozen = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-visa-nium-virtual-frozen.svg?react')
);
const DeelCard2024VisaNiumVirtualFrozenMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-visa-nium-virtual-mobile-frozen.svg?react')
);
const DeelCard2024VisaNiumVirtualCancelled = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-visa-nium-virtual-cancelled.svg?react')
);
const DeelCard2024VisaNiumVirtualCancelledMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-visa-nium-virtual-mobile-cancelled.svg?react')
);
const DeelCard2024MasterCardNiumVirtual = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-mc-nium-virtual.svg?react')
);
const DeelCard2024MasterCardNiumVirtualMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-mc-nium-virtual-mobile.svg?react')
);
const DeelCard2024MasterCardNiumVirtualFrozen = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-mc-nium-virtual-frozen.svg?react')
);
const DeelCard2024MasterCardNiumVirtualFrozenMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-mc-nium-virtual-mobile-frozen.svg?react')
);
const DeelCard2024MasterCardNiumVirtualCancelled = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-mc-nium-virtual-cancelled.svg?react')
);
const DeelCard2024MasterCardNiumVirtualCancelledMobile = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-mc-nium-virtual-mobile-cancelled.svg?react')
);
const DeelCard2024McPhysicalMiniatureCanceled = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-mastercard-physical-miniature-canceled.svg?react')
);
const DeelCard2024VisaPhysicalMiniatureCanceled = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-visa-physical-miniature-canceled.svg?react')
);
const DeelCard2024McVirtualMiniatureCanceled = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-mastercard-virtual-miniature-canceled.svg?react')
);
const DeelCard2024VisaVirtualMiniatureCanceled = lazy(
  () => import('../assets/images/graphics/large/deel-card/deel-2024-visa-virtual-miniature-canceled.svg?react')
);

const PiggyBank = lazy(() => import('../assets/images/graphics/large/piggy-bank.svg?react'));

// Other
const GoogleWorkspaceBanner = lazy(() => import('../assets/images/graphics/other/google-workspace.svg?react'));
const IntegrationsBanner = lazy(() => import('../assets/images/graphics/other/integrations-banner-graphics.svg?react'));
const AmazonGiftCardVariant = lazy(() => import('../assets/images/graphics/other/amazon-gift-card-variant.svg?react'));
const NotFound = lazy(() => import('../assets/images/graphics/other/not-found.svg?react'));
const AmazonGiftCardPurpleVariant = lazy(
  () => import('../assets/images/graphics/other/amazon-gift-card-purple-variant.svg?react')
);
const AmazonGiftCardPinkVariant = lazy(
  () => import('../assets/images/graphics/other/amazon-gift-card-pink-variant.svg?react')
);
const RootsBanner = lazy(() => import('../assets/images/graphics/other/roots-banner-graphic.svg?react'));

const HiringToolkit = lazy(() => import('../assets/images/graphics/other/hiring-toolkit.svg?react'));
const WorkdayBanner = lazy(() => import('../assets/images/graphics/other/workday-banner.svg?react'));
const AccountingPluginsBanner = lazy(
  () => import('../assets/images/graphics/other/accounting-plugins-banner.svg?react')
);
const PerksBanner = lazy(() => import('../assets/images/graphics/other/perks-banner-graphics.svg?react'));
const AppsAndPerksBanner = lazy(
  () => import('../assets/images/graphics/other/apps-and-perks-banner-graphics.svg?react')
);
const AppsAndIntegrationsBanner = lazy(() => import('../assets/images/graphics/other/integrations-banner.svg?react'));
const BambooHRBanner = lazy(() => import('../assets/images/graphics/other/bbhr-banner.svg?react'));
const HibobBanner = lazy(() => import('../assets/images/graphics/other/hibob-banner.svg?react'));
const AshbyBanner = lazy(() => import('../assets/images/graphics/other/ashby-banner.svg?react'));
const GreenhouseBanner = lazy(() => import('../assets/images/graphics/other/greenhouse-banner.svg?react'));
const LeverBanner = lazy(() => import('../assets/images/graphics/other/lever-banner.svg?react'));
const HrisPaywallBanner = lazy(() => import('../assets/images/graphics/other/hris-paywall-banner.svg?react'));
const HrisRootsPaywallBanner = lazy(
  () => import('../assets/images/graphics/other/hris-roots-paywall-banner.svg?react')
);
const SlackRootsBanner = lazy(() => import('../assets/images/graphics/other/slack-roots-banner.svg?react'));
const OrgChartBanner = lazy(() => import('../assets/images/graphics/other/org-chart.svg?react'));
const GlobalPayrollMarketingBannerOld = lazy(
  () => import('../assets/images/graphics/other/global-payroll/old/global-payroll-marketing-banner.svg?react')
);
const UpsellingValueExperts = lazy(
  () => import('../assets/images/graphics/other/upsellingValueIcons/upselling-payroll-experts.svg?react')
);
const UpsellingValueAccounting = lazy(
  () => import('../assets/images/graphics/other/upsellingValueIcons/upselling-payroll-accounting.svg?react')
);
const UpsellingValueGlobalSupport = lazy(
  () => import('../assets/images/graphics/other/upsellingValueIcons/upselling-payroll-global-support.svg?react')
);
const UpsellingValueEmployee = lazy(
  () => import('../assets/images/graphics/other/upsellingValueIcons/upselling-employee.svg?react')
);
const UpsellingValueAutomate = lazy(
  () => import('../assets/images/graphics/other/upsellingValueIcons/upselling-automate.svg?react')
);
const UpsellingValueReports = lazy(
  () => import('../assets/images/graphics/other/upsellingValueIcons/upselling-reports.svg?react')
);
const EmploymentVerificationLetter = lazy(
  () => import('../assets/images/graphics/other/employment-verification-letter.svg?react')
);
const ComplianceDocsVerticalBanner = lazy(
  () => import('../assets/images/graphics/other/compliance-docs-vertical-banner.svg?react')
);
const VerifiedAccountsVerticalBanner = lazy(
  () => import('../assets/images/graphics/other/verified-accounts-vertical-banner.svg?react')
);
const CommunityGift = lazy(() => import('../assets/images/graphics/other/community-page/gift.svg?react'));
const InternetUsers = lazy(() => import('../assets/images/graphics/other/community-page/internet-users.svg?react'));
const OpenedLaptop = lazy(() => import('../assets/images/graphics/other/community-page/opened-laptop.svg?react'));
const GlobalPayrollBannerMedia = lazy(() => import('../assets/images/graphics/blobs/global-payroll-banner.svg?react'));
const VisaSupportBannerMedia = lazy(() => import('../assets/images/graphics/other/immigration-banner.svg?react'));
const BenefitsQLEBanner = lazy(() => import('../assets/images/graphics/other/benefits-qle-banner.svg?react'));
const DeelHr = lazy(() => import('../assets/images/graphics/common/deel-hr-icon.svg?react'));
const WorkableBanner = lazy(() => import('../assets/images/graphics/other/workable-banner.svg?react'));

// Andela signup/signin backgrounds
const AndelaAlgeria = lazy(() => import('../assets/images/graphics/other/img_skylineAlgeria.svg?react'));
const AndelaEgypt = lazy(() => import('../assets/images/graphics/other/img_skylineEgypt.svg?react'));
const AndelaKenya = lazy(() => import('../assets/images/graphics/other/img_skylineKenya.svg?react'));
const AndelaMorocco = lazy(() => import('../assets/images/graphics/other/img_skylineMorocco.svg?react'));
const AndelaNigeria = lazy(() => import('../assets/images/graphics/other/img_skylineNigeria.svg?react'));
const AndelaSouthAfrica = lazy(() => import('../assets/images/graphics/other/img_skylineSouthAfrica.svg?react'));

//Verification letter elements
const VerificationLetterTopLeft = lazy(
  () => import('../assets/images/graphics/other/verification-letter-top-left.svg?react')
);
const VerificationLetterTop = lazy(() => import('../assets/images/graphics/other/verification-letter-top.svg?react'));
const VerificationLetterBottomRight = lazy(
  () => import('../assets/images/graphics/other/verification-letter-bottom-right.svg?react')
);
const VerificationLetterRight = lazy(
  () => import('../assets/images/graphics/other/verification-letter-right.svg?react')
);
const PeoSolutionsBanner = lazy(() => import('../assets/images/graphics/blobs/peo-solutions-banner.svg?react'));
const EorDeelInsightGraphic = lazy(() => import('../assets/images/graphics/illustration/eor-deel-insight.svg?react'));
const NewAppStoreGraphic = lazy(() => import('../assets/images/graphics/other/new-app-store.svg?react'));
const NewSuccessGraphic = lazy(() => import('../assets/images/graphics/blobs/new-design-success-popup.svg?react'));
const NewWarningGraphic = lazy(() => import('../assets/images/graphics/blobs/new-design-warning-popup.svg?react'));
const NoContentGraphic = lazy(() => import('../assets/images/graphics/blobs/no-content.svg?react'));
const BiometricGraphic = lazy(() => import('../assets/images/graphics/common/biometrics.svg?react'));
const PinCreationGraphic = lazy(() => import('../assets/images/graphics/common/pin-creation.svg?react'));
const DeleteAlt = lazy(() => import('../assets/images/graphics/blobs/delete-alt.svg?react'));
const AutomationAlertBanner = lazy(() => import('../assets/images/graphics/large/automate-alerts-mobile.svg?react'));
const SignContract = lazy(() => import('../assets/images/graphics/common/sign-contract.svg?react'));
const NoPaymentFiles = lazy(() => import('../assets/images/graphics/blobs/no-payment-files.svg?react'));
const MFARequest = lazy(() => import('../assets/images/graphics/common/mfa-request.svg?react'));
const MFASuccess = lazy(() => import('../assets/images/graphics/common/mfa-success.svg?react'));
const MFATimeOut = lazy(() => import('../assets/images/graphics/common/mfa-time-out.svg?react'));
const DAMFAPermission = lazy(() => import('../assets/images/graphics/common/damfa-permission.svg?react'));
const DAMFAPermissionSuccess = lazy(() => import('../assets/images/graphics/common/damfa-permission-success.svg?react'));

export const graphics = {
  blob: {
    ['no-payment-files']: <NoPaymentFiles />,
    ['biometric']: <BiometricGraphic />,
    ['pin-creation']: <PinCreationGraphic />,

    ['invoice-empty-state-blob']: <Illustration illustration="contract" />,
    ['invoice-empty-state-v2-blob']: <Illustration illustration="contract" />,
    ['deel-premium-banner-image']: <Illustration illustration="premium" />,
    ['guarantee-rejected']: <Illustration illustration="premium-error" />,

    ['mfa-request']: <MFARequest />,
    ['mfa-success']: <MFASuccess />,
    ['mfa-time-out']: <MFATimeOut />,
    ['damfa-permission']: <DAMFAPermission />,
    ['damfa-permission-success']: <DAMFAPermissionSuccess />,

    ['info-default']: <InfoDefaultAlert />,
    ['info-error']: <InfoErrorAlert />,
    ['general-error']: <GeneralError />,
    ['protection-blob']: <Illustration illustration="premium" />,
    ['protection-approved']: <Illustration illustration="premium-success" />,
    ['approved-contract-blob']: <Illustration illustration="contract-success" />,
    ['amend-contract-blob']: <Illustration illustration="contract" />,
    ['amend-contract-blob-new']: <Illustration illustration="contract" />,
    ['auto-pay']: <Illustration illustration="browser-plugin" />,
    ['cannon-blob']: <Illustration illustration="contract" />,
    ['document-in-folder-blob']: <Illustration illustration="contract" />,
    ['card-virtual']: <CardVirtual />,
    ['card-physical']: <CardPhysical />,
    ['company-blob']: <Illustration illustration="company" />,
    ['confirm-text']: <Illustration illustration="no-content" />,
    ['contact-support']: <Illustration illustration="info" />,
    ['eor-contract-blob']: <Illustration illustration="contract" />,
    ['direct-contract-blob']: <Illustration illustration="contract" />,
    ['contract-round']: <Illustration illustration="contract" />,
    ['credit-card-blob']: <Illustration illustration="payment" />,
    ['deel-card-blob']: <Illustration illustration="payment" />,
    ['deel-card-bg']: <DeelCardBg />,
    ['deel-mastercard-bg']: <DeelCardBgMC />,
    'deel-mastercard-physical': <DeelCardPhysical />,
    'deel-mastercard-physical-mobile': <DeelCardPhysicalMobile />,
    'deel-mastercard-virtual': <DeelCardVirtual />,
    'deel-mastercard-virtual-mobile': <DeelCardVirtualMobile />,
    'deel-mastercard-physical-frozen': <DeelCardPhysicalFrozen />,
    'deel-mastercard-physical-frozen-mobile': <DeelCardPhysicalFrozenMobile />,
    'deel-mastercard-virtual-frozen': <DeelCardVirtualFrozen />,
    'deel-mastercard-virtual-frozen-mobile': <DeelCardVirtualFrozenMobile />,
    'deel-mastercard-canceled': <DeelCardCanceled />,
    'deel-mastercard-canceled-mobile': <DeelCardCanceledMobile />,
    'deel-visa-physical': <DeelVisaCardPhysical />,
    'deel-visa-virtual': <DeelVisaCardVirtual />,
    'deel-visa-physical-frozen': <DeelVisaCardPhysicalFrozen />,
    'deel-visa-virtual-frozen': <DeelVisaCardVirtualFrozen />,
    'deel-visa-canceled': <DeelVisaCardCanceled />,
    'deel-visa-canceled-mobile': <DeelVisaCardCanceledMobile />,
    'freeze-card': <InfoDefaultAlert />,
    'unfreeze-card': <InfoDefaultAlert />,
    'deel-mastercard-miniature-physical': <DeelCardMiniaturePhysical />,
    'deel-mastercard-miniature-virtual': <DeelCardMiniatureVirtual />,
    'deel-mastercard-miniature-canceled': <DeelCardMiniatureCanceled />,
    'deel-mastercard-miniature-physical-frozen': <DeelCardMiniaturePhysicalFrozen />,
    'deel-mastercard-miniature-virtual-frozen': <DeelCardMiniatureVirtualFrozen />,
    'deel-visa-miniature-physical': <DeelVisaCardMiniaturePhysical />,
    'deel-visa-miniature-virtual': <DeelVisaCardMiniatureVirtual />,
    'deel-visa-miniature-canceled': <DeelVisaCardMiniatureCanceled />,
    'deel-visa-miniature-physical-frozen': <DeelVisaCardMiniaturePhysicalFrozen />,
    'deel-visa-miniature-virtual-frozen': <DeelVisaCardMiniatureVirtualFrozen />,
    'deel-2023-visa-miniature-virtual': <DeelCard2023VisaMiniatureVirtual />,
    'deel-2023-visa-miniature-physical': <DeelCard2023VisaMiniatureVirtual />,
    'deel-2023-visa-miniature-virtual-frozen': <DeelCard2023VisaMiniatureVirtualFrozen />,
    'deel-2023-visa-miniature-physical-frozen': <DeelCard2023VisaMiniatureVirtualFrozen />,
    'deel-2023-visa-miniature-canceled': <DeelCard2023VisaMiniatureCanceled />,
    'deel-2023-visa-physical': <DeelCard2023VisaVirtual />,
    'deel-2023-visa-virtual': <DeelCard2023VisaVirtual />,
    'deel-2023-visa-physical-frozen': <DeelCard2023VisaVirtualFrozen />,
    'deel-2023-visa-virtual-frozen': <DeelCard2023VisaVirtualFrozen />,
    'deel-2023-visa-canceled': <DeelCard2023VisaCanceled />,
    'deel-2023-visa-physical-mobile': <DeelCard2023VisaVirtualMobile />,
    'deel-2023-visa-virtual-mobile': <DeelCard2023VisaVirtualMobile />,
    'deel-2023-visa-virtual-frozen-mobile': <DeelCard2023VisaVirtualFrozenMobile />,
    'deel-2023-visa-physical-frozen-mobile': <DeelCard2023VisaVirtualFrozenMobile />,
    'deel-2023-visa-canceled-mobile': <DeelCard2023VisaVirtualCanceledMobile />,
    'deel-2024-visa-awx': <DeelCard2024VisaAwx />,
    'deel-2024-visa-awx-mobile': <DeelCard2024VisaAwxMobile />,
    'deel-2024-visa-awx-frozen': <DeelCard2024VisaAwxFrozen />,
    'deel-2024-visa-awx-mobile-frozen': <DeelCard2024VisaAwxFrozenMobile />,
    'deel-2024-visa-awx-cancelled': <DeelCard2024VisaAwxCancelled />,
    'deel-2024-visa-awx-mobile-cancelled': <DeelCard2024VisaAwxCancelledMobile />,
    'deel-2024-mc-awx': <DeelCard2024MasterCardAwx />,
    'deel-2024-mc-awx-mobile': <DeelCard2024MasterCardAwxMobile />,
    'deel-2024-mc-awx-frozen': <DeelCard2024MasterCardAwxFrozen />,
    'deel-2024-mc-awx-mobile-frozen': <DeelCard2024MasterCardAwxFrozenMobile />,
    'deel-2024-mc-awx-cancelled': <DeelCard2024MasterCardAwxCancelled />,
    'deel-2024-mc-awx-mobile-cancelled': <DeelCard2024MasterCardAwxCancelledMobile />,
    'deel-2024-visa-nium-physical': <DeelCard2024VisaNiumPhysical />,
    'deel-2024-visa-nium-physical-mobile': <DeelCard2024VisaNiumPhysicalMobile />,
    'deel-2024-visa-nium-physical-frozen': <DeelCard2024VisaNiumPhysicalFrozen />,
    'deel-2024-visa-nium-physical-mobile-frozen': <DeelCard2024VisaNiumPhysicalFrozenMobile />,
    'deel-2024-visa-nium-physical-cancelled': <DeelCard2024VisaNiumPhysicalCancelled />,
    'deel-2024-visa-nium-physical-mobile-cancelled': <DeelCard2024VisaNiumPhysicalCancelledMobile />,
    'deel-2024-mc-nium-physical': <DeelCard2024MasterCardNiumPhysical />,
    'deel-2024-mc-nium-physical-mobile': <DeelCard2024MasterCardNiumPhysicalMobile />,
    'deel-2024-mc-nium-physical-frozen': <DeelCard2024MasterCardNiumPhysicalFrozen />,
    'deel-2024-mc-nium-physical-mobile-frozen': <DeelCard2024MasterCardNiumPhysicalFrozenMobile />,
    'deel-2024-mc-nium-physical-cancelled': <DeelCard2024MasterCardNiumPhysicalCancelled />,
    'deel-2024-mc-nium-physical-mobile-cancelled': <DeelCard2024MasterCardNiumPhysicalCancelledMobile />,
    'deel-2024-visa-nium-virtual': <DeelCard2024VisaNiumVirtual />,
    'deel-2024-visa-nium-virtual-mobile': <DeelCard2024VisaNiumVirtualMobile />,
    'deel-2024-visa-nium-virtual-frozen': <DeelCard2024VisaNiumVirtualFrozen />,
    'deel-2024-visa-nium-virtual-mobile-frozen': <DeelCard2024VisaNiumVirtualFrozenMobile />,
    'deel-2024-visa-nium-virtual-cancelled': <DeelCard2024VisaNiumVirtualCancelled />,
    'deel-2024-visa-nium-virtual-mobile-cancelled': <DeelCard2024VisaNiumVirtualCancelledMobile />,
    'deel-2024-mc-nium-virtual': <DeelCard2024MasterCardNiumVirtual />,
    'deel-2024-mc-nium-virtual-mobile': <DeelCard2024MasterCardNiumVirtualMobile />,
    'deel-2024-mc-nium-virtual-frozen': <DeelCard2024MasterCardNiumVirtualFrozen />,
    'deel-2024-mc-nium-virtual-mobile-frozen': <DeelCard2024MasterCardNiumVirtualFrozenMobile />,
    'deel-2024-mc-nium-virtual-cancelled': <DeelCard2024MasterCardNiumVirtualCancelled />,
    'deel-2024-mc-nium-virtual-mobile-cancelled': <DeelCard2024MasterCardNiumVirtualCancelledMobile />,
    'deel-2024-mastercard-virtual-miniature-canceled': <DeelCard2024McVirtualMiniatureCanceled />,
    'deel-2024-mastercard-physical-miniature-canceled': <DeelCard2024McPhysicalMiniatureCanceled />,
    'deel-2024-visa-virtual-miniature-canceled': <DeelCard2024VisaVirtualMiniatureCanceled />,
    'deel-2024-visa-physical-miniature-canceled': <DeelCard2024VisaPhysicalMiniatureCanceled />,
    ['fail-blob']: <Illustration illustration="error" />,
    ['fail-small-blob']: <Illustration illustration="error" />,
    ['fixed-contract-blob']: <Illustration illustration="contract" />,
    ['handshake-blob']: <Illustration illustration="info" />,
    ['headset-blob']: <Illustration illustration="no-content" />,
    ['identity-blob']: <Illustration illustration="no-content" />,
    ['individual-blob']: <Illustration illustration="info" />,
    ['invite-sent-blob']: <Illustration illustration="message-sent" />,
    ['invite-sent-blue-blob']: <Illustration illustration="message-sent" />,
    ['invite-sent-new-blob']: <InviteSentNewBlobGraphic />,
    ['invoice-blob']: <Illustration illustration="contract" />,
    ['invoice-blue-blob']: <Illustration illustration="contract" />,
    lightning: <Illustration illustration="info" />,
    ['lock-blob']: <Illustration illustration="lock" />,
    ['lock-danger-blob']: <Illustration illustration="lock-error" />,
    ['milestone-contract-blob']: <Illustration illustration="contract" />,
    ['no-team-blob']: <Illustration illustration="error" />,
    ['pay-as-you-go-contract-blob']: <Illustration illustration="contract" />,
    ['payment-fail-blob']: <Illustration illustration="payment-error" />,
    ['payment-methods-blob']: <Illustration illustration="payment" />,
    ['payment-success-blob']: <Illustration illustration="payment-success" />,
    ['payment-success-v2-blob']: <Illustration illustration="payment-success" />,
    ['pending-blob']: <Illustration illustration="info" />,
    ['processing-blob']: <Illustration illustration="time" />,
    ['redirect-blob']: <Illustration illustration="info" />,
    ['satellite-blob']: <Illustration illustration="info" />,
    ['spilled-milk-blob']: <Illustration illustration="error" />,
    ['success-blob']: <Illustration illustration="success" />,
    ['no-content-blob']: <NoContentBlobGraphic />,
    ['one-off-popup']: <Illustration illustration="info" />,
    ['taxes-blob']: <Illustration illustration="contract" />,
    ['terminate-contract-blob']: <Illustration illustration="error" />,
    ['terminate-worker-blob']: <Illustration illustration="contract-error" />,
    ['thumb-print']: <ThumbPrintBlobGraphic />,
    ['withdraw-fail-blob']: <Illustration illustration="withdrawal-error" />,
    ['withdraw-success-blob']: <Illustration illustration="withdrawal-success" />,
    ['confirmation-email-blob']: <Illustration illustration="email-success" />,
    ['email-sent-blob']: <Illustration illustration="email" />,
    ['subdomain-blob']: <Illustration illustration="info" />,
    ['equipment-blob']: <Illustration illustration="laptop" />,
    ['info-blob']: <Illustration illustration="info" />,
    ['documents-blob']: <Illustration illustration="contract" />,
    ['empty-contract-blob']: <Illustration illustration="contract" />,
    ['password']: <Illustration illustration="info" />,
    ['background-check-blob']: <Illustration illustration="contract" />,
    ['background-check-regular-blob']: <Illustration illustration="contract" />,
    ['attention-blob']: <Illustration illustration="info" />,
    ['countries-blob']: <Illustration illustration="info" />,
    ['full-time-employee-blob']: <Illustration illustration="info" />,
    ['feedback-blob']: <Illustration illustration="info" />,
    ['warning-blob']: <Illustration illustration="warning" />,
    ['search-default']: <Illustration illustration="search" />,
    ['warning-red-blob']: <Illustration illustration="error" />,
    'upcoming-payment': <Illustration illustration="withdrawal" />,
    ['ending-contract']: <Illustration illustration="contract" />,
    ['ending-contract-small']: <Illustration illustration="contract" />,
    ['ending-contract-regular']: <Illustration illustration="contract" />,
    ['csm-call']: <Illustration illustration="no-content" />,
    ['contract-ended']: <Illustration illustration="contract" />,
    ['contract-ending']: <ContractEnding />,
    ['contract-ended-small']: <Illustration illustration="contract" />,
    ['connect-integrations']: <Illustration illustration="browser-plugin" />,
    ['connect-integrations-error']: <Illustration illustration="error" />,
    ['empty-state-bar-chart']: <Illustration illustration="no-content" />,
    ['team-blob']: <Illustration illustration="no-content" />,
    ['team-alt-blob']: <Illustration illustration="no-content" />,
    ['cannon-blob-blue']: <Illustration illustration="no-content" />,
    ['auth-blob']: <Illustration illustration="shield" />,
    ['national-id-blob']: <Illustration illustration="visa" />,
    ['automate']: <Illustration illustration="browser-plugin" />,
    ['insights-conversation-bg']: <InsightsConversationBG />,
    ['milestone-reached']: <Illustration illustration="success" />,
    ['eor-resignation-request-denied']: <Illustration illustration="shield" />,
    ['protection-shield']: <Illustration illustration="shield" />,
    'documents-logo': <Illustration illustration="contract" />,
    ['edit-with-pen']: <Illustration illustration="info" />,
    ['calendar-date-start']: <Illustration illustration="calendar" />,
    ['additional-details']: <Illustration illustration="no-content" />,
    ['bank-details']: <Illustration illustration="no-content" />,
    ['employee-handbook']: <Illustration illustration="company" />,
    ['about-benefits']: <Illustration illustration="no-content" />,
    ['edit-status']: <Illustration illustration="info" />,
    ['announcement']: <Illustration illustration="info" />,
    ['trash']: <Illustration illustration="delete" />,
    ['empty-sheet']: <Illustration illustration="contract" />,
    ['anniversary']: <Illustration illustration="calendar" />,
    'peo-solutions-banner': <PeoSolutionsBanner />,
    'edit-feedback': <Illustration illustration="info" />,
    'eor-deel-insight': <EorDeelInsightGraphic />,
    'mobility-visa-info-blob': <Illustration illustration="visa" />,
    'contractor-profile': <Illustration illustration="info" />,
    'end-contract-set-date': <Illustration illustration="contract" />,
    'contract-success': <Illustration illustration="contract-success" />,
    'contract-success-2': <Illustration illustration="contract-success" />,
    'time-default': <TimeDefaultGraphic />,
    'journey-default-thumbnail': <JourneyDefaultThumbnail />,
    'signing-editing': <Illustration illustration="info" />,
    ['add-benefit']: <Illustration illustration="no-content" />,
    'new-design-success-popup': <NewSuccessGraphic />,
    'new-design-warning-popup': <NewWarningGraphic />,
    ['no-content']: <NoContentGraphic />,
    ['delete-alt']: <DeleteAlt />,
    ['automation-alert-banner']: <AutomationAlertBanner />,
    'n-box-empty-state-blob': <NBoxEmptyStateBlob />,
    'heat-map-empty-state-blob': <HeatMapEmptyStateBlob />,
  },
  common: {
    dpaContract: <Illustration illustration="contract" />,
    star: <StarGraphic />,
    ['account-settings']: <Illustration illustration="info" />,
    ['amend-contract']: <Illustration illustration="contract" />,
    ['amend-contract-new']: <Illustration illustration="contract" />,
    briefcase: <Illustration illustration="info" />,
    ['briefcase-blue']: <BriefcaseBlueGraphic />,
    cash: <Illustration illustration="payment" />,
    company: <Illustration illustration="company" />,
    contract: <Illustration illustration="contract" />,
    contractDefault: <Illustration illustration="contract" />,
    ['contract-new']: <Illustration illustration="contract" />,
    ['contract-title']: <Illustration illustration="contract" />,
    delete: <Illustration illustration="delete" />,
    document: <Illustration illustration="contract" />,
    gift: <Illustration illustration="info" />,
    handshake: <Illustration illustration="no-content" />,
    globe: <GlobeGraphic />,
    individual: <Illustration illustration="no-content" />,
    megaphone: <Illustration illustration="info" />,
    shield: <Illustration illustration="shield" />,
    ['terminate-contract']: <Illustration illustration="contract-error" />,
    ['time-off']: <Illustration illustration="calendar" />,
    vault: <Illustration illustration="info" />,
    ['verify-member']: <Illustration illustration="success" />,
    ['verify-shield']: <Illustration illustration="shield-success" />,
    ['totp-shield']: <Illustration illustration="authenticator" />,
    ['verify-team']: <Illustration illustration="success" />,
    ['signup-side-client']: <SignupSideClient />,
    ['signup-side-contractor']: <SignupSideContractor />,
    ['signup-side-employee-info']: <SignupSideEmployeeInfo />,
    ['signup-side-client-rebrand']: <img src={SignupSideClient} alt="Client Signup" />,
    ['signup-side-contractor-rebrand']: <img src={SignupSideContractor} alt="Contractor Signup" />,
    ['signup-side-employee-info-rebrand']: <img src={SignupSideEmployeeInfo} alt="Employee Signup" />,

    ['calculator-circle']: <CalculatorCircle />,
    ['piggy-bank-circle']: <PiggyBankCircle />,
    ['cash-circle']: <CashCircle />,
    ['subdomain-small']: <SubdomainSmall />,
    laptop: <Illustration illustration="laptop" />,
    palette: <Illustration illustration="info" />,
    ['card-graphic']: <CardGraphic />,
    ['coin-rain']: <Illustration illustration="info" />,
    ['click-money']: <ClickMoney />,
    alarm: <Illustration illustration="time" />,
    ['tax-1099']: <Illustration illustration="info" />,
    ['doc-approved']: <Illustration illustration="contract-success" />,
    ['deel-envelope']: <DeelEnvelope />,
    ['cash-reward']: <CashReward />,
    ['sign-contract']: <SignContract />,
    ['call-icon']: <CallIcon />,
    ['gift-icon']: <GiftIcon />,
    ['invite-icon']: <InviteIcon />,
    chat: <Chat />,
    invoice: <Illustration illustration="contract" />,
    equipment: <Equipment />,
    ['deel-shield']: <Illustration illustration="shield" />,
    ['deel-shield-assessment']: <Illustration illustration="shield" />,
    ['deel-shield-failed-assessment']: <Illustration illustration="shield-error" />,
    ['deel-shield-passed-assessment']: <Illustration illustration="shield-success" />,
    ['deel-shield-sparkle']: <Illustration illustration="shield" />,
    ['deel-shield-sparkle-error']: <Illustration illustration="shield-error" />,
    ['deel-shield-sparkle-success']: <Illustration illustration="shield-success" />,
    ['deel-premium-sparkle']: <Illustration illustration="premium" />,
    ['shield-upselling']: <ShieldUpselling />,
    ['shield-upselling-banner']: <ShieldUpsellingBanner />,
    ['lightning-new']: <Illustration illustration="info" />,
    ['contact']: <Illustration illustration="email" />,
    ['help-center']: <HelpCenter />,
    ['blog']: <Blog />,
    ['faq']: <Faq />,
    ['deel-premium']: <Illustration illustration="premium" />,
    ['deel-hr']: <DeelHr />,
    ['deel-hr-darker-blue']: <Illustration illustration="info" />,
    ['general-letter']: <Illustration illustration="contract" />,
    ['add-member']: <Illustration illustration="info" />,
    puzzle: <PuzzleGraphic />,
    ['mail-delivering']: <MailDeliveringGraphic />,
    ['no-search-results']: <NoSearchResultsGrahic />,
    ['tfa-device']: <TFADevice />,
    ['sms-verification']: <Illustration illustration="sms" />,
    ['local-advice']: <LocalAdvice />,
    ['question-answer']: <QuestionAnswer />,
    ['select-cell']: <Illustration illustration="info" />,
    ['generic-warning']: <Illustration illustration="warning" />,
  },
  large: {
    'deel-card-info': <DeelCardInfo />,
    'deel-card-new': <DeelCardNew />,
    'deel-card-get': <DeelCardGet />,
    'deel-card-get-small': <DeelCardGetSmall />,
    'deel-virtual-card-img': <DeelVirtualCardImg />,
    'deel-physical-card-disabled-img': <DeelPhysicalCardDisabledImg />,
    'deel-physical-card-img': <DeelPhysicalCardImg />,
    ['national-id']: <Illustration illustration="visa" />,
    passport: <Illustration illustration="visa" />,
    phone: <Illustration illustration="info" />,
    webcam: <Illustration illustration="info" />,
    ['gov-letter']: <Illustration illustration="contract" />,
    ['utility-bill']: <Illustration illustration="contract" />,
    ['driving-license']: <Illustration illustration="visa" />,
    ['selfie-id']: <Illustration illustration="add-photo" />,
    ['bank-statement']: <Illustration illustration="info" />,
    ['bank-statement-or-bill']: <Illustration illustration="info" />,
    ['employer-letter']: <Illustration illustration="message-sent" />,
    perks: <Perks />,
    taxes: <Illustration illustration="info" />,
    taxes2: <Illustration illustration="info" />,
    ['add-integration']: <Illustration illustration="browser-plugin" />,
    reports: <img src={Reports} alt={i18next.t('common.misc.reports')} />,
    ['expense-pdf-mock']: <img src={ExpensePdfMock} alt="Expense pdf" />,
    ['piggy-bank']: <PiggyBank />,
    ['documents']: <Illustration illustration="contract" />,

    // To decrease bundle size we load big.svg?react file (>~30KB) as image but not import.
    // Important note it is not possible to change color for these.svg?react files.
    ['referrals-email-preview']: (
      <img
        src="https://s3.amazonaws.com/media.letsdeel.com/app/images/graphics/large/referrals-email-preview.png"
        alt="referral invite image"
      />
    ),
    ['top-nav-banner-media']: <img src={TopNavBannerMedia} alt="Top nav banner media" />,
    'credit-card': <CreditCard />,
    'deel-products-agreement': <DeelProductsAgreement />,
  },
  logos: LOGOS,
  other: {
    andelaAlgeria: <AndelaAlgeria />,
    andelaEgypt: <AndelaEgypt />,
    andelaKenya: <AndelaKenya />,
    andelaMorocco: <AndelaMorocco />,
    andelaNigeria: <AndelaNigeria />,
    andelaSouthAfrica: <AndelaSouthAfrica />,
    amazonGiftCardVariant: <AmazonGiftCardVariant />,
    amazonGiftCardPurpleVariant: <AmazonGiftCardPurpleVariant />,
    visaSupportBannerMedia: <VisaSupportBannerMedia />,
    amazonGiftCardPinkVariant: <AmazonGiftCardPinkVariant />,
    globalPayrollBannerMedia: <GlobalPayrollBannerMedia />,
    ['not-found']: <NotFound />,

    ['tax-advice-upsell-banner']: (
      <img
        src="/img/graphics/tax-advice-upsell-banner.png"
        alt="Tax Advice"
        width="100%"
        height={304}
        style={{ objectFit: 'cover' }}
      />
    ),

    ['amazon-claim-gift-card']: (
      <img
        src="https://s3.amazonaws.com/media.letsdeel.com/app/images/graphics/other/amazon-claim-gift-card.svg?react"
        alt="success"
        width={200}
        height={136}
      />
    ),
    ['deel-success']: (
      <img
        src="https://s3.amazonaws.com/media.letsdeel.com/app/images/graphics/other/deel-envelope-success.svg?react"
        alt="success"
        width={200}
        height={150}
      />
    ),
    ['amazon-card-success']: (
      <img
        src="https://s3.amazonaws.com/media.letsdeel.com/app/images/graphics/other/amazon-card-success.svg?react"
        alt="success"
        width={200}
        height={136}
      />
    ),
    ['chart-bar-error']: <Illustration illustration="error" />,
    rootsBanner: <RootsBanner />,
    hiringToolkit: <HiringToolkit />,
    workdayBanner: <WorkdayBanner />,
    accountingPluginsBanner: <AccountingPluginsBanner />,
    googleWorkspaceBanner: <GoogleWorkspaceBanner />,
    integrationsBanner: <IntegrationsBanner />,
    perksBanner: <PerksBanner />,
    appsAndPerksBanner: <AppsAndPerksBanner />,
    appsAndIntegrationsBanner: <AppsAndIntegrationsBanner />,
    newAppStoreGraphic: <NewAppStoreGraphic />,
    bambooHRBanner: <BambooHRBanner />,
    hibobBanner: <HibobBanner />,
    ashbyBanner: <AshbyBanner />,
    greenhouseBanner: <GreenhouseBanner />,
    leverBanner: <LeverBanner />,
    hrisPaywallBanner: <HrisPaywallBanner />,
    hrisRootsPaywallBanner: <HrisRootsPaywallBanner />,
    slackRootsBanner: <SlackRootsBanner />,
    noUpcomingPayments: <Illustration illustration="no-content" />,
    orgChartBanner: <OrgChartBanner />,
    webhooksBanner: <DeelWebhooks />,
    ['global-payroll-marketing-banner-old']: <GlobalPayrollMarketingBannerOld />,
    ['upselling-value-experts']: <UpsellingValueExperts />,
    ['upselling-value-accounting']: <UpsellingValueAccounting />,
    ['upselling-value-global-support']: <UpsellingValueGlobalSupport />,
    ['upselling-value-employee']: <UpsellingValueEmployee />,
    ['upselling-value-automate']: <UpsellingValueAutomate />,
    ['upselling-value-reports']: <UpsellingValueReports />,
    ['employment-verification-letter']: <EmploymentVerificationLetter />,
    verificationLetterTopLeft: <VerificationLetterTopLeft />,
    verificationLetterTop: <VerificationLetterTop />,
    VerificationLetterBottomRight: <VerificationLetterBottomRight />,
    VerificationLetterRight: <VerificationLetterRight />,
    communityGift: <CommunityGift />,
    internetUsers: <InternetUsers />,
    openedLaptop: <OpenedLaptop />,
    benefitsQLEBanner: <BenefitsQLEBanner />,
    ['benefits-icon']: <Illustration illustration="no-content" />,
    workableBanner: <WorkableBanner />,
  },
  ['compliance-docs-vertical-banner']: <ComplianceDocsVerticalBanner />,
  ['verified-accounts-vertical-banner']: <VerifiedAccountsVerticalBanner />,
} as const;
