 
import { subYears } from 'date-fns';
import { object, string, number, array, date, lazy } from 'yup';
import LookupStore from '@/stores/LookupStore';
import UserStore from '@/stores/UserStore';
import { isValidNumber } from '@/utils/phone';
import { DateFormat, createDate } from '@letsdeel/ui';
import { formatDateWithoutTimezoneShift } from '@/utils/gp';
import { t } from 'i18next';
import { validateABN } from '@/utils/validateAbn';

const shareHolderPercent = { min: 25, max: 100 };

const hasProvince = (country) => LookupStore.countries[country]?.provinces && country !== 'JP';

const customProvince = (field, fieldName = 'Province') => {
  return string().when(field, {
    is: (val) => hasProvince(val),
    then: string().required(`${fieldName} is required`),
    otherwise: string().nullable().optional(),
  });
};

const customFieldValueValidation = string().trim().max(100, 'Custom field value cannot have more than 100 characters.');
const customFieldsValuesValidation = array().of(
  object().shape({
    id: string(),
    value: customFieldValueValidation,
  })
);

const haveUniqueCustomFieldValues = () => {
  return customFieldsValuesValidation
    .test('unique', 'Custom field values have to be unique', (list) => {
      return list.length === new Set(list.map((a) => a.value?.toLowerCase?.())).size;
    })
    .min(1, 'Custom field list requires at least one value.');
};

export const REGEX_PATTERNS = {
  addressPatterns: {
    pattern: /^[a-zA-Z0-9\s\-.,#]+$/,
    err: 'Please, use latin letters',
  },
  companyName: {
    pattern: /^[A-Za-z()À-ÖØ-öø-ÿ0-9: & züôçčéèêΓΙΝΥΒΕΣΜΚΟł',.-]*$/,
    err: 'Please, use latin letters',
  },
  entityName: {
    pattern: /^[A-Za-z()À-ÖØ-öø-ÿ0-9: & züôçčéèêΓΙΝΥΒΕΣΜΚΟł',.!-]*$/,
    err: 'Please, use latin letters',
  },
  name: {
    pattern: /^[A-Za-zÀ-ÖØ-öø-ÿ0-9 züôçčéèêΓΙΝΥΒΕΣΜΚΟ'’,.-]*$/,
    err: 'Please, use latin letters',
  },
  noSpecial: {
    pattern: /^[A-Za-z0-9 #',.&/züôçéèêΓΙΝΥΒΕΣΜΚΟ',-]*$/,
    err: 'No special characters allowed',
  },
  bic: {
    pattern: /^[a-zA-Z]{6}(([a-zA-Z0-9]{2})|([a-zA-Z0-9]{5}))$/,
    err: 'BIC format is invalid',
  },
  mobile: {
    pattern: /^(([1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
    err: 'Mobile number is invalid',
  },
  allButSpecial: {
    pattern: /^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]*$/,
    err: 'No special characters allowed',
  },
  stripeFullLegalName: {
    pattern: /^[A-Za-zÀ-ÖØ-öø-ÿ0-9: “”' züôçčéèêΓΙΝΥΒΕΣΜΚΟł',.-]*$/,
    err: 'No special characters allowed',
  },
  teamName: {
    pattern: /^[A-Za-z()À-ÖØ-öø-ÿ0-9:@+_ & züôçčéèêΓΙΝΥΒΕΣΜΚΟł',.!-]*$/,
    err: 'Please, use latin letters',
  },
  onlyEnglishChars: {
    pattern: /^[a-zA-Z0-9\s-]+$/,
    err: 'Please enter your address using English characters only. The following symbols are not supported: @ # ! $ % , ^ &* : ; ’ “ <  > =',
  },
  zipDE: {
    pattern: /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/,
    err: 'Zip code is required',
  },
};

export const COMPANY_NAME_VALIDATION = string()
  .matches(REGEX_PATTERNS.companyName.pattern, REGEX_PATTERNS.companyName.err)
  .trim()
  .max(255, 'Must be less than 255 characters');

const ENTITY_NAME_VALIDATION = string()
  .matches(REGEX_PATTERNS.entityName.pattern, REGEX_PATTERNS.entityName.err)
  .trim()
  .max(255, 'Must be less than 255 characters');

export const DATE_PICKER_MIN_DATE = createDate('1900-01-01');
export const DATE_PICKER_MAX_DATE = createDate('2099-12-31');

export const LEGAL_MIN_AGE = 18;
export const USER_MIN_AGE = LEGAL_MIN_AGE;
export const USER_MAX_AGE = 100;
export const CONTRACTOR_MIN_AGE = 16;
export const CONTRACTOR_MAX_AGE = USER_MAX_AGE;

export const BIRTHDATE_INPUT_BOUNDARIES = {
  min: subYears(new Date(), USER_MAX_AGE),
  max: subYears(new Date(), USER_MIN_AGE),
};

export const CONTRACTOR_BIRTHDATE_VALIDATION = date()
  .min(BIRTHDATE_INPUT_BOUNDARIES.min, 'Invalid date of birth')
  .max(subYears(new Date(), CONTRACTOR_MIN_AGE), 'You must be at least 16 to use Deel');

const BIRTHDATE_VALIDATION = date()
  .min(BIRTHDATE_INPUT_BOUNDARIES.min, 'Invalid date of birth')
  .max(BIRTHDATE_INPUT_BOUNDARIES.max, 'You must be at least 18 to use Deel');

export const testMaxDecimals = number().test(
  'maxDigitsAfterDecimal',
  t ? t('common.fieldValidation.numberMustBeLessThan') : 'This field must have 2 digits after decimal or less',
  (number) => /^\d+(\.\d{1,2})?$/.test(number)
);

export const VALIDATE = {
  birthDate: BIRTHDATE_VALIDATION.typeError('Date of birth is required').required('Date of birth is required'),
  birthDateNotRequired: BIRTHDATE_VALIDATION,
  contractorBirthDate:
    CONTRACTOR_BIRTHDATE_VALIDATION.typeError('Date of birth is required').required('Date of birth is required'),
  effectiveDate: date()
    .min(subYears(new Date(), 1), 'Cannot select past dates.')
    .typeError('Effective date is required')
    .required('Effective date is required'),
  effectiveDateIncludingPastDates: (min = DATE_PICKER_MIN_DATE, minMessage = undefined) =>
    date()
      .test('minDate', minMessage, (value) => {
        const formattedValue = formatDateWithoutTimezoneShift(value, DateFormat.DATE_ONLY).formatted;
        const formattedMin = formatDateWithoutTimezoneShift(min, DateFormat.DATE_ONLY).formatted;
        if (value && min) {
          return formattedValue >= formattedMin;
        }
        return true;
      })
      .typeError('Effective date is required')
      .required('Effective date is required'),
  contractStartDate: date()
    .required('Invalid date')
    .min(DATE_PICKER_MIN_DATE, 'Invalid date')
    .max(DATE_PICKER_MAX_DATE, 'Invalid date')
    .typeError('Invalid date'),
  companyName: COMPANY_NAME_VALIDATION.required('Company name is required'),
  legalEntityName: ENTITY_NAME_VALIDATION.required('Legal entity name is required'),
  organizationName: string()
    .trim()
    .max(255, 'Must be less than 255 characters')
    .required('Organization name is required'),
  country: string().required('Country is required'),
  clientEntityCountry: string()
    .required('Country is required')
    .test('entityCountry', 'Country not supported for entities', (formValue) => {
      return UserStore.isClient ? LookupStore.countryLists.entity.some(({ value }) => value === formValue) : true;
    }),
  email: string()
    .trim()
    .email('Invalid email address')
    .required('Email is required')
    /**
     * @description
     * Restict Email inputs to 7-bit ASCII characters only.
     **/
    .test('sevenBit', 'Invalid characters in email address', (formValue) =>
      formValue ? formValue.match(/^[\x20-\x7F]+$/) : true
    )
    .max(120, 'Email must be shorter'),
  entityType: string().required('Company type is required'),
  name: string()
    .matches(REGEX_PATTERNS.allButSpecial.pattern, REGEX_PATTERNS.allButSpecial.err)
    .trim()
    .min(4, 'Must be at least 4 characters')
    .max(255, 'Must be less than 255 characters')
    .required('Name is required'),
  contractName: string().trim().max(255, 'Must be less than 256 characters').required('Contract name is required'),
  jobTitle: string().trim().max(255, 'Must be less than 256 characters'),
  scopeOfWork: string().trim().max(1000, 'Must be less than 1001 characters').required('Scope of work is required'),
  stripeFullLegalName: string()
    .matches(REGEX_PATTERNS.stripeFullLegalName.pattern, REGEX_PATTERNS.stripeFullLegalName.err)
    .trim()
    .min(4, 'Must be at least 4 characters')
    .max(255, 'Must be less than 255 characters')
    .required('Name is required'),
  firstName: string()
    .label(t('common.fields.legalFirstName'))
    .matches(REGEX_PATTERNS.allButSpecial.pattern, REGEX_PATTERNS.allButSpecial.err)
    .trim()
    .min(1, 'Must be at least 1 character')
    .max(50, 'Must be less than 50 characters')
    .required('Legal first name is required'),
  nationality: string()
    .matches(REGEX_PATTERNS.allButSpecial.pattern, REGEX_PATTERNS.allButSpecial.err)
    .min(2, 'Must be at least 2 characters')
    .required('Nationality is required'),
  middleName: string()
    .trim()
    .matches(REGEX_PATTERNS.allButSpecial.pattern, REGEX_PATTERNS.allButSpecial.err)
    .max(50, 'Must be less than 50 characters'),
  lastName: string()
    .label(t('common.fields.legalLastName'))
    .matches(REGEX_PATTERNS.allButSpecial.pattern, REGEX_PATTERNS.allButSpecial.err)
    .trim()
    .min(1, 'Must be at least 1 character')
    .max(100, 'Must be less than 100 characters')
    .required('Legal last name is required'),
  preferredName: string()
    .trim()
    .matches(REGEX_PATTERNS.allButSpecial.pattern, REGEX_PATTERNS.allButSpecial.err)
    .max(50, 'Must be less than 50 characters'),
  preferredFirstName: string()
    .trim()
    .matches(REGEX_PATTERNS.allButSpecial.pattern, REGEX_PATTERNS.allButSpecial.err)
    .min(1, 'Must be at least 1 character')
    .max(100, 'Must be less than 100 characters'),
  preferredLastName: string()
    .trim()
    .matches(REGEX_PATTERNS.allButSpecial.pattern, REGEX_PATTERNS.allButSpecial.err)
    .min(1, 'Must be at least 1 character')
    .max(100, 'Must be less than 100 characters'),
  password: string().min(6, 'Invalid password').required('Password is required'),
  shareholderPercent: number(`Must be a number between ${shareHolderPercent.min} and ${shareHolderPercent.max}`)
    .min(shareHolderPercent.min, `Must be a number between ${shareHolderPercent.min} and ${shareHolderPercent.max}`)
    .max(shareHolderPercent.max, `Must be a number between ${shareHolderPercent.min} and ${shareHolderPercent.max}`)
    .required('Percentage is required')
    .typeError(`Must be a number between ${shareHolderPercent.min} and ${shareHolderPercent.max}`),
  phoneNumber: string()
    .min(5, 'Phone number is too short')
    .required('Phone is required')
    .typeError('Numbers only please'),
  mobile: string()
    .matches(REGEX_PATTERNS.mobile.pattern, REGEX_PATTERNS.mobile.err)
    .min(2, 'Must be at least 2 characters')
    .required('Mobile phone number is required'),
  documentType: string().required('Document type is required'),
  customProvince,
  province: customProvince('country', 'Province'),
  state: customProvince('country', 'State').max(20, 'Must be less than 21 characters'),
  phone: lazy((value) => {
    switch (typeof value) {
      case 'object':
        return object({ number: VALIDATE.phoneNumber, code: number() });
      case 'string':
      default:
        return string().test('valid-number', 'Invalid phone number', (value) => {
          return isValidNumber(value);
        });
    }
  }),
  conditionalPhone: lazy((value) => {
    if (value) {
      return string().test('valid-number', 'Invalid phone number', (value) => {
        return isValidNumber(value);
      });
    }

    return string().nullable().notRequired();
  }),
  registrationNumber: string()
    .trim()
    .matches(REGEX_PATTERNS.noSpecial.pattern, REGEX_PATTERNS.noSpecial.err)
    .required('Registration number is required')
    .max(255, 'Must be less than 255 characters'),
  city: string()
    .matches(REGEX_PATTERNS.allButSpecial.pattern, REGEX_PATTERNS.allButSpecial.err)
    .min(3, 'Must be at least 3 characters')
    .max(70, 'Must be less than 70 characters')
    .required('City is required'),
  street: string()
    .trim()
    .min(3, 'Must be at least 3 characters')
    .max(70, 'Must be less than 70 characters')
    .required('Street is required'),
  teamName: string()
    .matches(REGEX_PATTERNS.teamName.pattern, REGEX_PATTERNS.teamName.err)
    .trim()
    .min(3, 'Must be at least 3 characters')
    .max(48, 'Can be max 48 characters')
    .required('Group name is required'),

  timezone: string().typeError('Timezone is required').required('Timezone is required'),
  zip: string()
    .trim()
    .matches(REGEX_PATTERNS.noSpecial.pattern, REGEX_PATTERNS.noSpecial.err)
    .test('zip-code', 'At least one alpha-numeric character is required', (value) => {
      return /^.*[a-zA-Z0-9]+.*$/.test(value);
    })
    .min(3, 'Must be at least 3 characters')
    .max(10, 'Must be less than 10 characters') //Iran is 10, Brazil is 9, Canada is 7, Iceland is 3
    .required('Zip is required'),
  zipDE: string()
    .trim()
    .matches(REGEX_PATTERNS.zipDE.pattern, REGEX_PATTERNS.zipDE.err)
    .length(5, 'Must be exactly 5 digits')
    .required('Zip is required'),
  customFieldValues: haveUniqueCustomFieldValues,
  customFieldsValues: customFieldsValuesValidation,
  sicNumber: string().trim().min(3, 'Must be at least 3 characters').max(255, 'Must be less than 255 characters'),
  abn: string()
    .required(t('onboarding.entity.abnRequiredField'))
    .test('abn', t('onboarding.entity.invalidAbn'), (value) => {
      return validateABN(value);
    }),
};
