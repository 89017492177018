import type { IPaymentMethod } from '@/utils/api/payments/mainV2';
import type { TeamMember } from '@/utils/api/team';

export interface ITeamsStore {
  loadingSchedule: boolean;
  loadTeams: () => void;
  getTeamSchedule: (id?: number | string) => any;
  getTeam: (id: number | string | null | undefined, fromCache?: boolean) => ITeamItem;
  findTeamInTeams: (id: number | string) => ITeamItem;
  updateTeams: (teams: ITeamItem[]) => void;
  teams: ITeamItem[];
  team: ITeamItem;

  // @todo: add correct types;
  mutate: any;
  setMutate: (value: any) => void;
}

export interface ITeamItem {
  permissionSets: string[];
  id: number;
  teamPublicId: string;
  name: string;
  picUrl: null;
  deelFeePercent: string;
  isAutoPayEnabled: boolean;
  autoPayForShield: boolean;
  autoPayForMilestone: boolean;
  generalLedgerAccount: null | string;
  country: null | string;
  role?: string | null;
  roleId?: number | null;
  approvesRequired: number | null;
  massApprove: MassApprove | null;
  legalCompanyName: null | string;
  saasFee: SaasFee | null;
  owner?: boolean;
  organization: Organization;
  invoiceReviewDays?: number;
  paymentMethods: IPaymentMethod[];
  activeContractsCount: string;
  isHrisApproveEnabled: boolean;
  contractsCount: number;
  members: TeamMember[];
  canBeDeleted: boolean;
  status?: string;
  archivedBy?: string;
  archivedAt?: string;
}

export enum MassApprove {
  Additional = 'ADDITIONAL',
  All = 'ALL',
}

export interface Organization {
  organizationPublicId: string;
  id: number;
  name: string;
  logoUrl: string | null;
}

export interface SaasFee {
  amount: string;
  type: string;
}
