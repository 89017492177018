import instance from '@/utils/api/instance';
import sortBy from 'lodash/sortBy';
import type { ApprovedStatus, DeclinedStatus } from '@/types/Contract';
import i18next from 'i18next';

export type HourlyReportType = {
  id: string | number;
};

export default {
  getList: async ({
    statuses = null,
    invoiceId = null,
    contractId,
    contractTypes = null,
    types = null,
    limit = 100,
    isSortEnabled = false,
    isLoadAllEnabled = false,
  }: any) => {
    const sendRequest = (offset = 0) =>
      instance.get(`hourly_reports${contractId ? `?contractId=${contractId}` : ''}`, {
        params: { statuses, contractTypes, types, limit, offset, invoiceId },
      });

    const { data } = await sendRequest();

    if (isLoadAllEnabled) {
      const promises = new Array(Math.round(data.count / limit))
        .fill(null)
        .map((item, idx) => sendRequest(limit * (idx + 1)));
      const results = await Promise.all(promises);
      results.forEach(({ data: { rows } }) => {
        data.rows = [...data.rows, ...rows];
      });
    }

    if (isSortEnabled) {
      data.rows = sortBy(data.rows, ['type', 'amount']).reverse();
    }
    return data;
  },

  getReport: async (id: HourlyReportType['id']) => {
    const { data } = await instance.get(`hourly_reports/${id}`);
    return data;
  },

  getAttachment: async ({ reportId }: { reportId: HourlyReportType['id'] }) => {
    const { data } = await instance.get(`hourly_reports/${reportId}/attachment`);
    return data;
  },

  reviewBatch: async (payload: ReviewBatch) => {
    const { data } = await instance.post(`hourly_reports/review_batch`, payload);
    return data;
  },

  delete: async ({
    reportId,
    reason = i18next.t('common.misc.removed'),
  }: {
    reportId: HourlyReportType['id'];
    reason?: string;
  }) => {
    const { data } = await instance.delete(`/hourly_reports/${reportId}`, { data: { reason } });
    return data;
  },

  save: async ({ id, report }: any, config?: any) => {
    const { data } = await instance.post(`/hourly_reports`, { ...report, contractId: id }, config);
    return data;
  },

  saveBulk: async (payload: { items: any[] }) => {
    const { data } = await instance.post(`/hourly_reports/bulk`, payload);
    return data;
  },

  deleteBulk: async (payload: { items: { id: string | number; removeRecurring?: boolean }[]; reason?: string }) => {
    const { data } = await instance.delete(`/hourly_reports/bulk`, { data: payload });
    return data;
  },

  update: async ({ reportId, payload }: { reportId: HourlyReportType['id']; payload: any }, config?: any) => {
    const { data } = await instance.patch(`/hourly_reports/${reportId}`, payload, config);
    return data;
  },
  recurringHourlyReports: {
    getReports: async () => {
      const { data } = await instance.get(`/recurring_hourly_reports`);
      return data;
    },
    delete: async (id: string) => {
      const { data } = await instance.delete(`recurring_hourly_reports/${id}`);
      return data;
    },
    patch: async (id: string, payload: { currency?: string | null; bonus?: number; description?: string }) => {
      const { data } = await instance.patch(`recurring_hourly_reports/${id}`, payload);
      return data;
    },
  },
  recurring: {
    get: async ({ id }: { id: string }) => {
      const { data } = await instance.get(`contracts/${id}/recurring_reports`);
      return data;
    },
    save: async ({ id, report }: any) => {
      const { data } = await instance.post(`contracts/${id}/recurring_reports`, { ...report, immediately: true });
      return data;
    },
    delete: async ({ id, reportId }: any) => {
      const { data } = await instance.delete(`contracts/${id}/recurring_reports/${reportId}`);
      return data;
    },
  },
};

interface review {
  status: ApprovedStatus.approved | DeclinedStatus.declined;
  reason?: string;
}

interface ReviewBatch extends review {
  ids: Array<HourlyReportType['id']>;
}
