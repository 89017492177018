import React, { createContext, useContext } from 'react';
import type { Owner } from './PermissionOwnerProvider';

interface OwnerContextValue {
  owner?: Owner;
}

const OwnerContext = createContext<OwnerContextValue | undefined>(undefined);

interface OwnerContextProviderProps {
  owner: Owner;
}

const OwnerContextProvider = ({ owner, children }: OwnerContextProviderProps & { children: React.ReactNode }) => {
  return <OwnerContext.Provider value={{ owner }}>{children}</OwnerContext.Provider>;
};

export const useOwnerContext = () => {
  const context = useContext(OwnerContext);
  if (!context) {
    return null;
  }
  return context;
};

export default OwnerContextProvider;
