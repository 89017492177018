import lightAnimation from '@/assets/animations/rebrand_logo_animation.json';
import darkAnimation from '@/assets/animations/white_deel_rebrand_logo_animation.json';
import { lazy } from 'react';
import { useThemeContext } from '@/context/ThemeContext/ThemeContext';

const Lottie = lazy(() => import('lottie-react'));

export default function DeelLoading() {
  const { activeDeelUITheme: theme } = useThemeContext();

  return (
    <Lottie
      animationData={theme === 'light' ? lightAnimation : darkAnimation}
      loop
      rendererSettings={{
        preserveAspectRatio: 'xMaxYMax meet',
      }}
      style={{ width: '180px', height: '180px' }}
    />
  );
}
