import { CONTRACT_STATUSES, CONTRACT_TYPES, CONTRACT_TYPES_MAPPED } from '@/constants/contract';
import type { TerminationLetterData } from '@/hooks/api/contract/useFetchTermination';
import type { TerminationReasonProps } from '@/scenes/Contract/components/FinalPaymentBox/Components/FinalPaymentPopupContent/TerminationReason';
import type { ContractStatus, IContractStore } from '@/types';
import type { ContractorContract } from '@/types/Contract/ContractorContract';
import type { CustomField } from '@/types/MassPay';
import instance from '@/utils/api/instance';
import GlobalPayrollContract from '@/utils/contract/globalPayrollContract';
import { Contract } from '@/utils/contract/main';
import { processShieldedOverdueReports } from '@/utils/contract/reports';
import dataLayer from '@/utils/dataLayer';
import type { AxiosRequestConfig } from 'axios';
import amendment from './amendment';
import cycle from './cycle';
import employee from './employee';
import finalPayment from './finalPayment';
import milestone from './milestone';
import projects from './project';
import reports from './reports';
import shield from './shield';
import timeOff from './timeOff';
import transfer from './transfer';
import vat from './vat';
import workStatement from './workStatement';

interface GetSeniorityLevelOpts {
  /** when sent will filter out c level job titles */
  isEORContract?: boolean;
}

type JobTitlesFilterType = 'eor';

export enum EligibleForRehire {
  YES = 'YES',
  NO = 'NO',
  DONT_KNOW = 'DONT_KNOW',
}

export type NLPField = {
  id: string;
  value: any;
};

export type UnsignedContractType = {
  contractType: string;
  oid: string;
  name: string;
  jobTitle: string;
  contractor?: any;
  client?: any;
  employee?: any;
  isShielded?: boolean;
};

export type ShieldDeposit = {
  id: string;
  type: 'SHIELD_DEPOSIT';
  shieldShieldedStatus: 'pending' | 'processing';
  status: 'paid' | '';
  requiredBeforeSign: boolean;
  total?: number;
  amount: number;
  currency: string;
  reports: unknown[];
  paymentCycle?: {
    due?: string;
  };
  cycle?: {
    due?: string;
  };
  contract: {
    status: ContractStatus;
  };
};

export interface TerminationLetterFilePayload {
  key: string;
  originalFilename: string;
  contentType: string;
}

export interface TerminationLetterPayload
  extends Omit<
    Partial<TerminationLetterData>,
    'file' | 'clientSignedAt' | 'clientName' | 'workerSignature' | 'workerName' | 'workerSignedAt'
  > {
  skip?: boolean; // This will be used to flag the client decision to skip attaching the letter on the BE payload
  file?: TerminationLetterFilePayload;
}

interface TerminationPayload {
  completionDate?: string;
  message?: string | null;
  removeAllPayments?: boolean;
  isTerminateNow?: boolean;
  attachment?: string | null;
  filename?: string;
  finalPayment?: IContractStore['workStatement']['finalPayment'];
  proRata?: IContractStore['workStatement']['proRata'];
  terminationLetter?: TerminationLetterPayload | null;
  terminationImpact?: { id: string };
  eligibleForRehire?: EligibleForRehire;
  eligibleForRehireReason?: string;
  extras?: {
    wework?: {
      terminationDate?: string;
      terminationDateType?: string;
    };
  };
}

export default {
  getApprovers: async (id: string) => {
    const { data } = await instance.get(`/contracts/${id}/approvers`);
    return data;
  },

  unCancel: async (id: string) => {
    const { data } = await instance.post(`/contracts/${id}/un-cancel`);
    return data;
  },

  amendment,
  finalPayment,
  timeOff,
  workStatement,
  projects,

  getCyclesInRange: async ({ id, start, end }: { id: string; start: any; end: any }) => {
    const { data } = await instance.get(`contracts/${id}/cycles?start=${start}&end=${end}`);
    return data;
  },

  createCommissionContract: async ({ id, payload }: any) => {
    const { data } = await instance.post(`contracts/${id}/commissions`, payload);
    return data;
  },

  getContractExample: async (query = {}) => {
    try {
      const { data } = await instance.get(`contracts/sample_pdf`, {
        params: query,
      });
      return data;
    } catch (err: any) {
      throw new Error(err);
    }
  },

  getSOWExample: async (id: string | number, query = {}) => {
    try {
      const { data } = await instance.get(`contracts/${id}/pdf?type=shield_sow`, {
        params: query,
      });
      return data;
    } catch (err: any) {
      throw new Error(err);
    }
  },

  getMsaPdf: async (id: string, query = {}) => {
    try {
      const { data } = await instance.get(`contracts/${id}/pdf`, {
        params: query,
      });
      return data;
    } catch (err: any) {
      throw new Error(err);
    }
  },

  getVerificationLetterPdf: async (contractOid: string | number, query = {}) => {
    try {
      const { data } = await instance.get(`verification_letter/pdf/${contractOid}/pdf`, {
        params: query,
      });
      return data;
    } catch (err: any) {
      throw new Error(err);
    }
  },

  getVerificationLetterSamplePdf: async (query = {}) => {
    try {
      const { data } = await instance.get(`verification_letter/pdf/sample`, {
        params: query,
      });
      return data;
    } catch (err: any) {
      throw new Error(err);
    }
  },

  getVerificationLetterSetup: async (orgId: string | number) => {
    try {
      const { data } = await instance.get(`verification_letter/organization_settings?organizationId=${orgId}`);
      return data;
    } catch (err: any) {
      throw new Error(err);
    }
  },

  updateVerificationLetterSetup: async (payload: any) => {
    try {
      const { data } = await instance.post(`verification_letter/organization_settings`, payload);
      return data;
    } catch (err: any) {
      throw new Error(err);
    }
  },

  requirementsList: async (id: string) => {
    const { data } = await instance.get(`contracts/${id}/requirements`);
    return data;
  },

  reset: async (id: string) => {
    const { data } = await instance.post(`contracts/${id}/reset`);
    return data;
  },

  uploadRequirement: async ({ oid, id, key, filename }: { oid: string; id: string; key: string; filename: string }) => {
    const { data } = await instance.post(`contracts/${oid}/requirements/${id}`, { filename, key });
    return data;
  },

  deleteRequirement: async ({ oid, id }: { oid: string; id: string }) => {
    const { data } = await instance.delete(`contracts/${oid}/requirements/${id}`);
    return data;
  },

  archive: async (id: string) => {
    const { data } = await instance.post(`contracts/${id}/archive`);
    return data;
  },

  copy: async (id: string, payload: any) => {
    const { data } = await instance.post(`contracts/${id}/copy`, payload);
    return data;
  },

  create: async (payload: any) => {
    const { data } = await instance.post('contracts', payload);
    return data;
  },

  estimate: async ({ id, ...payload }: any) => {
    const { data } = await instance.post(id ? `contracts/${id}/estimate` : 'contracts/estimate', payload);
    return data;
  },

  validateShieldFields: async (payload: {
    clientLegalEntityId?: string | number;
    agreementId?: string | number;
    fields: NLPField[];
  }) => {
    const { data } = await instance.post('shield/validations', payload);
    return data;
  },

  cycle,

  delete: async ({ id }: { id: string }) => {
    const { data } = await instance.post(`contracts/${id}/delete`);
    return data;
  },

  deleteDraft: async ({ id }: { id: string }) => {
    const { data } = await instance.delete(`draft_contracts/${id}`);
    return data;
  },

  loadCustomFields: async () => {
    const { data } = await instance.get<CustomField[]>('contracts/custom_fields_parameters');
    return data;
  },

  /**
   * @deprecated calling contracts/list endpoint is forbidden, please refer https://letsdeel.slack.com/archives/C01JQFK3G9E/p1714509420728719
   */
  getList: async (payload: any, componentName: string) => {
    const { data } = await instance.get('contracts/list', {
      params: payload,
      suppressSnackbar: true,
      headers: {
        'x-react-referrer': componentName,
      },
    });
    data.contractsLength = data.contracts.length;
    data.total = data.total + (data.draftContracts?.length || 0);
    if (data.draftContracts?.length) data.contracts.push(...data.draftContracts);
    return data;
  },

  getById: async (
    { id }: { id: string },
    config?: AxiosRequestConfig,
    { isOverdueProcessingApprovedReportsHidden = false }: { isOverdueProcessingApprovedReportsHidden?: boolean } = {},
    swrMutate?: any
  ) => {
    const { data } = await instance.get(`contracts/${id}`, config);
    if (swrMutate && data) await swrMutate(data, { revalidate: false });

    if (isOverdueProcessingApprovedReportsHidden) {
      processShieldedOverdueReports(data);
    }

    if (data?.eor) {
      if (data && data.eor && !Number(data?.eor?.variableCompensation)) {
        data.eor.variableCompensation = '';
      }
    }

    return data.type === CONTRACT_TYPES.globalPayroll.type ? new GlobalPayrollContract(data) : new Contract(data);
  },

  getEmployeeContracts: async ({ archived }: { archived: any }) => {
    const { data } = await instance.get('employee-contract', {
      params: { archived },
    });
    return data;
  },

  requestPayment: async ({ id, payload = { paymentRequested: true } }: any) => {
    const { data } = await instance.post(`contracts/${id}/request_payment`, payload);
    return data;
  },

  invite: async ({
    id,
    email,
    message,
    type,
    recaptcha,
    forceRecaptcha,
  }: {
    id: string;
    email: string;
    message: string;
    type: any;
    recaptcha?: string;
    forceRecaptcha?: boolean;
  }) => {
    const { data } = await instance.post(`/contracts/${id}/invite`, {
      email,
      message,
      type,
      recaptcha,
      forceRecaptcha,
    });
    return data;
  },

  resendInvite: async (oid: string) => {
    const { data } = await instance.post(`/contracts/${oid}/resend_invite`);
    return data;
  },

  milestone,
  vat,
  reports,

  sign: async ({ id, provider, signature, title, token, body = {}, shieldTrackingExtraData = {}, config }: any) => {
    const payload = { provider, signature, title, token, ...body };
    if (!payload.title) delete payload.title;
    const { data } = await instance.put(`contracts/${id}/sign`, payload, config);
    dataLayer.push({
      event_product: 'Contract Creation',
      event_action: 'Agreed & Signed',
      event_category: 'Click',
      page_path: window.location.pathname,
      page_title: 'Review & Sign',
      type_of_worker: 'Contractor',
      contract_type: CONTRACT_TYPES_MAPPED[data.type as keyof typeof CONTRACT_TYPES_MAPPED],
      contract_oid: id,
      contract_id: id,
      has_msa_signed: false,
      is_shield_upgrade: false,
      is_shield: false,
      ...shieldTrackingExtraData,
    });
    return data;
  },

  transfer,

  unarchive: async (id: string) => {
    const { data } = await instance.post(`contracts/${id}/unarchive`);
    return data;
  },

  update: async ({ id, payload }: any) => {
    // CD-142 FIX PATCH
    if (payload?.data?.terminator) {
      delete payload?.data?.terminator;
    }
    const { data } = await instance.patch(`contracts/${id}`, payload);
    return data;
  },

  terminate: async (id: string | number, payload: TerminationPayload) => {
    const { data } = await instance.post(`contract_terminations/${id}`, payload);
    return data;
  },

  getTermination: async (id: string | number) => {
    const { data } = await instance.get(`contract_terminations/${id}`);
    return data;
  },

  removeTermination: async (id: string | number) => {
    const { data } = await instance.delete(`contract_terminations/${id}`);
    return data;
  },

  revertTermination: async (id: string | number) => {
    const { data } = await instance.post(`contract_terminations/${id}/revert`);
    return data;
  },

  signTermination: async (id: string | number, signature: string) => {
    const { data } = await instance.post(`contract_terminations/${id}/letter/sign`, { signature });
    return data;
  },

  updateTermination: async (id: string | number, payload: TerminationPayload) => {
    const { data } = await instance.patch(`contract_terminations/${id}`, payload);
    return data;
  },

  cancel: async ({
    id,
    completionDate,
    message,
    removeAllPayments = false,
    termination,
  }: {
    id: string;
    completionDate?: string;
    message?: string;
    removeAllPayments?: boolean;
    termination?: TerminationReasonProps['reason'];
  }) => {
    await instance.post(`contracts/${id}/cancel${removeAllPayments ? `?removeAllPayments=${removeAllPayments}` : ''}`, {
      completionDate,
      message,
      termination,
    });
  },

  rejectUnsignedContract: async (id: string) => {
    const { data } = await instance.delete(`/contracts/${id}/unsigned-contract`);
    return data;
  },

  reinstateContract: async (id: string) => {
    const { data } = await instance.post(`/contracts/${id}/reinstate`);
    return data;
  },

  editCustomField: async (contractId: string, customFieldId: string, value: any) => {
    const { data } = await instance.post('contract_custom_fields/values', { contractId, customFieldId, value });
    return data;
  },

  deleteCustomField: async (contractId: string, customFieldId: string) => {
    const { data } = await instance.delete('contract_custom_fields/values', { data: { contractId, customFieldId } });
    return data;
  },

  employee,

  jobTitles: async (filterType?: JobTitlesFilterType, limit: number = 1500) => {
    const LIMIT_MAX = 1000;
    const PATH = 'contracts/job-titles';
    let tail;

    const { data } = await instance.get(PATH, {
      params: { limit: limit > LIMIT_MAX ? LIMIT_MAX : limit, filterType },
    });

    if (limit > LIMIT_MAX) {
      const { data: lastItems } = await instance.get(PATH, { params: { cursor: data.cursor } });
      tail = lastItems;
    }

    return tail ? { ...data, data: [...data.data, ...tail.data] } : data;
  },

  seniorityLevels: async (getSeniorityLevelOpts?: GetSeniorityLevelOpts) => {
    const opts: AxiosRequestConfig = {};
    if (getSeniorityLevelOpts?.isEORContract) opts.params = { 'is-eor-contract': true };
    const { data } = await instance.get('contracts/seniority?limit=200', opts);
    return data;
  },

  loadDeposits: async (contractId: string) => {
    const { data } = await instance.get<Array<ShieldDeposit>>(`contracts/${contractId}/deposits`);

    return data?.filter((invoice) => {
      if (invoice.type === 'SHIELD_DEPOSIT') {
        return (
          invoice.contract.status !== CONTRACT_STATUSES.waitingForProviderSign.type &&
          invoice.contract.status !== CONTRACT_STATUSES.new.type
        );
      }
      return true;
    });
  },

  addIntegration: async (id: string, source: string, refId: string) => {
    await instance.post(`contracts/${id}/integrations`, { source, refId });
  },

  shield,

  getAlternativeEmails: async (id: string) => {
    const { data } = await instance.get(`contracts/${id}/emails`);
    return data;
  },

  updateAlternativeEmails: async (id: string, email: string) => {
    const { data } = await instance.patch(`contracts/${id}/emails`, { email });
    return data;
  },

  getShieldWavData: async (contractId: string) => {
    const { data } = await instance.get(`contracts/${contractId}/wav_review_request`);
    return data;
  },

  updateShieldWavFiles: async (
    contractId: string,
    wavFile: {
      key: string;
      contentType: string;
      originalFilename: string;
    }
  ) => {
    const { data } = await instance.patch(`contracts/${contractId}/wav_review_request/files`, { wavFile });
    return data;
  },

  removeShieldWaveFiles: async (contractId: string) => {
    const { data } = await instance.patch(`contracts/${contractId}/wav_review_request/files`, {
      wavFile: null,
    });
    return data;
  },

  v2: {
    getFilters: async () => {
      const { data } = await instance.get('contracts/contractors/filters');
      return data;
    },
    getList: async (
      params: any
    ): Promise<{
      rows: ContractorContract[];
      count: number;
      cursor?: string | null;
    }> => {
      const { data } = await instance.get('contracts/contractors/list', { params: { isCustomOrder: true, ...params } });
      return data;
    },
  },

  getUnsignedContracts: async () => {
    const { data } = await instance.get('contracts/awaiting_to_sign');
    return data;
  },

  getContractBenefitsByCountry: async (country: string) => {
    const { data } = await instance.get(`/benefits_admin/benefit/fees?country=${country}`);
    return data;
  },
};

interface GetContractEndpoints {
  contractId?: string;
}

export const getContractEndpoints = ({ contractId }: GetContractEndpoints) => {
  return {
    getById: `/contracts/${contractId}`,
    getUnsignedContracts: '/contracts/awaiting_to_sign',
  };
};
