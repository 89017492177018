import { useCallback } from 'react';
import useMutation from '@/hooks/api/useMutation';
import type { StorageKeysEnum, StorageKeysValues } from './types';
import { mutate } from 'swr';

export const useMutateDashboardStorage = (key: StorageKeysEnum, mutateOnError?: boolean) => {
  const url = `/dashboard/home/storage/${key}`;

  const { trigger, isMutating, error } = useMutation(url, { method: 'POST' });

  const handleUpdate = useCallback(
    async (value: StorageKeysValues[typeof key]) => {
      try {
        await trigger({
          value,
        });
      } catch (error) {
        if (mutateOnError) {
          // Use new value even if request is failed
          mutate(url, { value }, false);
        }
        throw error;
      }
    },
    [trigger, mutateOnError, url]
  );

  return {
    isMutating,
    handleUpdate,
    error,
  };
};
