import { FeatureFlags } from '@/constants/featureFlags';
import UserStore from '@/stores/UserStore';
import LookupStore from '@/stores/LookupStore';
import { envShortKey } from '@/utils/environment';
import { inject } from 'mobx-react';
import React from 'react';
import { matchPath } from 'react-router-dom';

export const RECAPTCHA_ENABLED =
  envShortKey === 'dev' || envShortKey === 'local' ? localStorage.getItem('ENABLE_RECAPTCHA') === 'true' : true;
export const forceRecaptcha = localStorage.getItem('ENABLE_RECAPTCHA') === 'true' ? { forceRecaptcha: true } : {};

export const showRecaptchaCallbackFactory = async (onRecaptchaDisabledCallback) => {
  const isNotDisabledForOrg = !UserStore.isFeatureEnabledForOrganization(FeatureFlags.recaptchaDisabledOrganizations);
  const enabledRoutes = LookupStore.getFeatureFlag(FeatureFlags.captchaEnabledRoutes) || [];
  const isOnEnabledRoute = enabledRoutes.some((route) =>
    matchPath(window.location.pathname, { path: route, exact: true })
  );

  if (isOnEnabledRoute && isNotDisabledForOrg && RECAPTCHA_ENABLED) {
    try {
      if (window.grecaptcha) {
        await window.grecaptcha.reset();
        await window.grecaptcha.execute();
      } else onRecaptchaDisabledCallback();
    } catch (error) {
      console.error(error);
    }
  } else onRecaptchaDisabledCallback();
};

const Recaptcha = inject('lookups')(
  class Recaptcha extends React.Component {
    ref = React.createRef();

    onRecaptcha = async (token) => await this.props.onRecaptcha(token, this.props.data);

    async componentDidMount() {
      try {
        const { lookups } = this.props;
        const enabledRoutes = lookups.getFeatureFlag(FeatureFlags.captchaEnabledRoutes) || []; // ['/login', '/reset-password', '/signup', ...]
        const isCaptchaDisabled = lookups.getFeatureFlag(FeatureFlags.disableCaptcha); // disables all captcha
        const isOnEnabledRoute = enabledRoutes.some((route) =>
          matchPath(location.pathname, { path: route, exact: true })
        );

        const isNotDisabledForOrg = !UserStore.isFeatureEnabledForOrganization(
          FeatureFlags.recaptchaDisabledOrganizations
        );

        if (isNotDisabledForOrg && isOnEnabledRoute && !isCaptchaDisabled) {
          await Recaptcha.loadRecaptcha();
          if (this.ref.current) {
            window.grecaptcha.render(this.ref.current, {
              sitekey: '6LfBsOkUAAAAAGMTXqhjn2Thz4lERi2lC5o7h-7f',
              callback: this.onRecaptcha,
              'error-callback': this.props.onError,
              size: 'invisible',
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    }

    render() {
      return <div ref={this.ref} />;
    }

    static loadRecaptcha = () =>
      new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
        script.async = true;
        script.onload = async () => {
          const start = Date.now();
          while (!(window.grecaptcha && window.grecaptcha.render)) {
            if (Date.now() - start > 5000) return reject();
            await new Promise((r) => setTimeout(r, 100));
          }
          Recaptcha.loadRecaptcha = () => Promise.resolve();
          resolve();
        };
        script.onerror = reject;
        document.body.appendChild(script);
      });
  }
);

export default Recaptcha;
