import useFetch from '@/hooks/api/useFetch';
import useUserStore from '@/hooks/useUserStore';
import type { IEmployeeBenefitsContract } from '@/utils/api/benefits/benefits';

export const useFetchEmployeeBenefits = () => {
  const user = useUserStore();

  const employeeBenefitsEndpoint = user.isEmployee ? 'employee/benefits/list' : null;

  return useFetch<IEmployeeBenefitsContract[]>(employeeBenefitsEndpoint);
};
