import { ROUTES } from '@/constants/routesV2';

const routes = [
  {
    path: ROUTES.createProfileToken,
    loadComponent: () => import('./CreateProfile'),
  },
  {
    path: ROUTES.createSecondaryProfile,
    loadComponent: () => import('./CreateSecondaryProfile'),
  },
];

export default routes;
