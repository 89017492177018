import type { ReactNode } from 'react';
import type { SxProps } from '@letsdeel/ui';
import { MuiBox } from '@letsdeel/ui';

const ShapeContainer = ({ sx, children }: { sx?: SxProps; children: ReactNode }) => (
  <MuiBox
    sx={{
      zIndex: -1,
      position: 'fixed',
      top: 0,
      right: 0,
      '@media(max-width: 500px)': {
        display: 'none',
      },
      ...sx,
    }}
  >
    {children}
  </MuiBox>
);

export default ShapeContainer;
