import i18n from 'i18next';
import moment from 'moment';
import { DAYS_OF_WEEK } from '../constants/time';
import LookupStore from '../stores/LookupStore';

export const scrollTop = () => window.scrollTo(0, 0);

export const addBodyScroll = () => {
  if (document.body !== null) document.body.classList.add('no-scroll');
};

export const removeBodyScroll = () => {
  if (document.body !== null) document.body.classList.remove('no-scroll');
};

// Async loop
export const asyncLoop = async (array, func) => {
  // map array to promises
  const promises = array.map(async (item) => func(item));
  // wait until all promises are resolved
  await Promise.all(promises);
};

export const moveArrayEl = (arr, fromIndex, toIndex) => {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  return arr.splice(toIndex, 0, element);
};

export const toAddress = ({ street, city, country, zip, state = undefined, province, newLine, fullCountry } = {}) => {
  if (province) {
    province = LookupStore.countries[country]?.provinces
      ? LookupStore.countries[country].provinces.find((pr) => province === pr.value)?.label
      : province;
  }

  if (state) {
    state = LookupStore.countries[country]?.provinces
      ? LookupStore.countries[country].provinces.find((pr) => state === pr.value)?.label
      : state;
  }

  if (fullCountry) country = LookupStore.countries[country]?.label || country;
  let address = [street, city, state, province, zip, country].filter((o) => o).join(', ');
  if (newLine) address = address.replace(/,\s/g, ' \n');
  return address;
};

export const cn = (...classNames) => {
  return classNames.filter((className) => !!className).join(' ');
};

export const getFirstName = (name) => name?.split(' ')?.[0];

export const getLastName = (name) => name?.split(' ')[name?.split(' ')?.length - 1];

export const openCalendly = (url = 'https://letsdeel.chilipiper.com/book/all-aes', options = {}) =>
  window.Calendly.initPopupWidget({ url, ...options });

export const isCalendlyEvent = (e) => {
  return e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.') === 0;
};

export const updateFavicon = (src) => {
  let link = document.querySelector("link[rel*='icon']");
  link.rel = 'shortcut icon';
  link.href = src;
  document.getElementsByTagName('head')[0].appendChild(link);
};

export const getScaleNoun = (scale, firstCycleDate) => {
  return (
    {
      hourly: 'hour',
      daily: 'day',
      weekly: DAYS_OF_WEEK[(firstCycleDate + 6) % 7] || 'week',
      biweekly: firstCycleDate
        ? `other ${DAYS_OF_WEEK[(firstCycleDate + 6) % 7]}`
        : i18n.t('temp.unknown.utils.main.js.a9adf02fea'),
      semimonthly: 'cycle',
      monthly: 'month',
    }[scale] || ''
  );
};

export const getScreenSizeType = (width) => {
  if (width <= 576) {
    return 'xs';
  } else if (width <= 767) {
    return 'sm';
  } else if (width <= 992) {
    return 'md';
  } else if (width <= 1199) {
    return 'lg';
  } else {
    return 'xl';
  }
};

export const sortByProperty = (arr, property) => {
  return arr.sort((a, b) => (a[property] > b[property] ? 1 : -1));
};

export const sleep = (timeout) => new Promise((resolve) => setTimeout(resolve, timeout));

export const openPopupWindow = (url, windowName, win, w, h) => {
  const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
  const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;

  return win.open(
    url,
    windowName,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,
    width=${w}, height=${h}, top=${y}, left=${x}`
  );
};

export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const parseJwt = (token) => {
  try {
    const base64Url = token?.split('.')[1];

    if (!base64Url) {
      return null;
    }

    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  } catch (err) {
    console.error(err);
    return null;
  }
};

/**
 * @param {any} value
 * @param {Array} array
 * @param {Function} matcher, f.e. (item) => item.id === externalId;
 * @returns {Array}
 * @desc it takes an array, finds an element inside it based on matching function
 * and decides if it should add new value or update existing object
 */
export const createOrUpdateValues = (value, array = [], matcher) => {
  const newArray = [...array];
  const index = newArray.findIndex(matcher);

  if (index === -1) {
    newArray.push(value);
  } else {
    newArray[index] = value;
  }

  return newArray;
};

export const getTextWidth = (text) => {
  const canvas = document.createElement('CANVAS');
  const ctx = canvas.getContext('2d');
  ctx.font = getComputedStyle(document.body).font;
  return ctx.measureText(text).width;
};

export const getLoginMethods = () => {
  if (!window.deelConfig?.data) return new Set(['email', 'google', 'code']);
  if (window?.deelConfig?.data?.login && window.deelConfig.data.login !== 'normal') {
    return new Set(window.deelConfig.data.login.split(','));
  }

  const loginMethods = ['email', 'google', 'code'];
  if (window.deelConfig?.data?.ssoProviderClientId) loginMethods.push(window.deelConfig?.data?.ssoProvider);
  if (window.deelConfig?.data?.ssoIntegration) loginMethods.push('ssoIntegration');
  return new Set(loginMethods);
};

export const maskString = (string, from, to) => {
  if (string) {
    const string1 = string.substring(0, from);
    const mask = string.substring(from, to).replace(/\d/g, '*');
    return string1 + mask;
  }
};

export const capitalize = (string) => {
  if (!string) return '';
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
};

export const deCapitalizeFirstChar = (str) => {
  if (!str) return '';
  return `${str.charAt(0).toLowerCase()}${str.slice(1)}`;
};

export const toTitleCase = (string, skipWords = /^$/) => {
  if (!string) return '';
  return string
    .toLowerCase()
    .replaceAll('_', ' ')
    .split(' ')
    .map((word) => (word.match(skipWords) ? word : capitalize(word)))
    .join(' ');
};

export const snakeCaseToLowerCase = (text) => text.replace(/_/g, ' ').toLowerCase();

export const expiredDate = (expMonth, expYear) => {
  if (!expMonth || !expYear) return null;
  const month = `0${expMonth}`.slice(-2);

  return `${month}/${expYear.toString().slice(-2)}`;
};

export const trimSpaces = (payload) =>
  Object.keys(payload).reduce((a, key) => {
    a[key] = typeof payload[key] === 'string' ? payload[key].trim() : payload[key];

    return a;
  }, {});

export const isObject = (value) => typeof value === 'object' && !Array.isArray(value) && value !== null;

export const trimObject = (payload) =>
  Object.keys(payload).reduce((a, key) => {
    a[key] =
      typeof payload[key] === 'string'
        ? payload[key].trim()
        : isObject(payload[key]) && !moment.isMoment(payload[key])
        ? trimObject(payload[key])
        : payload[key];
    return a;
  }, {});

export const deleteEmptyStrings = (payload) =>
  Object.entries(payload).reduce((a, [key, value]) => {
    if (value === '') return a;
    if (moment.isMoment(value) && !value.isValid()) return a;
    a[key] =
      isObject(value) && !moment.isMoment(value)
        ? deleteEmptyStrings(value)
        : Array.isArray(value) && !moment.isMoment(value)
        ? value.map(deleteEmptyStrings)
        : value;
    return a;
  }, {});
