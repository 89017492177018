import i18n from 'i18next';
import { ProbationAmendedItemCell } from '@/scenes/Contract/components/EorContract/components/Amendments/components/AmendmentCart/components/ProbationAmendedItemCell';
import ScopeCell from '@/scenes/RequestChanges/components/AmendmentCells/ScopeCell';
import TimeOffCell from '@/scenes/RequestChanges/components/AmendmentCells/TimeOffCell';
import type { IContractStore } from '@/types';
import { ContractType, CycleScale, PaymentScale } from '@/types';

export const MAX_SCOPE_LENGTH = 10000;

export const MIN_PROBATION_PERIOD = 0;
export const DEFAULT_PROBATION_PERIOD = 90;
export const PROBATION_PERIOD_MAX_DAYS_LEFT_TO_DISPLAY = 45;
export const MIN_PROBATION_PERIOD_FOR_DEFINITE = 0;
export const DEFAULT_PROBATION_PERIOD_FOR_DEFINITE = 90;

export const MIN_CONTRACT_DURATION_MONTHS = 3;
export const MIN_CONTRACT_DURATION_DAYS = 90;

export const SCALE_TYPES: { [key: string]: string } = {
  weekly: i18n.t('temp.unknown.constants.contract.tsx.64863d871b'),
  biweekly: i18n.t('temp.unknown.constants.contract.tsx.b6c191c000'),
  semimonthly: i18n.t('temp.unknown.constants.contract.tsx.94a1c11e38'),
  'eor-twice-per-month': i18n.t('temp.unknown.constants.contract.tsx.94a1c11e38'),
  monthly: i18n.t('temp.unknown.constants.contract.tsx.8ed91b71d0'),
  'calendar-month': i18n.t('temp.unknown.constants.contract.tsx.8ed91b71d0'),
  annual: i18n.t('temp.unknown.constants.contract.tsx.16abe40749'),
};

export const SCALE_TYPES_PAYG: { [key: string]: string } = {
  weekly: i18n.t('temp.unknown.constants.contract.tsx.70a6facb9d'),
  biweekly: i18n.t('temp.unknown.constants.contract.tsx.61ebec238c'),
  semimonthly: i18n.t('temp.unknown.constants.contract.tsx.485b5d593a'),
  'eor-twice-per-month': i18n.t('temp.unknown.constants.contract.tsx.485b5d593a'),
  monthly: i18n.t('temp.unknown.constants.contract.tsx.96165d6df5'),
  'calendar-month': i18n.t('temp.unknown.constants.contract.tsx.96165d6df5'),
};

export const CYCLE_SCALES: Record<CycleScale, string> = {
  [CycleScale.WEEKLY]: i18n.t('temp.unknown.constants.contract.tsx.6c25e6a6da'),
  [CycleScale.BIWEEKLY]: i18n.t('temp.unknown.constants.contract.tsx.b6c191c000'),
  [CycleScale.SEMIMONTHLY]: i18n.t('temp.unknown.constants.contract.tsx.c6ba604e9e'),
  [CycleScale.EOR_TWICE_PER_MONTH]: i18n.t('temp.unknown.constants.contract.tsx.c6ba604e9e'),
  [CycleScale.MONTHLY]: i18n.t('temp.unknown.constants.contract.tsx.9030e39f00'),
  [CycleScale.CALENDAR_MONTH]: i18n.t('temp.unknown.constants.contract.tsx.9030e39f00'),
  [CycleScale.CUSTOM]: i18n.t('temp.unknown.constants.contract.tsx.90589c47f0'),
};

export const CYCLE_SCALES_RECURRENCE = {
  weekly: i18n.t('temp.unknown.constants.contract.tsx.weekly'),
  biweekly: i18n.t('temp.unknown.constants.contract.tsx.a9adf02fea'),
  semimonthly: i18n.t('temp.unknown.constants.contract.tsx.471d64a812'),
  'eor-twice-per-month': i18n.t('temp.unknown.constants.contract.tsx.471d64a812'),
  monthly: i18n.t('temp.unknown.constants.contract.tsx.month'),
  'calendar-month': i18n.t('temp.unknown.constants.contract.tsx.calendar-month'),
};

export const PAYMENT_SCALE_LABEL: Record<PaymentScale, string> = {
  [PaymentScale.HOURLY]: i18n.t('temp.unknown.constants.contract.tsx.167c49b224'),
  [PaymentScale.DAILY]: i18n.t('temp.unknown.constants.contract.tsx.abacc9b742'),
  [PaymentScale.WEEKLY]: i18n.t('temp.unknown.constants.contract.tsx.a12b8ee394'),
  [PaymentScale.TASK]: i18n.t('temp.unknown.constants.contract.tsx.5a32eb3613'),
};

/** @deprecated Use `PAYMENT_SCALE_LABEL` instead. */
export const CYCLE_SCALE_OPTIONS: { value: PaymentScale; label: string }[] = [
  { value: PaymentScale.HOURLY, label: PAYMENT_SCALE_LABEL[PaymentScale.HOURLY] },
  { value: PaymentScale.DAILY, label: PAYMENT_SCALE_LABEL[PaymentScale.DAILY] },
  { value: PaymentScale.WEEKLY, label: PAYMENT_SCALE_LABEL[PaymentScale.WEEKLY] },
  { value: PaymentScale.TASK, label: PAYMENT_SCALE_LABEL[PaymentScale.TASK] },
];

export const PAYMENT_SCALE_UNIT: Record<PaymentScale, string> = {
  [PaymentScale.HOURLY]: 'hour',
  [PaymentScale.DAILY]: 'day',
  [PaymentScale.WEEKLY]: 'week',
  [PaymentScale.TASK]: 'task',
};

export const SCALE_UNIT = {
  hourly: 'hour',
  daily: 'day',
  weekly: 'week',
  custom: 'task',
  monthly: 'month',
  semimonthly: 'half a month',
};

export const CONTRACT_CREATORS = {
  ongoing_time_based: {
    name: i18n.t('temp.unknown.constants.contract.tsx.4457d44087'),
    type: 'ongoing_time_based',
    desc: i18n.t('temp.unknown.constants.contract.tsx.3928af0997'),
  },
  pay_as_you_go_time_based: {
    name: i18n.t('temp.unknown.constants.contract.tsx.e95a95951d'),
    type: 'pay_as_you_go_time_based',
    desc: i18n.t('temp.unknown.constants.contract.tsx.fa5aa48858'),
  },
  payg_milestones: {
    name: i18n.t('temp.unknown.constants.contract.tsx.e9c626b8a8'),
    type: 'payg_milestones',
    desc: i18n.t('temp.unknown.constants.contract.tsx.0e8013f15c'),
  },
};

export const CONTRACT_TYPES = {
  commission: {
    type: ContractType.COMMISSION,
    name: 'COMMISSION',
  },
  fixed: {
    type: ContractType.ONGOING_TIME_BASED,
    name: 'FIXED',
  },
  milestones: {
    type: ContractType.MILESTONES,
    name: 'MILESTONES',
  },
  payAsYouGo: {
    type: ContractType.PAY_AS_YOU_GO_TIME_BASED,
    name: 'PAY AS YOU GO',
  },
  paygMilestones: {
    type: ContractType.PAYG_MILESTONES,
    name: 'MILESTONES',
  },
  paygTasks: {
    type: ContractType.PAYG_TASKS,
    name: 'PAY AS YOU GO',
  },
  peo: {
    type: ContractType.PEO,
    name: 'PEO EMPLOYEE',
  },
  eor: {
    type: ContractType.EOR,
    name: 'EOR EMPLOYEE',
  },
  employeeAgreement: {
    type: ContractType.EMPLOYEE,
    name: 'EOR EMPLOYEE',
  },
  globalPayroll: {
    type: ContractType.GLOBAL_PAYROLL,
    name: 'DIRECT EMPLOYEE GLOBAL PAYROLL',
  },
  shieldMSA: {
    type: ContractType.SHIELD_MSA,
    name: 'SHIELD',
    fullName: 'MASTER SERVICE AGREEMENT',
  },
  peoCSA: {
    type: ContractType.PEO_CSA,
    name: 'CSA',
    fullName: 'CLIENT SERVICE AGREEMENT',
  },
  hrisDirectEmployee: {
    type: ContractType.HRIS_DIRECT_EMPLOYEE,
    name: 'DIRECT EMPLOYEE',
  },
  contractorOutsideDeel: {
    type: ContractType.CONTRACTOR_OUTSIDE_DEEL,
    name: 'CONTRACTOR MANAGED OUTSIDE DEEL',
  },
};

// Also being used on the Payment flow to get the label for specific fee per contract type.
// Removing the unused types from the list will create an issue with filters on the Payments flow.
export const CONTRACT_TYPES_MAPPED: { [key in IContractStore['type']]: string } = {
  commission: i18n.t('temp.unknown.constants.contract.tsx.9180f9a5a1'),
  milestones: i18n.t('temp.unknown.constants.contract.tsx.bffb4d3b2f'),
  payg_milestones: i18n.t('temp.unknown.constants.contract.tsx.bffb4d3b2f'),
  time_based: i18n.t('temp.unknown.constants.contract.tsx.fb486b51d2'),
  ongoing_time_based: i18n.t('temp.unknown.constants.contract.tsx.fb486b51d2'),
  payg_tasks: i18n.t('temp.unknown.constants.contract.tsx.487cae69fa'),
  payg_default: i18n.t('temp.unknown.constants.contract.tsx.487cae69fa'),
  pay_as_you_go_time_based: i18n.t('temp.unknown.constants.contract.tsx.487cae69fa'),
  employee: i18n.t('temp.unknown.constants.contract.tsx.317d39e497'),
  unknown: 'TBD',
  eor: 'EOR',
  eor_msa: 'EOR',
  peo: i18n.t('temp.unknown.constants.contract.tsx.ee6a37d75a'),
  peo_csa: i18n.t('temp.unknown.constants.contract.tsx.ee6a37d75a'),
  gp_client_agreement: i18n.t('temp.unknown.constants.contract.tsx.433d613557'),
  hris_direct_employee: i18n.t('temp.unknown.constants.contract.tsx.433d613557'),
  global_payroll: i18n.t('temp.unknown.constants.contract.tsx.df91e4dec6'),
  shield_msa: i18n.t('temp.unknown.constants.contract.tsx.970da5f1f2'),
  services_default: i18n.t('temp.unknown.constants.contract.tsx.992a0f0542'),
  hris_service_agreement: i18n.t('temp.unknown.constants.contract.tsx.992a0f0542'),
  netx_fee: i18n.t('temp.unknown.constants.contract.tsx.992a0f0542'),
  hofy_standalone_payment: i18n.t('temp.unknown.constants.contract.tsx.992a0f0542'),
  missing_saas_fees: i18n.t('temp.unknown.constants.contract.tsx.992a0f0542'),
  prepaid_billing: i18n.t('temp.unknown.constants.contract.tsx.992a0f0542'),
  engage_monthly_subscription_fee: i18n.t('temp.unknown.constants.contract.tsx.992a0f0542'),
  benefits_admin_monthly_platform_fee: i18n.t('temp.unknown.constants.contract.tsx.992a0f0542'),
  contractor_outside_deel: i18n.t('temp.unknown.constants.contract.tsx.421cf564ec'),
  prepaid_funding_statement: i18n.t('temp.unknown.constants.contract.tsx.992a0f0542'),

  // @todo: Are the types below used? or should be defined as @SERVICE_INVOICES_CONTRACT_TYPES
  // @todo: contracts and payIns team should sync on this and defined what are the types that are used.
  // eor_one_off_management_fee: 'Services',
  // direct_employee: 'Direct Employee',
  // wework_standalone_payment: 'Services',
  // travel_insurance_standalone_payment: 'Services',
  // one_off_service_fee: 'Services',
  // late_payment_fee: 'Services',
  // background_check_standalone_payment: 'Services',
};

enum CONTRACT_TYPES_MAPPED_KEYS {
  MILESTONES = 'Milestones',
  FIXED = 'Fixed',
  PAYG = 'Pay As You Go',
}

export const CONTRACT_TYPES_KEYS_MAPPED: Partial<{ [key in ContractType]: string }> = {
  [ContractType.MILESTONES]: CONTRACT_TYPES_MAPPED_KEYS.MILESTONES,
  [ContractType.PAYG_MILESTONES]: CONTRACT_TYPES_MAPPED_KEYS.MILESTONES,
  [ContractType.TIME_BASED]: CONTRACT_TYPES_MAPPED_KEYS.FIXED,
  [ContractType.ONGOING_TIME_BASED]: CONTRACT_TYPES_MAPPED_KEYS.FIXED,
  [ContractType.PAYG_TASKS]: CONTRACT_TYPES_MAPPED_KEYS.PAYG,
  [ContractType.PAYG_DEFAULT]: CONTRACT_TYPES_MAPPED_KEYS.PAYG,
  [ContractType.PAY_AS_YOU_GO_TIME_BASED]: CONTRACT_TYPES_MAPPED_KEYS.PAYG,
};

export const HEAP_CONTRACT_TYPES_MAPPED = {
  ...CONTRACT_TYPES_MAPPED,
  eor: 'EOR',
  direct: 'Direct',
  'direct-employee': 'Direct',
  hris: 'HRIS',
  peo: 'peo',
};

export const CONTRACT_STATUSES = {
  archived: {
    type: 'archived',
    name: 'ARCHIVED',
    theme: 'danger',
  },
  new: {
    type: 'new',
    name: 'UNSIGNED',
  },
  onboarding: {
    type: 'onboarding',
    name: 'ONBOARDING',
  },
  onboarded: {
    type: 'onboarded',
    name: 'ONBOARDED',
  },
  waitingForContractorSign: {
    type: 'waiting_for_contractor_sign',
    name: 'AWAITING WORKER SIGNATURE',
  },
  waitingForClientSign: {
    type: 'waiting_for_client_sign',
    name: 'AWAITING ORGANIZATION SIGNATURE',
  },
  waitingForProviderSign: {
    type: 'waiting_for_provider_sign',
    name: 'AWAITING PROVIDER SIGNATURE',
  },
  waitingForEORSign: {
    type: 'waiting_for_eor_sign',
    name: 'WAITING FOR EOR TO SIGN',
  },
  waitingForEmployeeSign: {
    type: 'waiting_for_employee_sign',
    name: 'WAITING FOR EMPLOYEE TO SIGN',
  },
  waitingForHrisEmployeeSign: {
    type: 'waiting_for_hris_employee_sign',
    name: 'WAITING FOR EMPLOYEE TO SIGN',
  },
  waitingForEmployeeContract: {
    type: 'waiting_for_employee_contract',
    name: 'WAITING TO SEND EMPLOYEE AGREEMENT',
  },
  awaitingDepositPayment: {
    type: 'awaiting_deposit_payment',
    name: 'WAITING FOR DEPOSIT PAYMENT',
  },
  rejectedByContractor: {
    type: 'rejected',
    name: 'REJECTED BY CONTRACTOR',
  },
  inProgress: {
    type: 'in_progress',
    name: 'IN PROGRESS',
  },
  completed: {
    type: 'completed',
    name: 'ENDED',
  },
  userCancelled: {
    type: 'user_cancelled',
    name: 'ENDED',
  },
  cancelled: {
    type: 'cancelled',
    name: 'CANCELED',
  },
  waitingForClientPayment: {
    type: 'waiting_for_client_payment',
    name: 'AWAITING ESCROW ACTIVATION',
  },
  deleted: {
    type: 'deleted',
    name: 'DELETED',
    theme: 'danger',
  },
  expired: {
    type: 'expired',
    name: 'EXPIRED',
    theme: 'danger',
  },
  processingPayment: {
    type: 'processing_payment',
    name: 'PROCESSING PAYMENT',
  },
  rejected: {
    type: 'rejected',
    name: 'REJECTED',
    theme: 'danger',
  },
  underReview: {
    type: 'under_review',
    name: 'UNDER REVIEW',
  },
  amending: {
    type: 'amending',
    name: 'AMENDING',
  },
  draft: {
    type: 'draft',
    name: 'DRAFT',
  },
  expiring: {
    type: 'expiring',
    name: 'Expiring',
  },
};

export const EMPLOYEE_CONTRACT_STATUSES_OVERRIDE = {
  ...CONTRACT_STATUSES,
  new: {
    type: 'new',
    name: 'WAITING FOR EMPLOYEE TO SUBMIT DETAILS',
  },
  underReview: {
    type: 'under_review',
    name: 'WAITING FOR DEEL TO REVIEW',
  },
  waitingForEORSign: {
    type: 'waiting_for_eor_sign',
    name: 'WAITING FOR EMPLOYER TO SIGN',
  },
  waitingForEmployeeSign: {
    type: 'waiting_for_employee_sign',
    name: 'WAITING FOR EMPLOYEE TO COMPLETE ONBOARDING',
  },
  waitingForEmployeeContract: {
    type: 'waiting_for_employee_contract',
    name: 'WAITING TO SEND EMPLOYEE AGREEMENT',
  },
};

export const NOT_SIGNED_CONTRACT_STATUSES = [
  CONTRACT_STATUSES.new.type,
  CONTRACT_STATUSES.underReview.type,
  CONTRACT_STATUSES.waitingForContractorSign.type,
  CONTRACT_STATUSES.waitingForEmployeeContract.type,
  CONTRACT_STATUSES.waitingForClientSign.type,
  CONTRACT_STATUSES.waitingForEmployeeSign.type,
  CONTRACT_STATUSES.waitingForEORSign.type,
  CONTRACT_STATUSES.waitingForHrisEmployeeSign.type,
  CONTRACT_STATUSES.waitingForProviderSign.type,
];

export const CONTRACT_STATUSES_MAPPED = {
  archived: i18n.t('temp.unknown.constants.contract.tsx.7d69b3cb4c'),
  new: i18n.t('temp.unknown.constants.contract.tsx.03c2e7e41f'),
  waiting_for_contractor_sign: i18n.t('temp.unknown.constants.contract.tsx.09bea02f6c'),
  waiting_for_client_sign: i18n.t('temp.unknown.constants.contract.tsx.4b862a3cb1'),
  waiting_for_client_payment: i18n.t('temp.unknown.constants.contract.tsx.fe7746e4d1'),
  waiting_for_employee_sign: i18n.t('temp.unknown.constants.contract.tsx.ffa15ed2c9'),
  waiting_for_employee_contract: i18n.t('temp.unknown.constants.contract.tsx.f289571029'),
  waiting_for_provider_sign: i18n.t('temp.unknown.constants.contract.tsx.110878d534'),
  in_progress: i18n.t('temp.unknown.constants.contract.tsx.4d3d769b81'),
  in_progress_under_edit: i18n.t('temp.unknown.constants.contract.tsx.1a63ffb73c'),
  completed: i18n.t('temp.unknown.constants.contract.tsx.0157fbb99b'),
  cancelled: i18n.t('temp.unknown.constants.contract.tsx.0e22fe7d45'),
  ending: i18n.t('temp.unknown.constants.contract.tsx.753c34bcdd'),
  user_cancelled: i18n.t('temp.unknown.constants.contract.tsx.0157fbb99b'),
  deleted: i18n.t('temp.unknown.constants.contract.tsx.5fe6005bf6'),
  expired: i18n.t('temp.unknown.constants.contract.tsx.24fe48030f'),
  processing_payment: i18n.t('temp.unknown.constants.contract.tsx.4b36342bf8'),
  rejected: i18n.t('temp.unknown.constants.contract.tsx.d37b1f6c05'),
  under_review: i18n.t('temp.unknown.constants.contract.tsx.1321a344a5'),
  amending: i18n.t('temp.unknown.constants.contract.tsx.53f1a8a1b8'),
  offboarding: i18n.t('temp.unknown.constants.contract.tsx.5579548706'),
  onboarded: i18n.t('temp.unknown.constants.contract.tsx.f8e5c8ad5b'),
  onboarding: i18n.t('temp.unknown.constants.contract.tsx.10bf8b475a'),
  draft: i18n.t('temp.unknown.constants.contract.tsx.f03ab16cd5'),
};

export const EMPLOYEE_CONTRACT_STATUSES_MAPPED = {
  ...CONTRACT_STATUSES_MAPPED,
  new: i18n.t('temp.unknown.constants.contract.tsx.6d2fa53c96'),
  waiting_for_eor_sign: i18n.t('temp.unknown.constants.contract.tsx.c723e2e769'),
  waiting_for_employee_sign: i18n.t('temp.unknown.constants.contract.tsx.ffa15ed2c9'),
  waiting_for_employee_contract: i18n.t('temp.unknown.constants.contract.tsx.f289571029'),
  waiting_for_client_payment: i18n.t('temp.unknown.constants.contract.tsx.2ded7d45db'),
  waiting_for_hris_employee_sign: i18n.t('temp.unknown.constants.contract.tsx.f080a7831a'),
  under_review: i18n.t('temp.unknown.constants.contract.tsx.1f3d773313'),
  awaiting_onboarding_completion: i18n.t('temp.unknown.constants.contract.tsx.1c35ac5acf'),
  awaiting_review: i18n.t('temp.unknown.constants.contract.tsx.26fb3aaeeb'),
  awaiting_employee_signup: i18n.t('temp.unknown.constants.contract.tsx.b716b75881'),
  awaiting_deel_approval: i18n.t('temp.unknown.constants.contract.tsx.df71a393ef'),
  awaiting_employee_signature: i18n.t('temp.unknown.constants.contract.tsx.e55ad7c17f'),
  awaiting_deposit_payment: i18n.t('temp.unknown.constants.contract.tsx.8dc7efdddb'),
  awaiting_eor_signature: i18n.t('temp.unknown.constants.contract.tsx.e100b46404'),
  rejected: i18n.t('temp.unknown.constants.contract.tsx.rejected'),
};

export const PEO_EMPLOYEE_CONTRACT_STATUSES_MAPPED = {
  ...EMPLOYEE_CONTRACT_STATUSES_MAPPED,
  under_review: i18n.t('temp.unknown.constants.contract.tsx.1321a344a5'),
};

export const FULL_TIME_VALUE = 'Full-time';
export const PART_TIME_VALUE = 'Part-time';

export const MIN_PART_TIME_HOURS_US = 20;
export const MIN_PART_TIME_HOURS = 1;
export const DEFAULT_FULL_TIME_HOURS = 40;
export const DEFAULT_PART_TIME_HOURS = 20;
export const MAX_JOB_TITLE_NAME_CHARACTER = 255;

// EOR COMPENSATION OPTIONS
export const employmentTypeOptions = [
  { label: 'Full-time', value: FULL_TIME_VALUE },
  { label: 'Part-time', value: PART_TIME_VALUE },
];

export const EMPLOYEE_VALUE = i18n.t('temp.unknown.constants.contract.tsx.f8c8b903cb');
export const MANAGER_VALUE = i18n.t('temp.unknown.constants.contract.tsx.ae94be3cd5');

export const employeeTypeOptions = [
  { label: i18n.t('temp.unknown.constants.contract.tsx.f8c8b903cb'), value: EMPLOYEE_VALUE },
  { label: i18n.t('temp.unknown.constants.contract.tsx.ae94be3cd5'), value: MANAGER_VALUE },
];

export const PRORATED_VALUE = 'PRORATED';
export const STANDARD_VALUE = 'STANDARD';
export const SPECIFIC_VALUE = 'SPECIFIC';

export const RANGE = 'RANGE';
export const STANDARD = 'STANDARD';
export const WITHOUT = 'WITHOUT';

export const STANDARD_OPTIONS = [
  { label: i18n.t('temp.unknown.constants.contract.tsx.b76af2961d'), value: STANDARD_VALUE },
  { label: i18n.t('temp.unknown.constants.contract.tsx.fe0619d5f4'), value: SPECIFIC_VALUE },
];

export const PRORATED_OPTIONS = [
  { label: i18n.t('temp.unknown.constants.contract.tsx.fcb1062b22'), value: PRORATED_VALUE },
  ...STANDARD_OPTIONS,
];

export const INDEFINITE_VALUE = i18n.t('temp.unknown.constants.contract.tsx.f55dbf1424');
export const DEFINITE_VALUE = i18n.t('temp.unknown.constants.contract.tsx.1ab1ffcf43');

export const contractTermOptions = [
  { label: i18n.t('temp.unknown.constants.contract.tsx.f55dbf1424'), value: INDEFINITE_VALUE },
  { label: i18n.t('temp.unknown.constants.contract.tsx.1ab1ffcf43'), value: DEFINITE_VALUE },
];

export const NO_PROBATION_VALUE = false;
export const PROBATION_VALUE = true;

export const probationPeriodOptions = [
  { label: i18n.t('temp.unknown.constants.contract.tsx.333ed1d0c0'), value: NO_PROBATION_VALUE },
  { label: i18n.t('temp.unknown.constants.contract.tsx.1078a37d9c'), value: PROBATION_VALUE },
];

export const variableCompensationOptions = [
  {
    value: 'FIXED',
    label: i18n.t('temp.unknown.constants.contract.tsx.5f183d5b5e'),
  },
  {
    value: 'PERCENTAGE',
    label: i18n.t('temp.unknown.constants.contract.tsx.37be07209f'),
  },
];

export const qualificationOptions = [
  { label: i18n.t('temp.unknown.constants.contract.tsx.f8c8b903cb'), value: 'EMPLOYEE' },
  { label: i18n.t('temp.unknown.constants.contract.tsx.ae94be3cd5'), value: 'MANAGER' },
];

export const EOR_REPORT_TYPES = {
  employer_cost: i18n.t('temp.unknown.constants.contract.tsx.a4e6e8dc0d'),
  management_fee: i18n.t('temp.unknown.constants.contract.tsx.41940e0bb1'),
  health_benefit: i18n.t('temp.unknown.constants.contract.tsx.5b57a1b50e'),
  work: i18n.t('temp.unknown.constants.contract.tsx.b083a446a3'),
  signing_bonus: i18n.t('temp.unknown.constants.contract.tsx.0dcd9d52dc'),
  additional_fee: i18n.t('temp.unknown.constants.contract.tsx.771b646b41'),
  signing_bonus_employer_cost: i18n.t('temp.unknown.constants.contract.tsx.1c2c9f4435'),
  pension: '401(k)',
  deposit: i18n.t('temp.unknown.constants.contract.tsx.1777c2941b'),
};

export const EOR_SUPPORTED_PROVINCE_COUNTRIES = new Set(['CA', 'US', 'AU']);

export const MAPPED_EOR_FIELDS: Record<string, any> = {
  employeeEmail: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.9a3df74001') },
  employeeId: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.519fd28106') },
  employeeLegalName: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.c63a2a70ea') },
  employeeQualification: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.60bb72f838') },
  seniority: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.4404ec50a7') },
  seniorityId: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.4404ec50a7') },
  employmentType: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.c09f7025d8') },
  holidays: { type: 'hidden', label: i18n.t('temp.unknown.constants.contract.tsx.53ae1cdba2') },
  timeOffType: {
    type: 'text',
    label: i18n.t('temp.unknown.constants.contract.tsx.53ae1cdba2'),
    customComponent: TimeOffCell,
  },
  jobTitle: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.f4a7382548') },
  workHoursPerWeek: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.72c475c287') },
  probationPeriod: {
    type: 'text',
    label: i18n.t('temp.unknown.constants.contract.tsx.1078a37d9c'),
    customComponent: ProbationAmendedItemCell,
  },
  createdAt: {
    type: 'date',
    label: i18n.t('temp.unknown.constants.contract.tsx.994f1eb899'),
  },
  probationPeriodTypeForDefinite: { type: 'hidden' },
  reasonForRemovingProbationPeriod: { type: 'hidden' },
  workVisa: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.66a3b7bfe2') },
  scope: {
    type: 'text',
    label: i18n.t('temp.unknown.constants.contract.tsx.5d113f2038'),
    customComponent: ScopeCell,
  },
  employeeFirstName: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.2aac0ff377') },
  employeeLastName: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.fe91cb75a3') },
  healthAllowance: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.1df508a955') },

  employeeAddress: { type: 'address', label: i18n.t('temp.unknown.constants.contract.tsx.e37771a5a4') },

  workEligibility: { type: 'file', label: i18n.t('temp.unknown.constants.contract.tsx.f6b14ba7cd') },

  employmentCountry: { type: 'country', label: i18n.t('temp.unknown.constants.contract.tsx.61dd70e5fd') },
  employeeNationality: { type: 'country', label: i18n.t('temp.unknown.constants.contract.tsx.9df8dfd5c2') },
  employmentState: { type: 'province', label: i18n.t('temp.unknown.constants.contract.tsx.1b0a0b7b52') },

  effectiveDate: { type: 'date', label: i18n.t('temp.unknown.constants.contract.tsx.9cb4bef15e') },
  startDate: { type: 'date', label: i18n.t('temp.unknown.constants.contract.tsx.4d34f1097f') },
  endDate: { type: 'date', label: i18n.t('temp.unknown.constants.contract.tsx.b7de7e4247') },
  sickLeaveDays: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.e50abae63a') },

  salary: { type: 'money', label: i18n.t('temp.unknown.constants.contract.tsx.16ac98b318') },
  hourlyRate: { type: 'money', label: i18n.t('temp.unknown.constants.contract.tsx.45ebd0658c') },
  fixedAdjustments: { type: 'money', label: i18n.t('temp.unknown.constants.contract.tsx.7d965f6247') },
  nonStatutoryCosts: { type: 'money', label: i18n.t('temp.unknown.constants.contract.tsx.dc78ed5e4b') },
  variableCompensations: { type: 'money', label: i18n.t('temp.unknown.constants.contract.tsx.1b12d6ee7f') },
  signingBonus: { type: 'money', label: i18n.t('temp.unknown.constants.contract.tsx.40e4afbc66') },

  employerCost: { type: 'money', label: i18n.t('temp.unknown.constants.contract.tsx.a4e6e8dc0d') },
  managementFee: { type: 'money', label: i18n.t('temp.unknown.constants.contract.tsx.6943b319a7') },
  firstMonthEmployerCost: { type: 'money', label: i18n.t('temp.unknown.constants.contract.tsx.9760993447') },
  firstMonthSalary: { type: 'money', label: i18n.t('temp.unknown.constants.contract.tsx.1f86b9cd51') },
  monthlyEmployerCost: { type: 'money', label: i18n.t('temp.unknown.constants.contract.tsx.a4e6e8dc0d') },
  monthlyGrossSalary: { type: 'money', label: i18n.t('temp.unknown.constants.contract.tsx.f22c4c2d67') },
  monthlyHealthInsurance: { type: 'money', label: i18n.t('temp.unknown.constants.contract.tsx.5b57a1b50e') },
  monthlyManagementFee: { type: 'money', label: i18n.t('temp.unknown.constants.contract.tsx.6943b319a7') },
  monthlyManagementFeeUSD: {
    type: 'money',
    label: i18n.t('temp.unknown.constants.contract.tsx.c5a8ac9351'),
    customCurrency: 'USD',
  },
  depositDifference: { type: 'money', label: i18n.t('temp.unknown.constants.contract.tsx.e3261ecfb4') },
  clientLegalEntity: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.1a434befae') },
  alternateEmail: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.eeafc58e6d') },
  contractTerm: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.23607c5b60') },
  probationPeriodDecrease: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.d63cfc4222') },
  probationPeriodIncrease: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.5cec8ce721') },
  salaryIncrease: { type: 'money', label: i18n.t('temp.unknown.constants.contract.tsx.ec19bda53d') },
  salaryDecrease: { type: 'money', label: i18n.t('temp.unknown.constants.contract.tsx.bad6981f74') },
  workingHoursDecrease: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.c8f271f2e0') },
  workingHoursIncrease: { type: 'text', label: i18n.t('temp.unknown.constants.contract.tsx.b810f7e1b9') },
};

export const HEALTH_PROVIDER_COUNTRIES = ['CA', 'BR'];

export const STATUS_COLORS = {
  paid: 'success.main',
  credited: 'success.main',
  processed: 'success.main',
  processing: 'warning.main',
  pending: 'warning.main',
  overdue: 'error.main',
  failed: 'error.main',
  cancelled: 'text.primary',
  approved: 'text.primary',
};

export const STATUS_DESCRIPTION: Record<string, string> = {
  paid: i18n.t('temp.unknown.constants.contract.tsx.4cc774bc36'),
  readyForPayday: i18n.t('temp.unknown.constants.contract.tsx.49f6cc06b2'),
  overdue: i18n.t('temp.unknown.constants.contract.tsx.fe81fb6cca'),
  cancelled: i18n.t('temp.unknown.constants.contract.tsx.065b259b98'),
  failed: i18n.t('temp.unknown.constants.contract.tsx.c1e6a39b97'),
  processing: i18n.t('temp.unknown.constants.contract.tsx.655a443da5'),
};

export const EOR_STATUS_DESCRIPTION: Record<string, string> = {
  processing: i18n.t('temp.unknown.constants.contract.tsx.13bb50d609'),
  approved: i18n.t('temp.unknown.constants.contract.tsx.b706e5e171'),
  paid: i18n.t('temp.unknown.constants.contract.tsx.bd40d0b00d'),
  overdue: i18n.t('temp.unknown.constants.contract.tsx.45f96e8576'),
  cancelled: i18n.t('temp.unknown.constants.contract.tsx.065b259b98'),
  failed: i18n.t('temp.unknown.constants.contract.tsx.c1e6a39b97'),
};

export const SHIELD_NLP_RANKING_ERRORS = {
  GRAY_AREA: 'greyArea',
  STRONG_NOT_MATCH: 'strongNotMatch',
  STRONG_MATCH: 'strongMatch',
};

export const SENIORITY_LEVEL_NOT_ALLOWED_IN_SHIELD = [
  i18n.t('temp.unknown.constants.contract.tsx.7c5ba89264'),
  i18n.t('temp.unknown.constants.contract.tsx.556730c26c'),
  i18n.t('temp.unknown.constants.contract.tsx.9375a3914f'),
  i18n.t('temp.unknown.constants.contract.tsx.2a2fae71cd'),
  i18n.t('temp.unknown.constants.contract.tsx.eae9db61fb'),
];

export const SHIELD_FIELDS_TO_VALIDATE_IN_NLP = {
  CLIENT_LEGAL_ENTITY_ID: 'clientLegalEntityId',
  CONTRACT_NAME: 'contractName',
  JOB_TITLE: 'jobTitle',
  CONTRACT_SCOPE: 'contractScope',
  CONTRACTOR_TAX_RESIDENCE: 'contractorTaxResidence',
  NOTICE_PERIOD: 'noticePeriod',
};

export const STANDARD_SICK_LEAVE = 'RANGE_DEFAULT';
export const CUSTOM_SICK_LEAVE = 'RANGE_MINMAX';

export const SICK_LEAVE_OPTIONS = [
  { label: i18n.t('temp.unknown.constants.contract.tsx.eb6d8ae6f2'), value: STANDARD_SICK_LEAVE },
  { label: i18n.t('temp.unknown.constants.contract.tsx.90589c47f0'), value: CUSTOM_SICK_LEAVE },
];
