import i18n from 'i18next';
import { useCallback, useRef, useState } from 'react';
import type { ModalRef } from '@letsdeel/ui';
import { CellValue, H4, H6, Icon, IconButton, Link, Stack } from '@letsdeel/ui';
import type { AmendedItemProps } from '@/scenes/RequestChanges/interfaces';
import ScopeCellModal from '@/scenes/RequestChanges/components/AmendmentCells/ScopeCell/ScopeCellModal/ScopeCellModal';

interface Props {
  item: AmendedItemProps;
  isSummary?: boolean;
  onRestartFormValue?: (key: string) => void;
}

const ScopeCell = ({ item, isSummary, onRestartFormValue }: Props) => {
  const [viewOldData, setViewOldData] = useState(false);
  const modalRef = useRef<ModalRef>(null);

  const handleOpenModal = useCallback((isOld?: boolean) => {
    modalRef.current?.open();
    setViewOldData(isOld ?? false);
  }, []);

  if (isSummary) {
    return (
      <>
        <CellValue vertical>
          <Stack spacing={0.3}>
            <H4 regular color="text.disabled">
              {i18n.t('temp.eor.scenes.RequestChanges.5d113f2038').toString()}
            </H4>
            <Stack direction="row" justifyContent="space-between" alignItems="start">
              <Stack spacing={0.3}>
                <Link onClick={() => handleOpenModal()} variant="h4">
                  {i18n.t('temp.eor.scenes.RequestChanges.4351cfebe4').toString()}
                </Link>
                <H6>
                  {`Previous: `}
                  <Link onClick={() => handleOpenModal(true)} variant="h6">
                    {i18n.t('temp.eor.scenes.RequestChanges.4351cfebe4').toString()}
                  </Link>
                </H6>
              </Stack>
              <IconButton
                onClick={() =>
                  onRestartFormValue?.(i18n.t('temp.eor.scenes.RequestChanges.700e2ef2ce', { v0: item.key }).toString())
                }
                size="medium"
              >
                <Icon.TrashNew />
              </IconButton>
            </Stack>
          </Stack>
        </CellValue>
        <ScopeCellModal value={viewOldData ? item.oldValue : item.uiValue} ref={modalRef} />
      </>
    );
  }

  return (
    <>
      <CellValue label={i18n.t('temp.eor.scenes.RequestChanges.5d113f2038').toString()}>
        <Link onClick={() => handleOpenModal()}>{i18n.t('temp.eor.scenes.RequestChanges.4351cfebe4').toString()}</Link>
      </CellValue>
      <ScopeCellModal value={viewOldData ? item.oldValue : item.uiValue} ref={modalRef} />
    </>
  );
};

export default ScopeCell;
