import { onlyContainsZeroAfterDecimal } from '@/utils/numbers';
import { useCallback, useContext } from 'react';
import { SensitiveDataContext } from '@/context/SensitiveDataContext';
import { SENSITIVE_DATA_PLACEHOLDER } from '@/scenes/Settings/scenes/SecuritySettings/utils/SensitiveData';
import type BigNumber from 'bignumber.js';

/**
 * Formatting a price with {roundDigits} digits after decimal.
 * If the number is a fraction, the minimum numbers after decimal will be {roundDigits}.
 * Adds thousand separator as well.
 * if forceFractionDigits- shows fraction digits even if 0
 */
const convertToPriceFormat = (
  price?: string | number | BigNumber | null,
  roundDigits: number | null = null,
  forceFractionDigits: boolean = false
) => {
  if (!price || !+price) return '0';

  if (!roundDigits && +price < 1) roundDigits = 3;
  if (!roundDigits || roundDigits < 0) roundDigits = 2;

  const options: { maximumFractionDigits?: number; minimumFractionDigits?: number } = {};

  if (`${price}`.includes('.') || forceFractionDigits) {
    if (onlyContainsZeroAfterDecimal(price) && !forceFractionDigits) {
      options.maximumFractionDigits = 0;
    } else {
      options.maximumFractionDigits = roundDigits;
      options.minimumFractionDigits = roundDigits;
    }
  }

  // @ts-ignore format() works with the string type as well
  return new Intl.NumberFormat('en-US', options).format(price);
};

export const useSensitiveConvertToPriceFormat = (
  price?: string | number | BigNumber | null,
  roundDigits: number | null = null,
  forceFractionDigits: boolean = false,
  alwaysShowPrice: boolean = false
): string => {
  const { isSensitiveDataHidden } = useContext(SensitiveDataContext);

  if (isSensitiveDataHidden && !alwaysShowPrice) {
    return SENSITIVE_DATA_PLACEHOLDER;
  }

  return convertToPriceFormat(price, roundDigits, forceFractionDigits);
};

export const useSensitiveConvertToPriceFormatFactory = () => {
  const { isSensitiveDataHidden } = useContext(SensitiveDataContext);

  return useCallback(
    (
      price?: string | number | BigNumber | null,
      roundDigits: number | null = null,
      forceFractionDigits: boolean = false,
      alwaysShowPrice: boolean = false,
      isSidebarHiddenOnFocusView: boolean = false
    ) => {
      if (isSensitiveDataHidden && !alwaysShowPrice && !isSidebarHiddenOnFocusView) {
        return SENSITIVE_DATA_PLACEHOLDER;
      }

      return convertToPriceFormat(price, roundDigits, forceFractionDigits);
    },
    [isSensitiveDataHidden]
  );
};
