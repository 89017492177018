import { API_URL, STAGE } from '../constants/main';

type EnvironmentType = 'demo' | 'local' | 'prod' | 'dev';

export const envShortKey =
  STAGE?.startsWith('https://api-dev') || STAGE === 'development' || STAGE?.includes('api.deel.training')
    ? 'dev'
    : ({ staging: 'demo', demo: 'demo', production: 'prod', local: 'local' }[STAGE] as EnvironmentType);

export const API_REDIRECT_URL = envShortKey === 'prod' ? 'https://api-prod.letsdeel.com' : API_URL;

export const getAdminURL = (): string => {
  if (STAGE === 'local' || STAGE?.includes('http://localhost')) return 'http://localhost:3001';

  const gigerRegex = /www-dev-(\w+)\.giger\.training/;
  const gigerMatch = window.location.hostname.match(gigerRegex);

  if (gigerMatch?.length) return `https://admin-dev-${gigerMatch[1]}.giger.training`;

  if (STAGE === 'development' || STAGE?.includes('https://api-admin.deel.training'))
    return 'https://dev.admin.deel.wtf';
  if (STAGE === 'staging') return 'https://admin-demo.deel.wtf/';
  if (STAGE === 'demo') return 'https://admin.demo.deel.com';
  return 'https://admin.deel.network';
};
