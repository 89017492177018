import useStores from '../useStores';
import { isNative } from '@/utils/crossPlatform';

export const useNativeMobileWeb = () => {
  const {
    windowSize: { isMobile },
  } = useStores();

  const isNativeOrMobileWeb = isMobile || isNative();
  const isNativeAndMobileWeb = isMobile && isNative();

  return { isNativeOrMobileWeb, isNativeAndMobileWeb };
};
