import type { FormikValues } from 'formik';
import instance from '@/utils/api/instance';

export default {
  addTimeOffByDirectEmployee: async (contractOid: string, payload: FormikValues): Promise<any> => {
    await instance.post(`/hris/time-offs/${contractOid}`, payload, { suppressSnackbar: true });
  },

  updateTimeOffByDirectEmployee: async (
    contractOid: string,
    timeOffId: number,
    payload: FormikValues
  ): Promise<any> => {
    await instance.patch(`/hris/time-offs/${contractOid}/time-off/${timeOffId}`, payload, { suppressSnackbar: true });
  },

  requestTimeOffChange: async (contractOid: string, timeOffId: number, payload: FormikValues): Promise<any> => {
    await instance.post(`/hris/time-offs/${contractOid}/time-off/change-request/${timeOffId}`, payload, {
      suppressSnackbar: true,
    });
  },

  updateChangeRequest: async (contractOid: string, timeOffId: number, payload: FormikValues): Promise<any> => {
    await instance.patch(`/hris/time-offs/${contractOid}/time-off/change-request/${timeOffId}`, payload, {
      suppressSnackbar: true,
    });
  },

  cancelTimeOff: async (contractOid: string, timeOffId: number): Promise<any> => {
    await instance.delete(`/hris/time-offs/${contractOid}/time-off/${timeOffId}`, { suppressSnackbar: true });
  },

  cancelChangeRequest: async (contractOid: string, timeOffId: number): Promise<any> => {
    await instance.delete(`/hris/time-offs/${contractOid}/time-off/change-request/${timeOffId}`, {
      suppressSnackbar: true,
    });
  },

  approveTimeOff: async (contractOid: string, timeOffId: number): Promise<any> => {
    await instance.put(
      `/hris/time-offs/${contractOid}/time-off/${timeOffId}/review`,
      { isApproved: true },
      { suppressSnackbar: true }
    );
  },

  approveChangeRequest: async (timeOffId: number): Promise<any> => {
    await instance.put(
      `/hris/time-offs/change-request/${timeOffId}/review`,
      { isApproved: true },
      { suppressSnackbar: true }
    );
  },

  denyTimeOff: async (contractOid: string, timeOffId: number, denialReason: string): Promise<any> => {
    await instance.put(
      `/hris/time-offs/${contractOid}/time-off/${timeOffId}/review`,
      { isApproved: false, denialReason },
      { suppressSnackbar: true }
    );
  },

  denyChangeRequest: async (timeOffId: number, denialReason: string): Promise<any> => {
    await instance.put(
      `/hris/time-offs/change-request/${timeOffId}/review`,
      { isApproved: false, denialReason },
      { suppressSnackbar: true }
    );
  },
};
