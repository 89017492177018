import { PAYROLL_EVENT_STATE, PAYROLL_FUNDING_STATUS } from '@/types';
import { PAYROLL_DEPOSIT_STATUS } from '@/types/LegalEntity';
import { EmployeeStatus } from '@/types/EmploymentPayrollEvent';
import type { SmallChipTheme } from '@letsdeel/ui';

export enum payrollNavigationParams {
  submitReportSuccess = 'submit-report-success',
  payFundingRedirect = 'redirected-from-funding-payment',
  payrollApproveSuccess = 'payroll-approve-success',
  payrollReportReject = 'payroll-report-issues-reject',
  confirmPayrollApprove = 'confirm-payroll-approve',
  payrollEventsFilter = 'payroll-events',
  payrollVersionFilter = 'payroll-version',
}

export enum PAYROLL_EVENT_LIST_STATE {
  PAYROLL_SUBMISSION = 'PAYROLL_SUBMISSION',
  PAYROLL_PROCESSING = 'PAYROLL_PROCESSING',
  PAYROLL_APPROVAL = 'PAYROLL_APPROVAL',
  FUNDING_PAYMENT = 'FUNDING_PAYMENT',
  FUNDING_RECEIPT = 'FUNDING_RECEIPT',
  PAYMENT_DONE = 'PAYMENT_DONE',
  PROCESSED_BY_EXTERNAL_PROVIDER = 'PROCESSED_BY_EXTERNAL_PROVIDER',
}

export enum AWAITING_FUNDING_PAYROLL_EVENT_STATUS {
  AWAITING_DEPOSIT_PAYMENT = 'AWAITING_DEPOSIT_PAYMENT',
  PROCESSING_DEPOSIT_PAYMENT = 'PROCESSING_DEPOSIT_PAYMENT',
  DEPOSIT_PAYMENT_FAILED = 'DEPOSIT_PAYMENT_FAILED',
  AWAITING_FUNDING_PAYMENT = 'AWAITING_FUNDING_PAYMENT',
  PROCESSING_FUNDING_PAYMENT = 'PROCESSING_FUNDING_PAYMENT',
  FUNDING_PAYMENT_FAILED = 'FUNDING_PAYMENT_FAILED',
}

export const awaitingFundingEventStatusByFundingStatus: Record<
  PAYROLL_FUNDING_STATUS,
  AWAITING_FUNDING_PAYROLL_EVENT_STATUS
> = {
  [PAYROLL_FUNDING_STATUS.AWAITING_DEPOSIT]: AWAITING_FUNDING_PAYROLL_EVENT_STATUS.AWAITING_FUNDING_PAYMENT,
  [PAYROLL_FUNDING_STATUS.READY]: AWAITING_FUNDING_PAYROLL_EVENT_STATUS.AWAITING_FUNDING_PAYMENT,
  [PAYROLL_FUNDING_STATUS.PROCESSING]: AWAITING_FUNDING_PAYROLL_EVENT_STATUS.PROCESSING_FUNDING_PAYMENT,
  [PAYROLL_FUNDING_STATUS.COMPLETE]: AWAITING_FUNDING_PAYROLL_EVENT_STATUS.PROCESSING_FUNDING_PAYMENT,
  [PAYROLL_FUNDING_STATUS.FAILED]: AWAITING_FUNDING_PAYROLL_EVENT_STATUS.FUNDING_PAYMENT_FAILED,
};

export const awaitingFundingEventStatusByDepositStatus: Record<
  PAYROLL_DEPOSIT_STATUS,
  AWAITING_FUNDING_PAYROLL_EVENT_STATUS | null
> = {
  [PAYROLL_DEPOSIT_STATUS.IN_REVIEW]: AWAITING_FUNDING_PAYROLL_EVENT_STATUS.PROCESSING_DEPOSIT_PAYMENT,
  [PAYROLL_DEPOSIT_STATUS.AWAITING_PAYMENT]: AWAITING_FUNDING_PAYROLL_EVENT_STATUS.AWAITING_DEPOSIT_PAYMENT,
  [PAYROLL_DEPOSIT_STATUS.PROCESSING]: AWAITING_FUNDING_PAYROLL_EVENT_STATUS.PROCESSING_DEPOSIT_PAYMENT,
  [PAYROLL_DEPOSIT_STATUS.PAID]: null,
  [PAYROLL_DEPOSIT_STATUS.DISMISSED]: null,
  [PAYROLL_DEPOSIT_STATUS.FAILED]: AWAITING_FUNDING_PAYROLL_EVENT_STATUS.DEPOSIT_PAYMENT_FAILED,
};

export const approvedActionStates: PAYROLL_EVENT_STATE[] = [
  PAYROLL_EVENT_STATE.AWAITING_SELF_PAYMENT,
  PAYROLL_EVENT_STATE.AWAITING_GP_PAYMENT,
  PAYROLL_EVENT_STATE.COMPLETE,
];

export const displayPropsByEmployeeStatus: { [key in EmployeeStatus]: { color: SmallChipTheme; label: string } } = {
  [EmployeeStatus.ACTIVE]: { color: 'success', label: 'Active' },
  [EmployeeStatus.LEAVER]: { color: 'error', label: 'Leaver' },
  [EmployeeStatus.STARTER]: { color: 'neutral', label: 'Starter' },
  [EmployeeStatus.AMENDED]: { color: 'warning', label: 'Contract updated' },
  [EmployeeStatus.ON_LEAVE]: { color: 'warning', label: 'On Leave' },
  [EmployeeStatus.TERMINATED]: { color: 'error', label: 'Terminated' },
};

export const statusDisplayPropsByFundingStatus: Record<
  PAYROLL_FUNDING_STATUS,
  { color: string; text: string; chipColor: 'default' | 'success' | 'error' | 'warning'; chipDot?: boolean }
> = {
  [PAYROLL_FUNDING_STATUS.AWAITING_DEPOSIT]: { text: '', color: '', chipColor: 'default' },
  [PAYROLL_FUNDING_STATUS.COMPLETE]: { text: 'Paid', color: 'success.main', chipColor: 'success' },
  [PAYROLL_FUNDING_STATUS.FAILED]: { text: 'Not paid', color: 'error.main', chipColor: 'error' },
  [PAYROLL_FUNDING_STATUS.PROCESSING]: { text: 'Processing', color: 'warning.main', chipColor: 'warning' },
  [PAYROLL_FUNDING_STATUS.READY]: { text: 'Awaiting funding', color: 'warning.main', chipColor: 'warning' },
};

export const statusDisplayPropsFundingNotRequired = {
  text: 'Not required',
  color: 'neutral',
  chipColor: 'default',
  chipDot: false,
} as const;

export const publishedActionStates: PAYROLL_EVENT_STATE[] = [
  ...approvedActionStates,
  PAYROLL_EVENT_STATE.PENDING_PACKAGE_APPROVAL,
];

export const processingActionStates: PAYROLL_EVENT_STATE[] = [
  PAYROLL_EVENT_STATE.REPORT_PROCESSING,
  PAYROLL_EVENT_STATE.REPORT_SUBMITTED,
  PAYROLL_EVENT_STATE.PENDING_REVIEW,
];

export enum PayrollTabDownloadLabel {
  PROOF_OF_PAYMENT = 'Proof Of Payment',
  LEAVE_ACCRUAL_REPORT = 'Leave Accrual Report',
  TIME_OFF_USED_REPORT = 'Time Off Used Report',
  ACCOUNTING_SUMMARY = 'Summary of Accounting',
  TERMINATION_DOCS = 'Termination Docs',
  EOY_ENTITY_DOCUMENTS = 'EOY entity documents',
  LOCAL_G2N_REPORT = 'Local G2N report',
  OTHER_REPORT = 'Other report',
  PARALLEL_RUN_RESULTS = 'Parallel run results',
  LIABILITY_PAYMENTS = 'Liability Payment',
  LIABILITY_REPORT = 'Liability report',
  GENERAL_LEDGER_CURRENT_PAYROLL = 'General ledger (current payroll cycle)',
  GENERAL_LEDGER_MULTIPLE_PAYROLL = 'General ledger (multiple payroll cycles)',
  PAYSLIPS = 'Payslips',
  GENERAL_LEDGER = 'General ledger',
  ICP_PAYSLIPS = 'ICP Payslips',
  ICP_G2N_REPORT = 'ICP G2N Report',
  DEEL_LIABILITIES_REPORT = 'Deel Liabilities Report',
  TIMEOFF_REPORT = 'Time Off Report',
  TIMEOFF_REPORT_BROKEN_DOWN = 'Time off report (Broken down report)',
  SALARY_PAYMENTS = 'Salary Payments Report',
  EXPENSE_REPORT = 'Expense Report',
  CUSTOMER_DELIVERABLE = 'Customer Deliverable',
  CUSTOMER_DELIVERABLES = 'Customer Deliverables',
  PAYROLL_CHECK_JOURNAL_REPORT = 'Payroll Check Journal Report',
  PAYROLL_BILLING_SUMMARY_REPORT = 'Payroll Billing Summary Report',
}
