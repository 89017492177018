import instance from '@/utils/api/instance';
import type {
  FilterOptions,
  GetReportFilterOptionsParams,
  Report as AnalyticsReport,
  ReportListResponse,
} from '@/context/Reports/types';
import { sanitizeParameters } from '@/scenes/Analytics/utils';
import { FileFormats } from '@/scenes/Analytics/scenes/Reports/ReportViewer/constants';
import type { Report } from '@/types';

export type SaveCustomG2NPayload = {
  customReportName: string;
  sourceReportId: string;
  parameterValues: Record<string, any>;
  cellNamesToInclude: any;
};

export type CustomReportResponse = {
  id: number;
  name: string;
  sourceReportId: string;
  parameterValues: any;
  cellNamesToInclude: string[];
};

const reports = {
  loadCustomReportsList: async (): Promise<ReportListResponse> => {
    const { data: customReports } = await instance.get('data_reports/custom');
    const reports = customReports.data.map(
      ({ name, ...rest }: any): AnalyticsReport => ({
        title: name,
        description: '',
        isCustom: true,
        ...rest,
      })
    );
    return { reports };
  },
  loadList: async (): Promise<ReportListResponse> => {
    const [{ data: defaultReportsData }, { reports: customReports }] = await Promise.all([
      instance.get('data_reports'),
      reports.loadCustomReportsList(),
    ]);
    return {
      ...defaultReportsData,
      reports: [...defaultReportsData.data, ...customReports],
    };
  },
  loadReviewers: async (expenseId: number) => {
    const { data } = await instance.get<Required<Report>['approvers']>(`/expenses/${expenseId}/reviewers`);
    return data;
  },
  loadSources: async () => {
    const { data } = await instance.get('data_reports/custom/source_reports');
    return data;
  },
  loadPrimaryObjects: async () => {
    const { data } = await instance.get('data_reports/custom/primary_objects');
    return data;
  },
  loadTemplate: async (sourceReportId: string, hideFilterOptions?: boolean, apiVersion?: number) => {
    const { data } = await instance.get('data_reports/custom/template', {
      params: {
        sourceReportId,
        hideFilterOptions,
        apiVersion,
      },
    });
    return data;
  },
  loadReportAccessUsers: async (searchString?: string) => {
    const queryParam = searchString ? `?search=${searchString}` : '';
    const { data } = await instance.get(`data_reports/custom/share/people${queryParam}`);
    const sortedPeople = data.people.sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name));
    return { ...data, people: sortedPeople };
  },
  loadReportResult: async ({ id, body = {}, query = {}, isCustom }: any) => {
    const parameterValues = sanitizeParameters(body.parameterValues);
    const sanitizedBody = { ...body, parameterValues };
    const { data } = await instance.post(`data_reports${isCustom ? '/custom' : ''}/${id}/result`, sanitizedBody, {
      params: query,
      ...(body.format === FileFormats.XLSX ? { responseType: 'blob' } : {}),
    });
    return data;
  },
  loadReportFilterOptions: async (params: GetReportFilterOptionsParams): Promise<FilterOptions> => {
    const { sourceReportId, filterName, limit, offset, search, initialSelectedOptions } = params;
    const body = { apiVersion: 2, limit, offset, search, initialSelectedOptions };
    const { data } = await instance.post(`data_reports/${sourceReportId}/${filterName}/options`, body);
    return data;
  },
  createReport: async (body: any) => {
    const { data } = await instance.post('/data_reports/custom', body);
    return data;
  },
  editReport: async (id: string, body: any) => {
    const { data } = await instance.patch(`data_reports/custom/${id}`, body, {
      suppressSnackbar: true,
      hideErrorMessageOnNetworkError: true,
    });
    return data;
  },
  delete: (id: string | number) =>
    instance.delete(`data_reports/custom/${id}`, { suppressSnackbar: true, hideErrorMessageOnNetworkError: true }),
  saveReport: ({ id, body, create, sourceReportId }: any) => {
    if (create) {
      return reports.createReport({
        ...body,
        sourceReportId,
      });
    }

    return instance.patch(`data_reports/custom/${id}`, body);
  },
  loadReport: async (id: string) => {
    const { data } = await instance.get(`data_reports/custom/${id}`);
    return data;
  },
  loadChartResult: async <Payload = any>({ id, body = {}, query = {} }: any): Promise<Payload> => {
    const { data } = await instance.post(`data_reports/${id}/result/chart`, body, {
      params: query,
    });
    return data;
  },
  setFavorite: (id: string, favorite: boolean, isCustomReport: boolean) => {
    return instance(`data_reports/${isCustomReport ? 'custom/' : ''}favorites/${id}`, {
      method: favorite ? 'POST' : 'DELETE',
    });
  },
  saveCustomG2NReport: async ({
    customReportName,
    sourceReportId,
    parameterValues,
    cellNamesToInclude,
  }: SaveCustomG2NPayload): Promise<CustomReportResponse> => {
    const { data } = await instance.post(`payroll_events/custom_reports`, {
      customReportName,
      sourceReportId,
      parameterValues,
      cellNamesToInclude,
    });

    return data;
  },
  updateCustomG2NReport: async ({
    customReportId,
    customReportName,
    parameterValues,
    cellNamesToInclude,
  }: {
    customReportId: string;
    customReportName: string;
    parameterValues: Record<string, any>;
    cellNamesToInclude: any;
  }): Promise<CustomReportResponse> => {
    const { data } = await instance.put(`payroll_events/custom_reports/${customReportId}`, {
      customReportName,
      parameterValues,
      cellNamesToInclude,
    });

    return data;
  },
};

export default reports;

export const getChartEndpoint = (id: string) => `data_reports/${id}/result/chart`;
