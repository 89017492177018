import { Alert, TextField, VStack } from '@letsdeel/ui';
import type { FormikBag, FormikHandlers } from 'formik';
import { fieldName } from '@/components/ResetAuthenticator/ChoosePhoneNumber';
import { useTranslation } from 'react-i18next';

interface Props {
  getFieldProps: FormikHandlers['getFieldProps'];
  setFieldValue: FormikBag<any, any>['setFieldValue'];
  setFieldTouched: FormikBag<any, any>['setFieldTouched'];
}

const ConfirmPhoneNumber = ({ getFieldProps, setFieldValue, setFieldTouched }: Props) => {
  const { t } = useTranslation();
  return (
    <VStack gap={3}>
      <Alert>{t('components.resetAuthenticator.provideLast4DigitToVerify')}</Alert>
      <TextField
        {...getFieldProps('phoneNumber')}
        onChange={(e) => {
          setFieldTouched(fieldName, true);
          setFieldValue(fieldName, e.target.value.slice(0, 4));
        }}
        label={t('components.resetAuthenticator.last4DigitsOfPhoneNumber')}
        type={'number'}
      />
    </VStack>
  );
};

export default ConfirmPhoneNumber;
