import type { Employee } from '@/types/Employee';
import type { GPEmployeeOnboardingStep } from '@/types/GlobalPayroll/onboarding';
import type { Address, BankDetailsItemEnhanced } from '@/utils/contract/globalPayrollContract';
import instance from '@/utils/api/instance';

const BASE_URL = 'global_payroll/employee';

export default {
  updateEmployeeBankDetails: async (
    contractOid: string | null,
    payload: object,
    additionalRequestParams: { methodChainId?: string } = {}
  ): Promise<BankDetailsItemEnhanced | null> => {
    try {
      const response = await instance.post<BankDetailsItemEnhanced>(`${BASE_URL}/${contractOid}/bank_details`, {
        payload,
        ...additionalRequestParams,
      });
      return response.data;
    } catch {
      return null;
    }
  },

  getEmployeeBankDetailsRequirements: async (
    contractId: string,
    params: { country?: string; currency?: string } = {}
  ): Promise<any> => {
    const { data } = await instance.get(`${BASE_URL}/${contractId}/bank_details_requirements`, { params });

    return data;
  },

  updateEmployeeDetails: async (contractId: number, payload: Record<string, any>): Promise<void> => {
    return instance.patch(`${BASE_URL}/${contractId}/update_details`, payload);
  },

  updateEmployeeAddress: async (contractId: number, payload: Record<string, any>): Promise<Address> => {
    const { data } = await instance.patch(`${BASE_URL}/${contractId}/update_address`, payload);
    return data;
  },

  getEmployeeOnboardingSteps: async (employeeExternalId: string | null): Promise<GPEmployeeOnboardingStep[] | null> => {
    const { data } = await instance.get(`${BASE_URL}/${employeeExternalId}/onboarding_steps`);
    return data.steps;
  },

  updateProfileAddress: async (payload: Record<string, any>): Promise<Address> => {
    const { data } = await instance.patch(`${BASE_URL}/update_profile_address`, payload);
    return data;
  },

  getEmployee: async (): Promise<Employee> => {
    const { data } = await instance.get(`${BASE_URL}/details`);
    return data;
  },

  removeEmployeeBankDetail: async (contractOid: string, methodChainId: string) => {
    const { data } = await instance.delete(`${BASE_URL}/${contractOid}/bank_details/${methodChainId}`);
    return data;
  },

  distributeFunds: async (contractOid: string, settings: { methodChainId: string; fundsDistribution: number }[]) => {
    const { data } = await instance.patch(`${BASE_URL}/${contractOid}/settings`, { settings });
    return data;
  },
};

export const bankDetailEndpoints = {
  directEmployeeBankDetails: '/global_payroll/employee/bank_details',
  getDirectEmployeeBankDetailsRequirements: (contractOid: string) =>
    `/global_payroll/employee/${contractOid}/bank_details_requirements`,
};
