import i18n from 'i18next';
import { Button, HStack } from '@letsdeel/ui';
import { API_REDIRECT_URL } from '@/utils/environment';
import Logo from '../Logo';
import './one-login-button.less';

const OneLoginButton = ({ children, onComplete }) => {
  const handleClick = () => {
    localStorage.setItem('OneLoginOnComplete', onComplete);
    const query = new URLSearchParams();
    query.append('client_id', window.deelConfig?.data?.ssoProviderClientId);
    query.append('redirect_uri', `${API_REDIRECT_URL}/webhooks/onelogin`);
    query.append('state', `${window.deelConfig?.data?.ssoProviderClientId},${window.location.origin}`);
    query.append('response_type', 'code');
    query.append('scope', 'openid');
    window.location.href = `${window.deelConfig?.data?.ssoProviderDomain}/oidc/2/auth?${query.toString()}`;
  };

  return (
    <Button
      className="text-center position-relative one-login-button"
      fullWidth
      variant="outlined"
      size="large"
      onClick={handleClick}
    >
      <HStack gap={1} align="center" className="justify-content-center">
        <Logo type="one-login" size="24" color="#00297A" className={'d-flex align-items-center'} />
        {children || i18n.t('temp.platform-fe.components.OneLoginButton.880883cd33')}
      </HStack>
    </Button>
  );
};

export default OneLoginButton;
