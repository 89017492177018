import { NativeStorageKeys } from '@/constants/NativeStorageKeys';
import { isNative } from '@/utils/crossPlatform';
import { Preferences } from '@capacitor/preferences';
import * as Sentry from '@sentry/react';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { AppLaunchStatusKeys, AppLaunchStatusUtil } from '@/native/AppLaunchStatus/AppLaunchStatus';

export const isAppPinSet = async () => {
  try {
    if (!isNative()) {
      return true;
    }
    const { value } = await SecureStoragePlugin.keys();
    return value.includes(NativeStorageKeys.SECURE_PIN);
  } catch (error) {
    Sentry.captureException(error);
    return false;
  }
};

export const setLoggedInUserInformation = async (userInfo: LoggedInUserInformation) => {
  try {
    await Preferences.set({ key: NativeStorageKeys.LOGGED_IN_USER_INFO, value: JSON.stringify(userInfo) });
  } catch {}
};

export const getLoggedInUserInformation = async () => {
  const result = await Preferences.get({ key: NativeStorageKeys.LOGGED_IN_USER_INFO });
  if (!result.value) {
    return null;
  }
  return JSON.parse(result.value);
};

export const getIsBiometricAppPinSaved = async () => {
  try {
    const result = await Preferences.get({ key: NativeStorageKeys.BIOMETRIC_APP_PIN_SAVED_AT });

    if (!result.value) {
      return false;
    }

    return !!JSON.parse(result.value);
  } catch {
    return false;
  }
};

export const setIsBiometricAppPinSaved = async () => {
  await Preferences.set({ key: NativeStorageKeys.BIOMETRIC_APP_PIN_SAVED_AT, value: Date.now().toString() });
};

export const removeIsBiometricAppPinSaved = async () => {
  await Preferences.remove({ key: NativeStorageKeys.BIOMETRIC_APP_PIN_SAVED_AT });
};

export const setSecureAppPin = async (pin: string) => {
  await SecureStoragePlugin.set({ key: NativeStorageKeys.SECURE_PIN, value: pin });
};

export const getSecureAppPin = async () => {
  const pinSet = await isAppPinSet();
  if (!pinSet) return null;
  const { value } = await SecureStoragePlugin.get({ key: NativeStorageKeys.SECURE_PIN });
  return value;
};

export const removeAppPinInformation = async () => {
  try {
    await Preferences.remove({ key: NativeStorageKeys.BIOMETRIC_APP_PIN_SAVED_AT });
    await Preferences.remove({ key: NativeStorageKeys.LOGGED_IN_USER_INFO });
    await SecureStoragePlugin.remove({ key: NativeStorageKeys.SECURE_PIN });
    await AppLaunchStatusUtil.setBooleanStatus(AppLaunchStatusKeys.APP_PIN_VERIFICATION_NEEDED, true);
  } catch (e) {
    console.error(e);
  }
  localStorage.removeItem('isOnboardingCompleted');
};

export interface LoggedInUserInformation {
  email: string;
  picUrl: string | null;
  name: string;
}
