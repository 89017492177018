import type { WhiteLabelSettings } from '@/hooks/api/settings/whiteLabel/types';
import type { ContextForWhiteLabel } from './types';
import { useFetchWithHeaders } from '@/hooks/api/useFetch';

export const useFetchWhiteLabelSettingsByToken = (token?: string | null, context: ContextForWhiteLabel = 'login') => {
  const customDomain =
    (!window.location.origin.includes('deel') || window.location.origin.includes('deel.host')) &&
    window.location.hostname !== 'localhost'
      ? window.location.origin
      : null;

  const headers: Record<string, string> = token ? { 'x-white-label-token': token } : {};

  const { data, mutate, error, isLoading } = useFetchWithHeaders<Partial<WhiteLabelSettings>>(
    `/white_label_settings?context=${context}${customDomain ? `&customDomain=${customDomain}` : ''}`,
    headers
  );

  const isWhiteLabelDataEmpty = data?.status?.toLowerCase() !== 'enabled' || !data || !!error;

  return {
    data,
    mutate,
    isLoading,
    error,
    isWhiteLabelDataEmpty,
  };
};
