export enum ChartDataType {
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
  NUMBER = 'number',
}

export interface ChartProps {
  data: Array<ChartData> | undefined;
  chartMetadata: ChartMetadata | undefined;
  gridColumns: number;
}

export enum AxisType {
  SCATTER = 'scatter',
  LINE = 'line',
}

export enum AxisPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum AxisLineStyle {
  SOLID = 'solid',
  DASHED = 'dashed',
}

export enum AxisLineSize {
  SMALL = 'small',
  LARGE = 'large',
}

interface ChartMetadataAxisFill {
  offset: number;
  stopColor: string;
  stopOpacity: number;
}

export interface ChartMetadataAxis {
  key: string;
  name: string;
  color: string;
  hoverColor?: string;
  stackGroup?: string;
  showLabel?: boolean;
  type?: AxisType.LINE | AxisType.SCATTER;
  lineStyle?: AxisLineStyle.SOLID | AxisLineStyle.DASHED;
  lineSize?: AxisLineSize.SMALL | AxisLineSize.LARGE;
  position?: AxisPosition.BOTTOM | AxisPosition.TOP;
  fill?: Array<ChartMetadataAxisFill>;
  unit?: string;
  tooltip?: ChartTooltipContent[];
}

interface ChartMetadataTooltip {
  displayPercentage?: boolean;
  onShow?: () => void;
  subject?: ChartMetadataSubject;
  total?: {
    key: string;
    show?: boolean;
    subject: ChartMetadataSubject;
  };
  type?: string;
  disableHeaderFormatting?: boolean;
}

interface ChartMetadataSubject {
  singularName: string;
  pluralName: string;
}

export type ChartData = Record<string, number> & {
  name: string;
  xAxisData: string;
  tooltip?: ChartTooltipContent;
};

export type ChartTooltipContent = {
  name: string;
  value: number;
  type: ChartDataType;
  currency?: string;
};

enum LegendPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export interface ChartMetadata {
  dataType: ChartDataType;
  chartType: string;
  expand?: boolean;
  currency: string;
  axis: Array<ChartMetadataAxis>;
  subject: ChartMetadataSubject;
  tooltip?: ChartMetadataTooltip;
  maxItemsToDisplay?: number;
  disableXAxisFormatting?: boolean;
  hideXAxis?: boolean;
  containerHeightInPx?: number;
  legendPosition?: LegendPosition;
  hideBarChartLabel?: boolean;
  barSize?: number;
  isSensitiveDataHidden?: boolean;
  hideTotal?: boolean;
}

export interface useBarShapeDynamicValuesProps {
  axisItem: ChartMetadata['axis'][number];
  chartMetadata: ChartMetadata;
  payload: Record<string, number>;
  barShapeGap?: number;
}
