export const LS_ESCALATION_BANNER_VISIBILITY = 'LS_ESCALATION_BANNER_VISIBILITY';

export function setEscalationBannerVisibility(visible: boolean) {
  localStorage.setItem(LS_ESCALATION_BANNER_VISIBILITY, visible.toString());
}

export function removeEscalationBannerVisibility() {
  localStorage.removeItem(LS_ESCALATION_BANNER_VISIBILITY);
}

export function getEscalationButtonVisibility() {
  return localStorage.getItem(LS_ESCALATION_BANNER_VISIBILITY);
}

export function isEscalationButtonVisible() {
  return localStorage.getItem(LS_ESCALATION_BANNER_VISIBILITY) === 'true';
}
