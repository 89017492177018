import type { ApiResponse } from '@/hooks/api/useFetch';
import useFetch from '@/hooks/api/useFetch';
import type { StorageKeys, GetStorageValue } from './types';
import type { StorageKeysEnum } from './types';

/**
 * Fetches data from storage based on the provided key.
 * If no key is provided, fetches all data in the key-value storage.
 * @param key Storage key (optional)
 */
/* eslint-disable no-redeclare */
export function useFetchDashboardStorage<T extends StorageKeysEnum>(
  key: T,
  enabled?: boolean
): ApiResponse<GetStorageValue<T>>;
export function useFetchDashboardStorage(enabled?: boolean): ApiResponse<StorageKeys>;
export function useFetchDashboardStorage<T extends StorageKeysEnum>(
  key?: T,
  enabled: boolean = true
): ApiResponse<GetStorageValue<T>> | ApiResponse<StorageKeys> {
  const url = `/dashboard/home/storage${key ? `/${key}` : ''}`;
  return useFetch(enabled ? url : null, { keepPreviousData: false }) as T extends StorageKeysEnum
    ? ApiResponse<GetStorageValue<T>>
    : ApiResponse<StorageKeys>;
}
