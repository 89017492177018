import { useLocalStorage } from '@letsdeel/ui';
import { useCallback } from 'react';

export const HRIS_PEOPLE_LIST_CUSTOM_FILTERS_KEY = 'hrisPeopleListCustomFilters_';
export const HRIS_PEOPLE_LIST_CUSTOM_SEARCH_QUERY_KEY = 'hrisPeopleListCustomSearchQuery_';

const usePeopleListCustomFilters = (organizationId: number) => {
  const peopleListCustomFiltersStorageKey = `${HRIS_PEOPLE_LIST_CUSTOM_FILTERS_KEY}${organizationId}`;

  const [customQueryValues, setCustomQueryValues] = useLocalStorage<{ [key: string]: string[] | string }>(
    peopleListCustomFiltersStorageKey,
    {}
  );

  const resetCustomQueryValues = useCallback(() => {
    localStorage.removeItem(peopleListCustomFiltersStorageKey);
  }, [peopleListCustomFiltersStorageKey]);

  const removeCustomQueryValue = useCallback(
    (key: string) => {
      // eslint-disable-next-line no-prototype-builtins
      if (customQueryValues.hasOwnProperty(key)) {
        const { [key]: _, ...newCustomQueryValues } = customQueryValues;

        setCustomQueryValues(newCustomQueryValues);
      }
    },
    [customQueryValues, setCustomQueryValues]
  );

  return {
    customQueryValues,
    setCustomQueryValues,
    resetCustomQueryValues,
    removeCustomQueryValue,
  };
};

export default usePeopleListCustomFilters;
