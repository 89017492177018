import instance from '@/utils/api/instance';

export type DeelHRAccessPayload = {
  hrisEnabled: boolean;
};

export type DeelHRAccess = {
  hrisEnabled: boolean;
  hrisAccessStatusChangedAt: Date | null;
};

export default {
  deelHRAccess: async (orgId: number, payload: DeelHRAccessPayload): Promise<DeelHRAccess> => {
    const { data } = await instance.patch(`/organizations/${orgId}/settings/hr`, payload);
    return data;
  },
};
