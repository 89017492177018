import { type ConfirmOptions, type ConfirmResult, Dialog } from '@capacitor/dialog';

export const showConfirmDialog = async (confirmOptions: ConfirmOptions): Promise<ConfirmResult> => {
  return await Dialog.confirm(confirmOptions);
};

export const showAlert = async (message: string): Promise<void> => {
  await Dialog.alert({
    message,
  });
};
