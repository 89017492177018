import {
  HRIS_PEOPLE_LIST_CUSTOM_FILTERS_KEY,
  HRIS_PEOPLE_LIST_CUSTOM_SEARCH_QUERY_KEY,
} from '@/scenes/People/hooks/usePeopleListCustomFilters';
import type { Organization } from '@/types/User';

export const ENTITY_KEY = 'entity';
export const TEAM_KEY = 'team';
export const CONTRACT_TYPE_KEY = 'contractType';
export const CURRENCY_TYPE = 'currency';
export const COUNTRY_TYPE = 'country';
export const PAYMENT_CYCLE_TYPE = 'paymentCycle';
export const ENABLED_BETAS = 'enabledBetas';
export const ONBOARDING_DEST = 'onboardingDest';
export const DUE_DATE = 'dueDate';
export const KYC_CHECK = 'kycCheck';
export const HIRING_STATUSES = 'hiringStatuses';

export type FilterOptionTypeForInvoices =
  | 'team'
  | 'entity'
  | 'contractType'
  | 'currency'
  | 'country'
  | 'paymentCycle'
  | 'dueDate'
  | 'kycCheck'
  | 'hiringStatuses';

export type localStorageKeys = FilterOptionTypeForInvoices | 'enabledBetas' | 'onboardingDest';

export type ILocalStorageKeyMapForMassPayFilters = {
  [key in FilterOptionTypeForInvoices]: string;
};

export type ILocalStorageKeyMap = {
  [key in localStorageKeys]: string;
};

export const localStorageKeyMapForMassPayFilters: ILocalStorageKeyMapForMassPayFilters = {
  entity: ENTITY_KEY,
  team: TEAM_KEY,
  contractType: CONTRACT_TYPE_KEY,
  country: COUNTRY_TYPE,
  currency: CURRENCY_TYPE,
  paymentCycle: PAYMENT_CYCLE_TYPE,
  dueDate: DUE_DATE,
  kycCheck: KYC_CHECK,
  hiringStatuses: HIRING_STATUSES,
};

export const localStorageKeyMap: ILocalStorageKeyMap = {
  ...localStorageKeyMapForMassPayFilters,
  enabledBetas: ENABLED_BETAS,
  onboardingDest: ONBOARDING_DEST,
};

const getEnabledBetas = () => (localStorage.getItem(localStorageKeyMap.enabledBetas) || '').toLowerCase().split(',');

export const isBetaEnabled = (betaKey: string) => {
  const enabledBetas = getEnabledBetas();
  return enabledBetas.includes(String(betaKey).toLowerCase());
};

export const removeFromBeta = (betaKey: string) => {
  const enabledBetas = getEnabledBetas();

  const remainingBetas = enabledBetas.filter((item) => item !== String(betaKey).toLowerCase()).join(',');

  if (remainingBetas) {
    localStorage.setItem(localStorageKeyMap.enabledBetas, remainingBetas);
  } else {
    localStorage.removeItem(localStorageKeyMap.enabledBetas);
  }
};

export const LS_HRIS_PEOPLE_LIST_CUSTOM_FILTERS_KEY = (organizations?: Organization[]): string[] => {
  const noOrgSearch = `${HRIS_PEOPLE_LIST_CUSTOM_SEARCH_QUERY_KEY}null`;
  const noOrgFilters = `${HRIS_PEOPLE_LIST_CUSTOM_FILTERS_KEY}null`;

  if (organizations && organizations.length >= 1) {
    const customFilters = organizations
      .map((organization) =>
        organization?.id ? `${HRIS_PEOPLE_LIST_CUSTOM_FILTERS_KEY}${organization.id}` : undefined
      )
      .filter((item) => item !== undefined) as string[];

    const customSearch = organizations
      .map((organization) =>
        organization?.id ? `${HRIS_PEOPLE_LIST_CUSTOM_SEARCH_QUERY_KEY}${organization.id}` : undefined
      )
      .filter((item) => item !== undefined) as string[];

    return [...customFilters, ...customSearch, noOrgFilters, noOrgSearch];
  }

  return [noOrgFilters, noOrgSearch];
};
