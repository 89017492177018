import { Illustration, Modal, type ModalRef, Stack, TextField, P, Button } from '@letsdeel/ui';
import useUserStore from '@/hooks/useUserStore';
import { useEffect, useRef, useCallback, useState, type ChangeEvent } from 'react';
import {
  WORKFORCE_SIZE_MAX_LIMIT,
  WORKFORCE_SIZE_MIN_LIMIT,
} from '@/components/WorkforceSizeReminderModal/WorkforceSizeReminderModal.utils';
import { useSkipWorkforceSize, useUpdateWorkforceSize } from '@/components/WorkforceSizeReminderModal/useWorkforceSize';
import { useTranslation } from 'react-i18next';

export const LS_WORKFORCE_SIZE_SKIPPED_KEY = 'workforce_size_modal_skipped';

export const WorkforceSizeReminderModal = () => {
  const { organization } = useUserStore();
  const workforceSizeModal = useRef<ModalRef>();
  const [workforceValue, setWorkforceValue] = useState<number | null>(null);
  const [error, setError] = useState<string>('');
  const { t } = useTranslation();
  const user = useUserStore();
  const isSkippedOnCurrentSession = localStorage.getItem(LS_WORKFORCE_SIZE_SKIPPED_KEY);

  useEffect(() => {
    if (organization?.isWorkforceSizeFilingRequired && !isSkippedOnCurrentSession) {
      workforceSizeModal.current?.open();
    }
  }, [isSkippedOnCurrentSession, organization?.isWorkforceSizeFilingRequired]);

  const handleUpdateWorkforceValue = (e: ChangeEvent<HTMLInputElement>) => {
    const workforceValue = e?.target?.value ? +e.target.value : null;
    setWorkforceValue(workforceValue);
  };

  const handleCloseModal = useCallback(() => {
    workforceSizeModal.current?.close();
  }, []);

  const { trigger: updateWorkforceSize, isMutating } = useUpdateWorkforceSize({
    id: organization?.id,
    onSuccess: handleCloseModal,
  });
  const { trigger: skipFillingWorkforceSize } = useSkipWorkforceSize({ id: organization?.id });

  const handleSkipWorkforceFilling = useCallback(async () => {
    await skipFillingWorkforceSize({});
    localStorage.setItem(LS_WORKFORCE_SIZE_SKIPPED_KEY, LS_WORKFORCE_SIZE_SKIPPED_KEY);
    handleCloseModal();
  }, [handleCloseModal, skipFillingWorkforceSize]);

  const handleSubmit = useCallback(async () => {
    if (
      !workforceValue ||
      (workforceValue && (workforceValue > WORKFORCE_SIZE_MAX_LIMIT || workforceValue < WORKFORCE_SIZE_MIN_LIMIT))
    ) {
      setError(t('hris.workforceSize.maxLimitOfWorkforceSize', { maxLimit: WORKFORCE_SIZE_MAX_LIMIT }));
      return;
    }

    setError('');
    await updateWorkforceSize({ workforceSize: workforceValue });
    await user.getMyProfile();
  }, [t, updateWorkforceSize, user, workforceValue]);

  return (
    <Modal ref={workforceSizeModal} title={t('hris.workforceSize.modalTitle')} onClose={handleSkipWorkforceFilling}>
      <Stack alignItems="center" gap={2}>
        <Illustration illustration="message-sent" />
        <P textAlign="center">{t('hris.workforceSize.modalDescription')}</P>
        <TextField
          label={t('hris.workforceSize.organizationSize')}
          type="number"
          value={workforceValue}
          onChange={handleUpdateWorkforceValue}
          error={!!error}
          helperText={error ? error : t('hris.workforceSize.approximateNumberOfWorkers')}
          required
        />

        <Button sx={{ alignSelf: 'flex-end' }} onClick={handleSubmit} loading={isMutating} disabled={isMutating}>
          {t('hris.workforceSize.modalSubmitBtn')}
        </Button>
      </Stack>
    </Modal>
  );
};
