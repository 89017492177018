import { Button, HStack, Icon } from '@letsdeel/ui';
import { useTranslation } from 'react-i18next';
import './qr-login-button.less';

interface Props {
  loading: boolean;
  onClick: () => {};
}

export const QRLoginButton = ({ loading, onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Button
      className="text-center position-relative qr-login-button"
      fullWidth
      variant="outlined"
      disabled={loading}
      size="large"
      onClick={onClick}
    >
      <HStack gap={1} align="center" className="justify-content-center">
        <Icon.BarcodeQr size={24} className="d-flex align-items-center" />
        {t('authentication.qrLogin.button')}
      </HStack>
    </Button>
  );
};
