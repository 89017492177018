import i18n from 'i18next';
import { Stack, VStack } from '@letsdeel/ui';
import { useEffect } from 'react';

import { EmailVerification as WebEmailVerification } from '@/scenes/Login/components/EmailVerification/EmailVerification';

import TypeWebEmailForm from './components/TypeEmailForm';
import TypeNewPasswordForm from './components/TypeNewPasswordForm';
import AlternativeOptions from './components/AlternativeOptions';
import { useResetPassword } from '@/scenes/ResetPassword/useResetPassword';
import { type ResetPasswordProps } from '@/scenes/ResetPassword/types';
import { isNative } from '@/utils/crossPlatform';
import NativeTypeEmailForm from './components/NativeTypeEmailForm';
import { NativeEmailVerification } from '@/scenes/Login/components/EmailVerification/NativeEmailVerification';
import { addNativeForgotPasswordEvent } from './utils/nativeEvents';

const ResetPassword = ({ goBackAction, token, email, afterResetPassword }: ResetPasswordProps) => {
  const {
    confirmPreferredEmail,
    afterSubmitResetPassword,
    customDomainHostName,
    setIsBackupEmailReset,
    showAlternativeOptions,
    hasBackupEmail,
    isLoading,
    onSubmitForgotPassword,
    isBackupEmailReset,
    typedEmail,
    resendResetPasswordEmail,
    setTypeEmail,
    setShowAlternativeOptions,
    emailVerificationTexts,
  } = useResetPassword({ email });

  const TypeEmailForm = isNative() ? NativeTypeEmailForm : TypeWebEmailForm;
  const EmailVerification = isNative() ? NativeEmailVerification : WebEmailVerification;

  useEffect(() => {
    if (!showAlternativeOptions && (typedEmail || email)) {
      addNativeForgotPasswordEvent(i18n.t('temp.platform-fe.scenes.ResetPassword.9ade028837').toString());
    }
  }, [typedEmail, showAlternativeOptions, email]);

  const resetPasswordForm = (
    <>
      {token ? (
        <TypeNewPasswordForm
          token={token}
          isLoading={isLoading}
          customDomainHostName={customDomainHostName()}
          afterSubmitResetPassword={afterResetPassword ?? afterSubmitResetPassword}
        />
      ) : (
        <TypeEmailForm
          isLoading={isLoading}
          customDomainHostName={customDomainHostName()}
          onSubmitForgotPassword={onSubmitForgotPassword}
          goBackAction={goBackAction}
        />
      )}
    </>
  );

  const resetEmailSentScreen = (
    <EmailVerification
      {...emailVerificationTexts}
      alertInfo={i18n.t('temp.platform-fe.scenes.ResetPassword.9f9c8381e9').toString()}
      hasBackupEmail={hasBackupEmail}
      resend={resendResetPasswordEmail}
      setIsBackupEmailReset={setIsBackupEmailReset}
      goBackAction={email ? undefined : () => setTypeEmail('')}
      setShowAlternativeOptions={() => setShowAlternativeOptions(true)}
    />
  );

  if (typedEmail && showAlternativeOptions) {
    return (
      <Stack gap={3} justifyContent="space-between" height="100%">
        <AlternativeOptions
          isBackupEmailReset={isBackupEmailReset}
          onGoBack={() => setShowAlternativeOptions(false)}
          setIsBackupEmailReset={setIsBackupEmailReset}
          confirmPreferredEmail={confirmPreferredEmail}
        />
      </Stack>
    );
  }

  return (
    <VStack gap={3} className="justify-content-space-between height-100">
      {typedEmail || email ? resetEmailSentScreen : resetPasswordForm}
    </VStack>
  );
};

export default ResetPassword;
