import { SeasonsContext } from '@/context/SeasonsContext';
import { useContext } from 'react';

const useSeasons = () => {
  const { currentSeason } = useContext(SeasonsContext);

  return {
    currentSeason,
  };
};

export default useSeasons;
