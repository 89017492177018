import { lazy } from 'react';

// Deel
const DeelLogo = lazy(() => import('../assets/images/logos/deel.svg?react'));
const DeelLogoBlue = lazy(() => import('../assets/images/logos/deel-logo-blue.svg?react'));
const DeelWhiteLogo = lazy(() => import('../assets/images/logos/deel-white.svg?react'));
const DeelSmallWhiteLogo = lazy(() => import('../assets/images/logos/deel-small-white.svg?react'));
const DeelSquareLogo = lazy(() => import('../assets/images/logos/deel-square.svg?react'));
const DeelXmasLogo = lazy(() => import('../assets/images/logos/deel-xmas-logo.svg?react'));
const DeelXmasWhiteLogo = lazy(() => import('../assets/images/logos/deel-xmas-logo-white.svg?react'));
const DeelDAloneSmall = lazy(() => import('../assets/images/logos/deel-d-alone-small.svg?react'));
const DeelXmasBlueLogo = lazy(() => import('../assets/images/logos/deel-xmas-logo-blue.svg?react'));
const DeelRedesignBlack = lazy(() => import('../assets/images/logos/deel-redesign-black.svg?react'));
const DeelRedesignIconBlack = lazy(() => import('../assets/images/logos/deel-redesign-icon-black.svg?react'));
const DeelRedesignSmallIconBlack = lazy(() =>
  import('../assets/images/logos/deel-redesign-small-icon-black.svg?react')
);
const DeelRedesignWhite = lazy(() => import('../assets/images/logos/deel-redesign-white.svg?react'));
const DeelRedesignIconWhite = lazy(() => import('../assets/images/logos/deel-redesign-icon-white.svg?react'));
const DeelRedesignSmallIconWhite = lazy(() =>
  import('../assets/images/logos/deel-redesign-small-icon-white.svg?react')
);

// Others
const AchLogo = lazy(() => import('../assets/images/logos/ach.svg?react'));
const BrexLogo = lazy(() => import('../assets/images/logos/brex.svg?react'));
const AndroidLogo = lazy(() => import('../assets/images/logos/android.svg?react'));
const AppleLogo = lazy(() => import('../assets/images/logos/apple.svg?react'));
const BankTransferLogo = lazy(() => import('../assets/images/logos/bank-transfer.svg?react'));
const CreditCardsLogo = lazy(() => import('../assets/images/logos/credit-cards.svg?react'));
const RefundsUsedLogo = lazy(() => import('../assets/images/logos/refunds-used.svg?react'));
const BillingCreditsLogo = lazy(() => import('../assets/images/logos/billing-credits.svg?react'));
const NoLogo = lazy(() => import('../assets/images/logos/substract.svg?react'));
const AngelListSmallWhiteLogo = lazy(() => import('../assets/images/logos/angel-list-small-white-logo.svg?react'));
const DirectDebitLogo = lazy(() => import('../assets/images/logos/direct-debit.svg?react'));
const BecsLogo = lazy(() => import('../assets/images/logos/becs.svg?react'));
const DirectDebitNewLogo = lazy(() => import('../assets/images/logos/direct-debit-new.svg?react'));
const Expensify = lazy(() => import('../assets/images/logos/expensify.svg?react'));
const MercuryLogo = lazy(() => import('../assets/images/logos/mercury.svg?react'));
const PayoneerLogo = lazy(() => import('../assets/images/logos/payoneer.svg?react'));
const PayPalLogo = lazy(() => import('../assets/images/logos/paypal.svg?react'));
const DeelCardLogo = lazy(() => import('../assets/images/logos/deel_card.svg?react'));
const RevolutLogo = lazy(() => import('../assets/images/logos/revolut.svg?react'));
const SepaLogo = lazy(() => import('../assets/images/logos/sepa.svg?react'));
const StripeLogo = lazy(() => import('../assets/images/logos/stripe.svg?react'));
const TransferwiseLogo = lazy(() => import('../assets/images/logos/transferwise.svg?react'));
const TransferwiseLightLogo = lazy(() => import('../assets/images/logos/transferwise-light.svg?react'));
const WireTransferLogo = lazy(() => import('../assets/images/logos/wire-transfer.svg?react'));
const WealthSimple = lazy(() => import('../assets/images/logos/wealth-simple.svg?react'));
const WealthFront = lazy(() => import('../assets/images/logos/wealth-front.svg?react'));
const Google = lazy(() => import('../assets/images/logos/google-logo.svg?react'));
const Okta = lazy(() => import('../assets/images/logos/okta-logo.svg?react'));
const OktaOLogo = lazy(() => import('../assets/images/logos/okta-o-logo.svg?react'));
const OktaAuraLogo = lazy(() => import('../assets/images/logos/okta-aura-logo.svg?react'));
const OneLoginLogo = lazy(() => import('../assets/images/logos/one-login-logo.svg?react'));
const AzureLogo = lazy(() => import('../assets/images/logos/azure-login-logo.svg?react'));
const Saml = lazy(() => import('../assets/images/logos/saml2.svg?react'));
const SamlLogo = lazy(() => import('../assets/images/logos/saml2-logo.svg?react'));
const Coinbase = lazy(() => import('../assets/images/logos/coinbase.svg?react'));
const Veriff = lazy(() => import('../assets/images/logos/veriff.svg?react'));
const CanadianPAD = lazy(() => import('../assets/images/logos/ca-pad.svg?react'));
const CertnLogo = lazy(() => import('../assets/images/logos/certn-logo.svg?react'));
const OneLogin = lazy(() => import('../assets/images/logos/onelogin-logo.svg?react'));
const Visa = lazy(() => import('../assets/images/logos/visa.svg?react'));
const VisaCircle = lazy(() => import('../assets/images/logos/visa-circle.svg?react'));
const Mastercard = lazy(() => import('../assets/images/logos/master-card.svg?react'));
const MastercardCircle = lazy(() => import('../assets/images/logos/master-card-circle.svg?react'));
const WeWorkLogo = lazy(() => import('../assets/images/logos/wework-logo.svg?react'));
const WeWorkLogoText = lazy(() => import('../assets/images/logos/wework-logo-text.svg?react'));
const GSuite = lazy(() => import('../assets/images/logos/gsuite.svg?react'));
const BVNKLogo = lazy(() => import('../assets/images/logos/bvnk.svg?react'));

// Integrations
const QuickbooksLogo = lazy(() => import('../assets/images/logos/quickbooks.svg?react'));
const NetsuiteLogo = lazy(() => import('../assets/images/logos/netsuite.svg?react'));
const NetsuiteCircularLogo = lazy(() => import('../assets/images/logos/netsuite-circular.svg?react'));
const SlackLogo = lazy(() => import('../assets/images/logos/slack.svg?react'));
const JiraLogo = lazy(() => import('../assets/images/logos/jira.svg?react'));
const WebhookLogo = lazy(() => import('../assets/images/logos/webhooks.svg?react'));
const WebhookLogoDark = lazy(() => import('../assets/images/logos/webhooks-dark.svg?react'));
const BambooLogo = lazy(() => import('../assets/images/logos/bamboo.svg?react'));
const BambooLogoWhite = lazy(() => import('../assets/images/logos/bamboo-white.svg?react'));
const BambooDeelLogo = lazy(() => import('../assets/images/logos/bambooDeel.svg?react'));
const AshbyLogo = lazy(() => import('../assets/images/logos/ashby.svg?react'));
const MangtasLogo = lazy(() => import('../assets/images/logos/mangtas.svg?react'));
const GreenhouseLogo = lazy(() => import('../assets/images/logos/greenhouse.svg?react'));
const WorkdayLogo = lazy(() => import('../assets/images/logos/workday.svg?react'));
const XeroLogo = lazy(() => import('../assets/images/logos/xero.svg?react'));
const HiBob = lazy(() => import('../assets/images/logos/hibob.svg?react'));
const Lever = lazy(() => import('../assets/images/logos/lever.svg?react'));
const DeelScimLogo = lazy(() => import('../assets/images/logos/deel-scim-logo.svg?react'));
const WorkableLogo = lazy(() => import('../assets/images/logos/workable.svg?react'));
const RootsLogo = lazy(() => import('../assets/images/logos/roots.svg?react'));
const HofyLogo = lazy(() => import('../assets/images/logos/hofy.svg?react'));
const PersonioLogo = lazy(() => import('../assets/images/logos/personio.svg?react'));

// Transfer
const BankTransfer = lazy(() => import('../assets/images/logos/bank-transfer-small.svg?react'));
const InstantCardTransfer = lazy(() => import('../assets/images/logos/instant-card-transfer-small.svg?react'));
const RevolutTransfer = lazy(() => import('../assets/images/logos/revolut-transfer.svg?react'));
const TransferwiseTransfer = lazy(() => import('../assets/images/logos/transferwise-transfer.svg?react'));
const PaypalTransfer = lazy(() => import('../assets/images/logos/paypal-transfer.svg?react'));
const BinanceTransfer = lazy(() => import('../assets/images/logos/binance-transfer.svg?react'));
const CoinbaseTransfer = lazy(() => import('../assets/images/logos/coinbase-transfer.svg?react'));
const CoinbaseRoundSimple = lazy(() => import('../assets/images/logos/coinbase-round-simple.svg?react'));
const DeelTransfer = lazy(() => import('../assets/images/logos/deel-transfer.svg?react'));
const BvnkTransfer = lazy(() => import('../assets/images/logos/bvnk-transfer.svg?react'));
const AstroPayTransfer = lazy(() => import('../assets/images/logos/astropay.svg?react'));
const DolarTransfer = lazy(() => import('../assets/images/logos/dolarapp.svg?react'));
const PixTransfer = lazy(() => import('../assets/images/logos/pix-transfer.svg?react'));

// Mass Payment
const AchRoundOutline = lazy(() => import('../assets/images/logos/ach-round-outline.svg?react'));
const BacsRoundOutline = lazy(() => import('../assets/images/logos/bacs-round-outline.svg?react'));
const BecsRoundOutline = lazy(() => import('../assets/images/logos/becs-round-outline.svg?react'));
const MercuryRoundOutline = lazy(() => import('../assets/images/logos/mercury-round-outline.svg?react'));
const MercuryRoundSimple = lazy(() => import('../assets/images/logos/mercury-round-simple.svg?react'));
const SepaRoundOutline = lazy(() => import('../assets/images/logos/sepa-round-outline.svg?react'));
const PadRoundOutline = lazy(() => import('../assets/images/logos/pad-round-outline.svg?react'));
const BrexRoundOutline = lazy(() => import('../assets/images/logos/brex-round-outline.svg?react'));
const BankRoundOutlineLight = lazy(() => import('../assets/images/logos/bank-round-outline-light.svg?react'));
const CardRoundOutlineLight = lazy(() => import('../assets/images/logos/card-round-outline-light.svg?react'));

// Payroll
const GoogleDeel = lazy(() => import('../assets/images/logos/google-logo-deel-primary.svg?react'));
const AppleDeelLogo = lazy(() => import('../assets/images/logos/apple-deel-primary.svg?react'));
const OutlookDeelLogo = lazy(() => import('../assets/images/logos/outlook-deel-primary.svg?react'));

const MineralLogo = lazy(() => import('../assets/images/logos/mineral.svg?react'));
const PrismHRLogo = lazy(() => import('../assets/images/logos/prismhr.svg?react'));

const LOGOS = {
  ach: <AchLogo />,
  android: <AndroidLogo />,
  apple: <AppleLogo />,
  brex: <BrexLogo />,
  ['bank-transfer']: <BankTransferLogo />,
  ['credit-cards']: <CreditCardsLogo />,
  ['refunds-used']: <RefundsUsedLogo />,
  ['billing-credits']: <BillingCreditsLogo />,
  deel: <DeelLogo />,
  ['deel-blue']: <DeelLogoBlue />,
  ['deel-white']: <DeelWhiteLogo />,
  ['deel-small-white']: <DeelSmallWhiteLogo />,
  ['deel-square']: <DeelSquareLogo />,
  ['deel-xmas']: <DeelXmasLogo />,
  ['deel-xmas-white']: <DeelXmasWhiteLogo />,
  ['deel-d-alone-small']: <DeelDAloneSmall />,
  ['deel-xmas-blue']: <DeelXmasBlueLogo />,
  ['deel-redesign-black']: <DeelRedesignBlack />,
  ['deel-redesign-icon-black']: <DeelRedesignIconBlack />,
  ['deel-redesign-small-icon-black']: <DeelRedesignSmallIconBlack />,
  ['deel-redesign-white']: <DeelRedesignWhite />,
  ['deel-redesign-icon-white']: <DeelRedesignIconWhite />,
  ['deel-redesign-small-icon-white']: <DeelRedesignSmallIconWhite />,
  ['angel-list-small-white']: <AngelListSmallWhiteLogo />,
  ['direct-debit']: <DirectDebitLogo />,
  ['direct-debit-new']: <DirectDebitNewLogo />,
  google: <Google />,
  mercury: <MercuryLogo />,
  payoneer: <PayoneerLogo />,
  paypal: <PayPalLogo />,
  ['deel-card']: <DeelCardLogo />,
  revolut: <RevolutLogo />,
  sepa: <SepaLogo />,
  stripe: <StripeLogo />,
  pad: <CanadianPAD />,
  becs: <BecsLogo />,
  transferwise: <TransferwiseLogo />,
  ['transferwise-light']: <TransferwiseLightLogo />,
  ['wire-transfer']: <WireTransferLogo />,
  ['wealth-simple']: <WealthSimple />,
  ['wealth-front']: <WealthFront />,
  gsuite: <GSuite />,
  bvnk: <BVNKLogo />,

  veriff: <Veriff />,
  coinbase: <Coinbase />,
  okta: <Okta />,
  ['okta-aura']: <OktaAuraLogo />,
  ['okta-o']: <OktaOLogo />,
  ['one-login']: <OneLoginLogo />,
  ['azure-login']: <AzureLogo />,
  ['bank-small']: <BankTransfer color="#727283" />,
  ['instant-card-transfer-small']: <InstantCardTransfer color="#727283" />,
  ['bvnk-small']: <BvnkTransfer />,
  ['astropay']: <AstroPayTransfer />,
  ['dolarapp']: <DolarTransfer />,
  ['pix']: <PixTransfer />,
  ['revolut-small']: <RevolutTransfer />,
  ['transferwise-small']: <TransferwiseTransfer />,
  ['paypal-small']: <PaypalTransfer />,
  ['coinbase-small']: <CoinbaseTransfer />,
  ['binance-small']: <BinanceTransfer />,
  ['coinbase-round-simple']: <CoinbaseRoundSimple />,
  ['deel-small']: <DeelTransfer />,
  ['deel-transfer']: <DeelTransfer />,
  ['ach-round-outline']: <AchRoundOutline />,
  ['bacs-round-outline']: <BacsRoundOutline />,
  ['becs-round-outline']: <BecsRoundOutline />,
  ['bank-round-outline']: <BankRoundOutlineLight />,
  ['card-round-outline']: <CardRoundOutlineLight />,
  ['mercury-round-outline']: <MercuryRoundOutline />,
  ['mercury-round-simple']: <MercuryRoundSimple />,
  ['sepa-round-outline']: <SepaRoundOutline />,
  ['pad-round-outline']: <PadRoundOutline />,
  ['brex-round-outline']: <BrexRoundOutline />,
  onelogin: <OneLogin />,
  azure: <AzureLogo />,
  saml: <Saml />,
  visa: <Visa />,
  visaCircle: <VisaCircle />,
  mastercard: <Mastercard />,
  mastercardCircle: <MastercardCircle />,
  wework: <WeWorkLogo />,
  ['wework-text']: <WeWorkLogoText />,
  noLogo: <NoLogo />,

  // Integrations
  quickbooks: <QuickbooksLogo />,
  netsuite: <NetsuiteLogo />,
  ['netsuite-circular']: <NetsuiteCircularLogo />,
  bamboohr: <BambooLogo />,
  ['bamboohr-white']: <BambooLogoWhite />,
  bamboohrgp: <BambooLogo />,
  bambood: <BambooDeelLogo />,
  ashbyhq: <AshbyLogo />,
  mangtas: <MangtasLogo />,
  greenhouse: <GreenhouseLogo />,
  workday: <WorkdayLogo />,
  xero: <XeroLogo />,
  scim: <DeelScimLogo />,
  slack: <SlackLogo />,
  hibob: <HiBob />,
  lever: <Lever />,
  expensify: <Expensify />,
  workable: <WorkableLogo />,
  certn: <CertnLogo />,
  roots: <RootsLogo />,
  hofy: <HofyLogo />,
  azuresso: <AzureLogo />,
  oktasso: <OktaOLogo />,
  oneloginsso: <OneLoginLogo />,
  samlsso: <SamlLogo />,
  googleWorkspacesso: <Google />,
  googleworkspace: <Google />,
  personio: <PersonioLogo />,
  jira: <JiraLogo />,
  webhooks: <WebhookLogo />,
  ['webhooks-dark']: <WebhookLogoDark />,

  // Global Payroll
  ['google-deel']: <GoogleDeel />,
  ['apple-deel']: <AppleDeelLogo />,
  ['outlook-deel']: <OutlookDeelLogo />,

  // To decrease bundle size we load big SVG file (>~30KB) as image but not import.
  // Important note it is not possible to change color for these SVG files.
  ['payoneer-small']: (
    <img
      src="https://s3.amazonaws.com/media.letsdeel.com/app/images/logos/payoneer-transfer.svg?react"
      width={40}
      height={40}
      alt=""
    />
  ),
  mineral: <MineralLogo />,
  prismhr: <PrismHRLogo />,
};

export default LOGOS;
