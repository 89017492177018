import { Button, Icon } from '@letsdeel/ui';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addNativeSSOEvent } from '../../utils';

export const NativeSSOLoginButton = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const onClick = () => {
    addNativeSSOEvent(`User clicks on 'Log in using SSO'`);
    history.push('/native-sso');
  };
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      sx={{
        borderColor: 'neutral.main',
      }}
    >
      <Icon.Key size={24} sx={{ marginRight: '8px' }} />
      {t('authentication.nativeSSO.loginCTA')}
    </Button>
  );
};
