import type { CLIENT_PAYROLL_INPUT_FILE_TYPE, FileToUpload } from '@/types/GlobalPayroll/GlobalPayrollEventDocuments';
import { downloadLocalBlobFile } from '@/utils/file';
import instance from '@/utils/api/instance';
import isUndefined from 'lodash/isUndefined';
import type {AxiosRequestConfig} from 'axios';

export interface FileToArchive {
  fileId: string;
}

export interface FileToUpdate {
  fileId: string;
  type: CLIENT_PAYROLL_INPUT_FILE_TYPE;
  description?: string;
}
export const baseClientInputFilesUrl = '/global_payroll/client_payroll_input_file';

export default {
  upload: async (payrollEventId: string, files: FileToUpload[]): Promise<{ id: string; name: string }[]> => {
    const formData = new FormData();

    files.forEach(({ file }) => formData.append('files', file));

    const metadata = files.reduce((acc, file) => {
      acc[file.fileName] = {
        type: file.fileType,
        description: file.fileDescription,
      };

      if (file.automationId) {
        acc[file.fileName].automationId = file.automationId;
      }

      if (!isUndefined(file.replaceExistingFiles)) {
        acc[file.fileName].replaceExistingFiles = file.replaceExistingFiles;
      }
      return acc;
    }, {} as Record<string, { type?: CLIENT_PAYROLL_INPUT_FILE_TYPE | ''; description?: string; automationId?: string; replaceExistingFiles?: boolean }>);

    formData.append('metadata', JSON.stringify(metadata));

    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      suppressSnackbar: true,
    };

    const { data } = await instance.post(`${baseClientInputFilesUrl}/${payrollEventId}/upload_files`, formData, config);

    return data;
  },

  archive: async (payrollEventId: string, file: FileToArchive): Promise<{ id: string }[]> => {
    const { data } = await instance.post(`${baseClientInputFilesUrl}/${payrollEventId}/archive_file`, file);

    return data;
  },

  update: async (payrollEventId: string, fileData: FileToUpdate): Promise<{ id: string }[]> => {
    const { data } = await instance.post(`${baseClientInputFilesUrl}/${payrollEventId}/update_file`, fileData);

    return data;
  },

  updateMultiple: async (
    payrollEventId: string,
    fileIds: string[],
    type: CLIENT_PAYROLL_INPUT_FILE_TYPE,
    description?: string
  ): Promise<{ id: string }[]> => {
    const { data } = await instance.post(`${baseClientInputFilesUrl}/${payrollEventId}/update_multiple_files`, {
      fileIds,
      type,
      description,
    });

    return data;
  },

  download: async (payrollEventId: string, fileIds: string[]): Promise<{ id: string }[]> => {
    const response = await instance.get(`${baseClientInputFilesUrl}/${payrollEventId}/download_files`, {
      params: { ids: fileIds },
      responseType: 'arraybuffer',
    });

    const contentDisposition = response.headers['content-disposition'];

    let fileName = `file_${new Date().toISOString()}`;

    if (contentDisposition) {
      const match = contentDisposition.match(/filename="?([^"]+)"?/);
      if (match && match.length > 1) {
        fileName = match[1];
      }
    }

    const blob = new Blob([response.data], { type: response.headers['content-type'] });

    downloadLocalBlobFile(blob, fileName);

    return response.data;
  },
};
