import { useMemo } from 'react';
import { getSafeAreaInsetValue } from '@/native/SafeAreaContainer/utils';
import { Edges } from '@/types/Edges';

interface SafeAreaInsetValues {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

const useSafeAreaInsets: () => SafeAreaInsetValues = () => {
  return useMemo(
    () => ({
      top: getSafeAreaInsetValue(Edges.TOP),
      bottom: getSafeAreaInsetValue(Edges.BOTTOM),
      left: getSafeAreaInsetValue(Edges.LEFT),
      right: getSafeAreaInsetValue(Edges.RIGHT),
    }),
    []
  );
};

export default useSafeAreaInsets;
