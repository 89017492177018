import axios from '@/utils/api/instance';
import uploadApi from '@/utils/api/upload';
import filesApi from '@/utils/api/files';
import AppStore from '@/stores/AppStore';
import i18next from 'i18next';

export const download = (data: string | number | boolean, name: string, type = 'text/plain') => {
  const element = document.createElement('a');
  element.setAttribute('href', `data:${type};charset=utf-8,` + encodeURIComponent(data));
  element.setAttribute('download', name);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const downloadByUrl = (url: string, name?: string, newTab?: boolean) => {
  const element = document.createElement('a');
  if (name) element.setAttribute('download', name);
  if (newTab) element.setAttribute('target', '_blank');

  element.setAttribute('href', url);
  element.style.display = 'none';

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const downloadLocalBlobFile = (blob: Blob, filename?: string) => {
  const url = URL.createObjectURL(blob);
  downloadByUrl(url, filename || 'download');
};

export const base64ToFile = (dataUrl: string, filename: string) => {
  const array = dataUrl.split(',');
  const mime = array[0].match(/:(.*?);/);
  if (mime !== null) {
    const mimeType = mime[0];
    const byteString = atob(array[1]);
    let size = byteString.length;
    const uint8Array = new Uint8Array(size);

    while (size--) {
      uint8Array[size] = byteString.charCodeAt(size);
    }

    return new File([uint8Array], filename, { type: mimeType });
  }
};

export const base64toBlob = (b64Data: string, contentType: string = '', sliceSize: number = 512) => {
  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  const isBase64 = base64regex.test(b64Data);
  if (!isBase64) {
    return new Blob([b64Data]);
  }
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const FETCH_DELAY = 1000;
const FIND_FILE_ATTEMPTS = 30;

const defaultCheckUploadFn = async (key: string, fileExistsFn?: (key: string) => Promise<any>) => {
  const fileExistsFunction = fileExistsFn || filesApi.exists;
  for (let i = 0; i < FIND_FILE_ATTEMPTS; i++) {
    const res = await fileExistsFunction(key);

    if (res?.exists) {
      return;
    }

    await new Promise((resolve) => setTimeout(resolve, FETCH_DELAY));
  }

  throw new Error('Could not find file');
};
const fileExtensionToMime: Record<string, string> = {
  heic: 'image/heic',
  pts: 'text/plain',
};
export const getFileType = (file: File) =>
  file?.type
    ? file.type?.replace(':', '').replace(';', '')
    : fileExtensionToMime[file?.name?.replace(/.+\.(.+)/, '$1').toLowerCase()] || '';
export const fileToBlob = async (file: File) =>
  new Blob([new Uint8Array(await file.arrayBuffer())], { type: getFileType(file) });
export const fileToDataURL = (file: File) =>
  new Promise<string | ArrayBuffer | null>((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(file);
  });

export const convertHeicType = async (file: File) => {
  const blob = await fileToBlob(file);
  const heic2any = await import('heic2any');
  try {
    const convertedBlob = (await heic2any.default({ blob, toType: 'image/jpeg' })) as Blob;
    return new File([convertedBlob], removeFileExtension(file?.name).concat('.jpeg'), { type: 'image/jpeg' });
  } catch (error: any) {
    // unfortunately, the library doesn't provide a specific code for this error
    // so we need to check the message text
     
    if (error?.message?.includes('Image is already browser readable')) return file;
    throw error;
  }
};

export interface UploadFileResponse {
  key: string;
  url: string;
  convertedData?: string | ArrayBuffer | null;
  fields: any;
}

export interface UploadFileItemArgs {
  file: File;
  onUploadProgress?: (args: any) => void;
  getSignedUrl?: (filename: string, type?: string) => Promise<any>;
  checkUpload?: (key: string) => Promise<any>;
  fileExistsApiCall?: (key: string) => Promise<any>;
}

export const uploadFileItem = async ({
  file,
  onUploadProgress,
  getSignedUrl,
  checkUpload,
  fileExistsApiCall,
}: UploadFileItemArgs) => {
  const checkUploadFn = checkUpload || ((key: string) => defaultCheckUploadFn(key, fileExistsApiCall));

  return uploadFile(file, onUploadProgress, getSignedUrl, checkUploadFn);
};

export const uploadFile = async (
  file: File,
  onUploadProgress?: (args: any) => void,
  getSignedUrlFn = uploadApi.uploadForm,
  checkUploadFn = defaultCheckUploadFn,
  fileSizeLimit?: number
): Promise<UploadFileResponse> => {
  try {
    const fileType = getFileType(file);
    const { url, key, fields } = await getSignedUrlFn(
      file?.name?.replace(/heic$/i, 'jpeg'),
      fileType?.replace(/heic$/i, 'jpeg'),
      fileSizeLimit
    );

    const form = new FormData();
    for (const [key, value] of Object.entries(fields)) form.append(key, value as string | Blob);
    let convertedData;

    if (fileType.includes('heic')) {
      const convertedFile = await convertHeicType(file);
      convertedData = await fileToDataURL(convertedFile);
      form.append('file', convertedFile);
    } else {
      form.append('file', file);
    }

    await axios.post(url, form, { onUploadProgress, hideErrorMessageOnNetworkError: true });
    await checkUploadFn(key);

    return { url, key, convertedData, fields };
  } catch (error) {
    const errorData = (error as any)?.response?.data;

    if (typeof errorData === 'string') {
      const errorXML = new DOMParser().parseFromString(errorData, 'application/xml');

      if (errorXML?.querySelector('Error')) {
        let errorMessage =
          errorXML.querySelector('Message')?.innerHTML || i18next.t('common.errors.couldNotUploadFile');

        if (errorXML.querySelector('Code')?.textContent === 'EntityTooLarge') {
          const maxSizeInBytes = parseInt(errorXML.querySelector('MaxSizeAllowed')?.textContent || '');
          if (!isNaN(maxSizeInBytes)) {
            errorMessage = i18next.t('common.errors.fileMustBeSmallerThanMB', {
              size: Math.floor(maxSizeInBytes / Math.pow(2, 20)),
            });
          } else {
            errorMessage = i18next.t('common.errors.thisFileIsTooLarge');
          }
        }

        AppStore.setErrorMessage(errorMessage);
      } else {
        AppStore.setErrorMessage(i18next.t('common.errors.failedToUploadFile'));
      }
    } else {
      AppStore.setErrorMessage(i18next.t('common.errors.failedToUploadFile'));
    }

    console.error(error);
    throw error;
  }
};

export function bytesToSize(bytes: number, showDecimals: boolean = true, base: number = 1024) {
  const units = ['bytes', 'KB', 'MB'];
  const decimals = showDecimals ? 2 : 0;

  if (bytes === 0) {
     
    return '0 bytes';
  }

  const exponent = Math.floor(Math.log(bytes) / Math.log(base));
  const value = bytes / Math.pow(base, exponent);

  return `${value.toFixed(decimals)} ${units[exponent]}`;
}

export function getFileExtension(filename: string) {
  const filenameArray = filename.split('.');
  return filenameArray.length > 1 ? `.${filenameArray[filenameArray.length - 1]}` : '';
}

export function removeFileExtension(filename: string) {
  const lastDotIndex = filename.lastIndexOf('.');
  if (lastDotIndex !== -1) {
    return filename.substring(0, lastDotIndex);
  }
  return filename;
}

export const getDocumentViewerType = (fileName: string): 'pdf' | 'image' =>
  getFileExtension(fileName) === '.pdf' ? 'pdf' : 'image';

export enum FileType {
  JPG = 'JPG',
  PNG = 'PNG',
  GIF = 'GIF',
  PDF = 'PDF',
  ZIP = 'ZIP',
  CSV = 'CSV',
  HEIC = 'HEIC',
  DOCX = 'DOCX',
  XLSX = 'XLSX',
  DOC = 'DOC',
  XLS = 'XLS',
  TXT = 'TXT',
  JSON = 'JSON',
}

// https://wiki.deel.network/en/deel-workspace/engineering/teams/infra-eng/How-to-Upload-Service
export const fileTypeNameToMimeType: Record<FileType, { mime: string[] }> = {
  [FileType.JPG]: {
    mime: ['image/jpg', 'image/jpeg'],
  },
  [FileType.PNG]: {
    mime: ['image/png'],
  },
  [FileType.GIF]: {
    mime: ['image/gif'],
  },
  [FileType.PDF]: {
    mime: ['application/pdf'],
  },
  [FileType.ZIP]: {
    mime: ['application/zip', 'application/x-zip-compressed'],
  },
  [FileType.CSV]: {
    mime: ['text/csv'],
  },
  [FileType.HEIC]: {
    mime: ['image/heic', 'image/heif'],
  },
  [FileType.DOCX]: {
    mime: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  },
  [FileType.XLSX]: {
    mime: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  },
  [FileType.DOC]: {
    mime: ['application/msword'],
  },
  [FileType.XLS]: {
    mime: ['application/vnd.ms-excel'],
  },
  [FileType.TXT]: {
    mime: ['text/plain'],
  },
  [FileType.JSON]: {
    mime: ['application/json'],
  },
};

export const getFileTypeFromMimeType = (mimeType: string) => {
  const fileType = Object.entries(fileTypeNameToMimeType).find(([, { mime }]) => mime.includes(mimeType));
  return fileType ? (fileType[0] as FileType) : null;
};
