import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Stack, useSnackbar, Icon, H4, Button, type ModalRef, Modal, MuiBox, useTheme } from '@letsdeel/ui';
import { useTranslation } from 'react-i18next';
import { FormBuilderPreview } from '@letsdeel/ui/dist/shared';
import { useAllCountriesOptions } from '@/scenes/Immigration/hooks/useAllCountriesOptions';
import type { FormBuilderQuestionType } from '@letsdeel/ui/dist/types/FormBuilder';
import type { FormikValues } from 'formik';
import useUserStore from '@/hooks/useUserStore';

const SURVEY_SNACKBAR_KEY = 'SURVEY_SNACKBAR_KEY';
export const LS_INTEREST_SURVEY_SKIPPED_KEY = 'interest_survey_skipped';

export const useOrganizationInterestSurvey = () => {
  const { showSnackbar, hideSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { zIndex } = useTheme();
  const surveyModalRef = useRef<ModalRef>();
  const countries = useAllCountriesOptions();
  const isSkippedOnCurrentSession = localStorage.getItem(LS_INTEREST_SURVEY_SKIPPED_KEY);
  const { organization } = useUserStore();

  const isSurveyAwaiting = organization?.hrisSurveys?.length;

  const handleOpenSurveyModal = () => {
    surveyModalRef?.current?.open();
    hideSnackbar(SURVEY_SNACKBAR_KEY);
  };

  const handleCloseSurveyModal = useCallback(async () => {
    surveyModalRef?.current?.close();
    // TODO API request for skip filling survey form, https://letsdeel.atlassian.net/browse/HRACT-935
    // TODO instead of 'isSurveyExpired = true' do the API request for get 'isSurveyExpired' info, https://letsdeel.atlassian.net/browse/HRACT-932
    const isSurveyExpired = true;
    const skippingMessage = isSurveyExpired
      ? t('home.components.clientHomePage.notAskAgain')
      : t('home.components.clientHomePage.askLater');

    showSnackbar(skippingMessage, 'success');
    // since localStorage value are inside the useEffect dependencies (isSkippedOnCurrentSession), putting localStorage update into macro-task queue for not erasing last snackbar message immediately
    setTimeout(() => {
      localStorage.setItem(LS_INTEREST_SURVEY_SKIPPED_KEY, LS_INTEREST_SURVEY_SKIPPED_KEY);
    }, 2000);
  }, [showSnackbar, t]);

  const handleSubmitSurveyForm = useCallback(
    async (_data: FormikValues) => {
      // TODO API request for sending data, https://letsdeel.atlassian.net/browse/HRACT-935
      surveyModalRef?.current?.close();
      showSnackbar(t('home.components.clientHomePage.thanksForSubmitting'), 'success', {
        variant: 'success',
        key: 'sss',
      });
    },
    [showSnackbar, t]
  );

  useEffect(() => {
    if (isSurveyAwaiting && !isSkippedOnCurrentSession) {
      showSnackbar(
        <Stack direction="row" alignItems="center">
          <Icon.QuizFilled size={24} />
          <H4 sx={{ borderRight: '1px solid #7C8286', paddingRight: 2, marginLeft: 1 }}>
            {t('home.components.clientHomePage.hrToolsTitle')}
          </H4>
          <Button sx={{ color: '#9BCDFB' }} onClick={handleOpenSurveyModal}>
            {t('home.components.clientHomePage.hrToolsStartSurvey')}
          </Button>
        </Stack>,
        'success',
        {
          variant: 'success',
          onClose() {},
          key: SURVEY_SNACKBAR_KEY,
        }
      );

      return () => hideSnackbar(SURVEY_SNACKBAR_KEY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSnackbar, isSurveyAwaiting, t, isSkippedOnCurrentSession]);

  const InterestSurveyModal = useMemo(
    () => (
      <Modal
        ref={surveyModalRef}
        modalProps={{ fullScreen: true }}
        contentProps={{ sx: { padding: 0, overflowY: 'hidden', backgroundColor: 'background.default' } }}
        closeButton={false}
      >
        <MuiBox
          sx={{
            width: '100%',
            height: '100%',
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: zIndex?.tooltip,
            visibility: 'visible',
            overflowY: 'hidden',
            padding: 0,
          }}
        >
          <FormBuilderPreview
            // TODO remove mock data after https://letsdeel.atlassian.net/browse/HRACT-933
            formData={{
              description: 'Form with all questions description',
              name: 'Form with all questions name',
              pages: [
                {
                  id: 'niofiwofeiwgobehtrrnimgorepnf',
                  sections: [
                    {
                      id: 'niognrtgjuyjuyrenoigregmo',
                      questions: [
                        {
                          id: 'ougbrtnoingkiukluioggreerigeergopre',
                          isRequired: true,
                          title: 'Applicant name? (required)',
                          type: 'FreeText' as FormBuilderQuestionType,
                        },
                        {
                          helperText: '',
                          id: 'ougbrtnoingoloierignergoprve',
                          isRequired: false,
                          title: 'Applicant last name? (not required)',
                          type: 'FreeText' as FormBuilderQuestionType,
                        },
                      ],
                      subtitle: 'FreeText section subtitle',
                      title: 'Applicant',
                    },
                    {
                      id: 'ngiorengin2gceqwfwe43gi43mp',
                      questions: [
                        {
                          helperText: 'Paragraph helper text',
                          id: 'ngioreng4jcfrexq93gn034gm34v',
                          isRequired: true,
                          title: 'Short description of your application',
                          type: 'Paragraph' as FormBuilderQuestionType,
                        },
                      ],
                      subtitle: '',
                      title: 'Paragraph section',
                    },
                  ],
                  title: 'First page',
                },
              ],
              subtitle: 'Form with all questions subtitle',
              tempTitle: '',
              title: 'Form with all questions title',
            }}
            isAdminView={true}
            countries={countries}
            onAutoSave={() => {}}
            onSubmit={handleSubmitSurveyForm}
            onExit={handleCloseSurveyModal}
          />
        </MuiBox>
      </Modal>
    ),
    [countries, handleCloseSurveyModal, handleSubmitSurveyForm, zIndex?.tooltip]
  );

  return {
    InterestSurveyModal,
  };
};
