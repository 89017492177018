import useUserStore from '@/hooks/useUserStore';
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { COLOR_MODE } from '@/scenes/Settings/components/WhiteLabel/constants';
import { useLocation, useRouteMatch } from 'react-router';
import { ROUTES } from '@/constants/routesV2';
import { contextForWhiteLabel, type ContextForWhiteLabel, type WhiteLabelContextBody } from './types';
import { useFetchWhiteLabelSettingsByToken } from './useFetchWhiteLabelSettingsByToken';
import useLookupsStore from '@/hooks/useLookupsStore';
import { parseJwt, updateFavicon } from '@/utils/main';
import type { State } from '@/scenes/Login/utils';
import { isHexadecimalColor, isValidHttps } from '@/scenes/Settings/components/WhiteLabel/validation';

const DEFAULT_WHITE_LABEL_FEATURES: WhiteLabelContextBody['features'] = {};

export const WhiteLabelContext = createContext<WhiteLabelContextBody>({
  isWhiteLabelEnabled: false,
  isWhiteLabelLoading: true,
  showCircularProgress: true,
  primaryColor: null,
  logos: null,
  isWhiteLabelEmpty: true,
  features: DEFAULT_WHITE_LABEL_FEATURES,
});

const WHITE_LABEL_DISABLED_VALUES: WhiteLabelContextBody = {
  isWhiteLabelEnabled: false,
  isWhiteLabelLoading: false,
  showCircularProgress: false,
  primaryColor: null,
  logos: null,
  isWhiteLabelEmpty: true,
  features: DEFAULT_WHITE_LABEL_FEATURES,
};

export const WhiteLabelProvider = ({ children }: { children: ReactNode }) => {
  const user = useUserStore();
  const lookups = useLookupsStore();
  const isWhiteLabelFeatureEnabled = lookups.getFeatureFlag('whiteLabel.isPlatformExperienceForEorEnabled');
  const location = useLocation<State>();

  const matchCreateProfile = useRouteMatch<{ token: string }>(`${ROUTES.createProfile}/:token?`);
  const matchSignUp = useRouteMatch<{ token: string }>(ROUTES.signUp.withToken);
  const matchContractorSignUp = location.state?.token && location.state?.userType === 'contractor';

  const token = matchCreateProfile?.params.token || matchSignUp?.params.token || location.state?.token || user?.token;
  const decodedToken = token ? parseJwt(token) : null;

  const userContextToFetch: ContextForWhiteLabel = useMemo(() => {
    if (decodedToken?.sub && Object.values(contextForWhiteLabel).includes(decodedToken?.sub)) return decodedToken.sub;
    if (user?.token) return 'employee_logged_in';
    if (matchCreateProfile?.params.token || matchSignUp?.params.token) return 'employee';
    if (matchContractorSignUp) return 'contractor_invite';

    return 'login';
  }, [decodedToken, matchContractorSignUp, matchCreateProfile?.params.token, matchSignUp?.params.token, user?.token]);

  const isEorEmployee = user.isEmployee;
  const isUserContractor = user.isContractor;
  const usersThatWhiteLabelShouldBeEnabledFor = isEorEmployee || isUserContractor;

  const { data, isLoading, isWhiteLabelDataEmpty } = useFetchWhiteLabelSettingsByToken(token, userContextToFetch);

  const isWhiteLabelEnabled = useMemo(
    () => usersThatWhiteLabelShouldBeEnabledFor && isWhiteLabelFeatureEnabled && !isWhiteLabelDataEmpty,
    [isWhiteLabelDataEmpty, isWhiteLabelFeatureEnabled, usersThatWhiteLabelShouldBeEnabledFor]
  );

  const primaryColor = useMemo(() => {
    return isWhiteLabelEnabled
      ? {
          [COLOR_MODE.LIGHT_MODE]: isHexadecimalColor(data?.lightModeColorPrimary)
            ? data?.lightModeColorPrimary
            : undefined,
          [COLOR_MODE.DARK_MODE]: isHexadecimalColor(data?.darkModeColorPrimary)
            ? data?.darkModeColorPrimary
            : undefined,
        }
      : null;
  }, [isWhiteLabelEnabled, data?.lightModeColorPrimary, data?.darkModeColorPrimary]);

  const logos = useMemo(() => {
    return isWhiteLabelEnabled
      ? {
          [COLOR_MODE.LIGHT_MODE]: {
            sm: isValidHttps(data?.lightModeLogoSmall) ? data?.lightModeLogoSmall : undefined,
            md: isValidHttps(data?.lightModeLogo) ? data?.lightModeLogo : undefined,
          },
          [COLOR_MODE.DARK_MODE]: {
            sm: isValidHttps(data?.darkModeLogoSmall) ? data?.darkModeLogoSmall : undefined,
            md: isValidHttps(data?.darkModeLogo) ? data?.darkModeLogo : undefined,
          },
        }
      : null;
  }, [isWhiteLabelEnabled, data]);

  const organizationId = useMemo(() => {
    const currentOrganization = user?.organizations?.find(
      (org) => org?.organizationPublicId === data?.organizationPublicId
    );
    return isWhiteLabelEnabled ? currentOrganization?.id : undefined;
  }, [data?.organizationPublicId, isWhiteLabelEnabled, user?.organizations]);

  const isWhiteLabelEmpty = useMemo(
    () => !logos?.lightMode?.sm && !logos?.lightMode?.md && !logos?.darkMode?.sm && !logos?.darkMode?.md,
    [logos]
  );

  const features = useMemo<WhiteLabelContextBody['features']>(
    () => data?.features ?? DEFAULT_WHITE_LABEL_FEATURES,
    [data]
  );

  if (isWhiteLabelEnabled) updateFavicon(data?.lightModeLogoSmall);

  if (!isWhiteLabelFeatureEnabled) {
    return <WhiteLabelContext.Provider value={WHITE_LABEL_DISABLED_VALUES}>{children}</WhiteLabelContext.Provider>;
  }

  return (
    <WhiteLabelContext.Provider
      value={{
        isWhiteLabelEnabled,
        isWhiteLabelLoading: isLoading,
        showCircularProgress: !isWhiteLabelEnabled && isLoading,
        primaryColor,
        logos,
        businessName: isWhiteLabelEnabled ? data?.businessName : undefined,
        organizationId,
        publicOrganizationId: data?.organizationPublicId,
        isWhiteLabelEmpty,
        features,
      }}
    >
      {children}
    </WhiteLabelContext.Provider>
  );
};

export const useWhiteLabelContext = () => {
  const context = useContext(WhiteLabelContext);

  if (context === undefined) {
    throw new Error('useWhiteLabelContext must be used within a WhiteLabelProvider');
  }

  return context;
};
