import { ROUTES } from '@/constants/routesV2';
import { useFetchWithHeaders } from '../../useFetch';
import { contextForWhiteLabel, type ContextForWhiteLabel } from '@/context/WhiteLabel/types';
import type { CustomBrandingSettings } from './types';
import useUserStore from '@/hooks/useUserStore';
import type { State } from '@/scenes/Login/utils';
import { parseJwt } from '@/utils/main';
import { useMemo } from 'react';
import { useLocation, useRouteMatch } from 'react-router';

const VALID_HOSTNAME_REGEX = /^.*\.deel\.(cafe|team)$/;

export const useFetchCustomBrandingSettingsPublic = () => {
  const location = useLocation<State>();
  const user = useUserStore();

  const matchCreateProfile = useRouteMatch<{ token: string }>(`${ROUTES.createProfile}/:token?`);
  const matchSignUp = useRouteMatch<{ token: string }>(ROUTES.signUp.withToken);
  const matchContractorSignUp = location.state?.token && location.state?.userType === 'contractor';

  const token = matchCreateProfile?.params.token || matchSignUp?.params.token || location.state?.token || user?.token;
  const decodedToken = token ? parseJwt(token) : null;

  const context: ContextForWhiteLabel = useMemo(() => {
    if (decodedToken?.sub && Object.values(contextForWhiteLabel).includes(decodedToken?.sub)) return decodedToken.sub;
    if (user?.token) return 'employee_logged_in';
    if (matchCreateProfile?.params.token || matchSignUp?.params.token) return 'employee';
    if (matchContractorSignUp) return 'contractor_invite';
    return 'login';
  }, [decodedToken, matchContractorSignUp, matchCreateProfile?.params.token, matchSignUp?.params.token, user?.token]);

  const hostname =
    VALID_HOSTNAME_REGEX.test(window.location.hostname) && window.location.hostname !== 'localhost'
      ? window.location.hostname
      : null;

  const headers: Record<string, string> = token ? { 'x-custom-branding-token': token } : {};

  const { data, error, isLoading, mutate } = useFetchWithHeaders<Partial<CustomBrandingSettings>>(
    `/custom_branding_settings?context=${context}${hostname ? `&hostname=${hostname}` : ''}`,
    headers
  );

  const isCustomBrandingDataEmpty = !data || data?.status?.toLowerCase() !== 'enabled' || !!error;

  return { data, error, isCustomBrandingDataEmpty, isLoading, mutate };
};
