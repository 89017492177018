import type { EnumKeyAsString } from './../helpers';
export enum EmployeeInfoFieldRequiredByEnum {
  sign_up = 'sign_up',
  contract_signing = 'contract_signing',
  before_payroll = 'before_payroll',
  after_payroll = 'after_payroll',
}

export const BeforeSigningStatuses = [
  EmployeeInfoFieldRequiredByEnum.sign_up,
  EmployeeInfoFieldRequiredByEnum.contract_signing,
];
export const PayrollInfoStatuses = [
  EmployeeInfoFieldRequiredByEnum.before_payroll,
  EmployeeInfoFieldRequiredByEnum.after_payroll,
];

export type EmployeeInfoFieldRequiredBy = EnumKeyAsString<EmployeeInfoFieldRequiredByEnum>;

export type DependencyOperator = 'gt' | 'notnull' | 'contains';
interface DependencyWithExpression {
  op: DependencyOperator;
  value: string | number;
}

export const isDependencyWithExpression = (obj: string | DependencyWithExpression): obj is DependencyWithExpression => {
  return (obj as DependencyWithExpression).op !== undefined;
};
export interface EmployeeInfoField {
  dependencies?: {
    [index: string]: string | { op: DependencyOperator; value: string | number };
  };
  disabledForUpdate?: boolean;
  description?: string;
  excludeForGlobalPayroll?: boolean;
  optionalForGlobalPayroll?: boolean;
  onlyForGlobalPayroll?: boolean;
  excludeFrom?: string[];
  footnote?: string;
  howToObtain?: string;
  inputType: string;
  isHidden?: boolean;
  isOptional?: boolean;
  label: string;
  linkToObtain?: string;
  linkToObtainLabel?: string;
  localName?: string;
  options?: string[]; //when inputType is select
  placeholder?: {
    key: string;
    default: string;
  };
  regex?: RegExp;
  requiredBy?: EmployeeInfoFieldRequiredBy;
  notEditableByUser?: boolean; // restricting user by editing the field
  sensitive?: boolean;
}
