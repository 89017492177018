import * as Yup from 'yup';

export const PASSWORD_POLICY_LIST = [
  {
    id: 'length',
    label: 'temp.platform-fe.components.PasswordPolicy.between10And70Characters',
    regex: /^.{10,70}$/,
  },
  {
    id: 'upper_case',
    label: 'temp.platform-fe.components.PasswordPolicy.anUppercaseCharacter',
    regex: /(?=.*[A-Z])/,
  },
  {
    id: 'lower_case',
    label: 'temp.platform-fe.components.PasswordPolicy.aLowercaseCharacter',
    regex: /(?=.*[a-z])/,
  },
  {
    id: 'number',
    label: 'temp.platform-fe.components.PasswordPolicy.aNumber',
    regex: /[0-9]{1,}/,
  },
  {
    id: 'special',
    label: 'temp.platform-fe.components.PasswordPolicy.aSpecialCharacter',
    regex: /[^a-zA-Z0-9]{1,}/,
  },
];

// BASE PASSWORD SCHEMA
const PASSWORD_SCHEMA = Yup.string()
  .min(10, 'temp.platform-fe.components.PasswordPolicy.passwordRequiredToBeAtLeast10CharactersLong')
  .max(70, 'temp.platform-fe.components.PasswordPolicy.passwordCanNotBeLongerThan70Characters')
  .matches(/[a-z]{1,}/, 'temp.platform-fe.components.PasswordPolicy.passwordRequiredToHaveAtLeast1LowercaseLetter')
  .matches(/[A-Z]{1,}/, 'temp.platform-fe.components.PasswordPolicy.passwordRequiredToHaveAtLeast1UppercaseLetter')
  .matches(/[0-9]{1,}/, 'temp.platform-fe.components.PasswordPolicy.passwordRequiredToHaveAtLeast1Number')
  .matches(
    /[^a-zA-Z0-9]{1,}/,
    'temp.platform-fe.components.PasswordPolicy.passwordRequiredToHaveAtLeast1SpecialCharacter'
  )
  .required('temp.platform-fe.components.PasswordPolicy.newPasswordIsRequired');

// PASSWORD SHOULD NOT CONTAIN USER EMAIL OR PREVIOUS PASSWORD
export const getPasswordSchema = ({ email, prevPassword }: { email?: string | null; prevPassword?: string | null }) => {
  if (!email || typeof email !== 'string') return PASSWORD_SCHEMA;

  return PASSWORD_SCHEMA.test(
    'Password cannot contain user email address',
    'Password cannot contain user email address',
    (value) => !value?.includes(email)
  ).test('Password must be different than previous one', 'Password must be different than previous one', (value) =>
    prevPassword ? !value?.includes(prevPassword) : true
  );
};
