/* ================================================================================= *
 * Auto generated interface using MOCK data                                          *
 * Some properties might be missing                                                  *
 * Using any for phone and citizen- need to migrate old users to the new format      *
 * Using any for userProfile- Need to type GET profile api                           *
 * ================================================================================= */
import type { FeatureFlags } from '@/constants/featureFlags';
import type { ClientKycStatus } from '@/constants/kyc';
import type { OnboardingStatus, SignupData } from '@/constants/organization';
import type { VeriffStatus } from '@/constants/veriffStats';
import type { Advance } from '@/features/advance/types';
import type { WalletBalance } from '@/features/card/types';
import type { CustomFieldsTasks } from '@/hooks/api/people/useFetchCustomFieldsTasks';
import type { AccountTypes } from '@/scenes/Signup/constants';
import type { UserTimeTrackingConfig } from '@/scenes/TimeAttendance/types';
import type { VerificationLetterConfig } from '@/stores/UserStore';
import type { EngageFeature } from '@/utils/api/engage/engage';
import type { VisaApplicant } from '@/utils/api/mobility';
import type { ClientBalance, IPaymentMethodV2, PaymentOption } from '@/utils/api/payments/mainV2';
import type { PermissionNameType } from '@/utils/authorization/permissions';
import type { CountryCode } from '@letsdeel/ui';
import type { PermissionName } from './Authorization';
import type { AcknowledgedWseNoticeDate, PeoApplication } from './PEO';
import type { ITeamItem } from './Teams';
import type { WalletProvider } from './Withdraw';

export interface IUserStore {
  permissionsToRoles: Record<PermissionName, unknown>;
  paperTaxForm: boolean;
  dob: string;
  dateOfBirthConsent?: boolean;
  employmentCountry?: string; //this field is set only for EOR employees
  eorContractCurrency?: string;
  scimPermissionConfig: {
    hasAccess: boolean;
    hasSalesForceOwnerAccount: boolean;
    isOrganizationAdmin: boolean;
  };
  hasAttemptedVeriff: boolean;
  addedNewMethod: boolean;
  advance?: Advance;
  primaryDocumentType: string;
  showWithdrawCompletePopup: boolean;
  selectedBalance?: WalletBalance;
  token: string | null;
  switchingToken: boolean;
  country: string;
  isAffiliate: boolean;
  isDeelOrAffiliate: boolean;
  id: number;
  hashid: string;
  email: string;
  backupEmail: string;
  isBackupEmailVerified: boolean;
  isBackupEmailEnabled: boolean;
  name: string;
  firstName: string;
  middleName: string;
  lastName: string;
  preferredName?: string;
  preferredFirstName?: string;
  preferredLastName?: string;
  activeContractsCount: number;
  availableContractsCount: number;
  picUrl: string | null;
  // returns true if user has read-only access
  readOnly?: boolean;
  profileType: AccountTypes;
  companyId?: number;
  company: Company | null;
  compliance: string;
  suspected: boolean;
  team: ITeamItem | null;
  teams: Teams[];
  allTeamsSelected: boolean;
  organization: Omit<Organization, 'department'> | null;
  dashboard?: {
    isFirstLogin?: boolean;
    hasDeelCard?: boolean;
    hideOnboarding?: boolean;
    isPeopleListEnabled?: boolean;
    isPeopleViewPopupShown?: boolean;
  } | null;
  backgroundCheck?: {
    isEligible: boolean;
    hasPendingInvoices: boolean;
    isCDDApproved: boolean;
    account: {
      status: 'ACTIVE' | 'WAITING_ACCOUNT_CREATION' | 'DENIED';
      creator: {
        name: string;
        email: string;
      };
    };
  } | null;
  showLimitError?: boolean;
  fullName: string;
  companyData: string;
  isLoggedIn: boolean;
  isAccountCreated: boolean;
  createdAt: string;
  verifiedAt?: string;
  hasShareholders: boolean;
  hasVerifiedEmail: boolean;
  hasTaxAdviceAccess: boolean;
  hasContractorWorkedForUsa: boolean;
  hasContractorWorkedFromUsa: boolean;
  hasUsaLegalEntity: boolean;
  isAdvanceEnabled: boolean;
  hasPendingOrOutstandingAdvance: boolean;
  hasPreviousAdvances: boolean;
  isAccountant: boolean;
  isEor: boolean;
  isGPEnabled: boolean;
  isVendor: boolean;
  isClient: boolean;
  isDeeler: boolean;
  isDirectEmployee: boolean;
  isDirectEmployeeFromUS: boolean;
  isHrisDirectEmployee: boolean;
  isFinancialDataHidden: boolean;
  isAnyEmployee: boolean;
  isContractor: boolean;
  isPeoEmployee: boolean;
  isIndividual: boolean;
  isOnboarding: boolean;
  isOnboardingContractor: boolean;
  isOnboardingClient: boolean;
  isOnboardingPeoEmployee: boolean;
  isOnboardingEmployee: boolean;
  isOnboardingDirectEmployee: boolean;
  isShieldProvider: boolean;
  canBecomeClient: boolean;
  jobTitle: string;
  isEmployee: boolean;
  isICP: boolean;
  hideOnboarding: boolean;
  isUSTaxPayer: boolean;
  isUS: boolean;
  isDeelUser: boolean;
  isHrisReportsEnabled: boolean;
  isAnalyticsLiteEnabled: boolean;
  checkIfAnalyticsLiteIsEnabled: () => Promise<void>;
  hasPeoBenefitWindows: boolean;
  getPeoBenefitsWindows: () => Promise<void>;
  isKycNameChangeInProgress: boolean;
  kycNameChangeScreening: KycNameChangeScreening | null;
  isKYCApproved: boolean;
  isKYCPendingReview: boolean;
  isSignContractorWalletAgreementTaskPending: boolean;
  isClientBalanceEnabledForOrganization: boolean;
  isNewPaymentColumnsEnabledForOrganization: boolean;
  enablePaymentCurrencyForClientBalance: boolean;
  isAutoBillingEnabledForOrganization: boolean;
  isEorEarlyInvoicingEnabledForOrganization: boolean;
  isNewPaymentFlowEnabled: boolean;
  isClientBalanceAsClientMethodEnabledForOrganization: boolean;
  isDepositIntoBalanceExchangeEnabledForOrganization: boolean;
  isOrgKYCApproved: boolean;
  canWithdraw: boolean;
  canAddWithdrawalMethod: boolean;
  hasClientAccount: boolean;
  hasContractorAccount: boolean;
  paid: number;
  earned: string;
  massPayment: MassPayment | null;
  defaultCurrency: string;
  paymentMethods: string;
  selectedWithdrawMethodIndex: number;
  withdrawMethods: WithdrawMethod[];
  archivedWithdrawMethods: WithdrawMethod[];
  wallets: Wallet[];
  withdrawMethodsError: any | null;
  clientPaymentMethodsV2: IPaymentMethodV2[];
  paymentOptions: PaymentOption[] | null;
  clientBalances: ClientBalance[] | null;
  setClientBalance: (balance: ClientBalance) => void;
  clientBalance: ClientBalance | null;
  balance: string;
  balances: WalletBalance[];
  activeBalances: WalletBalance[];
  currency: string;
  balancesTotal: WalletBalance | null;
  processingWithdrawals: any[];
  processingInvoices: ProcessingInvoice[];
  profilePublicId?: string;
  isAutoWithdrawEnabled: boolean;
  isEarlyPayoutEnabled: string;
  totp: boolean;
  otpOptions: Array<OtpProvider>;
  isEorEnabled: boolean;
  isChangingToEntity: boolean;
  isChangingCompanyType: boolean;
  isChangingOrganization: boolean;
  kyc: string;
  isKYCSpecified: boolean;
  kycScreening: KycScreening | null;
  kycTracker: KycTracker | null;
  paymentLimit: number | null;
  countries: any[];
  showClearApprovalQueuePopup: boolean;
  canSubmitTranslation: boolean;
  screeningId: number | null;
  customVerificationStatus: string;
  translationSubmitted: boolean;
  userId: number;
  userPublicId: string;
  username: string;
  signature: string;
  signatureUrl: string | null;
  deel?: string;
  deelFeePercent: string;
  timezone: string;
  flags: number;
  invitationMessage: null;
  Company: null;
  CustomVerificationScreening?: {
    status: string;
  };
  organizations: Organization[] | null;
  organizationProfile: OrganizationProfile | null;
  numberOfTeams: number;
  taxForms: any[];
  availableProfiles: AvailableProfile[];
  displayRevolutBanner: boolean;
  signupRoute: string;
  hasReferralsPermission: boolean;
  hasBackgroundChecksEnabled: boolean;
  isPayrollConnectEnabled?: { entityId: string; isEnabled: boolean }[];
  hasPayrollConnectHomeScreen: boolean;
  employeeExternalId: string | null;
  countriesOptions: { label: string; value: string }[];
  switchingProfile?: boolean;
  isOrganizationOwner: boolean;
  features?: { [key: string]: SegmentFeatureFlags };
  mainOrganization: Omit<Organization, 'department'> | Organization | undefined;
  hrisId: string | undefined;
  isNativeOnboardingComplete?: boolean;
  // Methods
  setCdd1ScreeningStatus: ({ status }: { status: CddScreeningStatus }) => void;
  setCdd2ScreeningStatus: ({ status }: { status: CddScreeningStatus }) => void;
  setShowClearApprovalQueuePopup: (value: boolean) => void;
  setWithdrawMethodIndex: (index: number) => void;
  setPaymentMethodsV2: (methods: IPaymentMethodV2[]) => void;
  update: (userProfile: any) => void;
  getMyProfile: () => Promise<void>;
  logout: (idle: boolean) => Promise<void>;
  clearDataForProfileSwitch: () => Promise<void>;
  getPaymentDetails: () => Promise<void>;
  getAccountBalances: () => Promise<void>;
  getWithdrawMethods: () => Promise<void>;
  getWallets: () => Promise<void>;
  myWallet: (provider: WalletProvider) => Wallet | undefined;
  userType: UserType;
  switchProfile: (opt?: { [key: string]: string | number }, query?: string) => Promise<void>;
  switchUserByEmail: (email: string) => Promise<void>;
  switchBackToClient: (token: string) => Promise<void>;
  switchEORProfile: (country: string, profileId: number, resetLocation?: boolean) => Promise<void>;
  switchHrisOrganization: (orgId: string) => Promise<any>;
  resolveCompanyPersonalProperty: <T extends keyof Personal>(property: T) => Personal[T];
  hasPermission: (permission: PermissionNameType, teamId?: string | number) => boolean;
  hasRole: (roleId: number | number[], teamId?: string | number) => boolean;
  getCustomverification: () => Promise<void>;
  getPaymentMethodsV2: (rootInvoiceId?: string) => Promise<void>;
  getPaymentOptions: (rootInvoiceId: string) => Promise<void>;
  resetPaymentOptions: () => void;
  getClientBalances: () => Promise<void>;
  isFeatureEnabledForOrganization: (featureKey: FeatureFlags, organizationId?: number) => boolean;
  isFeatureEnabledByContract: (featureKey: FeatureFlags, contractId?: number) => boolean;
  changeActiveBalance: (balance: WalletBalance) => void;
  /**
   * @deprecated use isFeatureEnabled instead as it will also check for org ids.
   */
  isFeatureEnabledOld: (featureKey: FeatureFlags) => boolean;
  isFeatureEnabled: (featureKey: FeatureFlags) => boolean;
  isHrisFeatureEnabled: (key?: HrisScopes, organizationId?: number) => boolean;
  isNewTransactionEnabled: () => boolean;
  isHrisScopeEnabled: (key?: HrisScopes, organizationId?: number) => boolean;
  isDeelHREnabled: (organizationId?: number) => boolean;
  isSeniorityJobTitleEnabled: boolean;
  isFetchingPaymentMethods: boolean;
  isFetchingPaymentMethodsV2: boolean;
  isFetchingPaymentOptions: boolean;
  isFetchingClientBalances: boolean;
  isErrorFetchingClientBalances: boolean;
  isDeelOrgClient: () => boolean;
  hasTeams?: boolean;
  source: string;
  isKYCScreeningNeeded: boolean;
  isEorKycRequired: boolean;
  hrisProfileIds: HrisProfileId[];
  isHrisKycRequired: boolean;
  hris: {
    Contracts: {
      id: string;
      oid: string;
      contractType?: string;
      status?: string;
    }[];
    id: number;
    oid: string;
    slackUserId: string | null;
    organization?: Partial<Organization>;
    displayName?: string | null;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    preferredName?: string | null;
    preferredFirstName?: string | null;
    preferredLastName?: string | null;
    workEmail?: string;
  };
  mobility?: {
    companyId?: string;
    applicantId?: string;
    numberOfOpenCases?: number;
    numberOfClosedCases?: number;
    documentsInWalletCount?: number;
    switchData?: 'OLD' | 'NEW' | 'SWITCH';
  };
  isPeopleDirectoryVisible: boolean;
  isNewHiringStatusEnabled: boolean;
  clear: () => void;
  getAddress: (type?: 'company' | 'invoice' | 'team') => string;
  isTimeOffEnabled: (hrisProfileOid?: string) => boolean;
  customFieldsTasks?: CustomFieldsTasks | null;
  setCustomFieldsTasks: (data?: CustomFieldsTasks) => void;
  lastLoginMethod?: string;
  hasPasswordAsLoginMethod: boolean;
  isLastLoginIDP: boolean;
  hasTimeTrackingContract?: boolean;
  timeTracking?: UserTimeTrackingConfig;
  withdrawalCurrency?: string;
  withdrawalAmount?: number;
  withdrawalTransactionId?: number;
  peoEmployeeProfile?: PeoEmployeeProfile;
  hiddenOrgsForVerificationLetter: Array<VerificationLetterConfig>;
  withdrawEligibilityInfo: {
    enabled: boolean;
    reason?: DisabledWithdrawReason;
  };

  // @todo: check if we have these fields returned
  blocked?: boolean;
  estimatedArrival?: string;
  isDeelCardEnabled?: boolean;
  isDepartmentCustomField: boolean;
  kycIdentityResubmissionScreening?: {
    id: number;
    status: string;
    type: 'customVerificationIdentityResubmission';
  };
  kycExpirationDetails?: {
    expiredAt: string;
    screeningType: string;
  };
  isSelectedAllTeamsSidebar?: boolean;
  isContractorsOutsideDeelEnabled?: boolean;
  sensitiveFields?: string[];
  hasOptedOutOf2FA?: boolean;

  setIsSelectedAllTeamsSidebar(value: boolean): void;

  unreadNotificationsCount: number;
  requiredTaxForm?: {
    contractorType: string;
    formType: string;
  };
  setUnreadNotificationsCount: (count: number) => void;
  isInsightsEnabled: boolean;
  hrisOrganizationUser: {
    id: string;
  } | null;
  hrisOrganizationUserId: string | null;
  isLocationSectionHidden: boolean;
  engageFeatures?: EngageFeature[];
  isEngageFeatureEnabled: (feature: EngageFeature['slug']) => boolean;
  isMultiRateContractsFeatureEnabled: boolean;
  isContractExtensionFeatureEnabled: boolean;
  appExperience?: {
    isMinimalRequirements: boolean;
  };
  isAnyContractActive: boolean;
  isLightweightExperience: boolean;
  isEngageProductActivationUserActive: boolean;
  isUserActiveWithProductActivation: () => boolean;
}

export interface PeoEmployeeProfile {
  id: number;
  createdAt: string;
  updatedAt: string;
  deelProfileId: number;
  ethnicity: string | null;
  disability: string | null;
  veteranStatus: string | null;
  acceptedElectronicConsentFill: boolean;
  acceptedElectronicConsentFillDate: string | null;
  isWaitingForSSN: boolean;
  unionStatus: string | null;
  emergencyContactName: string | null;
  emergencyContactEmail: string | null;
  emergencyContactRelationship: string | null;
  emergencyContactPhone: string | null;
  acknowledgedWSENoticeDate: AcknowledgedWseNoticeDate;
}

export type OtpProvider = 'app' | 'email' | 'sms';

export enum HrisScopes {
  publicPeopleList = 'publicPeopleList',
  fullAccess = 'fullAccess',
}

export interface Screening {
  status: string;
  screeningAt: Date;
}

export interface AvailableProfile {
  id: number;
  profile_type: AccountTypes;
  country: CountryCode;
  employee: boolean;
  is_eor: boolean;
  company?: Pick<Company, 'personal'>;
  link?: string;
  internalEorName: string;
  last_login?: string | null;
  profilePublicId: string;
  OrganizationProfiles?: {
    Organization: { id: string; isDeelAdminLoginEnabled: boolean };
    id: number;
  }[];
}

export interface Shareholder {
  id: number;
  name: string;
  kyc: string;
  type: string;
  parentShareholderId?: number;
  Screening?: Screening;
  CustomVerificationScreening?: Screening;
  IdentityProfile?: Partial<IUserStore>;
}

export interface Company {
  citizen?: { value: unknown };
  type: string;
  registration: unknown;
  invoice?: Invoice;
  entityType: string;
  country: string;
  province: string;
  state?: string;
  street: string;
  street2: string;
  city: string;
  zip: string;
  legalCompanyName: string;
  registeredNumber?: string;
  shareholders: any[];
  phone: any;
  timezone: string;
  personal: Personal;
  abn?: string;
  signedAddress?: string | null;
  addressValid?: boolean;
  addressConfirmedByUser?: boolean;
  postal?: {
    country?: string;
    street?: string;
    city?: string;
    zip?: string;
    state?: string;
    province?: string;
    signedAddress?: string | null;
    addressValid?: boolean;
    addressConfirmedByUser?: boolean;
  };
  company?: {
    country?: string;
    street?: string;
    city?: string;
    zip?: string;
    province?: string;
  };
  preferredShipmentAddress?: 'personal' | 'postal' | 'company';
}

export interface Invoice {
  legalCompanyName: string;
  firstName: string;
  lastName: string;
  street: string;
  street2?: string;
  city: string;
  country: string;
  zip: string;
  phone: any;
  vatId: string;
  province: string;
  taxId: string;
  note: string;
  email?: string;
  showSignature?: boolean;
  isDefaultSignature?: boolean;
  signatureFileName?: string;
}

export interface Personal {
  firstName: string;
  middleName: string;
  lastName: string;
  preferredName: string;
  preferredFirstName: string;
  preferredLastName: string;
  birthDate: string;
  citizen: any;
  country: string;
  street: string;
  street2?: string;
  city: string;
  zip: string;
  province: string;
  state?: string;
  phone: any;
  taxResidence: string;
  entityTaxResidence: string;
  personalId: string;
  ssn: string;
  legalStatus: string;
  timezone: string;
  idType: unknown;
  abn?: string;
  signedAddress?: string | null;
  addressValid?: boolean;
  addressConfirmedByUser?: boolean;
}

export type SavedAddress = Pick<Personal, 'phone' | 'city' | 'province' | 'street' | 'state' | 'zip' | 'country'>;

export interface Phone {
  number: number;
}

export interface KycScreening {
  status: string;
  isPending: boolean;
  responseStatus: VeriffStatus;
  attempts: number;
}

export interface KycTracker {
  currentStep: string;
  steps: KycTrackerStep[];
}

export type KycStepStepT =
  | 'AWAITING_SUBMISSION'
  | 'DOCUMENTS_SUBMITTED'
  | 'AWAITING_REVIEW'
  | 'VERIFICATION_FAILED'
  | 'VERIFICATION_COMPLETED';
export type KycStepStatusT = 'PENDING' | 'IN_PROGRESS' | 'FAILED' | 'COMPLETED';

export interface KycTrackerStep {
  status: KycStepStatusT;
  step: KycStepStepT;
  title: string;
  description: string;
  completedAt?: string;
}

export interface KycNameChangeScreening {
  status: string;
  rejectedUserInput: boolean | null;
  type: string;
  id: number;
  screeningAt: string;
}

export interface MassPayment {
  amount: string;
  deelFee: string;
  total: string;
  currency: string;
}

export type CddScreeningStatus = 'pending_review' | 'approved' | 'rejected';

interface LegalEntitiesRoles {
  id: string;
  roleId: number;
  name: string;
  permissionSets: string[];
}

export interface Organization {
  id: number;
  organizationPublicId: string;
  name: string;
  globalPayrollEnabled: boolean;
  activeGlobalPayrollIntegrations: string[];
  isAutoBillingEnabled: boolean;
  isUsePaymentCurrencyInClientBalanceEnabled: boolean;
  isEorEarlyInvoicingEnabled: boolean;
  isLegalEntitiesCalendarEnabled: boolean;
  isApiEnabled: boolean;
  isInsightsEnabled: boolean;
  department?: string;
  logoUrl: string | null;
  currentOnboardingStatus: OnboardingStatus;
  signupData: SignupData;
  hrisScopes: HrisScopes;
  screeningStatus: ClientKycStatus;
  cdd1Screening: {
    id?: number;
    status: CddScreeningStatus;
    productsOrServicesDescription?: string | null;
  };
  cdd2Screening: {
    id?: number;
    status: CddScreeningStatus;
  };
  websiteURL: string;
  linkedInURL: string;
  canEditUrls: boolean;
  isCdd1Required: boolean;
  isCdd2Required: boolean;
  isScreeningPendingReview: boolean;
  isScreeningRejected: boolean;
  hrisAccessStatusChangedAt: Date;
  canContractorEnd: boolean;
  hasMilestoneContracts: boolean;
  financialPOCFullName?: string;
  financialPOCEmail?: string;
  hrisEnabled: boolean;
  hrisDirectoriesVisibility: {
    analytics: boolean;
    org_chart: boolean;
    people: boolean;
    pto: boolean;
  };
  pricing: {
    backgroundCheck: { fee: string };
    premium: { fee: string; type: 'CUSTOM' | 'PRESET'; warrantyAmount: number | null };
    mobility: { fee: string };
  };
  isPeopleDirectoryVisible: boolean;
  isClientBalanceEnabled?: boolean;
  isAlviereAchEnabled?: boolean;
  isAdyenCardEnabled?: boolean;
  isNewPaymentFlowEnabledV3?: boolean;
  isGPAutoBillingEnabled?: boolean;
  isShieldAutoBillingEnabled?: boolean;
  isNewPaymentCurrenciesEnabled?: boolean;
  isPrefundingEnabled?: boolean;
  showBannerToAddAlviereMethod?: boolean;
  shouldUseNewPaymentColumns?: boolean;
  isDepositIntoBalanceExchangeEnabled?: boolean;
  isClientBalanceAsClientMethodEnabled?: boolean;
  plugins?: {
    [key: string]: {
      enabled: boolean;
    };
  };
  peoApplication?: PeoApplication | null;
  customRolesEnabled?: boolean;
  hrisContractorOutsideDeelEnabled?: boolean;
  isAdditionalRolesEnabled?: boolean;
  legalEntities: LegalEntitiesRoles[];
  hasLegalEntities: boolean;
  hasDraftLegalEntities?: boolean;
  hasProcessingLegalEntities?: boolean;
  eorAmendmentsV2Enabled?: boolean;
  eaRedliningAllowed?: boolean;
  eorEALibraryEnabled?: boolean;
  formationDate?: string | null;
  isWorkforceSizeFilingRequired?: boolean;
  amendmentApprovalInvitationEnabled?: boolean;
  hrisSurveys?: string[];
}

export interface OrganizationProfile {
  id: number;
  role: string;
  roleId: number;
  permissionSets: string[];
  department: string | null;
  jobTitle: string | null;
  jobSeniorityId: number | null;
}

export interface ProcessingInvoice {
  status: string;
  paymentMethod: string;
  createdAt: string;
  amount: string;
  id: string;
  currency: string;
  paymentCurrency: string;
  payload: unknown;
}

export interface UserTeam {
  id: number;
  name: string;
  isAutoPayEnabled: boolean;
  organization: Organization;
  role: string;
  owner: boolean;
  massApprove: null;
}

export interface Teams {
  id: number;
  teamPublicId: string;
  name: string;
  deelFeePercent: string;
  isAutoPayEnabled: boolean;
  massApprove: null;
  saasFee: SaasFee;
  eORs: any[];
  organization: Organization;
  teamProfiles: any;
}

export interface SaasFee {
  amount: string;
  type: string;
}

export interface UserResponse {
  applicant: VisaApplicant;
  id: number;
  ICPId?: number;
  userId: number;
  userPublicId: string;
  profilePublicId: string;
  username: string;
  name: string;
  email: string;
  company: UserResponseCompany;
  // returns true if user has read-only access
  readOnly?: boolean;
  profileType: string;
  signature: string;
  deelFeePercent: string;
  isAutoWithdrawEnabled: boolean;
  timezone: string;
  currency: string;
  flags: number;
  dashboard: UserResponseDashboard;
  isEor: boolean;
  jobTitle: string;
  isEmployee: boolean;
  suspected: boolean;
  paymentLimit: number;
  deel: string;
  teams: any[];
  organizations: Organization[];
  organization: Organization | null;
  numberOfTeams: number;
  countries: any[];
  isEorEnabled: boolean;
  canBecomeClient: boolean;
  taxForms: any[];
  isEarlyPayoutEnabled: boolean;
  isAdvanceEnabled: boolean;
  hasPendingOrOutstandingAdvance: boolean;
  hasAcceptedGetHiredProgram: boolean;
  isDeelCardEnabled: boolean;
  compliance: UserResponseCompliance;
  availableProfiles: UserResponseAvailableProfiles[];
  kyc: string;
  kycScreening: UserResponseKycScreening;

  displayRevolutBanner: boolean;
  signupRoute: string;
  lastLoginData: UserResponseLastLoginData;
  canAccessScimConfig: boolean;
  hashid: string;
  peoEmployeeProfile?: PeoEmployeeProfile;
}

export interface UserResponseCompanyPersonal {
  firstName: string;
  lastName: string;
  street: string;
  city: string;
  country: string;
  province: string;
  zip: string;
  birthDate: string;
  taxResidence: string;
  citizen: string;
  personalId: string;
  idType: string;
}

export interface UserResponseCompanyInvoice {
  firstName: string;
  lastName: string;
  street: string;
  city: string;
  country: string;
  province: string;
  zip: string;
  phone: string;
  showSignature: boolean;
  isDefaultSignature: boolean;
  addressValid?: boolean;
  signedAddress?: string | null;
}

export interface UserResponseCompany {
  type: string;
  personal: UserResponseCompanyPersonal;
  shareholders: any[];
  invoice: UserResponseCompanyInvoice;
  phone: string;
  timezone: string;
  street: string;
  city: string;
  country: string;
  province: string;
  zip: string;
}

export interface UserResponseDashboard {
  hasDeelCard?: boolean;
}

export interface UserResponseCompliance {
  missing: number;
  available: number;
}

export interface UserResponseAvailableProfiles {
  id: number;
  profile_type: string;
  country: string;
  employee: boolean;
}

export interface UserResponseKycScreening {
  status: string;
  isPending: boolean;
}

export interface UserResponseLastLoginData {
  password: string;
}

export type UserType =
  | 'DIRECT_EMPLOYEE'
  | 'ICP'
  | 'EMPLOYEE'
  | 'CLIENT'
  | 'CONTRACTOR'
  | 'EOR'
  | 'HRIS_DIRECT_EMPLOYEE'
  | 'TAX_ADVISOR'
  | 'PEO_EMPLOYEE'
  | 'VENDOR';

export interface WithdrawMethod {
  uuid: string;
  payload: WithdrawMethodPayload;
  paymentMethod: string;
  status: string;
  methodName: any;
  estimatedArrival: string;
  currency: string;
  valid?: boolean;
  createdAt: string;
  deletedAt?: string;
  validationError?: string;
  validations?: MethodValidation[];
  fundsDistribution: number;
  methodChainId: string;
}

export type Wallet = {
  provider?: string;
  status: 'ACTIVE' | 'PENDING' | 'ACCEPTED_TNC';
  id: string;
  created_at: string;
};

export interface MethodValidation {
  validatedAt: string;
  type: MethodValidationType;
  status: MethodValidationStatus;
  data?: Record<string, any>;
}

export enum MethodValidationStatus {
  SCHEDULED = 'scheduled',
  IN_PROGRESS = 'in_progress',
  FAILED = 'failed',
  SUCCESSFUL = 'successful',
}

export enum MethodVerificationStatus {
  PENDING = 'PENDING',
  MANUAL_REJECTED = 'MANUAL_REJECTED',
  AUTO_REJECTED = 'AUTO_REJECTED',
  MANUAL_APPROVED = 'MANUAL_APPROVED',
  AUTO_APPROVED = 'AUTO_APPROVED',
}

export enum MethodValidationType {
  MANUAL = 'manual',
  PROVIDER = 'provider',
  E2E = 'e2e',
  VERIFICATION = 'verification',
}

export interface Validation {
  methodUuid: string;
  type: MethodValidationType;
  data?: Record<string, any> | null;
  status: MethodValidationStatus;
  validatedAt: string;
}

export interface AdditionalInfo {
  maxAllowedUpdates: number;
  canUpdate: boolean;
}

export interface WithdrawMethodPayload {
  bic?: string;
  deel: number;
  name: string;
  type?: string;
  email: string;
  address?: Address;
  currency: string;
  legalType?: string;
  accountNumber?: string;
  iban?: string;
  selectedMethod?: string;
  withdrawalCurrency?: string;
  phone?: string;
  paypal?: string;
  binancePayId?: string;
  cardNumber?: string;
  dolarTag?: string;
  revtag?: string;
  pixDocumentId?: string;
  pixEmail?: string;
  pixPhone?: string;
  pixBankAccount?: string;
  bank?: {
    account_name: string;
    bank_name: string;
    accountNumber?: string;
    iban?: string;
    IBAN?: string;
    swift?: string;
  };
  details?: {
    BIC: string;
    IBAN: string;
    accountHolderName: string;
    address: Partial<Address>;
    currency: string;
    legalType: string;
    accountNumber: string;
    sortCode: string;
  };
  fircNeeded?: boolean;
  payload?: Record<string, unknown>;
  methodName?: string;
}

export interface Address {
  city: string;
  country: string;
  postCode: string;
  state?: string;
  firstLine: string;
}

export interface SegmentFeatureFlags {
  keys: string;
  result: SegmentFeatureFlagsResult[];
}

export interface SegmentFeatureFlagsResult {
  isEnabled: boolean;
  model: 'organization' | 'profile' | 'team' | 'contract' | 'user';
  id: number;
  oid?: string;
}

interface DirectReport {
  id: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  workEmail?: string;
}

export interface HrisProfileId {
  oid: string;
  organizationId: number;
  isActive?: boolean;
  currency?: string;
  directReports?: DirectReport[];
}

export enum DisabledWithdrawReason {
  USER_BLOCKED = 'USER_BLOCKED',
  SANCTIONED = 'SANCTIONED',
  KYC_REJECTED = 'KYC_REJECTED',
  W9_MISSING = 'W9_MISSING',
  SSN_MISSING = 'SSN_MISSING',
  TAX_ID_MISSING = 'TAX_ID_MISSING',
  PROOF_OF_LOCATION_REQUIRED = 'PROOF_OF_LOCATION_REQUIRED',
}
