import type { LegalEntity, PaymentMethod, PAYROLL_DEPOSIT_STATUS } from '@/types/LegalEntity';
import type { PayrollSettings, Reviewer } from '@/types/PayrollSettings';
import type { EmploymentPayrollEvent } from '../EmploymentPayrollEvent';
import type { PayrollReportColumn, PayrollReportColumnUnitOfMeasure } from '../PayrollReportColumn';
import type { FrequencyKeys } from '../EmployeeRecurringItem';
import type { CustomerDeliverable } from '@/types/GlobalPayroll/types';
import type { Payer } from './ThirdParties';

export const PAYROLL_EVENT_TYPE = {
  REGULAR: 'REGULAR',
  OFFCYCLE: 'OFFCYCLE',
  HISTORICAL: 'HISTORICAL',
  PARALLEL: 'PARALLEL',
  RECONCILIATION: 'RECONCILIATION',
  CORRECTION: 'CORRECTION',
} as const;

export const PAYROLL_EVENT_TYPE_WITH_CORRECTION = {
  REGULAR: 'REGULAR',
  OFFCYCLE: 'OFFCYCLE',
  HISTORICAL: 'HISTORICAL',
  PARALLEL: 'PARALLEL',
  RECONCILIATION: 'RECONCILIATION',
  CORRECTION: 'CORRECTION',
} as const;

export enum PAYOUT_TYPE {
  ONE_OFF = 'oneOff',
  SALARY = 'salary',
}

export type PayrollEventType = (typeof PAYROLL_EVENT_TYPE)[keyof typeof PAYROLL_EVENT_TYPE];
export type PayrollEventTypeWithCorrection =
  (typeof PAYROLL_EVENT_TYPE_WITH_CORRECTION)[keyof typeof PAYROLL_EVENT_TYPE_WITH_CORRECTION];

export type PayrollTypedField = Omit<
  PayrollReportColumn,
  | 'searchableString'
  | 'category'
  | 'countries'
  | 'type'
  | 'itemOrder'
  | 'categoryOrder'
  | 'subCategoryOrder'
  | 'supportedInPayrollReport'
  | 'isGlobal'
  | 'appearsOnG2N'
> & {
  unit?: string;
};

export type PayrollField = PayrollTypedField & {
  disabled: boolean;
  isRecurring: boolean;
  isRemoved?: boolean;
  prevValue?: any;
  countries?: string[];
};

export type ReducedPayrollItemContent = {
  value: number;
  name: string;
  currentPayrollItemId?: string;
  payrollEventId: string;
  previousPayrollEventId: string;
  hasAdjustments: boolean;
};

export type ReducedPayrollReportItems = {
  [key: string]: ReducedPayrollItemContent;
};

export type UpdatePayrollPayload = {
  payrollReportItems: {
    payrollReportColumnId: string;
    value: number;
    isRecurringItem?: boolean;
    startDate?: Date | null;
    endDate?: Date | null;
    frequency?: FrequencyKeys | '';
  }[];
};

export type DeletePayrollItemPayload = {
  payrollReportItems: { payrollReportColumnId: string }[];
};

export type BulkUpdatePayrollErrorType =
  | 'unknownEmployees'
  | 'invalidFormat'
  | 'forbiddenEdit'
  | 'missingColumns'
  | 'unknownColumns'
  | 'employeeNumbersNotMatching';

export type BulkUpdatePayrollsResponseErrors = Record<
  BulkUpdatePayrollErrorType,
  Record<string, { name: string; index: number }[]> | { name: string }[] | { name: string; index: number }[]
>;

export type BulkUpdatePayrollsResponse = {
  eventUpdatedAt?: string;
  changedRowsCount?: number;
  emptyRowsCount?: number;
  isPublicApiDataEdited?: boolean;
  errors?: BulkUpdatePayrollsResponseErrors;
  csvParseFail?: true;
};

export enum PAYROLL_EVENT_STATE {
  NEW = 'NEW',
  REPORT_PROCESSING = 'REPORT_PROCESSING',
  REPORT_SUBMITTED = 'REPORT_SUBMITTED',
  PENDING_REVIEW = 'PENDING_REVIEW',
  PENDING_PACKAGE_APPROVAL = 'PENDING_PACKAGE_APPROVAL',
  AWAITING_SELF_PAYMENT = 'AWAITING_SELF_PAYMENT',
  AWAITING_GP_PAYMENT = 'AWAITING_GP_PAYMENT',
  PROCESSED_BY_EXTERNAL_PROVIDER = 'PROCESSED_BY_EXTERNAL_PROVIDER',
  COMPLETE = 'COMPLETE',
}

export const isEventApproved = ({ actionState }: { actionState: PAYROLL_EVENT_STATE }) =>
  actionState === PAYROLL_EVENT_STATE.AWAITING_SELF_PAYMENT ||
  actionState === PAYROLL_EVENT_STATE.AWAITING_GP_PAYMENT ||
  actionState === PAYROLL_EVENT_STATE.COMPLETE;

interface IProfile {
  name: string;
  id: number;
}

export interface IUpdater {
  id: number;
  email?: string;
  Profiles: IProfile[];
}

export enum PayrollVersionStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export interface PayrollVersion {
  rejectedByDeel: boolean | null;
  payrollEventId: string;
  version: number;
  status: PayrollVersionStatus;
  submittedBy: number | null;
  submittedAt: string | null;
  submittedByUser: IUpdater | null;
  approvedBy: number | null;
  approvedAt: string | null;
  approvedByUser: IUpdater | null;
  publishedBy: number | null;
  publishedAt: string | null;
  publishedByUser: IUpdater | null;
  rejectedBy: number | null;
  rejectedAt: string | null;
  rejectedByUser: IUpdater | null;
  rejectionReason: string | null;
}

export enum ReviewStatus {
  CAN_SUBMIT = 'CAN_SUBMIT',
  CAN_APPROVE = 'CAN_APPROVE',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  WAITING_FOR_SUBMISSION = 'WAITING_FOR_SUBMISSION',
  REPORT_SUBMITTED = 'REPORT_SUBMITTED',
}

export enum NetSalariesPayer {
  'DEEL_BANK_TRANSFER' = 'DEEL_BANK_TRANSFER',
  'DEEL_DIRECT_DEBIT' = 'DEEL_DIRECT_DEBIT',
  'DEEL_ICP' = 'DEEL_ICP',
  'CLIENT' = 'CLIENT',
  'CLIENT_BANK_FILE' = 'CLIENT_BANK_FILE',
  'CLIENT_ONLY' = 'CLIENT_ONLY',
  'NOT_APPLICABLE' = 'NOT_APPLICABLE',
}

export interface CostWithLiabilities {
  totalCost: string;
  fundingAmount: string;

  netSalariesPaidByDeel: string;
  netSalariesPaidBySelf: string;

  liabilitiesPaidByDeel: string;
  liabilitiesPaidByDeelCount: number;

  liabilitiesPaidBySelf: string;
  liabilitiesPaidBySelfCount: number;

  netSalariesPaymentMethod: Payer;
  netSalariesPayer: NetSalariesPayer | null;
}

export type PayrollEvent = {
  id: string;
  actionState: PAYROLL_EVENT_STATE;
  start: string;
  lock: string;
  end: string;
  payrollCalendar?: {
    payDate: string;
    payrollApprovalDate?: string;
    payrollProcessingDate?: string;
    payrollSubmissionDate?: string;
    cycleStartDate: string;
  };
  paymentsTracker?: PaymentsTrackerInList;
  paymentMethod: PaymentMethod;
  payrollCalendarId?: string;
  ICPId: number;
  payrollLegalEntityId: number;
  employeesCount: number;
  LegalEntity: Pick<
    LegalEntity,
    'id' | 'vatCountry' | 'name' | 'paymentMethod' | 'publicId' | 'payrollOnboardingStatus'
  > & {
    Organization?: { id: number; name: string };
  };
  type: PayrollEventType;

  updatedBy: number | null;
  updatedAt: string | null;
  Updater: IUpdater | null;

  ReportSubmittedAt: string | null;
  ReportSubmitter: IUpdater | null;

  PackageApprovedAt: string | null;
  PackageApprover: IUpdater | null;

  PaymentSubmittedAt: string | null;
  PaymentSubmitter: IUpdater | null;

  paymentDueDate: string;
  payDate: string;
  PayrollDeposit?: { status: PAYROLL_DEPOSIT_STATUS | null } | null;
  PayrollFunding?: PayrollFunding;

  ReportProcessedAt: string | null;
  PayrollApprovedAt: string | null;

  ClientG2NReportSubmittedAt: string | null;
  ClientG2NReportSubmittedBy: string | null;

  cutoffDate?: string;

  payrollSettings: PayrollSettings;
  approvedBy?: {
    payrollReviewer: Reviewer;
  }[];
  missingApprovals?: number[];
  state?: PayrollEventState;
  isCreatedByAdmin: boolean;
  currentVersion?: number;
  reference?: string;
  payrollPackages?: PayrollPackagesCollection;
  payrollCost?: {
    employerCost: number;
    netSalaries: number;
    liabilities: number;
    totalPaidThroughDeel: number;
    totalPaidByClient: number;
  };
};

export type PayrollEventState = 'LOCKED' | 'OPEN' | 'CLOSED' | PAYROLL_EVENT_STATE;

type PayrollActor = { id: number; email: string; Profiles: { id: number; name: string; email: string }[] } | null;

export type GlobalPayrollDoc = {
  packageType?: string;
  uploadedAt: string;
  id: number;
  Uploader: PayrollActor;
  isAtLegalEntityLevel?: boolean;
};

export enum PAYROLL_FUNDING_STATUS {
  AWAITING_DEPOSIT = 'AWAITING_DEPOSIT',
  READY = 'READY',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  COMPLETE = 'COMPLETE',
}

export type PayrollFunding = {
  status: PAYROLL_FUNDING_STATUS;
  amount?: number;
  Invoice?: {
    amount?: number;
    id: number;
    rootInvoiceId: number | null;
    rootInvoicePublicId: string | null;
    paidAt: string | null;
    processedAt: string | null;
    teamId: number;
    invoiceId: string;
  } | null;
};

type DeelG2NReport = {
  id: number;
  Uploader: PayrollActor;
  updatedAt: string;
  itemCount: string;
  liabilities: string;
  netSalaries: string;
  totalCost: string;
};

interface PaymentsTrackerDates {
  fundingPayment: string | null;
  fundingReceipt: string | null;
  payslipPublication: string | null;
  employeePayoutsRelease: string | null;
  payrollCompleted: string | null;
}

interface BasePaymentsTracker {
  effectiveDate: string | null;
  isLive: boolean;
}

export interface PaymentsTracker extends BasePaymentsTracker {
  dates: PaymentsTrackerDates;
  optionalSteps?: {
    employeePayoutsRelease?: boolean;
    payslipPublication?: boolean;
    fundingPayment?: boolean;
    fundingReceipt?: boolean;
  };
}

export interface PaymentsTrackerInList extends BasePaymentsTracker {
  dates: Pick<PaymentsTrackerDates, 'payslipPublication' | 'payrollCompleted' | 'employeePayoutsRelease'>;
}

interface GpCustomerDeliverable {
  id: string;
  packageType: string;
  publishedAt: string;
  uploadedAt: string;
  CustomerDeliverable: CustomerDeliverable;
  isAtLegalEntityLevel?: boolean;
}

export interface LegalEntityLevelPackages {
  icpPayslips: GlobalPayrollDoc[] | null;
  icpG2NReport: GlobalPayrollDoc[] | null;
  payslipsPackage: GlobalPayrollDoc[] | null;
  liabilitiesPackage: GlobalPayrollDoc[] | null;
  deelLiabilitiesReport: GlobalPayrollDoc[] | null;
  generalLedger: GlobalPayrollDoc[] | null;
  gpGeneralLedger: GlobalPayrollDoc[] | null;
  gpGeneralLedgerForMultipleCycles: GlobalPayrollDoc[] | null;
  timeoffReport: GlobalPayrollDoc[] | null;
  timeoffReportBrokenDown: GlobalPayrollDoc[] | null;
  liabilityPayments: GlobalPayrollDoc[] | null;
  salaryPayments: GlobalPayrollDoc[] | null;
  eoyEntityDocuments: GlobalPayrollDoc[] | null;
  expenseReport: GlobalPayrollDoc[] | null;
  parallelRunResults: GlobalPayrollDoc[] | null;
  proofOfPayment: GlobalPayrollDoc[] | null;
  leaveAccrualReport: GlobalPayrollDoc[] | null;
  timeOffUsedReport: GlobalPayrollDoc[] | null;
  accountingSummary: GlobalPayrollDoc[] | null;
  terminationDocs: GlobalPayrollDoc[] | null;
  gpCustomerDeliverable: GpCustomerDeliverable[] | null;
  gpCustomerDeliverables: GpCustomerDeliverable[] | null;
  other: GlobalPayrollDoc[] | null;
}

export interface PayrollEventData extends PayrollPackagesCollection {
  PayrollApprovedAt: string | null;
  actionState: PAYROLL_EVENT_STATE;
  payrollEventVersions?: PayrollVersion[];
  currentVersion?: number;
  fundingExclusion?: { amount: string } | null;
  PayrollFunding?: PayrollFunding | null;
  deelG2NReport?: DeelG2NReport | null;
  // Packages uploaded after the event version was approved, only applicable if the event is versioned (currentVersion != null)
  packagesAfterApproval?: (PayrollPackagesCollection & { legalEntityLevelPackages: LegalEntityLevelPackages }) | null;
  previousEventStart?: string;
  previousEventEnd?: string;
  newlyActiveReportItems?: PayrollReportColumn[];
  newlyDisabledReportItems?: PayrollReportColumn[];
  id: string;
  employmentPayrollEvents: EmploymentPayrollEvent[];
  employeesSummary: {
    starter: number;
    amended: number;
    leaver: number;
    active: number;
    total: number;
    on_leave: number;
  };
  eventEntityItems: PayrollReportColumn[];
  currency: string;
  updatedAt: string;
  start: string;
  end: string;
  rejectedBy?: string;
  rejectionReason?: string;
  payrollPackageStepPayrollSupportRequestsCount: { OPEN: number; CLOSED: number; DRAFT: number } | null;
  isFundingPaymentAccessibleByUser?: boolean;
  thirdPartyFunding?: {
    isEnabled: boolean;
    isLive: boolean;
    isLiveForEvent: boolean | null;
    canBeDisabled: boolean;
    effectiveDate: string | null;
  };
  payrollCost: {
    employerCost: string | 0;
    netSalaries: string | 0;
    liabilities: string | 0;
    totalPaidThroughDeel?: string | 0;
    totalPaidByClient?: string | 0;
  };
  totalPayrollCostsWithDeelLiabilities: CostWithLiabilities | null;
  previousTotalPayrollCostsWithDeelLiabilities: CostWithLiabilities | null;
  type: PayrollEventType;
  paymentsTracker?: PaymentsTracker;
  customerDeliverables?: GpCustomerDeliverable[];
  legalEntityLevelPackages?: LegalEntityLevelPackages;
}

export interface PayrollPackagesCollection {
  // TODO-EMS: add generalLedger and payslips to payroll-event response
  generalLedger?: GlobalPayrollDoc | null;
  gpGeneralLedger?: GlobalPayrollDoc[] | null;
  gpGeneralLedgerForMultipleCycles?: (GlobalPayrollDoc & { payrollEvents: PayrollEvent[] })[] | null;
  proofOfPayment?: GlobalPayrollDoc | null;
  leaveAccrualReport?: GlobalPayrollDoc | null;
  timeOffUsedReport?: GlobalPayrollDoc | null;
  accountingSummary?: GlobalPayrollDoc | null;
  terminationDocs?: GlobalPayrollDoc | null;
  eoyEntityDocuments?: GlobalPayrollDoc | null;
  payslipsPackage?: GlobalPayrollDoc | null;
  parallelRunResults?: GlobalPayrollDoc | null;
  localG2NReportId?: number | null;
  localG2NReport?: GlobalPayrollDoc | null;
  liabilitiesPackage?: GlobalPayrollDoc | null;
  deelLiabilitiesReport?: GlobalPayrollDoc | null;
  liabilityReportId?: number | string | null;
  other?: GlobalPayrollDoc | null;
  otherReportId?: number | null;
  liabilityPayments?: GlobalPayrollDoc | null;
  gpCustomerDeliverables?: GpCustomerDeliverable[];
}

export type AggregatedItemsData = {
  [category: string]: {
    [itemId: string]: {
      label: string;
      id: string;
      unitOfMeasure: PayrollReportColumnUnitOfMeasure;
      subCategory: string;
      canBeRecurring: boolean;
      isAdjustable: boolean;
    };
  };
};

export type PastPayrolls = {
  [key: string]: {
    totals: { [key: string]: number };
    items: { [key: string]: ReducedPayrollItemContent };
  };
};

export type PreviousEventData = {
  pastPayrolls: PastPayrolls;
  removedItems: AggregatedItemsData;
  addedItems: AggregatedItemsData;
};

export enum PAYROLL_REPORT_TYPE {
  PAYROLL_VARIANCE_REPORT = 'PAYROLL_VARIANCE_REPORT',
  TIME_OFF_REPORT = 'TIME_OFF_REPORT',
  EXPENSES_REPORT = 'EXPENSES_REPORT',
}

export enum PayrollFilterFrequency {
  All = 'all',
  Monthly = 'monthly',
  SemiMonthly = 'semi-monthly',
  BiWeekly = 'bi-weekly',
  OffCycle = 'off-cycle',
  Weekly = 'weekly',
}

export type PayrollEventBulkEditHistory = {
  id: string;
  payrollEventId: string;
  createdAt: string;
  name: string;
  uploadedBy: number;
  uploadedData: JSON;
};

export type PreIntegrationContract = {
  employeeNumber?: string;
  hrisProfileOid: string;
  name: string;
  oid: string;
};

export type GlobalPayrollNetSalaryItem = {
  contractOid: string;
  hrisProfileOid: string;
  employeeName: string;
  jobTitle: string;
  netPay: string;
};

export enum LiabilityThirdPartyPayer {
  DEEL_BANK_TRANSFER = 'DEEL_BANK_TRANSFER',
  DEEL_DIRECT_DEBIT = 'DEEL_DIRECT_DEBIT',
  DEEL_ICP = 'DEEL_ICP',
  CLIENT = 'CLIENT',
  CLIENT_ONLY = 'CLIENT_ONLY',
  CLIENT_BANK_FILE = 'CLIENT_BANK_FILE',
}

export type GlobalPayrollLiabilityItem = {
  id: string;
  thirdPartyId: string;
  thirdPartyName: string;
  thirdPartyLabel: string;
  thirdPartyPayer: LiabilityThirdPartyPayer;
  amount: string;
  dueDate: string;
  reference1: string;
  reference2: string;
  deelLiabilitiesReportId: string;
  createdAt: string;
  updatedAt: string;
};
