import instance from '@/utils/api/instance';

export default {
  getToken: async (isCohere?: boolean, conversationId?: number) => {
    const cohereParam = isCohere ? `?isCohere=${isCohere}` : '';
    const conversationIdParam = conversationId ? `${isCohere ? '&' : '?'}deelAIConversationId=${conversationId}` : '';

    const { data } = await instance.get(`/chat${cohereParam}${conversationIdParam}`);
    return data;
  },

  getSSOToken: async (google: string) => {
    const { data } = await instance.post('/zendesk_sso', { google });
    return data;
  },

  getJWTToken: async (email: string, name: string) => {
    const { data } = await instance.post('/zendesk_sso_jwt', {
      email,
      name,
    });
    return data;
  },
};
