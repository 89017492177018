export const BACKGROUND_COLORS: Record<string, string> = {
  purple: '#C4B1F9',
  blue: '#C7E2FE',
  yellow: '#FFE27C',
  creamPurple: '#FEF0D8',
  creamYellow: '#FEF0D8',
  creamBlue: '#FEF0D8',
};

export const SHAPE_COLORS: Record<string, string> = {
  purple: '#A98DF6',
  blue: '#B1D8FC',
  yellow: '#FFCF25',
  creamPurple: '#C4B1F9',
  creamYellow: '#FFCF25',
  creamBlue: '#B1D8FC',
};

export const HIGHLIGHT_COLORS: Record<string, string> = {
  creamPurple: '#C4B1F9',
  creamYellow: '#FFE27C',
  creamBlue: '#B1D8FC',
};
