import { makeObservable, action, observable } from 'mobx';
import UserStore from './UserStore';
import teamApi from '@/utils/api/team';

export class TeamStore {
  constructor() {
    makeObservable(this, {
      teams: observable,
      teamsLoaded: observable,
      mutate: observable,
      setMutate: action,
      loadingSchedule: observable,
      schedule: observable,
      loadTeams: action,
      getTeam: action,
      getTeamSchedule: action,
      updateTeams: action,
    });
  }

  teams = [];
  teamsLoaded = false;
  loadingSchedule = false;
  schedule = {};
  mutate = null;

  setMutate = (mutate) => {
    this.mutate = mutate;
  };

  updateTeams = (teams) => {
    this.teams = teams;
  };

  loadTeams = async () => {
    try {
      this.teams = await teamApi.getAll();
      this.teamsLoaded = true;
      if (this.mutate) {
        this.mutate('/team');
        UserStore.organizations.forEach(({ id }) => this.mutate(`/organizations/${id}/teams`));
      }
    } catch (error) {
      console.error(error);
    }
  };

  getTeam = async (id, fromCache) => {
    if (!id) return;
    const team = this.teams.find((value) => Number(value.id) === Number(id) || value.teamPublicId === id);
    if (fromCache && team) return team;

    const data = await teamApi.getTeam(id);

    if (team) return Object.assign(team, data);
    this.teams.push(data);
    return data;
  };

  getTeamSchedule = async (id) => {
    this.loadingSchedule = true;
    try {
      const data = await teamApi.getSchedule(id);

      this.schedule = data;

      return data;
    } catch (err) {
      console.error(err);
    } finally {
      this.loadingSchedule = false;
    }
  };

  findTeamInTeams = (teamId) => {
    return this.teams?.find((t) => t.id === teamId || t.teamPublicId === teamId);
  };
}

export default new TeamStore();
