const animate = ({
  ref,
  duration = 1,
  animation,
  fillMode = 'forwards',
  callback,
  callbackTimeout = duration * 1000,
}) => {
  if (!ref) return;

  // Remove animationName
  if (ref.style.animationName) {
    ref.style.className = animation;
  }

  // Add animation
  setTimeout(() => {
    ref.style.animationDuration = `${duration}s`;
    ref.style.animationName = animation;
    ref.style.animationFillMode = fillMode;

    if (callback) {
      setTimeout(() => {
        callback();
      }, callbackTimeout);
    }
  }, 0);
};

export default animate;
