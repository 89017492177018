import { H4, Stack } from '@letsdeel/ui';
import React from 'react';
export const LoginSeparator = () => {
  return (
    <Stack sx={{ flexDirection: 'row', alignItems: 'center', paddingRight: '44.5px', paddingLeft: '44.5px' }}>
      <Stack
        sx={{
          backgroundColor: 'divider',
          height: '1px',
          width: '100%',
        }}
      />
      <H4
        sx={{
          margin: '0 32px 0 32px',
          fontWeight: 400,
        }}
      >
        or
      </H4>
      <Stack
        sx={{
          backgroundColor: 'divider',
          height: '1px',
          width: '100%',
        }}
      />
    </Stack>
  );
};
