import type { CancelTokenSource, AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { CONTRACT_STATUSES } from '@/constants/contract';
import instance from '@/utils/api/instance';
import type { PaymentMethodsEnum } from '@/types/MassPay';
import type { DeelProduct } from '@/types/Organization';
import type { Company } from '@/types/User';
import type { PERMISSION_FIELDS_PREFIX } from '@/scenes/Settings/scenes/RolesPermissions/CustomRoleForm/components/CustomRolePermissionGroup';
import type { FormValues } from '@/scenes/Settings/scenes/RolesPermissions/CustomRoleForm/CustomRoleForm';
import type { COMPLIANCE_DOCUMENTS_SETTINGS_MAP } from '@/scenes/Settings/scenes/OrganizationSettings/components/ComplianceDocumentsSettingsCard/ComplianceDocumentsSettingsCard';
import type { DeepPartial } from '@/types/DeepPartial';

export type ProductOfInterest = {
  checked: boolean;
  product: DeelProduct;
};

export type ProductsOfInterestResponse = {
  productsOfInterest: ProductOfInterest[];
  hasAccountOwner: boolean;
  hasAccountOMOwner: boolean;
};

export type TeamMembersRequest =
  | {
      id: string;
      role: number;
    }
  | {
      name: string;
      email: string;
      role: number;
    };

export interface IcOrganizationSettings {
  id: string;
  milestones: {
    canContractorAddMilestones: boolean;
    canContractorMarkMilestonesAsComplete: boolean;
    enableMilestoneDueDateReminder: boolean;
    milestoneReminderDueDays?: number;
  };
  terminations: {
    canContractorSetTermination: boolean;
  };
  payg: {
    canClientSubmitPaygWorkReports: boolean;
    canContractorSubmitPaygWorkReports: boolean;
    paygReminderDueDays?: number;
    enablePaygDueDateReminder: boolean;
  };
}

export default {
  loadProfiles: async (organizationId: string | number) => {
    const { data } = await instance.get('organization_profiles', { params: { organizationId } });
    return data;
  },
  loadAgreements: async () => {
    const { data } = await instance.get('agreements');
    return data;
  },
  loadUnsignedAgreements: async () => {
    const { data } = await instance.get('agreements', {
      params: {
        status: [CONTRACT_STATUSES.waitingForClientSign.type],
      },
    });

    return data;
  },
  patchProfiles: async (
    organizationId: string | number,
    roleChanges: Array<{ profileId: string | number; role: string }>,
    config?: AxiosRequestConfig
  ) => {
    const { data } = await instance.patch(
      `organization_profiles`,
      {
        organizationId,
        roleChanges,
      },
      config
    );
    return data;
  },
  unAssignRoles: async (
    payload: {
      scope: string;
      teamId?: string;
      profileId: string;
    }[]
  ) => {
    const data = await instance.post('/roles/assignment/bulk_unassign', payload);
    return data;
  },
  assignRoles: async ({
    profileId,
    changes,
  }: {
    profileId: string;
    changes: Array<{
      scope: string;
      teamId?: string;
      legalEntityId?: string;
      roleId: number;
    }>;
  }) => {
    const data = await instance.post('/roles/assignment', { profileId, changes });
    return data;
  },
  getOrganization: async (organizationId: string | number) => {
    const { data } = await instance.get(`organizations/${organizationId}`);
    return data;
  },
  getContractsInfo: async () => {
    const { data } = await instance.get('organizations/contracts-info');
    return data;
  },
  updateOrganization: async (organizationId: string | number, payload: any) => {
    const { data } = await instance.patch(`organizations/${organizationId}`, payload);
    return data;
  },
  createTeam: async (params: { organizationId: string | number; name: string; company?: Company }) => {
    const { data } = await instance.post('team', params);
    return data;
  },
  cloneTeam: async (params: { teamId: string; name: string }) => {
    const { data } = await instance.post('team/clone', params);
    return data;
  },
  addTeamMembers: (teamId: string | number, members: Array<TeamMembersRequest>) => {
    return instance.patch(`team/${teamId}/role`, members);
  },
  updateName: (name: string, id: string | number, dryRun = false) => {
    const source: CancelTokenSource = axios.CancelToken.source();
    const promise = instance.patch(
      `organizations/${id}`,
      { name, dryRun },
      {
        cancelToken: source.token,
        ...(dryRun ? { suppressSnackbar: true } : {}),
      }
    );
    return { promise, source };
  },
  update: (
    id: string | number,
    payload: {
      name?: string;
      signupData?: Record<string, any>;
      isApiEnabled?: boolean;
      logo?: string | null;
      preferredPaymentMethod?: PaymentMethodsEnum;
    },
    dryRun = false,
    suppressSnackbar = false
  ) => {
    return instance.patch(`organizations/${id}`, { ...payload, dryRun }, { suppressSnackbar });
  },
  switch: async (id: string | number) => {
    const { data } = await instance.post(`organizations/${id}/switch`, {}, { withCredentials: true });
    return data;
  },
  getScim: async (id: string | number) => {
    const { data } = await instance.get(`organizations/${id}/scim`);
    return data;
  },
  deleteLogo: (id: string | number) => {
    return instance.delete(`organizations/${id}/logo`, { suppressSnackbar: true });
  },
  setFinancialPointOfContact: (
    id: string | number,
    payload: { financialPOCFullName: string; financialPOCEmail: string }
  ) => {
    return instance.post(`organizations/${id}/financial_point_of_contact`, payload, { suppressSnackbar: true });
  },
  updateFinancialPointOfContact: (
    id: string | number,
    payload: { financialPOCFullName: string; financialPOCEmail: string }
  ) => {
    return instance.patch(`organizations/${id}/financial_point_of_contact`, payload, { suppressSnackbar: true });
  },
  getProductsOfInterest: async (id: string | number) => {
    const { data } = await instance.get<ProductsOfInterestResponse>(`organizations/${id}/products_of_interest`);
    return data;
  },
  createProductOfInterest: async (
    id: string | number | undefined,
    payload: { products: DeelProduct[]; skip?: boolean }
  ) => {
    return instance.post(`organizations/${id}/products_of_interest`, payload);
  },
  scheduleSalesCall: async (id: string | number) => {
    return instance.post(`organizations/${id}/sales_call_schedule`);
  },
  loadRoles: async (id: string | number) => {
    const { data } = await instance.get(`organizations/${id}/roles`);
    return data;
  },
  createCustomRole: async (
    organizationId: string | number,
    payload: {
      name: string;
      description?: string;
      scope: FormValues['scope'];
      hrisOrgStructureId?: string;
      [PERMISSION_FIELDS_PREFIX]: string[];
    }
  ) => {
    return instance.post(`organizations/${organizationId}/roles`, payload);
  },
  updateCustomRole: async (
    organizationId: string | number,
    customRoleId: string | number,
    payload: {
      name: string;
      description?: string;
      hrisOrgStructureId?: string;
      [PERMISSION_FIELDS_PREFIX]: string[];
    }
  ) => {
    return instance.patch(`organizations/${organizationId}/roles/${customRoleId}`, payload);
  },
  checkCustomRoleApprovers: async (
    organizationId: string | number,
    customRoleId: string | number,
    payload: {
      [PERMISSION_FIELDS_PREFIX]: string[];
    }
  ) => {
    return instance.put(`organizations/${organizationId}/roles/${customRoleId}/check_affected_approvers`, payload);
  },
  deleteCustomRole: async (customRoleId: string | number, organizationId: string | number) => {
    return instance.delete(`/organizations/${organizationId}/roles/${customRoleId}`);
  },
  getSettings: async (organizationId: string | number) => {
    const { data } = await instance.get(`organizations/${organizationId}/settings`);
    return data;
  },
  updateSettings: async (
    organizationId: string | number,
    payload: Array<{
      key: keyof typeof COMPLIANCE_DOCUMENTS_SETTINGS_MAP;
      value: boolean;
    }>
  ) => {
    return instance.post(`organizations/${organizationId}/settings`, payload);
  },
  getIcOrganizationSettings: async () => {
    const { data } = await instance.get<IcOrganizationSettings>('/ic_organization_settings');
    return data;
  },
  updateIcOrganizationSettings: async (payload: DeepPartial<IcOrganizationSettings>) => {
    const { data } = await instance.patch<IcOrganizationSettings>(`/ic_organization_settings`, payload);
    return data;
  },
  force2FaForManagers: async (roleIds: (string | number)[]) => {
    return await instance.post(`/organizations/2fa`, { roleIds });
  },
};
