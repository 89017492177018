import type { AxiosRequestConfig } from 'axios';
import instance from '@/utils/api/instance';
import type { InviteEmailType } from '@/components/SelectInviteEmailType/SelectInviteEmailType';
import type {
  GPEmployeeOnboardingStatus,
  GPEmployeeOnboardingStatusSummary,
  GPEmployeeOnboardingStep,
} from '@/types/GlobalPayroll/onboarding';

import type { OnboardingStatus } from '../dashboard';

const BASE_URL = 'global_payroll/onboarding';

export interface OnboardingEmployeesSummaryResponse {
  total: number | null;
  statusSummary: GPEmployeeOnboardingStatusSummary | null;
}

type GetCsvTemplateParams = {
  country: string;
  isExample?: boolean;
  includeAdditionalDetails?: boolean;
  includeBankInfo?: boolean;
  includeWorkLocation?: boolean;
  isSoftGp?: boolean;
};

type ValidateCsvPayload = {
  country: string;
  fileKey: string;
  includeAdditionalDetails?: boolean;
  includeBankInfo?: boolean;
  legalEntityId: string | null;
  payrollSettingsId: string | null;
};

type ValidateCsvDataPayload = {
  fileKey: string;
  country: string;
  legalEntityId: string;
  payrollSettingsId: string;
  organizationId: number;
  teamId: number;
  isCreate?: boolean;
  includeAdditionalDetails?: boolean;
  includeBankInfo?: boolean;
  includeWorkLocation?: boolean;
  validateAndGetContractData?: boolean;
  shouldInviteToApp?: boolean;
  invitationEmailType?: NonNullable<InviteEmailType>;
  dateFormat?: string;
};

type ValidateCsvDataResponse = {
  contractualData: any[];
  creationResult?: { created: any[]; skipped: any[]; errors: any[] };
  isValid: boolean;
};

type ValidateEmployeeNumberResponse = {
  isValid: boolean;
  data: any;
};

export default {
  getOnboardingEmployeeSteps: async (contractId: number): Promise<GPEmployeeOnboardingStep[]> => {
    const { data } = await instance.get(`${BASE_URL}/onboarding_employee_steps/${contractId}`);
    return data;
  },

  getOnboardingEmployees: async (filterOption?: {
    status?: OnboardingStatus[];
    search?: string;
  }): Promise<GPEmployeeOnboardingStatus[]> => {
    const { data } = await instance.get(`${BASE_URL}/onboarding_employees`, {
      params: filterOption,
    });
    return data;
  },

  getOnboardingEmployeesSummary: async (): Promise<OnboardingEmployeesSummaryResponse> => {
    const { data: employeeOnboardingSummary } = await instance.get(`${BASE_URL}/onboarding_employees_summary`);
    return employeeOnboardingSummary;
  },

  getMassContractsCsvTemplate: async (params: GetCsvTemplateParams): Promise<any> => {
    const { data } = await instance.get(`${BASE_URL}/mass_contracts_csv_template`, { params });
    return data;
  },

  validateCsv: async (payload: ValidateCsvPayload): Promise<any> => {
    const { data } = await instance.post(`${BASE_URL}/pre_validate_mass_contracts_csv`, payload);
    return data;
  },

  validateCsvData: async (
    payload: ValidateCsvDataPayload,
    config?: AxiosRequestConfig
  ): Promise<ValidateCsvDataResponse> => {
    const url = payload.isCreate ? `${BASE_URL}/async_mass_contracts_csv` : `${BASE_URL}/mass_contracts_csv`;
    const { data } = await instance.post(url, payload, config);
    return data;
  },

  validateEmployeeNumber: async (
    legalEntityId?: string | number | null,
    employeeNumber?: string | null
  ): Promise<ValidateEmployeeNumberResponse> => {
    const defaultValue = { isValid: false, data: null };
    if (!employeeNumber || !legalEntityId) {
      return defaultValue;
    }
    try {
      const { data } = await instance.get(
        `${BASE_URL}/employee_number/validation?legalEntityId=${legalEntityId}&employeeNumber=${employeeNumber}`
      );
      return data;
    } catch {
      return defaultValue;
    }
  },
};
