import type { ReactNode } from 'react';
import useStores from '@/hooks/useStores';
import PermissionOwnerProvider from '@/context/Authorization/PermissionOwnerProvider';

const CurrentTeamRoleProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useStores();
  // TODO verify all teams mode
  return <PermissionOwnerProvider owner={{type: 'client', teamId: user.team?.teamPublicId!}}>{children}</PermissionOwnerProvider>;
};

export default CurrentTeamRoleProvider;
