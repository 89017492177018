import type { SetStateAction } from 'react';
import type { ThemeContextType } from './types';
import type { ThemeVariant } from '@/hooks/api/dashboard/home/storage';

export const ThemeContextInitialState: ThemeContextType = {
  selectedTheme: 'base',
  activeDeelUITheme: 'base',
  isUpdatingTheme: false,
  isCustomDomain: false,
  isUpdateThemeError: false,
  handleUpdateTheme: (_: ThemeVariant) => {},
  setTheme: function (_: SetStateAction<ThemeVariant>): void {},
  isThemeChanging: false,
  bottomNavigationState: {
    bottomNavShown: true,
    setBottomNavShown: () => {},
  },
  isSystemDefaultDarkMode: false,
};
