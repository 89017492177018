import i18n from 'i18next';
import { forwardRef } from 'react';
import type { ModalRef } from '@letsdeel/ui';
import { BulletPointCell, ListItem, Modal, Button, H4 } from '@letsdeel/ui';

interface Props {
  buttonProps: any;
}

const PhoneNumberTroubleshoot = forwardRef<ModalRef, Props>(({ buttonProps }, ref) => {
  return (
    <Modal
      ref={ref}
      title={i18n.t('temp.unknown.components.ResetAuthenticator.eb8b970747').toString()}
      subtitle={i18n.t('temp.unknown.components.ResetAuthenticator.a98d49cd32').toString()}
      actions={
        <>
          <Button {...buttonProps} fullWidth>
            {i18n.t('temp.unknown.components.ResetAuthenticator.67e13c1975').toString()}
          </Button>
        </>
      }
    >
      <BulletPointCell ordered>
        <ListItem>
          <H4 regular>{i18n.t('temp.unknown.components.ResetAuthenticator.874110dcb6').toString()}</H4>
        </ListItem>
        <ListItem>
          <H4 regular>{i18n.t('temp.unknown.components.ResetAuthenticator.165b4d426f').toString()}</H4>
        </ListItem>
        <ListItem>
          <H4 regular>{i18n.t('temp.unknown.components.ResetAuthenticator.fe6854cd2e').toString()}</H4>
        </ListItem>
      </BulletPointCell>
    </Modal>
  );
});

export default PhoneNumberTroubleshoot;
