import type { Node } from 'reactflow';
import type { EmployeeData, GroupData } from '@/hooks/api/organizationChart/useFetchOrganizationChart';
import type { CollapsibleNodeData } from './components';

export type EmployeeDetails = {
  groupNodeId?: string;
  employee?: EmployeeData;
  nextNodes?: Node<GroupData | CollapsibleNodeData>[];
};

// FilterKeys: Defines filter keys with their types.
// 'department' is the current key, but you can add more for additional filters.
export type FilterKeys = {
  department: string | (string | null)[] | null;
  groupBy: string | (string | null)[] | null;
  orgStructure: string | (string | null)[] | null;
  country: string | (string | null)[] | null;
  // Add more keys as needed, e.g., category, tags, etc.
};

// TFilterPrepareData: Represents a filter object with flexible keys from FilterKeys.
// Keys are optional, allowing for varied filter combinations.
export type TFilterPrepareData = {
  [key in keyof FilterKeys]?: FilterKeys[key];
};

export type THeightByParentIdMap = { [parentId: string]: number };

export type THandlerFilter = {
  [key in keyof FilterKeys]?: (employee: EmployeeData, filterValue: FilterKeys[key]) => boolean;
};

export type THandlerFilterByGroup = {
  [key in keyof FilterKeys]?: (employee: Node<GroupData>, filterValue: FilterKeys[key]) => boolean;
};

export enum GROUP_NODE_VARIANT {
  SMALL = 'small',
  MEDIUM = 'medium',
  DEFAULT = 'default',
  LARGE = 'large',
  EXTRA_LARGE = 'extra_large',
}

export enum GROUP_BY {
  NONE = 'none',
  ORG_STRUCTURE = 'ORG_STRUCTURE',
  COUNTRY = 'COUNTRY',
}

export enum ORG_STRUCTURE_TYPE {
  DEFAULT = 'DEFAULT',
  DEPARTMENT = 'DEPARTMENT',
}

export enum ORG_CHART_TYPE {
  EMPLOYEE = 'employee',
  GROUP = 'group',
}
