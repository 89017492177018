import type { AccountBalanceResult, AccountValidationResult, ValidateAccountPayload } from '@/types/api/payments';
import type { Wallet, WithdrawMethod } from '@/types/User';
import type { WithdrawBlock, WithdrawBlockAction, WithdrawBlockType } from '@/types/Withdraw';
import instance from '@/utils/api/instance';

export default {
  stripe: {
    getSecret: async (country: any, paymentStatementPublicId?: string) => {
      const { data } = await instance.get(`/payments/stripe_secret/${country}`, {
        params: { paymentStatementPublicId },
      });
      return data;
    },
    getAccountCountry: async ({ country }: any) => {
      const { data } = await instance.get(`/payments/stripe/account_country?country=${country}`);
      return data;
    },
  },

  bacs: {
    getSessionId: async ({ successUrl, cancelUrl }: any) => {
      const { data } = await instance.get(
        `payments/bacs_debit/session?success_url=${successUrl}&cancel_url=${cancelUrl}`
      );
      return data;
    },
  },

  sepa: {
    getClientSecret: async () => {
      const { data } = await instance.get(`payments/sepa_debit/client_secret`);
      return data;
    },
  },

  setDefault: async (id: string) => {
    const { data } = await instance.post(`payments/account/withdrawal_methods/${id}/default`);
    return data;
  },

  wallets: async () => {
    const { data } = await instance.get<{ wallets: Array<Wallet> }>(`payments/account/wallets`);
    return data;
  },

  deleteMethod: async (id: string) => {
    const { data } = await instance.delete(`payments/account/withdrawal_methods/${id}`);
    return data;
  },

  offcyclePayment: async (payload: any) => {
    const { data } = await instance.post('payments/offcycle', payload);
    return data;
  },

  getDetails: async ({ contractId, status, superadmin, milestones_ids, ignoreDueDate }: any) => {
    const { data } = await instance.get(`payments/details?superadmin=${+!!superadmin}`, {
      params: {
        status,
        milestones_ids,
        ignore_due_date: ignoreDueDate,
        ...(contractId ? { contract_id: contractId } : {}),
      },
    });
    return data;
  },

  addWithdrawMethod: async (payload: any) => {
    const { data } = await instance.post('payments/account/withdrawal_methods', payload);
    return data;
  },

  validateWithdrawMethodAccount: async (payload: ValidateAccountPayload): Promise<AccountValidationResult> => {
    const { data } = await instance.post('payments/withdraw_method/validate_account', payload);

    return data;
  },

  getPaymentDetails: async () => {
    const { data } = await instance.get('payments/account');
    return data;
  },

  getAccountBalances: async (): Promise<AccountBalanceResult> => {
    const { data } = await instance.get('payments/account/balance');
    return data;
  },

  getWithdrawMethods: async () => {
    const { data } = await instance.get<WithdrawMethod[]>('payments/account/withdrawal_methods', {
      params: { withValidations: true, withoutAutoRejected: true },
    });
    return data;
  },

  withdraw: async (params: any) => {
    const { data } = await instance.post('payments/withdraw', params);
    return data;
  },

  getClientMethods: async () => {
    const { data } = await instance.get('payments/account/client_methods');
    return data;
  },

  setClientMethods: async (payload: any) => {
    const { data } = await instance.post('payments/account/client_methods', payload);
    return data;
  },

  getBlocks: async (params: {
    type: WithdrawBlockType;
    enabled: boolean;
    actions: WithdrawBlockAction[];
  }): Promise<WithdrawBlock[]> => {
    const { data } = await instance.get('payments/blocks', { params });
    return data;
  },
};
