import { cn } from '@/utils/main';
import Flex from '@/components/Flex';
import { Icon, IconButton, H6 } from '@letsdeel/ui';
import type { ReactNode } from 'react';

export interface PopupHeaderProps {
  subtitle?: ReactNode;
  title?: ReactNode;
  information?: string;
  className?: string;
  hasDefaultMargin?: boolean;
  onHide: () => void;
  onBack?: () => void;
  onCloseButtonClick?: () => void;
  backButton?: boolean;
  closeButton?: boolean;
}

/**
 * @deprecated Use Deel/ui Modal instead
 */
const PopupHeader = ({
  hasDefaultMargin = true,
  backButton,
  onBack,
  title,
  subtitle,
  information,
  onHide,
  closeButton,
  className,
  onCloseButtonClick,
}: PopupHeaderProps) => {
  const handleClose = () => {
    if (onCloseButtonClick) {
      onCloseButtonClick();
    }
    onHide();
  };
  return (
    <Flex
      className={cn('align-items-flex-start', 'space-btwn', hasDefaultMargin ? 'mb-7' : null, className)}
      style={{ marginTop: -5 }}
    >
      <div className="arrow-popup-icon mr-4">
        {backButton ? (
          <IconButton>
            <Icon.BackArrow onClick={onBack} />
          </IconButton>
        ) : null}
      </div>
      <div className="text-center max-w-100">
        {title ? <h2 className={cn('text-center')}>{title}</h2> : null}
        {!subtitle ? null : typeof subtitle === 'string' ? <H6 color={'text.secondary'}>{subtitle}</H6> : subtitle}
        {information ? <p className="color-gray-dark">{information}</p> : null}
      </div>
      <div className="ml-4">
        {closeButton ? (
          <IconButton onClick={handleClose} data-qa="close">
            <Icon.Close />
          </IconButton>
        ) : null}
      </div>
    </Flex>
  );
};

export default PopupHeader;
