import type { ReactNode } from 'react';

import { H1 } from '@letsdeel/ui';

export const LoginPromoHeader = ({ children }: { children: ReactNode }) => {
  return (
    <H1 color="primary.contrastText" className="login-promo-header">
      {children}
    </H1>
  );
};
