import useLookupsStore from '@/hooks/useLookupsStore';
import { COLOR_MODE } from '@/scenes/Settings/components/WhiteLabel/constants';
import { isHexadecimalColor, isValidHttps } from '@/scenes/Settings/components/WhiteLabel/validation';
import { createContext, useContext, useMemo, type ReactNode } from 'react';
import { type CustomBrandingContextBody } from './types';
import { useFetchCustomBrandingSettingsPublic } from '@/hooks/api/settings/customBranding/useFetchCustomBrandingSettingsPublic';
import { updateFavicon } from '@/utils/main';

export const CustomBrandingContext = createContext<CustomBrandingContextBody>({
  isCustomBrandingEnabled: false,
  isCustomBrandingLoading: true,
  isCustomBrandingEmpty: true,
  logos: null,
  primaryColor: null,
});

const CUSTOM_BRANDING_DISABLED_VALUES = {
  isCustomBrandingEnabled: false,
  isCustomBrandingLoading: false,
  isCustomBrandingEmpty: true,
  logos: null,
  primaryColor: null,
};

export const CustomBrandingProvider = ({ children }: { children: ReactNode }) => {
  const lookups = useLookupsStore();
  const isCustomBrandingFeatureEnabled = lookups.getFeatureFlag('whiteLabel.isCustomBrandingEnabled');

  const { data, isCustomBrandingDataEmpty, isLoading } = useFetchCustomBrandingSettingsPublic();

  const isCustomBrandingEnabled = useMemo(
    () => isCustomBrandingFeatureEnabled && !isCustomBrandingDataEmpty,
    [isCustomBrandingDataEmpty, isCustomBrandingFeatureEnabled]
  );

  const primaryColor = useMemo(
    () =>
      isCustomBrandingEnabled
        ? {
            [COLOR_MODE.LIGHT_MODE]: isHexadecimalColor(data?.lightModeColorPrimary)
              ? data?.lightModeColorPrimary
              : undefined,
            [COLOR_MODE.DARK_MODE]: isHexadecimalColor(data?.darkModeColorPrimary)
              ? data?.darkModeColorPrimary
              : undefined,
          }
        : null,
    [isCustomBrandingEnabled, data?.lightModeColorPrimary, data?.darkModeColorPrimary]
  );

  const logos = useMemo(
    () =>
      isCustomBrandingEnabled
        ? {
            [COLOR_MODE.LIGHT_MODE]: {
              sm: isValidHttps(data?.lightModeLogoSmall) ? data?.lightModeLogoSmall : undefined,
              md: isValidHttps(data?.lightModeLogo) ? data?.lightModeLogo : undefined,
            },
            [COLOR_MODE.DARK_MODE]: {
              sm: isValidHttps(data?.darkModeLogoSmall) ? data?.darkModeLogoSmall : undefined,
              md: isValidHttps(data?.darkModeLogo) ? data?.darkModeLogo : undefined,
            },
          }
        : null,
    [
      isCustomBrandingEnabled,
      data?.lightModeLogoSmall,
      data?.lightModeLogo,
      data?.darkModeLogoSmall,
      data?.darkModeLogo,
    ]
  );

  if (isCustomBrandingEnabled) updateFavicon(data?.lightModeLogoSmall);

  if (!isCustomBrandingFeatureEnabled) {
    return (
      <CustomBrandingContext.Provider value={CUSTOM_BRANDING_DISABLED_VALUES}>
        {children}
      </CustomBrandingContext.Provider>
    );
  }

  return (
    <CustomBrandingContext.Provider
      value={{
        hostname: isCustomBrandingEnabled ? data?.hostname : undefined,
        isCustomBrandingEnabled,
        isCustomBrandingLoading: isLoading,
        isCustomBrandingEmpty: isCustomBrandingDataEmpty,
        logos,
        primaryColor,
      }}
    >
      {children}
    </CustomBrandingContext.Provider>
  );
};

export const useCustomBrandingContext = () => {
  const context = useContext(CustomBrandingContext);

  if (context === undefined) {
    throw new Error('useCustomBrandingContext must be used within a CustomBrandingProvider');
  }

  return context;
};
