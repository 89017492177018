import type { ContractEndingFlowTypes } from '@/scenes/Contract/components/EorContract/components/EorContractDetails/components/ContractEndingFlow/ContractEndingFlow.props';
import type { SupportingDocument } from '@/scenes/Contract/components/PeoDecision/components/EOREndOfContractBox/components/TerminationProcess/TerminationProcess.types';
import type { EORPaymentCycle, Review } from '@/types/Contract';
import type { IncentivePlansValues } from '@/scenes/Create/components/CompensationForm/components/VariableCompensationFormBox/IncentivePlans/IncentivePlans.props';
import type {
  CreateOneOffPaymentPayload,
  CurrentOneOffPayment,
  CustomVerificationLetterPayload,
  EmergencyData,
  IncentivePlanPermission,
  OtherChangesFileProps,
  RedlineRequestPayload,
  UpdateEmployeeData,
  VariableCompensation,
} from '@/types/EOR';
import type { ITimeOffData, TimeOffRequestType } from '@/utils/api/employee';
import type { IClientMethod } from '@/utils/api/payments/mainV2';

import type {
  IAdjustment,
  IApprovedAdjustment,
  IAwaitingPaymentAdjustment,
  IDeclinedAdjustment,
  IPendingAdjustment,
} from '@/utils/api/employment-service';
import type { AxiosRequestConfig } from 'axios';

import type { QuoteRejectionReasonsEnum } from '@/utils/contract/quoteRejection';
import { toDownload } from '../download';
import { uploadFileItem } from '../file';
import uploadApi from '@/utils/api/upload';
import filesApi from '@/utils/api/files';
import instance from '@/utils/api/instance';
import type { CancellationReasonEnum } from '@/scenes/Contract/components/PeoDecision/components/EOREndOfContractBox/types';
import type { EmploymentCost } from '@/scenes/Create/scenes/EORCostCalculator/components/EmploymentCostsBox/types';

export interface IMassApproveTimeOff {
  employees: ITimeOffEmployee[];
  totalRequests: number;
}

export interface ITimeOffEmployee {
  id: number;
  name: string;
  vacations: IEmployeeTimeOffEntitlementData[];
  sickLeaves: IEmployeeTimeOffEntitlementData[];
  others: IEmployeeTimeOffEntitlementData[];
  timezone: string;
  eorContractId: number;
  contractId: string;
  hrisProfileOid?: string;
}

export interface IEmployeeTimeOffEntitlementData {
  year: number;
  daysRequested: number;
  currentApproved: number;
  yearlyLimit: number | null;
  isRequestOverAnnualLimit: boolean;
  yearlyRequests: number | null;
  requests: ITimeOffData[];
}

export interface IGetTotalTimeOffsResponse {
  daysUsed: string;
  daysUsedStartYear: string;
  daysUsedEndYear: string;
  isAmountOverAllowance: boolean;
}
export interface ITimeOffDaysUsedByYear {
  year: number;
  daysUsed: string;
}
export interface IGetTotalTimeOffsPayload {
  type: TimeOffRequestType;
  startDate?: string;
  endDate?: string | null;
  singleDate?: string;
  isStartDateHalfDay?: boolean;
  isEndDateHalfDay?: boolean;
  isSingleDateHalfDay?: boolean;
}

export interface ITimeOffEntitlements {
  year: number;
  vacationAllowed: string;
  vacationUsed: string;
  vacationRequested: string;
  vacationTotal: string;
  vacationRolloverForThisYear: string;
  sickLeaveAllowed: string;
  sickLeaveUsed: string;
  sickLeaveRequested: string;
  sickLeaveTotal: string;
  otherLeaveUsed: string;
  otherLeaveRequested: string;
  otherLeaveTotal: string;
  rolloverType: RolloverType | null;
  maxRolloverYearly: string | null;
  vacationRollover: string | null;
  vacationExpired: string | null;
  vacationPayout: string | null;
  vacationApproved: string | null;
  sickLeaveApproved: string | null;
  otherLeaveApproved: string | null;
  holidayRolloverExpirationDate: string | null;
  holidayRolloverExpirationDateFollowingYear: string | null;
  holidayRolloverExpirationAfterMonths: string | null;
  holidayCalcOverride: boolean;
}

export interface ITimeOffLatestRolloverLogic {
  maxRolloverYearly: number | null;
  type: RolloverType | null;
}

export interface ITimeOffEntitlementData {
  entitlements: ITimeOffEntitlements[];
  latestRolloverLogic: ITimeOffLatestRolloverLogic;
}

export enum RolloverType {
  LIMITED_ROLLOVER = 'LIMITED_ROLLOVER',
  UNLIMITED_ROLLOVER = 'UNLIMITED_ROLLOVER',
  EXPIRATION = 'EXPIRATION',
  PAY_OUT = 'PAY_OUT',
  UNSET = 'UNSET',
}

export type IEorTimeOffData = ITimeOffData;

export type IGetAllTimeOffsOptions = { employeeName?: string; type?: string[]; teamId?: string[] };

export interface MissingDataMessage {
  message: { message: string; type: 'success' | 'info' | 'warning' | 'error' | 'empty' };
}
export interface NonDeelEorReview extends Review {
  deelCommission?: number | null;
  amount: number;
}

export interface PendingAdjustment {
  country: string;
  contracts: PendingAdjustmentContract[];
}

export interface PendingAdjustmentContract {
  id: string;
  employeeName: string;
  timezone: string;
  paymentCycleEnd: string;
  paymentCycleStart: string;
  total: number;
  isTaxable: boolean;
  isRecurring: boolean;
  adjustmentIds: number[];
  currency: string;
}

export interface DepositRefunds {
  refunds: DepositRefundsRefunds[];
}
export interface DepositRefundsRefunds {
  id: number;
  amount: string | null;
  currency: string | null;
  total: string;
  confirmedAt: string | null;
  Invoice: RefundInvoice | null;
  ClientMethod: IClientMethod | null;
  refundUsingClientBalance: boolean;
  refundStatementId: string | null;
  isPartialRefund: boolean;
  refundedOffPlatform: boolean;
}
export interface ClientMethod {
  id: number;
  paymentMethod: string;
  payload: ClientMethodPayload;
  isDefault: boolean;
  userId: number;
}
export interface ClientMethodPayloadAddress {
  city: string;
  country: string;
  postCode: string;
  firstLine: string;
}
export interface ClientMethodPayload {
  deel: number;
  iban: string;
  name: string;
  type: string;
  email: string;
  address: ClientMethodPayloadAddress;
  currency: string;
  legalType: string;
  selectedMethod: string;
}
export interface RefundInvoice {
  id: number;
  amount: string;
  status: string;
  paidAt: string;
  currency: string;
  invoiceId: string;
}

export interface BankInfoChangeWarningResponse {
  message: string | null;
}

export interface BankInfoChangeMessage {
  status: 'old' | 'new';
  message: string;
}
export type BankInfoChangeMessagesResponse = BankInfoChangeMessage[];

interface GetEorEndpoints {
  contractId?: string | number;
  employeeId?: string | number;
  countryDataVersionId?: number;
  country?: string;
  eorContractId?: number;
  eorId?: string;
}

export const PERMISSION_TYPE = {
  PAYSLIP: 'PAYSLIP',
  COMPLIANCE_DOCUMENTS: 'COMPLIANCE_DOCUMENTS',
  IDENTIFICATION_DOCUMENTS: 'IDENTIFICATION_DOCUMENTS',
} as const;

export const PERMISSION_STATUS_TYPE = {
  NOT_GRANTED: 'NOT_GRANTED',
  GRANTED: 'GRANTED',
  REQUESTED: 'REQUESTED',
  REJECTED: 'REJECTED',
} as const;

export type PermissionType = (typeof PERMISSION_TYPE)[keyof typeof PERMISSION_TYPE];
export type PermissionStatusType = (typeof PERMISSION_STATUS_TYPE)[keyof typeof PERMISSION_STATUS_TYPE];

export interface TaxDocument {
  country: string;
  documentType: string;
  file: string;
  year: number;
  oid: string;
}

export interface IDocumentPermission {
  id?: number;
  type: PermissionType;
  EmployeeId?: number;
  organizationId?: number;
  status: PermissionStatusType;
  grantedAt?: string | null;
  rejectedAt?: string | null;
  requestedAt?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
}

export interface IOrgWithPermissions {
  organizationId: number;
  organizationName: string;
  accesses: IDocumentPermission[];
}

export interface ISetPermissionsPayloadItem {
  type: PermissionType;
  status: PermissionStatusType;
  organizationId: number;
}

export type ISetPermissionsPayload = ISetPermissionsPayloadItem[];

export interface IRequestPermissionPayload {
  type: PermissionType;
}

export type PayrollEventStateType = 'OPEN' | 'LOCKED' | 'CLOSED';
export interface IPayrollEvent {
  id: string;
  start: string;
  end: string;
  lock: string;
  state: PayrollEventStateType;
}

export type MonthlyCostType =
  | 'employer_cost'
  | 'non_statutory_employer_cost'
  | 'management_fee'
  | 'work'
  | 'deposit'
  | 'health_benefit'
  | 'health_allowance'
  | 'accrued_holiday'
  | 'severance'
  | 'health_insurance_fee'
  | 'signing_bonus'
  | 'signing_bonus_employer_cost'
  | 'additional_fee'
  | 'pension'
  | 'hofy_equipment'
  | 'wework_access'
  | 'foreign_currency_contract_fee'
  | 'eor_management_fee'
  | 'pension_fee'
  | 'external_legal_advisory_cost';

export type MonthlyCostStatus = 'approved' | 'not_payable';

export interface IMonthlyCostItem {
  amount: string;
  type: MonthlyCostType;
  status: MonthlyCostStatus;
}
export type PaymentCycleStatusType = 'active' | 'overdue' | 'upcoming' | 'completed' | 'awaiting_payment' | 'deleted';
export interface IPaymentCycle {
  id: number;
  invoiceId: number | null;
  invoiceLabel: string | null;
  total: string;
  status: PaymentCycleStatusType;
  start: string;
  end: string;
  due: string;
  payrollEvents: IPayrollEvent[];
  monthlyCosts: IMonthlyCostItem[];
  adjustments: (IApprovedAdjustment | IDeclinedAdjustment | IAwaitingPaymentAdjustment)[];
}

export interface IGetPaymentCyclesResponse {
  cycles: IPaymentCycle[];
  pendingAdjustments: IPendingAdjustment[];
  currency: string;
}
export interface IPartnersOffCycleInvoice {
  description: string | null;
  amount: string;
  type: 'expense' | 'allowance' | 'bonus' | 'work' | 'management_fee' | 'employer_cost' | 'other';
  expenseDate?: string;
}
export interface IPartnersOffCycleInvoicePayload {
  items: IPartnersOffCycleInvoice[];
}

export interface IEorForQuote {
  employmentCountry: string;
  teamId: string;
  workVisa?: boolean;
  employeeNationality?: string;
  employmentState?: string | null;
}

export interface ICsatStatus {
  lastUnenrollmentStepConfirmedAt?: string;
  lastCountersignedAmendmentAt?: string;
  firstPayslipAvailableAt?: string;
}

export interface IEorContracts {
  country: string;
  teamId: boolean;
}

export interface IRegionWaitlistPayload {
  profileId?: number;
  provinceCode: string;
  countryCode: string;
  isVisaRequired: boolean;
  hasVisa: boolean;
  expectedHireNumber: number | null;
  idealStartDate: string | null;
}

export interface INotPublishedPayslip {
  id: number;
  dueMonth: string;
  amount: number;
  currency: string;
  country: string;
  taxableItems: {
    description: string;
    total: string;
    taxPercentage: string;
    netAmount: string;
  }[];
}

export interface ReviewClientSignOffDocumentsPayload {
  hasClientApproved: boolean;
  reviewerFeedback: string | null;
  hasEmployeeBeenNotified: boolean | null;
}

export interface ReviewEmployeeSignOffDocumentsPayload {
  approved: boolean;
  requestedChanges?: string;
  reviewerSignature?: string;
}

export interface IJobScopeErrorItem {
  message: string;
  relevantText?: string;
}

export interface IJobScopeValidation {
  errors?: IJobScopeErrorItem[];
  error?: string;
  quoteValidationLogPublicId?: string;
}

export interface IGetQuoteAutomationSupport {
  isAutoQuoteValidationEnabled: boolean;
}

export interface IJobScopeValidationParams {
  jobScope: string;
  jobTitle: string;
  employeeName: string;
  clientLegalEntityId: number;
}

export interface IReportValidationFalseErrors {
  jobScope: string;
  jobTitle: string;
  incorrectJobScopeResult: boolean;
  incorrectJobTitleResult: boolean;
  additionalInfo: string;
}

export interface IComplianceDocsValidation {
  s3Keys: string[];
}

export enum ComplianceDocsRejectionReasons {
  POOR_QUALITY = 'POOR_QUALITY',
  WRONG_DOCUMENT = 'WRONG_DOCUMENT',
  DOCUMENT_EXPIRED = 'DOCUMENT_EXPIRED',
  INACCURATE_DATA = 'INACCURATE_DATA',
  NONE = 'NONE',
}

export interface ComplianceDocsIaVerificationResponse {
  id: string;
  complianceDocsValidationPublicId: string;
  s3_keys: string[];
  details_validation: DetailsValidation;
  image_quality_validation: ImageQualityValidation;
  rejection_reason: ComplianceDocsRejectionReasons[];
  template_validation: TemplateValidation;
}

export interface DetailsValidation {
  date_of_birth: boolean;
  first_name: boolean;
  last_name: boolean;
  nationality: boolean;
}

export interface ImageQualityValidation {
  is_not_blurry: boolean;
  is_not_grayscale: boolean;
}

export interface TemplateValidation {
  correct_type: boolean;
  is_not_expired: boolean;
  missing_hints: string[];
}

export interface UpdateComplianceDocsValidationLogs {
  submittedWithFailure: boolean;
  s3Key: string;
}

export interface CalculateOffboardingCostPayload {
  endingType: 'TERMINATION' | 'RESIGNATION' | null;
  desiredEndDate: string | null;
  timeOffUsedDaysByYear: {
    [year: number]: string;
  };
  renderPDF?: boolean;
}

export interface OffboardingCost {
  name: string;
  description: string | null;
  amount: string;
}
export interface CalculateOffboardingCostResult {
  costs: OffboardingCost[];
  total: string;
  currency: string;
}

export type ServerSeveranceType = 'DAYS' | 'WEEKS' | 'MONTHS' | 'CASH';

export type NettingOffPayload = {
  refundMethodId: number | null;
};

export interface GetIPDeposit {
  incentivePlan: IncentivePlan;
  incentivePlanCountryValidation: IncentivePlanPermission;
  contract: Contract;
}

export interface Contract {
  currency: string;
  annualSalary: number;
}

export interface IncentivePlan {
  maxAmount: number;
  incentivePlanType: string;
  timeframe: string;
}

export interface IPDepositResponse {
  isRequired: boolean;
  amount: number | null;
}

export interface ProjectDescriptionRequestChangesPayload {
  notes: string;
}

export enum ContractsEntityMovementStatus {
  AWAITING_SIGNATURE = 'AWAITING_SIGNATURE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum ContractsEntityMovementSignatureRole {
  REQUESTER = 'REQUESTER',
  ENTITY_POC = 'ENTITY_POC',
}

export enum ContractsEntityMovementSignatureStatus {
  AWAITING_SIGNATURE = 'AWAITING_SIGNATURE',
  SIGNED = 'SIGNED',
}

export interface IContractsEntityMovementSignature {
  id: number;
  profileId: number;
  publicProfileId: string;
  name: string;
  pictureUrl: string;
  role: ContractsEntityMovementSignatureRole;
  status: ContractsEntityMovementSignatureStatus;
  signedAt: Date | null;
}

export interface IContractsEntityMovementItem {
  oid: string;
  employeeLegalName: string;
  pictureUrl?: string;
  jobTitle: string;
  originLegalEntity: {
    id: number;
    publicId: string;
    name: string;
  };
}

export interface IContractsEntityMovement {
  id: string;
  requesterProfileId: number;
  entityAgreementTemplateInstanceId: string;
  status: ContractsEntityMovementStatus;
  completedAt: Date | null;
  destinationLegalEntity: {
    id: number;
    publicId: string;
    name: string;
  };
  contracts: IContractsEntityMovementItem[];
  signatures: IContractsEntityMovementSignature[];
}

export default {
  amendment: {
    getHistory: async ({ eorContractId }: { eorContractId: string }) => {
      const { data } = await instance.get(`eor/amendments/${eorContractId}`);
      return data;
    },
    create: async ({ eorContractId, payload }: any) => {
      const { data } = await instance.post(`eor/amendments/${eorContractId}`, payload);
      return data;
    },
    getMessage: async (eorContractId: string, payload: any) => {
      const { data } = await instance.post(`eor/amendments/${eorContractId}/message`, payload);
      return data;
    },
    update: async ({ eorContractId, amendmentId, payload }: any) => {
      const { data } = await instance.patch(`eor/amendments/${eorContractId}/${amendmentId}`, payload);
      return data;
    },
    send: async ({ eorContractId }: { eorContractId: string }) => {
      const { data } = await instance.post(`eor/amendments/${eorContractId}/send`);
      return data;
    },
    revoke: async ({ eorContractId }: { eorContractId: string }) => {
      const { data } = await instance.post(`eor/amendments/${eorContractId}/revoke`);
      return data;
    },
    sign: async ({ eorContractId, payload }: any) => {
      const { data } = await instance.post(`eor/amendments/${eorContractId}/sign`, payload);
      return data;
    },
    cancel: async ({ eorContractId, amendmentId }: any) => {
      const { data } = await instance.post(`eor/amendments/${eorContractId}/cancel/${amendmentId}`);
      return data;
    },
    delete: async ({ eorContractId, amendmentId }: any) => {
      const { data } = await instance.delete(`eor/amendments/${eorContractId}/${amendmentId}`);
      return data;
    },
    reject: async ({ eorContractId, reason }: { eorContractId: number; reason?: string }) => {
      return (await instance.post(`eor/amendments/${eorContractId}/reject`, { reason })).data;
    },
    download: async ({
      eorContractId,
      amendmentId,
      type,
    }: {
      eorContractId: number | string;
      amendmentId: number;
      type: string;
    }) => {
      return (
        await instance.get(`eor/amendments/${eorContractId}/pdf/${amendmentId}`, {
          params: { type, download: 2 },
          suppressSnackbar: true,
        })
      ).data;
    },
    action: async ({ eorContractId, amendmentId }: any) => {
      const { data } = await instance.patch(`eor/amendments/${eorContractId}/action/${amendmentId}`);
      return data;
    },
    logAction: ({ eorContractId, amendmentId, action }: any) => {
      if (!eorContractId) {
        return;
      }
      if (!amendmentId) {
        return;
      }
      if (!action) {
        return;
      }
      try {
        instance
          .patch(`eor/amendments/${eorContractId}/log_action/${amendmentId}`, {
            action,
          })
          .catch((err) => {
            console.warn(err);
          })
          .finally(() => {});
      } catch (err) {
        console.warn(err);
      }
    },
  },

  endContract: {
    confirmEndContract: async ({ contractOid, body }: { contractOid: string; body: ContractEndingFlowTypes }) => {
      const { data } = await instance.put(`eor/contracts/${contractOid}/defined-end-date/confirm`, body, {
        suppressSnackbar: true,
      });
      return data;
    },
  },

  timeOffs: {
    getTotal: async (
      eorContractId: number,
      queryPayload: IGetTotalTimeOffsPayload
    ): Promise<IGetTotalTimeOffsResponse> => {
      const queryParams = new URLSearchParams();

      for (let key of Object.getOwnPropertyNames(queryPayload)) {
        // @ts-ignore
        queryParams.append(key, queryPayload[key]);
      }

      return (await instance.get(`eor/time-offs/${eorContractId}/calculate/days-to-be-used?${queryParams.toString()}`))
        .data;
    },
  },

  documents: {
    compliance: {
      getFile: (contractId: string, docId: string | number) =>
        toDownload(`/eor/${contractId}/work_eligibility_doc/${docId}`),
    },
  },

  taxDocuments: {
    getFile: (oid: string) => toDownload(`/eor/tax-documents/${oid}/file`),
  },

  quoteValidation: {
    validateJobScopeAndTitle: async (
      eorId: number,
      payload: IJobScopeValidationParams
    ): Promise<IJobScopeValidation> => {
      const { data } = await instance.post(`/eor/quote_validations/${eorId}`, payload, {
        suppressSnackbarForStatuses: [500],
      });
      return data;
    },
    reportValidationFalseErrors: async (payload: IReportValidationFalseErrors) => {
      const { data } = await instance.post('/eor/quote_validations/reports', payload);
      return data;
    },
    getQuoteAutomationSupport: async (eorId: number): Promise<IGetQuoteAutomationSupport> => {
      const { data } = await instance.get(`/eor/quote_validations/${eorId}`);
      return data;
    },
    getValidationErrorsMessage: async (contractOid: string): Promise<string[]> => {
      const { data } = await instance.get(`/eor/quote_validations/contracts/${contractOid}/errors`);
      return data;
    },
  },

  complianceDocsValidation: {
    validate: async (
      contractOid: string,
      payload: IComplianceDocsValidation
    ): Promise<ComplianceDocsIaVerificationResponse[]> => {
      const { data } = await instance.post(`eor/compliance_docs_validations/${contractOid}`, payload, {
        suppressSnackbarForStatuses: [502],
      });
      return data;
    },
    updateValidationLog: async (
      contractOid: string,
      documentId: string,
      payload: UpdateComplianceDocsValidationLogs
    ) => {
      const { data } = await instance.patch(
        `eor/compliance_docs_validations/${contractOid}/document/${documentId}`,
        payload,
        { suppressSnackbarForStatuses: [400] }
      );
      return data;
    },
    getValidationSupport: async (contractOid: string): Promise<{ isComplianceDocAICheckEnabled: boolean }> => {
      const { data } = await instance.get(`eor/compliance_docs_validations/${contractOid}/`);
      return data;
    },
  },

  create: async (payload: any) => {
    const { data } = await instance.post('eor', payload);
    return data;
  },

  getSalesforceOwner: async (contractId: string) => {
    const { data } = await instance.get(`eor/${contractId}/account-owner`);
    return data;
  },

  getOffPlatformProcessByContractId: async (
    contractId: string
  ): Promise<{
    offPlatformProcess: boolean;
    country: {
      country_name: string;
      country_code: string;
      citizen_naming: string;
    };
  }> => {
    const { data } = await instance.get(`eor/${contractId}/off_platform_process`);
    return data;
  },

  update: async (id: string, payload: any) => {
    const { data } = await instance.patch(`eor/${id}`, payload);
    return data;
  },

  setReasonForEditingJobTitleScope: async (contractId: string, reasonId: string, payload: any) => {
    const { data } = await instance.patch(`eor/${contractId}/job_scope_update/${reasonId}/add_reason`, payload);
    return data;
  },

  requestQuote: async (id: string) => {
    const { data } = await instance.patch(`eor/${id}/request-quote`);
    return data;
  },

  sendQuote: async (id: string) => {
    const { data } = await instance.patch(`eor/${id}/send-quote`);
    return data;
  },

  rejectQuote: async (id: string, payload: { reason: QuoteRejectionReasonsEnum; message?: string }) => {
    const { data } = await instance.patch(`eor/${id}/reject`, payload);
    return data;
  },

  deleteQuote: async (contractId: string) => {
    return (await instance.delete(`eor/${contractId}/quote`)).data;
  },

  setQuote: async (id: string, payload: any) => {
    const { data } = await instance.patch(`eor/${id}/quote`, payload);
    return data;
  },

  getEorForQuote: async (params: IEorForQuote) => {
    const { data } = await instance.get(`eor/find-eor-for-quote`, {
      params,
    });
    return data;
  },

  getEORContractsByCountryAndOrganizaion: async (params: IEorContracts) => {
    const { data } = await instance.get(`eor/eor_contracts`, {
      params,
    });
    return data;
  },

  getSupportingDocumentUrl: async (contractId: string, documentId: string) => {
    const { data } = await instance.get(`eor/${contractId}/supporting_document/${documentId}/pdf?noredirect=1`);
    return data?.url;
  },
  updateStartDate: async (oid: string, payload: any) => {
    const { data } = await instance.patch(`eor/${oid}/start-date`, payload);
    return data;
  },

  uploadInvoice: async ({ invoiceId, key, filename }: { invoiceId: string; key: string; filename: string }) => {
    await instance.post(`eor/invoices/${invoiceId}/file`, { key, filename });
  },

  uploadCustomAddon: async (contractId: string, payload: any) => {
    await instance.post(`eor/${contractId}/addon`, payload);
  },

  deleteCustomAddon: async (contractId: string, type: any) => {
    await instance.delete(`eor/${contractId}/addon?type=${type}`);
  },

  getClient: async (id: string) => {
    const { data } = await instance.get(`eor/${id}/client`);
    return data;
  },

  supportedCountries: async () => {
    const { data } = await instance.get(`/eor/countries`);
    return data;
  },

  healthInsurance: async (employmentCountry: any, workVisa: any, id?: number) => {
    const { data } = await instance.get(
      `/eor/health-insurance?employmentCountry=${employmentCountry}&workVisa=${workVisa}${id ? `&id=${id}` : ''}`
    );
    return data;
  },

  creditControlCheck: async (
    organizationId: string,
    {
      suppressSnackbar = false,
      hideErrorMessageOnNetworkError = false,
    }: { suppressSnackbar?: boolean; hideErrorMessageOnNetworkError?: boolean }
  ) => {
    const { data } = await instance.get(`/eor/${organizationId}/credit_control_check`, {
      suppressSnackbar,
      hideErrorMessageOnNetworkError,
    });
    return data as { isEorContractOnboardingBlocked: boolean };
  },

  currencies: async (employmentCountry: any, teamId: number) => {
    const { data } = await instance.get(`/eor/currencies?country=${employmentCountry}&teamId=${teamId}`);
    return data;
  },

  approveCustom: async (contractId: string, payload: any) => {
    const { data } = await instance.post(`eor/${contractId}/sign-custom`, payload);
    return data;
  },

  updateCycleAmount: async (contractId: string, cycleStatus = 'active', payload: any) => {
    const { data } = await instance.put(`eor/${contractId}/cycle-costs?cycleStatus=${cycleStatus}`, payload);
    return data;
  },

  uploadQuoteFile: async (contractId: string, payload: any) => {
    await instance.post(`eor/${contractId}/quote/file`, payload);
  },

  deleteQuoteFile: async (contractId: string) => {
    await instance.delete(`eor/${contractId}/quote/file`);
  },

  getSupportForAutomaticQuote: async (contractId: string) => {
    const { data } = await instance.get(`/eor/automated-quote-support/${contractId}`);
    return data;
  },

  generateAutomatedQuote: async (contractOid: string) => {
    const { data } = await instance.post(`/eor/automated-quote`, { contractOid });
    return data;
  },

  deposit: async (id: string) => {
    const { data } = await instance.get(`/eor/${id}/deposit`);
    return data;
  },

  depositEstimate: async (id: string, payload: any) => {
    const { data } = await instance.post(`/eor/${id}/deposit/estimate`, payload);
    return data;
  },

  accruedDeposit: async (id: string) => {
    const { data } = await instance.get(`/eor/${id}/accrued-deposit-default`);
    return data;
  },

  cancelContract: async (
    contractId: string,
    payload: { cancellationReason: CancellationReasonEnum; cancellationMessage?: string }
  ) => {
    const { data } = await instance.post(`/eor/contracts/${contractId}/cancel`, payload, { suppressSnackbar: true });
    return data;
  },

  requestTermination: async (
    contractId: string,
    payload: {
      endingType: 'with_cause' | 'without_cause' | 'resignation';
      endingAdditionalInfo: {
        reason?: string;
        message?: string;
        numberHolidays: number;
        severanceType?: 'time_value' | 'cash_value';
        severanceTimeType?: 'weeks' | 'days' | 'months';
        severancePay?: number;
        customTerminationReasonId?: string | null;
      };
      terminationDate: string; // '2022-01-01';
    }
  ) => {
    const { data } = await instance.put(`/eor/${contractId}/cancel`, payload);
    return data;
  },

  requestEORTermination: async (
    contractId: string,
    type: 'TERMINATION' | 'RESIGNATION',
    payload: {
      reason?: string;
      employeeNotificationDetail?: string;
      isEmployeeNotified?: boolean;
      usedTimeOff: {
        isDeelPtoConfirmed: boolean | null;
        timeOffs: {
          policyType: string;
          trackingPeriods: {
            startDate: string;
            endDate: string;
            used: number | null;
          }[];
          scheduledUntilEndDate: number;
        }[];
        additionalDetails: string | null;
      };
      severanceAmount?: number;
      severanceType?: 'DAYS' | 'WEEKS' | 'MONTHS' | 'CASH';
      reasonDetail?: string;
      additionalReason?: string;
      isEmployeeStayingWithDeel?: boolean;
      desiredEndDate?: string;
      attachments?: SupportingDocument[];
      additionalSupportingDocuments?: SupportingDocument[];
      additionalCountryData?: { question: string; answer: boolean | null | undefined }[];
      eorCountryDataId?: number;
      customTerminationReasonId?: string | null;
    }
  ) => {
    const url = `/eor/terminations_v3/${contractId}/${type === 'RESIGNATION' ? 'resignation' : 'regular'}`;

    const { data } = await instance.post(url, {
      ...payload,
      attachments: payload.attachments?.map((a) => ({ fileName: a.filename, key: a.key })),
      additionalSupportingDocuments: payload.additionalSupportingDocuments?.map((a) => ({
        fileName: a.filename,
        key: a.key,
      })),
    });
    return data;
  },

  employeeCreateResignation: async ({
    contractId,
    ...payload
  }: {
    contractId: string;
    desiredEndDate: string;
    reason: string;
    reasonDetail?: string;
  }) => {
    const { data } = await instance.post(`/eor/terminations_v3/${contractId}/resignation`, payload, {
      suppressSnackbar: true,
    });

    return data;
  },

  employeeUpdateResignation: async ({
    contractId,
    ...payload
  }: {
    contractId: string;
    desiredEndDate: string;
    reason: string;
    reasonDetail?: string;
  }) => {
    await instance.put(`/eor/terminations_v3/${contractId}/employee_resignation_request`, payload, {
      suppressSnackbar: true,
    });
  },

  employeeSubmitResignation: async (contractId: string) => {
    await instance.post(`/eor/terminations_v3/${contractId}/employee_resignation_request/submit`);
  },

  employeeUploadResignationSupportingDocuments: async (
    contractId: string,
    files: File[],
    onValidationError: (message: string) => void
  ) => {
    const attachments = [];
    const MAX_FILE_SIZE = 5 * 1024 * 1024;

    for (const file of files) {
      if (file.size > MAX_FILE_SIZE) {
        onValidationError(`The file ${file.name} exceeds the maximum size of 5MB`);
        continue;
      }

      const { key } = await uploadFileItem({
        file,
        getSignedUrl: uploadApi.uploadFormEorExperience,
        fileExistsApiCall: filesApi.existsEorExperience,
      });

      attachments.push({
        key,
        fileName: file.name,
        label: file.name,
      });
    }

    if (attachments.length) {
      await instance.post(`/eor/terminations_v3/${contractId}/employee_resignation_request/attachments`, {
        attachments,
      });
    }
  },

  employeeDeleteResignationSupportingDocument: async (contractId: string, attachmentId: string) => {
    await instance.delete(
      `/eor/terminations_v3/${contractId}/employee_resignation_request/attachments/${attachmentId}`
    );
  },

  employeeDownloadTerminationDocument: async (contractId: string, attachmentId: string) => {
    const { data } = await instance.get(`/eor/terminations_v3/${contractId}/attachments/${attachmentId}`);
    return data;
  },

  sendClientSignOffReview: async (contractId: string, payload: ReviewClientSignOffDocumentsPayload) => {
    const { data } = await instance.post(`/eor/terminations_v3/${contractId}/client_sign_off/review`, payload);
    return data;
  },

  sendEmployeeSignOffReview: async (contractId: string, payload: ReviewEmployeeSignOffDocumentsPayload) => {
    const { data } = await instance.post(`/eor/terminations_v3/${contractId}/employee_sign_off/review`, payload);
    return data;
  },

  enrollEmployeeToGetHiredProgram: async (contractOid: string, hasAcceptedGetHiredProgram: boolean) => {
    const { data } = await instance.post(`/eor/terminations_v3/${contractOid}/get_hired`, {
      hasAcceptedGetHiredProgram,
    });
    return data;
  },

  employeeSubmitResignationPTO: async ({
    contractId,
    ...payload
  }: {
    contractId: string;
    hasClientAcceptedResignationRequest?: boolean;
    declineReason?: string;
    declineReasonDetail?: string;
    severanceType?: ServerSeveranceType;
    severanceAmount?: number | null;
    usedTimeOff: {
      isDeelPtoConfirmed: boolean | null;
      timeOffs: {
        policyType: string;
        trackingPeriods: {
          startDate: string;
          endDate: string;
          used: number | null;
        }[];
        scheduledUntilEndDate: number;
      }[];
      additionalDetails: string | null;
    };
  }) => {
    await instance.post(
      `/eor/terminations_v3/${contractId}/employee_resignation_request/submit_pto`,
      {
        ...payload,
      },
      { suppressSnackbar: true }
    );
  },

  downloadResignationLetter: async (contractId: string, query = {}, is_tm?: boolean) =>
    (
      await instance.get(`/eor/terminations_v3/${contractId}/resignation_letter/pdf`, {
        params: { ...query, ...(is_tm !== undefined ? { is_tm: Number(is_tm) } : {}) },
      })
    ).data,

  signResignation: async (contractId: string, payload: { signature: string }) => {
    const { data } = await instance.post(`/eor/terminations_v3/${contractId}/resignation_letter/sign`, payload);
    return data;
  },

  cutoffMessage: async (id: string | number) => {
    const { data } = await instance.get(`/eor/messages/${id}/cut_off`);
    return data;
  },

  startDatePushMessage: async (contractOid: string | number) => {
    const { data } = await instance.get(`/eor/messages/${contractOid}/start-date-push`);
    return data;
  },

  missingDataMessage: async () => {
    const { data } = await instance.get<MissingDataMessage>(`eor/messages/missing-data`);
    return data;
  },

  salaryChangeMessage: async (oid: string) => {
    const { data } = await instance.get(`/eor/messages/${oid}/salary-changes`);
    return data;
  },

  unpaidDepositMessage: async (oid: string) => {
    const { data } = await instance.get(`/eor/messages/${oid}/deposit`);
    return data;
  },

  setRefundMethod: async (
    id: string,
    payload: {
      refundMethodId?: string | null;
      refundUsingClientBalance?: boolean;
      refundClientBalanceCurrency?: string | null;
    }
  ) => {
    const { data } = await instance.put(`eor/${id}/refund-method`, payload);
    return data;
  },

  healthPlan: async (id: string) => {
    const { data } = await instance.get(`eor/health/${id}/plan`);
    return data;
  },

  validation: async (country: string, state?: string) => {
    const params = state ? { state } : {};
    const { data } = await instance.get(`/eor/validations/${country}`, { params });
    return data;
  },

  hourlyValidation: async (country: string, state?: string) => {
    const params = state ? { stateCode: state } : {};
    const { data } = await instance.get(`time_tracking/eor/country_validations/${country}`, {
      params,
      suppressSnackbar: true,
    });
    return data;
  },

  validationVersion: async (contractId: string) => {
    const { data } = await instance.get(`/eor/validations/version/${contractId}`);
    return data;
  },

  sign: async ({
    id,
    signature,
    title,
    isEmployeeOnboardingDelayed,
    isMobilityPreContract,
    suppressSnackbar = false,
  }: {
    id: string;
    signature: string;
    title: string;
    isEmployeeOnboardingDelayed: boolean;
    isMobilityPreContract?: boolean;
    suppressSnackbar?: boolean;
  }) => {
    const { data } = await instance.put(
      `/eor/${id}/sign`,
      {
        signature,
        title,
        isEmployeeOnboardingDelayed,
        isMobilityPreContract,
      },
      {
        suppressSnackbar,
      }
    );
    return data;
  },

  updateDelayOnboarding: async (id: string, payload: { isEmployeeOnboardingDelayed: boolean }) => {
    await instance.patch(`/eor/${id}/delay_onboarding`, payload);
  },

  updateEmployeeEmail: async (contractId: string, payload: { email: string }) => {
    const { data } = await instance.patch(`/eor/${contractId}/employee`, payload);
    return data;
  },

  regionWaitlistRequest: async (payload: IRegionWaitlistPayload) => {
    const { data } = await instance.post(`/eor/region_wait_list_requests`, payload);
    return data;
  },

  getJobKeywordsBlacklistAndWhitelist: async () => {
    const { data } = await instance.get(`/eor/job_keywords/blacklist_and_whitelist`);
    return data;
  },

  getEorDetails: async (eorId: number | string) => {
    const { data } = await instance.get(`/eor/${eorId}/details`);
    return data;
  },

  permissions: {
    setPermissions: async (payload: ISetPermissionsPayload, suppressSnackbar: boolean = false) => {
      const { data } = await instance.post(`/eor/access`, payload, { suppressSnackbar });
      return data;
    },
    requestPermission: async (contractOid: string, payload: IRequestPermissionPayload, config?: AxiosRequestConfig) => {
      const { data } = await instance.post(`/eor/access/${contractOid}/request`, payload, config);
      return data;
    },
  },

  adjustments: {
    getAdjustmentDetails: async (adjustmentId: string) => {
      const { data } = await instance.get(`/eor-experience/adjustment-requests/overview/${adjustmentId}`);
      return data as IAdjustment;
    },
  },

  partners: {
    cycles: {
      getActiveCycle: async (id: string): Promise<EORPaymentCycle> => {
        const { data } = await instance.get(`/eor/partners/cycle/${id}/active`);
        return data;
      },
    },
    offCycles: {
      addOffCycleInvoice: async (contractOid: string, payload: IPartnersOffCycleInvoicePayload) => {
        const { data } = await instance.post(`/eor/partners/one-off/${contractOid}`, payload);
        return data;
      },
      deleteOffCycleInvoice: async (invoiceId: string) => {
        const { data } = await instance.post(`/eor/partners/one-off/${invoiceId}/cancel`);
        return data;
      },
    },
    adjustments: {
      getPendingAdjustments: async () => {
        const { data } = await instance.get(`/eor/partners/adjustments/pending`);
        return data;
      },
      updateAdjustment: async (adjustmentId: number, payload: { tax?: number; type?: string }) => {
        const { data } = await instance.patch(`/eor/partners/adjustments/${adjustmentId}`, payload);
        return data;
      },
      reviewBatch: async (adjustmentIds: number[]) => {
        const { data } = await instance.put(`/eor/partners/adjustments/review`, { adjustmentIds });
        return data;
      },
      review: async (
        adjustmentId: number,
        payload: { isApprove: boolean; reviewReason?: string | null; tax?: number }
      ) => {
        const { data } = await instance.put(`/eor/partners/adjustments/${adjustmentId}/review`, payload);
        return data;
      },
    },
    terminate: {
      confirm: async (contractId: string, payload: { confirmedEndDate: string; reason?: string }) => {
        const { data } = await instance.put(`/eor/partners/terminate/${contractId}/confirm`, payload);
        return data;
      },
      revert: async (contractId: string) => {
        const { data } = await instance.put(`/eor/partners/terminate/${contractId}/revert`);
        return data;
      },
    },
  },

  redlineRequest: {
    save: async (contractId?: string, payload?: RedlineRequestPayload) => {
      const { data } = await instance.post(`/eor-experience/contract-redline-requests/${contractId}`, payload);
      return data;
    },
    saveCustomFileChanges: async (contractId: string, redlineRequestId: string, payload: OtherChangesFileProps) => {
      const { data } = await instance.post(
        `/eor-experience/contract-redline-requests/${contractId}/redline-request/${redlineRequestId}/custom-changes-file`,
        payload
      );
      return data;
    },
    update: async (contractId: string, redlineRequestId: string, payload: RedlineRequestPayload) => {
      const { data } = await instance.patch(
        `/eor-experience/contract-redline-requests/${contractId}/redline-request/${redlineRequestId}`,
        payload
      );
      return data;
    },
    cancel: async (contractId: string, redlineRequestId: string) => {
      const { data } = await instance.post(
        `/eor-experience/contract-redline-requests/${contractId}/redline-request/${redlineRequestId}/cancel`
      );
      return data;
    },
    getActive: async (contractId: string) => {
      const { data } = await instance.get(`/eor-experience/contract-redline-requests/${contractId}/active`);
      return data;
    },
    getCustomFileChanges: async (contractId: string, redlineRequestId: string) => {
      const { data } = await instance.get(
        `/eor-experience/contract-redline-requests/${contractId}/redline-request/${redlineRequestId}/custom-changes-file`
      );
      return data;
    },
    getSampleEA: async (contractId: string) => {
      const { data } = await instance.get(`/eor/${contractId}/quote/sample-ea`);
      return data;
    },
    createTemplateRedlineGoogleDocument: async (redlineId: string) => {
      const { data } = await instance.put(
        `/eor-experience/template-redline-requests/${redlineId}/create-refinement-google-document`
      );
      return data;
    },
    createContractRedlineGoogleDocument: async (contractId: string, changeRequestId: string) => {
      const { data } = await instance.put(
        `/eor-experience/contract-change-requests/${contractId}/${changeRequestId}/create-refinement-google-document`
      );
      return data;
    },
  },

  customVerificationLetter: {
    submitForm: async (contractId: string, payload: CustomVerificationLetterPayload) => {
      const { data } = await instance.post(
        `/eor-experience/employment-verification-letter/${contractId}/request`,
        payload
      );
      return data;
    },
  },

  employeeInfo: {
    updateEmergencyData: async (contractId: string, payload: EmergencyData) => {
      const { data } = await instance.patch(`/eor/employee_info/${contractId}/emergency`, payload);
      return data;
    },
    update: async (contractId: string, payload: UpdateEmployeeData, requestId?: number) => {
      const { data } = await instance.patch(`/eor/employee_info/${contractId}/${requestId}`, payload);
      return data;
    },
    save: async (contractId: string, payload: UpdateEmployeeData) => {
      const { data } = await instance.patch(`/eor/employee_info/${contractId}`, payload);
      return data;
    },
    delete: async (contractId: string, requestId?: number) => {
      const { data } = await instance.delete(`/eor/employee_info/${contractId}/${requestId}`);
      return data;
    },
    downloadFile: async (contractId: string, requestId?: number, fileId?: string) => {
      const { data } = await instance.get(`/eor/employee_info/${contractId}/${requestId}/${fileId}`);
      return data;
    },
    missingEmployeeInfo: async ({ oid, payload }: { oid: any; payload: object }): Promise<any> => {
      const { data } = await instance.post(`/eor/employee_info/${oid}/missing-employee-info`, payload);
      return data;
    },
    editEmployeeInfo: async ({ oid, payload }: { oid: any; payload: object }): Promise<any> => {
      const { data } = await instance.patch(`/eor/employee_info/${oid}/employee-info`, payload);
      return data;
    },
    getEmployeeInfoPerCountry: async (country: string) => {
      const { data } = await instance.get(`/eor/employee_info/country/${country}`);
      return data;
    },
  },

  oneOffPayment: {
    deleteAdjustment: async (oneOffPaymentId: string, adjustmentId: string) => {
      try {
        await instance.delete(
          `/eor-experience/one-off-payments/${oneOffPaymentId}/adjustment-requests/${adjustmentId}`
        );
        return true;
      } catch {
        return false;
      }
    },
    reviewAdjustment: async (
      oneOffPaymentId: string,
      adjustmentId: string,
      payload: { isApproved: boolean; declineReason?: string }
    ) => {
      try {
        await instance.patch(
          `/eor-experience/one-off-payments/${oneOffPaymentId}/adjustment-requests/${adjustmentId}/review`,
          payload
        );
        return true;
      } catch {
        return false;
      }
    },
    updateAdjustmentStatus: async (
      oneOffPaymentId: string,
      adjustmentId: string,
      payload: { isApproved: boolean; declineReason?: string }
    ) => {
      try {
        await instance.patch(
          `/eor-experience/one-off-payments/${oneOffPaymentId}/adjustment-requests/${adjustmentId}/update-status`,
          payload
        );
        return true;
      } catch {
        return false;
      }
    },
    create: async (
      contractId: string,
      payload: CreateOneOffPaymentPayload
    ): Promise<{ data: CurrentOneOffPayment } | undefined> => {
      try {
        return await instance.post(`/eor-experience/one-off-payments/${contractId}`, payload);
      } catch {
        return;
      }
    },
    discard: async (oneOffPaymentId: string) => {
      try {
        await instance.delete(`/eor-experience/one-off-payments/${oneOffPaymentId}/discard`);
        return true;
      } catch {
        return false;
      }
    },
    addAdjustment: async (
      oneOffPaymentId: string,
      payload: CreateOneOffPaymentPayload
    ): Promise<{ data: CurrentOneOffPayment } | undefined> => {
      try {
        return await instance.post(`/eor-experience/one-off-payments/${oneOffPaymentId}/adjustment-requests`, payload);
      } catch {
        return;
      }
    },
    submit: async (oneOffPaymentId: string) => {
      try {
        await instance.post(`/eor-experience/one-off-payments/${oneOffPaymentId}/submit`);
        return true;
      } catch {
        return false;
      }
    },
    cancelSubmission: async (
      oneOffPaymentId: string,
      payload?: {
        skipAdjustmentRequestIds: string[];
      }
    ) => {
      try {
        await instance.post(`/eor-experience/one-off-payments/${oneOffPaymentId}/cancel-submission`, payload);
        return true;
      } catch {
        return false;
      }
    },
  },
  incentivePlan: {
    getIncentivePlans: async (contractId: string) => {
      const { data } = await instance.get(`/eor-experience/incentive-plans/${contractId}`);
      return data;
    },
    add: async (contractId: string, payload: IncentivePlansValues) => {
      const { data } = await instance.post(`/eor-experience/incentive-plans/${contractId}`, payload);
      return data;
    },
    update: async (contractId: string, incentivePlanId: string, payload: IncentivePlansValues) => {
      const { data } = await instance.patch(
        `/eor-experience/incentive-plans/${contractId}/${incentivePlanId}`,
        payload
      );
      return data;
    },
    delete: async (contractId: string, incentivePlanId: string) => {
      const { data } = await instance.delete(`/eor-experience/incentive-plans/${contractId}/${incentivePlanId}`);
      return data;
    },
    calculateDeposit: async (payload: GetIPDeposit) => {
      const { data } = await instance.post<IPDepositResponse>(
        `/eor-experience/incentive-plans/calculate-deposit`,
        payload
      );
      return data;
    },
  },
  variableCompensation: {
    getVariableCompensations: async (contractId: string) => {
      const { data } = await instance.get(`/eor-experience/variable-compensations/${contractId}`);
      return data;
    },
    add: async (contractId: string, payload: VariableCompensation) => {
      const { data } = await instance.post(`/eor-experience/variable-compensations/${contractId}`, payload);
      return data;
    },
    update: async (contractId: string, varCompId: string, payload: VariableCompensation) => {
      const { data } = await instance.patch(
        `/eor-experience/variable-compensations/${contractId}/${varCompId}`,
        payload
      );
      return data;
    },
    delete: async (contractId: string, varCompId: string) => {
      const { data } = await instance.delete(`/eor-experience/variable-compensations/${contractId}/${varCompId}`);
      return data;
    },
    downloadAgreement: async (contractOid: string, query = {}) => {
      const { data } = await instance.get(`/eor/contracts/${contractOid}/incentive_plan_agreements/pdf`, {
        params: query,
      });
      return data;
    },
  },

  getUnsignedEorContracts: async () => {
    const { data } = await instance.get('/eor/contracts/awaiting_to_sign');
    return data;
  },

  calculateOffboardingCost: async (oid: string, payload: CalculateOffboardingCostPayload & { renderPDF?: false }) => {
    const { data } = await instance.post<CalculateOffboardingCostResult>(
      `eor/offboarding_costs/${oid}/calculate`,
      payload
    );
    return data;
  },

  renderOffboardingCostCalculationPdf: async (
    oid: string,
    payload: CalculateOffboardingCostPayload & { renderPDF?: true }
  ) => {
    const { data } = await instance.post<Blob>(
      `eor/offboarding_costs/${oid}/calculate`,
      {
        ...payload,
        renderPDF: true,
      },
      {
        responseType: 'blob',
      }
    );
    return data;
  },

  nettingOffDeposits: async (oid: string, payload: NettingOffPayload) => {
    await instance.post(`eor/deposits/${oid}/netting`, payload);
  },

  getDeelSolutionsQuoteValidation: async (country: string, clientEntityId?: string) => {
    const { data } = await instance.get(`eor/validations/${country}/deel_solutions?clientEntityId=${clientEntityId}`);
    return data;
  },

  getEORAdditionalCosts: async (country: string) => {
    const { data } = await instance.get(`/eor/validations/${country}/additional_costs`);
    return data;
  },

  getEORTransferOptions: async (country: string) => {
    const { data } = await instance.get(`/eor/validations/${country}/eor_transfer_options`);
    return data;
  },

  getHelp: (contractOid: string, content: string) => {
    return instance.post(`/eor/help/${contractOid}`, { content });
  },
  getSupportingDocumentsPDF: async (documentId?: string, query = {}): Promise<{ documentId: string }> => {
    try {
      const { data } = await instance.get(`/eor_shield_msa/supporting_documents/${documentId}/pdf`, {
        params: query,
      });
      return data;
    } catch (err: any) {
      throw new Error(err);
    }
  },
  getEmployerCosts: async (payload: any) => {
    const { data } = await instance.post<EmploymentCost>('/eor/employment_costs', payload);
    return data;
  },

  acceptProjectDescription: async (contractId: string) => {
    const { data } = await instance.post(`/eor/consultants/${contractId}/project_description`);
    return data;
  },

  cancelProjectDescriptionRequestedChanges: async (contractId: string) => {
    const { data } = await instance.post(`/eor/consultants/${contractId}/project_description/cancel_changes`);
    return data;
  },

  requestChangesForProjectDescription: async (contractId: string, payload: ProjectDescriptionRequestChangesPayload) => {
    const { data } = await instance.patch(`/eor/consultants/${contractId}/project_description`, payload);
    return data;
  },

  archiveContract: async (contractOid: string) => {
    const { data } = await instance.post(`eor/contracts/${contractOid}/archive`);
    return data;
  },

  isOnSiteRestricted: async (countryCode: string) => {
    const { data } = await instance.get(`/eor/validations/${countryCode}/on_site`);
    return data;
  },

  contractsLegalEntityMovement: {
    previewEntityAgreement: async (payload: {
      destinationLegalEntityId: string;
      contracts: { oid: string }[];
      signerProfileIds: string[];
    }) => {
      const { data } = await instance.post<{ pdfUrl: string }>(
        `/eor-experience/contracts-legal-entity-movement/preview-agreement`,
        payload
      );
      return data;
    },
    request: async (payload: {
      destinationLegalEntityId: string;
      contracts: { oid: string }[];
      signerProfileIds: string[];
    }) => {
      const { data } = await instance.post<IContractsEntityMovement>(
        `/eor-experience/contracts-legal-entity-movement`,
        payload
      );
      return data;
    },
    get: async (id: string) => {
      const { data } = await instance.get<IContractsEntityMovement>(
        `/eor-experience/contracts-legal-entity-movement/${id}`
      );
      return data;
    },
    downloadAgreement: async (id: string) => {
      const { data } = await instance.get<{ pdfUrl: string }>(
        `/eor-experience/contracts-legal-entity-movement/${id}/agreement`
      );
      return data;
    },
    signAgreement: async (id: string) => {
      const { data } = await instance.put<IContractsEntityMovement>(
        `/eor-experience/contracts-legal-entity-movement/${id}/sign`
      );
      return data;
    },
  },
};

export const getEorEndpoints = ({ contractId, employeeId, country, eorContractId, eorId }: GetEorEndpoints) => {
  const prefix = '/eor';

  return {
    getPaymentCycles: `${prefix}/contracts/${contractId}/cycles`,
    oneOffInvoices: `${prefix}/${contractId}/off-cycles`,
    depositRefunds: `${prefix}/contracts/${contractId}/deposit-refunds`,
    amendmentHistory: `${prefix}/amendments/${contractId}`,
    depositStatus: `${prefix}/${contractId}/deposit-status`,
    eorDepositStatus: `${prefix}/${eorContractId}/eor-deposit-status`,
    countryValidation: `${prefix}/validations/${country}`,
    countryValidationVersion: `${prefix}/validations/version/${contractId}`,
    getDeelSolutionsQuoteValidation: `${prefix}/validations/${country}/deel_solutions`,
    getAdditionalCosts: `${prefix}/validations/${country}/additional_costs`,
    bankDetailsMessages: `${prefix}/messages/bank-info-change-messages`,
    bankDetailsWarning: `${prefix}/messages/bank-info-change-warning`,
    payDatesMessages: `${prefix}/messages/pay-dates`,
    timeOffTotal: `${prefix}/time-offs/${contractId}/calculate/days-to-be-used`,
    taxDocuments: `${prefix}/tax-documents`,
    clientsWithDocPermissions: `${prefix}/access`,
    getPermissions: `${prefix}/access/${contractId}/status`,
    getPermissionsPerEmployee: `${prefix}/access/${employeeId}/employee-status`,
    getHrisPermissionsPerEmployee: `${prefix}/access/hris-profile/${employeeId}/employee-status`,
    resignationsToSign: `${prefix}/terminations_v3/employee/resignations_to_sign`,
    contractsCount: `${prefix}/created-contracts-count `,
    getClient: `${prefix}/${contractId}/client`,
    getEmployeeInfoData: `${prefix}/employee_info/${contractId}`,
    getDetails: `${prefix}/${eorId}/details`,
    getVariableCompensations: `${prefix}-experience/variable-compensations/${contractId}`,
    getIncentivePlans: `${prefix}-experience/incentive-plans/${contractId}`,
    getEorEaSigningState: `${prefix}/contracts/${contractId}/ea_signing_state`,
    getSalaryAdvanceLimit: `${prefix}-experience/contracts/${contractId}/salary-advance-limit`,
    getOneOffPaymentLimit: `${prefix}-experience/contracts/${contractId}/one-off-payment-limit`,
    getEorPlatformFee: `${prefix}/${contractId}/platform_fee`,
    getOneOffPaymentBuffer: `${prefix}/contracts/${contractId}/one_off_buffer`,
    getOneOffBusinessDays: `${prefix}/one_offs/business_days`,
    getConsultantHandbook: `${prefix}/consultants/${contractId}/deel_solutions_handbook/pdf`,
    getUnsignedEorContracts: `${prefix}/contracts/awaiting_to_sign`,
    getProjectDescription: `${prefix}/consultants/${contractId}/project_description`,
    getProjectDescriptionPDF: `${prefix}/consultants/${contractId}/project_description/pdf`,
    getPendingProjectDescriptions: `${prefix}/consultants/project_descriptions`,
    getPendingProjectAssignmentCheckins: `${prefix}/consultants/checkins`,
    createProjectAssignmentCheckinSubmission: `${prefix}/consultants/${contractId}/checkin`,
  };
};

export const getEorExperienceEndpoints = () => {
  const prefix = '/eor-experience';

  return {
    reviewAllowanceTasks: `${prefix}/country-specific-fixed-adjustments/tasks`,
  };
};
