import { CONTRACT_STATUSES } from '@/constants/contract';
import { FeatureFlags } from '@/constants/featureFlags';
import { ClientKycStatus, KycStatus } from '@/constants/kyc';
import { LS_HRIS_PEOPLE_LIST_CUSTOM_FILTERS_KEY } from '@/constants/localStorage';
import { OnboardingStatus } from '@/constants/organization';
import { RoleId } from '@/constants/roleNameToIdMap';
import { OnboardingSteps } from '@/constants/signup';
import type { WalletBalance } from '@/features/card/types';
import { AccountTypes } from '@/scenes/Signup/constants';
import { DEEL_ORG_ID } from '@/stores/OrganizationsStore';
import { ContractStatus, ContractType } from '@/types';
import type { ITeamItem } from '@/types/Teams';
import type { IUserStore, OrganizationProfile } from '@/types/User';
import { HrisScopes } from '@/types/User';
import companyApi from '@/utils/api/company';
import contractApi from '@/utils/api/contract';
import engageApi, { type EngageFeature } from '@/utils/api/engage/engage';
import hrisApi from '@/utils/api/hris';
import type { UpcomingInvoiceItem } from '@/utils/api/invoice';
import invoiceApi from '@/utils/api/invoice';
import paymentApi from '@/utils/api/payments/main';
import paymentV2Api from '@/utils/api/payments/mainV2';
import peoApi from '@/utils/api/peo';
import profilesApi from '@/utils/api/profiles';
import reportsApi from '@/utils/api/reports';
import userApi, { applyLoginSideEffects } from '@/utils/api/user';
import { isNative } from '@/utils/crossPlatform';
import { envShortKey } from '@/utils/environment';
import { clearLocalStorageKeys } from '@/utils/localstorage';
import { LS_CLEAR_ON_LOGOUT_KEYS } from '@/utils/localstorage/constants';
import { getUserAddress, isDeelUser } from '@/utils/user';
import { getDeelWalletEligibility } from '@/utils/withdrawals/wallets';
import isBefore from 'date-fns/isBefore';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import ContractsStore from './ContractsStore';
import LookupStore from './LookupStore';
import TeamStore from './TeamStore';

const INITIAL_STATE: Pick<IUserStore, 'withdrawEligibilityInfo' | 'scimPermissionConfig' | 'hris'> = {
  withdrawEligibilityInfo: {
    enabled: false,
  },
  scimPermissionConfig: {
    isOrganizationAdmin: false,
    hasAccess: false,
    hasSalesForceOwnerAccount: false,
  },
  hris: { Contracts: [], id: 0, oid: '', slackUserId: null },
};

export type VerificationLetterConfig = { organizationId: number; organizationPublicId: string; enabled: boolean };

const getInitialValue = (
  key: keyof IUserStore,
  value: any
):
  | never[]
  | 0
  | { hasAccess: boolean; hasSalesForceOwnerAccount: boolean; isOrganizationAdmin: boolean }
  | IUserStore['withdrawEligibilityInfo']
  | {
      Contracts: { id: string; oid: string; contractType?: string | undefined; status?: string | undefined }[];
      id: number;
      oid: string;
      slackUserId: string | null;
    }
  | null
  | undefined => {
  if (key in INITIAL_STATE) {
    return { ...(INITIAL_STATE[key as keyof typeof INITIAL_STATE] || {}) };
  } else if (Number.isInteger(value)) {
    return 0;
  } else if (Array.isArray(value)) {
    return [];
  } else if (typeof value !== 'function') {
    return null;
  }
  return value;
};

export class UserStore implements IUserStore {
  firstName: IUserStore['firstName'] = '';
  createdAt: IUserStore['createdAt'] = '';
  middleName: IUserStore['middleName'] = '';
  lastName: IUserStore['lastName'] = '';
  isAccountant: IUserStore['isAccountant'] = false;
  scimPermissionConfig: IUserStore['scimPermissionConfig'] = INITIAL_STATE.scimPermissionConfig;
  features: IUserStore['features'];
  isShieldProvider: IUserStore['isShieldProvider'] = false;
  otpOptions: IUserStore['otpOptions'] = [];
  isChangingCompanyType: IUserStore['isChangingCompanyType'] = false;
  isChangingOrganization: IUserStore['isChangingOrganization'] = false;
  addedNewMethod: IUserStore['addedNewMethod'] = false;
  isKYCSpecified: IUserStore['isKYCSpecified'] = false;
  username: IUserStore['username'] = '';
  signature: IUserStore['signature'] = '';
  signatureUrl: IUserStore['signatureUrl'] = null;
  deelFeePercent: IUserStore['deelFeePercent'] = '';
  flags: IUserStore['flags'] = 0;
  invitationMessage: IUserStore['invitationMessage'] = null;
  Company: IUserStore['Company'] = null;
  numberOfTeams: IUserStore['numberOfTeams'] = 0;
  taxForms: IUserStore['taxForms'] = [];
  availableProfiles: IUserStore['availableProfiles'] = [];
  displayRevolutBanner: IUserStore['displayRevolutBanner'] = false;
  signupRoute: IUserStore['signupRoute'] = '';
  employeeExternalId: IUserStore['employeeExternalId'] = null;
  hasPermission: IUserStore['hasPermission'] = () => false;
  isNewTransactionEnabled: IUserStore['isNewTransactionEnabled'] = () => false;
  isFetchingPaymentMethods: IUserStore['isFetchingPaymentMethods'] = false;
  hrisProfileIds: IUserStore['hrisProfileIds'] = [];
  hris: IUserStore['hris'] = INITIAL_STATE.hris;
  isPeopleDirectoryVisible: IUserStore['isPeopleDirectoryVisible'] = false;
  showWithdrawCompletePopup = false;
  selectedBalance: IUserStore['selectedBalance'] = undefined;
  token: IUserStore['token'] = localStorage.getItem('token');
  switchingToken: IUserStore['switchingToken'] = false;
  id: IUserStore['id'] = 0;
  userId: IUserStore['userId'] = 0;
  userPublicId: IUserStore['userPublicId'] = '';
  profilePublicId: IUserStore['profilePublicId'] = '';
  email = '';
  backupEmail = '';
  isBackupEmailVerified = false;
  isBackupEmailEnabled = false;
  name = '';
  activeContractsCount = 0;
  availableContractsCount = 0;
  picUrl = '';
  profileType: IUserStore['profileType'] = AccountTypes.Contractor;
  company: IUserStore['company'] = null;
  dob = '';
  compliance = '';
  suspected: IUserStore['suspected'] = false;
  team: IUserStore['team'] = null;
  teams = [];
  organization: IUserStore['organization'] = null;
  organizations: IUserStore['organizations'] = [];
  dashboard: IUserStore['dashboard'] = null;
  companyData: IUserStore['companyData'] = '';
  isAdvanceEnabled = false;
  hasPendingOrOutstandingAdvance = false;
  hasPreviousAdvances = false;
  isEor = false;
  isFinancialDataHidden = false;
  jobTitle = '';
  isEmployee = false;
  hideOnboarding = false;
  countries: IUserStore['countries'] = [];
  timezone = '';
  switchingProfile = false;
  organizationProfile: IUserStore['organizationProfile'] = null;
  hashid = '';
  currentOnboardingStep?: OnboardingSteps;

  // Payment related
  paid: IUserStore['paid'] = 0;
  earned = '';
  massPayment: IUserStore['massPayment'] = null;
  paymentMethods = '';
  selectedWithdrawMethodIndex = 0;
  withdrawMethods: IUserStore['withdrawMethods'] = [];
  wallets: IUserStore['wallets'] = [];
  archivedWithdrawMethods: IUserStore['archivedWithdrawMethods'] = [];
  withdrawMethodsError: IUserStore['withdrawMethodsError'] = null;
  upcomingPayments: Array<UpcomingInvoiceItem> | null = null;
  balance = '';
  balances: IUserStore['balances'] = [];
  currency = 'USD';

  balancesTotal: IUserStore['balancesTotal'] = null;
  processingWithdrawals = [];
  processingInvoices = [];
  isAutoWithdrawEnabled: IUserStore['isAutoWithdrawEnabled'] = false;
  showLimitError: IUserStore['showLimitError'] = false;
  isEarlyPayoutEnabled: IUserStore['isEarlyPayoutEnabled'] = '';
  totp: IUserStore['totp'] = false;
  isEorEnabled = false;
  isChangingToEntity = false;
  kyc = '';
  kycScreening: IUserStore['kycScreening'] = null;
  kycTracker: IUserStore['kycTracker'] = null;
  kycNameChangeScreening: IUserStore['kycNameChangeScreening'] = null;
  paymentLimit: IUserStore['paymentLimit'] = null;
  // UI
  showClearApprovalQueuePopup = false;
  // Manual verification
  canSubmitTranslation = false;
  screeningId = null;
  customVerificationStatus: IUserStore['customVerificationStatus'] = '';
  translationSubmitted = false;
  hasAttemptedVeriff = false;
  primaryDocumentType = '';
  canBecomeClient = true;
  EmployeeDetail = undefined;
  clientPaymentMethodsV2: IUserStore['clientPaymentMethodsV2'] = [];
  paymentOptions: IUserStore['paymentOptions'] = null;
  isFetchingPaymentMethodsV2 = false;
  isFetchingPaymentOptions = false;
  source = '';
  isKycNameChangeInProgress = false;
  isKYCScreeningNeeded = false;
  isKYCPendingReview = false;
  hasTaxAdviceAccess = false;
  hasContractorWorkedForUsa = false;
  hasContractorWorkedFromUsa = false;
  hasUsaLegalEntity = false;
  isDeeler = false;
  withdrawEligibilityInfo = INITIAL_STATE.withdrawEligibilityInfo;
  clientBalances: IUserStore['clientBalances'] = null;
  clientBalance: IUserStore['clientBalance'] = null;
  isFetchingClientBalances = false;
  isErrorFetchingClientBalances = false;
  hiddenOrgsForVerificationLetter: Array<VerificationLetterConfig> = [];
  isReferralsEnabled = false;
  isPayrollConnectEnabled: IUserStore['isPayrollConnectEnabled'] = [];
  customFieldsTasks: IUserStore['customFieldsTasks'] = null;
  shouldCheck2FA = false;
  isNativeOnboardingComplete = false;
  hasOptedOutOf2FA = false;
  paperTaxForm: IUserStore['paperTaxForm'] = false;
  isAnalyticsLiteEnabled: IUserStore['isAnalyticsLiteEnabled'] = false;
  isSelectedAllTeamsSidebar: IUserStore['isSelectedAllTeamsSidebar'] = false;
  unreadNotificationsCount: IUserStore['unreadNotificationsCount'] = 0;
  isInsightsEnabled: IUserStore['isInsightsEnabled'] = false;
  permissionsToRoles = {} as IUserStore['permissionsToRoles'];
  hrisOrganizationUser: IUserStore['hrisOrganizationUser'] = null;
  engageFeatures: IUserStore['engageFeatures'] = [];
  isSignContractorWalletAgreementTaskPending = false;
  peoEmployeeProfile: IUserStore['peoEmployeeProfile'] = undefined;
  appExperience: IUserStore['appExperience'] = undefined;
  hasPeoBenefitWindows: IUserStore['hasPeoBenefitWindows'] = false;
  isEngageProductActivationUserActive: IUserStore['isEngageProductActivationUserActive'] = true;

  constructor() {
    makeObservable(this, {
      unreadNotificationsCount: observable,
      setIsSelectedAllTeamsSidebar: action,
      kycNameChangeScreening: true,
      isKycNameChangeInProgress: true,
      paperTaxForm: true,
      showWithdrawCompletePopup: observable,
      selectedBalance: observable,
      token: observable,
      switchingToken: observable,
      id: observable,
      userId: observable,
      userPublicId: observable,
      profilePublicId: observable,
      email: observable,
      backupEmail: observable,
      isBackupEmailVerified: observable,
      isBackupEmailEnabled: observable,
      name: observable,
      activeContractsCount: observable,
      availableContractsCount: observable,
      picUrl: observable,
      profileType: observable,
      company: observable,
      dob: observable,
      compliance: observable,
      suspected: observable,
      team: observable,
      teams: observable,
      organization: observable,
      dashboard: observable,
      companyData: observable,
      isAdvanceEnabled: observable,
      hasPendingOrOutstandingAdvance: observable,
      hasPreviousAdvances: observable,
      EmployeeDetail: observable,
      isEor: observable,
      jobTitle: observable,
      isEmployee: observable,
      hideOnboarding: observable,
      paid: observable,
      earned: observable,
      massPayment: observable,
      paymentMethods: observable,
      selectedWithdrawMethodIndex: observable,
      withdrawMethods: observable,
      archivedWithdrawMethods: observable,
      wallets: observable,
      withdrawMethodsError: observable,
      upcomingPayments: observable,
      balance: observable,
      balances: observable,
      currency: observable,
      balancesTotal: observable,
      processingWithdrawals: observable,
      processingInvoices: observable,
      isAutoWithdrawEnabled: observable,
      showLimitError: observable,
      isEarlyPayoutEnabled: observable,
      totp: observable,
      isEorEnabled: observable,
      isFinancialDataHidden: observable,
      isChangingToEntity: observable,
      kyc: observable,
      kycScreening: observable,
      kycTracker: observable,
      paymentLimit: observable,
      countries: observable,
      clientPaymentMethodsV2: observable,
      paymentOptions: observable,
      countriesOptions: computed,
      switchingProfile: observable,
      showClearApprovalQueuePopup: observable,
      isDeelUser: computed,
      isDeeler: observable,
      isHrisReportsEnabled: computed,
      isAnalyticsLiteEnabled: observable,
      checkIfAnalyticsLiteIsEnabled: action,
      isKYCApproved: computed,
      isKYCPendingReview: observable,
      isClientBalanceEnabledForOrganization: computed,
      isNewPaymentColumnsEnabledForOrganization: computed,
      isAutoBillingEnabledForOrganization: computed,
      enablePaymentCurrencyForClientBalance: computed,
      isEorEarlyInvoicingEnabledForOrganization: computed,
      isNewPaymentFlowEnabled: computed,
      isClientBalanceAsClientMethodEnabledForOrganization: computed,
      isDepositIntoBalanceExchangeEnabledForOrganization: computed,
      canWithdraw: computed,
      canAddWithdrawalMethod: computed,
      hasClientAccount: computed,
      hasContractorAccount: computed,
      contractorProfiles: computed,
      hasShareholders: computed,
      isIndividual: computed,
      isLoggedIn: computed,
      hasVerifiedEmail: computed,
      isDeelOrAffiliate: computed,
      isAffiliate: computed,
      isAccountCreated: computed,
      allTeamsSelected: computed,
      isClient: computed,
      isUSDCurrency: computed,
      country: computed,
      defaultCurrency: computed,
      activeBalances: computed,
      isUSTaxPayer: computed,
      isUS: computed,
      fullName: computed,
      isContractor: computed,
      isTaxAdvisor: computed,
      isICP: computed,
      isDirectEmployee: computed,
      isDirectEmployeeFromUS: computed,
      isAnyEmployee: computed,
      hasPeoBenefitWindows: observable,
      getPeoBenefitsWindows: action,
      isGPEnabled: computed,
      setShowClearApprovalQueuePopup: action,
      update: action,
      clearMassPay: action,
      getPaymentDetails: action,
      getAccountBalances: action,
      getWithdrawMethods: action,
      getWallets: action,
      myWallet: action,
      getMyProfile: action,
      cacheProfileType: action,
      clear: action,
      clearDataForProfileSwitch: action,
      logout: action.bound,
      switchProfile: action,
      switchHrisOrganization: action,
      switchEORProfile: action,
      getAddress: action,
      changeActiveBalance: action,
      setWithdrawMethodIndex: action,
      canSubmitTranslation: observable,
      screeningId: observable,
      customVerificationStatus: observable,
      translationSubmitted: observable,
      getCustomverification: action,
      canBecomeClient: observable,
      hasReferralsPermission: computed,
      hasPayrollConnectHomeScreen: computed,
      currentOnboardingStep: observable,
      organizationProfile: observable,
      isOrganizationOwner: computed,
      getPaymentMethodsV2: action,
      getPaymentOptions: action,
      resetPaymentOptions: action,
      setPaymentMethodsV2: action,
      isOnboarding: computed,
      isOnboardingContractor: computed,
      isOnboardingClient: computed,
      isFetchingPaymentMethodsV2: observable,
      isFetchingPaymentOptions: observable,
      isOnboardingEmployee: computed,
      isOnboardingDirectEmployee: computed,
      isKYCScreeningNeeded: observable,
      isEorKycRequired: computed,
      hasTaxAdviceAccess: observable,
      hasContractorWorkedForUsa: observable,
      isHrisKycRequired: computed,
      withdrawEligibilityInfo: observable,
      clientBalance: observable,
      setClientBalance: action,
      clientBalances: observable,
      getClientBalances: action,
      isFetchingClientBalances: observable,
      isErrorFetchingClientBalances: observable,
      getOrgsSetupForVerificationLetter: action,
      hiddenOrgsForVerificationLetter: observable,
      setCustomFieldsTasks: action,
      customFieldsTasks: observable,
      shouldCheck2FA: observable,
      isNativeOnboardingComplete: observable,
      hasOptedOutOf2FA: observable,
      lastLoginMethod: computed,
      hasPasswordAsLoginMethod: computed,
      isLastLoginIDP: computed,
      isDeelHREnabled: action,
      isHrisFeatureEnabled: action,
      isFeatureEnabledOld: action,
      isFeatureEnabled: action,
      isHrisScopeEnabled: action,
      isEngageFeatureEnabled: action,
      isContractorsOutsideDeelEnabled: computed,
      isFeatureEnabledForOrganization: action,
      isSignContractorWalletAgreementTaskPending: observable,

      // Not annotated properties:
      // More about annotations https://mobx.js.org/observable-state.html#creating-observable-state
      firstName: false,
      createdAt: false,
      middleName: false,
      lastName: false,

      // @todo: set as false to avoid unexpected issues.
      // We need to check it per property and optimize performance of the User Store.
      // If you can check some properties and verify that be very careful changing these values.
      isAccountant: false,
      scimPermissionConfig: false,
      features: false,
      isShieldProvider: false,
      otpOptions: false,
      isChangingCompanyType: false,
      isChangingOrganization: false,
      addedNewMethod: false,
      isKYCSpecified: false,
      username: false,
      signature: false,
      signatureUrl: false,
      deelFeePercent: false,
      flags: false,
      invitationMessage: false,
      Company: false,
      numberOfTeams: false,
      taxForms: false,
      displayRevolutBanner: false,
      signupRoute: false,
      employeeExternalId: false,
      hasPermission: false,
      isNewTransactionEnabled: false,
      isFetchingPaymentMethods: false,
      hrisProfileIds: false,
      hris: false,
      isPeopleDirectoryVisible: false,
      organizations: false,
      timezone: false,
      hashid: false,
      hasAttemptedVeriff: false,
      primaryDocumentType: false,
      source: false,
      isReferralsEnabled: false,
      isPayrollConnectEnabled: false,
      initialize: false,
      isOrgKYCApproved: false,
      mainOrganization: false,
      hrisId: false,
      isHrisDirectEmployee: false,
      isPeoEmployee: false,
      userType: false,
      isOnboardingPeoEmployee: false,
      hasBackgroundChecksEnabled: false,
      setCdd1ScreeningStatus: false,
      setCdd2ScreeningStatus: false,
      setTeam: false,
      setOrganizationProfile: false,
      resolveCompanyPersonalProperty: false,
      hasRole: false,
      hasTeams: false,
      isFeatureEnabledByContract: true,
      isSeniorityJobTitleEnabled: false,
      isTimeOffEnabled: false,
      isDeelOrgClient: false, // should be computed, but used without get keyword.
      isDepartmentCustomField: false, // computed
      isNewHiringStatusEnabled: false, // computed

      // error appears if make those observable:
      availableProfiles: false, // this property was mutated inside component which cause an error.
      isSelectedAllTeamsSidebar: observable,
      setUnreadNotificationsCount: action,
      isInsightsEnabled: false,
      permissionsToRoles: observable,
      hrisOrganizationUser: false,
      hrisOrganizationUserId: computed,
      isLocationSectionHidden: computed,
      isMultiRateContractsFeatureEnabled: computed,
      isContractExtensionFeatureEnabled: computed,
      engageFeatures: false,
      peoEmployeeProfile: observable,
      isAnyContractActive: computed,
      appExperience: observable,
      isLightweightExperience: false,
      isEngageProductActivationUserActive: true,
    });
    this.initialize();
  }

  initialize() {}

  loadEngageFeatures = async () => {
    try {
      this.engageFeatures = await engageApi.getEngageFeatures();
    } catch (error) {
      console.error(error);
    }
  };

  isEngageFeatureEnabled = (featureSlug: EngageFeature['slug']) => {
    return this.engageFeatures?.find?.((feature) => feature.slug === featureSlug)?.isEnabled === true;
  };

  loadUserProductActivationStatus = async () => {
    if (this.isClient) return;
    try {
      const data = await engageApi.getProductActivationUserStatus();
      if (data) {
        this.isEngageProductActivationUserActive = data?.isActive;
      }
    } catch (error) {
      console.error(error);
    }
  };

  isUserActiveWithProductActivation = () => {
    if (!this.isEngageFeatureEnabled('product_activation')) return true;
    return this.isEngageProductActivationUserActive;
  };

  getPaymentMethodsV2: IUserStore['getPaymentMethodsV2'] = async (rootInvoiceId) => {
    try {
      this.isFetchingPaymentMethodsV2 = true;
      const data = await paymentV2Api.getPaymentMethodsV2(rootInvoiceId);

      runInAction(() => {
        this.clientPaymentMethodsV2 = data;
        this.isFetchingPaymentMethodsV2 = false;
      });
    } catch (error) {
      console.error(error);
      this.isFetchingPaymentMethodsV2 = false;
    }
  };

  getPaymentOptions: IUserStore['getPaymentOptions'] = async (rootInvoiceId) => {
    try {
      this.isFetchingPaymentOptions = true;
      const data = await paymentV2Api.getPaymentOptions(rootInvoiceId);

      runInAction(() => {
        this.paymentOptions = data?.paymentOptions || null;
        this.isFetchingPaymentOptions = false;
      });
    } catch (error) {
      this.paymentOptions = null;
      this.isFetchingPaymentOptions = false;
    }
  };

  resetPaymentOptions = (): void => {
    this.paymentOptions = null;
  };

  getClientBalances: IUserStore['getClientBalances'] = async () => {
    try {
      this.isFetchingClientBalances = true;
      this.isErrorFetchingClientBalances = false;

      const data = await paymentV2Api.getClientBalances();
      runInAction(() => {
        this.clientBalances = data;
        this.isFetchingClientBalances = false;

        if (!this.clientBalance) {
          const currencyInStorage = localStorage.getItem('clientBalanceCurrency');
          const lastUsedBalance = currencyInStorage
            ? data.find((balance) => balance.currency === currencyInStorage)
            : null;

          this.clientBalance = lastUsedBalance || data[0];
        } else {
          this.clientBalance = data.find((balance) => balance.currency === this.clientBalance?.currency) ?? null;
        }
      });
    } catch {
      this.clientBalances = null;
      this.clientBalance = null;
      this.isErrorFetchingClientBalances = true;
      this.isFetchingClientBalances = false;
    }
  };

  setClientBalance: IUserStore['setClientBalance'] = (balance) => {
    this.clientBalance = balance;
    localStorage.setItem('clientBalanceCurrency', balance.currency);
  };

  setPaymentMethodsV2: IUserStore['setPaymentMethodsV2'] = (paymentMethods) => {
    this.clientPaymentMethodsV2 = paymentMethods;
  };

  get isHrisReportsEnabled() {
    const isFeatureFlagOn = LookupStore.getFeatureFlag('hris.isAnalyticsEnabled');

    return (
      this.isHrisScopeEnabled(HrisScopes.fullAccess) &&
      (isFeatureFlagOn || !!localStorage.getItem('deel-hris-analytics-debug'))
    );
  }

  async checkIfAnalyticsLiteIsEnabled() {
    if (this.isFeatureEnabled(FeatureFlags.isReportViewLiteEnabled)) {
      try {
        const response = await reportsApi.loadCustomReportsList();
        if (response?.reports.length) {
          runInAction(() => {
            this.isAnalyticsLiteEnabled = true;
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  get isDeelUser() {
    if (!this.email) return false;
    return isDeelUser(this.email);
  }

  get isKYCApproved() {
    if (this.isClient) {
      return this?.organization?.screeningStatus === ClientKycStatus.Approved || this.kyc === KycStatus.Approved;
    }
    return this.kyc === KycStatus.Approved;
  }

  get isClientBalanceEnabledForOrganization() {
    return LookupStore.getFeatureFlag('payments.isClientBalancesEnabled') && this.organization?.isClientBalanceEnabled;
  }

  get isNewPaymentFlowEnabled() {
    return Boolean(this.organization?.isNewPaymentFlowEnabledV3);
  }

  get isNewPaymentColumnsEnabledForOrganization() {
    return Boolean(this.organization?.shouldUseNewPaymentColumns);
  }

  get isAutoBillingEnabledForOrganization() {
    return LookupStore.getFeatureFlag('payIns.isAutoBillingEnabled') && this.organization?.isAutoBillingEnabled;
  }

  get enablePaymentCurrencyForClientBalance() {
    return Boolean(this.organization?.isUsePaymentCurrencyInClientBalanceEnabled);
  }

  get isEorEarlyInvoicingEnabledForOrganization() {
    return Boolean(this.organization?.isEorEarlyInvoicingEnabled);
  }

  get isClientBalanceAsClientMethodEnabledForOrganization() {
    return (
      // @ts-ignore
      this.organization?.isClientBalanceAsClientMethodEnabled &&
      LookupStore.getFeatureFlag('payIns.isClientBalanceAsClientMethodEnabled')
    );
  }

  get isDepositIntoBalanceExchangeEnabledForOrganization() {
    return (
      LookupStore.getFeatureFlag('payIns.isSelectingEorDepositRefundCurrencyEnabled') &&
      this.organization?.isDepositIntoBalanceExchangeEnabled
    );
  }

  get isOrgKYCApproved() {
    return this.isClient && this?.organization?.screeningStatus === ClientKycStatus.Approved;
  }

  get canAddWithdrawalMethod() {
    return this.isKYCApproved;
  }

  get canWithdraw() {
    return this.withdrawEligibilityInfo?.enabled;
  }

  get mainOrganization() {
    const activeProfile = this.hrisProfileIds?.find(({ isActive }) => isActive);

    return activeProfile
      ? this.organizations?.find(({ id }) => id === activeProfile?.organizationId)
      : this.organization || this.organizations?.[0];
  }

  get hrisId() {
    return this.hrisProfileIds.find((profile) => profile.organizationId === this.mainOrganization?.id)?.oid;
  }

  get hasClientAccount() {
    return this.availableProfiles.some(({ profile_type }) => profile_type === 'client');
  }

  get hasContractorAccount() {
    return this.availableProfiles.some(({ profile_type }) => profile_type === 'contractor');
  }

  get contractorProfiles() {
    return this.availableProfiles.filter(({ profile_type }) => profile_type === 'contractor');
  }

  get hasShareholders() {
    // @ts-expect-error
    if (LookupStore.entityTypes.find(({ value }) => value === this.companyData?.legalStatus)?.disabledShareholders)
      return false;
    if (this.isIndividual) return false;
    // @ts-expect-error
    return !!this.companyData?.shareholders?.length;
  }

  get isIndividual() {
    return this.company?.type === 'individual';
  }

  get isLoggedIn() {
    if (!isNative()) {
      return !!this.id;
    }
    const isNativeOnboardingComplete =
      localStorage.getItem('isOnboardingCompleted') === 'true' || this.isNativeOnboardingComplete;
    return !!this.id && isNativeOnboardingComplete;
  }

  get hasVerifiedEmail() {
    // @ts-expect-error
    return !!this.verifiedAt;
  }

  get isDeelOrAffiliate() {
    // @ts-expect-error
    return this.eor?.isDeel || this?.eor?.isAffiliate;
  }

  get isAffiliate() {
    // @ts-expect-error
    return this?.eor?.isAffiliate;
  }

  get isAccountCreated() {
    if (this.isICP) return true;
    if (this.isDirectEmployee) return true;
    if (this.isEor) return true;
    if (this.isPeoEmployee) return Boolean(this.peoEmployeeProfile);

    const hasTimeZone = Boolean(this.timezone) || Boolean(this.company?.timezone);

    if (!hasTimeZone) return false;

    const isNewSignup = Boolean(this.currentOnboardingStep);

    if (!isNewSignup) {
      if (this.isAnyEmployee) {
        return Boolean(this.resolveCompanyPersonalProperty('phone') || this.company?.phone);
      }
      return Boolean(this.company?.personal);
    }

    const isAtLeastProfileCompleted = Boolean(
      this.currentOnboardingStep &&
        [OnboardingSteps.ProfileCompleted, OnboardingSteps.OnboardingCompleted].includes(this.currentOnboardingStep)
    );

    if (isAtLeastProfileCompleted || this.isEmployee) {
      return isAtLeastProfileCompleted;
    }

    if (this.isClient) {
      return Boolean(this.dob);
    }

    if (this.isHrisDirectEmployee) {
      return Boolean(this.company?.personal);
    }

    return Boolean(this.company?.personal?.zip) && (this.company?.type !== 'company' || Boolean(this.company?.invoice));
  }

  get allTeamsSelected() {
    return !this.team?.id && this.isClient;
  }

  get isGPEnabled() {
    return !!this?.organization?.globalPayrollEnabled;
  }

  get isVendor() {
    return this.profileType === AccountTypes.VmsVendor;
  }

  get isClient() {
    return this.profileType === 'client';
  }

  get isDirectEmployee() {
    return this.profileType === 'direct_employee';
  }

  get isHrisDirectEmployee() {
    return this.profileType === 'hris_direct_employee';
  }

  get isPeoEmployee() {
    return this.profileType === 'peo_employee';
  }

  get isAnyEmployee() {
    return this.isEmployee || this.isDirectEmployee || this.isPeoEmployee || this.isHrisDirectEmployee;
  }

  get isDirectEmployeeFromUS() {
    return this.isDirectEmployee && this.availableProfiles.find((profile) => profile.id === this.id)?.country === 'US';
  }

  get userType() {
    if (this.isTaxAdvisor) return 'TAX_ADVISOR';
    if (this.isDirectEmployee) return 'DIRECT_EMPLOYEE';
    if (this.isHrisDirectEmployee) return 'HRIS_DIRECT_EMPLOYEE';
    if (this.isContractor) return 'CONTRACTOR';
    if (this.isEmployee) return 'EMPLOYEE';
    if (this.isEor) return 'EOR';
    if (this.isPeoEmployee) return 'PEO_EMPLOYEE';
    if (this.isVendor) return 'VENDOR';
    return 'CLIENT';
  }

  get isUSDCurrency() {
    return this.currency === 'USD';
  }

  get country() {
    const citizen = this.company?.personal?.citizen?.value || this.company?.personal?.citizen;
    if (this.isClient || this.isVendor) {
      return this.company?.country || this.company?.citizen?.value || this.company?.citizen;
    } else {
      return this.company?.personal?.country || citizen;
    }
  }

  get countriesOptions() {
    return this.countries
      .map((country) => LookupStore.countries[country])
      .sort((countryA, countryB) => (countryA.label > countryB.label ? 1 : -1));
  }

  get defaultCurrency() {
    const { company } = this;
    if (!company) {
      return 'USD';
    }
    const country = company.type === 'company' ? company.country : company.personal?.country;
    return LookupStore.countries[country]?.defaultCurrency || 'USD';
  }

  get activeBalances() {
    return this.balances;
  }

  get isUSTaxPayer() {
    return this.company?.personal?.taxResidence === 'US';
  }

  get isUS() {
    return this.company?.personal?.taxResidence === 'US' || this.company?.personal?.citizen === 'US';
  }

  get fullName() {
    if (this.company?.personal?.firstName) {
      const { firstName, lastName } = this.company.personal;
      return `${firstName}${lastName ? ' ' + lastName : ''}`;
    }

    return this.name;
  }

  get isTaxAdvisor() {
    return this.profileType === AccountTypes.TaxAdvisor;
  }

  get isContractor() {
    return (
      !this.isClient &&
      !this.isEmployee &&
      !this.isEor &&
      !this.isICP &&
      !this.isDirectEmployee &&
      !this.isHrisDirectEmployee &&
      !this.isTaxAdvisor &&
      !this.isPeoEmployee &&
      !this.isVendor
    );
  }

  get isOnboarding() {
    const isEnabledUserType =
      this.isClient || this.isContractor || this.isEmployee || this.isDirectEmployee || this.isPeoEmployee;
    const isOnboardingCompleted = this.isClient
      ? this.organization?.currentOnboardingStatus === OnboardingStatus.Completed
      : this.currentOnboardingStep === OnboardingSteps.OnboardingCompleted;

    const currentStepIsNotNull = this.currentOnboardingStep !== null;

    return isEnabledUserType && !isOnboardingCompleted && currentStepIsNotNull;
  }

  get isOnboardingContractor() {
    return this.isContractor && this.isOnboarding;
  }

  get isOnboardingClient() {
    return this.isClient && this.isOnboarding;
  }

  get isOnboardingPeoEmployee() {
    return this.isPeoEmployee && this.isOnboarding;
  }

  get isOnboardingEmployee() {
    return this.isEmployee && this.isOnboarding;
  }

  get isOnboardingDirectEmployee() {
    return this.isDirectEmployee && this.isOnboarding;
  }

  get isICP() {
    return this.profileType === AccountTypes.Icp;
  }

  get hasReferralsPermission() {
    if (!this.isReferralsEnabled) return false;
    if (!LookupStore.getFeatureFlag('referrals.displayMenuItem')) return false;

    if (this.isClient || this.isContractor) return this.isKYCApproved;
    return this.isEmployee || this.isDirectEmployee || this.isEor;
  }

  get hasPayrollConnectHomeScreen() {
    return this.isPayrollConnectEnabled?.some((entity) => entity.isEnabled) ?? false;
  }

  get hasBackgroundChecksEnabled() {
    if (this.isClient) {
      const flag = LookupStore.getFeatureFlag('backgroundCheck.allowedOrganizations');
      if (!flag) return false;
      if (flag === true) return true;
      if (!Array.isArray(flag)) return false;
      return flag.includes(this.organization?.id);
    }
    // We enabled BGC for all worker types
    return true;
  }

  setShowClearApprovalQueuePopup: IUserStore['setShowClearApprovalQueuePopup'] = (value) => {
    this.showClearApprovalQueuePopup = value;
  };
  update: IUserStore['update'] = async (data) => {
    for (const [key, value] of Object.entries(data).filter(([, value]) => value !== undefined)) {
      // @ts-ignore
      this[key] = value;
    }

    if (!this.currency) {
      this.currency = 'USD';
    }

    const hasBalances = this.activeBalances.length > 0;

    //Pick the most recently used balance if funded, otherwise most funded balance as active;
    if (!this.selectedBalance?.currency && hasBalances) {
      let currency = '';
      const sortedBalances = this.activeBalances.sort((a, b) => +b.amount - +a.amount);
      const lastBalanceCurrency = localStorage.getItem('lastUsedBalanceCurrency');
      let assignedBalance = lastBalanceCurrency
        ? sortedBalances.find((balance) => balance.currency === lastBalanceCurrency && Number(balance.amount) > 0)
        : undefined;
      if (!assignedBalance) {
        const hasBalanceWithAmount = this.activeBalances.some((balance) => Number(balance.amount) > 0);
        if (hasBalanceWithAmount) {
          currency = sortedBalances[0]?.currency;
        } else {
          try {
            if (!this.upcomingPayments) {
              const { rows } = await invoiceApi.getUpcoming({ limit: 1, offset: 0 });
              runInAction(() => {
                this.upcomingPayments = rows;
              });
            }

            currency = this.upcomingPayments?.[0]?.currency || this.currency;
          } catch (error) {
            console.error(error);
          }
        }
        const balance = this.activeBalances.find((balance) => balance.currency === currency);
        assignedBalance = balance ? balance : this.activeBalances[0];
      }
      this.changeActiveBalance(assignedBalance);
    }
    this.cacheProfileType();
  };

  async getOrgsSetupForVerificationLetter() {
    // Contractors are unlinkely to have more than 3 orgs

    const promises = (this.organizations ?? []).map((org) => {
      return contractApi.getVerificationLetterSetup(org?.organizationPublicId || org?.id);
    });

    const hiddenOrgs: Array<VerificationLetterConfig> = [];

    (await Promise.all(promises)).forEach((org) => {
      if (org) {
        if (!org.enabled) {
          hiddenOrgs.push(org);
        }
      }
    });

    this.hiddenOrgsForVerificationLetter = hiddenOrgs;
  }

  clearMassPay() {
    this.update({ massPayment: { amount: 0, invoicesIds: [] } });
  }

  cacheProfileType = () => {
    let type = this.profileType || 'general';

    if (this.isEor) {
      type = AccountTypes.Eor;
    } else if (this.isEmployee) {
      type = AccountTypes.Employee;
    }

    localStorage.setItem('user_type', type);
  };

  async getPaymentDetails() {
    try {
      const paymentDetails = await paymentApi.getPaymentDetails();
      //Reselect the current balance to update the amount
      if (this.selectedBalance?.currency) {
        this.changeActiveBalance(undefined);
        this.changeActiveBalance({ ...this.selectedBalance });
      }
      this.update(paymentDetails);
    } catch (error) {
      console.error(error);
    }
  }

  async getAccountBalances() {
    try {
      const balanceResponse = await paymentApi.getAccountBalances();
      //Reselect the current balance to update the amount
      if (this.selectedBalance?.currency) {
        this.changeActiveBalance(undefined);
        this.changeActiveBalance({ ...this.selectedBalance });
      }
      this.update(balanceResponse);
    } catch (error) {
      console.error(error);
    }
  }

  async getWithdrawMethods() {
    try {
      this.withdrawMethodsError = null;
      const allWithdrawMethods = await paymentApi.getWithdrawMethods();
      const archivedWithdrawMethods = allWithdrawMethods.filter((method) => method.deletedAt);
      const withdrawMethods = allWithdrawMethods.filter((method) => !method.deletedAt);
      this.update({ archivedWithdrawMethods, withdrawMethods });
    } catch (error) {
      this.withdrawMethodsError = error as unknown as any;
      console.error(error);
    }
  }

  async getWallets() {
    const isEligible = getDeelWalletEligibility(this, LookupStore);
    if (!isEligible) return;
    if (this.isSignContractorWalletAgreementTaskPending) return;
    try {
      const data = await paymentApi.wallets();
      this.update(data);
    } catch (err) {
      console.error(err);
    }
  }

  myWallet: IUserStore['myWallet'] = (provider) => {
    return this.wallets.find((wallet) => wallet.provider?.toLowerCase() === provider.toLowerCase());
  };

  async getMyProfile() {
    try {
      const userData = await profilesApi.getMyProfile();
      this.update(userData);
    } catch (error) {
      console.error(error);
    }
  }

  async getPeoBenefitsWindows() {
    const benefitsEnabledByFeatureFlag = this.isFeatureEnabledForOrganization(FeatureFlags.PEO_BENEFITS);
    if (benefitsEnabledByFeatureFlag) {
      this.update({
        hasPeoBenefitWindows: benefitsEnabledByFeatureFlag,
      });
      return;
    }
    const contractOid = this.hris?.Contracts?.find?.(
      ({ status, contractType }) =>
        (status === ContractStatus.IN_PROGRESS || status === ContractStatus.ONBOARDED) &&
        contractType === ContractType.PEO
    )?.oid;
    if (!contractOid) return;
    try {
      const { data: benefitWindowList } = await peoApi.fetchBenefitWindows(contractOid); // TODO finish the BE integration
      this.update({
        hasPeoBenefitWindows: !!benefitWindowList?.some((benefitWindow) =>
          isBefore(new Date(benefitWindow.startDate), new Date())
        ),
      });
    } catch (error) {
      console.error(error);
    }
  }

  async clear() {
    for (const [key, value] of Object.entries(this as IUserStore)) {
      // @ts-expect-error
      this[key] = getInitialValue(key as keyof IUserStore, value);
    }
    localStorage.removeItem('token');
    localStorage.removeItem('missingRegNumberModalControl');
    localStorage.removeItem('lastUsedBalanceCurrency');
    this.token = null;
    this.balances = [];
    TeamStore.teams = [];
    ContractsStore.clearContracts();
  }

  async clearDataForProfileSwitch() {
    // @ts-expect-error
    this.isEarlyPayoutEnabled = false;
    this.suspected = false;
    this.paymentLimit = null;
  }

  logout: IUserStore['logout'] = async (idle) => {
    try {
      // we need this to clear filters on /people page
      // if there will be more filters, it's better to store it in a separate function
      clearLocalStorageKeys([
        ...LS_CLEAR_ON_LOGOUT_KEYS,
        ...LS_HRIS_PEOPLE_LIST_CUSTOM_FILTERS_KEY(this.organizations ? this.organizations : []),
      ]);
      await userApi.logout(idle);
    } catch (error) {
      console.error(error);
    } finally {
      await this.clear();
    }
  };

  async switchProfile(opt = {}, query?: string) {
    try {
      this.switchingProfile = true;
      await this.clearDataForProfileSwitch();
      const { token } = await userApi.login({ type: this.isClient ? 'contractor' : 'client', ...opt });
      this.update({ token });
      // This update ensures that we don't lose any important query parameters when navigating.
      window.location.href = query ? `/${query}` : '/'; // FIXME: hotfix for issues detected in DEV-6505. We probably need to find a better solution for cleaning all the user data while switching account types
    } catch (err) {
      console.error(err);
    }
  }

  // This is used only for demo purposes
  async switchUserByEmail(email: string) {
    if (envShortKey === 'prod') return;
    const clientToken = this.token;
    try {
      await userApi.getTokenByEmail(email);
      localStorage.setItem('clientToken', clientToken as string);
      window.location.href = '/';
    } catch (err) {
      console.error(err);
    }
  }

  async switchBackToClient(token: string) {
    if (envShortKey === 'prod') return;
    try {
      localStorage.removeItem('clientToken');
      await userApi.setToken(token);
      applyLoginSideEffects({ opt: {}, token });
      window.location.href = '/';
    } catch (err) {
      console.error(err);
    }
  }

  switchHrisOrganization: IUserStore['switchHrisOrganization'] = async (id) => {
    try {
      const res = await hrisApi.employee.switchHrisOrganization(id);
      if (res !== null) {
        this.update({ token: res.data.token });
      }
      return res;
    } catch (err) {
      console.error(err);
    }
  };

  switchEORProfile: IUserStore['switchEORProfile'] = async (country, profileId, resetLocation = true) => {
    try {
      if (resetLocation) this.switchingProfile = true;
      await this.clearDataForProfileSwitch();
      const { token } = await userApi.login({ type: 'contractor', country, profileId });
      this.update({ token });
      // FIXME: hotfix for issues detected in DEV-6505. We probably need to find a better solution for cleaning all the user data while switching account types
      if (resetLocation) {
        window.location.pathname = '/';
      } else {
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  };

  async getCustomverification() {
    try {
      const { data } = await companyApi.getCustomVerification();

      if (!data) {
        this.customVerificationStatus = '';
        return;
      }

      this.canSubmitTranslation = data.canSubmitTranslation;
      this.screeningId = data.id;
      this.customVerificationStatus = data.status;
      this.translationSubmitted = data.translationSubmitted;
      this.hasAttemptedVeriff = data.hasAttemptedVeriff;
      this.primaryDocumentType = data.primaryDocumentType;
    } catch (error) {
      console.error(error);
    }
  }

  getAddress: IUserStore['getAddress'] = (type) => {
    const path = type
      ? { company: 'company', invoice: 'company.invoice', team: 'team.company' }[type]
      : 'company.personal';
    const data = get(this, path);

    return getUserAddress(data, LookupStore);
  };

  get isOrganizationOwner() {
    return this.organizationProfile?.roleId === RoleId.OrganizationAdmin;
  }

  get lastLoginMethod() {
    // @ts-expect-error
    return orderBy(Object.keys(this.lastLoginData || {}), (key) => this.lastLoginData[key], 'desc')[0];
  }

  get hasPasswordAsLoginMethod() {
    // @ts-ignore
    return Object.keys(this.lastLoginData || {}).includes('password');
  }

  get isLastLoginIDP() {
    const nonIDPMethods = ['password', 'totp', 'google'];
    const lastLoginMethod = this.lastLoginMethod;

    return Boolean(lastLoginMethod && !nonIDPMethods.includes(lastLoginMethod));
  }

  changeActiveBalance = (balance?: WalletBalance) => {
    if (balance?.currency) {
      localStorage.setItem('lastUsedBalanceCurrency', balance.currency);
    }
    this.selectedBalance = balance;
  };
  setWithdrawMethodIndex: IUserStore['setWithdrawMethodIndex'] = (index) => {
    this.selectedWithdrawMethodIndex = index;
  };
  setCdd1ScreeningStatus: IUserStore['setCdd1ScreeningStatus'] = (status) => {
    if (!this.organization) return;
    this.organization.cdd1Screening = status;
  };
  setCdd2ScreeningStatus: IUserStore['setCdd2ScreeningStatus'] = (status) => {
    if (!this.organization) return;
    this.organization.cdd2Screening = status;
  };

  setTeam = (team: ITeamItem) => {
    this.team = { ...team };
  };

  setOrganizationProfile = (profile: OrganizationProfile) => {
    this.organizationProfile = { ...profile };
  };

  resolveCompanyPersonalProperty: IUserStore['resolveCompanyPersonalProperty'] = (property) => {
    let value = this.company?.personal?.[property];

    if (!value) {
      const otherProfile = (this.availableProfiles || []).find((other) => other.profile_type !== this.profileType);
      value = otherProfile?.company?.personal?.[property];
    }

    return value;
  };

  hasRole: IUserStore['hasRole'] = (roles, teamId) => {
    const team = teamId ? TeamStore.findTeamInTeams(teamId) : this.team;
    return Boolean(Array.isArray(roles) ? roles?.some((role) => role === team.roleId) : roles === team.roleId);
  };

  get hasTeams() {
    if (!TeamStore.teamsLoaded) return undefined;
    return TeamStore.teams?.some((team) => team.organization?.id === this.organization?.id);
  }

  get isEorKycRequired() {
    return this.isEmployee && this.isKYCScreeningNeeded;
  }

  get isHrisKycRequired() {
    return this.isHrisDirectEmployee && this.isKYCScreeningNeeded;
  }

  isFeatureEnabledForOrganization: IUserStore['isFeatureEnabledForOrganization'] = (
    featureKey,
    organizationId = this.organization?.id
  ) => {
    return (
      !!this.features?.[featureKey]?.result?.some(
        (item) => item.isEnabled && (!this.isClient || item.id === organizationId)
      ) ||
      (LookupStore.getFeatureFlag(featureKey) &&
        Array.isArray(LookupStore.getFeatureFlag(featureKey)) &&
        LookupStore.getFeatureFlag(featureKey).includes(organizationId))
    );
  };

  isFeatureEnabledByContract: IUserStore['isFeatureEnabledByContract'] = (featureKey, contractId) => {
    return !!this.features?.[featureKey]?.result?.some(
      (item) => item.isEnabled && item.model === 'contract' && (!contractId || item.id === contractId)
    );
  };

  // Used for old feature flags. It doesn't include checking against organization model. See isFeatureEnabled for new usage.
  isFeatureEnabledOld: IUserStore['isFeatureEnabledOld'] = (featureKey) => {
    return !!this.features?.[featureKey]?.result?.some(
      (item) =>
        item.isEnabled &&
        ((item.model === 'profile' && (item.id === this.id || String(item.id) === this.profilePublicId)) ||
          (item.model === 'user' && (item.id === this.userId || String(item.id) === this.userPublicId)))
    );
  };

  // Use this for new frontend feature flags. This will include organization model checking.
  // See https://wiki.deel.network/i/11924 for more details.
  isFeatureEnabled: IUserStore['isFeatureEnabled'] = (featureKey) => {
    // Get worker org id
    const getWorkerOrgId = () =>
      this.organizations?.find(
        (organization) => organization.organizationPublicId === this.hris?.organization?.organizationPublicId
      )?.id;

    return !!this.features?.[featureKey]?.result?.some(
      (item) =>
        item.isEnabled &&
        ((item.model === 'profile' && (item.id === this.id || String(item.id) === this.profilePublicId)) ||
          (item.model === 'user' && (item.id === this.userId || String(item.id) === this.userPublicId)) ||
          (item.model === 'organization' &&
            (this.isClient
              ? item.id === this.organization?.id || String(item.id) === this.organization?.organizationPublicId
              : item.id === getWorkerOrgId() || String(item.id) === this.hris?.organization?.organizationPublicId)))
    );
  };

  isHrisScopeEnabled: IUserStore['isHrisScopeEnabled'] = (key = HrisScopes.fullAccess, organizationId) => {
    const organizationById = organizationId
      ? this.organizations?.find((organization) => organization.id === organizationId)
      : this.organizations?.[0];

    const organizationScopes = this.organization?.hrisScopes || organizationById?.hrisScopes || [];

    // @ts-expect-error
    return organizationScopes?.includes(key);
  };

  get isSeniorityJobTitleEnabled() {
    return true;
  }

  isDeelHREnabled: IUserStore['isDeelHREnabled'] = (organizationId) => {
    if (organizationId) {
      const organization = this.organizations?.find((organization) => organization.id === organizationId);

      return Boolean(organization?.hrisEnabled);
    }

    return Boolean(this.organization?.hrisEnabled ?? this.organizations?.[0]?.hrisEnabled);
  };

  isHrisFeatureEnabled: IUserStore['isHrisFeatureEnabled'] = (key, organizationId) => {
    return this.isDeelHREnabled(organizationId) || this.isHrisScopeEnabled(key, organizationId);
  };

  get isNewHiringStatusEnabled() {
    // @ts-expect-error
    return (LookupStore.getFeatureFlag('hris.newHiringStatusEnabledFor') || [])?.includes(this.deel);
  }

  isTimeOffEnabled(hrisProfileOid?: any) {
    const organization = (() => {
      if (this.organization) return this.organization;

      if (hrisProfileOid) {
        const activeProfile = this.hrisProfileIds.find((profile) => profile.oid === hrisProfileOid);
        if (activeProfile) return this.organizations?.find(({ id }) => id === activeProfile.organizationId);
      }

      return this.organizations?.[0];
    })();

    const isDeelHRFeatureEnabled = organization?.hrisDirectoriesVisibility?.pto;
    return isDeelHRFeatureEnabled !== false;
  }

  isDeelOrgClient() {
    // TODO: XTR-1124 Refactor check for Deel employee
    return this.organization?.id === DEEL_ORG_ID;
  }

  setCustomFieldsTasks: IUserStore['setCustomFieldsTasks'] = (data) => {
    this.customFieldsTasks = data;
  };

  get isDepartmentCustomField() {
    return true;
  }

  get isContractorsOutsideDeelEnabled() {
    return (this.isClient && this.organization?.hrisContractorOutsideDeelEnabled) ?? false;
  }

  setIsSelectedAllTeamsSidebar: IUserStore['setIsSelectedAllTeamsSidebar'] = (value: boolean) => {
    this.isSelectedAllTeamsSidebar = value;
  };
  setUnreadNotificationsCount: IUserStore['setUnreadNotificationsCount'] = (value: number) => {
    this.unreadNotificationsCount = value;
  };

  get hrisOrganizationUserId() {
    return this.hrisOrganizationUser?.id ?? null;
  }

  get isLocationSectionHidden() {
    return this.isFeatureEnabled(FeatureFlags.hideLocationSectionsFor);
  }

  get isMultiRateContractsFeatureEnabled() {
    return this.isFeatureEnabled(FeatureFlags.MULTI_RATE_CONTRACTS);
  }

  get isContractExtensionFeatureEnabled() {
    return this.isFeatureEnabled(FeatureFlags.CONTRACT_EXTENSION);
  }

  get isAnyContractActive() {
    return this?.hris?.Contracts?.some((contract) => contract.status === CONTRACT_STATUSES.inProgress.type);
  }

  get isLightweightExperience() {
    return (!this.isClient && this.appExperience?.isMinimalRequirements) || false;
  }
}

const store = new UserStore();

export default store;
