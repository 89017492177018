import i18n from 'i18next';
import { useCallback } from 'react';
import { CellValue, Cell, H4, H6, Icon, IconButton, Stack } from '@letsdeel/ui';

interface Props {
  amendedItems: any;
  item: any;
  onRestartFormValue?: (value: string) => void;
  isSummary?: boolean;
  isOldAmendment?: boolean;
}

const HOLIDAYS_KEYS = ['holidaysIncrease', 'holidaysDecrease', 'holidays'];

const TimeOffCell = ({ item, amendedItems, isSummary, onRestartFormValue, isOldAmendment }: Props) => {
  const holidays = amendedItems?.find((item: { key: string }) => HOLIDAYS_KEYS.includes(item.key))?.uiValue;
  const holidaysOld = amendedItems?.find((item: { key: string }) => HOLIDAYS_KEYS.includes(item.key))?.oldValue;
  const handleRestartData = useCallback(() => {
    onRestartFormValue?.(i18n.t('temp.eor.scenes.RequestChanges.700e2ef2ce', { v0: item.key }).toString());
    onRestartFormValue?.(`amendments.holidays`);
  }, [onRestartFormValue, item.key]);

  if (isSummary) {
    return (
      <Cell>
        <Stack spacing={0.3}>
          <H4 regular color="text.disabled">
            {i18n.t('temp.eor.scenes.RequestChanges.53ae1cdba2').toString()}
          </H4>
          <Stack direction="row" justifyContent="space-between" alignItems="start">
            <Stack spacing={0.3}>
              <H4 color="tertiary.main">{`${item.uiValue} ${
                holidays ? i18n.t('temp.eor.scenes.RequestChanges.c0198ca961', { v0: holidays }).toString() : ''
              }`}</H4>
              <H6>{`Previous: ${item.oldValue} ${
                holidaysOld ? i18n.t('temp.eor.scenes.RequestChanges.c0198ca961', { v0: holidaysOld }).toString() : ''
              }`}</H6>
            </Stack>
            <IconButton onClick={handleRestartData} size="medium">
              <Icon.TrashNew />
            </IconButton>
          </Stack>
        </Stack>
      </Cell>
    );
  }

  if (isOldAmendment) {
    return (
      <Stack alignItems="end">
        {item.uiValue}
        {holidays ? <H6>{`${holidays} days`}</H6> : null}
      </Stack>
    );
  }

  return (
    <CellValue label={i18n.t('temp.eor.scenes.RequestChanges.53ae1cdba2').toString()}>
      <Stack alignItems="end">
        {item.uiValue}
        {holidays ? <H6>{`${holidays} days`}</H6> : null}
      </Stack>
    </CellValue>
  );
};

export default TimeOffCell;
