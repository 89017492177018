import instance from '@/utils/api/instance';

export default {
  deleteCycle: async ({ cycleId }: { cycleId: string }) => {
    const { data } = await instance.delete(`payment_cycles/${cycleId}`);
    return data;
  },

  getInvoicePdfBeforeInvoiceGenerated: async (cycleId: string, wait = false) => {
    const { data } = await instance.get(`payment_cycles/${cycleId}/pdf?noredirect=1${wait ? '&wait=1' : ''}`);
    return data;
  },

  toggleWithholdingTax: async (cycleId: string, disabled: boolean) => {
    const { data } = await instance.put(`payment_cycles/${cycleId}/withholding-tax`, { disabled });
    return data;
  },

  getWithholdingTaxEstimate: async (cycleId: string) => {
    const { data } = await instance.get(`payment_cycles/${cycleId}/withholding-tax/estimate`);
    return data;
  },
};
