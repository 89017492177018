import { useEffect } from 'react';
import { Button, H4, H6, Icon, Link, P, Stack, TextField } from '@letsdeel/ui';
import type { FormikHelpers } from 'formik';
import { Field, Form, Formik } from 'formik';
import { NativeContainer } from '@/native/NativeContainer';
import { useTranslation } from 'react-i18next';
import { FORGOT_PASSWORD_INITIAL_VALUES, FORGOT_PASSWORD_VALIDATION_SCHEMA } from '../consts';
import { type ForgotPasswordInitialValues } from '../types';
import { Edges } from '@/types/Edges';
import { addNativeForgotPasswordEvent } from '../utils/nativeEvents';

interface Props {
  goBackAction?: () => void;
  token?: string;
  isLoading: boolean;
  customDomainHostName?: string;
  onSubmitForgotPassword: (
    values: ForgotPasswordInitialValues,
    options?: FormikHelpers<ForgotPasswordInitialValues>
  ) => void;
}

export const NativeTypeEmailForm = ({
  goBackAction,
  isLoading,
  customDomainHostName,
  onSubmitForgotPassword,
}: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    addNativeForgotPasswordEvent('Enter Email Address');
  }, []);

  const onSubmit = (values: ForgotPasswordInitialValues, formikHelpers: FormikHelpers<ForgotPasswordInitialValues>) => {
    addNativeForgotPasswordEvent('User Clicks on Button');
    onSubmitForgotPassword(values, formikHelpers);
  };

  const addNativeChangeEvent = () => {
    addNativeForgotPasswordEvent('User Enters Email Address');
  };

  return (
    <Formik
      key="forgot-form"
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={FORGOT_PASSWORD_INITIAL_VALUES}
      validationSchema={FORGOT_PASSWORD_VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors }) => (
        <Form onChange={addNativeChangeEvent}>
          <NativeContainer edges={[Edges.TOP]} sx={{ backgroundColor: 'background.paper' }}>
            <Stack px={2} sx={{ flex: 1 }}>
              <Icon.BackArrow onClick={goBackAction} size={24} />
              <P
                sx={{
                  fontFamily: 'Bagoss',
                  fontSize: '32px',
                  fontWeight: 500,
                  lineHeight: '40px',
                  textAlign: 'left',
                }}
                mt={4}
              >
                {t('authentication.resetPassword.title')}
              </P>
              {customDomainHostName ? (
                <H6 className="text-center">
                  <span className="capitalize">{customDomainHostName}</span>{' '}
                  {t('authentication.resetPassword.deelPortal')}
                </H6>
              ) : null}
              <Stack mb={2} />
              <Field
                label={t('authentication.resetPassword.emailLabel')}
                name="email"
                error={!!errors.email}
                helperText={errors.email}
                as={TextField}
              />
              <Stack
                sx={{
                  flex: 1,
                  justifyContent: 'flex-end',
                }}
              >
                <Button type="submit" loading={isSubmitting || isLoading}>
                  {t('authentication.resetPassword.submit')}
                </Button>
                <H4
                  sx={{
                    fontWeight: 400,
                    textAlign: 'center',
                  }}
                  mb={2}
                  mt={1}
                >
                  {t('authentication.resetPassword.helperText')}{' '}
                  <Link
                    href="https://help.letsdeel.com/hc/en-gb/requests/new?ticket_form_id=360001676798"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('authentication.resetPassword.helperLinkText')}
                  </Link>
                </H4>
              </Stack>
            </Stack>
          </NativeContainer>
        </Form>
      )}
    </Formik>
  );
};

export default NativeTypeEmailForm;
