import useFeatureFlag, { EFeatureFlag } from '@/hooks/useFeatureFlag';
import { FeatureFlags } from '@/constants/featureFlags';
import { isNative } from '@/utils/crossPlatform';
import useStores from '../useStores';

export const useIsBottomNavigationEnabled = () => {
  const {
    windowSize: { isMobile },
  } = useStores();
  const isBottomNavigationEnabled = useFeatureFlag(FeatureFlags.isBottomNavigationEnabled, EFeatureFlag.BOOLEAN_BASED);
  return isNative() || (isMobile && isBottomNavigationEnabled);
};
