import { EventActions, EventProducts } from '@/utils/analytics/constants';
import { addDataLayerMobile } from '@/utils/nativeDataLayer';

export const addPinCodeDataLayer = (event_action: string, params?: Record<string, string>) =>
  addDataLayerMobile({
    event_product: EventProducts.PIN_CODE,
    event_action: event_action,
    ...params,
  });

export const addSetupPinCodeEvent = (params?: Record<string, string>) =>
  addPinCodeDataLayer(EventActions.Setup, params);
