import instance from '@/utils/api/instance';

export default {
  update: async ({ id, payload }: any) => {
    const { data } = await instance.patch(`/contracts/${id}/amendment`, payload);
    return data;
  },

  sign: async (id: string, signature?: string | null) => {
    const { data } = await instance.post(`/contracts/${id}/amendment/sign`, signature ? { signature } : null);
    return data;
  },

  cancel: async (id: string) => {
    const { data } = await instance.delete(`/contracts/${id}/amendment`);
    return data;
  },

  delegateSignature: async ({ id, message, inviteeId }: any) => {
    const { data } = await instance.post(`/contracts/${id}/amendment/delegate_sign`, { message, inviteeId });
    return data;
  },
};
