import React from 'react';
import type { MouseEvent } from 'react';
import { Button, P, Stack } from '@letsdeel/ui';
import type { SxProps } from '@letsdeel/ui';
import type { GraphicType } from '@/components/Graphic';
import Graphic from '@/components/Graphic';
import { NativeContainer } from '@/native/NativeContainer';
import { Edges } from '@/types/Edges';

type Props = {
  illustration?: GraphicType;
  title: string;
  subtitle: string;
  primaryButton: {
    text: string;
    onClick: (e: MouseEvent) => void;
  };
  secondaryButton?: {
    text: string;
    onClick: (e: MouseEvent) => void;
  };
  graphic?: React.ReactNode;
  contentClassName?: string;
  sx?: SxProps;
};

interface DialogContentProps extends Props {
  styles?: {
    content?: SxProps;
    title?: SxProps;
    subTitle?: SxProps;
    container?: SxProps;
    graphics?: SxProps;
  };
}

export const NativeDialogContainer = (props: Props) => {
  return (
    <NativeContainer
      edges={[Edges.TOP, Edges.BOTTOM]}
      sx={{
        backgroundColor: 'background.paper',
        ...props.sx,
      }}
    >
      <DialogContent {...props} />
    </NativeContainer>
  );
};

export const DialogContent = ({
  illustration,
  title,
  subtitle,
  primaryButton,
  secondaryButton,
  graphic,
  contentClassName,
  styles,
}: DialogContentProps) => {
  return (
    <Stack
      sx={{
        paddingBottom: '16px',
        justifyContent: 'flex-end',
        flex: 1,
        ...styles?.container,
      }}
    >
      <Stack
        spacing={2}
        sx={{ marginBottom: '100px', alignItems: 'center', ...styles?.content }}
        className={contentClassName}
      >
        {graphic || <Graphic sx={styles?.graphics} type={illustration || 'success-blob'} />}
        <P
          sx={{
            fontSize: 28,
            lineHeight: '40px',
            letterSpacing: '-1.5%',
            fontFamily: 'Bagoss Condensed',
            textAlign: 'center',
            ...styles?.title,
          }}
          medium
        >
          {title}
        </P>
        <P
          sx={{
            fontFamily: 'Inter',
            fontSize: 16,
            lineHeight: '26px',
            letterSpacing: '-0.005em',
            textAlign: 'center',
            ...styles?.subTitle,
          }}
        >
          {subtitle}
        </P>
      </Stack>
      <Stack spacing={2}>
        <Button color="primary" onClick={primaryButton.onClick}>
          {primaryButton.text}
        </Button>
        {secondaryButton ? (
          <Button color="secondary" onClick={secondaryButton.onClick}>
            {secondaryButton.text}
          </Button>
        ) : (
          <></>
        )}
      </Stack>
    </Stack>
  );
};
