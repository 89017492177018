import { Button, Illustration, Stack } from '@letsdeel/ui';
import { cn } from '../../utils/main';
import Graphic from '../Graphic';
import Heading from '../Heading';
import Popup from '../Popup';
import i18next from 'i18next';

/**
 * @param {{
 * show?: any,
 * onHide?: any,
 * title?: string | ReactNode,
 * subtitle?: any,
 * graphic?: any,
 * illustration?: illustration,
 * showGraphic?: any,
 * children?: any,
 * buttonText?: any,
 * closeButton?: any,
 * onClick?: any,
 * showPopupButtons?: any,
 * usePopupHeaders?: any,
 * }} param0
 */
const InfoPopup = ({
  show,
  onHide = undefined,
  title = null,
  subtitle = null,
  graphic = 'info-blob',
  illustration = null,
  showGraphic = true,
  children = <></>,
  buttonText = <span>{i18next.t('common.actions.ok')}</span>,
  closeButton = false,
  onClick,
  showPopupButtons = true,
  usePopupHeaders = false,
}) => (
  <Popup
    className="text-center"
    show={show}
    centered
    onHide={onHide || onClick}
    closeButton={closeButton}
    data-qa="info-popup"
    subtitle={usePopupHeaders ? subtitle : ''}
    title={usePopupHeaders ? title : ''}
  >
    <Stack spacing={2}>
      {showGraphic ? (
        illustration ? (
          <Stack alignItems={'center'}>
            <Illustration width={128} height={128} illustration={illustration} />
          </Stack>
        ) : (
          <Graphic type={graphic} />
        )
      ) : null}
      <Stack spacing={1}>
        {!usePopupHeaders && (
          <>
            {typeof title === 'string' ? (
              <Heading className={cn(subtitle ? 'mt-7' : 'mb-4')} size={2}>
                {title}
              </Heading>
            ) : (
              title
            )}
          </>
        )}
        {children}
      </Stack>
    </Stack>
    {showPopupButtons ? (
      <Button className="mt-9" onClick={onClick} sx={{ minWidth: '100px' }}>
        {buttonText}
      </Button>
    ) : null}
  </Popup>
);

export default InfoPopup;
