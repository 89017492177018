// translation:no-need
import * as Sentry from '@sentry/react';
import log from 'loglevel';
import { createRoot } from 'react-dom/client';
import { SITE_IS_DOWN, STAGE, IS_COOKIE_TOKEN, RELEASE_NAME } from './constants/main';
import Maintenance from './scenes/Maintenance';
import { BrowserRouter } from 'react-router-dom';
import { loadExternalScripts, zendeskOpenPostMessage } from '@/external/externalScripts';
import { ExternalScriptsHandler } from '@/external/utils';
import { initEventsTracking } from '@/external/analyticsEventsTracking';

import LoadingScreen from '@/components/LoadingScreen';
import dataLayer from '@/utils/dataLayer';
import { URL_REGEX } from '@/utils/regex';
import UserStore from './stores/UserStore';
import { PERMISSIONS_DEBUG_KEY } from '@/context/Authorization/AuthorizationProvider';
import userApi from '@/utils/api/user';
import { DYNAMIC_RENDER_DEBUG_KEY } from '@/scenes/People/DynamicRender/DynamicRender.constants';
import { isNative } from '@/utils/crossPlatform';
import axios from 'axios';
import { persistLocalStorageHandler } from '@/native/General/persistLocalStorageHandler';
import { handleBackButton } from '@/native/General/nativeBackButtonHandler';
import { OfflineErrorPage } from './native/Offline/OfflineErrorPage/OfflineErrorPage';
import { AppLaunchStatusUtil } from '@/native/AppLaunchStatus/AppLaunchStatus';

// Polyfills to support .replaceAll and .at
import 'core-js/es/string/replace-all';
import 'core-js/es/array/at';
import 'core-js/es/string/at';

// polyfill to support structuredClone
import 'core-js/actual/structured-clone';

// Polyfill for CSSStyleSheet
import 'construct-style-sheets-polyfill';
import googleTranslateExtensionWorkaround from '@/utils/googleTranslateExtensionWorkaround';

// CON-6828 Override Yup email validation to match BE validation
import './utils/emailValidation';

window.log = log;
log.setDefaultLevel((localStorage.getItem('LOG_LEVEL') || 'warn').toLowerCase());

const changeStage = (newStage) => {
  localStorage.setItem('VITE_STAGE', newStage);
  location.reload();
};

window._dev = () => {
  changeStage('development');
};

window._local = () => {
  changeStage('local');
};

window._giger = (gigerEnv) => {
  if (!gigerEnv) {
    console.warn("You didn't provide a giger env");
    return;
  }
  const isURL = gigerEnv.match(URL_REGEX);
  changeStage(isURL ? gigerEnv : `https://api-${gigerEnv}.giger.training`);
};

const PERMISSIONS_DEBUG_OPTIONS = ['orgSets', 'teamSets', 'allSets', 'noSets'];

window._permissionsDebug = (...args) => {
  if (args.length === 0 && localStorage.getItem(PERMISSIONS_DEBUG_KEY)) localStorage.removeItem(PERMISSIONS_DEBUG_KEY);
  else localStorage.setItem(PERMISSIONS_DEBUG_KEY, JSON.stringify(args.length === 0 ? ['noSets'] : args));
  // eslint-disable-next-line no-console
  console.log('Permissions debug mode is now', localStorage.getItem(PERMISSIONS_DEBUG_KEY) ? 'ON' : 'OFF');
  // eslint-disable-next-line no-console
  console.log(`Possible options: `, PERMISSIONS_DEBUG_OPTIONS);
  location.reload();
};

window._dynamicRenderDebug = () => {
  if (localStorage.getItem(DYNAMIC_RENDER_DEBUG_KEY)) localStorage.removeItem(DYNAMIC_RENDER_DEBUG_KEY);
  else localStorage.setItem(DYNAMIC_RENDER_DEBUG_KEY, 'true');
  // eslint-disable-next-line no-console
  console.log('Dynamic render debug mode is now ', localStorage.getItem(DYNAMIC_RENDER_DEBUG_KEY) ? 'ON' : 'OFF');
  location.reload();
};

window.DEEL_VERSION = import.meta.env.VITE_CI_COMMIT_SHA ? RELEASE_NAME : 'unknown';

window.forceCrash = () => {
  setTimeout(() => {
    throw new Error('Simulated Crash');
  }, 0);
};

const IGNORED_ERRORS = ['amplitude is not defined', `Can't find variable: amplitude`];

Sentry.init({
  release: RELEASE_NAME,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: [Sentry.browserTracingIntegration({ enableInp: true })],
  tracesSampleRate: 0.25,
  ignoreErrors: ['Non-Error exception captured with keys'],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
  ],
  beforeSend: (event, hint) => {
    const error = hint.originalException;
    const isIgnoredError = IGNORED_ERRORS.some((item) => error?.message?.includes(item));
    if (error?.message && isIgnoredError) return null;

    return event;
  },
});

const appendCustomDomainStyles = () => {
  if (!window.deelConfig.data.secondaryColor && !window.deelConfig.data.secondaryColor) return;
  const style = document.createElement('style');
  style.appendChild(
    document.createTextNode(`
    :root {
      --primary-color:${window.deelConfig.data.primaryColor} !important;
      --secondary-color:${window.deelConfig.data.secondaryColor} !important;
    }
    `)
  );
  document.head.appendChild(style);
};

const renderErrorPage = (err, root) => {
  root.render(<LoadingScreen show={true} />);
  console.error('Sorry. There was a problem loading domain config.', err.message);
};

const getUserAuth = async (forceGetToken = false) => {
  //Temporary check for token in local storage
  const oldToken = localStorage.getItem('token');

  if (oldToken) {
    UserStore.token = oldToken;
    if (IS_COOKIE_TOKEN) {
      await userApi.setToken(oldToken);
      localStorage.removeItem('token');
    }
  }

  const { token } = UserStore;
  if ((!token && IS_COOKIE_TOKEN) || forceGetToken) {
    const newToken = await userApi.getToken();
    UserStore.token = newToken;
  }
};

googleTranslateExtensionWorkaround();

const container = document.getElementById('root');
const root = createRoot(container);

async function loadTranslations() {
  const currentLanguage = localStorage.getItem('language') || 'en';
  document.querySelector('html').lang = currentLanguage;

  if (currentLanguage === 'en') {
    return;
  }

  try {
    const version = `?v=${window.DEEL_VERSION}`;
    const response = await fetch(`/translations/${currentLanguage}.json${version}`, {
      cache: 'force-cache',
    });
    if (!response.ok) {
      throw new Error('Failed to load translation file');
    }
    const data = await response.json();
    window.translations = data;
  } catch (error) {
    console.error('Error loading translations:', error);
  }
}

const renderApp = async () => {
  await loadTranslations();
  (await import('./i18n'));
  const App = (await import('./App')).default;

  if (window.location.pathname === '/zd_init_chat') {
    /**
     * Route: /zd_init_chat
     * This route is used to open chat on help desk page.
     * Checks if user is logged in and sends a postMessage which will be processed there.
     * For development and local testing move /misc/helpdesk.html to /public and
     * set VITE_HELP_DESK_DOMAIN to your http://localhost:port and the same to APP_DOMAIN in localStorage
     * `true` param on getUserAuth is used to force token request only on this path as token is not needed on help desk page
     * and it existence can't be taken from localStorage due to different domains and recent changes in browser security
     */
    await getUserAuth(true);
    zendeskOpenPostMessage();
  } else {
    dataLayer.set({
      env: STAGE === 'production' ? 'prod' : 'dev',
      language: 'en',
      version: document.querySelector('html').dataset.version,
    });
    initEventsTracking();
    loadExternalScripts();

    if (window.location.pathname !== '/setup') {
      await getUserAuth();
    }

    const configExists = await window.loadDomainConfig;
    if (configExists && window.deelConfig) {
      appendCustomDomainStyles();
      const windowTitleStorageKey = 'customDomainWindowTitle';
      if (window.deelConfig?.data?.windowTitle) {
        document.title = window.deelConfig.data.windowTitle;
        localStorage.setItem(windowTitleStorageKey, window.deelConfig.data.windowTitle);
      } else {
        document.title = 'Deel - Your forever people platform';
        localStorage.removeItem(windowTitleStorageKey);
      }
    }

    root.render(
      <BrowserRouter>
        <ExternalScriptsHandler>
          <App />
        </ExternalScriptsHandler>
      </BrowserRouter>
    );
  }
};

const start = () => {
  const isNetworkError = (err) => axios.isAxiosError(err) && !err.response && err.request;

  if (SITE_IS_DOWN) {
    root.render(<Maintenance />);
  } else {
    (async () => {
      try {
        await renderApp(root);
      } catch (err) {
        if (isNative() && isNetworkError(err)) {
          root.render(<OfflineErrorPage />);
        } else {
          renderErrorPage(err, root);
        }
      }
    })();
  }
};

if (!isNative()) {
  // For web - start
  start();
} else {
  AppLaunchStatusUtil.registerKeys();
  if (localStorage.getItem('LS_INTACT') !== 'true') {
    // For native app - if needed - first load local storage from persistent storage.
    // Local storage is fetched when code is imported, so reload is needed to apply changes
    persistLocalStorageHandler().then(() => {
      localStorage.setItem('LS_INTACT', 'true');
      location.reload();
    });
  } else {
    // For native app - start
    handleBackButton();
    start();
  }
}
