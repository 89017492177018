import { BottomNavigationContext } from '@/context/BottomNavigation/BottomNavigationContext';
import useUserStore from '@/hooks/useUserStore';
import { useContext } from 'react';
import { useIsBottomNavigationEnabled } from './useIsBottomNavigationEnabled';

export const useCheckIfShouldShowBottomNav = () => {
  const { bottomNavShown, isKeyboardVisible } = useContext(BottomNavigationContext);
  const user = useUserStore();
  const isBottomNavigationEnabled = useIsBottomNavigationEnabled();

  return isBottomNavigationEnabled && bottomNavShown && user?.isLoggedIn && !isKeyboardVisible;
};
