import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { Icon, P, Stack } from '@letsdeel/ui';

export interface Props {
  password: string;
  title?: string;
  className?: string;
  onPasswordValidated?: (state: { isValid: boolean }) => void;
  hidePreviousPasswordsWarning?: boolean;
  passwordPolicyList: {
    id: string;
    label: string;
    regex: RegExp;
  }[];
}

interface PolicyItem {
  id: string;
  label: string;
  match: boolean;
}

const PasswordPolicy = ({
  password,
  onPasswordValidated,
  hidePreviousPasswordsWarning,
  passwordPolicyList,
  title = i18n.t('temp.platform-fe.components.PasswordPolicy.yourPasswordMustContain').toString(),
}: Props) => {
  const policyItemMapper = ({ id, regex, label }: { id: string; regex: RegExp; label: string }): PolicyItem => ({
    id,
    label,
    match: !!password && regex?.test(password),
  });

  const policyItems = passwordPolicyList.map(policyItemMapper);

  onPasswordValidated?.({ isValid: policyItems.every((policy) => policy.match) });

  return (
    <Stack gap={1}>
      <P medium fontSize={12}>
        {hidePreviousPasswordsWarning ? (
          title
        ) : (
          <Trans
            i18nKey="temp.platform-fe.components.PasswordPolicy.yourNewPasswordMustNotBeTheSameOrContainTheSamePasswordAsYourPreviousOnes"
            components={[<b key="c49ef1b47d_0" />]}
          />
        )}
      </P>
      <div>
        {policyItems.map(({ id, label, match }: PolicyItem) => (
          <Stack key={`password-policy-${id}`} gap={1} alignItems="center" direction="row">
            <Icon.Check color={match ? 'success.main' : 'error.main'} size={14} />
            <P color="text.secondary" fontSize={12}>
              {i18n.t(label).toString()}
            </P>
          </Stack>
        ))}
      </div>
    </Stack>
  );
};

export default PasswordPolicy;
