export enum HomeBackendFeatureFlags {
  DEEL_AI_FEATURE_FLAG = 'deelIq',
  SERVICE_HUB_FEATURE_FLAG = 'serviceHub',
  PROFILE_SWITCHER_UPDATES_FEATURE_FLAG = 'profileSwitcher',
  HIDE_SENSITIVE_INFO_FEATURE_FLAG = 'hideSensitiveInfo',
  HIDE_SENSITIVE_INFO_US_WORKERS_FEATURE_FLAG = 'hideSensitiveInfoUsWorkers',
  GLOBAL_SEARCH_FEATURE_FLAG = 'globalSearch',
  GLOBAL_SEARCH_HR_CHIPS_FEATURE_FLAG = 'globalSearchHrChips',
  GLOBAL_SEARCH_RECENT_PROFILES_FEATURE_FLAG = 'globalSearchRecentlyViewed',
  CUSTOMIZE_HOME_FEATURE_FLAG = 'customizeHomePage',
  CUSTOMIZE_WIDGETS_FEATUER_FLAG = 'customizeWidgets',
  TOP_NAVIGATION = 'topNavigation',
  TOP_NAV_SWITCH_BAR = 'topNavSwitchBar',
  DEEL_AI_GLOBAL_SEARCH_FEATURE_FLAG = 'deelIqGlobalSearch',
  TOP_NAV_FEEBACK_FEATURE_FLAG = 'topNavFeedback',
  UI_DENSITY_SETTING = 'uiDensitySetting',
}
