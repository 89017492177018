import { Button, HStack } from '@letsdeel/ui';
import { useTranslation } from 'react-i18next';

export const SsoLoginButton = ({ loading }: { loading?: boolean }) => {
  const deelConfig = (window as any).deelConfig;
  const loginName = deelConfig?.data?.ssoIntegration?.name;
  const { t } = useTranslation();

  const handleClick = () => {
    window.location.href = deelConfig?.data?.ssoIntegration?.connectionUrl;
  };

  return (
    <Button
      className="text-center position-relative azure-login-button"
      fullWidth
      variant="outlined"
      size="large"
      onClick={handleClick}
      loading={loading}
    >
      <HStack gap={1} align="center" className="justify-content-center">
        {t('common.misc.loginUsing', { loginName })}
      </HStack>
    </Button>
  );
};
