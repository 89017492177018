import * as Yup from 'yup';
import { VALIDATE } from '@/constants/validation';
import { Field, Form, Formik, type FormikErrors } from 'formik';
import { Button, Grid, Icon, IconButton, Link, TextField, Stack } from '@letsdeel/ui';
import { type ChangeEvent, forwardRef, useState } from 'react';
import { type FormikProps } from 'formik/dist/types';
import { isNative } from '@/utils/crossPlatform';
import { useTranslation } from 'react-i18next';

export type LoginFormValues = {
  email: string;
  password: string;
  autofill: boolean;
  focusInput: string;
};

type Props = {
  onSubmitWithEmail: (values: LoginFormValues) => void;
  onInitializeSetErrors: (setErrors: (errors: FormikErrors<LoginFormValues>) => void) => void;
  validateEmail: (value: LoginFormValues['email']) => void;
  onForgotPasswordClick: () => void;
  isLoading: boolean;
  isDisabled: boolean;
};

const isNativePlatform = isNative();

export const LoginForm = forwardRef<FormikProps<LoginFormValues>, Props>(
  ({ onSubmitWithEmail, onInitializeSetErrors, validateEmail, onForgotPasswordClick, isLoading, isDisabled }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const { t } = useTranslation();
    const togglePasswordVisibility = () => {
      setShowPassword((prevState) => !prevState);
    };
    return (
      <Formik
        innerRef={ref}
        key="login-form"
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{ email: '', password: '', autofill: false, focusInput: '' }}
        validationSchema={Yup.object({
          email: VALIDATE.email,
          password: VALIDATE.password,
        })}
        onSubmit={onSubmitWithEmail}
      >
        {({ values, isSubmitting, errors, setFieldValue, setErrors }) => {
          onInitializeSetErrors(setErrors);
          return (
            <Form>
              <Stack spacing={2}>
                <Field
                  label={t('authentication.login.email')}
                  placeholder="Type your email"
                  name="email"
                  autoComplete="email"
                  error={!!errors.email}
                  helperText={errors.email}
                  as={TextField}
                  validate={validateEmail}
                  onFocus={() => {
                    setFieldValue('focusInput', 'email');
                  }}
                  onBlur={() => {
                    setFieldValue('focusInput', '');
                  }}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('autofill', false);
                    setFieldValue('email', event.target.value.trim());
                  }}
                  InputLabelProps={{
                    shrink: values.autofill || !!values.email?.length || values.focusInput === 'email',
                  }}
                />
                <Field
                  label={t('authentication.login.password')}
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  autoComplete="current-password"
                  error={!!errors.password}
                  helperText={errors.password}
                  as={TextField}
                  onFocus={() => {
                    setFieldValue('focusInput', 'password');
                  }}
                  onBlur={() => {
                    setFieldValue('focusInput', '');
                  }}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('autofill', false);
                    setFieldValue('password', event.target.value.trim());
                  }}
                  InputLabelProps={{
                    shrink: values.autofill || !!values.password?.length || values.focusInput === 'password',
                  }}
                  endIcon={
                    <IconButton onClick={togglePasswordVisibility}>
                      {showPassword ? <Icon.Hide color="primary" /> : <Icon.Show color="primary" />}
                    </IconButton>
                  }
                />
              </Stack>
              <Grid
                container
                justifyContent={isNativePlatform ? 'flex-start' : 'flex-end'}
                mt="4px"
                pr={isNativePlatform ? undefined : 2}
              >
                <Link onClick={onForgotPasswordClick}>{t('authentication.login.forgotPassword')}</Link>
              </Grid>
              <Stack mt={isNativePlatform ? 2 : 3}>
                <Button type="submit" loading={isLoading} disabled={isSubmitting || isDisabled}>
                  {t('authentication.login.login')}
                </Button>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    );
  }
);
