import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { FormikHelpers } from 'formik';
import userApi from '@/utils/api/user';
import { isNative } from '@/utils/crossPlatform';
import type { ForgotPasswordInitialValues } from './types';
import { useTranslation } from 'react-i18next';

export const useResetPassword = ({ email }: { email?: string } = {}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const [typedEmail, setTypeEmail] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasBackupEmail, setHasBackupEmail] = useState<boolean>(false);
  const [showAlternativeOptions, setShowAlternativeOptions] = useState<boolean>(false);
  const [isBackupEmailReset, setIsBackupEmailReset] = useState<boolean>(false);

  const goToLogin = () => history.push('/login');

  const customDomainHostName = () => {
    const innerWindow = window as any;

    if (!innerWindow.deelConfig?.data?.hostname) return null;

    return innerWindow.deelConfig.data.hostname.split('.')[0];
  };

  const afterSubmitResetPassword = (error?: any) => {
    if (error.response?.status === 410) {
      console.warn('token already being used');
    }

    console.error(error);
    return goToLogin();
  };

  const resendResetPasswordEmail = async (): Promise<Boolean> => {
    try {
      if (!typedEmail) throw new Error('reset email is required');

      return await onSubmitForgotPassword({ email: typedEmail.trim() });
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const onSubmitForgotPassword = useCallback(
    async (
      values: ForgotPasswordInitialValues,
      options?: FormikHelpers<ForgotPasswordInitialValues>
    ): Promise<Boolean> => {
      const { email } = values;
      if (!email) {
        options?.setErrors({ email: 'Email is required' });
        return false;
      }

      try {
        setIsLoading(true);
        const response = await userApi.reset({
          email: email.trim(),
          ...(isBackupEmailReset ? { useBackupEmail: true } : {}),
        });
        setHasBackupEmail(response?.data?.hasBackupEmail);
        setTypeEmail(email);
        return true;
      } catch (err) {
        console.error(err);

        return false;
      } finally {
        setIsLoading(false);
      }
    },
    [isBackupEmailReset]
  );

  useEffect(() => {
    if (!email) return;
    onSubmitForgotPassword({ email });
  }, [email, onSubmitForgotPassword]);

  const confirmPreferredEmail = () => {
    setShowAlternativeOptions(false);
    resendResetPasswordEmail();
  };

  const emailVerificationTexts = useMemo(() => {
    return {
      mainTitle: isNative()
        ? t('authentication.emailVerification.native.mainTitle', { email: typedEmail })
        : t('authentication.emailVerification.mainTitle'),
      subTitle: isNative()
        ? t('authentication.emailVerification.native.subTitle')
        : isBackupEmailReset
        ? t('authentication.emailVerification.subTitleForBackupEmail', { email: typedEmail })
        : t('authentication.emailVerification.subTitle', { email: typedEmail }),
    };
  }, [isBackupEmailReset, t, typedEmail]);

  return {
    confirmPreferredEmail,
    afterSubmitResetPassword,
    customDomainHostName,
    setIsBackupEmailReset,
    showAlternativeOptions,
    hasBackupEmail,
    isLoading,
    onSubmitForgotPassword,
    isBackupEmailReset,
    typedEmail,
    resendResetPasswordEmail,
    setTypeEmail,
    setShowAlternativeOptions,
    emailVerificationTexts,
  };
};
