import instance from '@/utils/api/instance';
import type { PayrollEvent } from '@/types';
import type { TerminationDetailsTypeEnum } from '@/scenes/People/PersonView/Offboarding/utils';
import type { TimeUnit } from '@/types/TimeOff/enums/TimeUnit';
import type { EligibleForRehire } from '../contract';
import type { TerminationLetterData } from '@/hooks/api/contract/useFetchTermination';

export const BASE_URL = 'global_payroll/terminations';

export enum GPTerminationState {
  AWAITING_DETAILS = 'AWAITING_DETAILS',
  AWAITING_TRIAGE = 'AWAITING_TRIAGE',
  AWAITING_REVIEW = 'AWAITING_REVIEW',
  CONFIRMED = 'CONFIRMED',
}

export enum TerminationDetailsStateEnum {
  PENDING = 'PENDING',
  EXECUTED = 'EXECUTED',
  REQUESTED = 'REQUESTED',
  CANCELED = 'CANCELED',
}

export interface Severance {
  type: 'CASH' | 'TIME';
  timeUnit: 'DAYS' | 'WEEKS' | 'MONTHS';
  amount: number;
  currency: string;
}

export interface TerminationTimeOff {
  policyName: string;
  entitlementAvailable: string | null | undefined;
  payrollReportColumnId?: string;
  payrollReportColumnName?: string;
  entitlementUnit: TimeUnit;
  timeOffsUnitsToPay: number;
  entitlementId: string;
  confirmedTimeOffsUnitsToPay?: number;
}

export interface GpTermination {
  id: string;
  lastDateOfWork: string;
  desiredEndDate: string;
  effectiveFrom: string;
  state: GPTerminationState;
  status: TerminationDetailsStateEnum;
  creationDate: Date;
  severance?: Severance;
  offCycleEvent: PayrollEvent | undefined;
  contract: {
    id: string;
    hrisProfile: {
      oid: string;
      firstName: string;
      middleName?: string;
      lastName: string;
    };
    clientLegalEntity: {
      name: string;
      vatCountry: string;
      id: number;
      organization: {
        id: number;
      };
    };
  };
  documents?: {
    id: string;
    key: string;
    contentType: string;
    fileName: string;
    HrisTerminationDetailsId: number;
    HrisFinalPaycheckItemId?: number | null;
  }[];
  confirmedVacationDaysUsed: number;
  terminationTimeOffs: TerminationTimeOff[];
  confirmedVacationDaysToBePaidOut: number | null;
  isVoluntary: boolean;
  terminationReason: { id: string; terminationReason: string };
  customTerminationReason: { id: string; name: string } | null;
  customTerminationReasonId: string;
  terminationCreatedStep?: {
    data?: {
      endDate: Date;
      lastDateOfWork: Date;
      isVoluntary: boolean;
      terminationReason: { id: string; terminationReason: string };
      customTerminationReason: { id: string; name: string } | null;
      vacationDaysUsed?: number;
      vacationDaysToBePaidOut?: number;
      severance: Severance;
    };
  };
  employment: {
    ptoYearlyAllowance: number;
    employee: {
      firstName: string;
      middleName?: string;
      lastName: string;
    };
  };
  orgActiveHrisIntegration: string | null;
  additionalDetails?: string;
  terminationImpact?: {
    id: string;
    title: string;
  };
  eligibleForRehire?: EligibleForRehire;
  eligibleForRehireReason?: string;
  type: TerminationDetailsTypeEnum.GP_DIRECT_EMPLOYEE;
  terminationLetter?: TerminationLetterData;
}

export default {
  cancelContractTermination: async (oid: string): Promise<boolean> => {
    try {
      await instance.delete(`${BASE_URL}/${oid}`, { suppressSnackbar: true });

      return true;
    } catch (error) {
      return false;
    }
  },
};
