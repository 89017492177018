import instance from '@/utils/api/instance';

export default {
  toTeam: async ({ id, team }: any) => {
    const { data } = await instance.post(`contracts/${id}/transfer`, {
      team,
    });
    return data;
  },

  toOwner: async ({ id }: { id: string }) => {
    const { data } = await instance.post(`contracts/${id}/transfer`);
    return data;
  },
};
