import type { ReactNode, MouseEvent } from 'react';
import { useMemo } from 'react';
import kebabCase from 'lodash/kebabCase';

import { cn } from '@/utils/main';

import Anchor from '@/components/Anchor/Anchor';
import Spinner from '@/components/Spinner';

import './button.less';
import type { ComponentProps } from 'react';
import DeelUIButtonWrap from './DeelUIButtonWrap';

const spinnerColor = {
  primary: 'white',
  error: 'white',
  ['outline-error']: 'error',
  ['secondary-error']: 'error',
  ['text-error']: 'error',
};

export type ButtonSizeType = 'xs' | 'sm' | 'md' | 'lg';
export type ButtonThemeType =
  | 'icon'
  | 'icon-light'
  | 'icon-dark'
  | 'dropdown-icon'
  | 'close-icon'
  | 'icon-light'
  | 'icon-dark'
  | 'primary'
  | 'secondary'
  | 'gray-secondary'
  | 'secondary-error'
  | 'outline'
  | 'outline-gray'
  | 'outline-error'
  | 'link'
  | 'error'
  | 'error-light'
  | 'text-error'
  | 'text'
  | 'text-primary'
  | 'text-success';

type ButtonType = 'button' | 'reset' | 'submit';

const UPPER_CASED_CHILDREN = ['ok'];

interface Props {
  children: ReactNode;
  size?: ButtonSizeType;
  anchorClassName?: string;
  className?: string;
  containerClassName?: string;
  to?: ComponentProps<typeof Anchor>['to'];
  href?: string;
  download?: string;
  full?: boolean;
  loading?: boolean;
  theme?: ButtonThemeType;
  noDefaultClass?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  newTab?: boolean;
  disabled?: boolean;
  minWidth?: boolean;
  type?: ButtonType;
  'data-qa'?: string;
}

const Button = ({
  children,
  className,
  anchorClassName,
  noDefaultClass,
  containerClassName,
  size = 'lg',
  full,
  to,
  href,
  loading,
  theme = 'primary',
  onClick,
  newTab,
  disabled,
  minWidth,
  type,
  download,
  ...rest
}: Props) => {
  const spinnerSize = {
    xs: 16,
    sm: 18,
    lg: 18,
  };

  let buttonInternalDivCn = containerClassName;

  if (loading) {
    buttonInternalDivCn = cn(buttonInternalDivCn, 'opacity-0', 'height-0');
  }

  const buttonChildren = useMemo(() => {
    if (typeof children === 'string' && theme !== 'link') {
      if (UPPER_CASED_CHILDREN.includes(children.toLowerCase())) {
        return children.toUpperCase();
      }

      return children.charAt(0).toUpperCase() + children.slice(1).toLowerCase();
    }

    return children;
  }, [children, theme]);

  return (
    <Anchor
      className={cn(full ? 'w-100' : null, anchorClassName)}
      to={disabled ? undefined : to}
      href={disabled ? undefined : href}
      noDefaultClass={Boolean(noDefaultClass)}
      newTab={newTab}
      download={download}
    >
      {theme.includes('icon') || theme === 'link' || theme === 'text' || theme === 'text-success' ? (
        <button
          className={cn('button', className, full ? 'w-100' : null, minWidth ? 'button-min-width' : null)}
          // @ts-ignore
          sizetype={theme === 'link' ? null : size || 'lg'}
          loading={loading ? 'true' : null}
          onClick={loading ? undefined : onClick}
          theme={theme || 'primary'}
          disabled={disabled}
          type={type}
          data-qa={rest['data-qa'] || (typeof children === 'string' ? kebabCase(children) : null)}
          {...rest}
        >
          {loading ? (
            <Spinner
              className="button-spinner"
              size={spinnerSize[size === 'lg' ? 'sm' : 'xs']}
              color={spinnerColor[theme as keyof typeof spinnerColor] || 'primary'}
            />
          ) : null}
          <div className={buttonInternalDivCn}>{buttonChildren}</div>
        </button>
      ) : (
        <DeelUIButtonWrap
          className={cn('button', className, full ? 'w-100' : null, minWidth ? 'button-min-width' : null)}
          size={size}
          loading={loading}
          type={type}
          disabled={disabled}
          theme={theme || 'primary'}
          data-qa={rest['data-qa'] || (typeof children === 'string' ? kebabCase(children) : null)}
          onClick={onClick}
          {...rest}
        >
          <div className={buttonInternalDivCn}>{buttonChildren}</div>
        </DeelUIButtonWrap>
      )}
    </Anchor>
  );
};

export default Button;
