import instance from '@/utils/api/instance';

const BASE_URL = 'hris';

export default {
  createAssignment: async (payload: TaskAssignmentType): Promise<any> => {
    const response = await instance.post(`${BASE_URL}/assignments`, { ...payload });
    return response;
  },

  updateAssignment: async (payload: TaskAssignmentType, taskId: string): Promise<any> => {
    const response = await instance.put(`${BASE_URL}/assignments/${taskId}`, { ...payload });
    return response;
  },

  completeAssignment: async (status: StatusType, taskId: string) => {
    const response = await instance.put(`${BASE_URL}/assignments/${taskId}/status`, { status });
    return response;
  },

  deleteAssignment: async (taskId: string) => {
    const response = await instance.delete(`${BASE_URL}/assignments/${taskId}`);
    return response;
  },

  sendTaskReminder: async (taskId: string) => {
    const response = await instance.post(`${BASE_URL}/assignments/${taskId}/remind`, {});
    return response;
  },
};

export interface TaskAssignmentType {
  title: string;
  description: string;
  dueDate: string | null;
  targetPage: string;
  targetCustomUrl?: string;
  targetCustomTitle?: string;
  hrisProfileAssignees: string[];
  clientProfileAssignees: number[];
  remindAt: string | null;
}

export type StatusType = 'IN_PROGRESS' | 'COMPLETED';
