import dataLayer from '@/utils/dataLayer';
import isObject from 'lodash/isObject';

const handleClick = (event: MouseEvent) => {
  const target = (event.target as HTMLElement).closest('[data-tracking-event-product]');
  if (!target) return;
  if (!(target instanceof HTMLElement || target instanceof SVGElement) || !target.dataset) return;
  if (
    !target.dataset.trackingEventProduct ||
    !target.dataset.trackingEventCategory ||
    !target.dataset.trackingEventAction
  )
    return;

  let eventAdditionalData = {};
  try {
    if (target.dataset.trackingEventJson) {
      const parsedEventAdditionalData = JSON.parse(target.dataset.trackingEventJson);
      eventAdditionalData = isObject(parsedEventAdditionalData) ? parsedEventAdditionalData : {};
    }
  } catch (e) {}

  dataLayer.push({
    event_product: target.dataset.trackingEventProduct,
    event_category: target.dataset.trackingEventCategory,
    event_action: target.dataset.trackingEventAction,
    ...eventAdditionalData,
  });
};

export const initEventsTracking = () => {
  document.addEventListener('click', handleClick);
};
