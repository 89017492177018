import { useState, useEffect } from 'react';

type CountdownTimer = {
  timerDone: boolean;
  secondsRemaining: string;
  resetCountdown: () => void;
};

function useCountdownTimer(durationInSeconds: number): CountdownTimer {
  const [secondsRemaining, setSecondsRemaining] = useState(durationInSeconds);
  const [timerDone, setTimerDone] = useState(false);

  useEffect(() => {
    if (secondsRemaining > 0) {
      const timerId = setTimeout(() => {
        setSecondsRemaining(secondsRemaining - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    } else {
      setTimerDone(true);
    }
  }, [secondsRemaining]);

  const resetCountdown = () => {
    setSecondsRemaining(durationInSeconds);
    setTimerDone(false);
  };

  const formattedTime = `${Math.floor(secondsRemaining / 60)
    .toString()
    .padStart(2, '0')}:${(secondsRemaining % 60).toString().padStart(2, '0')}`;

  return { timerDone, secondsRemaining: formattedTime, resetCountdown };
}

export default useCountdownTimer;
