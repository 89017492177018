import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import pick from 'lodash/pick';
import LookupStore from '@/stores/LookupStore';
import type { PayrollEvent } from '@/types';
import type {
  CostCenter,
  GeoLocationResponse,
  LegalEntityReportItem,
  PaymentMethod,
  LegalEntityPublicId,
  LegalEntityDraft,
  EEntityBusinessType,
} from '@/types/LegalEntity';
import instance from '@/utils/api/instance';
import type { CountryCode } from '@letsdeel/ui';
import type { SubmitCdd1FormPayload } from '@/types/cdd';

export const ADDRESS_FIELDS = ['country', 'state', 'province', 'city', 'zip', 'street'];

export interface Address {
  country: string;
  state?: string;
  province?: string;
  region?: string;
  prefecture?: string;
  city: string;
  zip: string;
  street: string;
}

export interface LegalEntityPayload extends Address {
  id?: string | number;
  publicId?: LegalEntityPublicId;
  name: string;
  localizedName?: string;
  legalType?: string;
  entityType: string;
  taxedAs?: string;
  cdd1?: SubmitCdd1FormPayload;
  registrationNumber?: string | null;
  logoUploadKey?: string;
  sicNumber?: string | number;
  industryName?: string | null;
  phone?: string | null;
  vatId?: string | null;
  companyIdentifiers: EntityIdentifierType[];
  address?: Address;
  postalAddress?: Address;
  registrationAddress?: RegistrationAddress;
  requiredRegistrationNumbers?: boolean;
  shouldRunUSPayrollAtDeel?: boolean;
  shouldRunUSContractorsAtDeel?: boolean;
  optedOutRegistrationNumber?: boolean;
  optedOutVatId?: boolean;
  isPeo?: boolean;
  formationDocument?: {
    key: string;
    filename: string;
  };
  commercialName?: string;
  missingSicNumberEntityIds?: number[];
  productsOrServicesDescription?: string;
  relationshipWithWorkersDescription?: string;
  personalLinkedInProfileURL?: string;
  personalLinkedInProfileURLBypassed?: boolean;
  companyWebsiteURL?: string;
  companyWebsiteURLBypassed?: boolean;
  companyLinkedInProfileURL?: string;
  companyLinkedInProfileURLBypassed?: boolean;
  website?: Array<string>;
  naicsCode?: string;
  hasAnyEntityInUS?: boolean;
  companyWebsiteURLBypassedReason?: string;
  companyWebsiteURLBypassedReasonOther?: string;
  useAddressAsPostalAddress?: boolean;
  legalEntityDraftId?: string | number;
  businessType?: EEntityBusinessType;
}

export type LegalEntityEditFormData = {
  companyLinkedInProfileURL?: string;
  companyLinkedInProfileURLBypassed?: boolean;
  companyWebsiteURL?: string;
  companyWebsiteURLBypassed?: boolean;
  personalLinkedInURL?: string;
  companyAdditionalInfo?: string;
  sicNumber?: string;
  optedOutSIC?: boolean;
};

export type LegalEntityDraftRequest = {
  name: string;
  address: {
    country: string;
  };
  entityType: string;
};

export type FinancialPOCFormData = {
  financialPOCFullName?: string;
  financialPOCEmail?: string;
  financialPOCIsCurrentUser?: boolean;
};

export type LegalEntityFormData = Partial<Omit<LegalEntityPayload, 'registrationAddress' | 'companyIdentifiers'>> & {
  formationDate?: string;
  registrationAddress?: string;
  hasVatId?: boolean;
  sicNumber?: string;
  industryName?: string | null;
  cdd1?: SubmitCdd1FormPayload;
  logoUploadKey?: string;
  logoUrl?: string;
  shouldRunUSPayrollAtDeel?: boolean;
  shouldRunUSContractorsAtDeel?: boolean;
  formationDocument?: {
    key: string;
    filename: string;
  };
  stateKey?: string | null;
  vatCountry?: string;
  optedOutSIC?: boolean;
  postalAddress?: Address & { stateKey?: string | null };
  useAddressAsPostalAddress?: boolean;
  legalEntityDraftId?: number | string;
} & LegalEntityEditFormData &
  FinancialPOCFormData;

interface RegistrationAddress {
  state?: string;
  province?: string;
}

interface LegalEntityServerBody {
  organizationId?: number;
  name: string;
  localizedName?: string;
  entityType: string;
  phone?: string | null;
  companyIdentifiers: EntityIdentifierType[];
  address?: Address;
  postalAddress?: Address;
  registrationAddress?: {
    state: string;
  };
}

export enum IdentifierCategory {
  COMMERCIAL_NAME = 'commercialName',
  TAX = 'tax',
  REGISTRATION = 'registration',
}

export enum IdentifierFillType {
  BYPASS = 'bypass',
  OPTIONAL = 'optional',
  REQUIRED = 'required',
}

export type ShieldProvider = {
  id: number;
  name: string;
  organization: {
    id: number;
    name: string;
    createdAt: string;
  };
  providerProfile: {
    id: number;
    name: string;
  };
};

export interface EntityIdentifierType {
  id: number;
  fieldCategory: IdentifierCategory;
  displayName: string;
  principal: boolean;
  fillRequiredType: IdentifierFillType;
  description: string;
  link?: string;
  linkDescription?: string;
  error?: string | null;
  value?: string | null;
  fallbackCheckboxLabel?: string;
}

const stringOrNull = (val: string | undefined | null) => {
  if (typeof val === 'string' && !val) return null;
  return val;
};

const processAddress = (address: any) => {
  if (!address?.country) return address;

  if (address.country === 'US') {
    return {
      ...address,
      province: null,
    };
  }

  if (LookupStore.hasProvince(address.country)) {
    return {
      ...address,
      state: null,
    };
  }

  return {
    ...address,
    province: null,
    state: null,
  };
};

export const agreementsByEntityIdEndpoint = 'legal_entities/:entityId/agreements';

const convertFormDataToServerBody = (
  entityData: Partial<LegalEntityPayload | LegalEntityFormData>,
  fullEntityData?: LegalEntityPayload
) => {
  let useVatId = undefined;
  const country = entityData.country || fullEntityData?.country;
  if (country && LookupStore.isEUVatRequired(country as CountryCode) && entityData.vatId !== undefined) {
    useVatId = !!entityData.vatId;
  }

  const registrationAddress =
    typeof entityData.registrationAddress === 'string' && entityData.registrationAddress
      ? {
          state: entityData.registrationAddress,
        }
      : entityData.registrationAddress || undefined;

  const companyIdentifiers: Pick<EntityIdentifierType, 'value' | 'fieldCategory'>[] = [];
  companyIdentifiers.push({
    fieldCategory: IdentifierCategory.REGISTRATION,
    value: entityData.optedOutRegistrationNumber ? null : stringOrNull(entityData.registrationNumber)!,
  });
  companyIdentifiers.push({
    fieldCategory: IdentifierCategory.TAX,
    value: entityData.optedOutVatId ? null : stringOrNull(entityData.vatId)!,
  });

  return {
    name: entityData.name,
    localizedName: entityData.localizedName,
    entityType: entityData.entityType,
    taxedAs: entityData.entityType === 'llc' && country === 'US' ? entityData.taxedAs : undefined,
    phone: entityData.phone || undefined,
    companyIdentifiers,
    cdd1: entityData.cdd1,
    address: processAddress(entityData.country ? pick(entityData, ADDRESS_FIELDS) : undefined),
    postalAddress: entityData.useAddressAsPostalAddress ? undefined : processAddress(entityData.postalAddress),
    registrationAddress,
    logo: entityData.logoUploadKey || undefined,
    vatCountry: entityData.country || fullEntityData?.country || undefined,
    optedOutRegistrationNumber: entityData.optedOutRegistrationNumber,
    optedOutVatId: entityData.optedOutVatId,
    sicNumber: entityData.sicNumber || undefined,
    industryName: entityData.industryName || null,
    formationDocument: entityData.formationDocument,
    shouldRunUSPayrollAtDeel: country === 'US' ? entityData.shouldRunUSPayrollAtDeel : undefined,
    shouldRunUSContractorsAtDeel: country === 'US' ? entityData.shouldRunUSContractorsAtDeel : undefined,
    useVatId,
    naicsCode: entityData.naicsCode || null,
    isPeo: entityData.isPeo,
    commercialName: entityData.commercialName,
    useAddressAsPostalAddress: entityData.useAddressAsPostalAddress,
    legalEntityDraftId: entityData?.legalEntityDraftId || undefined,
    businessType: entityData.businessType || undefined,
  };
};

const convertServerBodyToFormData = (entityData: LegalEntityServerBody) => {
  return {
    ...entityData,
    address: undefined,
    ...(processAddress(entityData?.address) || {}),
    postalAddress: processAddress(entityData?.postalAddress),
    registrationAddress: entityData.registrationAddress?.state,
  };
};
export default {
  // TODO: change to string after refactoring all places
  load: async (id: string | number) => {
    let data;

    const response = await instance.get(`/legal_entities/${id}`);
    data = response?.data;

    return {
      ...convertServerBodyToFormData(data),
      contractsCount: data.contractsCount,
      id: data.id,
      useAddressAsPostalAddress: data.useAddressAsPostalAddress,
    };
  },
  add: async (entityData: LegalEntityPayload | LegalEntityFormData, organizationId: number) => {
    const requestBody = {
      organizationId,
      ...convertFormDataToServerBody(entityData),
    };

    const { data } = await instance.post('/legal_entities', requestBody);
    return data;
  },
  addRaw: async (entityData: LegalEntityPayload | LegalEntityFormData, organizationId: number) => {
    const requestBody = {
      organizationId,
      ...entityData,
    };

    const { data } = await instance.post('/legal_entities', requestBody);
    return data;
  },
  update: async (
    { id, ...entityData }: Partial<LegalEntityFormData>,
    fullEntityData?: LegalEntityPayload,
    suppressSnackbar = false
  ) => {
    const requestBody = convertFormDataToServerBody(entityData, fullEntityData);
    const { data } = await instance.patch(`/legal_entities/${id}`, requestBody, { suppressSnackbar });
    return { ...data, ...requestBody };
  },
  updateEntitiesSicNumber: async (data: Array<{ id: string | number; sicNumber: string | number }>) =>
    instance.patch(`/legal_entities`, data),
  updateRaw: async ({ id, ...body }: any) => instance.patch(`/legal_entities/${id}`, body),
  bulkUpdate: async (payload: { id: number; sicNumber: string }[]) => {
    const { data } = await instance.patch('/legal_entities', payload, { suppressSnackbar: true });
    return data;
  },
  delete: async (id: string | number) => instance.delete(`/legal_entities/${id}`),
  deleteLogo: (id: string | number) => {
    return instance.delete(`legal_entities/${id}/logo`, { suppressSnackbar: true });
  },
  loadList: async (organizationId: number, withoutFilter?: boolean, includePayrollSettings = true) => {
    try {
      const { data } = await instance.get('/legal_entities', {
        params: { organizationId, withoutFilter, includePayrollSettings },
      });
      return data;
    } catch (error) {
      return [];
    }
  },
  shieldProviders: async () => {
    const { data } = await instance.get('/legal_entities/shield-providers');
    return data;
  },
  validateVat: (countryCode: string, value: string) => {
    const source: CancelTokenSource = axios.CancelToken.source();
    // TODO: is this dead code? no such endpoint exist in the backend
    const promise = instance.post(
      '/legal_entities/validate_vat_id ',
      { countryCode, value },
      {
        cancelToken: source.token,
      }
    );
    return { promise, source };
  },
  loadReportItems: async (
    id: number | string
  ): Promise<{ countryItemsOnly: boolean; items: LegalEntityReportItem[] }> => {
    const response = await instance.get(`/legal_entities/${id}/report-items`);
    return response.data as { countryItemsOnly: boolean; items: LegalEntityReportItem[] };
  },
  updateReportItems: async (id: number, changedItems: { [key: string]: boolean }) => {
    const payload: {
      payrollLegalEntityId: number;
      items: {
        payrollReportColumnId: string;
        isActive: boolean;
      }[];
    } = {
      payrollLegalEntityId: id,
      items: Object.entries(changedItems).map(([key, value]) => ({
        payrollReportColumnId: key,
        isActive: value,
      })),
    };

    const response = await instance.post(`/employment/payroll-report-configuration/update-many`, payload);
    return response.data;
  },
  loadCostCenters: async (id: number | string): Promise<CostCenter[]> => {
    const response = await instance.get(`/legal_entities/${id}/cost_centers`);
    return response.data as CostCenter[];
  },
  updateCostCenters: async (id: number | string, costCenters: any[]) => {
    const response = await instance.post(`/legal_entities/${id}/cost_centers`, costCenters);
    return response.data;
  },
  validateCostCentersCSV: async (fileId: number | string, suppressSnackbar = true): Promise<CostCenter[]> => {
    const response = await instance.get(`cost_centers/validate`, { suppressSnackbar, params: { fileId } });
    return response.data;
  },
  unarchiveCostCenter: async (id: number | string) => {
    const response = await instance.post(`/cost_centers/${id}/unarchive`);
    return response.data;
  },
  updateChartOfAccounts: async (chartOfAccounts: any[]) => {
    const response = await instance.post(`/employment/global-ledger-chart-account/bulk`, chartOfAccounts);
    return response.data;
  },
  getPayrollEventsForEntity: async (id: number | string, suppressSnackbar = false): Promise<PayrollEvent[]> => {
    const { data } = await instance.get(`legal_entities/${id}/payroll_events`, { suppressSnackbar });
    return data as PayrollEvent[];
  },
  updatePaymentMethod: async (id: string, newMethod: PaymentMethod): Promise<void> => {
    await instance.post(`/legal_entities/${id}/payment_method`, { newMethod });
  },
  validateIdentifier: (payload: {
    identifierType?: 'IDENTIFIER' | 'VAT';
    identifierId: number;
    countryCode: string;
    value: string;
  }) => {
    const source: CancelTokenSource = axios.CancelToken.source();
    const promise = instance.get('/legal_entities/identifiers/validate', {
      params: payload,
      cancelToken: source.token,
    });
    return { promise, source };
  },
  getAgreementsByEntityId: async (entityId: string | number): Promise<any> => {
    const { data } = await instance.get(agreementsByEntityIdEndpoint.replace(':entityId', entityId.toString()));
    return data;
  },
  getGeoCodesByZipCode: (zipCode: number | string) => {
    const source: CancelTokenSource = axios.CancelToken.source();
    const promise = instance.get<GeoLocationResponse>(`legal_entities/geo_codes_by_zip_code/${zipCode}`, {
      cancelToken: source.token,
    });

    return { promise, source };
  },
  createDraft: async (request: LegalEntityDraftRequest): Promise<LegalEntityDraft> => {
    const { data } = await instance.post('/legal_entity_drafts', request);
    return data;
  },
  getDrafts: async (): Promise<LegalEntityDraft[]> => {
    const { data } = await instance.get('/legal_entity_drafts');
    return data;
  },
  restoreEntity: async (entityId: string): Promise<any> => {
    const { data } = await instance.post(`/legal_entities/restore`, { entityId });
    return data;
  },
};
