import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { Component, createRef } from 'react';
import { Redirect } from 'react-router-dom';
import { computed, makeObservable, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Alert, CircularProgress, Grid, H1, H6, Link, Modal, VStack } from '@letsdeel/ui';
import OneLoginButton from '@/components/OneLoginButton';
import OtpPopup from '@/components/OtpPopup/OtpPopup';
import { SamlLoginButton } from '@/components/Saml';
import ChangePasswordPopup from '@/scenes/Settings/scenes/SecuritySettings/components/ChangePasswordPopup/ChangePasswordPopup';
import { redirect } from '@/utils/redirect';
import { OTPCodeEventActions, OTPCodeProducts, trackMfaEvent } from '@/utils/analytics/otpCode';
import AzureLoginButton from '../../components/AzureLoginButton';
import Flex from '../../components/Flex';
import GoogleLoginButton from '../../components/GoogleLoginButton';
import OktaLoginButton from '../../components/OktaLoginButton';
import Stack from '../../components/Stack';
import { getLoginMethods, parseJwt } from '@/utils/main';
import userApi from '@/utils/api/user';
import { isEmail } from '@/utils/regex';
import './login.less';
import cheq from '@/external/cheq';
import { DATA_LAYER_PUSH_LOGIN_EVENT } from '@/constants/login/backgrounds';
import { isNative } from '@/utils/crossPlatform';
import { DEFAULT_EXPIRY_DAYS } from '@/utils/api/trustedDevices';
import { SsoLoginButton } from '@/components/SsoLoginButton';
import { hideNativeKeyboard } from '@/utils/nativeKeyboard';
import { NativeLogin } from './NativeLogin';
import { LoginModals, LoginForm } from './components';
import { isAppPinSet } from '@/native/AppPin/appPin';
import { removeAppPinInformation } from '@/native/AppPin/appPin';
import { ROUTES } from '@/constants/routesV2';
import { RequestAppPin } from './native/RequestAppPin/RequestAppPin';
import { QRLoginButton } from '@/components/QRLoginButton';
import { AppLaunchStatusKeys, AppLaunchStatusUtil } from '@/native/AppLaunchStatus/AppLaunchStatus';
import { setAskForAppMFAPermission } from '@/native/MFA/deelAppMfa';

export const ERROR_CODES = {
  LOGIN_2FA_TOO_MANY_FAILED_ATTEMPTS: '2FA_TOO_MANY_FAILED_ATTEMPTS',
  LOGIN_2FA_INVALID_CODE: '2FA_INVALID_CODE',
  LOGIN_ACCEPTS_ONLY_SSO: 'ACCEPTS_ONLY_SSO_LOGIN',
  LOGIN_RECAPTCHA: 'RECAPTCHA',
  LOGIN_JWT_ERROR: 'JWT_ERROR',
  ORGANIZATION_SSO_ENABLED: 'ORGANIZATION_SSO_ENABLED',
};

const Login = inject(
  'redirect',
  'user',
  'showSnackbar'
)(
  observer(
    class Login extends Component {
      redirect = false;
      okta = localStorage.getItem('okta');
      azure = localStorage.getItem('azure');
      saml = localStorage.getItem('saml');
      hasPhoneNumber = Boolean(JSON.parse(localStorage.getItem('hasPhoneNumber'))) || null;
      totp = localStorage.getItem('totp');
      isLocalhost = window.location.hostname === 'localhost';
      loginCounter = 0;
      loading = false;
      loadingSSO = false;
      externalAuthLoading = false;
      totpValue = null;
      totpModalIsVisible = false;
      trustedDeviceFeature = Boolean(localStorage.getItem('trustedDeviceFeature')) || false;
      passwordRotationToken = '';
      partialLoginToken = localStorage.getItem('partialLoginToken') || '';
      trustedDeviceExpiryDays = DEFAULT_EXPIRY_DAYS;
      showPasswordRotationModal = false;
      otpOptions = null;
      otpVerificationError = null;
      google = null;
      email = null;
      password = null;
      setErrors = null;
      modalsRef = createRef();
      requestPinModalRef = createRef();
      loggedInSuccessfully = false;
      hasBackupEmail = null;
      hasAppPinSet = false;

      constructor(props) {
        super(props);

        const urlParams = new URLSearchParams(location.search);

        this.urlErrorCode = urlParams.get('error_code');

        makeObservable(this, {
          redirect: observable,
          totp: observable,
          loginCounter: observable,
          loading: observable,
          loadingSSO: observable,
          totpValue: observable,
          totpModalIsVisible: observable,
          passwordRotationToken: observable,
          partialLoginToken: observable,
          trustedDeviceExpiryDays: observable,
          showPasswordRotationModal: observable,
          otpOptions: observable,
          otpVerificationError: observable,
          google: observable,
          hasBackupEmail: observable,
          loginMethodsList: computed,
          isOktaEnabled: computed,
          isGoogleEnabled: computed,
          isEmailEnabled: computed,
          isEmailConfirmed: computed,
          ssoProviderClientId: computed,
          isQrCodeLoginEnabled: computed,
          customDomainHostName: computed,
          externalAuthLoading: observable,
          loggedInSuccessfully: observable,
        });

        // Fixing browser autofill bug
        this.formRef = createRef();

        this.state = {};
      }

      removeLoginData = () => {
        localStorage.removeItem('totp');
        localStorage.removeItem('okta');
        localStorage.removeItem('azure');
        localStorage.removeItem('trustedDeviceFeature');
        localStorage.removeItem('hasPhoneNumber');
        localStorage.removeItem('partialLoginToken');
        localStorage.removeItem('saml');
        localStorage.removeItem('otpOptions');
      };

      back = () => {
        this.totpModalIsVisible = false;
        this.totp = null;
        this.removeLoginData();
      };

      componentDidMount() {
        // SSO login
        if (this.props.location.search) {
          const searchParams = new URLSearchParams(this.props.location.search);
          const tokenOpenId = searchParams.get('openid');
          const tokenSaml2 = searchParams.get('saml2');
          const sso = searchParams.get('sso');
          const showDeleteAccountFeedbackPopup = searchParams.get('showDeleteAccountFeedbackPopup');

          if ((tokenOpenId || tokenSaml2) && sso) {
            this.SSOLogin(tokenOpenId, tokenSaml2, sso, searchParams.get('redirect'));
          }

          if (showDeleteAccountFeedbackPopup) {
            this.modalsRef.current?.openShowFeedbackModal();
          }

          // Handle SSO login redirect error
          const errorText = searchParams.get('error');
          if (errorText) {
            this.props.showSnackbar(errorText, 'error');
          }
        }

        // Fixing browser autofill bug
        window.addEventListener('animationstart', this.handleAutofill);
        cheq.init();

        if (this.totp === 'undefined') {
          this.totp = null;
          localStorage.removeItem('totp');
        }

        const storedOtpOptions = localStorage.getItem('otpOptions');
        if (storedOtpOptions) {
          try {
            this.otpOptions = JSON.parse(storedOtpOptions);
          } catch {}
        }

        if (this.totp && ((this.okta && this.otpOptions) || (this.azure && this.otpOptions) || this.saml)) {
          this.totpModalIsVisible = true;
        }

        if (isNative()) {
          this.initNativePlatform();
        }
      }

      async initNativePlatform() {
        // In case user close app without setting up pin code don't show login and send to onboarding
        if (this.props.user?.id) {
          this.goToNativeOnBoarding();
        }

        this.hasAppPinSet = await isAppPinSet();
        if (this.hasAppPinSet) {
          this.requestPinModalRef.current.open();
        }
      }

      goToNativeOnBoarding = () => {
        if (!isNative()) {
          return;
        }
        const { history } = this.props;
        history.push({
          pathname: ROUTES.nativeOnboarding,
        });
      };

      componentWillUnmount() {
        this.removeLoginData();
        window.removeEventListener('animationstart', this.handleAutofill);
      }

      handleAutofill = (e) => {
        switch (e.animationName) {
          case 'mui-auto-fill':
            this.formRef?.current?.setFieldValue('autofill', true);
        }
      };

      validateEmail = (value) => {
        let error = '';
        if (!value) error = i18n.t('temp.platform-fe.scenes.Login.b651efdb98');
        else if (!isEmail(value.trim())) error = i18n.t('temp.platform-fe.scenes.Login.59e4904d43');
        return error;
      };

      get loginMethodsList() {
        return getLoginMethods();
      }

      get isOktaEnabled() {
        return this.loginMethodsList.has('okta');
      }
      get isOneLoginEnabled() {
        return this.loginMethodsList.has('onelogin');
      }
      get isAzureLoginEnabled() {
        return this.loginMethodsList.has('azure');
      }
      get isGoogleEnabled() {
        return this.loginMethodsList.has('google');
      }
      get isEmailEnabled() {
        return this.loginMethodsList.has('email');
      }
      get isSamlLoginEnabled() {
        return this.loginMethodsList.has('saml');
      }
      get isSsoIntegrationEnabled() {
        return this.loginMethodsList.has('ssoIntegration');
      }
      get isQrCodeLoginEnabled() {
        return this.loginMethodsList.has('code');
      }

      get isEmailConfirmed() {
        return this.props.isEmailConfirmed;
      }

      get isAcceptsOnlySSOLoginError() {
        return this.urlErrorCode === ERROR_CODES.LOGIN_ACCEPTS_ONLY_SSO.toLowerCase();
      }

      hidePasswordRotationModal = () => {
        this.showPasswordRotationModal = false;
      };

      totpForm() {
        const onOtpPopupSubmit = async (totp, otpProvider, isTrusted) => {
          this.totpValue = totp;
          await this.onSubmit(
            { password: totp, isTrusted },
            {
              setErrors: () => {
                throw new Error(i18n.t('temp.platform-fe.scenes.Login.e4282a24d0'));
              },
              setFieldValue: () => {},
            },
            otpProvider
          );
        };

        const onHideTotpForm = () => {
          this.totpModalIsVisible = false;
          this.totp = null;
          this.otpVerificationError = null;
        };

        return (
          this.otpOptions && (
            <OtpPopup
              centered
              data-qa="totp-verification-popup"
              show={this.totpModalIsVisible}
              userEmail={this.email}
              otpOptions={this.otpOptions}
              allowEmailFallback
              errorMessage={this.otpVerificationError}
              sendCodeOnMount={false}
              onHide={onHideTotpForm}
              onResendCode={this.onResendTotp}
              onSubmit={onOtpPopupSubmit}
              hasPhoneNumber={this.hasPhoneNumber}
              hasBackupEmail={this.hasBackupEmail}
              partialLoginToken={this.partialLoginToken}
              trustedDeviceExpiryDays={this.trustedDeviceExpiryDays}
              withTrusted={this.trustedDeviceFeature}
              isLoginScreen
            />
          )
        );
      }

      passwordRotationModal() {
        return (
          <ChangePasswordPopup
            passwordRotationToken={this.passwordRotationToken}
            show={this.showPasswordRotationModal}
            onHide={this.hidePasswordRotationModal}
          />
        );
      }

      onInitializeSetErrors = (setErrors) => {
        this.setErrors = setErrors;
      };

      handleSignUpClick = () => {
        const { history, location } = this.props;
        history.push({
          pathname: '/signup',
          state: {
            ...(location.state || {}),
            from: '/login',
          },
        });
      };

      loginForm() {
        if (this.redirect) return <Redirect push to={this.redirect} />;
        if (!this.isEmailEnabled) return null;

        return (
          <Grid container>
            <Grid item width="100%">
              {!this.isEmailConfirmed && (
                <h4 className="text-center mb-7">{i18n.t('temp.platform-fe.scenes.Login.c42be5a670')}</h4>
              )}
              <LoginForm
                onSubmitWithEmail={this.onSubmitWithEmail}
                onInitializeSetErrors={this.onInitializeSetErrors}
                validateEmail={this.validateEmail}
                onForgotPasswordClick={this.props.onForgotPasswordClick}
                isLoading={
                  this.loading || this.totpModalIsVisible || this.externalAuthLoading || this.loggedInSuccessfully
                }
                isDisabled={this.totpModalIsVisible || this.changePasswordModalRef?.current?.isOpen}
                ref={this.formRef}
              />
            </Grid>
          </Grid>
        );
      }

      expirationTimer = null;
      initLoginExpirationTimer = (token) => {
        this.clearLoginExpirationTimer();

        try {
          const { exp, iat } = parseJwt(token) || {};
          if (!exp || !iat) return;
          const tokenTimeout = exp - iat;
          if (tokenTimeout < 60) return;
          this.expirationTimer = setTimeout(this.onLoginTokenExpired, (tokenTimeout - 5) * 1000);
        } catch (e) {}
      };

      clearLoginExpirationTimer = () => {
        if (this.expirationTimer) {
          clearTimeout(this.expirationTimer);
          this.expirationTimer = null;
        }
      };

      onLoginTokenExpired = () => {
        this.clearLoginExpirationTimer();
        if (!this.totp) return;
        this.totpModalIsVisible = false;
        this.totp = null;
        this.otpVerificationError = null;
        this.modalsRef.current?.openLoginAgainModal();
      };

      onError = (error) => {
        if (error) {
          console.error(error);
        }
      };

      onSuccess = async (google) => {
        this.setErrors?.({ email: null, password: null });
        this.loading = true;
        try {
          const {
            totp,
            otpOptions,
            hasPhoneNumber,
            partialLoginToken,
            trustedDeviceFeature,
            trustedDeviceExpiryDays,
            token,
          } = await userApi.login({
            google,
          });

          if (totp) {
            if (!this.totp) {
              this.totp = totp;
              this.google = google;
              this.initLoginExpirationTimer(totp);
            }
            this.otpOptions = otpOptions;
            this.totpModalIsVisible = true;
            this.trustedDeviceFeature = !!trustedDeviceFeature;
            this.hasPhoneNumber = hasPhoneNumber;
            this.partialLoginToken = partialLoginToken;
            this.trustedDeviceExpiryDays = trustedDeviceExpiryDays;
          }
          if (token && !totp) {
            this.loggedInSuccessfully = true;
            this.goToNativeOnBoarding();
          }
          localStorage.setItem(DATA_LAYER_PUSH_LOGIN_EVENT, true);
        } catch (err) {
          this.handleLoginProviderError(err, 'google-token', google);
        } finally {
          this.props.user.shouldCheck2FA = true;
          this.loading = false;
        }
      };

      handleLoginProviderError = (err, tokenName, token) => {
        const { showSnackbar } = this.props;
        const errorMessage = err.response?.data?.errors?.[0]?.message;
        switch (err.response?.status) {
          case 401: {
            if (this.google || this.okta) {
              localStorage.setItem(tokenName, token);
              return (this.redirect = 'signup');
            }
            return showSnackbar(errorMessage || i18n.t('temp.platform-fe.scenes.Login.c503a39184'), 'error');
          }
          case 412:
            return showSnackbar(errorMessage || i18n.t('temp.platform-fe.scenes.Login.e9fea511f4'), 'error');
          case 403:
            if (err?.response?.data?.code === ERROR_CODES.ORGANIZATION_SSO_ENABLED) {
              const redirectUrl = err.response.data.connections?.[0]?.connectionUrl;
              redirect(redirectUrl, '_self');
              return;
            }
        }
        console.error(err);
      };

      onSubmitWithEmail = async (values, { setErrors, setFieldValue }) => {
        this.google = null;
        this.okta = null;
        this.saml = null;
        this.hasPhoneNumber = false;
        this.onSubmit(values, { setErrors, setFieldValue });
      };

      get ssoProviderClientId() {
        return window.deelConfig?.data?.ssoProviderClientId;
      }

      get forceEmail2FA() {
        return localStorage.getItem('login.forceEmail2FA') ? { force2FA: true } : {};
      }

      getCommonLoginPayload = (email) => {
        const payload = { ...this.forceEmail2FA };
        if (this.google) Object.assign(payload, { google: this.google });
        else if (this.okta && this.ssoProviderClientId)
          Object.assign(payload, { okta: { token: this.okta, clientId: this.ssoProviderClientId } });
        else if (this.saml) Object.assign(payload, { saml: this.saml });
        else if (this.azure && this.totp) Object.assign(payload, { email: 'totp@azure.microsoft.com' });
        else if (email)
          Object.assign(payload, {
            email: email.trim(),
          });

        return payload;
      };

      SSOLogin = async (tokenOpenId, tokenSaml2, sso, redirectUrl) => {
        try {
          this.loadingSSO = true;
          let loginData = {};
          loginData[tokenOpenId ? 'openid' : 'saml2'] = {
            token: tokenOpenId || tokenSaml2,
            sso,
          };

          const loginResponseData = await userApi.login(loginData);
          this.handleAfterLoginSuccess(loginResponseData, null, null);

          if (redirectUrl) {
            this.redirect = redirectUrl;
          }
        } catch (err) {
          this.handleLoginProviderError(err, 'openid', tokenOpenId || tokenSaml2);
        } finally {
          this.loadingSSO = false;
        }
      };

      handleAfterLoginSuccess = async (loginResponseData, email, password) => {
        const {
          totp,
          otpOptions,
          passwordRotationToken,
          trustedDeviceFeature,
          partialLoginToken,
          hasPhoneNumber,
          hasBackupEmail,
          trustedDeviceExpiryDays,
          token,
        } = loginResponseData;

        if (token && !totp) {
          trackMfaEvent(OTPCodeEventActions.AuthenticationSuccessful, OTPCodeProducts.Login);
          this.loggedInSuccessfully = true;
        }

        if (!this.totp) {
          this.totp = totp;
          this.initLoginExpirationTimer(totp);
        }

        if (partialLoginToken) {
          this.partialLoginToken = partialLoginToken;
        }

        if (passwordRotationToken) {
          this.passwordRotationToken = passwordRotationToken;
          this.showPasswordRotationModal = true;
        }

        if (hasPhoneNumber) {
          this.hasPhoneNumber = true;
        }

        if (hasBackupEmail) {
          this.hasBackupEmail = true;
        }

        this.otpOptions = otpOptions;
        this.trustedDeviceExpiryDays = trustedDeviceExpiryDays;
        this.trustedDeviceFeature = !!trustedDeviceFeature;

        if (this.totp) {
          this.totpModalIsVisible = true;
        }

        this.props.user.shouldCheck2FA = true;

        this.email = email;
        this.password = password;

        localStorage.setItem(DATA_LAYER_PUSH_LOGIN_EVENT, true);

        if (!this.otpOptions && !this.hasAppPinSet) {
          this.goToNativeOnBoarding();
        }
      };

      afterLoginModalClose = async (token) => {
        this.requestPinModalRef?.current?.close();
        if (token) {
          this.props.user.token = token;
          this.handleAfterLoginSuccess({ token }, null, null);
          AppLaunchStatusUtil.setBooleanStatus(AppLaunchStatusKeys.APP_PIN_VERIFICATION_NEEDED, false);
        } else {
          await removeAppPinInformation();
          this.initNativePlatform();
        }
      };

      onSubmit = async (values, { setErrors }, otpProvider) => {
        hideNativeKeyboard();
        const { email, password, isTrusted } = values;
        this.otpVerificationError = null;
        this.loading = true;

        try {
          this.loading = true;

          const loginResponseData = await userApi.login({
            totp: this.totp,
            trusted: isTrusted,
            ...this.getCommonLoginPayload(email || this.email),
            password: this.totpValue || password,
            otpProvider,
            ...this.forceEmail2FA,
          });

          // TODO: Add check if App based MFA enabled from BE
          if (isNative() && this.props.isAppBasedMFAEnabled && this.otpOptions && this.totp) {
            setAskForAppMFAPermission(true);
          }
          this.handleAfterLoginSuccess(loginResponseData, email, password);
        } catch (err) {
          console.error(err);
          const errorCode = err?.response?.data?.code;

          switch (err.response?.status || 0) {
            case 401:
              if (errorCode === ERROR_CODES.LOGIN_2FA_TOO_MANY_FAILED_ATTEMPTS) {
                this.totpModalIsVisible = false;
                this.modalsRef?.current?.openTempLockedModal(err?.response?.data?.timeoutFor ?? 0);
                this.totp = null;
                return;
              }
              return setErrors({ email: i18n.t('temp.platform-fe.scenes.Login.4069adab79') });
            case 403:
              if (errorCode === ERROR_CODES.ORGANIZATION_SSO_ENABLED) {
                const redirectUrl = err.response.data.connections?.[0]?.connectionUrl;
                redirect(redirectUrl, '_self');
                return;
              }

              if (this.totpModalIsVisible || errorCode === ERROR_CODES.LOGIN_2FA_INVALID_CODE) {
                trackMfaEvent(OTPCodeEventActions.FiredErrors, OTPCodeProducts.Login, { error: errorCode });
                const attemptsLeft = err.response?.data?.attemptsLeft ?? 0;
                if (attemptsLeft) {
                  this.otpVerificationError = (
                    <span>
                      <Trans
                        i18nKey="temp.platform-fe.scenes.Login.dd3371cab4"
                        components={[
                          <nobr key="e1fb5972a0_0" />,
                          <Link
                            sx={{ display: 'inline' }}
                            target="_blank"
                            newTab
                            href="https://help.letsdeel.com/hc/en-gb/articles/21231762160529-How-To-Troubleshoot-Issues-With-Two-Factor-Authentication-2FA"
                            key="d86d9000af_1"
                          />,
                        ]}
                        values={{ attempt: attemptsLeft }}
                      />
                    </span>
                  );
                } else {
                  this.otpVerificationError = i18n.t('temp.platform-fe.scenes.Login.dfcfb00aab');
                }
                return;
              }
              if (errorCode === ERROR_CODES.LOGIN_ACCEPTS_ONLY_SSO) {
                const { hostname } = err?.response?.data || {};

                if (hostname) {
                  const rawHostname = hostname.replace('https://', '').replace('http://', '');
                  redirect(`https://${rawHostname}?error_code=${errorCode.toLowerCase()}`);
                }

                return;
              }
              return setErrors({ password: i18n.t('temp.platform-fe.scenes.Login.08de2fdaab') });
            case 412:
              if (errorCode === ERROR_CODES.LOGIN_RECAPTCHA) {
                this.loginCounter--;
                this.props.showSnackbar(i18n.t('temp.platform-fe.scenes.Login.b8eab6ef98'), 'error');
                return;
              } else if (errorCode === ERROR_CODES.LOGIN_JWT_ERROR) {
                this.props.showSnackbar(i18n.t('temp.platform-fe.scenes.Login.b8eab6ef98'), 'error');
                this.totp = null;
                return this.onLoginTokenExpired();
              }

              this.props.onNotVerifiedError?.({ email });
          }
        } finally {
          this.totpValue = null;
          this.loginCounter++;
          this.loading = false;
        }
      };

      /** A dumber call to /login, with the previous payload, to trigger the 2FA code to be sent again **/
      onResendTotp = async (otpProvider) => {
        this.otpVerificationError = null;
        this.loading = true;

        try {
          const { totp } = await userApi.resendLoginOtp({
            totp: this.totp,
            ...otpProvider,
          });

          if (totp) {
            this.totp = totp;
            this.initLoginExpirationTimer(totp);
          }
        } catch (err) {
          this.otpVerificationError = i18n.t('temp.platform-fe.scenes.Login.7a6f8a445d');
          const errorCode = err?.response?.data?.code;
          if (Number(err?.response?.status) === 412 && errorCode === ERROR_CODES.LOGIN_JWT_ERROR) {
            this.onLoginTokenExpired();
          }

          throw err;
        } finally {
          this.loading = false;
        }
      };

      get customDomainHostName() {
        if (!window.deelConfig?.data?.hostname) return null;

        return window.deelConfig.data.hostname.split('.')[0];
      }

      get idleSessionLogout() {
        return window.sessionStorage.getItem('idleSessionLogout') === 'true';
      }

      renderAlerts = () => {
        return (
          <>
            {this.idleSessionLogout ? (
              <Grid
                container
                className={'safe-area-margin-top'}
                pt={isNative() ? 2 : undefined}
                px={isNative() ? 2 : undefined}
              >
                <Grid item width="100%" mb="24px">
                  <Alert>{i18n.t('temp.platform-fe.scenes.Login.2b707769c7')}</Alert>
                </Grid>
              </Grid>
            ) : this.isAcceptsOnlySSOLoginError ? (
              <Grid
                container
                className={'safe-area-margin-top'}
                pt={isNative() ? 2 : undefined}
                px={isNative() ? 2 : undefined}
              >
                <Grid item width="100%" mb="24px">
                  <Alert>{i18n.t('temp.platform-fe.scenes.Login.452b621c4c')}</Alert>
                </Grid>
              </Grid>
            ) : null}
          </>
        );
      };

      render() {
        if (this.loadingSSO) {
          return (
            <Grid display="flex" minHeight={538} justifyContent="center" alignItems="center" margin="auto">
              <CircularProgress />
            </Grid>
          );
        }

        if (isNative()) {
          return (
            <>
              <NativeLogin
                onForgotPasswordClick={this.props.onForgotPasswordClick}
                handleSignUpClick={this.handleSignUpClick}
                loading={this.loading}
                totpModalIsVisible={this.totpModalIsVisible}
                externalAuthLoading={this.externalAuthLoading}
                loggedInSuccessfully={this.loggedInSuccessfully}
                onSubmitWithEmail={this.onSubmitWithEmail}
                ref={this.formRef}
                isLoading={
                  this.loading || this.totpModalIsVisible || this.externalAuthLoading || this.loggedInSuccessfully
                }
                isDisabled={this.totpModalIsVisible || this.changePasswordModalRef?.current?.isOpen}
                onInitializeSetErrors={this.onInitializeSetErrors}
                validateEmail={this.validateEmail}
                googleLogin={{
                  onSuccess: this.onSuccess,
                  loading:
                    this.loading || this.totpModalIsVisible || this.externalAuthLoading || this.loggedInSuccessfully,
                  onLoading: (isLoading) => (this.externalAuthLoading = isLoading),
                }}
              >
                <Stack style={{ position: 'absolute', top: '0' }}>{this.renderAlerts()}</Stack>
              </NativeLogin>

              <Modal
                ref={this.requestPinModalRef}
                closeButton={false}
                disableBackdropClick
                modalProps={{ sx: { padding: '0 !important' } }}
                contentProps={{
                  sx: { padding: '0 !important ' },
                }}
              >
                <RequestAppPin closeModal={this.afterLoginModalClose} />
              </Modal>
              <LoginModals ref={this.modalsRef} />
              <Grid container item mt="24px">
                {this.totpForm()}
                {this.passwordRotationModal()}
              </Grid>
            </>
          );
        }

        return (
          <>
            {this.renderAlerts()}
            <VStack gap={1}>
              <H1 className="text-center">{i18n.t('temp.platform-fe.scenes.Login.bffe9a3c9a')}</H1>
              {Boolean(this.customDomainHostName) && (
                <H6 className="text-center">
                  <Trans
                    i18nKey="temp.platform-fe.scenes.Login.e9ff005026"
                    components={[<span className="capitalize" key="c314fadea5_0" />]}
                    values={{ v0: this.customDomainHostName }}
                  />
                </H6>
              )}
            </VStack>

            {Boolean(this.isGoogleEnabled) && !this.isEmailConfirmed && (
              <Grid container>
                <Grid item width="100%" mt={['40px', '40px', '24px']}>
                  <div className="login-box">
                    <GoogleLoginButton
                      onSuccess={this.onSuccess}
                      loading={
                        this.loading || this.totpModalIsVisible || this.externalAuthLoading || this.loggedInSuccessfully
                      }
                      onLoading={(isLoading) => (this.externalAuthLoading = isLoading)}
                    />
                  </div>
                </Grid>
              </Grid>
            )}
            {this.props.isAppBasedMFAEnabled && Boolean(this.isQrCodeLoginEnabled) && !this.isEmailConfirmed ? (
              <Grid container>
                <Grid item width="100%" mt={['40px', '40px', '24px']}>
                  <div className="login-box">
                    <QRLoginButton
                      loading={
                        this.loading || this.totpModalIsVisible || this.externalAuthLoading || this.loggedInSuccessfully
                      }
                      onClick={this.props.onLoginWithQRClick}
                    />
                  </div>
                </Grid>
              </Grid>
            ) : null}
            {Boolean(this.isOktaEnabled) && !this.isEmailConfirmed && (
              <Grid container>
                <Grid item width="100%" mt={['40px', '40px', '24px']}>
                  <div className="login-box">
                    <OktaLoginButton onComplete="login" />
                  </div>
                </Grid>
              </Grid>
            )}
            {Boolean(this.isOneLoginEnabled) && !this.isEmailConfirmed && (
              <Grid container>
                <Grid item width="100%" mt={['40px', '40px', '24px']}>
                  <div className="login-box">
                    <OneLoginButton onComplete="login" />
                  </div>
                </Grid>
              </Grid>
            )}
            {Boolean(this.isAzureLoginEnabled) && !this.isEmailConfirmed && (
              <Grid container>
                <Grid item width="100%" mt={['40px', '40px', '24px']}>
                  <div className="login-box">
                    <AzureLoginButton onComplete="login" />
                  </div>
                </Grid>
              </Grid>
            )}
            {Boolean(this.isSamlLoginEnabled) && !this.isEmailConfirmed && (
              <Grid container>
                <Grid item width="100%" mt={['40px', '40px', '24px']}>
                  <div className="login-box">
                    <SamlLoginButton onComplete="login" />
                  </div>
                </Grid>
              </Grid>
            )}
            {Boolean(this.isSsoIntegrationEnabled) && !this.isEmailConfirmed && (
              <Grid container>
                <Grid item width="100%" mt={['40px', '40px', '24px']}>
                  <div className="login-box">
                    <SsoLoginButton
                      loading={
                        this.loading || this.totpModalIsVisible || this.externalAuthLoading || this.loggedInSuccessfully
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            )}
            {Boolean(
              this.isGoogleEnabled ||
                this.isOktaEnabled ||
                this.isOneLoginEnabled ||
                this.isAzureLoginEnabled ||
                this.isSamlLoginEnabled ||
                this.isSsoIntegrationEnabled ||
                this.isQrCodeLoginEnabled
            ) &&
              Boolean(this.isEmailEnabled) &&
              !this.isEmailConfirmed && (
                <Grid container mt="24px">
                  <Grid item width="100%">
                    <Flex className="align-items-center">
                      <div className="login-separate" />
                      <div className="login-separate-text">or</div>
                      <div className="login-separate" />
                    </Flex>
                  </Grid>
                </Grid>
              )}
            <Grid container item mt="24px">
              {this.loginForm()}
              {this.totpForm()}
              {this.passwordRotationModal()}
            </Grid>
            {!this.isEmailConfirmed && (
              <Grid container item justifyContent="center">
                <p className="create-account text-center">
                  <Trans
                    i18nKey="temp.platform-fe.scenes.Login.needToCreateAccount"
                    components={{ Link: <Link href="/signup" /> }}
                  />
                </p>
              </Grid>
            )}

            <LoginModals ref={this.modalsRef} />
          </>
        );
      }
    }
  )
);

export default Login;
