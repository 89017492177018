import { SHAPE_COLORS } from '@/components/BrazeLoginBackground/constants';
import { ShapeContainer } from '@/components/BrazeLoginBackground/components';
import type { ShapeProps } from '@/components/BrazeLoginBackground/types';
import Shape from './images/Hourglass-01-Hero.svg?react';

const Hourglass01Hero = ({ backgroundColor }: ShapeProps) => (
  <ShapeContainer
    sx={{
      transform: 'translate(35%) scale(1.3)',
      '@media(max-width: 1400px)': { transform: 'translate(37%, 15%) scale(1.3)' },
      '@media(max-width: 1200px)': { transform: 'translate(38%, 15%) scale(1.3)' },
      '@media(max-width: 1100px)': { transform: 'translate(39%, 21%) scale(1.3)' },
      '@media(max-width: 959px)': {
        top: '50%',
        left: '50%',
        right: 'unset',
        transform: 'translate(-63%, -12%) scale(1)',
      },
      '@media(max-width: 599px)': {
        transform: 'translate(-55%, -12%) scale(1)',
      },
    }}
  >
    <Shape fill={SHAPE_COLORS[backgroundColor]} preserveAspectRatio="none" height="100vh" />
  </ShapeContainer>
);

export default Hourglass01Hero;
