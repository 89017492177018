import { Link, P, Stack } from '@letsdeel/ui';
import { type FormikErrors, type FormikValues } from 'formik';

import { forwardRef, lazy, type PropsWithChildren } from 'react';
import { type FormikProps } from 'formik/dist/types';
import { NativeContainer } from '@/native/NativeContainer';
import GoogleLoginButton from '@/components/GoogleLoginButton';
import { LoginSeparator } from './components/LoginSeparator';
import LOGOS from '@/constants/logos';
import './NativeLogin.less';
import { LoginForm, type LoginFormValues } from '../components';
import useStatusBar from '@/hooks/native/useStatusBar';
import { NativeSSOLoginButton } from './components/NativeSSOLoginButton';
import { Edges } from '@/types/Edges';
import { useTranslation } from 'react-i18next';

type Props = {
  onForgotPasswordClick: () => void;
  handleSignUpClick: () => void;
  onSubmitWithEmail: (values: LoginFormValues) => void;
  isLoading: boolean;
  isDisabled: boolean;
  onInitializeSetErrors: (setErrors: (errors: FormikErrors<FormikValues>) => void) => void;
  validateEmail: (value: LoginFormValues['email']) => void;
  googleLogin: {
    onSuccess: () => void;
    loading: boolean;
    onLoading: (bool: boolean) => void;
  };
};

const titleStyle = {
  fontFamily: 'Bagoss Condensed',
  fontSize: '32px',
  fontWeight: 500,
  lineHeight: '40px',
  letterSpacing: '-0.015em',
};

const deelLogo = LOGOS['deel-redesign-black'];
const TextBg = lazy(() => import('./assets/text_bg.svg?react'));
const HourGlass = lazy(() => import('./assets/hourglass.svg?react'));

export const NativeLogin = forwardRef<FormikProps<LoginFormValues>, PropsWithChildren<Props>>(
  (
    {
      validateEmail,
      onInitializeSetErrors,
      onForgotPasswordClick,
      handleSignUpClick,
      onSubmitWithEmail,
      isLoading,
      isDisabled,
      children,
      googleLogin,
    },
    ref
  ) => {
    useStatusBar('#FFE27C', true);
    const { t } = useTranslation();

    return (
      <>
        <NativeContainer edges={[Edges.TOP]} className={'native-login-container'}>
          <Stack className={'wrapper'}>
            <Stack className={'background-container'}>
              <Stack className={'hourglass-icon'}>
                <HourGlass />
              </Stack>
              {deelLogo}
              <Stack justifyContent={'flex-end'} flex={1}>
                <P sx={titleStyle}>{t('authentication.nativeLogin.header.text')}</P>
                <Stack mt={0.5} flexDirection={'row'}>
                  <Stack className={'relative'}>
                    <P className={'relative z-2'} sx={titleStyle}>
                      {t('authentication.nativeLogin.header.highlighted')}
                    </P>
                    <div className={'text-bg'}>
                      <TextBg />
                    </div>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack className={'form-container safe-area-inset-bottom'}>
              <LoginForm
                onSubmitWithEmail={onSubmitWithEmail}
                onInitializeSetErrors={onInitializeSetErrors}
                validateEmail={validateEmail}
                onForgotPasswordClick={onForgotPasswordClick}
                isLoading={isLoading}
                isDisabled={isDisabled}
                ref={ref}
              />
              <Stack spacing={2} mt={2} pb={2}>
                <Stack alignItems={'center'}>
                  <p>
                    {t('authentication.nativeLogin.createAnAccount')}{' '}
                    <Link onClick={handleSignUpClick}>{t('authentication.nativeLogin.signUp')}</Link>
                  </p>
                </Stack>
                <LoginSeparator />
                <GoogleLoginButton {...googleLogin} />
                <NativeSSOLoginButton />
              </Stack>
            </Stack>
          </Stack>
          {children}
        </NativeContainer>
      </>
    );
  }
);
