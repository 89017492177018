export interface VariableCompensation {
  id: string;
  title: string;
  currency: string;
  attachmentUrl?: string;
  attachmentKey?: string;
  conditions?: string | null;
  attachmentTempUrl?: string;
  attachmentFileName?: string;
  effectiveDate: string;
  compensationAmount: number;
  type: VariableCompensationType;
  originalVariableCompensationId?: string;
  frequency: VariableCompensationFrequency;
}

export interface VariableCompensationPayload {
  id?: string;
  title: string;
  currency: string;
  conditions?: string;
  documentUrl?: string;
  effectiveDate: string;
  attachmentKey?: string;
  attachmentFileName?: string;
  compensationAmount: number;
  type: VariableCompensationType;
  frequency: VariableCompensationFrequency;
}

export interface SignPayload {
  signature: string;
  editReason?: {
    reasonOid: string;
    additionalInfo: string;
  };
}

export enum VariableCompensationFrequency {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Annually = 'ANNUALLY',
}

export enum VariableCompensationType {
  FlatRate = 'FIXED',
  Percentage = 'PERCENTAGE',
}
