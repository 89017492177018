import {
  Perks,
  // VisaChecker
} from '@/scenes/Login/components/Backgrounds/General';
// import { Birthday } from '@/scenes/Login/components/Backgrounds/General/Birthday';
import type { FunctionComponent } from 'react';

export interface Background {
  id: string;
  deelLogo: string;
  CurrentAnimation: FunctionComponent;
  rebrand?: boolean;
  priority?: number;
}

export const FALLBACK_BACKGROUNDS: Array<Background> = [
  {
    id: 'perks',
    deelLogo: 'deel',
    rebrand: true,
    CurrentAnimation: Perks,
  },
];

/* Common backgrounds */
const GENERAL_BACKGROUNDS: Array<Background> = [];

/* Client only backgrounds */
const CLIENT_BACKGROUNDS: Array<Background> = [
  ...GENERAL_BACKGROUNDS,
  // release postponed due to https://letsdeel.slack.com/archives/C05U5KXQDSL/p1701937165673529?thread_ts=1701410897.156839&cid=C05U5KXQDSL
  // {
  //   id: 'visa-checker',
  //   deelLogo: 'deel',
  //   CurrentAnimation: VisaChecker,
  // },
];

/* Contractor only backgrounds */
const CONTRACTOR_BACKGROUNDS: Array<Background> = [...GENERAL_BACKGROUNDS];

/* Employee only backgrounds */
const EMPLOYEE_BACKGROUNDS: Array<Background> = [...GENERAL_BACKGROUNDS]; // todo add backgrounds once product is ready

/* Overridden backgrounds */
const OVERRIDDEN_BACKGROUNDS: Array<Background> = [
  // {
  //   id: 'birthday',
  //   deelLogo: 'deel-white',
  //   CurrentAnimation: Birthday,
  // },
];

export type LoginUserType = 'contractor' | 'client' | 'employee' | 'general' | 'overridden';

export const LOGIN_BACKGROUNDS: { [key in LoginUserType]: Array<Background> } = {
  contractor: CONTRACTOR_BACKGROUNDS,
  client: CLIENT_BACKGROUNDS,
  employee: EMPLOYEE_BACKGROUNDS,
  general: GENERAL_BACKGROUNDS,
  overridden: OVERRIDDEN_BACKGROUNDS,
};

export const LOGIN_REFERRAL_KEY = 'userReferralsLink';

export const LOGIN_USER_ID = 'loginUserId';
export const LOGIN_USER_UUID = 'loginUserUuid';
export const LOGIN_PROFILE_UUID = 'loginProfileUuid';
export const LOGIN_ORGANIZATION_UUID = 'loginOrganizationUuid';

export const DATA_LAYER_PUSH_LOGIN_EVENT = 'dataLayerPushLoginEvent';
