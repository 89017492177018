// Check if a contract has an unpaid bonus
export const hasBonus = (contract) => {
  return getBonuses(contract).length > 0;
};

// Get current bonuses from contract
export const getBonuses = (
  contract,
  includeUpcoming = true,
  includeZero = false,
  includeActive = true,
  includeAwaitingPayment = false
) => {
  return contract.paymentCycles
    .filter((cycle) => {
      return { active: includeActive, awaiting_payment: includeAwaitingPayment, upcoming: includeUpcoming }[
        cycle.status
      ];
    })
    .reduce((reports, cycle) => {
      return reports.concat(
        cycle.reports.filter(
          ({ bonus, status }) => (includeZero ? +bonus || +bonus === 0 : +bonus) && { pending: 1, approved: 1 }[status]
        )
      );
    }, []);
};

// Add bonus
export const addBonus = ({ contract, bonus, description, type }) => {
  const cycle = contract.paymentCycles.filter((cycle) => cycle.status === 'active')[0];
  Object.assign(cycle, {
    reports: [{ bonus, description, type, status: 'approved', when: Date.now() }].concat(cycle.reports),
  });
};

// Delete a bonus by id
export const deleteBonus = (contract, id) => {
  const { reports } = contract.paymentCycles.filter((cycle) => cycle.status === 'active')[0];

  for (let i in reports) {
    if (reports[i].id === id) {
      reports.splice(i, 1);
    }
  }
};

// get vat
export const getVat = (contract, cycleStatus = 'active', cycleId) => {
  if (!contract?.vat?.length) return null;
  const vats = contract.vat
    ?.map((vat) => {
      const paymentCycle = contract.paymentCycles?.find(({ id }) => id === vat.paymentCycleId);
      if (paymentCycle) return { ...vat, cycleId: paymentCycle.id, cycleStatus: paymentCycle.status };
      else vat;
    })
    ?.filter((vat) => vat);

  if (cycleId) return vats?.find(({ paymentCycleId }) => +paymentCycleId === +cycleId);
  const statuses = new Set(cycleStatus ? [cycleStatus] : []);

  const filteredVats = vats?.filter(({ cycleStatus }) => {
    return statuses.has(cycleStatus);
  });

  if (!filteredVats?.length) return vats?.find(({ recurring }) => recurring);
  return filteredVats[0];
};

export const buildVatReport = (vat) => {
  return {
    ...vat,
    type: `vat`,
    id: vat.id,
    description: vat.description,
    bonus: vat.total,
    status: 'approved',
  };
};
