export enum NoticePeriodRuleType {
  LIMITED_TO_PLATFORM_OPTIONS = 'LIMITED_TO_PLATFORM_OPTIONS',
  FULL_AGREEMENT = 'FULL_AGREEMENT',
  LIMITED_AGREEMENT = 'LIMITED_AGREEMENT',
}

export enum NoticePeriodTimeUnit {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export type EmploymentType = 'PART_TIME' | 'FULL_TIME';
export type EmploymentTerm = 'DEFINITE' | 'INDEFINITE';

export enum NoticePeriodType {
  NO_NOTICE_PERIOD = 'NO_NOTICE_PERIOD',
  SPECIFIC = 'SPECIFIC',
  MIN_MAX_LIMITED = 'MIN_MAX_LIMITED',
  TENURE_BASED = 'TENURE_BASED',
}

export type NoticePeriodSettings = {
  employmentType: EmploymentType;
  employmentTerm: EmploymentTerm;
  typeForDuringProbation: NoticePeriodType | null;
  typeForAfterProbation: NoticePeriodType | null;
  minNoticeValueDuringProbation: number | null;
  maxNoticeValueDuringProbation: number | null;
  minNoticeValueAfterProbation: number | null;
  maxNoticeValueAfterProbation: number | null;
  noticeValueDuringProbation: number | null;
  noticeValueAfterProbation: number | null;
  tenureRules: TenureRule[];
};

export type DurationType = 'MONTH' | 'YEAR';

export type TenureRule = {
  minEmploymentDuration: number;
  maxEmploymentDuration: number | null;
  employmentDurationType: DurationType;
  noticeValue: number;
};
