import i18n from 'i18next';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Component, Fragment } from 'react';
import Logo from '../Logo';
import { sleep } from '@/utils/main';
import './google-login-button.less';
import { Button, HStack } from '@letsdeel/ui';
import { isNative } from '@/utils/crossPlatform';
import { initNativeGoogleAuth, signInNativeGoogle } from '@/utils/nativeGoogleAuth';
import { WEB_GOOGLE_CLIENT_ID } from '@/constants/google';

const GoogleLoginButton = observer(
  class GoogleLoginButton extends Component {
    loading = false;

    onClick = async () => {
      this.loading = true;
      this.props.onLoading?.(true);

      if (isNative()) {
        const accessToken = await signInNativeGoogle();
        if (!accessToken) {
          this.loading = false;
          this.props.onLoading?.(false);
          return;
        }
        this.props.onSuccess(accessToken);
        this.loading = false;
        this.props.onLoading?.(false);
        return;
      }

      const popup = window.open(
        `https://accounts.google.com/o/oauth2/v2/auth?scope=email%20profile%20openid&response_type=token&redirect_uri=https%3A%2F%2Fwww.deel.com%2Flogin&prompt=select_account&client_id=${WEB_GOOGLE_CLIENT_ID}`
      );
      const listener = (evt) => {
        if (evt.source !== popup) return;
        const [, token] = String(evt.data).match(/[?&#]access_token=([^&]+)/) || [];
        if (!token) return;
        try {
          popup.close();
        } catch (err) {
          console.error(err);
        } finally {
          this.props.onSuccess(token);
          this.loading = false;
          this.props.onLoading?.(false);
        }
      };
      try {
        window.addEventListener('message', listener, false);
        while (!popup.closed) await sleep(100);
      } catch (err) {
        console.error(err);
      } finally {
        window.removeEventListener('message', listener);
        this.loading = false;
        this.props.onLoading?.(false);
      }
    };

    constructor(props) {
      super(props);

      makeObservable(this, {
        loading: observable,
      });
    }

    componentDidMount() {
      if (isNative()) {
        initNativeGoogleAuth();
      }
      if (this.props.autoLoad) this.onClick();
    }

    render() {
      const { children } = this.props;

      return (
        <Fragment>
          <Button
            loading={this.loading || this.props.loading}
            className="text-center position-relative google-login-button"
            fullWidth
            variant="outlined"
            size="large"
            onClick={this.onClick}
          >
            <HStack gap={1} align="center" className="justify-content-center">
              <Logo type="google" size="24" />
              {children || i18n.t('temp.platform-fe.components.GoogleLoginButton.eab7060452')}
            </HStack>
          </Button>
        </Fragment>
      );
    }
  }
);

export default GoogleLoginButton;
