export type UnisureEnrollmentFormInfo = {
  url: string;
  body: {
    first_name: string;
    last_name: string;
    deel_id: number;
    token: string;
    employer_contribution: number;
    coverage: number;
    residence: string;
    gender: string;
    dob: string;
    company: string;
    cover_start: string;
    cover_end: string;
  };
};

export default {
  sendUnisureFormEnrollment: async (url: string, body: UnisureEnrollmentFormInfo['body']) => {
    const form = document.createElement('form');
    form.setAttribute('action', url);
    form.setAttribute('target', '_blank');
    form.setAttribute('method', 'POST');
    form.setAttribute('enctype', 'multipart/form-data');

    Object.entries(body).forEach(([key, value]) => {
      const input = document.createElement('input');
      input.name = key;
      input.value = String(value);
      form.appendChild(input);
    });

    const input = document.createElement('input');
    input.name = 'redirect';
    input.value = window.location.origin + (window.location.pathname !== '/' ? window.location.pathname : '');
    form.appendChild(input);

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  },
};
