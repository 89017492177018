import i18n from 'i18next';
import { ERROR_CODES } from '@/scenes/Login/Login';
import axios from 'axios';
import { action, observable, makeObservable } from 'mobx';

export default new (class AppStore {
  constructor() {
    makeObservable(this, {
      version: observable,
      latestVersion: observable,
      newVersionAvailable: observable,
      errorMessage: observable,
      lastError: observable,
      setErrorMessage: action,
      hideErrorMessage: action,
      otpProvider: observable,
      getRecaptchaToken: observable,
    });
  }

  version = null;
  latestVersion = null;
  newVersionAvailable = false;
  errorMessage = null;
  otpProvider = null;
  getRecaptchaToken = null;
  lastError = null;

  setErrorMessage = (err) => {
    if (typeof err === 'string') {
      return (this.errorMessage = err);
    }

    if (!err.response && !axios.isCancel(err)) {
      return (this.errorMessage = i18n.t('temp.unknown.stores.AppStore.js.42cd08444f'));
    }

    const requestIsSSOLogin = err.response?.data?.code === ERROR_CODES.ORGANIZATION_SSO_ENABLED;

    if (
      !requestIsSSOLogin &&
      err.response?.status === 403 &&
      ['post', 'put', 'patch', 'delete'].includes(err.response?.config?.method?.toLowerCase())
    ) {
      return (this.errorMessage = i18n.t('temp.unknown.stores.AppStore.js.08de2fdaab'));
    }

    const data = err.response?.data;
    const errors = Array.isArray(data) ? data : data?.errors || [];

    if (errors.length) {
      const errorMessages = errors.map(({ message = i18n.t('temp.unknown.stores.AppStore.js.902b0d55fd') }) => message);
      return (this.errorMessage = errorMessages);
    }

    if (data?.error?.message) {
      return (this.errorMessage = data.error.message);
    }

    if (data?.error_description || data?.error) {
      return (this.errorMessage = data?.error_description || data?.error);
    }
  };

  hideErrorMessage = () => {
    this.errorMessage = null;
  };
})();
