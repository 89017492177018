import omit from 'lodash/omit';
import type { WorkStatementRequest } from '@/scenes/Contract/components/EditContract/ICContract/helper';
import type { SignPayload, VariableCompensation, WorkStatement } from '@/types';
import instance from '@/utils/api/instance';

export default {
  loadAll: async (params?: { statuses?: string[] }) => {
    const { data } = await instance.get('/work_statements', { params });
    return data;
  },

  loadById: async (id: WorkStatement['id']) => {
    const { data } = await instance.get(`/work_statements/${id}`);
    return data;
  },

  create: async (
    payload: Partial<
      Omit<WorkStatementRequest, 'variableCompensations'> & {
        contractId: number | string;
        variableCompensations: Partial<VariableCompensation>[];
      }
    >
  ) => {
    const { data } = await instance.post(
      '/work_statements',
      omit(payload, ['id', 'seniorityName', 'seniority', 'jobTitle']),
      {
        suppressSnackbar: true,
      }
    );
    return data;
  },

  delete: async (workStatementId: WorkStatement['id']) => {
    const { data } = await instance.delete(`/work_statements/${workStatementId}`);
    return data;
  },

  patch: async (workStatementId: WorkStatement['id'], payload: Partial<WorkStatementRequest>) => {
    const { data } = await instance.patch(
      `/work_statements/${workStatementId}`,
      omit(payload, ['seniorityName', 'seniority', 'jobTitle']),
      {
        suppressSnackbar: true,
      }
    );
    return data;
  },

  decline: async (wsId: WorkStatement['id'], payload?: { notes: string }) => {
    const { data } = await instance.post(`/work_statements/${wsId}/decline`, payload);
    return data;
  },

  sign: async (wsId: WorkStatement['id'], payload?: SignPayload) => {
    const { data } = await instance.post(`/work_statements/${wsId}/sign`, payload?.signature ? payload : undefined);
    return data;
  },

  approve: async (wsId: WorkStatement['id']) => {
    const { data } = await instance.post(`/work_statements/${wsId}/approve`);
    return data;
  },

  resetFields: async (wsId: WorkStatement['id'], fields: string[]) => {
    const { data } = await instance.post(`/work_statements/${wsId}/reset_draft`, { fields });
    return data;
  },
};
