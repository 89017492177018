import { useEffect } from 'react';
import { changeStatusBarColorAndroid, changeToDefaultThemeColorAndStyle } from '@/utils/nativeStatusBar';

// Change the status bar color to the specified color go back to the default color when the component unmounts.
const useStatusBar = (color: string, overrideLastUsedColor: boolean = true) => {
  useEffect(() => {
    changeStatusBarColorAndroid(color, overrideLastUsedColor);

    return () => {
      changeToDefaultThemeColorAndStyle(overrideLastUsedColor);
    };
  }, [color, overrideLastUsedColor]);
};

export default useStatusBar;
