import instance from '@/utils/api/instance';

export interface SnapshotFilePayloadType {
  hrisProfileIds: string[];
  columnNames: string[];
  customFields: string[];
  customFieldAssociations: string[];
  labels: string[];
  orgStructures?: string[];
  orgStructuresRoles?: string[];
}

interface UploadFileReponse {
  data: {
    failed: any[];
    message: {
      details: string[];
    };
    status: string;
    success: any[];
  };
}

export const bulkEditLogsEndpoint = '/hris/mass_edit/snapshots';

export default {
  downloadFile: async (payload: SnapshotFilePayloadType): Promise<any> => {
    return await instance.post('/hris/mass_edit/snapshot/file', payload, {
      suppressSnackbar: true,
    });
  },

  getReviewData: async (payload: SnapshotFilePayloadType, isReview = false): Promise<any> => {
    return await instance.post(`/hris/mass_edit/snapshot/data?isReview=${isReview}`, payload, {
      suppressSnackbar: true,
    });
  },

  getValidEmails: async (payload: { emails: string[] }): Promise<any> => {
    return await instance.post('/hris/mass_edit/emails/verify', payload, { suppressSnackbar: true });
  },
  uploadFile: async (
    payload: any,
    snapshotOid: string,
    singleReasonFile: boolean,
    shouldCreateDraft = false
  ): Promise<UploadFileReponse> => {
    return await instance.post(
      `/hris/mass_edit?force=true&snapshotId=${snapshotOid}&perUser=${singleReasonFile}&shouldCreateDraft=${shouldCreateDraft}`,
      payload,
      {
        suppressSnackbar: true,
      }
    );
  },

  getLookupValues: async (): Promise<any> => {
    return await instance.get(`/hris/mass_edit/lookup`);
  },

  getCustomAndLabels: async (): Promise<any> => {
    return await instance.get(`/hris/mass_edit/fields`);
  },

  getCustomFields: async (): Promise<any> => {
    return await instance.get(`/custom_fields`);
  },

  addModificationReason: async (payload: any): Promise<any> => {
    return await instance.post('/hris/modification_reasons/', payload);
  },

  editModificationReason: async (payload: any, reasonId: string): Promise<any> => {
    return await instance.put(`/hris/modification_reasons/${reasonId}`, payload);
  },

  deleteModificationReason: async (reasonId: string): Promise<any> => {
    return await instance.delete(`/hris/modification_reasons/${reasonId}`);
  },

  bulkEditValidator: async (payload: any): Promise<any> => {
    return await instance.post('/hris/mass_edit/validator', payload);
  },
};
