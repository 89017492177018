import i18n from 'i18next';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Cell, CopyButton, Link, Stack, H2, H4 } from '@letsdeel/ui';

const StyledStep = styled(Stack)`
  padding-top: 16px;
  min-height: 400px;
`;

const StyledImageContainer = styled.div`
  border: 1px solid var(--gray-disabled);
  padding: 8px;
  border-radius: 8px;
  width: 216px;
  height: 216px;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    width: 238px;
    height: 238px;
    left: -12px;
    top: -12px;
  }
`;

const TokenString = styled(H4)`
  flex-grow: 1;
  flex-shrink: 1;
  word-break: break-all;
  text-overflow: ellipsis;
`;

interface Props {
  qrCode: string;
  secret: string;
}

const TFASetupLink = ({ secret, qrCode }: Props) => {
  const [isTextMode, setIsTextMode] = useState(false);

  if (isTextMode)
    return (
      <StyledStep direction="column" spacing={4}>
        <Stack direction="column" spacing={2}>
          <H2 medium>{i18n.t('temp.platform-fe.scenes.Settings.ab40702c2e').toString()}</H2>
          <H4 regular color="neutral.darker">
            {i18n.t('temp.platform-fe.scenes.Settings.8809ee1040').toString()}
          </H4>
        </Stack>
        <Stack direction="column" spacing={2} alignItems="center">
          <Cell className="p-6">
            <Stack direction="row" spacing={2} alignItems="center">
              <TokenString medium>{secret}</TokenString>
              <div>
                <CopyButton
                  text={secret}
                  className="sm"
                  copyToastMessage={i18n.t('temp.platform-fe.scenes.Settings.c462fae317').toString()}
                />
              </div>
            </Stack>
          </Cell>
          <Link key="show-code" onClick={() => setIsTextMode(false)}>
            {i18n.t('temp.platform-fe.scenes.Settings.0ce32e5a36').toString()}
          </Link>
        </Stack>
      </StyledStep>
    );

  return (
    <StyledStep direction="column" spacing={4}>
      <H2 medium>{i18n.t('temp.platform-fe.scenes.Settings.27930f56be').toString()}</H2>

      <Stack direction="column" spacing={3} alignItems="center">
        <StyledImageContainer>
          <img src={qrCode} alt={i18n.t('temp.platform-fe.scenes.Settings.0b9d04ab06').toString()} />
        </StyledImageContainer>

        <Link key="show-qr" onClick={() => setIsTextMode(true)}>
          {i18n.t('temp.platform-fe.scenes.Settings.4a7d515c20').toString()}
        </Link>
      </Stack>
    </StyledStep>
  );
};

export default TFASetupLink;
