import { API_URL, STAGE } from '../constants/main';
import OrganizationsStore from './OrganizationsStore';
import { PayrollInfoStatuses } from '@/types/Static/EmployeeInfo';
import { envShortKey } from '@/utils/environment';
import commonsApi from '@/utils/api/commons';
import instance from '@/utils/api/instance';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import { action, computed, makeObservable, observable } from 'mobx';
import type {
  ILookupsResponse,
  ILookupsStore,
  LookupsBankAccount,
  LookupsCountry,
  LookupsCurrencies,
  LookupsCurrency,
} from '@/types/Lookups';

export const INCORRECT_LOOKUP_DATA = 'Incorrect lookup data.';
export const LOOKUP_NETWORK_ERROR = 'Network error';

const DEFAULT_FILTERS = [
  'bankAccounts',
  'contractEditorPlaceholders',
  'countries',
  'currencies',
  'cryptoCurrencies',
  'cryptoCurrenciesCoinbase',
  'entityTypes',
  'paymentMethods',
  'withdrawMethods',
  'multipleCurrencyCountries',
  'features',
  'employeeInfo',
  'oauthDomains',
  'referrals',
  'UsZipCodeRanges',
  'backgroundCheckAllowedCountries',
  'sicNumbers',
  'socCodes',
  // TODO: check if these are actually used
  'hrisAnalytics',
  'integrations',
  'payAdjustments',
  'quoteInfo',
  'kycScreenings',
];

export class LookupStore implements ILookupsStore {
  constructor() {
    makeObservable(this, {
      quoteInfo: observable,
      UsZipCodeRanges: observable,
      bankAccounts: observable,
      multipleCurrencyCountries: observable,
      countries: observable,
      allCurrencies: observable,
      entityTypes: observable,
      payAdjustments: observable,
      paymentMethods: observable,
      withdrawMethods: observable,
      integrations: observable,
      allowedCountriesList: observable,
      countriesWithHrisEnabled: observable,
      fullCountriesList: observable,
      features: observable,
      frontend: observable,
      hrisAnalytics: observable,
      cryptoCurrencies: observable,
      cryptoCurrenciesCoinbase: observable,
      employeeInfo: observable,
      oauthDomains: observable,
      getLookup: action,
      bankAccountCountries: computed,
      currencies: computed,
      fxRateCurrencies: computed,
      defaultCurrencyOptions: computed,
      countryLists: computed,
      socCodesList: computed,
      saveLookups: action,
      sepaCountries: observable,
      kycScreenings: observable,
      getCountry: action,
      backgroundCheckAllowedCountries: observable,
      sicNumbers: observable,
      socCodes: observable,
    });
  }

  countriesWithCardDelivery: ILookupsStore['countriesWithCardDelivery'] = [];
  quoteInfo = {} as ILookupsStore['quoteInfo'];
  UsZipCodeRanges = {} as ILookupsStore['UsZipCodeRanges'];
  bankAccounts: ILookupsStore['bankAccounts'] = {};
  multipleCurrencyCountries: ILookupsStore['multipleCurrencyCountries'] = {};
  countries: ILookupsStore['countries'] = {};
  biMonthlyCountries: ILookupsStore['biMonthlyCountries'] = [];
  allCurrencies: ILookupsStore['allCurrencies'] = {};
  entityTypes: ILookupsStore['entityTypes'] = [];
  payAdjustments: ILookupsStore['payAdjustments'] = {};
  paymentMethods = {} as ILookupsStore['paymentMethods'];
  oauthDomains: ILookupsStore['oauthDomains'] = [];
  withdrawMethods = {} as ILookupsStore['withdrawMethods'];
  integrations: ILookupsStore['integrations'] = {};
  allowedCountriesList: ILookupsStore['allowedCountriesList'] = [];
  countriesWithHrisEnabled: ILookupsStore['countriesWithHrisEnabled'] = [];
  fullCountriesList: ILookupsStore['fullCountriesList'] = [];
  features: ILookupsStore['features'] = [];
  frontend: ILookupsStore['frontend'] = {};
  hrisAnalytics: ILookupsStore['hrisAnalytics'] = {};
  cryptoCurrencies = {} as ILookupsStore['cryptoCurrencies'];
  cryptoCurrenciesCoinbase = {} as ILookupsStore['cryptoCurrenciesCoinbase'];
  employeeInfo: ILookupsStore['employeeInfo'] = {};
  sepaCountries: ILookupsStore['sepaCountries'] = [];
  referrals: ILookupsStore['referrals'] = {
    INVITATION_DAILY_LIMIT: 0,
    REWARD_CONTRACT: '',
    REWARD_EOR: '',
    REWARD_LIMIT: '',
  };
  kycScreenings: ILookupsStore['kycScreenings'] = {
    veriff: {
      documents: {},
      countries: {},
    },
  };
  sicNumbers: ILookupsStore['sicNumbers'] = [];
  socCodes: ILookupsStore['socCodes'] = [];
  backgroundCheckAllowedCountries: ILookupsStore['backgroundCheckAllowedCountries'] = [];

  getEntityTypesByCountry: ILookupsStore['getEntityTypesByCountry'] = (country) => {
    return this.entityTypes.filter((option) => {
      if (country === 'US' && option.type === 'entity') {
        return option?.countries?.some((currentCountry) => currentCountry === country);
      }
      if (!option.type || option.type === 'entity') {
        if (option.countries) {
          return option.countries.some((currentCountry) => currentCountry === country);
        }
        return true;
      }
      return false;
    });
  };

  getUsTaxedAsEntityOptions: ILookupsStore['getUsTaxedAsEntityOptions'] = () => {
    return this.entityTypes.filter((option) => option.type === 'taxed-as');
  };

  isBiMonthlyCountry: ILookupsStore['isBiMonthlyCountry'] = (country) => {
    return this.biMonthlyCountries.includes(country);
  };

  hasProvince: ILookupsStore['hasProvince'] = (country) => {
    return Boolean(this.countries[country]?.provinces);
  };

  getAvailableProvinces: ILookupsStore['getAvailableProvinces'] = (country) => {
    const provinces = this.countries[country]?.provinces || [];

    return provinces.filter((province) => !province.unsupported);
  };

  getLookup = async (documents = DEFAULT_FILTERS) => {
    const url = envShortKey === 'prod' || envShortKey === 'demo' ? `/lookups` : `${API_URL}/lookups`;
    try {
      const { data } = await instance.get<ILookupsResponse>(url, {
        params: { documents, ver: 2 },
        headers: { 'x-app-host': window.location.host },
      });
      return data;
    } catch (e) {
      const errorMessage = (typeof e === 'object' && e && 'message' in e && e.message) ?? '-';
      console.error(`Can't load static data: ${url}. Message: ${errorMessage}`);
      throw e;
    }
  };

  get bankAccountCountries() {
    return [...this.allowedCountriesList.map(({ value }) => value)]
      .map((value) => this.countries[value])
      .sort((a, b) => (a.label < b.label ? -1 : 1))
      .filter((o) => o);
  }

  get currencies() {
    if (!Object.keys(this.allCurrencies)?.length) return {};

    // Hiding eorOnly currencies
    const currencies: LookupsCurrencies = {};

    for (let key in this.allCurrencies) {
      if (!this.allCurrencies[key].eorOnly) {
        currencies[key] = this.allCurrencies[key];
      }
    }

    return currencies;
  }

  get fxRateCurrencies() {
    if (!Object.keys(this.allCurrencies)?.length) return {};

    // Hiding currencies that aren't supported by the FxRate service
    const currencies: LookupsCurrencies = {};

    for (let key in this.allCurrencies) {
      if (this.allCurrencies[key].fxRateSupported) {
        currencies[key] = this.allCurrencies[key];
      }
    }

    return currencies;
  }

  get defaultCurrencyOptions() {
    return {
      EUR: { currency: this.currencies.EUR, bank: this.bankAccounts.sepa },
      USD: { currency: this.currencies.USD, bank: this.bankAccounts.unitedStates },
      GBP: { currency: this.currencies.GBP, bank: this.bankAccounts.swiftGBP },
      CAD: { currency: this.currencies.CAD, bank: this.bankAccounts.swiftCAD },
    };
  }

  get socCodesList() {
    let majorCodesMap: Record<string, string> = {};

    return this.socCodes?.reduce((accumulator, current) => {
      const { title, code } = current;
      const firstTwoDigits = code.slice(0, 2);

      if (code.startsWith('00')) return accumulator;

      if (majorCodesMap[firstTwoDigits]) {
        const newItem = {
          group: majorCodesMap[firstTwoDigits],
          label: `${code} ${title}`,
          value: code,
        };
        return [...accumulator, newItem];
      }

      majorCodesMap[firstTwoDigits] = `${code} ${title}`.toUpperCase();

      return accumulator;
    }, [] as { label: string; value: string; group: string }[]);
  }

  get countryLists() {
    if (!this.countries) {
      return {
        client: {
          creditCardCountries: [],
        },
        contractor: {
          taxResidences: [],
          citizenships: [],
          residences: [],
          nationalities: [],
        },
        entity: [],
      };
    }

    const withCommonCountries = (options: LookupsCountry[]) => {
      if (options.length === 0) {
        return [];
      }

      const mostCommon = new Set(['US', 'GB', 'CA']);

      return [
        {
          label: 'MOST COMMON',
          options: options.filter(({ value }) => mostCommon.has(value)),
        },
        {
          label: 'ALL',
          options: options.filter(({ value }) => !mostCommon.has(value))?.sort((a, b) => (a.label < b.label ? -1 : 1)),
        },
      ];
    };

    return {
      client: {
        creditCardCountries: withCommonCountries(
          Object.values(this.countries)
            .filter((country) => !country.blocked?.client?.some((block) => block === 'cc-payment'))
            .sort((a, b) => (a.label < b.label ? -1 : 1))
        ),
      },
      contractor: {
        taxResidences: Object.values(this.countries)
          .filter((country) => !country.blocked?.contractor?.some?.((block) => block === 'tax-residence'))
          .sort((a, b) => (a.label < b.label ? -1 : 1)),
        citizenships: Object.values(this.countries)
          .filter((country) => !country.blocked?.contractor?.some?.((block) => block === 'citizenship'))
          .sort((a, b) => (a.label < b.label ? -1 : 1)),
        residences: withCommonCountries(
          Object.values(this.countries)
            .filter((country) => !country.blocked?.contractor?.some?.((block) => block === 'residence'))
            .sort((a, b) => (a.label < b.label ? -1 : 1))
        ),
        nationalities: Object.values(this.countries).sort((a, b) => (a.label < b.label ? -1 : 1)),
      },
      entity: Object.values(this.countries)
        .filter((country) => !country.blocked?.entity?.some?.((block) => block === 'entity'))
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    };
  }

  private parseMultipleCurrencyCountries = (
    multipleCurrencyCountries: Record<string, Record<string, { currency: string; bank: string }>>
  ) => {
    this.multipleCurrencyCountries = Object.entries(multipleCurrencyCountries).reduce(
      (accumulator, [country, detailsByCurrency]) => {
        accumulator[country] = Object.values(detailsByCurrency).reduce((r, { currency, bank }) => {
          r[currency] = { currency: this.allCurrencies[currency], bank: this.bankAccounts[bank] };
          return r;
        }, {} as Record<string, { currency: LookupsCurrency; bank: LookupsBankAccount }>);
        return accumulator;
      },
      {} as Record<string, Record<string, { currency: LookupsCurrency; bank: LookupsBankAccount }>>
    );
  };

  isFeatureEnabled: ILookupsStore['isFeatureEnabled'] = (featureName) => {
    return this.features.includes(featureName);
  };

  saveLookups = async () => {
    try {
      let entityTypes = null;

      const lookups = await this.getLookup();

      this.allCurrencies = lookups.currencies;

      this.countries = lookups.countries;
      this.bankAccounts = lookups.bankAccounts;
      this.employeeInfo = lookups.employeeInfo;
      this.cryptoCurrencies = lookups.cryptoCurrencies;
      this.cryptoCurrenciesCoinbase = lookups.cryptoCurrenciesCoinbase;
      this.payAdjustments = lookups.payAdjustments;
      this.paymentMethods = lookups.paymentMethods;
      this.withdrawMethods = lookups.withdrawMethods;
      this.frontend = lookups.frontend;
      this.hrisAnalytics = lookups.hrisAnalytics;
      this.backgroundCheckAllowedCountries = lookups.backgroundCheckAllowedCountries;
      this.integrations = lookups.integrations;
      this.UsZipCodeRanges = lookups.UsZipCodeRanges;
      this.quoteInfo = lookups.quoteInfo;
      this.oauthDomains = lookups.oauthDomains;
      this.kycScreenings = lookups.kycScreenings;
      this.referrals = lookups.referrals;
      this.sicNumbers = lookups.sicNumbers;
      this.socCodes = lookups.socCodes;
      this.biMonthlyCountries = await commonsApi.getBiMonthlyCountries();

      entityTypes = lookups.entityTypes;

      const multipleCurrencyCountries = lookups.multipleCurrencyCountries;
      this.parseMultipleCurrencyCountries(multipleCurrencyCountries);

      this.entityTypes = entityTypes.sort();
      if (this.entityTypes && Array.isArray(this.entityTypes)) {
        this.entityTypes.push({ label: 'Other', value: 'other', type: '' });
      }

      this.allowedCountriesList = Object.values(this.countries)
        .filter(({ prohibited }) => !prohibited)
        .sort((a, b) => (a.label < b.label ? -1 : 1));

      this.countriesWithHrisEnabled = Object.values(this.countries)
        .filter((country) => country.hris?.enabled)
        .sort((a, b) => (a.label < b.label ? -1 : 1));

      this.fullCountriesList = Object.values(this.countries).sort((a, b) => (a.label < b.label ? -1 : 1));

      this.countriesWithCardDelivery = Object.values(this.countries)
        .filter(({ hasCardDelivery, prohibited }) => hasCardDelivery && !prohibited)
        .sort((a, b) => (a.label < b.label ? -1 : 1));

      this.sepaCountries = Object.values(this.countries)
        .filter(({ isSepa }) => isSepa)
        .sort((a, b) => (a.label < b.label ? -1 : 1));

      this.features = Object.entries(lookups.features).reduce((acc, [featureName, config]) => {
        const configPresent = Boolean(
          isObject(config)
            ? (config as Record<'prod' | 'dev', unknown>)[STAGE === 'production' ? 'prod' : 'dev']
            : config
        );
        if (configPresent) {
          acc.push(featureName);
        }
        return acc;
      }, [] as Array<string>);
    } catch (error) {
      console.error(error);
      const message =
        error && typeof error === 'object' && 'message' in error && error.message === 'Network Error'
          ? LOOKUP_NETWORK_ERROR
          : INCORRECT_LOOKUP_DATA;
      throw new Error(message);
    }
  };

  getWithholdingTax: ILookupsStore['getWithholdingTax'] = (legalEntityId) => {
    if (!OrganizationsStore.legalEntities) return;
    const legalEntity = OrganizationsStore.legalEntities.find(
       
      ({ publicId, id }) => String(publicId) === String(legalEntityId) || String(id) === String(legalEntityId)
    );
    const entityCountry = legalEntity?.address?.country || legalEntity?.country;
    if (!entityCountry) return;
    return this.countries[entityCountry]?.withholdingTax;
  };

  isWithholdingTaxSupported: ILookupsStore['isWithholdingTaxSupported'] = (legalEntityId) => {
    return Boolean(this.getWithholdingTax(legalEntityId));
  };

  isEUVatRequired: ILookupsStore['isEUVatRequired'] = (countryCode) => {
    return Boolean(countryCode && this.countries[countryCode]?.tags?.includes('EU_VAT_ID_REQUIRED'));
  };

  isEUMember: ILookupsStore['isEUMember'] = (countryCode) => {
    return Boolean(countryCode && this.countries[countryCode]?.tags?.includes('EU_MEMBER'));
  };

  // For getting employee info at the federal, state and locality level with global
  private getEmployeeInfoWithGlobal = (country: string, state?: string, city?: string) => {
    return {
      ...this.employeeInfo['GLOBAL'],
      ...(country ? this.employeeInfo[country] : {}),
      ...(country && state ? this.employeeInfo[`${country}_${state}`] : {}),
      ...(country && state && city ? this.employeeInfo[`${country}_${state}_${city}`] : {}),
    };
  };

  getGlobalEmployeeInfo = () => {
    return this.employeeInfo['GLOBAL'];
  };

  isEmployeeNeedPayrollInfo: ILookupsStore['isEmployeeNeedPayrollInfo'] = (country) => {
    const fields = this.getEmployeeInfoWithGlobal(country);
    return Object.entries(fields).some(([, field]) => this.filterEmployeePayrollFieldsFunc?.(field));
  };

  isMissingPayrollInfo: ILookupsStore['isMissingPayrollInfo'] = (country, additionalInfoObj) => {
    if (!this.isEmployeeNeedPayrollInfo(country)) return false;
    if (!additionalInfoObj) return true;

    const additionalInfoFields = this.getEmployeeInfoWithGlobal(country);
    return Object.keys(additionalInfoFields)
      .filter((fieldKey) => this.filterEmployeePayrollFieldsFunc?.(additionalInfoFields?.[fieldKey]))
      .some((fieldKey) => {
        if (additionalInfoFields?.[fieldKey]?.isOptional) return false;
        if (additionalInfoObj?.[fieldKey] === null || additionalInfoObj?.[fieldKey] === undefined) {
          if (additionalInfoFields?.[fieldKey]?.dependencies) {
            return Object.keys(additionalInfoFields?.[fieldKey]?.dependencies || {}).some(
              (dependFieldKey) =>
                additionalInfoObj?.[dependFieldKey] === additionalInfoFields?.[fieldKey]?.dependencies?.[dependFieldKey]
            );
          }
          return true;
        }
        return false;
      });
  };

  filterEmployeePayrollFieldsFunc: ILookupsStore['filterEmployeePayrollFieldsFunc'] = (additionalDetail) => {
    return PayrollInfoStatuses.includes(additionalDetail?.['requiredBy']);
  };
  filterEmployeeSigningFieldsFunc: ILookupsStore['filterEmployeeSigningFieldsFunc'] = (additionalDetail) => {
    return !PayrollInfoStatuses.includes(additionalDetail?.['requiredBy']);
  };

  getFeatureFlag: ILookupsStore['getFeatureFlag'] = (key, useObjWithEnv = false) => {
    const overrideValue = localStorage.getItem(`frontend.${key}`);
    if (overrideValue !== null) {
      return JSON.parse(overrideValue);
    }
    const featureValue = get(this.frontend, key);
    if (useObjWithEnv && isObject(featureValue)) {
      return (featureValue as any)[envShortKey];
    }
    return featureValue;
  };

  getCountry: ILookupsStore['getCountry'] = (countryCode) => {
    return this.countries[countryCode];
  };

  getCountryNameFromCode: ILookupsStore['getCountryNameFromCode'] = (countryCode) => {
    return this.countries[countryCode]?.label;
  };

  getCountryCodeFromCountryName: ILookupsStore['getCountryCodeFromCountryName'] = (countryName: string) => {
    const countryNameDictionary = Object.keys(this.countries).reduce((acc: { [key: string]: any }, key) => {
      return { ...acc, [this.countries[key].label.toLowerCase()]: key };
    }, {});

    return countryNameDictionary[countryName.toLowerCase()] ?? null;
  };

  getCurrencyFlag: ILookupsStore['getCurrencyFlag'] = (currency, country) => {
    if (currency === 'EUR') return 'european-union';
    if (currency === 'USD') return 'united-states';
    if (currency === 'GBP') return 'united-kingdom';
    if (currency === 'AUD') return 'australia';
    if (currency === 'MAD') return 'morocco';

    const currencyCountry =
      country && this.countries[country]?.defaultCurrency === currency
        ? this.countries[country]
        : Object.values(this.countries)?.find((item) => item?.defaultCurrency === currency);

    return currencyCountry?.value || 'US';
  };

  getIcpEmployeeListFilterCache = () => {
    const cachedSearch = localStorage.getItem('ICP_EMPLOYEE_LIST_FILTERS');
    return cachedSearch;
  };

  getIcpDataUpdatesFilterCache = () => {
    const cachedSearch = sessionStorage.getItem('ICP_DATA_UPDATES_FILTERS');
    return cachedSearch;
  };

  getEnabledCountriesForTaxesSubscription: ILookupsStore['getEnabledCountriesForTaxesSubscription'] = () => {
    return this.getFeatureFlag('taxesSubscription.enabledCountries') || [];
  };

  isFeatureEnabledByPercentage = (referenceNumber: number, featureKey: string) => {
    const featureValue = this.getFeatureFlag(featureKey);
    const percentage = Number.isInteger(featureValue) ? featureValue : parseInt(featureValue || '0', 10);

    return (referenceNumber % 100) + 1 <= percentage;
  };

  loadLookup = async (lookup: string) => {
    try {
      const lookupResponse: any = await this.getLookup([lookup]);
      const lookups = this;
      (lookups as any)[lookup] = lookupResponse[lookup];
      return lookupResponse[lookup];
    } catch (err) {
      console.error(err);
    }
  };
}

export default new LookupStore();
