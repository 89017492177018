import instance from '@/utils/api/instance';

export interface ProjectAssignmentBasicPayload {
  rate: number;
  description?: string | null;
  attachmentKey?: string | null;
  attachmentFilename?: string | null;
}

export interface CreateProjectAssignmentPayload extends ProjectAssignmentBasicPayload {
  contractId: string;
}

export interface CreateProjectPayload {
  title: string;
  description: string;
  attachmentKey?: string;
  attachmentFilename?: string;
  hourlyReportPresets?: CreateProjectAssignmentPayload[];
  hourlyReportPresetsProcessingType?: 'SYNC' | 'ASYNC';
}

export interface CreateProjectResponse {
  id: string;
  asyncTask: {
    id: string;
  };
}

interface AssignProjectPayload extends ProjectAssignmentBasicPayload {
  hourlyReportRootPresetId?: string;
  workStatementId?: string;
}

interface EditProjectAssignmentPayload extends ProjectAssignmentBasicPayload {}

export default {
  createProject: async (payload: CreateProjectPayload) => {
    const res = await instance.post<CreateProjectResponse>('/hourly_reports/root_presets', payload);
    return res.data;
  },
  deleteProject: async (projectId: string) => {
    await instance.put(`/hourly_reports/root_presets/${projectId}`, { status: 'INACTIVE' });
  },
  assignProject: async (assignmentPayload: AssignProjectPayload) => {
    await instance.post('/hourly_reports/presets', assignmentPayload);
  },
  editProjectAssignment: async (assignmentId: string, assignmentPayload: EditProjectAssignmentPayload) => {
    await instance.patch(`/hourly_reports/presets/${assignmentId}`, assignmentPayload);
  },
  deleteProjectAssignment: async (assignmentId: string) => {
    await instance.delete(`/hourly_reports/presets/${assignmentId}`);
  },
};
