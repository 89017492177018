import { HStack, Stack, VStack, P } from '@letsdeel/ui';
import React, { type FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import './AppPinInput.less';
import { addSetupPinCodeEvent } from '../../utils';

export interface AppPinInputProps {
  pinInputKey: string;
  initialValues?: string[];
  matchValues?: string[];
  isLoading?: boolean;
  justifyContent?: 'center' | 'flex-start';
  noMatchMessage?: string;
  hasError?: boolean;
  setHasError?: (value: boolean) => void;
  errorMessage?: string;
  onSubmit?: (value: string[]) => void;
}

const AppPinInput: FunctionComponent<AppPinInputProps> = ({
  pinInputKey,
  initialValues,
  matchValues,
  isLoading = false,
  justifyContent = 'center',
  noMatchMessage,
  hasError = false,
  setHasError,
  errorMessage,
  onSubmit,
}) => {
  const INPUT_LENGTH = 4;
  const [pin, setPin] = useState<string[]>(() => Array(INPUT_LENGTH).fill(''));
  const [displayedPin, setDisplayedPin] = useState<string[]>(() => Array(INPUT_LENGTH).fill(''));

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isMatch, setIsMatch] = useState(true);
  const [shake, setShake] = useState(false);

  const timer = useRef<NodeJS.Timeout | null>(null);

  const shakeInput = useCallback(() => {
    setShake(true);
    setTimeout(() => {
      setShake(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (hasError) {
      shakeInput();
    }
  }, [hasError, pin, shakeInput]);

  useEffect(() => {
    setPin(initialValues || Array(INPUT_LENGTH).fill(''));
    if (initialValues?.join('').length === 4) {
      setDisplayedPin('••••'.split(''));
    }
  }, [initialValues]);

  const submitPin = useCallback(
    async (values: string[]) => {
      if (onSubmit) {
        await onSubmit(values);
      }
    },
    [onSubmit]
  );

  const clearTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }, []);

  const resetValues = useCallback(() => {
    clearTimer();
    inputRef.current?.focus();
    setPin(Array(INPUT_LENGTH).fill(''));
    setDisplayedPin(Array(INPUT_LENGTH).fill(''));
  }, [clearTimer]);

  useEffect(() => {
    if (hasError) {
      resetValues();
    }
  }, [hasError, resetValues]);

  useEffect(() => {
    resetValues();
    setIsMatch(true);
    if (setHasError) setHasError(false);
  }, [pinInputKey, resetValues, setHasError]);

  const validateValues = useCallback(async () => {
    if (!onSubmit) return;

    if (matchValues) {
      const isMatch = pin.join('') === matchValues.join('');

      setIsMatch(isMatch);
      if (isMatch) {
        await submitPin(pin);
      } else {
        shakeInput();
        resetValues();
      }
    } else {
      await submitPin(pin);
    }
  }, [matchValues, pin, shakeInput, resetValues, submitPin, onSubmit]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.slice(0, INPUT_LENGTH).split(''); // Limit input to 4 digits

      setIsMatch(true);
      addSetupPinCodeEvent({ step_name: 'User Enters Pincode' });
      if (setHasError) setHasError(false);

      // Handle masking and display the last entered digit
      const newDisplayedPin = value.map((digit, index) => (index === value.length - 1 ? digit : '•'));
      setDisplayedPin(newDisplayedPin);

      // Mask the last digit after 1 second
      if (value.length > 0) {
        clearTimer();
        timer.current = setTimeout(() => {
          setDisplayedPin((prev) => {
            const maskedPin = [...prev];
            maskedPin[value.length - 1] = '•';
            return maskedPin;
          });
        }, 200);
      }

      setPin(value);
    },
    [clearTimer, setHasError]
  );

  useEffect(() => {
    if (pin.join('').length === INPUT_LENGTH) {
      const validationTimeout = setTimeout(() => validateValues(), 200);
      return () => clearTimeout(validationTimeout);
    }
  }, [pin, validateValues]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Backspace') {
        resetValues();
      }
    },
    [resetValues]
  );

  const handleFocus = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <VStack gap={'12px'}>
      <HStack
        gap={2}
        alignSelf={'stretch'}
        sx={{
          justifyContent,
          opacity: isLoading ? 0.5 : 1,
          ...(shake && (!isMatch || hasError) ? { animation: 'shake 0.2s' } : {}),
        }}
      >
        {[0, 1, 2, 3].map((index) => (
          <Stack
            onClick={handleFocus}
            key={index}
            sx={{
              width: '64px',
              height: '64px',
              border: `1px solid`,
              borderRadius: '8px',
              borderColor:
                !isMatch || hasError
                  ? 'error.main'
                  : pin.join('').length === index || (pin.join('').length === 4 && index === 3 && !isLoading)
                  ? '#111111'
                  : 'primary.dark',
              margin: '0',
              opacity: isLoading ? 0.5 : 1,
              backgroundColor: 'primary.paper',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Stack
              sx={{
                color: 'text.primary',
                fontSize: displayedPin[index] === '•' ? '40px' : '20px',
                textAlign: 'center',
                lineHeight: '24px',
                letterSpacing: '0.1px',
                fontWeight: 500,
              }}
            >
              {displayedPin[index]}
            </Stack>
          </Stack>
        ))}
        <input
          ref={inputRef}
          type="tel"
          value={pin.join('')}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          maxLength={4}
          style={{ position: 'absolute', opacity: 0 }}
        />
      </HStack>
      {!isMatch ? (
        <P color="error.main" fontSize={12} alignSelf={'stretch'}>
          {noMatchMessage}
        </P>
      ) : null}
      {hasError ? (
        <P color="error.main" fontSize={12} alignSelf={'center'}>
          {errorMessage}
        </P>
      ) : null}
    </VStack>
  );
};

export default AppPinInput;
