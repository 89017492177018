import instance from '@/utils/api/instance';

export default {
  getToken: async (conversationId?: number) => {
    const param = conversationId ? `?deelAIConversationId=${conversationId}` : '';

    try {
      const { data } = await instance.get(`/chat/zowie${param}`);
      return data;
    } catch (err) {
      console.error(err);
    }
  },
};
