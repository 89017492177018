import instance from '@/utils/api/instance';
import type {
  CustomVerification,
  Translation,
} from '@/scenes/Settings/scenes/VerificationSettings/Interfaces/VerificationInterfaces';
import type { CountryCode } from '@letsdeel/ui';

type ShareholderBulkPayloadT =
  | {
      type: 'individual';
      firstName: string;
      lastName: string;
      citizenship: CountryCode;
      hasIdentityProfile?: boolean;
    }
  | {
      type: 'entity';
      entityName: string;
      countryOfRegistration: CountryCode;
      ubo: {
        firstName: string;
        lastName: string;
        citizenship: CountryCode;
      };
    };

export default {
  create: async (payload: any) => {
    const { data } = await instance.post('companies', payload);
    return data;
  },
  getCompany: async (id: string) => {
    const { data } = await instance.get(`companies/${id}`);
    return data;
  },
  update: async (id: string | number, payload: any) => {
    const { data } = await instance.put(`companies/${id}`, payload);
    return data;
  },
  deleteCompany: async (id: string) => {
    await instance.delete(`companies/${id}`);
  },
  addShareholders: async (payload: any) => {
    const data = instance.post(`shareholders`, payload);
    return data;
  },
  addShareholdersBulk: async (payload: { shareholders: ShareholderBulkPayloadT[] }) => {
    return instance.post(`shareholders/bulk`, payload);
  },
  updateShareholder: async (shareholderId: string, payload: any) => {
    const data = instance.patch(`shareholders/${shareholderId}`, payload);
    return data;
  },
  deleteShareholder: async (shareholderId: string) => {
    const data = instance.delete(`shareholders/${shareholderId}`);
    return data;
  },
  getShareholders: async () => {
    const data = await instance.get(`shareholders`);
    return data;
  },
  verifyShareholders: async (id: number, shareholderId: string | number, payload: any) => {
    const data = instance.post(`companies/${id}/shareholders/${shareholderId}/verify `, payload);
    return data;
  },
  customVerification: async (payload: CustomVerification) => {
    const data = instance.post('/screenings/customVerification', payload);
    return data;
  },
  getCustomVerification: async () => {
    const data = instance.get('/screenings/customVerification');
    return data;
  },
  postTranslation: async (id: number, payload: Translation) => {
    const data = instance.post(`/screenings/customVerification/${id}/translation`, payload);
    return data;
  },
};
