import { type ForgotPasswordInitialValues } from './types';
import * as Yup from 'yup';

export const FORGOT_PASSWORD_INITIAL_VALUES: ForgotPasswordInitialValues = {
  email: '',
  recaptcha: undefined,
};

export const FORGOT_PASSWORD_VALIDATION_SCHEMA = Yup.object({
  email: Yup.string().trim().email('Please enter a valid email address').required('Email is required'),
});
