export enum AccountTypes {
  Client = 'client',
  Contractor = 'contractor',
  Employee = 'employee',
  DirectEmployee = 'direct_employee',
  HRISDirectEmployee = 'hris_direct_employee',
  PeoEmployee = 'peo_employee',

  // based on checks in UserStore.ts
  Eor = 'eor',
  Icp = 'icp',
  TaxAdvisor = 'tax_advisor',

  // VendorManagement
  VmsVendor = 'vms_vendor',
}

export const AccountTypesLabel: Record<AccountTypes, string> = {
  [AccountTypes.Client]: 'Client',
  [AccountTypes.Contractor]: 'Contractor',
  [AccountTypes.DirectEmployee]: 'Direct employee global payroll',
  [AccountTypes.Employee]: 'Employee',
  [AccountTypes.HRISDirectEmployee]: 'Direct Employee',
  [AccountTypes.PeoEmployee]: 'PEO Employee',

  [AccountTypes.Eor]: '',
  [AccountTypes.Icp]: '',
  [AccountTypes.TaxAdvisor]: '',
  [AccountTypes.VmsVendor]: '',
};

export type VisibleAccountTypes =
  | AccountTypes.Client
  | AccountTypes.Contractor
  | AccountTypes.Employee
  | AccountTypes.PeoEmployee
  | AccountTypes.HRISDirectEmployee;

export const isVisibleAccountType = (type: AccountTypes): type is VisibleAccountTypes => {
  return [
    AccountTypes.Client,
    AccountTypes.Contractor,
    AccountTypes.Employee,
    AccountTypes.PeoEmployee,
    AccountTypes.HRISDirectEmployee,
  ].includes(type);
};

export interface FeatureContent {
  subtitle: string;
  graphic: string;
  features: string[];
  title?: never;
}

export interface InfoContent {
  title: string;
  body: string;
  linkText: string;
  link: string;
  features?: never;
}

export type SignUpContent = { [key in VisibleAccountTypes]: FeatureContent | InfoContent };
