import type { AccountTypes } from '@/scenes/Signup/constants';
import instance from '@/utils/api/instance';
import type { IDeveloperApp } from '@/scenes/DeveloperCenter/models/developer-app.model';
import type { DeveloperConsumerAccountInfo } from '@/scenes/Settings/scenes/Integrations/models/Oauth';
import UserStore from '../../stores/UserStore';
// TODO: After hris campaign, uncomment this
// import { goToNextBanner } from '@/components/UpsellingBanners/utils';
import LogRocket from '@/external/LogRocket';
import { IS_COOKIE_TOKEN } from '@/constants/main';
import { clearLocalStorageKeys } from '@/utils/localstorage';
import { LS_HRIS_PEOPLE_LIST_CUSTOM_FILTERS_KEY } from '@/constants/localStorage';
import type { IDeveloperAccount } from '@/scenes/DeveloperCenter/models/developer-account.model';
import { isNative } from '../crossPlatform';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { removeAppPinInformation } from '@/native/AppPin/appPin';

const SIGNUP_ROUTE_EXPIRES_LIMIT = 1000 * 60 * 60 * 24 * 7 * 2;
export const REQUEST_EMAIL_CHANGE_ERROR_ALREADY_EXISTS = 'REQUEST_EMAIL_CHANGE_ERROR_ALREADY_EXISTS';

export const applyLoginSideEffects = ({ opt, token }: { opt: any; token: string }) => {
  clearLocalStorageKeys([
    ...LS_HRIS_PEOPLE_LIST_CUSTOM_FILTERS_KEY(UserStore?.organizations ? UserStore?.organizations : []),
  ]);

  if (!IS_COOKIE_TOKEN) {
    localStorage.setItem('token', token);
  }

  if (!opt.dryRun) {
    UserStore.token = token;
  }
};

const getLoginEventHeaders = async () => {
  const deviceDimensions = JSON.stringify({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  if (isNative()) {
    const deviceInfo = await Device.getInfo();
    const appInfo = await App.getInfo();

    return {
      'x-device': deviceInfo.model,
      'x-os': deviceInfo.platform,
      'x-platform': 'mobile',
      'x-app-version': appInfo.version,
      'x-device-dimensions': deviceDimensions,
    };
  } else {
    return {
      'x-platform': 'web',
      'x-device-dimensions': deviceDimensions,
    };
  }
};

export default {
  leaveFeedback({
    categoryId,
    text,
    topic,
    userType,
    country,
    contractOid,
    source,
    theme,
  }: {
    categoryId?: string;
    text: string;
    topic?: string;
    userType?: string;
    country?: string | null;
    theme?: string;
    source?: string;
    contractOid?: string | null;
  }) {
    const openReplaySessionId = LogRocket.getSessionID();

    return instance.post('feedbacks', {
      categoryId,
      text,
      topic,
      userType,
      country,
      contractOid,
      openReplaySessionId,
      source,
      theme,
    });
  },

  async changePassword({
    currentPassword,
    newPassword,
    totp,
    otpProvider,
  }: {
    currentPassword: string;
    newPassword: string;
    totp?: string;
    otpProvider?: string;
  }) {
    // @ts-ignore
    log.debug('password', { old_password: currentPassword, password: newPassword });

    if (totp) {
      return await instance.post(
        'password',
        {
          old_password: currentPassword,
          password: newPassword,
          code: totp,
          otpProvider,
        },
        { suppressSnackbar: true }
      );
    }

    return await instance.post(
      'password',
      { old_password: currentPassword, password: newPassword },
      { suppressSnackbar: true, withCredentials: true }
    );
  },

  async passwordRotate({
    currentPassword,
    newPassword,
    passwordRotationToken,
  }: {
    currentPassword: string;
    newPassword: string;
    passwordRotationToken: string;
  }) {
    // @ts-ignore
    log.debug('password_rotate', { currentPassword, newPassword });

    return await instance.post(
      'login/password_rotate',
      { currentPassword, newPassword, passwordRotationToken },
      { suppressSnackbar: true, withCredentials: true }
    );
  },

  async register(opt: any) {
    // @ts-ignore
    log.debug('register', opt);
    const signupRouteLs = localStorage.getItem('signupRoute');

    let signupRoute = null;
    try {
      if (signupRouteLs) signupRoute = JSON.parse(signupRouteLs);
    } catch {}

    const {
      data: { token },
    } = await instance.post(
      'register',
      {
        ...opt,
        signupRoute:
          signupRoute?.time && signupRoute?.route && Date.now() - signupRoute?.time < SIGNUP_ROUTE_EXPIRES_LIMIT
            ? signupRoute?.route
            : 'self',
      },
      { withCredentials: true }
    );

    if (!IS_COOKIE_TOKEN) {
      localStorage.setItem('token', token);
    }

    UserStore.token = token;

    // @ts-ignore
    if (window.dataLayer) window.dataLayer.push({ event: 'signup_form_submit' });

    return token;
  },

  async login(opt: any) {
    // @ts-ignore
    log.debug('login', opt);
    const {
      data: {
        token,
        totp,
        otpOptions,
        passwordRotationToken,
        trustedDeviceFeature,
        trustedDeviceExpiryDays,
        partialLoginToken,
        hasPhoneNumber,
        hasBackupEmail,
      },
    } = await instance.post('login', opt, {
      withCredentials: true,
      headers: await getLoginEventHeaders(),
    });

    applyLoginSideEffects({ opt, token });

    return {
      token,
      totp,
      otpOptions,
      passwordRotationToken,
      trustedDeviceFeature,
      partialLoginToken,
      hasPhoneNumber,
      hasBackupEmail,
      trustedDeviceExpiryDays,
    };
  },

  async getTokenByEmail(email: string) {
    // @ts-ignore
    log.debug('switch user', email);

    const response = await instance.post('login_demo/worker', { email }, { withCredentials: true });
    const { data } = response;

    applyLoginSideEffects({ opt: {}, token: data.token });

    return { token: data.token };
  },

  async resendLoginOtp(payload: { totp: string; otpProvider: string }) {
    // @ts-ignore
    log.debug('resending otp', payload);
    const {
      data: { totp },
    } = await instance.post('login/totp/resend', payload, { withCredentials: true });

    return { totp };
  },

  async optOutOf2FA(optOut: boolean) {
    const { data } = await instance.post('user/opt_out_of_2fa', { optOut });
    return data;
  },

  async logout(idle: boolean) {
    // @ts-ignore
    log.debug('logging out');

    if (isNative() && !idle) {
      await removeAppPinInformation();
    }

    // TODO: After hris campaign, uncomment this
    // goToNextBanner();
    await instance.post(idle ? `logout?idle=${idle}` : `logout`, {}, { withCredentials: true });
    localStorage.removeItem('token');
  },

  async verify(email: string) {
    // @ts-ignore
    log.debug('verify', email);
    await instance.post('verify', { email });
  },

  async validateToken(token: string, context: AccountTypes) {
    // more contexts will be added as they get supported by this endpoint
    const { data } = await instance.get(`verify/${token}?context=${context}`);
    return data;
  },

  async retrieveContractDataByToken(token: string) {
    const { data } = await instance.get(`register/${token}/contract`);
    return data;
  },

  async reset({
    totp,
    email,
    token,
    password,
    recaptcha,
    forceRecaptcha,
    useBackupEmail,
  }: {
    totp?: string;
    email?: string;
    token?: string;
    password?: string;
    recaptcha?: string | null;
    forceRecaptcha?: boolean;
    useBackupEmail?: boolean;
  }) {
    if (email) {
      // @ts-ignore
      log.debug('reset', email);
      return instance.post('reset', { email, recaptcha, forceRecaptcha, useBackupEmail });
    } else if (token) {
      // @ts-ignore
      log.debug('reset', token);
      return await instance.post(`reset/${token}`, password ? { password, totp } : { dryRun: true });
    }
  },

  async ssoLogin(email: string) {
    return await instance.post<{
      ssoURL: {
        name: string;
        allowBypass: boolean;
        connectionUrl: string;
      };
    }>('login/get_sso_url', { email });
  },

  employee: {
    register: async (opt: any) => {
      // @ts-ignore
      log.debug('employee register', opt);
      const result = await instance.post('register/employee', opt, { withCredentials: true });
      // @ts-ignore
      if (window.dataLayer) window.dataLayer.push({ event: 'signup_form_submit' });
      return result;
    },
  },

  directEmployee: {
    register: async (opt: any) => {
      const result = await instance.post('register/direct_employee', opt, { withCredentials: true });
      // @ts-ignore
      if (window.dataLayer) window.dataLayer.push({ event: 'signup_form_submit' });
      return result;
    },
  },

  hrisDirectEmployee: {
    register: async (opt: any) => {
      const result = await instance.post('register/hris-direct-employee', opt, { withCredentials: true });
      // @ts-ignore
      if (window.dataLayer) window.dataLayer.push({ event: 'signup_form_submit' });
      return result;
    },
  },

  peoEmployee: {
    register: async (opt: any) => {
      const result = await instance.post('register/peo_employee', opt, { withCredentials: true });
      // @ts-ignore
      if (window.dataLayer) window.dataLayer.push({ event: 'signup_form_submit' });
      return result;
    },
  },

  requestEmailChange: async (newEmail: string) => {
    try {
      await instance.post('user/change-email/confirm-current-email', { newEmail });
    } catch (e: any) {
      if (e.response?.status === 400) throw { reason: REQUEST_EMAIL_CHANGE_ERROR_ALREADY_EXISTS };
      throw e;
    }
  },

  verifyOldEmail: async (token: string) => {
    await instance.post('user/change-email/confirm-new-email', { token });
  },

  updateEmail: async (token: string) => {
    await instance.post('user/change-email/apply-change', { token });
  },

  getBlacklistedEmailDomains: async () => {
    const { data } = await instance.get<string>('register/email-domain-blacklist');
    return data;
  },

  getSupportInfo: async () => {
    const { data } = await instance.get('profiles/me/support');
    return data;
  },

  getToken: async () => {
    const {
      data: { token },
    } = await instance.post('/login/get-token', {}, { withCredentials: true });
    return token;
  },

  setToken: async (token: string) => {
    await instance.post('/login/set-token', { token }, { withCredentials: true });
  },

  createSandbox: async (email: string, password: string) => {
    const organizationId = (UserStore?.organization as any)?.id;
    const { data } = await instance.post('sandbox_external/accounts', { email, password, organizationId });
    return data;
  },

  getSandboxes: async () => {
    const organizationId = (UserStore?.organization as any)?.id;
    const { data } = await instance.get(`sandbox_external/accounts?organizationIds=[${organizationId}]`);
    return data;
  },

  createDeveloperConsumerAccount: async (consumerAccountInfo: DeveloperConsumerAccountInfo) => {
    const { data } = await instance.post<{ data: IDeveloperAccount }>(`/apps/oauth2/consumers`, consumerAccountInfo);
    return data.data;
  },

  createDeveloperApp: async (consumerAccountInfo: IDeveloperApp) => {
    const { data } = await instance.post(`apps/oauth2/clients`, consumerAccountInfo);
    return data.data;
  },

  updateDeveloperApp: async (clientAppId: string, appInfo: Partial<IDeveloperApp>) => {
    const { data } = await instance.patch(`apps/oauth2/clients/${clientAppId}`, appInfo);
    return data;
  },

  deleteDeveloperApp: async (clientAppId: string) => {
    const { data } = await instance.delete(`apps/oauth2/clients/${clientAppId}`);
    return data;
  },

  delete: async (profileId: string) => {
    const { data } = await instance.delete(`organization_profiles/profile/${profileId}`);
    return data;
  },

  checkAccountDeactivation: async () => {
    const { data } = await instance.get('account/deactivation_check');
    return data;
  },

  deleteContractorAccount: async ({
    totp,
    reasons,
    suggestion,
    profilePublicId,
  }: {
    totp?: string;
    reasons: string[];
    suggestion: string;
    profilePublicId: number | string;
  }) => {
    const { data } = await instance.post(`account/delete/${profilePublicId}`, {
      totp,
      reasons,
      suggestion,
    });
    return data;
  },
  checkIfCanChangeEmail: async () => {
    const { data } = await instance.get('user/email_change/can_change');
    return data;
  },
  checkUserEmail: async (newEmail: string) => {
    const { data } = await instance.post(
      'user/email_change/verify_new_email',
      { newEmail },
      { suppressSnackbar: true }
    );
    return data;
  },
  checkNewEmailToken: async (newToken: string) => {
    const { data } = await instance.post(
      `user/email_change/confirm_new_email`,
      { token: newToken },
      { suppressSnackbar: true }
    );
    return data;
  },
  cancelEmailChange: async () => {
    const { data } = await instance.get('user/email_change/cancel');
    return data;
  },
  confirmEmailChange: async () => {
    const { data } = await instance.post('user/email_change/apply_change');
    return data;
  },
  mobileSession: {
    registerKey: async (publicKey: string) => {
      return await instance.post<{ challengeToken: string }>(
        'login/key/register',
        { publicKey },
        { withCredentials: true }
      );
    },
    getChallenge: async (publicKey: string) => {
      return await instance
        .post<{ challengeToken: string }>('login/key/challenge', { publicKey }, { withCredentials: true })
        .then(({ data }) => {
          return data.challengeToken;
        })
        .catch((e) => {
          throw e;
        });
    },
    renewToken: async ({ challenge, signature }: { challenge: string; signature: string }) => {
      return await instance
        .post('login/key/token', { challenge, signature }, { withCredentials: true })
        .then(({ data }) => {
          return data.token;
        })
        .catch((e) => {
          console.error(e);
          throw e;
        });
    },
  },
};
