import { Button, H1, Icon, P, safelySetInnerHTML, Stack } from '@letsdeel/ui';
import { highlightText } from '@/components/BrazeLoginBackground/utils';
import { Hourglass01Hero } from '@/components/BrazeLoginBackground/components';

export const Perks = () => {
  return (
    <Stack
      direction="row"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#C4B1F9',
        zIndex: -1,
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ width: '50%', '@media(max-width: 1200px) and (min-width: 960px)': { paddingRight: '40px' } }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          gap={2}
          sx={{
            img: {
              maxWidth: '600px',
              '@media(max-width: 1200px), (max-height: 770px)': {
                maxWidth: '420px',
              },
              '@media(max-height: 680px) and (max-width: 1150px),': {
                display: 'none',
              },
            },
            '@media(max-width: 959px) and (min-height: 1000px)': {
              position: 'absolute',
              top: '50%',
              left: '50%',
              zIndex: 1,
              transform: 'translate(-50%, -430px)',
              button: { display: 'none' },
              img: { display: 'none' },
            },
            '@media(max-width: 500px), (max-width: 959px) and (max-height: 999px)': {
              display: 'none',
            },
          }}
        >
          <Stack sx={{ width: '100%', height: '360px' }} alignItems="center" justifyContent="center">
            <img src="/login/perks/Perks.png" width="100%" height="100%" style={{ objectFit: 'contain' }} alt="Perks" />
          </Stack>
          <H1
            sx={{
              maxWidth: '600px',
              textAlign: 'center',
              fontSize: '56px',
              lineHeight: '105%',
              letterSpacing: '-0.22px',
              span: {
                display: 'inline-block',
                position: 'relative',
                marginTop: '4px',
                lineHeight: '110%',
                fontFamily: 'inherit',
                '&:after': {
                  position: 'absolute',
                  content: "''",
                  top: 0,
                  left: '-6px',
                  width: 'calc(100% + 12px)',
                  height: '100%',
                  backgroundColor: 'text.contrastText',
                  zIndex: '-1',
                  transform: 'rotate(0.833deg) skewX(4deg)',
                },
              },
              '@media(min-width: 1366px)': { fontSize: '56px', lineHeight: '105%' },
              '@media(max-width: 1365px)': { fontSize: '44px', lineHeight: '52px', maxWidth: '480px' },
              '@media(min-width: 960px) and (max-width: 1100px)': { maxWidth: '370px' },
              '@media(max-width: 600px)': { fontSize: '44px !important', lineHeight: '52px !important' },
            }}
            {...safelySetInnerHTML(highlightText('Sweet perks and even *sweeter discounts*'))}
          />
          <P
            sx={{
              maxWidth: '550px',
              textAlign: 'center',
              fontSize: '18px',
              '@media(max-width: 1200px), (max-width: 1365px) and (max-height: 675px), ': {
                fontSize: '17px',
              },
              '@media(min-width: 960px) and (max-width: 1200px)': { maxWidth: '370px' },
              '@media(max-width: 959px)': {
                minWidth: '490px',
              },
            }}
          >
            Made for remote teams everywhere.
          </P>
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            sx={{ fontSize: '14px' }}
            onClick={() => window.open('https://www.deel.com/perks', '_blank')}
            endIcon={<Icon.NewTab size={20} />}
          >
            Learn more
          </Button>
        </Stack>
      </Stack>
      <Hourglass01Hero backgroundColor="purple" />
    </Stack>
  );
};
