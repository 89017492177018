import { useSafeAreaClasses } from '@/hooks/useSafeAreaClasses';
import type { Edges } from '@/types/Edges';
import { type PropsWithChildren } from 'react';
import { isNative } from '@/utils/crossPlatform';

type Props = {
  edges?: Edges[];
};
export const SafeAreaContainer = ({ children, edges }: PropsWithChildren<Props>) => {
  const safeAreaClasses = useSafeAreaClasses(edges);

  if (!isNative()) {
    return <>{children}</>;
  }

  return <div className={`${safeAreaClasses}`}>{children}</div>;
};
