import instance from '@/utils/api/instance';
import GlobalPayrollContract from '@/utils/contract/globalPayrollContract';
import type { NewDirectEmployeeContractPayload } from '@/types/GlobalPayroll/CreateGlobalPayrollContract';

interface SignDirectEmployeeAgreementPayload {
  signature: string;
}

export default {
  editEmployeeInfo: async ({ oid, payload }: { oid: any; payload: object }): Promise<any> => {
    const { data } = await instance.patch(`global_payroll/contracts/${oid}/employee_info`, payload);
    return new GlobalPayrollContract(data);
  },

  updateEmployeeCostCenter: async (contractOid: string, costCenterId: string): Promise<any> => {
    try {
      const response = await instance.patch(`global_payroll/contracts/${contractOid}/cost_center`, { costCenterId });
      return response.data;
    } catch {
      return null;
    }
  },

  createContract: async (
    payload: NewDirectEmployeeContractPayload
  ): Promise<{
    contract: {
      oid: string;
    };
    hrisInfo: {
      oid: string;
      workerId: number;
    };
  }> => {
    const { data } = await instance.post(`global_payroll/contracts/create`, payload);
    return data;
  },

  signDirectEmployeeAgreement: async (oid: string, payload: SignDirectEmployeeAgreementPayload) => {
    const { data } = await instance.post(`global_payroll/contracts/${oid}/employment_agreement/sign`, payload);
    return data;
  },

  cancelContract: async (oid: string) => {
    const { data } = await instance.patch(`global_payroll/contracts/${oid}/cancel`);
    return data;
  },
};
