import instance from '@/utils/api/instance';

export default {
  create: async (contractId: string, payload: any) => {
    const { data } = await instance.post(`contracts/${contractId}/milestones`, payload);
    return data;
  },

  update: async (contractId: string, id: string, payload: any) => {
    const { data } = await instance.patch(`contracts/${contractId}/milestones/${id}`, payload);
    return data;
  },

  delete: async (contractId: string, id: string, reason?: string) => {
    await instance.delete(`contracts/${contractId}/milestones/${id}`, { data: { reason } });
  },

  edit: async (id: string, payload: any) => {
    const { data } = await instance.post(`contracts/${id}/milestones/`, payload);
    return data;
  },

  addMilestone: async (id: string, milestone: any) => {
    const { data } = await instance.post(`contracts/${id}/milestones/`, { create: [milestone] });
    return data;
  },

  approve: async ({ contractId, ruleIndex }: any) => {
    const { data } = await instance.post(`contracts/${contractId}/milestones/${ruleIndex}/approve`);
    return data;
  },

  requestApproval: async ({ contractId, id }: any) => {
    const { data } = await instance.post(`contracts/${contractId}/milestones/${id}/request_approve`);
    return data;
  },
};
