import Anchor from '@/components/Anchor/Anchor';
import ErrorBoundary, { ErrorBoundaryOwners } from '@/components/ErrorBoundary/ErrorBoundary';
import { FeatureFlags } from '@/constants/featureFlags';
import LOGOS from '@/constants/logos';
import { useCheckIfShouldShowTopNav } from '@/hooks/home/useCheckIfShouldShowTopNav';
import useLookupsStore from '@/hooks/useLookupsStore';
import useSeasons from '@/hooks/useSeasons';
import { cn } from '@/utils/main';
import type { BoxProps } from '@letsdeel/ui';
import { MuiBox, Icon } from '@letsdeel/ui';
import type { FC, PropsWithChildren } from 'react';
import { Suspense, cloneElement } from 'react';
import './logo.less';
import { useCustomLogos } from '@/context/WhiteLabel/useCustomLogos';

export type LogoSize =
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '16'
  | '24'
  | '26'
  | '32'
  | '40'
  | '48'
  | '56'
  | '64'
  | '92'
  | '96'
  | '112';

interface Props {
  to?: string;
  type?: string;
  size?: LogoSize;
  className?: string;
  info?: string;
  wrapperClassName?: string;
  [p: string]: any;
  color?: string;
  sx?: BoxProps['sx'];
  forceCustomDomainLogo?: boolean;
  forceMobile?: boolean;
}

const Logo: FC<PropsWithChildren<Props>> = ({
  to,
  type = 'deel',
  size = 'md',
  className,
  info,
  color,
  wrapperClassName,
  forceCustomDomainLogo,
  sx,
  forceMobile,
  ...rest
}) => {
  const { customLogo, showCustomLogo: isCustomLogoEnabled } = useCustomLogos(forceMobile);

  const config = (window as any).deelConfig;
  const subdomainLogo = (type === 'deel' || forceCustomDomainLogo) && config?.data?.logo;
  const { currentSeason } = useSeasons();
  const logoName = type === 'dlocal' ? 'deel' : type;
  const lookups = useLookupsStore();
  const isRebrandEnabledOnPublicRoutes = lookups?.getFeatureFlag(FeatureFlags.RebrandEnabledOnPublicRoutes);
  const shouldShowTopNav = useCheckIfShouldShowTopNav();

  const showCustomLogo = isCustomLogoEnabled && type.includes('deel');

  const getLogoType = () => {
    if (isRebrandEnabledOnPublicRoutes) {
      switch (logoName) {
        case 'deel':
          return 'deel-redesign-black';

        case 'deel-blue':
          return 'deel-redesign-black';

        case 'deel-redesign-icon-black':
          return 'deel-redesign-icon-black';

        case 'deel-small':
          return 'deel-redesign-icon-black';

        case 'deel-white':
          return 'deel-redesign-white';

        case 'deel-redesign-icon-white':
          return 'deel-redesign-icon-white';
      }
    }

    if (currentSeason) {
      switch (logoName) {
        case 'deel':
          return `deel-${currentSeason}`;
        case 'deel-white':
          return `deel-${currentSeason}-white`;
        case 'deel-blue':
          return `deel-${currentSeason}-blue`;
      }
    }

    return logoName;
  };

  const logoComponent = (LOGOS as any)[getLogoType()];
  const displayComponent = color ? cloneElement(logoComponent, { color }) : logoComponent;

  if (!subdomainLogo && !showCustomLogo && forceMobile) {
    return <Icon.Deel size={28} />;
  }

  return (
    <Anchor to={to}>
      <MuiBox className={wrapperClassName} sx={sx}>
        <ErrorBoundary owner={ErrorBoundaryOwners.unknown} fallback={null}>
          <Suspense fallback={null}>
            {subdomainLogo && !shouldShowTopNav ? (
              <MuiBox sx={{ img: { height: '24px' } }}>
                <img src={subdomainLogo} className="subdomain-logo" alt="logo" {...rest} />
              </MuiBox>
            ) : null}
            {showCustomLogo ? (
              <MuiBox sx={{ img: { height: '30px', objectFit: 'contain' } }}>
                <img src={customLogo} className="custom-logo" alt="logo" {...rest} />
              </MuiBox>
            ) : null}

            {!subdomainLogo && !showCustomLogo ? (
              // @ts-ignore
              <div className={cn('logo', className)} sizetype={size} data-qa="logo" {...rest}>
                {displayComponent}
              </div>
            ) : null}
            {info ? <h6 className="mt-4 pre-wrap">{info}</h6> : null}
          </Suspense>
        </ErrorBoundary>
      </MuiBox>
    </Anchor>
  );
};

export default Logo;
