// If you want to add a new route, please make sure to add a corresponding title in the ROUTES_TITLES variable.
// The structure of ROUTES and ROUTES_TITLES should match.
import { t } from 'i18next';

export const ROUTES = {
  login: {
    main: '/login',
    okta: '/login/okta',
    zendesk: '/zendesk/login',
  },
  switchUser: '/u/:email',
  xeroSignup: '/xerosignup',
  resetPasswordToken: '/reset-password/:token?',
  resetPasswordError: '/reset-password/error/:status?',
  loginWithCode: '/login-with-code',
  nativeSSO: '/native-sso',
  nativeOnboarding: '/native-onboarding',
  verifyBackupEmail: '/verify-backup-email',
  growthTools: {
    main: '/growth-tools/:tool',
    tools: {
      visa: '/growth-tools/immigration',
    },
  },
  backgroundChecks: {
    result: '/background-checks/result/:contractOid',
    standaloneResult: '/background-checks/result/standalone/:publicId',
  },
  getStartedPage: {
    main: '/get-started',
  },
  community: {
    main: '/community',
  },
  hiring: {
    main: '/hiring',
    candidates: {
      list: '/hiring/candidates',
      view: '/hiring/candidates/:candidateId/:applications(applications)?/:applicationId?/:tab?',
    },
    jobs: {
      list: '/hiring/jobs',
      view: '/hiring/jobs/:id/:tab?',
    },
    pipeline: '/hiring/pipeline',
    settings: {
      main: '/hiring/settings',
      interviewPlans: '/hiring/settings/interview-plans',
      departmentsAndTeams: '/hiring/settings/departments-and-teams',
      applicationForms: '/hiring/settings/application-forms',
      feedbackForms: '/hiring/settings/feedback-forms',
      locations: '/hiring/settings/locations',
      careerPage: '/hiring/settings/career-page',
    },
  },
  compliance: {
    main: '/compliance',
    monitor: '/compliance/monitor',
    contractorClassification: '/compliance/contractor-classification',
    workerMisclassificationAssesment: '/compliance/contractor-classification/:contractorId/assesment',
    workerHiringAssesment: '/compliance/contractor-classification/worker-hiring-assessment',
    massWorkerAssesment: '/compliance/contractor-classification/mass-worker-assessment',
  },
  documents: {
    main: '/documents',
    requestedByDeel: '/documents/requested-by-deel',
    agreementTemplates: '/documents/agreement-templates',
    registrationAndFiles: '/documents/registration-and-files',
    registrationAndFilesFolder: '/documents/registration-and-files/:folderId',
    custom: '/documents/custom',
    customDetails: '/documents/custom/:id',
    complianceCalendar: '/documents/calendar',
    workerVerification: '/documents/worker-verification-letter/:contractId/preview',
    general: '/documents/general',
    i9: '/documents/i-9',
    i9Details: '/i-9/contract/:id',
    customDocDetail: '/documents/tracker/custom-documents/:documentTemplateId',
    customDocOverview: '/documents/tracker/custom-documents/details/:documentTemplateId/overview/:hrisProfileOid',
    contractRequirements: '/documents/tracker/contract-requirements',
    contractRequirementDetails:
      '/documents/tracker/contract-requirements/details/:contractRequirementId/:hrisProfileOid',
    identityVerification: '/documents/tracker/identity-verification',
    i9DocDetail: '/documents/tracker/i-9',
    i9DocDetailPreSelected: '/documents/tracker/i-9/:contractId',
    employeeHandbook: '/documents/tracker/employee-handbook',
    eorEATemplates: {
      main: '/documents/eor-employee-agreements',
      templates: '/documents/eor-employee-agreements/templates',
      templatePreview: '/documents/eor-employee-agreements/templates/:templateId',
      changeRequestTracker: '/documents/eor-employee-agreements/change-requests',
      changeRequestForm: '/documents/eor-employee-agreements/change-requests/create',
    },
  },
  eorClientAllowancesReview: '/client-allowance-review/:taskPublicId',
  employeeComplianceDocs: '/employee-compliance-documents',
  employeeComplianceForm: '/employee-compliance-documents/form/:complianceDocId',
  confirmation: '/confirmation',
  checkinSubmission: '/checkin-submission/:contractId',
  people: {
    main: '/people',
    organization: '/people/organization/:orgId',
    individual: '/people/:id',
    contract: '/people/:id/contracts/:contractId/:reviewAmendment(review-amendments/.+)?',
    jobInformationUpdateWizard: '/people/:id/contracts/:contractId/update-job-information',
    contractEorAmendment: '/people/:id/contracts/:contractId/eor-amendment',
    requestChanges: '/people/:id/contracts/:contractId/request-changes',
    reviewContract: '/people/:id/contracts/:contractId/review',
    reviewSignContract: '/people/:id/contract-review/:contractId',
    contracts: '/people/:id/contracts',
    personalInformation: '/people/:id/personal-information',
    supplementaryInformation: '/people/:id/supplementary-Information',
    terminations: '/people/:id/terminations',
    contractTermination: '/people/:id/contracts/:contractId/terminate',
    contractExtension: '/people/:id/contracts/:contractId/extend',
    projectReview: '/people/:id/contracts/:contractId/project-review',
    timeOffDetails: '/people/:id/time-off/:contractId',
    timeOffEmployeeHistory: '/people/:id/time-off/:contractId/history',
    timeOff: '/people/:id/time-off',
    timeOffHistory: '/people/:id/time-off/history',
    timeOffHistoryList: '/people/:id/time-off/:contractId/history-list',
    timeOffEditBalance: '/people/:id/time-off/:contractId/adjust-balance/:entitlementId',
    documents: '/people/:id/documents',
    contractDocuments: '/people/:id/documents/:contractId',
    payments: '/people/:id/payments',
    paymentDetails: '/people/:id/payments/:contractId',
    apps: '/people/:id/apps',
    logChanges: '/people/:id/change-log',
    equipment: '/people/:id/equipment',
    equipmentDetails: '/people/:id/equipment/:contractId',
    coworking: '/people/:id/coworking',
    coworkingDetails: '/people/:id/coworking/:contractId',
    travelInsurance: '/people/:id/travel-insurance',
    offboard: '/people/:id/offboard',
    payslipsDetails: '/people/:id/payslips/:contractId',
    taxDocumentsDetails: '/people/:id/tax-documents/:contractId',
    taxDocuments: '/people/:id/tax-documents',
    payslips: '/people/:id/payslips',
    identityVerification: '/people/:id/identity-verification',
    career: {
      roleCardCareerPath: '/people/career/role-cards/:roleCardId/career-path',
      roleCardAssignees: '/people/career/role-cards/:roleCardId/assignees',
      levelingStructure: '/people/career/leveling-structure',
      competencies: '/people/career/competencies',
      assignments: '/people/career/assignments',
      frameworks: '/people/career/frameworks',
    },
    equity: {
      main: '/people/:id/equity',
      addOffer: '/people/:id/equity/add-offer',
      editOffer: '/people/:id/equity/edit-offer/:equityId',
      editGrant: '/people/:id/equity/edit-grant/:equityId',
      editInfo: '/people/:id/equity/edit-info/:equityId',
      downloadAgreements: '/equities/download_agreements',
      addOfferWorkerSelector: '/employee/equity/add-offer',
    },
    benefitsDetails: '/people/:id/benefits/:contractId',
    benefits: '/people/:id/benefits',
    odpOverviewsDetails: '/people/:id/odp-overviews/:contractId',
    odpOverviews: '/people/:id/odp-overviews',
    timeAttendanceContracts: '/people/:id/time-attendance',
    timeAttendance: '/people/:id/time-attendance/:contractId',
    bulkEdit: {
      peopleSelect: '/bulk-edit-select',
      downloadFlow: '/bulk-edit-download',
      uploadFlow: '/bulk-edit-upload',
    },
    bulkTerminate: {
      downloadFlow: '/bulk-terminate-download',
      uploadFlow: '/bulk-terminate-upload',
    },
    massUpload: {
      downloadFlow: '/mass-download/:massUploadFlow/:contractType',
      uploadFlow: '/mass-upload/:massUploadFlow/:contractType',
    },
    performance: {
      main: '/performance',
      reviewCycles: '/performance/review-cycles',
      reviewCycle: {
        setup: '/performance/review-cycle/setup/:cycleId',
        review: '/performance/review-cycle/review/:cycleId',
        manage: '/performance/review-cycle/manage/:cycleId',
        results: '/performance/review-cycle/results/:cycleId',
        team: '/performance/review-cycle/team/:cycleId',
        teamResults: '/performance/review-cycle/:cycleId/team-member/:id/results',
      },
    },
    reviewCycles: {
      main: '/people/:id/review-cycles',
      reviewCycle: '/people/:id/review-cycles/:reviewCycleId',
    },
    goals: '/people/:id/goals',
    immigration: {
      profile: '/people/:id/immigration',
      wallet: '/people/:id/immigration/wallet',
    },
    edit: '/people/:id/edit',
    transitions: {
      newContract: '/people/:profileId/transitions/:transitionId/new-contract',
      termination: '/people/:profileId/transitions/:transitionId/termination',
    },
  },
  webLoginRequest: '/web-login-request/:requestId',
  terminationReview: '/termination-review',
  serviceHub: {
    main: '/services',
    groupedFeaturesListPages: {
      plugins: '/services/plugins',
      consulting: '/services/consulting',
      services: '/services/list',
      hiringTools: '/services/hiring-tools',
      perks: '/services/perks',
    },
    plugins: {
      announcements: '/services/plugins-announcements',
      connections: '/services/plugins-connections',
      kudos: '/services/plugins-kudos',
      onboarding: '/services/plugins-onboarding',
      oneOnOne: '/services/plugins-one-on-ones',
      orgCharts: '/services/plugins-org-charts',
      pto: '/services/plugins-pto',
      pulseSurveys: '/services/plugins-pulse-surveys',
      referrals: '/services/plugins-referrals',
    },
    consulting: {
      eorEquityService: '/services/eor-equity-service',
      hrConsulting: '/services/hr-consulting',
      privacyConsulting: '/services/privacy-consulting',
      globalExpansion: '/services/global-expansion',
    },
    services: {
      dpa: '/services/dpa',
      backgroundCheck: '/services/background-checks',
      consolidatedBackgroundChecks: '/services/background-checks/all',
      individualBackgroundCheck: '/services/background-checks/:id',
      shield: '/services/shield',
      coworking: '/services/coworking',
      equipment: '/services/equipment',
      leasedEquipment: '/services/leased-equipment',
      equipmentDeviceInventory: {
        archive: '/services/equipment-inventory/archive',
        massImport: '/services/equipment-inventory/mass-import',
      },
      api: {
        main: '/services/api',
        apps: '/services/api/apps',
        webhooks: '/services/api/webhooks',
        deelApi: '/services/api/deel-api',
      },
      taxAdvice: {
        main: '/services/tax-advice',
        codes: '/services/tax-advice/codes/:id',
      },
      entityRegister: {
        main: '/services/entity-register',
      },
      equity: {
        main: '/services/equity',
      },
      nda: '/services/nda',
      globalPayroll: '/services/global-payroll',
      benefits: '/services/benefits',
      immigration: {
        main: '/services/immigration',
        request: '/services/immigration/request',
        visaEligibleCheck: '/services/immigration/visa-eligible-check',
        visas: '/services/immigration/visas',
        cases: '/services/immigration/cases',
        caseDetails: '/services/immigration/cases/:caseId',
        wallet: '/services/immigration/wallet',
        tracker: '/services/immigration/visas/tracker',
        trackerAssessments: '/services/immigration/visas/tracker-assessments',
        trackerApplications: '/services/immigration/visas/tracker-applications',
        profile: '/services/immigration/profile/:companyId/:id',
      },
    },
    engage_plugins: {
      setup: {
        finish: '/engage/signinwith/roots/finalize', // to deprecate route
        finishV2: '/engage-plugins/signinwith/roots/finalize',
      },
    },
  },

  immigration: {
    cases: '/immigration',
    wallet: '/immigration/wallet',
    caseDetails: '/immigration/cases/:caseId',
  },

  organizationChart: {
    main: '/organization-chart',
    organization: '/organization-chart/organization/:orgId',
    organization_employee: '/organization-chart/organization/:orgId/profile/:employeeId',
    individual: '/organization-chart/:employeeId',
    assignment: '/organization-chart/orphan-assignment',
  },

  tracker: {
    main: '/tracker',
    peopleOnboarding: '/tracker/people-onboarding',
    changeRequests: '/tracker/change-requests',
    endingContracts: '/tracker/termination',
    contractDrafts: '/tracker/contract-drafts',
    contractEdits: '/tracker/contracts-edits',
    processes: '/tracker/processes',
  },
  task: {
    main: '/tasks',
    create: '/tasks/create',
    update: '/tasks/update/:id',
    individual: '/tasks/:id/view',
  },
  timeAttendance: {
    main: '/time-attendance/:contractId',
    reviewHours: '/time-attendance/review-hours',
    contracts: '/time-attendance/contracts',
  },

  massOnboarding: '/mass-onboarding',
  deelHRToGPEmployee: '/deel-hr-to-gp-employee',
  gpMassUpdate: '/gp-mass-update',
  mobilityTermsConditions: '/mobility/terms-and-conditions',
  contracts: '/contracts',
  contract: '/contract',
  contractEdit: '/contract/:id/edit/:oid/:peopleId?',
  contractTermination: '/contract/:id/terminate/:peopleId?',
  contractExtension: '/contract/:contractId/extend',
  contractPreview: '/contract-preview/:id',
  contractUpdateAdjustment: '/contract/:contractId/update-adjustment/:adjustmentId',
  /** created to support redirection from deprecated Contract page*/
  contractJobInformationUpdateWizard: '/contract/:contractId/update-job-information',
  contractTemplateEditor: '/contract-editor/:templateId?',
  customDocsUpload: '/custom-documents-upload',
  reports: {
    main: '/analytics',
    salaryInsights: '/analytics/salary-insights',
    dashboard: '/analytics/dashboards/:dashboardId',
    reportsList: '/analytics/reports/list',
    reportCreateCustom: '/analytics/reports/custom/:sourceReportId?',
    reportEditCustom: '/analytics/reports/edit/:reportId',
    reportCustomization: '/analytics/reports/:id/customize',
    report: '/analytics/reports/view/:id',
    globalG2NReport: '/analytics/reports/view/global_gross_to_net',
    g2nVariances: '/analytics/reports/g2n_variances/:entityId',
    g2nVariancesDetails: '/analytics/reports/g2n_variances/:entityId/:contractOid',
    globalG2NReportEmployeeView: '/analytics/reports/view/global_gross_to_net_employee_view',
    g2nEmployeeView: '/analytics/reports/view/direct_employee',
    g2nEorEmployeeView: '/analytics/reports/view/eor_employee',
    g2nEorVarianceReport: '/analytics/reports/view/variance_gross_to_net',
    g2nReport: '/analytics/reports/view/gross_to_net',
    g2nReportEmployeeContributions: '/analytics/reports/view/employee_contributions',
    g2nReportEmployeeDeductions: '/analytics/reports/view/employee_deductions',
    g2nReportByCostCenter: '/analytics/reports/view/g2n_by_cost_center',
    g2nReportByCostCenterSummary: '/analytics/reports/view/g2n_by_cost_center_summary',
    timeOffSummaryReport: '/analytics/reports/view/time_off_summary_report',
    timeOffsRequestsReport: '/analytics/reports/view/time_off_requests_report',
    recurringAllowancesAndDeductions: '/analytics/reports/view/recurring_allowances_and_deductions',
    recurringPayrollItems: '/analytics/reports/view/recurring_payroll_items',
  },
  create: {
    account: '/create-account',
    accountCompany: '/create-account/company',
    accountIndividual: '/create-account/individual',
    accountEmployee: '/create-account/employee',
    main: '/create',
    fixed: '/create/fixed',
    payAsYouGo: '/create/pay-as-you-go',
    milestone: '/create/milestone',
    eor: '/create/full-time-employee',
    peo: '/create/peo-employee',
    costCalculator: '/create/employee-cost-calculator',
    directEmployee: '/create/direct-employee',
    directHREmployee: '/create/hr-direct-employee',
    payrollSelector: '/create/choose-payroll',
    contractorOutsideDeel: '/create/contractor-outside-deel',
  },
  createOrganization: '/create-organization',
  contractorType: '/contractor-type',
  createHrisEmployee: '/create-hris-employee',
  createHrisEmployeeLightweight: '/create-hris-employee-lightweight',
  home: '/',
  cdd2: '/client-due-diligence',
  cdd2LegalEntity: '/client-due-diligence/:id',
  contractorPartialRecords: {
    invoices: '/contractor-partial-records-invoices',
    resolveInvoice: '/contractor-partial-records-invoices/resolve/:id',
    resolveInvoices: '/contractor-partial-records-invoices/resolve',
    expenses: '/contractor-partial-records-expenses',
    resolveExpense: '/contractor-partial-records-expenses/resolve/:id',
    resolveExpenses: '/contractor-partial-records-expenses/resolve',
  },
  invoices: '/invoices',
  invoicesImport: '/invoices/import',
  expensesAdd: '/add-expense',
  expensesReview: '/review-expense',
  expensesClaims: '/expenses-claims/:tab?',
  expenses: '/expenses',
  expensesImport: '/expenses/import',
  expense: '/expense/:id',
  platformExpense: '/platform-expense/:id/',
  editExpense: '/expense/:id/edit',
  addExternalExpense: '/expense/add',
  externalInvoices: '/invoices/external',
  incomeExternal: {
    main: '/income/external',
    upload: '/income/external/upload',
    details: '/income/external/details',
    charges: '/income/external/charges',
    payments: '/income/external/payments',
    preview: '/income/external/preview',
  },
  receipts: '/receipts',
  deelInvoice: '/invoice/deel/:id',
  externalInvoice: '/invoice/external/:id',
  uploadedInvoice: '/invoice/external/uploaded/:id',
  externalInvoicePreview: '/invoice/external/:id/preview',
  uploadedInvoicePreview: '/invoice/external/uploaded/:id/preview',
  invoice: '/invoice/:id',
  receipt: '/receipt/:id',
  paymentSummary: '/payment-summary/:id',
  paymentSummaryPublicLanding: '/guest/payment-summary/:id',
  settings: {
    accountDetails: '/settings/account-settings',
    analytics: '/settings/analytics',
    contractsSettings: '/settings/contracts-settings',
    contractsSettingsTab: '/settings/contracts-settings/:tab',
    organization: '/settings/organization-settings',
    organizationDetailsDocs: '/settings/organization-details-docs',
    rolesPermissionsAuditLogs: '/settings/roles-permissions-audit-logs',
    entityLogs: '/settings/entity-logs',
    compareRoles: '/settings/compare-roles',
    emailLogs: '/settings/email-logs',
    emailLog: '/settings/email-logs/:id',
    emailLogPreview: '/settings/email-logs/preview',
    emailLogPreviewDetails: '/settings/email-logs/preview/:id',
    managers: '/settings/managers',
    workers: '/settings/workers',
    singleUser: '/settings/user/:id/details',
    singleWorker: '/settings/worker/:id',
    inviteManager: '/settings/managers/invite-manager',
    assignRole: '/settings/managers/:managerId/assign-role',
    contractorEntity: '/settings/contractor-entity',
    customAdjustments: '/settings/custom-adjustments',
    workerRelations: '/settings/worker-relations',
    orgStructures: '/settings/org-structures',
    editOrgStructureById: '/settings/edit-org-structure/:id',
    editOrgStructureByType: '/settings/edit-org-structure/type/:type',
    addOrgStructure: '/settings/add-org-structure',
    eorEarlyInvoicingFunds: '/settings/eor-early-invoicing-funds',
    entities: '/settings/entity',
    entity: '/settings/entity/:id',
    entityAdd: '/settings/entity/add',
    entityEdit: '/settings/entity/:id/edit-entity',
    entityAddPeo: '/settings/entity/add/peo',
    entityDetails: '/settings/entity/:id/details',
    entityEmployeeDetails: '/settings/entity/:id/employee-details',
    entityMultiStateRegistration: '/settings/entity/:id/multi-state-registration',
    entityAddStateRegistrations: '/settings/entity/:id/state-registrations',
    entityGlobalPayroll: '/settings/entity/:id/global-payroll',
    entityEmployees: '/settings/entity/:id/employees',
    entityPayrollSchedule: '/settings/entity/:id/payroll-schedule',
    entityAddPayrollGroups: '/settings/entity/:id/add-payroll-groups',
    entityPayrollDataImport: '/settings/entity/:id/import-payroll-data',
    entityConfirmTaxesDue: '/settings/entity/:id/confirm-taxes-due',
    entitySignDocuments: '/settings/entity/:id/sign-documents',
    entityGlobalPayrollCalendar: '/settings/entity/:id/global-payroll/:payrollSettingsId/calendar',
    entityGlobalPayrollGeneralLedgerWizard: '/settings/entity/:id/global-payroll/general-template-wizard',
    entityGlobalPayrollEditGeneralLedgerWizard:
      '/settings/entity/:id/global-payroll/general-template-wizard/:templateId/edit',
    entityGlobalPayrollProcessGuidelinesWizard:
      '/settings/entity/:id/global-payroll/payroll-guidelines-template-wizard',
    entityGlobalPayrollProcessGuidelinesEditWizard:
      '/settings/entity/:id/global-payroll/payroll-guidelines-template-wizard/:templateId/edit',
    teams: '/settings/teams',
    dynamicGroups: '/settings/dynamic-groups',
    accountEditEntityAddress: '/settings/organization-settings/edit-entity-address',
    accountEditEntity: '/settings/organization-settings/edit-entity',
    accountEditPersonal: '/settings/account-settings/edit-personal',
    accountEditPersonalAddress: '/settings/account-settings/edit-personal-address',
    accountContractorsEntityOnboarding: '/settings/account-settings/contractor-entity-onboarding',
    emailChangeLinkExpired: '/settings/account-settings/link-expired',
    accountEditShareholders: '/settings/organization-settings/edit-shareholders',
    invoiceDetails: '/settings/invoice-details',
    invoiceEditAddress: '/settings/invoice-details/edit-address',
    updateEmail: '/settings/account-settings/update-email/:token/:stage',
    main: '/settings',
    billing: '/settings/billing',
    deposits: '/settings/deposits',
    general: '/settings/general',
    globalPayrollExpenses: '/settings/global-payroll-expenses',
    integrations: '/settings/integrations',
    addIntegration: '/settings/add-integrations',
    security: '/settings/security',
    securityResetPassword: '/reset-password/:resetToken',
    securityResetPasswordError: '/reset-password/error/:errorStatus',
    permissions: '/settings/permissions',
    team: '/settings/team',
    addTeam: '/settings/teams/add-team',
    addTeamMember: '/settings/teams/:teamId/add-team-member',
    verification: '/settings/verification',
    verificationLetterSample: '/settings/organization-settings/verification-letter-sample',
    notificationSettings: '/settings/notification-settings',
    bankDetails: '/settings/bank-details',
    paymentMethods: '/settings/payment-methods',
    refundMethods: '/settings/refund-methods',
    bankGuarantee: '/settings/bank-guarantee',
    billingEmails: '/settings/payments/billing-emails',
    payments: '/settings/payments',
    project: {
      management: '/settings/project',
      creation: '/settings/project/create',
      editDetails: '/settings/project/:projectId',
    },
    document: {
      management: '/settings/document-management',
      creation: '/settings/document-creation',
      editTemplate: '/settings/document-edit-template/:documentId',
      customTemplateEditor: '/settings/document-custom-template/:documentId/:peopleId',
      editDetails: '/settings/document-edit-details/:documentId',
      editDeliveryOptions: '/settings/document-edit-delivery-options/:documentId',
      editConfiguration: '/settings/document-edit-configuration/:documentId',
      editCustomAgreementTemplate: '/settings/document-edit-custom-agreement-template/:documentId',
      signDocument: '/settings/sign-document/:documentId',
      addDPA: '/settings/add-dpa',
    },
    auditLogs: '/settings/audit-logs',
    scheduledDataEdits: '/settings/schedule-data-edits',
    bulkEditLogs: '/settings/bulk-edit-logs',
    demographicInformation: '/settings/demographic-information',
    deelHr: {
      deelHrAccess: '/settings/deel-hr-access',
      main: '/settings/hr',
      // entityType is HrisEntityType
      hide: '/settings/hr/:organizationId/hide/:entityType',
      choice: '/settings/hr/:organizationId/hide/:entityType/choice',
      terminationReasons: '/settings/hr/termination-reasons',
      addTerminationReason: '/settings/hr/termination-reasons/add',
      editTerminationReason: '/settings/hr/termination-reasons/edit/:id',
      modificationReasons: '/settings/hr/modification-reasons',
      terminationImpact: '/settings/hr/termination-impacts',
      addTerminationImpact: '/settings/hr/termination-impacts/add',
      editTerminationImpact: '/settings/hr/termination-impacts/edit/:id',
    },
    timeOff: {
      policies: '/settings/time-off/policies',
      policyTemplates: '/settings/time-off/policy-templates',
      addPolicy: '/settings/time-off/add-policy',
      viewPolicy: '/settings/time-off/view-policy/:policyId',
    },
    customizeDomain: '/settings/organization-settings/custom-domain/:step',
    developerSettings: '/settings/developers-settings',
    addCustomField: '/settings/add-custom-field',
    editCustomField: '/settings/custom-field/:id',
    customFieldsList: '/settings/custom-fields',
    payrollData: '/settings/payroll-data',
    editOrganizationField: '/settings/organization-field/:field',
    deelBalance: '/settings/refunds',
    deelBalanceRefund: '/settings/refunds/refund',
    slackNotifications: '/settings/slack-notifications',
    germanTaxesSubscription: '/settings/german-taxes-subscription',
    contractorPaymentMethods: '/settings/contractor-payment-methods',
    bookkeeping: '/settings/bookkeeping',
    subscriptionBilling: '/settings/subscriptions/subscription-billing',
    roles: '/settings/roles-permissions',
    organizationSecurity: '/settings/organization-settings/security',
    createCustomRole: '/settings/roles-permissions/create-custom-role',
    chooseRoleTemplate: '/settings/roles-permissions/choose-role-template',
    editCustomRole: '/settings/roles-permissions/edit-custom-role/:customRoleId',
    timeAttendance: {
      settings: '/settings/time-attendance-settings',
      addSchedule: '/settings/time-attendance-settings/add-schedule',
      viewAllSchedules: '/settings/time-attendance-settings/view-all-schedules/',
      editSchedule: '/settings/time-attendance-settings/edit/:id',
      ratePolicies: '/settings/time-attendance-settings/rate-policies',
      viewAllRatePolicies: '/settings/time-attendance-settings/view-all-rate-policies',
      ratePolicyForm: '/settings/time-attendance-settings/rate-policy',
      ratePolicyFormEdit: '/settings/time-attendance-settings/rate-policy/:id',
      overtimeSubmissionForm: '/settings/time-attendance-settings/overtime-submission',
      overtimeSubmissionFormEdit: '/settings/time-attendance-settings/overtime-submission/:id',
      massAssignPolicies: '/settings/time-attendance-settings/assign-policies',
      massAssignSchedule: '/settings/time-attendance-settings/assign-policies/:id',
      viewAllAutoAssignmentRules: '/settings/time-attendance-settings/view-all-auto-assignment-rules',
    },
    prepaidBilling: '/settings/payments/prepaid-billing',
    benefits: {
      main: '/settings/benefits',
      app: {
        main: '/benefits-app',
        employee: '/benefits-app/employee/:hrisProfileOid',
      },
    },
    deelBillingCredits: '/settings/payments/deel-billing-credits',
    benefitsOffer: '/settings/benefits/offer/:benefitType?',
    approvalPolicy: {
      main: '/settings/approval-policies',
      create: '/settings/approval-policies/create',
      update: '/settings/approval-policies/edit/:id',
      requests: '/settings/approval-policies/requests',
      delegations: '/settings/approval-policies/delegations',
    },
    dataChangePolicy: {
      main: '/settings/data-change-policies',
      create: '/settings/data-change-policies/create',
      update: '/settings/data-change-policies/:id',
    },
    personalDetails: {
      main: '/settings/personal-details',
      edit: '/settings/personal-details/edit',
    },
    whiteLabel: {
      main: '/settings/white-label',
      emails: '/settings/white-label/emails',
      features: '/settings/white-label/features',
      setup: '/settings/white-label/setup',
    },
    immigration: {
      main: '/settings/immigration',
    },
    customBranding: {
      main: '/settings/custom-branding',
      setup: '/settings/custom-branding/setup',
    },
    deelEngage: {
      main: '/settings/deel-engage',
      edit: '/settings/deel-engage/edit',
      crateAutomation: '/settings/deel-engage/create-automation',
      questionBank: {
        main: '/settings/deel-engage/question-bank',
        editor: '/settings/deel-engage/question-bank/:versionedQuestionId/editor',
      },
    },
    moveContractsBetweenPayrollGroups:
      '/settings/entity/:id/global-payroll/payroll-group/:payrollGroupId/move-contracts',
  },
  eor: {
    bankDetails: '/bank-details',
    addBankDetails: '/bank-details/add',
    editBankDetails: '/bank-details/edit/:methodChainId',
    viewBankDetails: '/bank-details/view/:methodChainId?',
    fundDistribution: '/bank-details/fund-distribution',
    paymentHistory: '/payment-history',
    requestContractsEntityMovement: '/eor-contracts-entity-movement/:destinationLegalEntityId/request',
    reviewContractsEntityMovement: '/eor-contracts-entity-movement/:contractsEntityMovementId',
  },
  odp: {
    bulkSubmit: '/submit-bulk-odp',
    singleRequest: '/request-odp',
  },
  peo: {
    bankDetails: '/peo-bank-details',
    addBankDetails: '/peo-bank-details/add',
    editBankDetails: '/peo-bank-details/edit/:methodChainId',
    viewBankDetails: '/peo-bank-details/view/:methodChainId?',
    fundDistribution: '/peo-bank-details/fund-distribution',
    completeAgreements: '/peo-complete-agreements',
    terminate: '/peo-terminate/:contractId',
    docsCollection: '/peo-documents-collection',
  },
  peoQuestionnaire: {
    ghq: '/peo-questionnaire/ghq',
    riskUnderwriting: '/peo-questionnaire/ru',
  },
  gpDirectEmployee: {
    bankDetails: '/direct-employee-bank-details',
    onboardingBankDetails: '/contract/:contractOid/direct-employee-bank-details/onboarding',
    addBankDetails: '/contract/:contractOid/direct-employee-bank-details/add',
    viewBankDetails: '/contract/:contractOid/direct-employee-bank-details/view/:methodChainId',
    editBankDetails: '/contract/:contractOid/direct-employee-bank-details/edit/:methodChainId',
    fundDistribution: '/contract/:contractOid/direct-employee-bank-details/fund-distribution',
  },
  signUp: {
    main: '/signup',
    employee: '/signup/employee',
    changeAccountType: '/signup/change-account-type',
    withToken: '/signup/:token?',
  },
  setup2FA: {
    main: '/setup/authenticator',
    initial: '/setup/authenticator/initial',
    add: '/setup/authenticator/add',
    mandatory: '/setup/authenticator/mandatory',
    fallback: '/setup/authenticator/fallback',
  },
  createProfile: '/create-profile',
  createProfileToken: '/create-profile/:token?',
  createSecondaryProfile: '/create-secondary-profile/:id',
  withdraw: '/withdraw',
  autoWithdrawal: '/auto-withdrawal',
  pay: '/pay',
  payment: '/payment',
  addPayment: '/add-payment',
  engagePluginsSubscription: '/engage-subscription', // to deprecate route
  engagePluginsSubscriptionV2: '/engage-plugins/subscription',
  payments: {
    invoices: '/payments/invoices',
    receipts: '/payments/receipts',
    upcoming: '/payments/upcoming',
  },
  billing: {
    receipts: '/billing/receipts',
    invoices: '/billing/invoices',
    refunds: '/billing/refunds',
    liabilities: '/billing/liabilities',
  },
  payOffcycle: '/pay-offcycle',
  referrals: {
    main: '/referrals',
    invites: '/referrals/invites',
    list: '/referrals/list',
  },
  taxes: {
    main: '/taxes',
    taxRegistration: '/taxes/tax-registration',
    taxRegisterPersonalDetails: '/taxes/tax-registration',
    taxRegisterBusinessInfo: '/taxes/tax-registration/business-info',
    taxRegisterTaxSetup: '/taxes/tax-registration/tax-setup',
    taxRegisterTaxEstimation: '/taxes/tax-registration/tax-estimation',
    taxRegisterBankAccount: '/taxes/tax-registration/bank-account',
    taxRegisterPreviewSubmit: '/taxes/tax-registration/preview-submit',
    file: '/taxes/file-1099/:year',
    createTaxForm: '/taxes/create',
    editTaxForm: '/taxes/edit/:id',
    taxForm: '/taxes/tax-form/:taxFormId',
  },
  taxAdvice: {
    main: '/tax-advice',
    services: '/tax-advice/services',
    guides: '/tax-advice/guides',
    about: '/tax-advice/about',
    taxQuestionAnalysis: '/tax-advice/tax-question-analysis',
    officeQuestion: '/tax-advice/office-question',
    taxServiceQuote: '/tax-advice/tax-service-quote',
    consultantCall: '/tax-advice/consultant-call',
  },
  accounting: {
    main: '/accounting',
    income: '/accounting/income',
    expenses: '/accounting/expenses',
    import: '/accounting/import',
    importReview: '/accounting/import/review',
  },
  transactionHistory: '/transaction-history',
  upcomingTransaction: '/transaction-history/payments',
  transactionDetails: '/transaction-history/:itemType(payments|withdrawals)/:id',
  deelAdvance: {
    dashboard: '/advance/dashboard',
    info: '/advance',
    form: '/advance/request',
    agreement: '/advance/:advanceId/agreement',
  },
  deelCard: {
    main: '/deel-card',
    cardInfo: '/deel-card/card-info',
    add: '/deel-card/add',
    addVirtual: '/deel-card/add/virtual',
    addPhysical: '/deel-card/add/physical',
    transactions: '/deel-card/transactions',
  },
  ewa: {
    main: '/earned-wage-access',
    workers: '/earned-wage-access/workers',
    workerDetails: '/earned-wage-access/worker/:id',
    requests: '/earned-wage-access/requests',
    tos: '/earned-wage-access/terms-of-service',
  },
  withdrawalMethods: '/withdrawal-methods',
  team: {
    main: '/team-settings',
    paymentMethods: '/team-settings/payment-methods',
    managers: '/team-settings/managers',
  },
  payslips: '/payslips',
  taxDocuments: '/tax-documents',
  employeeAdditionalDetails: '/employee-additional-details',
  employeePayrollCompliance: '/employee-payroll-and-compliance',
  directEmployeeAdditionalDetails: '/direct-employee-additional-details',
  hrisDirectEmployee: {
    finance: {
      bankDetails: '/hris/finance/bank-details',
      allPayslips: '/hris/finance/payslips',
      contractPayslips: '/hris/finance/payslips/:contractId',
    },
  },
  hrisDirectEmployeeBankDetails: '/hris-direct-employee-bank-details',
  hrisAdditionalDetails: '/hris-additional-details',
  hrisPayslipsUpload: '/hris-payslips-upload/:id/contract/:contractOid',
  multipleHrisPayslipsUpload: '/multiple-hris-payslips-upload',
  additionalProfileInformation: '/additional-profile-information',
  supplementaryInformation: '/supplementary-information',
  demographicInformation: '/demographic-information/:orgId',
  hrisTaxUpload: '/hris-tax-documents-upload/:profileId/:contractId',
  multipleHrisTaxDocumentsUpload: '/multiple-hris-tax-documents-upload',
  offerLetters: '/offer-letters/:id',
  equity: '/equity/:id',
  signEquityAgreements: '/sign-equity-agreements',
  employeeFAQ: '/employee-faq',
  employeeContractEditor: '/ea-contract-editor',
  incentivePlans: '/incentive-plans/:id',
  icpPortal: {
    home: '/',
    adjustments: '/adjustments',
    contractSearch: '/contract-search',
    payrollSoftwareSync: '/payroll-software-sync',
    documentSharing: '/document-sharing',
    employeesList: '/employee-list',
    employeeDetails: '/employee-details',
    employeeDocuments: '/employee-documents',
    timeOff: '/time-off',
    dataUpdates: '/data-updates',
    offCycleTerminations: '/off-cycle-terminations',
    payrollTaxForms: '/filings/payroll-tax-forms',
    addPayrollTaxForms: '/filings/payroll-tax-forms/add/:entityId/event/:eventId/:frequency',
    editPayrollTaxForms: '/filings/payroll-tax-forms/edit/:type/:entityId/event/:eventId/:frequency',
    reports: '/reports',
    configureTemplate: '/configure-template',
    cycles: '/cycles',
    cyclesEntityPage: '/cycles/:entity',
    cyclesEntityDetailsPage: '/cycles/:entity/details',
    cyclesEntityWorkbenchPage: '/cycles/:entity/workbench',
    cyclesEntityReportsPage: '/cycles/:entity/reports',
    cyclesEntityInvoiceItemsPage: '/cycles/:entity/invoices',
    cyclesEntityNotesPage: '/cycles/:entity/notes',
    editPreviewInvoice: '/employee-details/:contractOid/invoices/preview/:invoiceId',
    employeeInvoices: '/employee-details/:contractOid/invoices',
    payslips: '/payslips',
    qaFlags: '/qa-flags',
    g2nVariances: '/g2n-variances/:entityId',
    g2nVariancesDetails: '/g2n-variances/:entityId/:contractOid',
    verifyExpenses: '/verify-expenses/:expenseId?',
    reviewExpense: '/review-expenses/:expenseId?',
  },
  atsNewHires: '/ats-new-hires',
  integrations: {
    unsyncedInvoices: '/integration/:id/unsynced-invoices',
    failedInvoices: '/integration/:id/failed-invoices',
    settings: '/integration/:id/settings',
    new: '/integration/:id/new/:type',
    addIntegration: '/add-integration/:type',
    completeIntegration: '/complete-integration/:type/:id',
    integrationCreadentials: '/add-integration/:type/credentials',
    integrationAbout: '/integrations-store/:type',
    integrationAboutLever: '/integrations-store/lever',
    integrationAboutSlack: '/integrations-store/slack',
    integrationAboutQuickbooks: '/integrations-store/quickbooks',
    advancedSettings: '/integration/:id/settings/advanced',
    autoLink: '/integration/:id/settings/auto-link',
    teamAccounts: '/integration/:id/settings/team-accounts',
    accountsMapping: '/integration/:id/settings/accounts-mapping',
    peopleMapping: '/integration/:type/settings/people-mapping',
    generalLedgerSyncSettings: '/integrations/:type/general-ledger-sync-settings',
    bamboohr: '/integrations/bamboohr',
    workday: '/integrations/workday',
    xero: '/integrations/xero',
    ['xero_v2']: '/integrations/xero_v2',
    quickbooks: '/integrations/quickbooks',
    sage: '/integrations/sage',
    netsuiteJournalCreation: '/integrations/netsuite/:id/journal-creation/:connectionId/:step',
    netsuite: '/integrations/netsuite',
    slack: {
      base: '/integrations/slack',
      plugins: '/integrations/slack/plugins',
      peopleMatch: {
        unmatched: '/integration/slack/settings/people-mapping/unmatched',
      },
    },
    expensify: '/integrations/expensify',
    skovik: '/integrations/skovik',
    hibob: '/integrations/hibob',
    ashby: '/integrations/ashbyhq',
    greenhouse: '/integrations/greenhouse',
    lever: '/integrations/lever',
    ukg: '/integrations/ukg',
    ukg_ready: '/integrations/ukg_ready',
    hris_okta: '/integrations/hris_okta',
    disconnected: '/integrations/disconnected/:type',
    jumpcloud: '/integrations/jumpcloud',
    personio: '/integrations/personio',
    sap: '/integrations/sap',
    workable: '/integrations/workable',
    fieldMappingPluginMappingPage: '/integrations/field-mapping/:type/:id',

    // new integration service
    integrationOauthSetupFinish: '/integrations/oauth-setup-finish',
    integrationDetails: '/integrations/details/:schemaId',
    integrationSchemaId: '/integrations/:schemaId',
    connectToIntegration: '/integrations/:schemaId/new',
    integrationSettings: '/integrations/:schemaId/settings/:connectionId',
    integrationPluginsAndSettings: '/integrations/:schemaId/settings/:connectionId/plugins',
    integrationSyncHistory: '/integrations/:schemaId/settings/:connectionId/sync-history',
    integrationEventLogs: '/integrations/:schemaId/settings/:connectionId/event-logs',
    peopleMatch: '/integrations/:schemaId/setup/:connectionId/people-match',
    gpPeopleSync: '/integrations/:schemaId/setup/:connectionId/gp-people-sync',
    gpG2NSync: '/integrations/:schemaId/setup/:connectionId/gp-g2n-sync',
    sftpConnectors: {
      details: '/integrations/:schemaId/sftp/details/:connectionId?',
      newConnection: '/integrations/:schemaId/sftp/:connectionId?/new',
      editConnection: '/integrations/:schemaId/sftp/:connectionId/edit/:id',
    },
    multiStepSetupFlow: '/integrations/:schemaId/multi-step-setup/:connectionId/:workflowIndex',
    gpDownloadCsv: '/integrations/hr/api/v2/:type/:id/global-payroll/:gpType/export',
  },
  netsuite: {
    users: '/netsuite/:id/users',
    unsyncedInvoices: '/netsuite/:id/unsynced-invoices',
    failedInvoices: '/netsuite/:id/failed-invoices',
    settings: '/netsuite/:id/settings',
    bookkepingModules: '/netsuite/:id/bookkeeping-modules',
    journalCreation: '/netsuite/:id/journal-creation/:connectionId/:step',
    new: '/netsuite/:id/new/:type',
    advancedSettings: '/netsuite/:id/settings/advanced',
    autoLink: '/netsuite/:id/settings/auto-link',
    teamAccounts: '/netsuite/:id/settings/team-accounts',
    accountsMapping: '/netsuite/:id/settings/accounts-mapping',
    trackingCategoryMapping: '/netsuite/:id/tracking-category-mapping',
  },
  xero: {
    users: '/xero/:id/users',
    unsyncedInvoices: '/xero/:id/unsynced-invoices',
    failedInvoices: '/xero/:id/failed-invoices',
    settings: '/xero/:id/settings',
    new: '/xero/:id/new/:type',
    advancedSettings: '/xero/:id/settings/advanced',
    autoLink: '/xero/:id/settings/auto-link',
    teamAccounts: '/xero/:id/settings/team-accounts',
    accountsMapping: '/xero/:id/settings/accounts-mapping',
  },
  quickbooks: {
    users: '/quickbooks/:id/users',
    unsyncedInvoices: '/quickbooks/:id/unsynced-invoices',
    failedInvoices: '/quickbooks/:id/failed-invoices',
    settings: '/quickbooks/:id/settings',
    new: '/quickbooks/:id/new/:type',
    advancedSettings: '/quickbooks/:id/settings/advanced',
    autoLink: '/quickbooks/:id/settings/auto-link',
    teamAccounts: '/quickbooks/:id/settings/team-accounts',
    accountsMapping: '/quickbooks/:id/settings/accounts-mapping',
  },
  hr: {
    users: '/hr/:id/users',
    settings: '/hr/:id/settings',
    plugins: '/hr/:id/plugins',
    errors: '/hr/:id/errors',
    new: '/hr/:id/new/:type',
    enablePlugin: '/hr/enable-plugin/:type/:id/',
    typeMapping: '/hr/:id/time-off-mapping',
  },
  lever: {
    settings: '/lever/:id/settings',
  },
  expensify: {
    settings: '/expensify/:id/settings',
    connection: '/expensify/:id/connection',
  },
  workday: {
    plugins: '/workday/:id/plugins',
    new: '/workday/:id/new/:type',
    terms: '/workday/terms-and-conditions',
    enablePlugin: '/workday/enable-plugin/:type/:id/',
  },
  workable: {
    settings: '/workable/:id/settings',
  },
  provisioning: {
    scim: '/scim',
    scimSetup: '/scim/setup',
  },
  approveReports: {
    main: '/approve-reports',
    selectType: '/select-approve-type',
    hris: '/hris-approve-reports',
  },
  globalPayroll: {
    mainOld: '/payroll',
    main: '/global-payroll',
    createOffcyclePayrollEvent: '/global-payroll/create-offcycle-payroll-event',
    editOffcyclePayrollEvent: '/global-payroll/offcycle-event/:eventId/edit',
    payrollEventOld: '/payroll/:entityId/payroll-events/:eventId',
    payrollEventGeneral: '/global-payroll/:entityId/payroll-events/:eventId',
    payrollEventDocuments: '/global-payroll/:entityId/payroll-events/:eventId/documents',
    payrollEventPayslips: '/global-payroll/:entityId/payroll-events/:eventId/payslips',
    payrollEventReport: '/global-payroll/:entityId/payroll-events/:eventId/report',
    payrollEventPayroll: '/global-payroll/:entityId/payroll-events/:eventId/payroll',
    payrollEventCorrection: '/global-payroll/:entityId/payroll-events/:eventId/correction',
    payrollEventPayment: '/global-payroll/:entityId/payroll-events/:eventId/payment',
    payrollEventG2N: '/global-payroll/:entityId/payroll-events/:eventId/gross_to_net',
    payrollEventPreview: '/global-payroll/:entityId/payroll-events/:eventId/preview',
    payrollEventIssues: '/global-payroll/:entityId/payroll-events/:eventId/issues',
    payrollEventInputs: '/global-payroll/:entityId/payroll-events/:eventId/inputs',
    clientGpG2NUpload: '/global-payroll/:entityId/payroll-events/:eventId/client-g2n-upload',
    payrollGLReport: '/global-payroll/:entityId/payroll-events/:eventId/gl-report-generation',
    payrollSupport: '/global-payroll/payroll-support',
  },
  appStore: {
    main: '/app-store',
    roots: '/app-store/plugins',
  },
  insurance: '/insurance',
  appsAndIntegrations: {
    main: '/app-store',
    integrationsStore: '/integrations-store',
    connectedApps: '/connected-apps',
    developerCenter: '/developer-center',
    developerCenterApps: '/developer-center/apps',
    deelApi: '/developer-center/deel-api',
    deelWebhooks: '/developer-center/webhooks',
    deelWebhooksDetail: '/developer-center/webhooks/:webhookId',
    addDeelWebhook: '/developer-center/add-webhook',
    oauthAuthorization: '/oauth2/authorize',
    appPublish: '/developer-center/apps/publish',
    survey: '/integrations-survey',
  },
  appsAndPerks: {
    main: '/perks-and-extras',
  },
  emailExpired: '/email-expired',
  serviceRequests: '/service-requests',
  notContractMember: '/contract/:contractId',
  employeeAgreementChanges: '/contract/:contractId/employee-agreement-changes',
  requestChanges: '/contract/:contractId/request-changes',
  reviewAmendments: '/contract/:contractId/review-amendments/:requestId',
  employeeVerificationCustomRequest: '/employee-verification-custom-requests',
  profile: {
    main: '/profile',
    organization: '/profile/:orgId',
    contract: '/profile/:orgId/contracts/:contractId',
    contracts: '/profile/:orgId/contracts',
    personalInformation: '/profile/:orgId/personal-information',
    timeOffDetails: '/profile/:orgId/time-off/:contractId',
    supplementaryInformation: '/profile/:orgId/supplementary-information',
    timeOffEmployeeHistory: '/profile/:orgId/time-off/:contractId/history',
    timeOff: '/profile/:orgId/time-off',
    timeDetails: '/profile/:orgId/time-off/:contractId',
    timeOffHistory: '/profile/:orgId/time-off/history',
    timeOffEditBalance: '/profile/:orgId/time-off/:contractId/adjust-balance/:entitlementId',
    documents: '/profile/:orgId/documents',
    payments: '/profile/:orgId/payments',
    paymentDetails: '/profile/:orgId/payments/:contractId',
    expensesAndClaims: '/profile/:orgId/expenses-and-claims',
    expensesAndClaimsDetails: '/profile/:orgId/expenses-and-claims/:contractId',
    equipment: '/profile/:orgId/equipment',
    travelInsurance: '/profile/:orgId/travel-insurance',
    equity: '/profile/:orgId/equity',
    equipmentDetails: '/profile/:orgId/equipment/:contractId',
    coworking: '/profile/:orgId/coworking',
    coworkingDetails: '/profile/:orgId/coworking/:contractId',
    payslipsDetails: '/profile/:orgId/payslips/:contractId',
    payslips: '/profile/:orgId/payslips',
    taxDocumentsDetails: '/profile/:orgId/tax-documents/:contractId',
    taxDocuments: '/profile/:orgId/tax-documents',
    benefitsDetails: '/profile/:orgId/benefits/:contractId',
    benefits: '/profile/:orgId/benefits',
    odpOverviewsDetails: '/profile/:orgId/odp-overviews/:contractId',
    odpOverviews: '/profile/:orgId/odp-overviews',
    edit: '/profile/edit',
    reviewCycles: {
      main: '/profile/:orgId/review-cycles',
      reviewCycle: '/profile/:orgId/review-cycles/:reviewCycleId',
    },
    goals: '/profile/:orgId/goals',
  },
  editEmployeeData: '/contract/:contractId/edit-employee-data/:type',
  visaAssessmentAdditionalQuestions: '/visa-assessment-additional-questions',
  employeeVerificationLetter: '/guest/employment-verification-letter',
  workflows: {
    list: '/settings/workflows',
    create: '/settings/workflow-create',
    view: '/settings/workflow-view/:id',
    edit: '/settings/workflow-view/:id/edit',
    history: '/settings/workflow_histories',
    historyDetails: '/settings/workflow_histories/:id',
    templates: '/settings/workflow_templates',
    goToHub: '/settings/workflow-go-to-hub',
  },
  createNDAForContract: '/create-nda/:contractOid',
  visaSponsorship: {
    main: '/visa-sponsorship',
    entities: '/visa-sponsorship/entities/:country',
    sponsorshipDetails: '/visa-sponsorship/company/:id',
  },
  insights: {
    main: '/insights',
    ai: '/deelai',
  },
  germanTaxesSubscription: '/german-taxes-subscription',
  peoAdditionalDetails: '/peo-additional-details',
  permissionsCheck: '/permissions-check',
  representativeVerification: {
    code: '/guest/i-9',
    success: '/guest/i-9/success',
  },
  employeeEntityTransfer: {
    main: '/employee-entity-transfer/:contractOid',
    newEntityDetails: '/employee-entity-transfer/:contractOid/new-entity-details',
    reviewTransferDetails: '/employee-entity-transfer/:contractOid/review-transfer-details',
  },
  proofOfPayment: '/proofOfPayment/:id',
  benefits: {
    tools: {
      main: '/settings/benefits-tool',
      fallback: '/benefits-tool',
      plan: {
        main: '/benefits-tool/:benefitProviderId/plan/:benefitType',
        edit: '/benefits-tool/:benefitProviderId/plan/:benefitType/edit',
      },
    },
    app: {
      main: '/benefits-app',
    },
  },
  msaContract: {
    main: '/msa-contract/:contractOid',
  },
  csaContract: {
    main: '/csa-contract/:contractOid/:tab?',
  },
  calendar: '/calendar',
  getStarted: {
    deelHR: '/get-started-deel-hr',
  },
  externalClientSignature: {
    main: '/guest/external-client-signature',
    signing: '/guest/external-client-signature/sign/:contractId',
  },
  goals: '/goals',
  shareFeedback: '/share-feedback',
  learning: {
    journeys: {
      list: '/learning/journeys',
      view: '/learning/journeys/:id/:tab?/:insightsCategory?',
      editBuilder: '/learning/journeys/:id/builder/:stepId?',
      editBuilderDraft: '/learning/journeys/:id/draft-journeys/:draftId/builder/:stepId?',
      preview: '/learning/journeys/:id/preview/:stepId?',
      previewDraft: '/learning/journeys/:id/draft-journeys/:draftId/preview/:stepId?',
      assignments: '/learning/journey-assignments',
      automations: '/learning/journey-automations',
      actionableList: '/learning/actionable-journeys',
      actionableViewAssignee: '/learning/journeys/:journeyId/assignments/:assignmentId/assignee-steps/:stepId',
      actionableViewSpecificRecipient:
        '/learning/journeys/:journeyId/assignments/:assignmentId/specific-recipient-steps/:stepId',
      actionableViewRedirect: '/learning/actionable-journeys/:journeyId/recipient-step-redirection',
      scormReportRedirect: '/learning/scorm-report-redirect',
    },
  },
  surveys: {
    list: '/surveys',
    view: '/surveys/details/:id',
    analytics: '/surveys/analytics',
    overview: '/surveys/overview',
    actionPlans: '/surveys/action-plans',
    builder: '/surveys/:id/builder/:sectionId?',
    create: '/surveys/create/:id',
    surveyActive: {
      overview: '/surveys/survey-active/overview',
      results: '/surveys/survey-active/results',
      assignments: '/surveys/survey-active/assignments',
      automations: '/surveys/survey-active/automations',
      actionPlans: '/surveys/survey-active/action-plans',
    },
  },
  approvalRequests: {
    main: '/approval-requests',
    dataChanges: '/approval-requests/data-changes',
    timesheets: '/approval-requests/timesheets',
    timeOffs: '/approval-requests/time-offs',
  },
  etlTemplate: {
    main: '/etl/:presetName',
  },
  eorShieldMSA: {
    main: '/eor-shield-msa/:id',
    details: '/eor-shield-msa/:id/details',
    shieldInvoice: '/eor-shield-msa/:id/shield-invoice',
  },
  vendorManagement: {
    requisitions: {
      main: '/vendor-management/requisitions',
      viewDetailed: '/vendor-management/requisitions/:id',
      viewDetailedHistory: '/vendor-management/requisitions/:id/history',
      viewCandidate: '/vendor-management/requisitions/:id/candidate/:candidateId',
      viewCandidateHistory: '/vendor-management/requisitions/:id/candidate/:candidateId/history',
      edit: '/vendor-management/requisitions/:id/edit',
      create: '/vendor-management/requisitions/create',
      candidateSubmit: '/vendor-management/requisitions/candidate-submit',
    },
    talentPartners: {
      main: '/vendor-management/talent-partners',
      view: '/vendor-management/talent-partners/:id',
    },
    talentProfiles: {
      main: '/vendor-management/talent-profiles',
      viewDetailed: '/vendor-management/talent-profiles/:id',
    },
    pendingTasks: '/vendor-management/pending-tasks',
    clients: {
      main: '/vendor-management/clients',
      view: '/vendor-management/clients/:id',
    },
    vendor: {
      organizationDetails: '/vendor-management/vendor/organization-details',
    },
  },
  deelIT: {
    main: '/it',
    assets: {
      list: '/it/assets/list/:tabName',
      create: '/it/assets/create',
      details: '/it/assets/:id',
      detailsTab: '/it/assets/:id/:tabName',
    },
    people: {
      main: '/it/people',
    },
    catalogue: {
      main: '/it/catalogue',
      viewDetailed: '/it/catalogue/:id',
    },
    orders: {
      main: '/it/orders',
      details: '/it/orders/:id',
      create: '/it/orders/create/:hrisId',
      checkout: '/it/orders/create/:hrisId/checkout',
      createDetails: '/it/orders/create/:hrisId/product/:productId',
    },
    finance: {
      main: '/it/finance',
      payNow: '/it/pay-now',
      billing: '/it/billing',
    },
    services: {
      main: '/it/services',
    },
    policies: {
      list: '/it/it-policies',
      viewDetailed: '/it/it-policies/:id',
      viewDetailedTab: '/it/it-policies/:id/:tab',
    },
  },
  forms: {
    fillForm: '/forms/fill-form/:formId',
  },
  knowledgeHub: {
    main: '/knowledge/personalized',
    search: '/knowledge/search',
    saved: '/knowledge/saved',
    article: '/knowledge/article/:id',
  },
};

type RoutesType = typeof ROUTES;

export const ROUTES_TITLES: RoutesType = {
  switchUser: '',
  growthTools: {
    main: t('routes.titles.growthTools.main'),
    tools: {
      visa: t('routes.titles.growthTools.tools.visa'),
    },
  },
  getStartedPage: {
    main: t('routes.titles.getStartedPage.getStarted'),
  },
  community: {
    main: t('routes.titles.community.main'),
  },
  hiring: {
    main: t('routes.titles.hiring.main'),
    candidates: {
      list: t('routes.titles.hiring.candidates.list'),
      view: t('routes.titles.hiring.candidates.view'),
    },
    pipeline: t('routes.titles.hiring.pipeline'),
    jobs: {
      list: t('routes.titles.hiring.jobs.list'),
      view: t('routes.titles.hiring.jobs.view'),
    },
    settings: {
      main: t('routes.titles.hiring.settings.main'),
      interviewPlans: t('routes.titles.hiring.settings.interviewPlans'),
      departmentsAndTeams: t('routes.titles.hiring.settings.departmentsAndTeams'),
      applicationForms: t('routes.titles.hiring.settings.applicationForms'),
      feedbackForms: t('routes.titles.hiring.settings.feedbackForms'),
      locations: t('routes.titles.hiring.settings.locations'),
      careerPage: t('routes.titles.hiring.settings.careerPage'),
    },
  },
  backgroundChecks: {
    result: t('routes.titles.backgroundChecks.result'),
    standaloneResult: t('routes.titles.backgroundChecks.standaloneResult'),
  },
  compliance: {
    main: t('routes.titles.compliance.main'),
    contractorClassification: t('routes.titles.compliance.contractorClassification'),
    monitor: t('routes.titles.compliance.monitor'),
    workerMisclassificationAssesment: t('routes.titles.compliance.workerMisclassificationAssesment'),
    workerHiringAssesment: t('routes.titles.compliance.workerHiringAssesment'),
    massWorkerAssesment: t('routes.titles.compliance.massWorkerAssesment'),
  },
  documents: {
    requestedByDeel: t('routes.titles.documents.requestedByDeel'),
    registrationAndFiles: t('routes.titles.documents.registrationAndFiles'),
    registrationAndFilesFolder: t('routes.titles.documents.registrationAndFilesFolder'),
    custom: t('routes.titles.documents.custom'),
    customDetails: t('routes.titles.documents.customDetails'),
    main: t('routes.titles.documents.main'),
    complianceCalendar: t('routes.titles.documents.complianceCalendar'),
    general: t('routes.titles.documents.general'),
    i9: t('routes.titles.documents.i9'),
    i9Details: t('routes.titles.documents.i9Details'),
    workerVerification: t('routes.titles.documents.workerVerification'),
    customDocDetail: t('routes.titles.documents.customDocDetail'),
    customDocOverview: t('routes.titles.documents.customDocOverview'),
    contractRequirements: t('routes.titles.documents.contractRequirements'),
    contractRequirementDetails: t('routes.titles.documents.contractRequirementDetails'),
    identityVerification: t('routes.titles.documents.identityVerification'),
    i9DocDetail: t('routes.titles.documents.i9DocDetail'),
    i9DocDetailPreSelected: t('routes.titles.documents.i9DocDetailPreSelected'),
    employeeHandbook: t('routes.titles.documents.employeeHandbook'),
    eorEATemplates: {
      main: t('routes.titles.documents.eorEATemplates.main'),
      templates: t('routes.titles.documents.eorEATemplates.templates'),
      templatePreview: t('routes.titles.documents.eorEATemplates.templatePreview'),
      changeRequestForm: t('routes.titles.documents.eorEATemplates.changeRequestForm'),
      changeRequestTracker: t('routes.titles.documents.eorEATemplates.changeRequestTracker'),
    },
    agreementTemplates: t('routes.titles.documents.agreementTemplates'),
  },
  employeeComplianceDocs: t('routes.titles.main.employeeComplianceDocs'),
  employeeComplianceForm: t('routes.titles.main.employeeComplianceForm'),
  eorClientAllowancesReview: t('routes.titles.main.employeeAllowanceReview'),
  confirmation: t('routes.titles.main.confirmation'),
  demographicInformation: t('settings.demographicInformation.form.heading'),
  webLoginRequest: t('routes.titles.main.webLoginRequest'),
  terminationReview: t('routes.titles.main.terminationReview'),
  checkinSubmission: t('routes.titles.main.checkinSubmission'),
  people: {
    main: t('routes.titles.people.main'),
    organization: t('routes.titles.people.organization'),
    individual: t('routes.titles.people.individual'),
    contract: t('routes.titles.people.contract'),
    jobInformationUpdateWizard: t('routes.titles.people.jobInformationUpdateWizard'),
    contractEorAmendment: t('routes.titles.people.contractEorAmendment'),
    requestChanges: t('routes.titles.people.requestChanges'),
    contracts: t('routes.titles.people.contracts'),
    personalInformation: t('routes.titles.people.personalInformation'),
    supplementaryInformation: t('routes.titles.people.supplementaryInformation'),
    terminations: t('routes.titles.people.terminations'),
    contractTermination: t('routes.titles.people.contractTermination'),
    contractExtension: t('routes.titles.people.contractExtension'),
    timeOffDetails: t('routes.titles.people.timeOffDetails'),
    timeOffEmployeeHistory: t('routes.titles.people.timeOffEmployeeHistory'),
    timeOff: t('routes.titles.people.timeOff'),
    timeOffHistory: t('routes.titles.people.timeOffHistory'),
    timeOffHistoryList: t('routes.titles.people.timeOffHistoryList'),
    timeOffEditBalance: t('routes.titles.people.timeOffEditBalance'),
    documents: t('routes.titles.people.documents'),
    contractDocuments: t('routes.titles.people.contractDocuments'),
    payments: t('routes.titles.people.payments'),
    paymentDetails: t('routes.titles.people.paymentDetails'),
    apps: t('routes.titles.people.apps'),
    logChanges: t('routes.titles.people.logChanges'),
    equipment: t('routes.titles.people.equipment'),
    equipmentDetails: t('routes.titles.people.equipmentDetails'),
    coworking: t('routes.titles.people.coworking'),
    coworkingDetails: t('routes.titles.people.coworkingDetails'),
    travelInsurance: t('routes.titles.people.travelInsurance'),
    offboard: t('routes.titles.people.offboard'),
    payslipsDetails: t('routes.titles.people.payslipsDetails'),
    payslips: t('routes.titles.people.payslips'),
    taxDocuments: t('routes.titles.people.taxDocuments'),
    taxDocumentsDetails: t('routes.titles.people.taxDocumentsDetails'),
    benefitsDetails: t('routes.titles.people.benefitsDetails'),
    benefits: t('routes.titles.people.benefits'),
    odpOverviewsDetails: t('routes.titles.people.odpOverviewsDetails'),
    odpOverviews: t('routes.titles.people.odpOverviews'),
    timeAttendanceContracts: t('routes.titles.people.timeAttendanceContracts'),
    timeAttendance: t('routes.titles.people.timeAttendance'),
    identityVerification: t('routes.titles.people.identityVerification'),
    bulkEdit: {
      peopleSelect: t('routes.titles.people.bulkEdit.peopleSelect'),
      downloadFlow: t('routes.titles.people.bulkEdit.downloadFlow'),
      uploadFlow: t('routes.titles.people.bulkEdit.uploadFlow'),
    },
    bulkTerminate: {
      downloadFlow: t('routes.titles.people.bulkTerminate.downloadFlow'),
      uploadFlow: t('routes.titles.people.bulkTerminate.uploadFlow'),
    },
    massUpload: {
      downloadFlow: t('routes.titles.people.massUpload.downloadFlow'),
      uploadFlow: t('routes.titles.people.massUpload.uploadFlow'),
    },
    career: {
      roleCardCareerPath: t('routes.titles.people.career.roleCardCareerPath'),
      roleCardAssignees: t('routes.titles.people.career.roleCardAssignees'),
      frameworks: t('routes.titles.people.career.frameworks'),
      levelingStructure: t('routes.titles.people.career.levelingStructure'),
      competencies: t('routes.titles.people.career.competencies'),
      assignments: t('routes.titles.people.career.assignments'),
    },
    equity: {
      main: t('routes.titles.people.equity.main'),
      addOfferWorkerSelector: t('routes.titles.people.equity.addOfferWorkerSelector'),
      addOffer: t('routes.titles.people.equity.addOffer'),
      editOffer: t('routes.titles.people.equity.editOffer'),
      editGrant: t('routes.titles.people.equity.editGrant'),
      editInfo: t('routes.titles.people.equity.editInfo'),
      downloadAgreements: t('routes.titles.people.equity.downloadAgreements'),
    },
    performance: {
      main: t('routes.titles.people.performance.main'),
      reviewCycles: t('routes.titles.people.performance.reviewCycles'),
      reviewCycle: {
        setup: t('routes.titles.people.performance.reviewCycle.setup'),
        review: t('routes.titles.people.performance.reviewCycle.review'),
        manage: t('routes.titles.people.performance.reviewCycle.manage'),
        results: t('routes.titles.people.performance.reviewCycle.results'),
        team: t('routes.titles.people.performance.reviewCycle.team'),
        teamResults: t('routes.titles.people.performance.reviewCycle.teamResults'),
      },
    },
    reviewContract: t('routes.titles.people.reviewContract'),
    immigration: {
      profile: t('routes.titles.people.immigration.profile'),
      wallet: t('routes.titles.people.immigration.wallet'),
    },
    edit: t('routes.titles.people.edit'),
    projectReview: t('routes.titles.people.projectDescriptionReview'),
    reviewCycles: {
      main: t('routes.titles.people.reviewCycles.main'),
      reviewCycle: t('routes.titles.people.reviewCycles.reviewCycle'),
    },
    goals: t('routes.titles.people.goals'),
    reviewSignContract: t('routes.titles.people.reviewSignContract'),
    transitions: {
      newContract: t('contract.transition.routes.newContract'),
      termination: t('contract.transition.routes.termination'),
    },
  },
  serviceHub: {
    main: t('routes.titles.serviceHub.main'),
    groupedFeaturesListPages: {
      plugins: t('routes.titles.serviceHub.groupedFeaturesListPages.plugins'),
      consulting: t('routes.titles.serviceHub.groupedFeaturesListPages.consulting'),
      services: t('routes.titles.serviceHub.groupedFeaturesListPages.services'),
      hiringTools: t('routes.titles.serviceHub.groupedFeaturesListPages.hiringTools'),
      perks: t('routes.titles.serviceHub.groupedFeaturesListPages.perks'),
    },
    plugins: {
      announcements: t('routes.titles.serviceHub.plugins.announcements'),
      connections: t('routes.titles.serviceHub.plugins.connections'),
      onboarding: t('routes.titles.serviceHub.plugins.onboarding'),
      oneOnOne: t('routes.titles.serviceHub.plugins.oneOnOne'),
      orgCharts: t('routes.titles.serviceHub.plugins.orgCharts'),
      pto: t('routes.titles.serviceHub.plugins.pto'),
      pulseSurveys: t('routes.titles.serviceHub.plugins.pulseSurveys'),
      referrals: t('routes.titles.serviceHub.plugins.referrals'),
      kudos: t('routes.titles.serviceHub.plugins.kudos'),
    },
    consulting: {
      eorEquityService: t('routes.titles.serviceHub.consulting.eorEquityService'),
      hrConsulting: t('routes.titles.serviceHub.consulting.hrConsulting'),
      privacyConsulting: t('routes.titles.serviceHub.consulting.privacyConsulting'),
      globalExpansion: t('routes.titles.serviceHub.consulting.globalExpansion'),
    },
    services: {
      dpa: t('routes.titles.serviceHub.services.dpa'),
      backgroundCheck: t('routes.titles.serviceHub.services.backgroundCheck'),
      individualBackgroundCheck: t('routes.titles.serviceHub.services.individualBackgroundChecks'),
      consolidatedBackgroundChecks: t('routes.titles.serviceHub.services.consolidatedBackgroundChecks'),
      shield: t('routes.titles.serviceHub.services.shield'),
      coworking: t('routes.titles.serviceHub.services.coworking'),
      equipment: t('routes.titles.serviceHub.services.equipment'),
      leasedEquipment: t('routes.titles.serviceHub.services.leasedEquipment'),
      equipmentDeviceInventory: {
        archive: t('routes.titles.serviceHub.services.equipmentDeviceInventory.archive'),
        massImport: t('routes.titles.serviceHub.services.equipmentDeviceInventory.massImport'),
      },
      api: {
        main: t('routes.titles.serviceHub.services.api.main'),
        apps: t('routes.titles.serviceHub.services.api.apps'),
        webhooks: t('routes.titles.serviceHub.services.api.webhooks'),
        deelApi: t('routes.titles.serviceHub.services.api.deelApi'),
      },
      taxAdvice: {
        main: t('routes.titles.serviceHub.services.taxAdvice.main'),
        codes: t('routes.titles.serviceHub.services.taxAdvice.codes'),
      },
      entityRegister: {
        main: t('routes.titles.serviceHub.services.entity.main'),
      },
      equity: {
        main: t('routes.titles.serviceHub.services.equity.main'),
      },
      nda: t('routes.titles.serviceHub.services.nda'),
      globalPayroll: t('routes.titles.serviceHub.services.globalPayroll'),
      benefits: t('routes.titles.serviceHub.services.benefits'),
      immigration: {
        main: t('routes.titles.serviceHub.services.immigration.main'),
        request: t('routes.titles.serviceHub.services.immigration.request'),
        visaEligibleCheck: t('routes.titles.serviceHub.services.immigration.visaEligibleCheck'),
        visas: t('routes.titles.serviceHub.services.immigration.visas'),
        cases: t('routes.titles.serviceHub.services.immigration.cases'),
        caseDetails: t('routes.titles.serviceHub.services.immigration.caseDetails'),
        wallet: t('routes.titles.serviceHub.services.immigration.wallet'),
        tracker: t('routes.titles.serviceHub.services.immigration.tracker'),
        trackerApplications: t('routes.titles.serviceHub.services.immigration.trackerApplications'),
        trackerAssessments: t('routes.titles.serviceHub.services.immigration.trackerAssessments'),
        profile: t('routes.titles.serviceHub.services.immigration.profile'),
      },
    },
    engage_plugins: {
      setup: {
        finish: t('routes.titles.serviceHub.engage_plugins.setup.finish'),
        finishV2: t('routes.titles.serviceHub.engage_plugins.setup.finishV2'),
      },
    },
  },
  immigration: {
    cases: t('routes.titles.immigration.cases'),
    caseDetails: t('routes.titles.immigration.caseDetails'),
    wallet: t('routes.titles.immigration.wallet'),
  },
  organizationChart: {
    main: t('routes.titles.organizationChart.main'),
    organization: t('routes.titles.organizationChart.organization'),
    individual: t('routes.titles.organizationChart.individual'),
    assignment: t('routes.titles.organizationChart.assignment'),
    organization_employee: t('routes.titles.organizationChart.organization_employee'),
  },

  tracker: {
    main: t('routes.titles.tracker.main'),
    changeRequests: t('routes.titles.tracker.changeRequests'),
    peopleOnboarding: t('routes.titles.tracker.peopleOnboarding'),
    endingContracts: t('routes.titles.tracker.endingContracts'),
    contractEdits: t('routes.titles.tracker.contractEdits'),
    processes: t('routes.titles.tracker.processes'),
    contractDrafts: t('routes.titles.tracker.contractDrafts'),
  },
  task: {
    main: t('routes.titles.task.main'),
    create: t('routes.titles.task.create'),
    update: t('routes.titles.task.update'),
    individual: t('routes.titles.task.individual'),
  },
  timeAttendance: {
    main: t('routes.titles.timeAttendance.main'),
    reviewHours: t('routes.titles.timeAttendance.reviewHours'),
    contracts: t('routes.titles.timeAttendance.contracts'),
  },
  massOnboarding: t('routes.titles.main.massOnboarding'),
  deelHRToGPEmployee: t('routes.titles.main.deelHRToGPEmployee'),
  gpMassUpdate: t('routes.titles.main.gpMassUpdate'),
  mobilityTermsConditions: t('routes.titles.main.mobilityTermsConditions'),
  contracts: t('routes.titles.main.contracts'),
  contract: t('routes.titles.main.contract'),
  contractEdit: t('routes.titles.main.contractEdit'),
  contractTermination: t('routes.titles.main.contractTermination'),
  contractExtension: t('routes.titles.people.contractExtension'),
  contractPreview: t('routes.titles.main.contractPreview'),
  contractUpdateAdjustment: t('routes.titles.main.contractUpdateAdjustment'),
  contractTemplateEditor: t('routes.titles.main.contractTemplateEditor'),
  contractJobInformationUpdateWizard: t('routes.titles.main.contractJobInformationUpdateWizard'),
  customDocsUpload: t('routes.titles.main.customDocsUpload'),
  reports: {
    main: t('routes.titles.reports.main'),
    salaryInsights: t('routes.titles.reports.salaryInsights'),
    dashboard: t('routes.titles.reports.dashboard'),
    reportsList: t('routes.titles.reports.reportsList'),
    reportCreateCustom: t('routes.titles.reports.reportCreateCustom'),
    reportEditCustom: t('routes.titles.reports.reportEditCustom'),
    reportCustomization: t('routes.titles.reports.reportCustomization'),
    globalG2NReport: t('routes.titles.reports.globalG2NReport'),
    globalG2NReportEmployeeView: t('routes.titles.reports.globalG2NReportEmployeeView'),
    report: t('routes.titles.reports.report'),
    g2nEmployeeView: t('routes.titles.reports.g2nEmployeeView'),
    g2nEorEmployeeView: t('routes.titles.reports.g2nEorEmployeeView'),
    g2nEorVarianceReport: t('routes.titles.reports.g2nEorVarianceReport'),
    g2nReport: t('routes.titles.reports.g2nReport'),
    g2nVariances: t('routes.titles.reports.g2nVariances'),
    g2nVariancesDetails: t('routes.titles.reports.g2nVariancesDetails'),
    g2nReportEmployeeContributions: t('routes.titles.reports.g2nReportEmployeeContributions'),
    g2nReportEmployeeDeductions: t('routes.titles.reports.g2nReportEmployeeDeductions'),
    g2nReportByCostCenter: t('routes.titles.reports.g2nReportByCostCenter'),
    g2nReportByCostCenterSummary: t('routes.titles.reports.g2nReportByCostCenterSummary'),
    timeOffSummaryReport: t('routes.titles.reports.timeOffSummaryReport'),
    timeOffsRequestsReport: t('routes.titles.reports.timeOffsRequestsReport'),
    recurringAllowancesAndDeductions: t('routes.titles.reports.recurringAllowancesAndDeductions'),
    recurringPayrollItems: t('routes.titles.reports.recurringPayrollItems'),
  },
  create: {
    account: t('routes.titles.create.account'),
    accountCompany: t('routes.titles.create.accountCompany'),
    accountIndividual: t('routes.titles.create.accountIndividual'),
    accountEmployee: t('routes.titles.create.accountEmployee'),
    main: t('routes.titles.create.main'),
    fixed: t('routes.titles.create.fixed'),
    payAsYouGo: t('routes.titles.create.payAsYouGo'),
    milestone: t('routes.titles.create.milestone'),
    eor: t('routes.titles.create.eor'),
    peo: t('routes.titles.create.peo'),
    costCalculator: t('routes.titles.create.costCalculator'),
    directEmployee: t('routes.titles.create.directEmployee'),
    directHREmployee: t('routes.titles.create.directHREmployee'),
    payrollSelector: t('routes.titles.create.payrollSelector'),
    contractorOutsideDeel: t('routes.titles.create.contractorOutsideDeel'),
  },
  createOrganization: t('routes.titles.main.createOrganization'),
  contractorType: t('routes.titles.main.contractorType'),
  createHrisEmployee: t('routes.titles.main.createHrisEmployee'),
  createHrisEmployeeLightweight: t('routes.titles.main.createHrisEmployeeLightweight'),
  home: t('routes.titles.main.home'),
  cdd2: t('routes.titles.main.cdd2'),
  cdd2LegalEntity: t('routes.titles.main.cdd2LegalEntity'),
  contractorPartialRecords: {
    invoices: t('routes.titles.contractorPartialRecords.invoices'),
    resolveInvoice: t('routes.titles.contractorPartialRecords.resolveInvoice'),
    resolveInvoices: t('routes.titles.contractorPartialRecords.resolveInvoices'),
    expenses: t('routes.titles.contractorPartialRecords.expenses'),
    resolveExpense: t('routes.titles.contractorPartialRecords.resolveExpense'),
    resolveExpenses: t('routes.titles.contractorPartialRecords.resolveExpenses'),
  },
  invoices: t('routes.titles.main.invoices'),
  invoicesImport: t('routes.titles.main.invoicesImport'),
  expensesAdd: t('routes.titles.main.expensesAdd'),
  expensesReview: t('routes.titles.main.expensesReview'),
  expensesClaims: t('routes.titles.main.expensesClaims'),
  expenses: t('routes.titles.main.expenses'),
  expensesImport: t('routes.titles.main.expensesImport'),
  expense: t('routes.titles.main.expense'),
  platformExpense: t('routes.titles.main.platformExpense'),
  editExpense: t('routes.titles.main.editExpense'),
  addExternalExpense: t('routes.titles.main.addExternalExpense'),
  externalInvoices: t('routes.titles.main.externalInvoices'),
  incomeExternal: {
    main: t('routes.titles.incomeExternal.main'),
    upload: t('routes.titles.incomeExternal.upload'),
    details: t('routes.titles.incomeExternal.details'),
    charges: t('routes.titles.incomeExternal.charges'),
    payments: t('routes.titles.incomeExternal.payments'),
    preview: t('routes.titles.incomeExternal.preview'),
  },
  receipts: t('routes.titles.main.receipts'),
  deelInvoice: t('routes.titles.main.deelInvoice'),
  externalInvoice: t('routes.titles.main.externalInvoice'),
  uploadedInvoice: t('routes.titles.main.uploadedInvoice'),
  externalInvoicePreview: t('routes.titles.main.externalInvoicePreview'),
  uploadedInvoicePreview: t('routes.titles.main.uploadedInvoicePreview'),
  invoice: t('routes.titles.main.invoice'),
  receipt: t('routes.titles.main.receipt'),
  paymentSummary: t('routes.titles.main.paymentSummary'),
  paymentSummaryPublicLanding: t('routes.titles.main.paymentSummaryPublicLanding'),
  settings: {
    accountContractorsEntityOnboarding: t('routes.titles.settings.accountContractorsEntityOnboarding'),
    analytics: t('routes.titles.settings.analytics'),
    accountDetails: t('routes.titles.settings.accountDetails'),
    contractsSettings: t('routes.titles.settings.contractsSettings'),
    contractsSettingsTab: t('routes.titles.settings.contractsSettings'),
    organization: t('routes.titles.settings.organization'),
    organizationDetailsDocs: t('routes.titles.settings.organizationDetailsDocs'),
    rolesPermissionsAuditLogs: t('routes.titles.settings.rolesPermissionsAuditLogs'),
    entityLogs: t('routes.titles.settings.rolesPermissionsAuditLogs'),
    compareRoles: t('routes.titles.settings.compareRoles'),
    emailLogs: t('routes.titles.settings.emailLogs'),
    emailLog: t('routes.titles.settings.emailLog'),
    emailLogPreview: t('routes.titles.settings.emailLogPreview'),
    emailLogPreviewDetails: t('routes.titles.settings.emailLogPreviewDetails'),
    managers: t('routes.titles.settings.managers'),
    workers: t('routes.titles.settings.workers'),
    singleUser: t('routes.titles.settings.singleUser'),
    singleWorker: t('routes.titles.settings.singleWorker'),
    inviteManager: t('routes.titles.settings.inviteManager'),
    assignRole: t('routes.titles.settings.assignRole'),
    contractorEntity: t('routes.titles.settings.contractorEntity'),
    customAdjustments: t('routes.titles.settings.customAdjustments'),
    workerRelations: t('routes.titles.settings.workerRelations'),
    editOrgStructureById: t('routes.titles.settings.editOrgStructureById'),
    editOrgStructureByType: t('routes.titles.settings.editOrgStructureByType'),
    addOrgStructure: t('routes.titles.settings.addOrgStructure'),
    eorEarlyInvoicingFunds: t('routes.titles.settings.eorEarlyInvoicingFunds'),
    entities: t('routes.titles.settings.entities'),
    entity: t('routes.titles.settings.entity'),
    entityAdd: t('routes.titles.settings.entityAdd'),
    entityAddPeo: t('routes.titles.settings.entityAddPeo'),
    entityEdit: t('routes.titles.settings.entityEdit'),
    entityDetails: t('routes.titles.settings.entityDetails'),
    entityEmployeeDetails: t('routes.titles.settings.entityEmployeeDetails'),
    entityMultiStateRegistration: t('routes.titles.settings.entityMultiStateRegistration'),
    entityAddStateRegistrations: t('routes.titles.settings.entityAddStateRegistrations'),
    entityGlobalPayroll: t('routes.titles.settings.entityGlobalPayroll'),
    entityEmployees: t('routes.titles.settings.entityEmployees'),
    entityPayrollSchedule: t('routes.titles.settings.entityPayrollSchedule'),
    entityAddPayrollGroups: t('routes.titles.settings.entityAddPayrollGroups'),
    entitySignDocuments: t('routes.titles.settings.entitySignDocuments'),
    entityPayrollDataImport: t('routes.titles.settings.entityPayrollDataImport'),
    entityConfirmTaxesDue: t('routes.titles.settings.entityConfirmTaxesDue'),
    entityGlobalPayrollCalendar: t('routes.titles.settings.entityGlobalPayrollCalendar'),
    entityGlobalPayrollGeneralLedgerWizard: t('routes.titles.settings.entityGlobalPayrollGeneralLedgerWizard'),
    entityGlobalPayrollEditGeneralLedgerWizard: t('routes.titles.settings.entityGlobalPayrollEditGeneralLedgerWizard'),
    entityGlobalPayrollProcessGuidelinesWizard: t('routes.titles.settings.entityGlobalPayrollProcessGuidelinesWizard'),
    entityGlobalPayrollProcessGuidelinesEditWizard: t(
      'routes.titles.settings.entityGlobalPayrollProcessGuidelinesEditWizard'
    ),
    teams: t('routes.titles.settings.teams'),
    dynamicGroups: t('routes.titles.settings.teams'),
    accountEditEntityAddress: t('routes.titles.settings.accountEditEntityAddress'),
    accountEditEntity: t('routes.titles.settings.accountEditEntity'),
    accountEditPersonal: t('routes.titles.settings.accountEditPersonal'),
    accountEditPersonalAddress: t('routes.titles.settings.accountEditPersonalAddress'),
    accountEditShareholders: t('routes.titles.settings.accountEditShareholders'),
    invoiceDetails: t('routes.titles.settings.invoiceDetails'),
    emailChangeLinkExpired: t('routes.titles.settings.emailChangeLinkExpired'),
    invoiceEditAddress: t('routes.titles.settings.invoiceEditAddress'),
    updateEmail: t('routes.titles.settings.updateEmail'),
    main: t('routes.titles.settings.main'),
    billing: t('routes.titles.settings.billing'),
    deposits: t('routes.titles.settings.deposits'),
    general: t('routes.titles.settings.general'),
    globalPayrollExpenses: t('routes.titles.settings.globalPayrollExpenses'),
    integrations: t('routes.titles.settings.integrations'),
    addIntegration: t('routes.titles.settings.addIntegration'),
    security: t('routes.titles.settings.security'),
    securityResetPassword: t('routes.titles.settings.securityResetPassword'),
    securityResetPasswordError: t('routes.titles.settings.securityResetPasswordError'),
    permissions: t('routes.titles.settings.permissions'),
    team: t('routes.titles.settings.team'),
    addTeam: t('routes.titles.settings.addTeam'),
    addTeamMember: t('routes.titles.settings.addTeamMember'),
    verification: t('routes.titles.settings.verification'),
    verificationLetterSample: t('routes.titles.settings.verificationLetterSample'),
    notificationSettings: t('routes.titles.settings.notificationSettings'),
    bankDetails: t('routes.titles.settings.bankDetails'),
    paymentMethods: t('routes.titles.settings.paymentMethods'),
    refundMethods: t('routes.titles.settings.refundMethods'),
    bankGuarantee: t('routes.titles.settings.bankGuarantee'),
    billingEmails: t('routes.titles.settings.billingEmails'),
    payments: t('routes.titles.settings.payments'),
    document: {
      management: t('routes.titles.settings.document.management'),
      creation: t('routes.titles.settings.document.creation'),
      editTemplate: t('routes.titles.settings.document.editTemplate'),
      customTemplateEditor: t('routes.titles.settings.document.customTemplateEditor'),
      editDetails: t('routes.titles.settings.document.editDetails'),
      editDeliveryOptions: t('routes.titles.settings.document.editDeliveryOptions'),
      editConfiguration: t('routes.titles.settings.document.editConfiguration'),
      editCustomAgreementTemplate: t('routes.titles.settings.document.editCustomAgreementTemplate'),
      signDocument: t('routes.titles.settings.document.signDocument'),
      addDPA: t('routes.titles.settings.document.addDPA'),
    },
    project: {
      management: t('routes.titles.settings.project.management'),
      creation: t('routes.titles.settings.project.creation'),
      editDetails: t('routes.titles.settings.project.editDetails'),
    },
    scheduledDataEdits: t('routes.titles.settings.scheduledDataEdits'),
    bulkEditLogs: t('routes.titles.settings.bulkEditLogs'),
    auditLogs: t('routes.titles.settings.auditLogs'),
    demographicInformation: t('routes.titles.settings.demographicInformation'),
    deelHr: {
      deelHrAccess: t('routes.titles.settings.deelHr.deelHrAccess'),
      main: t('routes.titles.settings.deelHr.main'),
      hide: t('routes.titles.settings.deelHr.hide'),
      choice: t('routes.titles.settings.deelHr.choice'),
      terminationReasons: t('routes.titles.settings.deelHr.terminationReasons'),
      addTerminationReason: t('routes.titles.settings.deelHr.addTerminationReason'),
      editTerminationReason: t('routes.titles.settings.deelHr.editTerminationReason'),
      modificationReasons: t('routes.titles.settings.deelHr.modificationReasons'),
      terminationImpact: t('routes.titles.settings.deelHr.terminationImpact'),
      addTerminationImpact: t('routes.titles.settings.deelHr.addTerminationImpact'),
      editTerminationImpact: t('routes.titles.settings.deelHr.editTerminationImpact'),
    },
    timeOff: {
      policies: t('routes.titles.settings.timeOff.policies'),
      policyTemplates: t('routes.titles.settings.timeOff.policyTemplates'),
      addPolicy: t('routes.titles.settings.timeOff.addPolicy'),
      viewPolicy: t('routes.titles.settings.timeOff.viewPolicy'),
    },
    customizeDomain: t('routes.titles.settings.customizeDomain'),
    developerSettings: t('routes.titles.settings.developerSettings'),
    addCustomField: t('routes.titles.settings.addCustomField'),
    editCustomField: t('routes.titles.settings.editCustomField'),
    customFieldsList: t('routes.titles.settings.customFieldsList'),
    payrollData: t('routes.titles.settings.payrollData'),
    editOrganizationField: t('routes.titles.settings.editOrganizationField'),
    deelBalance: t('routes.titles.settings.deelBalance'),
    deelBalanceRefund: t('routes.titles.settings.deelBalanceRefund'),
    slackNotifications: t('routes.titles.settings.slackNotifications'),
    germanTaxesSubscription: t('routes.titles.settings.germanTaxesSubscription'),
    contractorPaymentMethods: t('routes.titles.settings.contractorPaymentMethods'),
    bookkeeping: t('routes.titles.settings.bookkeeping'),
    subscriptionBilling: t('routes.titles.settings.subscriptionBilling'),
    roles: t('routes.titles.settings.roles'),
    organizationSecurity: t('routes.titles.settings.organizationSecurity'),
    createCustomRole: t('routes.titles.settings.createCustomRole'),
    editCustomRole: t('routes.titles.settings.editCustomRole'),
    chooseRoleTemplate: t('routes.titles.settings.createCustomRole'),
    timeAttendance: {
      settings: t('routes.titles.settings.timeAttendance.settings'),
      addSchedule: t('routes.titles.settings.timeAttendance.addSchedule'),
      viewAllSchedules: t('routes.titles.settings.timeAttendance.viewAllSchedules'),
      editSchedule: t('routes.titles.settings.timeAttendance.editSchedule'),
      ratePolicies: t('routes.titles.settings.timeAttendance.ratePolicies'),
      viewAllRatePolicies: t('routes.titles.settings.timeAttendance.viewAllRatePolicies'),
      ratePolicyForm: t('routes.titles.settings.timeAttendance.ratePolicyForm'),
      ratePolicyFormEdit: t('routes.titles.settings.timeAttendance.ratePolicyFormEdit'),
      overtimeSubmissionForm: t('routes.titles.settings.timeAttendance.overtimeSubmissionForm'),
      overtimeSubmissionFormEdit: t('routes.titles.settings.timeAttendance.overtimeSubmissionFormEdit'),
      massAssignPolicies: t('routes.titles.settings.timeAttendance.massAssignPolicies'),
      massAssignSchedule: t('routes.titles.settings.timeAttendance.massAssignSchedule'),
      viewAllAutoAssignmentRules: t('routes.titles.settings.timeAttendance.viewAllAutoAssignmentRules'),
    },
    prepaidBilling: t('routes.titles.settings.prepaidBilling'),
    benefits: {
      main: t('routes.titles.settings.benefits.main'),
      app: {
        main: t('routes.titles.settings.benefits.app.main'),
        employee: t('routes.titles.settings.benefits.app.main'),
      },
    },
    deelBillingCredits: t('routes.titles.settings.deelBillingCredits'),
    approvalPolicy: {
      main: t('routes.titles.settings.approvalPolicy.main'),
      create: t('routes.titles.settings.approvalPolicy.create'),
      update: t('routes.titles.settings.approvalPolicy.update'),
      requests: t('routes.titles.settings.approvalPolicy.requests'),
      delegations: t('routes.titles.settings.approvalPolicy.delegations'),
    },
    dataChangePolicy: {
      main: t('settings.dataChangePolicy.title'),
      create: t('settings.dataChangePolicy.createNewPolicy'),
      update: t('settings.dataChangePolicy.createNewPolicy'),
    },
    personalDetails: {
      main: t('routes.titles.settings.personalDetails.main'),
      edit: t('routes.titles.settings.personalDetails.edit'),
    },
    orgStructures: '',
    whiteLabel: {
      main: t('routes.titles.settings.whiteLabel.main'),
      emails: t('routes.titles.settings.whiteLabel.emails'),
      features: t('routes.titles.settings.whiteLabel.features'),
      setup: t('routes.titles.settings.whiteLabel.setup'),
    },
    benefitsOffer: t('routes.titles.settings.benefitsOffer'),
    customBranding: {
      main: t('routes.titles.settings.customBranding.main'),
      setup: t('routes.titles.settings.customBranding.setup'),
    },
    deelEngage: {
      main: t('routes.titles.settings.deelEngage.main'),
      edit: t('routes.titles.settings.deelEngage.edit'),
      crateAutomation: t('routes.titles.settings.deelEngage.createAutomation'),
      questionBank: {
        main: t('routes.titles.settings.questionBank.main'),
        editor: t('routes.titles.settings.questionBank.questionEditor'),
      },
    },
    immigration: {
      main: t('routes.titles.settings.immigration.main'),
    },
    moveContractsBetweenPayrollGroups: t('routes.titles.settings.moveContractsBetweenPayrollGroups'),
  },
  eor: {
    bankDetails: t('routes.titles.eor.bankDetails'),
    addBankDetails: t('routes.titles.eor.addBankDetails'),
    editBankDetails: t('routes.titles.eor.editBankDetails'),
    viewBankDetails: t('routes.titles.eor.viewBankDetails'),
    fundDistribution: t('routes.titles.eor.fundDistribution'),
    paymentHistory: t('routes.titles.eor.paymentHistory'),
    requestContractsEntityMovement: t('routes.titles.eor.requestContractsEntityMovement'),
    reviewContractsEntityMovement: t('routes.titles.eor.reviewContractsEntityMovement'),
  },
  odp: {
    bulkSubmit: t('routes.titles.odp.bulkSubmit'),
    singleRequest: t('routes.titles.odp.singleRequest'),
  },
  peo: {
    bankDetails: t('routes.titles.peo.bankDetails'),
    addBankDetails: t('routes.titles.peo.addBankDetails'),
    editBankDetails: t('routes.titles.peo.editBankDetails'),
    viewBankDetails: t('routes.titles.peo.viewBankDetails'),
    fundDistribution: t('routes.titles.peo.fundDistribution'),
    completeAgreements: t('routes.titles.peo.completeAgreements'),
    terminate: t('routes.titles.peo.terminate'),
    docsCollection: t('routes.titles.peo.docsCollection'),
  },
  peoQuestionnaire: {
    ghq: t('routes.titles.peoQuestionnaire.ghq'),
    riskUnderwriting: t('routes.titles.peoQuestionnaire.riskUnderwriting'),
  },
  gpDirectEmployee: {
    bankDetails: t('routes.titles.gpDirectEmployee.bankDetails'),
    addBankDetails: t('routes.titles.gpDirectEmployee.addBankDetails'),
    onboardingBankDetails: t('routes.titles.gpDirectEmployee.onboardingBankDetails'),
    editBankDetails: t('routes.titles.gpDirectEmployee.editBankDetails'),
    viewBankDetails: t('routes.titles.gpDirectEmployee.viewBankDetails'),
    fundDistribution: t('routes.titles.gpDirectEmployee.fundDistribution'),
  },
  signUp: {
    main: t('routes.titles.signUp.main'),
    employee: t('routes.titles.signUp.employee'),
    changeAccountType: t('routes.titles.signUp.changeAccountType'),
    withToken: t('routes.titles.signUp.withToken'),
  },
  setup2FA: {
    main: t('routes.titles.setup2FA.main'),
    initial: t('routes.titles.setup2FA.initial'),
    add: t('routes.titles.setup2FA.add'),
    mandatory: t('routes.titles.setup2FA.mandatory'),
    fallback: t('routes.titles.setup2FA.fallback'),
  },
  createProfile: t('routes.titles.main.createProfile'),
  createSecondaryProfile: t('routes.titles.main.createSecondaryProfile'),
  withdraw: t('routes.titles.main.withdraw'),
  autoWithdrawal: t('routes.titles.main.autoWithdrawal'),
  payment: t('routes.titles.main.payment'),
  pay: t('routes.titles.main.pay'),
  addPayment: t('routes.titles.main.addPayment'),
  engagePluginsSubscription: t('routes.titles.main.engagePluginsSubscription'),
  engagePluginsSubscriptionV2: t('routes.titles.main.engagePluginsSubscriptionV2'),
  payments: {
    invoices: t('routes.titles.payments.invoices'),
    receipts: t('routes.titles.payments.receipts'),
    upcoming: t('routes.titles.payments.upcoming'),
  },
  billing: {
    receipts: t('routes.titles.billing.receipts'),
    invoices: t('routes.titles.billing.invoices'),
    liabilities: t('routes.titles.billing.liabilities'),
    refunds: t('routes.titles.billing.refunds'),
  },
  payOffcycle: t('routes.titles.main.payOffcycle'),
  referrals: {
    main: t('routes.titles.referrals.main'),
    invites: t('routes.titles.referrals.invites'),
    list: t('routes.titles.referrals.list'),
  },
  taxes: {
    main: t('routes.titles.taxes.main'),
    taxRegistration: t('routes.titles.taxes.taxRegistration'),
    taxRegisterPersonalDetails: t('routes.titles.taxes.taxRegisterPersonalDetails'),
    taxRegisterBusinessInfo: t('routes.titles.taxes.taxRegisterBusinessInfo'),
    taxRegisterTaxSetup: t('routes.titles.taxes.taxRegisterTaxSetup'),
    taxRegisterTaxEstimation: t('routes.titles.taxes.taxRegisterTaxEstimation'),
    taxRegisterBankAccount: t('routes.titles.taxes.taxRegisterBankAccount'),
    taxRegisterPreviewSubmit: t('routes.titles.taxes.taxRegisterPreviewSubmit'),
    file: t('routes.titles.taxes.file'),
    taxForm: t('routes.titles.taxes.taxForm'),
    createTaxForm: t('routes.titles.taxes.createTaxForm'),
    editTaxForm: t('routes.titles.taxes.editTaxForm'),
  },
  taxAdvice: {
    main: t('routes.titles.taxAdvice.main'),
    services: t('routes.titles.taxAdvice.services'),
    guides: t('routes.titles.taxAdvice.guides'),
    about: t('routes.titles.taxAdvice.about'),
    taxQuestionAnalysis: t('routes.titles.taxAdvice.taxQuestionAnalysis'),
    officeQuestion: t('routes.titles.taxAdvice.officeQuestion'),
    taxServiceQuote: t('routes.titles.taxAdvice.taxServiceQuote'),
    consultantCall: t('routes.titles.taxAdvice.consultantCall'),
  },
  accounting: {
    main: t('routes.titles.accounting.main'),
    income: t('routes.titles.accounting.income'),
    expenses: t('routes.titles.accounting.expenses'),
    import: t('routes.titles.accounting.import'),
    importReview: t('routes.titles.accounting.importReview'),
  },
  transactionHistory: t('routes.titles.main.transactionHistory'),
  upcomingTransaction: t('routes.titles.main.upcomingTransaction'),
  transactionDetails: t('routes.titles.main.transactionDetails'),
  deelAdvance: {
    dashboard: t('routes.titles.deelAdvance.dashboard'),
    info: t('routes.titles.deelAdvance.info'),
    form: t('routes.titles.deelAdvance.form'),
    agreement: t('routes.titles.deelAdvance.agreement'),
  },
  deelCard: {
    main: t('routes.titles.deelCard.main'),
    cardInfo: t('routes.titles.deelCard.cardInfo'),
    add: t('routes.titles.deelCard.add'),
    addVirtual: t('routes.titles.deelCard.addVirtual'),
    addPhysical: t('routes.titles.deelCard.addPhysical'),
    transactions: t('routes.titles.deelCard.transactions'),
  },
  ewa: {
    main: t('routes.titles.ewa.main'),
    workers: t('routes.titles.ewa.feature', { feature: t('routes.titles.ewa.features.workers') }),
    workerDetails: t('routes.titles.ewa.feature', { feature: t('routes.titles.ewa.features.workers') }),
    requests: t('routes.titles.ewa.feature', { feature: t('routes.titles.ewa.features.requests') }),
    tos: t('routes.titles.ewa.feature', { feature: t('routes.titles.ewa.features.tos') }),
  },
  withdrawalMethods: t('routes.titles.main.withdrawalMethods'),
  team: {
    main: t('routes.titles.team.main'),
    paymentMethods: t('routes.titles.team.paymentMethods'),
    managers: t('routes.titles.team.managers'),
  },
  payslips: t('routes.titles.main.payslips'),
  taxDocuments: t('routes.titles.main.taxDocuments'),
  employeeAdditionalDetails: t('routes.titles.main.employeeAdditionalDetails'),
  employeePayrollCompliance: t('routes.titles.main.employeePayrollCompliance'),
  directEmployeeAdditionalDetails: t('routes.titles.main.directEmployeeAdditionalDetails'),
  hrisDirectEmployee: {
    finance: {
      bankDetails: t('routes.titles.settings.bankDetails'),
      allPayslips: t('routes.titles.main.payslips'),
      contractPayslips: t('routes.titles.main.payslips'),
    },
  },
  hrisDirectEmployeeBankDetails: t('routes.titles.main.hrisDirectEmployeeBankDetails'),
  hrisAdditionalDetails: t('routes.titles.main.hrisAdditionalDetails'),
  hrisPayslipsUpload: t('routes.titles.main.hrisPayslipsUpload'),
  multipleHrisPayslipsUpload: t('routes.titles.main.multipleHrisPayslipsUpload'),
  hrisTaxUpload: t('routes.titles.main.hrisTaxUpload'),
  multipleHrisTaxDocumentsUpload: t('routes.titles.main.multipleHrisTaxDocumentsUpload'),
  additionalProfileInformation: t('routes.titles.main.additionalProfileInformation'),
  supplementaryInformation: t('routes.titles.main.supplementaryInformation'),
  offerLetters: t('routes.titles.main.offerLetters'),
  equity: t('routes.titles.main.equity'),
  signEquityAgreements: t('routes.titles.main.signEquityAgreements'),
  employeeFAQ: t('routes.titles.main.employeeFAQ'),
  employeeContractEditor: t('routes.titles.main.employeeContractEditor'),
  incentivePlans: t('routes.titles.main.incentivePlans'),
  icpPortal: {
    home: t('routes.titles.icpPortal.home'),
    contractSearch: t('routes.titles.icpPortal.contractSearch'),
    payrollSoftwareSync: t('routes.titles.icpPortal.payrollSoftwareSync'),
    documentSharing: t('routes.titles.icpPortal.documentSharing'),
    employeesList: t('routes.titles.icpPortal.employeesList'),
    employeeDetails: t('routes.titles.icpPortal.employeeDetails'),
    employeeDocuments: t('routes.titles.icpPortal.employeeDocuments'),
    adjustments: t('routes.titles.icpPortal.adjustments'),
    timeOff: t('routes.titles.icpPortal.timeOff'),
    dataUpdates: t('routes.titles.icpPortal.dataUpdates'),
    offCycleTerminations: t('routes.titles.icpPortal.offCycleTerminations'),
    payrollTaxForms: t('routes.titles.icpPortal.payrollTaxForms'),
    addPayrollTaxForms: t('routes.titles.icpPortal.addPayrollTaxForms'),
    editPayrollTaxForms: t('routes.titles.icpPortal.editPayrollTaxForms'),
    reports: t('routes.titles.icpPortal.reports'),
    configureTemplate: t('routes.titles.icpPortal.configureTemplate'),
    cycles: t('routes.titles.icpPortal.cycles'),
    cyclesEntityPage: t('routes.titles.icpPortal.cyclesEntityPage'),
    cyclesEntityDetailsPage: t('routes.titles.icpPortal.cyclesEntityDetailsPage'),
    cyclesEntityWorkbenchPage: t('routes.titles.icpPortal.cyclesEntityWorkbenchPage'),
    cyclesEntityReportsPage: t('routes.titles.icpPortal.cyclesEntityReportsPage'),
    cyclesEntityInvoiceItemsPage: t('routes.titles.icpPortal.cyclesEntityInvoiceItemsPage'),
    cyclesEntityNotesPage: t('routes.titles.icpPortal.cyclesEntityNotesPage'),
    editPreviewInvoice: t('routes.titles.icpPortal.editPreviewInvoice'),
    employeeInvoices: t('routes.titles.icpPortal.employeeInvoices'),
    payslips: t('routes.titles.icpPortal.payslips'),
    qaFlags: t('routes.titles.icpPortal.qaFlags'),
    g2nVariances: t('routes.titles.icpPortal.g2nVariances'),
    g2nVariancesDetails: t('routes.titles.icpPortal.g2nVariancesDetails'),
    verifyExpenses: t('routes.titles.icpPortal.verifyExpenses'),
    reviewExpense: t('routes.titles.icpPortal.reviewExpense'),
  },
  atsNewHires: t('routes.titles.main.atsNewHires'),
  integrations: {
    unsyncedInvoices: t('routes.titles.integrations.unsyncedInvoices'),
    failedInvoices: t('routes.titles.integrations.failedInvoices'),
    settings: t('routes.titles.integrations.settings'),
    new: t('routes.titles.integrations.new'),
    addIntegration: t('routes.titles.integrations.addIntegration'),
    completeIntegration: t('routes.titles.integrations.completeIntegration'),
    integrationCreadentials: t('routes.titles.integrations.integrationCreadentials'),
    integrationAbout: t('routes.titles.integrations.integrationAbout'),
    integrationAboutLever: t('routes.titles.integrations.integrationAboutLever'),
    integrationAboutSlack: t('routes.titles.integrations.integrationAboutSlack'),
    integrationAboutQuickbooks: t('routes.titles.integrations.integrationAboutQuickbooks'),
    integrationEventLogs: t('routes.titles.integrations.integrationEventLogs'),
    advancedSettings: t('routes.titles.integrations.advancedSettings'),
    autoLink: t('routes.titles.integrations.autoLink'),
    teamAccounts: t('routes.titles.integrations.teamAccounts'),
    accountsMapping: t('routes.titles.integrations.accountsMapping'),
    peopleMapping: t('routes.titles.integrations.peopleMapping'),
    generalLedgerSyncSettings: t('routes.titles.integrations.generalLedgerSyncSettings'),
    bamboohr: t('routes.titles.integrations.bamboohr'),
    workday: t('routes.titles.integrations.workday'),
    xero: t('routes.titles.integrations.xero'),
    ['xero_v2']: t('routes.titles.integrations.xero_v2'),
    quickbooks: t('routes.titles.integrations.quickbooks'),
    sage: t('routes.titles.integrations.sage'),
    netsuiteJournalCreation: t('routes.titles.integrations.netsuiteJournalCreation'),
    netsuite: t('routes.titles.integrations.netsuite'),
    slack: {
      base: t('routes.titles.integrations.slack.base'),
      plugins: t('routes.titles.integrations.slack.plugins'),
      peopleMatch: {
        unmatched: t('routes.titles.integrations.slack.peopleMatch.unmatched'),
      },
    },
    expensify: t('routes.titles.integrations.expensify'),
    skovik: t('routes.titles.integrations.skovik'),
    hibob: t('routes.titles.integrations.hibob'),
    ashby: t('routes.titles.integrations.ashby'),
    greenhouse: t('routes.titles.integrations.greenhouse'),
    lever: t('routes.titles.integrations.lever'),
    ukg: t('routes.titles.integrations.ukg'),
    ukg_ready: t('routes.titles.integrations.ukg_ready'),
    hris_okta: t('routes.titles.integrations.hris_okta'),
    disconnected: t('routes.titles.integrations.disconnected'),
    jumpcloud: t('routes.titles.integrations.jumpcloud'),
    personio: t('routes.titles.integrations.personio'),
    sap: t('routes.titles.integrations.sap'),
    workable: t('routes.titles.integrations.workable'),
    fieldMappingPluginMappingPage: t('routes.titles.integrations.fieldMappingPluginMappingPage'),
    integrationOauthSetupFinish: t('routes.titles.integrations.integrationOauthSetupFinish'),
    integrationDetails: t('routes.titles.integrations.integrationDetails'),
    connectToIntegration: t('routes.titles.integrations.connectToIntegration'),
    integrationSettings: t('routes.titles.integrations.integrationSettings'),
    integrationSyncHistory: t('routes.titles.integrations.integrationSyncHistory'),
    integrationPluginsAndSettings: t('routes.titles.integrations.integrationPluginsAndSettings'),
    integrationSchemaId: t('routes.titles.integrations.integrationSchemaId'),
    peopleMatch: t('routes.titles.integrations.peopleMatch'),
    gpPeopleSync: t('routes.titles.integrations.gpPeopleSync'),
    gpG2NSync: t('routes.titles.integrations.gpG2NSync'),
    sftpConnectors: {
      details: t('routes.titles.integrations.sftpConnectors.details'),
      newConnection: t('routes.titles.integrations.sftpConnectors.newConnection'),
      editConnection: t('routes.titles.integrations.sftpConnectors.editConnection'),
    },
    multiStepSetupFlow: t('routes.titles.integrations.multiStepSetupFlow'),
    gpDownloadCsv: t('routes.titles.integrations.gpDownloadCsv'),
  },
  netsuite: {
    users: t('routes.titles.netsuite.users'),
    unsyncedInvoices: t('routes.titles.netsuite.unsyncedInvoices'),
    failedInvoices: t('routes.titles.netsuite.failedInvoices'),
    settings: t('routes.titles.netsuite.settings'),
    bookkepingModules: t('routes.titles.netsuite.bookkepingModules'),
    journalCreation: t('routes.titles.netsuite.journalCreation'),
    new: t('routes.titles.netsuite.new'),
    advancedSettings: t('routes.titles.netsuite.advancedSettings'),
    autoLink: t('routes.titles.netsuite.autoLink'),
    teamAccounts: t('routes.titles.netsuite.teamAccounts'),
    accountsMapping: t('routes.titles.netsuite.accountsMapping'),
    trackingCategoryMapping: t('routes.titles.netsuite.trackingCategoryMapping'),
  },
  xero: {
    users: t('routes.titles.xero.users'),
    unsyncedInvoices: t('routes.titles.xero.unsyncedInvoices'),
    failedInvoices: t('routes.titles.xero.failedInvoices'),
    settings: t('routes.titles.xero.settings'),
    new: t('routes.titles.xero.new'),
    advancedSettings: t('routes.titles.xero.advancedSettings'),
    autoLink: t('routes.titles.xero.autoLink'),
    teamAccounts: t('routes.titles.xero.teamAccounts'),
    accountsMapping: t('routes.titles.xero.accountsMapping'),
  },
  quickbooks: {
    users: t('routes.titles.quickbooks.users'),
    unsyncedInvoices: t('routes.titles.quickbooks.unsyncedInvoices'),
    failedInvoices: t('routes.titles.quickbooks.failedInvoices'),
    settings: t('routes.titles.quickbooks.settings'),
    new: t('routes.titles.quickbooks.new'),
    advancedSettings: t('routes.titles.quickbooks.advancedSettings'),
    autoLink: t('routes.titles.quickbooks.autoLink'),
    teamAccounts: t('routes.titles.quickbooks.teamAccounts'),
    accountsMapping: t('routes.titles.quickbooks.accountsMapping'),
  },
  hr: {
    users: t('routes.titles.hr.users'),
    settings: t('routes.titles.hr.settings'),
    plugins: t('routes.titles.hr.plugins'),
    errors: t('routes.titles.hr.errors'),
    new: t('routes.titles.hr.new'),
    enablePlugin: t('routes.titles.hr.enablePlugin'),
    typeMapping: t('routes.titles.hr.typeMapping'),
  },
  lever: {
    settings: t('routes.titles.lever.settings'),
  },
  expensify: {
    settings: t('routes.titles.expensify.settings'),
    connection: t('routes.titles.expensify.connection'),
  },
  workday: {
    plugins: t('routes.titles.workday.plugins'),
    new: t('routes.titles.workday.new'),
    terms: t('routes.titles.workday.terms'),
    enablePlugin: t('routes.titles.workday.enablePlugin'),
  },
  workable: {
    settings: t('routes.titles.workable.settings'),
  },
  provisioning: {
    scim: t('routes.titles.provisioning.scim'),
    scimSetup: t('routes.titles.provisioning.scimSetup'),
  },
  approveReports: {
    main: t('routes.titles.approveReports.main'),
    selectType: t('routes.titles.approveReports.selectType'),
    hris: t('routes.titles.approveReports.hris'),
  },
  globalPayroll: {
    mainOld: t('routes.titles.globalPayroll.mainOld'),
    main: t('routes.titles.globalPayroll.main'),
    createOffcyclePayrollEvent: t('routes.titles.globalPayroll.createOffcyclePayrollEvent'),
    editOffcyclePayrollEvent: t('routes.titles.globalPayroll.editOffcyclePayrollEvent'),
    payrollEventOld: t('routes.titles.globalPayroll.payrollEventOld'),
    payrollEventGeneral: t('routes.titles.globalPayroll.payrollEventGeneral'),
    payrollEventReport: t('routes.titles.globalPayroll.payrollEventReport'),
    payrollEventPayroll: t('routes.titles.globalPayroll.payrollEventPayroll'),
    payrollEventCorrection: t('routes.titles.globalPayroll.payrollEventCorrection'),
    payrollEventPayment: t('routes.titles.globalPayroll.payrollEventPayment'),
    payrollEventG2N: t('routes.titles.globalPayroll.payrollEventG2N'),
    payrollEventPreview: t('routes.titles.globalPayroll.payrollEventPreview'),
    payrollEventIssues: t('routes.titles.globalPayroll.payrollEventIssues'),
    payrollEventInputs: t('routes.titles.globalPayroll.payrollEventInputs'),
    clientGpG2NUpload: t('routes.titles.globalPayroll.clientGpG2NUpload'),
    payrollEventDocuments: t('routes.titles.globalPayroll.payrollEventDocuments'),
    payrollEventPayslips: t('routes.titles.globalPayroll.payrollEventPayslips'),
    payrollGLReport: t('routes.titles.globalPayroll.payrollGLReport'),
    payrollSupport: t('routes.titles.globalPayroll.payrollSupport'),
  },
  appStore: {
    main: t('routes.titles.appStore.main'),
    roots: t('routes.titles.appStore.plugins'),
  },
  insurance: t('routes.titles.main.insurance'),
  appsAndIntegrations: {
    main: t('routes.titles.appsAndIntegrations.main'),
    integrationsStore: t('routes.titles.appsAndIntegrations.integrationsStore'),
    connectedApps: t('routes.titles.appsAndIntegrations.connectedApps'),
    developerCenter: t('routes.titles.appsAndIntegrations.developerCenter'),
    developerCenterApps: t('routes.titles.appsAndIntegrations.developerCenterApps'),
    deelApi: t('routes.titles.appsAndIntegrations.deelApi'),
    deelWebhooks: t('routes.titles.appsAndIntegrations.deelWebhooks'),
    deelWebhooksDetail: t('routes.titles.appsAndIntegrations.deelWebhooksDetail'),
    addDeelWebhook: t('routes.titles.appsAndIntegrations.addDeelWebhook'),
    oauthAuthorization: t('routes.titles.appsAndIntegrations.oauthAuthorization'),
    appPublish: t('routes.titles.appsAndIntegrations.appPublish'),
    survey: t('routes.titles.appsAndIntegrations.survey'),
  },
  appsAndPerks: {
    main: t('routes.titles.appsAndPerks.main'),
  },
  emailExpired: t('routes.titles.main.emailExpired'),
  serviceRequests: t('routes.titles.main.serviceRequests'),
  employeeAgreementChanges: t('routes.titles.main.employeeAgreementChanges'),
  requestChanges: t('routes.titles.main.requestChanges'),
  reviewAmendments: t('routes.titles.main.reviewAmendments'),
  employeeVerificationCustomRequest: t('routes.titles.main.employeeVerificationCustomRequest'),
  profile: {
    main: t('routes.titles.profile.main'),
    organization: t('routes.titles.profile.organization'),
    contract: t('routes.titles.profile.contract'),
    contracts: t('routes.titles.profile.contracts'),
    personalInformation: t('routes.titles.profile.personalInformation'),
    timeOffDetails: t('routes.titles.profile.timeOffDetails'),
    supplementaryInformation: t('routes.titles.profile.supplementaryInformation'),
    timeOffEmployeeHistory: t('routes.titles.profile.timeOffEmployeeHistory'),
    timeOff: t('routes.titles.profile.timeOff'),
    timeDetails: t('routes.titles.profile.timeDetails'),
    timeOffHistory: t('routes.titles.profile.timeOffHistory'),
    timeOffEditBalance: t('routes.titles.profile.timeOffEditBalance'),
    documents: t('routes.titles.profile.documents'),
    payments: t('routes.titles.profile.payments'),
    paymentDetails: t('routes.titles.profile.paymentDetails'),
    expensesAndClaims: t('routes.titles.profile.expensesAndClaims'),
    expensesAndClaimsDetails: t('routes.titles.profile.expensesAndClaimsDetails'),
    equipment: t('routes.titles.profile.equipment'),
    travelInsurance: t('routes.titles.profile.travelInsurance'),
    equity: t('routes.titles.profile.equity'),
    equipmentDetails: t('routes.titles.profile.equipmentDetails'),
    coworking: t('routes.titles.profile.coworking'),
    coworkingDetails: t('routes.titles.profile.coworkingDetails'),
    payslipsDetails: t('routes.titles.profile.payslipsDetails'),
    payslips: t('routes.titles.profile.payslips'),
    taxDocumentsDetails: t('routes.titles.profile.taxDocumentsDetails'),
    taxDocuments: t('routes.titles.profile.taxDocuments'),
    benefitsDetails: t('routes.titles.profile.benefitsDetails'),
    benefits: t('routes.titles.profile.benefits'),
    odpOverviewsDetails: t('routes.titles.profile.odpOverviewsDetails'),
    odpOverviews: t('routes.titles.profile.odpOverviews'),
    edit: t('routes.titles.profile.edit'),
    reviewCycles: {
      main: t('routes.titles.profile.reviewCycles.main'),
      reviewCycle: t('routes.titles.profile.reviewCycles.reviewCycle'),
    },
    goals: t('routes.titles.profile.goals'),
  },
  editEmployeeData: t('routes.titles.main.editEmployeeData'),
  visaAssessmentAdditionalQuestions: t('routes.titles.main.visaAssessmentAdditionalQuestions'),
  employeeVerificationLetter: t('routes.titles.main.employeeVerificationLetter'),
  workflows: {
    list: t('routes.titles.workflows.list'),
    create: t('routes.titles.workflows.create'),
    view: t('routes.titles.workflows.view'),
    edit: t('routes.titles.workflows.edit'),
    history: t('routes.titles.workflows.history'),
    historyDetails: t('routes.titles.workflows.historyDetails'),
    templates: t('routes.titles.workflows.templates'),
    goToHub: t('routes.titles.workflows.goToHub'),
  },
  createNDAForContract: t('routes.titles.main.createNDAForContract'),
  visaSponsorship: {
    main: t('routes.titles.visaSponsorship.main'),
    entities: t('routes.titles.visaSponsorship.entities'),
    sponsorshipDetails: t('routes.titles.visaSponsorship.sponsorshipDetails'),
  },
  insights: {
    main: t('routes.titles.insights.main'),
    ai: t('routes.titles.insights.ai'),
  },
  germanTaxesSubscription: t('routes.titles.main.germanTaxesSubscription'),
  peoAdditionalDetails: t('routes.titles.main.peoAdditionalDetails'),
  permissionsCheck: t('routes.titles.main.permissionsCheck'),
  representativeVerification: {
    code: t('routes.titles.representativeVerification.code'),
    success: t('routes.titles.representativeVerification.success'),
  },
  employeeEntityTransfer: {
    main: t('routes.titles.employeeEntityTransfer.main'),
    newEntityDetails: t('routes.titles.employeeEntityTransfer.newEntityDetails'),
    reviewTransferDetails: t('routes.titles.employeeEntityTransfer.reviewTransferDetails'),
  },
  proofOfPayment: t('routes.titles.main.proofOfPayment'),
  benefits: {
    tools: {
      main: t('routes.titles.benefits.tools.main'),
      fallback: t('routes.titles.benefits.tools.fallback'),
      plan: {
        main: t('routes.titles.benefits.tools.plan.main'),
        edit: t('routes.titles.benefits.tools.plan.edit'),
      },
    },
    app: {
      main: t('routes.titles.benefits.app.main'),
    },
  },
  msaContract: {
    main: t('routes.titles.msaContract.main'),
  },
  csaContract: {
    main: t('routes.titles.csaContract.main'),
  },
  calendar: t('routes.titles.main.calendar'),
  getStarted: {
    deelHR: t('routes.titles.getStarted.deelHR'),
  },
  externalClientSignature: {
    main: t('routes.titles.externalClientSignature.main'),
    signing: t('routes.titles.externalClientSignature.signing'),
  },
  goals: t('routes.titles.main.goals'),
  shareFeedback: t('routes.titles.main.shareFeedback'),
  learning: {
    journeys: {
      list: t('routes.titles.learning.journeys.list'),
      assignments: t('routes.titles.learning.journeys.assignments'),
      automations: t('routes.titles.learning.journeys.automations'),
      view: t('routes.titles.learning.journeys.view'),
      preview: t('routes.titles.learning.journeys.preview'),
      previewDraft: t('routes.titles.learning.journeys.previewDraft'),
      editBuilder: t('routes.titles.learning.journeys.editBuilder'),
      editBuilderDraft: t('routes.titles.learning.journeys.editBuilderDraft'),
      actionableList: t('routes.titles.learning.journeys.actionableList'),
      actionableViewAssignee: t('routes.titles.learning.journeys.actionableViewAssignee'),
      actionableViewSpecificRecipient: t('routes.titles.learning.journeys.actionableViewSpecificRecipient'),
      actionableViewRedirect: t('routes.titles.learning.journeys.actionableViewRedirect'),
      scormReportRedirect: t('routes.titles.learning.journeys.scormReportRedirect'),
    },
  },
  surveys: {
    list: t('routes.titles.surveys.list'),
    create: t('routes.titles.surveys.create'),
    view: t('routes.titles.surveys.view'),
    overview: t('routes.titles.surveys.overview'),
    analytics: t('routes.titles.surveys.analytics'),
    actionPlans: t('routes.titles.surveys.actionPlans'),
    builder: t('routes.titles.surveys.builder'),
    surveyActive: {
      overview: t('routes.titles.surveys.surveyActive.overview'),
      results: t('routes.titles.surveys.surveyActive.results'),
      assignments: t('routes.titles.surveys.surveyActive.assignments'),
      automations: t('routes.titles.surveys.surveyActive.automations'),
      actionPlans: t('routes.titles.surveys.surveyActive.actionPlans'),
    },
  },
  approvalRequests: {
    main: t('routes.titles.approvalRequests.main'),
    dataChanges: t('routes.titles.approvalRequests.dataChanges'),
    timesheets: t('routes.titles.approvalRequests.timesheets'),
    timeOffs: t('routes.titles.approvalRequests.timeOffs'),
  },
  etlTemplate: {
    main: t('routes.titles.etlTemplate.main'),
  },
  login: {
    main: t('routes.titles.login.main'),
    okta: t('routes.titles.login.okta'),
    zendesk: t('routes.titles.login.zendesk'),
  },
  xeroSignup: t('routes.titles.main.xeroSignup'),
  resetPasswordToken: t('routes.titles.main.resetPasswordToken'),
  resetPasswordError: t('routes.titles.main.resetPasswordError'),
  nativeSSO: t('routes.titles.main.nativeSSO'),
  loginWithCode: t('routes.titles.main.loginWithCode'),
  nativeOnboarding: t('routes.titles.main.nativeOnboarding'),
  verifyBackupEmail: t('routes.titles.main.verifyBackupEmail'),
  createProfileToken: t('routes.titles.main.createProfileToken'),
  notContractMember: t('routes.titles.main.notContractMember'),
  eorShieldMSA: {
    main: t('routes.titles.eorShieldMSA.main'),
    details: t('routes.titles.eorShieldMSA.details'),
    shieldInvoice: t('routes.titles.eorShieldMSA.shieldInvoice'),
  },
  vendorManagement: {
    requisitions: {
      main: t('routes.titles.vendorManagement.requisitions.main'),
      viewDetailed: t('routes.titles.vendorManagement.requisitions.viewDetailed'),
      viewDetailedHistory: t('routes.titles.vendorManagement.requisitions.viewDetailedHistory'),
      viewCandidate: t('routes.titles.vendorManagement.requisitions.viewCandidate'),
      viewCandidateHistory: t('routes.titles.vendorManagement.requisitions.viewCandidateHistory'),
      edit: t('routes.titles.vendorManagement.requisitions.edit'),
      create: t('routes.titles.vendorManagement.requisitions.create'),
      candidateSubmit: t('routes.titles.vendorManagement.requisitions.candidateSubmit'),
    },
    talentProfiles: {
      main: t('routes.titles.vendorManagement.talentProfiles.main'),
      viewDetailed: t('routes.titles.vendorManagement.talentProfiles.viewDetailed'),
    },
    talentPartners: {
      main: t('routes.titles.vendorManagement.talentPartners.main'),
      view: t('routes.titles.vendorManagement.talentPartners.view'),
    },
    pendingTasks: t('routes.titles.vendorManagement.pendingTasks'),
    clients: {
      main: t('routes.titles.vendorManagement.clients.main'),
      view: t('routes.titles.vendorManagement.clients.view'),
    },
    vendor: {
      organizationDetails: t('routes.titles.vendorManagement.vendor.organizationDetails'),
    },
  },
  deelIT: {
    main: t('deelIT.navigation.main'),
    catalogue: {
      main: t('deelIT.navigation.subMenuItems.catalogue'),
      viewDetailed: t('deelIT.navigation.subMenuItems.catalogue'),
    },
    orders: {
      main: t('deelIT.navigation.subMenuItems.orders'),
      details: t('deelIT.navigation.subMenuItems.orders'),
      create: t('deelIT.navigation.subMenuItems.orders'),
      checkout: t('deelIT.navigation.subMenuItems.orders'),
      createDetails: t('deelIT.navigation.subMenuItems.orders'),
    },
    assets: {
      list: t('deelIT.navigation.subMenuItems.assets'),
      details: t('deelIT.navigation.subMenuItems.assets'),
      detailsTab: t('deelIT.navigation.subMenuItems.assets'),
      create: t('deelIT.navigation.subMenuItems.assets'),
    },
    people: {
      main: t('deelIT.navigation.subMenuItems.people'),
    },
    finance: {
      main: t('deelIT.navigation.subMenuItems.finance'),
      payNow: t('deelIT.navigation.subMenuItems.finance'),
      billing: t('deelIT.navigation.subMenuItems.finance'),
    },
    services: {
      main: t('deelIT.navigation.subMenuItems.services'),
    },
    policies: {
      list: t('deelIT.navigation.subMenuItems.policies'),
      viewDetailed: t('deelIT.navigation.subMenuItems.policies'),
      viewDetailedTab: t('deelIT.navigation.subMenuItems.policies'),
    },
  },
  forms: {
    fillForm: t('routes.titles.forms.fillForm'),
  },
  knowledgeHub: {
    main: t('routes.titles.knowledgeHub.main'),
    search: t('routes.titles.knowledgeHub.search'),
    saved: t('routes.titles.knowledgeHub.saved'),
    article: t('routes.titles.knowledgeHub.article'),
  },
};
