import React from 'react';
import ErrorGraphic from '@letsdeel/ui/dist/assets/illustrations/base/info-error.svg?react';
import { useTranslation } from 'react-i18next';
import { NativeDialogContainer } from '@/native/NativeDialogContainer';
import './OfflineErrorPage.less';

export const OfflineErrorPage = () => {
  const onRetryClick = () => window.location.reload();
  const { t } = useTranslation();

  return (
    <div className="offline-container">
      <NativeDialogContainer
        graphic={<ErrorGraphic className="info-icon" />}
        contentClassName={'offline-content'}
        title={t('banners.network.errorPage.title')}
        subtitle={t('banners.network.errorPage.content')}
        primaryButton={{
          onClick: onRetryClick,
          text: t('banners.network.errorPage.buttons.retry'),
        }}
      />
    </div>
  );
};
