import { HomeBackendFeatureFlags } from '@/constants/featureFlags/home';
import { useHomeFeatureFlag } from './useHomeFeatureFlag';
import { StorageKeysEnum, useFetchDashboardStorage } from '@/hooks/api/dashboard/home/storage';
import useUserStore from '@/hooks/useUserStore';
import { useIsBottomNavigationEnabled } from '@/hooks/native/useIsBottomNavigationEnabled';
import useLookupsStore from '../useLookupsStore';

export const useCheckIfShouldShowTopNav = () => {
  const lookups = useLookupsStore();
  const isBottomNavEnabled = useIsBottomNavigationEnabled();
  const user = useUserStore();
  const optOutFeatureFlag = lookups.getFeatureFlag('home.topNavOptOut');

  const shouldShowNavSwitchBar = useHomeFeatureFlag(HomeBackendFeatureFlags.TOP_NAV_SWITCH_BAR);
  const { data: topNavSwitchBarConfig } = useFetchDashboardStorage(
    StorageKeysEnum.TOP_NAV_SWITCH_BAR,
    user?.isLoggedIn && shouldShowNavSwitchBar
  );
  const isFFEnabled = useHomeFeatureFlag(HomeBackendFeatureFlags.TOP_NAVIGATION);

  if (optOutFeatureFlag && optOutFeatureFlag.orgs && optOutFeatureFlag.orgs.length > 0) {
    if (
      user.organizations &&
      user.organizations.some((org) => optOutFeatureFlag.orgs.includes(org.organizationPublicId))
    ) {
      return false;
    }
  }

  if (isBottomNavEnabled) return user?.isLoggedIn;
  if (shouldShowNavSwitchBar) {
    return isFFEnabled && (topNavSwitchBarConfig?.value?.visible || topNavSwitchBarConfig?.value === null);
  }

  return isFFEnabled;
};
