import type { ReactNode, Dispatch, SetStateAction } from 'react';
import { createContext, useState, useEffect } from 'react';
import useUserStore from '@/hooks/useUserStore';
import { useCheckIfHideSensitiveInfoEnabled } from '@/hooks/home/useCheckIfHideSensitiveInfoEnabled';

export interface ISensitiveDataContext {
  isSensitiveDataHidden: boolean;
  setSensitiveDataVisibility: Dispatch<SetStateAction<boolean>>;
}

export const SensitiveDataContext = createContext<ISensitiveDataContext>({
  isSensitiveDataHidden: false,
  setSensitiveDataVisibility: () => {},
});

export const SensitiveDataProvider = ({ children }: { children: ReactNode }) => {
  const user = useUserStore();
  const [isInit, setIsInit] = useState<boolean>(false);
  const isHideSensitiveInfoFeatureEnabled = useCheckIfHideSensitiveInfoEnabled();
  const [isSensitiveDataHidden, setSensitiveDataVisibility] = useState<boolean>(false);

  useEffect(() => {
    if (isHideSensitiveInfoFeatureEnabled && !isInit) {
      setSensitiveDataVisibility(user.isFinancialDataHidden);
      setIsInit(true);
    }
  }, [isInit, isHideSensitiveInfoFeatureEnabled, user.isFinancialDataHidden]);

  return (
    <SensitiveDataContext.Provider
      value={{
        isSensitiveDataHidden,
        setSensitiveDataVisibility,
      }}
    >
      {children}
    </SensitiveDataContext.Provider>
  );
};
