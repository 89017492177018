import type { Employment } from '@/types/Employment';
import type { Seniority } from '@/utils/contract/globalPayrollContract';
import instance from '@/utils/api/instance';
import type { OvertimeStatus } from '@/scenes/Create/components/DirectEmployeeComponents/types';

const BASE_URL = 'global_payroll/gp_details';
const BASE_DATA_CHANGES_URL = 'global_payroll/contract_data_changes';
export type UpdateContractDetails = {
  startDate?: string;
  deelStartDate?: string;
  originalStartDate?: string | null;
  email?: string;
  workVisa?: Boolean;
  employeeNumber?: string | null;
};

export default {
  resendInvitation: async (contractId: number): Promise<void> => {
    return instance.post(`${BASE_URL}/${contractId}/invite_employee`);
  },

  updateContractDetails: async (contractId: number, payload: UpdateContractDetails): Promise<void> => {
    return instance.patch(`${BASE_URL}/${contractId}/update`, payload);
  },

  updateContractPTODetails: async (contractId: number, payload: Record<string, any>): Promise<void> => {
    return instance.patch(`${BASE_URL}/${contractId}/update_contract_pto`, payload);
  },

  updateCompensation: async (payload: {
    contractOid: string;
    effectiveDate: string;
    changeData: { rate: number; scale: string };
  }): Promise<Employment> => {
    const { data } = await instance.post(`${BASE_DATA_CHANGES_URL}/salary`, payload);
    return data;
  },

  updateOvertime: async (payload: {
    contractOid: string;
    effectiveDate: string;
    changeData: { overtimeStatus: OvertimeStatus };
  }): Promise<void> => {
    await instance.post(`${BASE_DATA_CHANGES_URL}/overtime`, payload);
  },

  deleteCompensation: async (dataChangeId: string): Promise<void> => {
    const { data } = await instance.delete(`${BASE_DATA_CHANGES_URL}/${dataChangeId}`);
    return data;
  },

  updateEmploymentStatus: async (contractId: number, payload: Record<string, any>): Promise<Employment> => {
    const { data } = await instance.post(`${BASE_URL}/${contractId}/create_employment_status`, payload);
    return data;
  },

  deleteEmploymentStatus: async (contractId: number, termId: string): Promise<void> => {
    return instance.delete(`global_payroll/employment_status/${contractId}/${termId}`);
  },

  updateJobInformation: async (contractId: number, payload: Record<string, any>): Promise<Employment> => {
    const { data } = await instance.post(`${BASE_URL}/${contractId}/create_job_information`, payload);
    return data;
  },

  updateRoleDetails: async (contractId: number, payload: Record<string, any>): Promise<Seniority> => {
    const { data } = await instance.patch(`${BASE_URL}/${contractId}/role_details`, payload);
    return data?.seniority?.id && data?.seniority?.name ? data?.seniority : null;
  },

  deleteJobInformation: async (contractId: number, termId: string): Promise<void> => {
    return instance.delete(`global_payroll/job_information/${contractId}/${termId}`);
  },
};
