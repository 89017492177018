export const DAYS_OF_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const MILLISECONDS_IN_MINUTE = 60000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const DAYS_IN_WEEK = 7;
export const DAYS_IN_MONTH = 30;
export const MONTHS_IN_YEAR = 12;

export const ONE_SECOND = 1000;
export const ONE_MINUTE = ONE_SECOND * SECONDS_IN_MINUTE;
export const ONE_HOUR = ONE_MINUTE * MINUTES_IN_HOUR;
export const ONE_DAY = ONE_HOUR * HOURS_IN_DAY;
export const ONE_WEEK = ONE_DAY * DAYS_IN_WEEK;
export const ONE_MONTH = ONE_DAY * DAYS_IN_MONTH;
