import type { InviteEmailType } from '@/components/SelectInviteEmailType/SelectInviteEmailType';
import instance from '@/utils/api/instance';

export const BASE_URL = 'global_payroll';

export interface GPMassInviteRequest {
  contractOids: string[];
  invitationEmailType: InviteEmailType;
}

export interface GPMassInviteResponse {
  totalInvites: number;
  result: Record<string, boolean>;
}

export default {
  sendInvitation: async (request: GPMassInviteRequest): Promise<GPMassInviteResponse> => {
    const { data } = await instance.post(`${BASE_URL}/mass_invite`, request);
    return data;
  },
};
