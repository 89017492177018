import useFetch from '@/hooks/api/useFetch';
import type { UnisureEnrollmentFormInfo } from '@/utils/api/benefits/healthInsurance';

interface UseFetchUnisureEnrollmentArgs {
  contractOid?: string;
}

export const useFetchUnisureEnrollment = ({ contractOid }: UseFetchUnisureEnrollmentArgs = {}) => {
  const { data, isValidating, error } = useFetch<UnisureEnrollmentFormInfo>(
    contractOid ? `/eor/integrations/unisure/register-member/${contractOid}` : null
  );

  return {
    data,
    isValidating,
    error,
  };
};
