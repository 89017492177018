import type { ReactNode } from 'react';
import React from 'react';
import { cn } from '../../utils/main';

interface Props {
  className?: string;
  children: ReactNode;
  [p: string]: any;
}

const Flex = React.forwardRef<HTMLDivElement, Props>(({ children, className, ...rest }, ref) => (
  <div ref={ref} className={cn('flex', className)} {...rest}>
    {children}
  </div>
));

export default Flex;
