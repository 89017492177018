import type { SWRConfiguration, MutatorCallback, Key, Fetcher, MutatorOptions } from 'swr';
import useSWR from 'swr';
import instance from '@/utils/api/instance';

export interface ApiResponse<T> {
  data: T | undefined;
  error?: any;
  mutate: (
    data?: T | Promise<T> | MutatorCallback<T> | undefined,
    opts?: boolean | MutatorOptions<T>
  ) => Promise<T | undefined>;
  isValidating: boolean;
  isLoading: boolean;
}

export const defaultFetcher = (url: string) => {
  return instance.get(url, { suppressSnackbar: true }).then((res) => res.data);
};

export const getComponentStack = (): string => {
  try {
    throw new Error();
  } catch (error) {
    return (
      (error as Error).stack
        ?.split('\n')
        .slice(2, 5) // Capture only the first 3 relevant stack frames
        .reduce((acc, line) => {
          const match = line.match(/at\s+(.*?)(\s|\()|at\s+(.*?)(:\d+:\d+)/);
          const name = match ? match[1] || match[3] : null;
          if (name && !name.includes('node_modules')) {
            acc.push(name);
          }
          return acc;
        }, [] as string[])
        .join(' -> ') || 'unknown'
    );
  }
};
/**
 *
 * Enables tracking of referrer component for API calls on datadog
 */
export const fetcherWithReferrer = (componentName: string) => (url: string) =>
  instance.get(url, { suppressSnackbar: true, headers: { 'x-react-referrer': componentName } }).then((res) => res.data);

export const fetcherWithStack = (componentName?: string) => (url: string) => {
  const componentStack = getComponentStack();
  return instance
    .get(url, {
      suppressSnackbar: true,
      headers: {
        ...(componentName ? { 'x-react-compontn': componentName } : {}),
        'x-react-stack': componentStack,
        'x-react-pathname': window.location.href,
      },
    })
    .then((res) => res.data);
};

const useFetch = <T, FetcherKey extends Key = Key>(
  url: FetcherKey | null,
  options: SWRConfiguration = {},
  fetcher: Fetcher<T, FetcherKey> = defaultFetcher as Fetcher<T, FetcherKey>
): ApiResponse<T> => {
  const { data, error, mutate, isValidating, isLoading } = useSWR<T>(url, fetcher, {
    dedupingInterval: 60 * 60 * 1000,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    ...options,
  });

  return { data, error, mutate, isValidating, isLoading };
};

export const useFetchWithHeaders = <T, FetcherKey extends string = string>(
  url: FetcherKey | null,
  headers: Record<string, string> = {},
  options: SWRConfiguration = {}
): ApiResponse<T> => {
  const fetcherWithHeaders = async (fetcherKey: string): Promise<T> => {
    const response = await instance.get(fetcherKey, {
      suppressSnackbar: true,
      headers: {
        ...headers,
        'x-react-stack': getComponentStack(),
        'x-react-pathname': window.location.href,
      },
    });
    if (!response.status || response.status >= 400) {
      throw new Error('An error occurred while fetching the data.');
    }
    return response.data as T;
  };

  return useFetch<T, FetcherKey>(url, options, fetcherWithHeaders as Fetcher<T, FetcherKey>);
};

export default useFetch;
