import type { FormikHelpers } from 'formik';
import { useFormik } from 'formik';
import React, { useMemo, useRef, useState } from 'react';
import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { getPasswordSchema } from '@/components/PasswordPolicy/helpers';
import userApi from '@/utils/api/user';
import { Alert, Button, Icon, Link, Stack, TextField, VStack, useSnackbar, H2, H3, H6, P, MuiBox } from '@letsdeel/ui';
import PasswordPolicy from '@/components/PasswordPolicy';
import useUserStore from '@/hooks/useUserStore';
import Graphic from '@/components/Graphic';
import OtpPopup from '@/components/OtpPopup/OtpPopup';
import Popup from '@/components/Popup';
import { PASSWORD_POLICY_LIST } from '@/components/PasswordPolicy/helpers';
import PartiallyScrollingPopup from '@/components/PartiallyScrollingPopup';
import axios from 'axios';
import { parseJwt } from '@/utils/main';
import { useHistory } from 'react-router-dom';
import ResetPassword from '@/scenes/ResetPassword/ResetPassword';

enum Steps {
  PasswordChange,
  TwoFA,
  Success,
  ResetPasswordSend,
}

interface Props {
  show: boolean;
  onHide: () => void;
  afterSuccess?: () => void;
  passwordRotationToken?: string;
}

interface ChangePasswordValues {
  currentPassword: string;
  newPassword: string;
}

const ChangePasswordPopup = ({ show = true, onHide, passwordRotationToken, afterSuccess }: Props) => {
  const { t } = useTranslation();
  const user = useUserStore();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState<Steps>(Steps.PasswordChange);
  const [isShowingPassword, setIsShowingPassword] = useState(false);
  const currentPasswordRef = useRef('');
  const newPasswordRef = useRef('');
  const { showSnackbar } = useSnackbar();

  const daysToExpire = useMemo(() => {
    const defaultDaysToExpire = 90;
    if (!passwordRotationToken) return defaultDaysToExpire;
    return parseJwt(passwordRotationToken)?.daysToExpire ?? defaultDaysToExpire;
  }, [passwordRotationToken]);

  const checkCurrentPassword = async (
    currentPassword: string,
    setErrors: FormikHelpers<ChangePasswordValues>['setErrors']
  ) => {
    try {
      await userApi.login({
        email: user.email,
        password: currentPassword,
        dryRun: true,
      });
      setActiveStep(Steps.PasswordChange);
      return true;
    } catch {
      setErrors({ currentPassword: t('settings.security.changePassword.incorrectPassword') });
      return false;
    }
  };

  const rotatePassword = async (
    { currentPassword, newPassword, passwordRotationToken }: ChangePasswordValues & { passwordRotationToken: string },
    setErrors: FormikHelpers<ChangePasswordValues>['setErrors']
  ) => {
    try {
      await userApi.passwordRotate({ currentPassword, newPassword, passwordRotationToken });
      afterSuccess?.();
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          showSnackbar('Session expired', 'error');
          onHide();
        } else if (error.response?.status === 400) {
          if (
            error?.response?.data?.code === 'INVALID_PASSWORD' ||
            error?.response?.data?.errors?.some(({ key }: { key: string }) => key === 'currentPassword')
          ) {
            setErrors({ currentPassword: t('settings.security.changePassword.currentPasswordWrong') });
          } else if (error?.response?.data?.code === 'RECENT_PASSWORD_USED') {
            setErrors({ newPassword: t('settings.security.changePassword.newPasswordIsSimilar') });
          }
        } else {
          console.error(error);
        }
      } else {
        console.error(error);
      }
    }
  };

  const changePassword = async (
    { currentPassword, newPassword }: ChangePasswordValues,
    setErrors: FormikHelpers<ChangePasswordValues>['setErrors']
  ) => {
    try {
      // validates new password
      await userApi.changePassword({ currentPassword, newPassword });
      afterSuccess?.();
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        if (error.code === 'auth/weak-password') {
          setErrors({ newPassword: error.message });
        } else if (error.response?.status === 403) {
          if (error?.response?.data?.code === '2FA_INVALID_CODE') {
            setActiveStep(Steps.TwoFA);
          } else {
            setErrors({ newPassword: t('settings.security.changePassword.currentPasswordWrong') });
          }
        } else if (error.response?.status === 409) {
          setErrors({ newPassword: t('settings.security.changePassword.newPasswordIsSimilar') });
        } else {
          console.error(error);
        }
      } else {
        console.error(error);
      }
    }
  };

  const changePwdFormik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: { currentPassword: '', newPassword: '' },
    validationSchema: Yup.object({
      currentPassword: Yup.string()
        .min(6, t('settings.security.changePassword.invalidPassword'))
        .required(t('settings.security.changePassword.currentPasswordRequired')),
      newPassword: getPasswordSchema({ email: user.email, prevPassword: currentPasswordRef.current }),
    }),
    onSubmit: async ({ currentPassword, newPassword }: ChangePasswordValues, { setErrors }) => {
      newPasswordRef.current = newPassword;
      if (passwordRotationToken) {
        await rotatePassword({ currentPassword, newPassword, passwordRotationToken }, setErrors);
      } else if (await checkCurrentPassword(currentPassword, setErrors)) {
        await changePassword({ currentPassword, newPassword }, setErrors);
      }
    },
  });

  const toggleShowPassword = () => setIsShowingPassword(!isShowingPassword);

  const handleResetPasswordLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (user?.id) {
      setActiveStep(Steps.ResetPasswordSend);
      e.preventDefault();
      e.stopPropagation();
    } else {
      history.push('/reset-password');
    }
  };

  const beforeHide = () => {
    onHide();
    setActiveStep(Steps.PasswordChange);
    changePwdFormik.resetForm();
    setIsShowingPassword(false);
  };

  const onSubmitTotp = async (totp: string, otpProvider: string) => {
    await userApi.changePassword({
      currentPassword: currentPasswordRef.current,
      newPassword: newPasswordRef.current,
      totp,
      otpProvider,
    });
  };

  const onTotpSuccess = () => {
    afterSuccess?.();
    setActiveStep(Steps.Success);
  };

  // Saving email value ref to validate that password value is not containing the user email
  const handleCurrentPasswordChange = (event: any) => {
    const { value } = event.target;

    currentPasswordRef.current = value;
    changePwdFormik.setFieldValue('currentPassword', value);
  };

  const disabled = !changePwdFormik.values.currentPassword || !changePwdFormik.values.newPassword;
  const isLoading = changePwdFormik.isSubmitting;

  const passwordPolicyList = useMemo(
    () => [
      ...PASSWORD_POLICY_LIST,
      {
        id: 'non_equal',
        label: t('temp.platform-fe.components.PasswordPolicy.mustNotBeTheSameAsPreviousPassword'),
        regex: new RegExp(
          `^((?!${changePwdFormik.values.currentPassword.replace(/([^0-9a-zA-Z])/g, '\\$1')}).)*$`,
          'i'
        ),
      },
    ],
    [changePwdFormik.values.currentPassword, t]
  );

  if (activeStep === Steps.ResetPasswordSend) {
    return (
      <PartiallyScrollingPopup
        show={show}
        onBack={() => setActiveStep(Steps.PasswordChange)}
        backButton={true}
        onHide={beforeHide}
        title={t('settings.security.resetPassword.title')}
      >
        <ResetPassword email={user.email} />
      </PartiallyScrollingPopup>
    );
  }

  return activeStep < Steps.TwoFA ? (
    <PartiallyScrollingPopup
      centered
      show={show}
      onHide={beforeHide}
      {...(passwordRotationToken
        ? {
            modalProps: {
              disableEscapeKeyDown: true,
              open: true,
            },
            disableBackdropClick: true,
          }
        : {})}
      title={t('settings.security.changePassword.title')}
      contentAfter={
        <VStack spacing={16} stretch sx={{ width: '100%', textAlign: 'center' }}>
          <Button
            fullWidth
            type={'submit'}
            onClick={changePwdFormik.submitForm}
            disabled={disabled}
            loading={isLoading}
            data-qa="changePasswordBtn"
          >
            {t('settings.security.changePassword.title')}
          </Button>
          <H6>
            {t('authentication.resetPassword.title')}{' '}
            <Link data-qa="resetPasswordLink" onClick={handleResetPasswordLinkClick}>
              {t('authentication.resetPassword.clickHereToReset')}
            </Link>
          </H6>
        </VStack>
      }
    >
      <Stack spacing={2}>
        {passwordRotationToken ? (
          <>
            <P>{t('settings.security.changePassword.daysSinceLastPasswordChange', { count: daysToExpire })}</P>
            <Alert severity="info">
              {t('settings.security.changePassword.passwordChangeInfo', { count: daysToExpire })}
            </Alert>
          </>
        ) : null}
        <TextField
          {...changePwdFormik.getFieldProps('currentPassword')}
          label={t('settings.security.changePassword.currentPasswordLabel')}
          error={!!changePwdFormik.errors.currentPassword}
          helperText={
            changePwdFormik.errors.currentPassword ? (
              <span data-qa="validationError">{changePwdFormik.errors.currentPassword}</span>
            ) : undefined
          }
          disabled={isLoading}
          onChange={handleCurrentPasswordChange}
          type={isShowingPassword ? 'text' : 'password'}
          endIcon={
            isShowingPassword ? (
              <Icon.Hide size={22} color="primary" onClick={toggleShowPassword} />
            ) : (
              <Icon.Show size={22} color="primary" onClick={toggleShowPassword} />
            )
          }
        />
        <TextField
          {...changePwdFormik.getFieldProps('newPassword')}
          error={!!changePwdFormik.errors.newPassword}
          helperText={
            changePwdFormik.errors.newPassword ? (
              <span data-qa="validationError">{t(changePwdFormik.errors.newPassword)}</span>
            ) : undefined
          }
          autoComplete="new-password"
          label={t('settings.security.changePassword.newPasswordLabel')}
          disabled={isLoading}
          type={isShowingPassword ? 'text' : 'password'}
          endIcon={
            isShowingPassword ? (
              <Icon.Hide size={22} color="primary" onClick={toggleShowPassword} />
            ) : (
              <Icon.Show size={22} color="primary" onClick={toggleShowPassword} />
            )
          }
        />
        <MuiBox padding={2} borderRadius={1} border="1px solid var(--dui-palette-neutral-light)">
          <PasswordPolicy
            hidePreviousPasswordsWarning
            password={changePwdFormik.values.newPassword}
            passwordPolicyList={passwordPolicyList}
            title={t('settings.security.changePassword.passwordPolicyTitle')}
          />
        </MuiBox>
      </Stack>
    </PartiallyScrollingPopup>
  ) : activeStep === Steps.TwoFA ? (
    <OtpPopup
      show
      centered
      backButton
      onBack={() => setActiveStep(activeStep - 1)}
      onSuccess={onTotpSuccess}
      onSubmit={onSubmitTotp}
      onHide={beforeHide}
    />
  ) : (
    <Popup show centered closeButton={false}>
      <div className="text-center">
        <Graphic type="success-blob" className="mb-7" />
        <H2 className="mb-4" data-qa="password-updated-modal-title">
          {t('settings.security.changePassword.passwordUpdated')}
        </H2>
        <H3 color="neutral.darker" className="mb-9">
          {t('settings.security.changePassword.activeSessionsInfo')}
        </H3>
        <Button onClick={beforeHide} data-qa="okbtn">
          {t('common.actions.ok')}
        </Button>
      </div>
    </Popup>
  );
};

export default ChangePasswordPopup;
