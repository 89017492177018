 
import type { PopupProps } from '@/components/Popup';
import Popup from '@/components/Popup';
import OtpPopupContent from '@/components/OtpPopupContent/OtpPopupContent';
import type { OtpPopupContentProps } from '@/components/OtpPopupContent/OtpPopupContent';
import { SafeAreaContainer } from '@/native/SafeAreaContainer';
import { Edges } from '@/types/Edges';

export type OtpPopupProps = OtpPopupContentProps & Omit<PopupProps, 'title' | 'subtitle' | 'children'>;

const OtpPopup = ({ show, centered, ...rest }: OtpPopupProps) => {
  return (
    <Popup show={show} centered={centered} closeButton={false} onHide={rest.onHide}>
      <SafeAreaContainer edges={[Edges.BOTTOM, Edges.TOP]}>
        <OtpPopupContent {...rest} />
      </SafeAreaContainer>
    </Popup>
  );
};

export default OtpPopup;
