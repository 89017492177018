import type { ComponentProps, ReactNode } from 'react';
import { Suspense } from 'react';
import { MuiBox } from '@letsdeel/ui';
import type { GetObjectKeys } from '@/types/utils';
import { graphics } from '../../constants/graphics';
import ErrorBoundary, { ErrorBoundaryOwners } from '../ErrorBoundary';

export type GraphicType = GetObjectKeys<typeof graphics>;

export type GraphicProps = {
  type: GraphicType;
  onError?: (error: Error) => void;
} & Omit<ComponentProps<typeof MuiBox>, 'type' | 'onError'>;

const names = Object.values(graphics).reduce(
  (names, items) => Object.assign(names, items),
  {} as Record<GraphicType, ReactNode>
);

const Graphic = ({ type, onError, ...props }: GraphicProps) => (
  <MuiBox {...props}>
    <ErrorBoundary owner={ErrorBoundaryOwners.unknown} fallback={null} onError={onError}>
      <Suspense fallback={null}>{names[type]}</Suspense>
    </ErrorBoundary>
  </MuiBox>
);

export default Graphic;
