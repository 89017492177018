import type { ReactNode } from 'react';
import { useEffect, useState, useRef } from 'react';
import type { SxProps } from '@letsdeel/ui';
import { MuiBox } from '@letsdeel/ui';

import './PopupStickyElement.less';

type PopupStickyElementProps = {
  children: ReactNode;
  isSticky?: boolean;
  isTop?: boolean;
  contentSx?: SxProps;
};

/**
 * @deprecated Use the Deel/ui Modal component instead
 */
const PopupStickyElement = ({ children }: PopupStickyElementProps) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const [_shadowTop, setShadowTop] = useState<number>();

  useEffect(() => {
    if (headerRef?.current) {
      setShadowTop(headerRef.current.offsetHeight - 18);
    }
  }, []);

  return <MuiBox sx={{ paddingBottom: 2, paddingTop: 1 }}>{children}</MuiBox>;
};

export default PopupStickyElement;
