import type { PayrollReportColumn } from '@/types/PayrollReportColumn';
import instance from '@/utils/api/instance';

export default {
  getExchangeRates: async () => {
    const { data } = await instance.get('commons/exchange_rates');
    return data;
  },

  requestDemo: async (payload: any) => {
    const data = await instance.post('/commons/request_demo', payload);
    return data;
  },

  fillBalance: async (payload: any) => {
    const { data } = await instance.post(`commons/refill_balance`, payload);
    return data;
  },

  fillClientBalance: async (payload: { organizationId: number; amount: number; currency: string }) => {
    const { data } = await instance.post(`commons/client_balances`, payload);
    return data;
  },

  getBiMonthlyCountries: async () => {
    try {
      // dont remove until bimonthly is in the static repo
      const { data } = await instance.get<string[]>(`commons/countries/biMonthly`);
      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  },

  getGlobalPayrollReportItems: async (): Promise<Array<PayrollReportColumn>> => {
    try {
      const { data } = await instance.get(`employment/payroll-report-columns`, {
        params: {
          sortBy: ['categoryOrder', 'subCategoryOrder', 'label'],
          excludeCategories: ['EOR'],
          supportedInPayrollReport: true,
          isGlobal: false,
        },
      });
      return data as Array<PayrollReportColumn>;
    } catch (e) {
      console.error(e);
      return [];
    }
  },
};
