import type { Address } from './Contract';

export enum EmploymentTermStatus {
  ACTIVE = 'ACTIVE',
  OUTDATED = 'OUTDATED',
  UPCOMING = 'UPCOMING',
}

export enum EmploymentTermType {
  EMPLOYEE_PT = 'EMPLOYEE_PT',
  EMPLOYEE_FT = 'EMPLOYEE_FT',
  TERMINATED = 'TERMINATED',
  ON_LEAVE = 'ON_LEAVE',
}
export enum EmploymentTermChangeType {
  RESIGNATION_VOLUNTARY = 'RESIGNATION_VOLUNTARY',
  TERMINATION_INVOLUNTARY = 'TERMINATION_INVOLUNTARY',
}

export type WorkLocation = {
  id: string;
  localJurisdiction: string;
  stateJurisdiction: string;
  isWfh: boolean;
  name: string;
  country: string;
  physicalAddress: Address;
};

export interface EmploymentCostCenter {
  costCenterId: number;
  /** Value between 0 and 1 is returned from the BE */
  allocationPercentage: number;
}

export enum EmploymentTermSalaryScale {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  TWO_WEEK = 'TWO_WEEK',
  DAY = 'DAY',
  HOUR = 'HOUR',
}

export interface EmploymentTerm {
  amendedItems: string[];
  changeReason: string | null;
  customChangeReason: string | null;
  changeType: EmploymentTermChangeType | null;
  comments: string | null;
  createdAt: string;
  department: string | null;
  desiredStartDate: string | null;
  division: string | null;
  productArea: string | null;
  effectiveDate: string;
  employmentId: string;
  endDate: string | null;
  estimatedEmployerCost: string | null;
  fullTimePercentage: string;
  id: string;
  jobTitle: string;
  lastPayrollReference: string | null;
  leaveType: string | null;
  leaveEndDate: string | null;
  location: string | null;
  overtimeRate: string | null;
  overtimeStatus: string | null;
  rate: string | null;
  scale: EmploymentTermSalaryScale | null;
  scope: string | null;
  startDate: string;
  status: EmploymentTermStatus;
  type: EmploymentTermType;
  updatedAt: string;
  workLocationId?: string | null;
  workLocation?: WorkLocation | null;
  costCenters?: EmploymentCostCenter[];
  payCurrency?: string;
  [index: string]: string | null | string[] | Record<string, any> | undefined;
}
