import type { OtpProvider } from '@/types/User';
import instance from '@/utils/api/instance';

export default {
  getQRData: async ({
    totpValidatedToken,
    isLoginScreen,
  }: {
    totpValidatedToken?: string;
    isLoginScreen?: boolean;
  }) => {
    const { data } = await instance.post(
      isLoginScreen ? `login/totp/generate_mfa_secret` : `account/totp/generate_mfa_secret`,
      {
        totpValidatedToken,
      }
    );
    return data;
  },

  setTotp: async ({
    totpSecretGeneratedToken,
    code,
    partialLoginToken,
  }: {
    totpSecretGeneratedToken: string;
    code: string;
    partialLoginToken?: string;
  }) => {
    const { data } = await instance.post(
      partialLoginToken ? 'login/totp/reset_mfa' : 'account/totp/reset_mfa',
      { totpSecretGeneratedToken, code, partialLoginToken },
      { withCredentials: true }
    );
    return data;
  },

  deleteTotp: async ({
    code,
    otpProvider,
    totpValidatedToken,
  }: {
    code?: string;
    otpProvider?: string;
    totpValidatedToken?: string;
  }) =>
    await instance.delete(`account/totp`, {
      data: {
        code,
        otpProvider,
        totpValidatedToken,
      },
    }),

  sendOtp: async ({
    otpProvider,
    phoneNumberLastDigits,
    partialLoginToken,
  }: {
    otpProvider: OtpProvider;
    phoneNumberLastDigits?: string;
    partialLoginToken?: string;
  }) => {
    const response = await instance.post(
      partialLoginToken ? 'login/totp/send' : 'account/totp/send',
      {
        otpProvider,
        phoneNumberLastDigits,
        partialLoginToken,
      },
      {
        suppressSnackbarForStatuses: [500],
      }
    );
    return response;
  },

  validateOtp: async ({
    code,
    otpProvider = 'app',
    partialLoginToken,
  }: {
    code: string;
    otpProvider?: OtpProvider;
    partialLoginToken?: string;
  }) => {
    const response = await instance.post(
      partialLoginToken ? 'login/totp/validate' : 'account/totp/validate',
      {
        code,
        otpProvider,
        partialLoginToken,
      },
      {
        suppressSnackbarForStatuses: [403],
      }
    );
    return response;
  },
};
