import { useWhiteLabelLogos } from './useWhiteLabelLogos';
import { useCustomBrandingLogos } from '../CustomBranding/useCustomBrandingLogos';

export const useCustomLogos = (forceMobile: boolean = false) => {
  const { whiteLabelLogo, showWhiteLabelLogo } = useWhiteLabelLogos(forceMobile);
  const { customBrandingLogo, showCustomBrandingLogo } = useCustomBrandingLogos(forceMobile);

  if (showWhiteLabelLogo) {
    return {
      customLogo: whiteLabelLogo,
      showCustomLogo: showWhiteLabelLogo,
    };
  }

  if (showCustomBrandingLogo) {
    return {
      customLogo: customBrandingLogo,
      showCustomLogo: showCustomBrandingLogo,
    };
  }

  return {
    customLogo: undefined,
    showCustomLogo: false,
  };
};
