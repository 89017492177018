import type { FileUploaderFile } from '@letsdeel/ui';

export const DARK_MODE_COLORS = {
  cellBackground: '#343434',
  title: '#ffffff',
  caption: '#CFCFCF',
  avatarBackground: '#282828',
  avatarIcon: '#bebebe',
  buttonText: '#1B1B1B',
  primaryButtonBackground: '#ffffff',
  secondaryButtonBackground: '#616161',
  emailHeader: '#1B1B1B',
  emailBody: '#1B1B1B',
  emailFooter: '#1B1B1B',
  defaultCustomColor: '#FFFFFF',
};

export const LIGHT_MODE_COLORS = {
  cellBackground: '#fafafa',
  title: '#1B1B1B',
  caption: '#616161',
  avatarBackground: '#ffffff',
  avatarIcon: '#878787',
  buttonText: '#ffffff',
  primaryButtonBackground: '#1B1B1B',
  secondaryButtonBackground: '#e9e9e9',
  emailHeader: '#FFE27C',
  emailBody: '#FFFBF4',
  emailFooter: '#1B1B1B',
  defaultCustomColor: '#1B1B1B',
};

export const COLOR_MODE = {
  LIGHT_MODE: 'lightMode',
  DARK_MODE: 'darkMode',
} as const;

export type TCOLOR_MODE = (typeof COLOR_MODE)[keyof typeof COLOR_MODE];

export const EMAIL_VISUALIZATION = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
} as const;

export type TEMAIL_VISUALIZATION = (typeof EMAIL_VISUALIZATION)[keyof typeof EMAIL_VISUALIZATION];

export const COLOR_EDITING = {
  HEADER: 'header',
  BODY: 'body',
  FOOTER: 'footer',
} as const;

export type TCOLOR_EDITING = (typeof COLOR_EDITING)[keyof typeof COLOR_EDITING];

export type LogoSizes = {
  sm?: FileUploaderFile;
  md?: FileUploaderFile;
};

export type Logos = {
  [COLOR_MODE.LIGHT_MODE]?: LogoSizes;
  [COLOR_MODE.DARK_MODE]?: LogoSizes;
};

export type Color = {
  value?: string;
  error?: string;
};

export type ColorsProps = {
  [COLOR_MODE.LIGHT_MODE]?: string;
  [COLOR_MODE.DARK_MODE]?: string;
};

export const EMAIL_FIELDS = {
  NAME: 'name',
  EMAIL: 'email',
} as const;

export type TEMAIL_FIELDS = (typeof EMAIL_FIELDS)[keyof typeof EMAIL_FIELDS];

export type ColorValidationErrors = {
  title: string;
  description: string;
}[];
