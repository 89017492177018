import { FeatureFlags } from '@/constants/featureFlags';
import { DisabledWithdrawReason, type IUserStore } from '@/types/User';
import type { ILookupsStore } from '@/types/Lookups';

export const getDeelWalletEligibility = (user: IUserStore, lookups: ILookupsStore) => {
  const isDeelWalletEnabled = !!lookups.getFeatureFlag(FeatureFlags.DeelWalletWithdrawals);
  const isContractorDeelWalletEnabled = isDeelWalletEnabled || !!user.isFeatureEnabled(FeatureFlags.deelWallet);
  const isW9TaxInfoMissing =
    !user.canWithdraw && user.withdrawEligibilityInfo?.reason === DisabledWithdrawReason.W9_MISSING;
  return isContractorDeelWalletEnabled && user.isUSTaxPayer && user.isContractor && !isW9TaxInfoMissing;
};
