import type { ReactNode } from 'react';

import { H2 } from '@letsdeel/ui';

export const LoginPromoText = ({ children }: { children: ReactNode }) => {
  return (
    <H2 color="primary.contrastText" className="login-promo-text">
      {children}
    </H2>
  );
};
