import { Button, HStack } from '@letsdeel/ui';
import { API_URL } from '@/constants/main';
import Logo from '../Logo';
import { useTranslation } from 'react-i18next';

export const SamlLoginButton = () => {
  const { t } = useTranslation();
  const handleClick = () => {
    const deelConfig = (window as any).deelConfig;

    const query = new URLSearchParams();
    query.append('clientId', deelConfig?.data?.ssoProviderClientId);
    query.append('origin', `${window.location.origin}`);
    query.append('hostname', deelConfig?.data?.hostname);
    window.location.href = `${API_URL}/login/saml?${query.toString()}`;
  };

  return (
    <Button
      className="text-center position-relative azure-login-button"
      fullWidth
      variant="outlined"
      size="large"
      onClick={handleClick}
    >
      <HStack gap={1} align="center" className="justify-content-center">
        <Logo type="azure" size="md" color="#00297A" className="d-flex align-items-center" />
        {t('common.apps.loginUsingSAML')}
      </HStack>
    </Button>
  );
};
