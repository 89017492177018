import { Capacitor } from '@capacitor/core';
import { UserAgent } from '@adeprez/capacitor-user-agent';
import { Device } from '@capacitor/device';

export const isNative = () => {
  return Capacitor.isNativePlatform();
};

export const isNativeIos = () => {
  return Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios';
};

export const isNativeAndroid = () => {
  return Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'android';
};

export const getUserAgent = async () => {
  const storedUserAgent = localStorage.getItem('native-user-agent');
  if (storedUserAgent) {
    return storedUserAgent;
  }
  const result = await UserAgent.get();
  localStorage.setItem('native-user-agent', result.userAgent);
  return result.userAgent;
};

//Returns language code of the device
//This value is independent of app language, which can be switched by user
//Example format: 'en' / 'de' / 'es' etc...
export const getDeviceLanguageCode = async () => {
  if (!isNative()) {
    return '';
  }
  const { value: languageCode } = await Device.getLanguageCode();

  return languageCode;
};

export const getDeviceId = async () => {
  if (!isNative()) {
    return '';
  }
  const { identifier: deviceId } = await Device.getId();
  return deviceId;
};

export const crossPlatormProps = {
  isNative: isNative(),
  isNativeAndroid: isNativeAndroid(),
  isNativeIos: isNativeIos(),
};
