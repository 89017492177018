import legalEntity from './legalEntity';
import massUpdate from './massUpdate';
import clientG2N from '@/utils/api/globalPayroll/clientG2N';
import contracts from '@/utils/api/globalPayroll/contracts';
import employee from '@/utils/api/globalPayroll/employee';
import gpDetails from '@/utils/api/globalPayroll/gpDetails';
import massTerminate from '@/utils/api/globalPayroll/massTerminate';
import clientPayrollInputFiles from '@/utils/api/globalPayroll/clientPayrollInputFiles';
import onboarding from '@/utils/api/globalPayroll/onboarding';
import terminations from '@/utils/api/globalPayroll/terminations';
import generalLedger from './generalLedger';
import massInvite from './massInvite';

export default {
  onboarding,
  terminations,
  contracts,
  employee,
  gpDetails,
  massUpdate,
  clientG2N,
  legalEntity,
  massTerminate,
  generalLedger,
  clientPayrollInputFiles,
  massInvite,
};
