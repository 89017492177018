import type { CountryCode } from '@letsdeel/ui';
import type { PAYROLL_EVENT_STATE } from '../GlobalPayroll';

export const PayrollEventTypes = {
  REGULAR: 'REGULAR',
  OFFCYCLE: 'OFFCYCLE',
  HISTORICAL: 'HISTORICAL',
  PARALLEL: 'PARALLEL',
  RECONCILIATION: 'RECONCILIATION',
  CORRECTION: 'CORRECTION',
} as const;

export type PayrollEventType = (typeof PayrollEventTypes)[keyof typeof PayrollEventTypes];

interface PayrollEventPeriod {
  from?: string;
  to?: string;
}

type ExperienceType = 'EOR' | 'GP' | 'PEO';

type PayrollEventState = 'OPEN' | 'LOCKED' | 'CLOSED';

export interface PayrollEventsPayload {
  payrollLegalEntityIds?: number[];
  actionState?: PAYROLL_EVENT_STATE;
  ICPId?: number;
  experienceType?: ExperienceType;
  fullInclude?: boolean;
  country?: CountryCode;
  start?: string;
  end?: string;
  reference?: string;
  gpContractId?: number;
  contractOid?: string;
  employeeName?: string;
  limit?: number;
  cursor?: string;
  period?: PayrollEventPeriod;
  type?: PayrollEventType;
  states?: PayrollEventState[];
}

interface PayrollEventPayrollSchedule {
  payDayInMonth: number;
  payMonth: number;
  id: string;
  startDayInMonth: number;
  lockDayInMonth: number;
  endDayInMonth: number | null;
  lockMonth: 'SAME_MONTH' | 'PREV_MONTH';
  payDateOffsetFromEnd: number | null;
  index: number;
  payrollSettingsId: string;
  createdAt: string;
  updatedAt: string;
}

export interface PayrollEventPayrollSettings {
  id: string;
  payrollLegalEntityId: number;
  ICPId: number;
  cycleType: 'MONTHLY' | 'BIMONTHLY' | 'CUSTOM';
  experienceType: ExperienceType;
  createdAt: string;
  updatedAt: string;
  isActive: Boolean;
  embeddedPayrollEnabled: Boolean;
}

export interface PayrollEvent {
  id: string;
  start: string;
  lock: string | null;
  end: string;
  reference: string;
  payrollScheduleId: string | null;
  payrollSettingsId: string;
  type: PayrollEventType;
  state: PayrollEventState;
  createdAt: string;
  updatedAt: string;
  payrollSettings: PayrollEventPayrollSettings;
  payrollSchedule: PayrollEventPayrollSchedule | null;
  actionState: PAYROLL_EVENT_STATE;
  currentVersion: number | null;

  // TODO: add types for missed fields
  steps: Array<unknown>;
  updatedBy: unknown;
}

export type PayrollEventsResponse = {
  count: number;
  data: PayrollEvent[];
};
