import type { SpecialCaseSentences } from '@/constants/specialCaseSentences';
import { specialCaseSentences } from '@/constants/specialCaseSentences';
import capitalize from 'lodash/capitalize';

export const pluralString = (str: string, amount: number): string => {
  if (amount === 1) return str;
  const reg = /.*(ch|sh|s|x|z){1}$/;
  return `${str}${reg.test(str) ? 'es' : 's'}`;
};

export const findPatternInString = (inputText: string, pattern: string): boolean => {
  return inputText.toLowerCase().indexOf(pattern.toLowerCase().trim()) !== -1;
};

export const isMatchStrings = (first: string, second: string): boolean => {
  return first.toLowerCase().trim() === second.toLowerCase().trim();
};

export const convertSnakeCaseToSentence = (str: string, shouldCapitalize: boolean = true) => {
  return (
    specialCaseSentences[str as keyof SpecialCaseSentences] ||
    ((shouldCapitalize && capitalize(str)) || str).replaceAll('_', ' ')
  );
};

export function replaceUnderscoreAndCapitalize(inputString: string): string {
  const lowerCaseStringWithSpaces = inputString.replace(/_/g, ' ').toLowerCase();
  const capitalizedString = lowerCaseStringWithSpaces.charAt(0).toUpperCase() + lowerCaseStringWithSpaces.slice(1);

  return capitalizedString;
}
export const convertToTitleCase = (field: string) => {
  if (!field || typeof field !== 'string') {
    return '';
  }

  // Split the string into words by spaces, underscores, or camel case
  const words = field
    .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/_/g, ' ')
    .trim()
    .split(/\s+/);

  // Capitalize the first letter of each word and join them with spaces
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  const result = capitalizedWords.join(' ');

  return result;
};
