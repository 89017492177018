import type { ComponentProps, Dispatch, FC, ReactElement, SetStateAction, PropsWithChildren } from 'react';
import { Children, useEffect, useMemo } from 'react';
import PartiallyScrollingPopup from '@/components/PartiallyScrollingPopup';
import { HStack, VStack, Button, H6 } from '@letsdeel/ui';
import MultiStepPopupStep from './MultiStepPopupStep';
import { useTranslation } from 'react-i18next';

interface Props extends ComponentProps<typeof PartiallyScrollingPopup> {
  stepIndex: number;
  setStepIndex: Dispatch<SetStateAction<number>>;
  onNext?: () => void;
  onConfirm?: () => void;
  isLoading?: boolean;
  isNextDisabled?: boolean;
}

const MultiStepPopup: FC<PropsWithChildren<Props>> = ({
  children,
  stepIndex,
  setStepIndex,
  onNext,
  onConfirm,
  isLoading = false,
  isNextDisabled = false,
  title,
  subtitle,
  backButton,
  onHide,
  ...rest
}) => {
  const { t } = useTranslation();
  const steps = useMemo(
    () => Children.toArray(children).filter((step) => (step as ReactElement).type === MultiStepPopupStep),
    [children]
  );

  useEffect(() => {
    if (!steps.length) {
      console.error(`No ${MultiStepPopupStep.name} provided as children to ${MultiStepPopup.name}`);
    }
  }, [steps]);

  const addToStepIndex = (value: number) => {
    const newValue = stepIndex + value;
    // close modal if user presses back button when already in first step
    if (newValue < 0) {
      onHide?.();
    } else if (newValue < steps.length) {
      setStepIndex(newValue);
    } else {
      onConfirm?.();
    }
  };

  const step = steps[stepIndex] as ReactElement;
  if (!step) {
    return null;
  }

  return (
    <PartiallyScrollingPopup
      // only allow backButton to be forcefully used when popup is closeable
      backButton={(backButton && !!onHide) || stepIndex > 0}
      onHide={onHide}
      onBack={() => addToStepIndex(-1)}
      contentAfter={
        !step.props.hideFooter && (
          <footer className="mt-10">
            {step.props.onlyButton ? (
              <Button
                type="button"
                className="w-100"
                disabled={isNextDisabled}
                loading={isLoading}
                onClick={() => (onNext ? onNext() : addToStepIndex(1))}
              >
                {step.props.nextButtonText || t('common.actions.confirm')}
              </Button>
            ) : (
              <HStack justify="end" spacing={16}>
                <VStack justify="center" align="end">
                  <H6>
                    {step.props.currentName ? step.props.currentName : `Step: ${stepIndex + 1} of ${steps.length}`}
                  </H6>
                  <H6>{step.props.nextName}</H6>
                </VStack>
                <Button
                  type="button"
                  disabled={isNextDisabled}
                  loading={isLoading}
                  onClick={() => (onNext ? onNext() : addToStepIndex(1))}
                >
                  {step.props.nextButtonText || t('common.actions.next')}
                </Button>
              </HStack>
            )}
          </footer>
        )
      }
      title={step.props.popupTitle || title}
      subtitle={step.props.popupSubtitle || subtitle}
      {...rest}
    >
      {step}
    </PartiallyScrollingPopup>
  );
};

export default MultiStepPopup;
