import instance from '@/utils/api/instance';
import type {
  CreateVariableCompensationPayload,
  VariableCompensationItem,
} from '@/scenes/People/PersonView/VariableCompensation/types';

export const VARIABLE_COMPENSATION_HRIS_ENDPOINT = '/hris_core/variable_compensations';
export const VARIABLE_COMPENSATION_GP_ENDPOINT = '/global_payroll/variable_compensations';

export default {
  addVariableCompensation: async (endpoint: string, payload: CreateVariableCompensationPayload): Promise<void> => {
    await instance.post(endpoint, payload);
  },

  deleteVariableCompensation: async (endpoint: string, compensationId: string): Promise<void> => {
    await instance.delete(`${endpoint}/${compensationId}`);
  },

  updateVariableCompensation: async (
    endpoint: string,
    compensationId: string | undefined,
    item: VariableCompensationItem
  ): Promise<void> => {
    await instance.patch(`${endpoint}/${compensationId}`, item);
  },
};
