import type { Theme } from '@letsdeel/ui';
import { MuiBox } from '@letsdeel/ui';
import type { WalkthroughStep } from '@letsdeel/ui/dist/providers/WalkthroughProvider/WalkthroughProvider.props';
import type { FitViewOptions } from 'reactflow';
import type { CollapsibleNodeData } from './components';
import { ORG_CHART_TYPE, GROUP_NODE_VARIANT } from './types';
import { t } from 'i18next';
import { Translation } from 'react-i18next';

export const NODE_HEIGHT = 64;
export const NODE_WIDTH = 234;
export const BIGGER_GAP = 80;
export const SMALLER_GAP = 40;
export const GAP_BETWEEN_ORG_CHART_AND_TOP_BAR = 120;

export const MAX_NUMBER_OF_EMPLOYEES_PER_GROUP_TO_SHOW = 20;

export const GROUP_BY_REPORTER_KEY = 'group-by-report';
export const GROUP_BY_COUNTRY_KEY = 'country';

// Query string parameters not used in filtering
export enum ADDITIONAL_QS_KEYS {
  REFERRER = 'referrer',
}

export enum QS_REFERRER {
  HR_DIRECT_EMPLOYEE = 'hr-direct-employee',
}

type NodeSize = {
  node_width: number;
  node_height: number;
};

type GroupByNodeSizes = {
  [key in GROUP_NODE_VARIANT]: NodeSize;
};

type NodeSizeConfig = {
  [key in ORG_CHART_TYPE]: GroupByNodeSizes;
};

export const NODE_SIZE: NodeSizeConfig = {
  [ORG_CHART_TYPE.EMPLOYEE]: {
    [GROUP_NODE_VARIANT.DEFAULT]: {
      node_width: 234,
      node_height: 64,
    },
    [GROUP_NODE_VARIANT.LARGE]: { node_width: 234, node_height: 64 },
    [GROUP_NODE_VARIANT.EXTRA_LARGE]: { node_width: 234, node_height: 64 },
    [GROUP_NODE_VARIANT.MEDIUM]: {
      node_width: 112,
      node_height: 104,
    },
    [GROUP_NODE_VARIANT.SMALL]: {
      node_width: 112,
      node_height: 104,
    },
  },
  [ORG_CHART_TYPE.GROUP]: {
    [GROUP_NODE_VARIANT.EXTRA_LARGE]: {
      node_width: 528,
      node_height: 167,
    },
    [GROUP_NODE_VARIANT.LARGE]: {
      node_width: 528,
      node_height: 167,
    },
    [GROUP_NODE_VARIANT.DEFAULT]: {
      node_width: 528,
      node_height: 167,
    },
    [GROUP_NODE_VARIANT.MEDIUM]: {
      node_width: 528,
      node_height: 167,
    },
    [GROUP_NODE_VARIANT.SMALL]: {
      node_width: 528,
      node_height: 64,
    },
  },
};

export const EMPLOYEE_CARD_SIZE = {
  [GROUP_NODE_VARIANT.DEFAULT]: {
    node_width: 234,
    node_height: 64,
  },
  [GROUP_NODE_VARIANT.LARGE]: { node_width: 234, node_height: 64 },
  [GROUP_NODE_VARIANT.EXTRA_LARGE]: { node_width: 234, node_height: 64 },
  [GROUP_NODE_VARIANT.MEDIUM]: {
    node_width: 112,
    node_height: 120,
  },
  [GROUP_NODE_VARIANT.SMALL]: {
    node_width: 112,
    node_height: 120,
  },
};

export const DEFAULT_ANIMATION_DURATION = 300;

export const FIT_VIEW_OPTIONS_DEFAULT: Required<Pick<FitViewOptions, 'duration' | 'padding'>> = {
  duration: DEFAULT_ANIMATION_DURATION,
  padding: 0.2,
};

export const FIT_VIEW_OPTIONS_FOCUS_ON_NODE: Required<Pick<FitViewOptions, 'duration' | 'padding'>> = {
  ...FIT_VIEW_OPTIONS_DEFAULT,
  padding: 5,
};

export const MAIN_ROOT_NODE: CollapsibleNodeData = {
  id: 'rootNode',
  parentId: null,
  type: 'mainRoot',
  collapsed: false,
  label: t('orgChart.main.main_root_node.organization') as string,
  children: undefined,
};

export const ORPHAN_THREE_ROOT_NODE: CollapsibleNodeData = {
  id: 'orphanTreeRootNode',
  parentId: MAIN_ROOT_NODE.id,
  type: 'orphanNode',
  collapsed: true,
  label: t('orgChart.main.orphan_tree_root_node.label') as string,
  children: undefined,
};

export const WALK_THROUGH_DATA: WalkthroughStep[] = [
  {
    content: (
      <Translation>
        {(t) => (
          <MuiBox
            component="span"
            pr={3}
            sx={(theme: Theme) => ({
              color: theme.palette.primary.contrastText,
            })}
            fontWeight={600}
          >
            {t('orgChart.main.walkthrough.walkthrough_step_1_content')}
          </MuiBox>
        )}
      </Translation>
    ),
    id: 'org-chart-walk-through-1',
    placement: 'top-end',
    wide: false,
    scrollIntoView: false,
  },
  {
    content: (
      <Translation>
        {(t) => (
          <MuiBox
            component="span"
            pr={3}
            sx={(theme: Theme) => ({
              color: theme.palette.primary.contrastText,
            })}
            fontWeight={600}
          >
            {t('orgChart.main.walkthrough.walkthrough_step_2_content')}
          </MuiBox>
        )}
      </Translation>
    ),
    id: 'org-chart-walk-through-2',
    placement: 'top-end',
    wide: false,
    scrollIntoView: false,
  },
  {
    content: (
      <Translation>
        {(t) => (
          <MuiBox
            component="span"
            pr={3}
            sx={(theme: Theme) => ({
              color: theme.palette.primary.contrastText,
            })}
            fontWeight={600}
          >
            {t('orgChart.main.walkthrough.walkthrough_step_3_content')}
          </MuiBox>
        )}
      </Translation>
    ),
    id: 'org-chart-walk-through-3',
    placement: 'left-start',
    wide: false,
    scrollIntoView: false,
  },
  {
    content: (
      <Translation>
        {(t) => (
          <MuiBox
            component="span"
            pr={3}
            sx={(theme: Theme) => ({
              color: theme.palette.primary.contrastText,
            })}
            fontWeight={600}
          >
            {t('orgChart.main.walkthrough.walkthrough_step_4_content')}
          </MuiBox>
        )}
      </Translation>
    ),
    id: 'org-chart-walk-through-4',
    placement: 'left-start',
    wide: false,
    scrollIntoView: false,
  },
  {
    id: 'org-chart-walk-through-5',
    title: <Translation>{(t) => t('orgChart.main.walkthrough.walkthrough_step_5_title')}</Translation>,
    content: (
      <Translation>
        {(t) => (
          <MuiBox
            component="span"
            pr={3}
            sx={(theme: Theme) => ({
              color: theme.palette.primary.contrastText,
            })}
            fontWeight={600}
          >
            {t('orgChart.main.walkthrough.walkthrough_step_5_content')}
          </MuiBox>
        )}
      </Translation>
    ),
    placement: 'left-start',
    wide: false,
    scrollIntoView: false,
  },
  {
    id: 'org-chart-walk-through-6',
    title: <Translation>{(t) => t('orgChart.main.walkthrough.walkthrough_step_6_title')}</Translation>,
    content: (
      <Translation>
        {(t) => (
          <MuiBox
            component="span"
            pr={3}
            sx={(theme: Theme) => ({
              color: theme.palette.primary.contrastText,
            })}
            fontWeight={400}
          >
            {t('orgChart.main.walkthrough.walkthrough_step_6_content')}
          </MuiBox>
        )}
      </Translation>
    ),
    placement: 'left-start',
    wide: false,
    scrollIntoView: true,
  },
  {
    id: 'org-chart-walk-through-7',
    title: <Translation>{(t) => t('orgChart.main.walkthrough.walkthrough_step_7_title')}</Translation>,
    content: (
      <Translation>
        {(t) => (
          <MuiBox
            component="span"
            pr={3}
            sx={(theme: Theme) => ({
              color: theme.palette.primary.contrastText,
            })}
            fontWeight={400}
          >
            {t('orgChart.main.walkthrough.walkthrough_step_7_content')}
          </MuiBox>
        )}
      </Translation>
    ),
    placement: 'left-start',
    wide: false,
    scrollIntoView: false,
  },
  {
    id: 'org-chart-walk-through-8',
    title: <Translation>{(t) => t('orgChart.main.walkthrough.walkthrough_step_8_title')}</Translation>,
    content: (
      <Translation>
        {(t) => (
          <MuiBox
            component="span"
            pr={3}
            sx={(theme: Theme) => ({
              color: theme.palette.primary.contrastText,
            })}
            fontWeight={400}
          >
            {t('orgChart.main.walkthrough.walkthrough_step_8_content')}
          </MuiBox>
        )}
      </Translation>
    ),
    placement: 'left-start',
    wide: false,
    scrollIntoView: false,
  },
];

// Local storage keys
export const LS_ORPHAN_WARNING_SHOWN = 'LS_ORPHAN_WARNING_SHOWN';
export const ORG_CHART_INSTRUCTIONS_SHOWN = 'org-chart-instructions-shown';
export const CUSTOM_FIELDS_DEPARTMENTS_SHOWN = 'custom-fields-department-instructions-shown';
export const ORG_CHART_PARTIAL_FILTERED_NODE_SHOWN = 'org-chart-partial-filtered-node-shown';

export const createVariantStyles = (variant: GROUP_NODE_VARIANT) => {
  const styles = {
    [GROUP_NODE_VARIANT.SMALL]: {
      display: 'flex',
      flexDirection: 'column',
      width: EMPLOYEE_CARD_SIZE.small.node_width,
      height: EMPLOYEE_CARD_SIZE.small.node_height,
    },
    [GROUP_NODE_VARIANT.MEDIUM]: {
      display: 'flex',
      flexDirection: 'column',
      width: EMPLOYEE_CARD_SIZE.medium.node_width,
      height: EMPLOYEE_CARD_SIZE.medium.node_height,
    },
    [GROUP_NODE_VARIANT.DEFAULT]: {},
    [GROUP_NODE_VARIANT.LARGE]: {},
    [GROUP_NODE_VARIANT.EXTRA_LARGE]: {},
  };
  return styles[variant] || {};
};

export const DRAWER_BOX_SHADOW = '0px 8px 16px rgba(0, 0, 0, 0.25)';
export const TIME_OUT_CHANGES_MS = 100;
export const IS_HORIZONTAL = false;
export const TIME_TO_MOVE_FOCUS = 200;
