import i18n from 'i18next';
import type { ButtonProps } from '@letsdeel/ui';
import { Button } from '@letsdeel/ui';
import useCountdownTimer from '@/components/OtpPopupContent/useCountdownTimer';

interface Props {
  onClick: () => void;
  isLoading: boolean;
  resendCodeText?: string;
  timeoutInSeconds?: number;
  buttonProps?: ButtonProps;
}

const ResendButton = ({
  onClick,
  isLoading,
  resendCodeText = i18n.t('temp.platform-fe.components.OtpPopupContent.7dc945e9b6').toString(),
  timeoutInSeconds = 60,
  buttonProps,
}: Props) => {
  const { secondsRemaining, resetCountdown, timerDone } = useCountdownTimer(timeoutInSeconds);

  const handleOnClick = () => {
    resetCountdown();
    onClick();
  };

  return (
    <Button
      {...buttonProps}
      disabled={isLoading || !timerDone}
      variant="outlined"
      className="mt-4 mb-7"
      onClick={handleOnClick}
    >
      {timerDone
        ? resendCodeText
        : i18n.t('temp.platform-fe.components.OtpPopupContent.a0e7d48c61', { v0: secondsRemaining }).toString()}
    </Button>
  );
};

export default ResendButton;
