// translation:no-need
import { BiometryType, NativeBiometric } from 'capacitor-native-biometric';
import * as Sentry from '@sentry/react';
import { BIOMETRIC_SERVER } from '@/constants/nativeApp';
import { getSecureAppPin, removeIsBiometricAppPinSaved, setIsBiometricAppPinSaved } from '@/native/AppPin/appPin';

const BIOMETRIC_DEFAULT_USERNAME = 'appPin';

const fallbackBiometricInfo = {
  isAvailable: false,
  biometryType: BiometryType.NONE,
};

export const getDeviceBiometryInfo = async (): Promise<{ isAvailable: boolean; biometryType: BiometryType }> => {
  try {
    const { isAvailable, biometryType, errorCode } = await NativeBiometric.isAvailable({ useFallback: true });

    if (errorCode) {
      Sentry.addBreadcrumb({
        message: 'NativeBiometric has returned with error code',
        data: {
          errorCode,
          isAvailable,
          biometryType,
        },
      });
      return fallbackBiometricInfo;
    }

    return {
      isAvailable,
      biometryType,
    };
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        platform: 'mobile-app',
      },
    });
    return fallbackBiometricInfo;
  }
};

export const setBiometricCredentials = async () => {
  try {
    const appPin = await getSecureAppPin();
    if (!appPin) return;
    await setIsBiometricAppPinSaved();
    await NativeBiometric.setCredentials({
      username: BIOMETRIC_DEFAULT_USERNAME,
      password: appPin,
      server: BIOMETRIC_SERVER,
    });
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        platform: 'mobile-app',
      },
    });
  }
};

export const deleteBiometricCredentials = async () => {
  try {
    await NativeBiometric.deleteCredentials({ server: BIOMETRIC_SERVER });
    await removeIsBiometricAppPinSaved();
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        platform: 'mobile-app',
      },
    });
  }
};

export const getBiometricCredentials = async () => {
  try {
    return await NativeBiometric.getCredentials({ server: BIOMETRIC_SERVER });
  } catch (e) {
    return null;
  }
};

export const verifyBiometrics = async () => {
  try {
    await NativeBiometric.verifyIdentity({
      maxAttempts: 2,
    });
    return true;
  } catch {
    return false;
  }
};
