import instance from '@/utils/api/instance';
import type { AxiosError, AxiosRequestConfig } from 'axios';

const BASE_URL = 'hris';

export default {
  updateHrisEmployeeBankDetailsByEmployee: async (payload: object): Promise<any> => {
    try {
      const response = await instance.post(`${BASE_URL}/bank_details`, { payload });
      return response.data.payload;
    } catch {
      return null;
    }
  },

  switchHrisOrganization: async (organizationId: string): Promise<any> => {
    try {
      const response = await instance.put(`${BASE_URL}/me/organization/${organizationId}/switch`);
      return response;
    } catch {
      return null;
    }
  },

  // called by the employee
  getBankDetails: async (config: AxiosRequestConfig = {}): Promise<any> => {
    try {
      const response = await instance.get(`${BASE_URL}/bank_details`, config);
      return response.data;
    } catch (err) {
      if ((err as AxiosError)?.response?.status === 404) return null;
      throw err;
    }
  },

  // called by the client
  getHrisEmployeeBankDetails: async (
    employeeId: string | number,
    config: AxiosRequestConfig = {}
  ): Promise<any> => {
    try {
      const response = await instance.get(`${BASE_URL}/bank_details/${employeeId}`, config);
      return response.data;
    } catch (err) {
      /**
       * The reason for re-throwing the error is that the backend returns a 404 error code when a record is not found,
       * instead of a 200 error code with an empty response. This affects how the error handling is done on the frontend, leading to unclear code.
       * We need to threat 404 code as an request with a 200 status that
       * has an empty response, while preserving the normal error flow for other error codes.
       */

      if ((err as AxiosError)?.response?.status === 404) return null;
      throw err;
    }
  },

  getBankRequirements: async (country: string, currency?: string): Promise<any> => {
    try {
      const response = await instance.get(
        `${BASE_URL}/bank_details/requirements?country=${country}${currency ? `&currency=${currency}` : ''}`
      );
      return response;
    } catch (err) {
      if ((err as AxiosError)?.response?.status === 404) return null;
      throw err;
    }
  },
};

export const bankDetailEndpoints = {
  hrisDirectEmployeeBankRequirements: `${BASE_URL}/bank_details/requirements`,
  hrisDirectEmployeeBankDetails: `${BASE_URL}/bank_details`,
};
