import i18n from 'i18next';
import { CONTRACT_STATUSES, CONTRACT_TYPES, CONTRACT_TYPES_MAPPED } from '@/constants/contract';
import { createDate } from '@letsdeel/ui';

export const getNewFilterOptions = () => [
  {
    value: 'team',
    label: i18n.t('temp.contracts-core-fe.components.ContractsTable.03937134ce'),
    checked: false,
    eor: false,
  },
  {
    value: 'entity',
    label: i18n.t('temp.contracts-core-fe.components.ContractsTable.1a434befae'),
    checked: false,
    eor: false,
  },
  {
    value: 'contractType',
    label: i18n.t('temp.contracts-core-fe.components.ContractsTable.09e2e92a8c'),
    checked: true,
    eor: false,
  },
  {
    value: 'contractState',
    label: i18n.t('temp.contracts-core-fe.components.ContractsTable.e1487025c8'),
    checked: true,
    eor: true,
  },
  {
    value: 'currency',
    label: i18n.t('temp.contracts-core-fe.components.ContractsTable.386c339d37'),
    checked: false,
    eor: false,
  },
  {
    value: 'country',
    label: i18n.t('temp.contracts-core-fe.components.ContractsTable.59716c9749'),
    checked: false,
    eor: false,
  },
  {
    value: 'refundStatus',
    label: i18n.t('temp.contracts-core-fe.components.ContractsTable.bf73eb2b0b'),
    checked: false,
    eor: false,
  },
];

export const FILTER_OPTIONS = getNewFilterOptions();

export const getNewContractTypeOptions = (isGPEnabled = false) => {
  const types = [
    { value: 'all', label: i18n.t('temp.contracts-core-fe.components.ContractsTable.134353eb58'), type: 'all' },
    { value: CONTRACT_TYPES.fixed.type, label: i18n.t('temp.contracts-core-fe.components.ContractsTable.300b11be75') },
    {
      value: 'payg',
      label: i18n.t('temp.contracts-core-fe.components.ContractsTable.cc00c21792'),
      values: [CONTRACT_TYPES.payAsYouGo.type, CONTRACT_TYPES.paygTasks.type],
    },
    {
      value: 'milestones',
      label: i18n.t('temp.contracts-core-fe.components.ContractsTable.e9c626b8a8'),
      values: [CONTRACT_TYPES.milestones.type, CONTRACT_TYPES.paygMilestones.type],
    },
    { value: CONTRACT_TYPES.eor.type, label: i18n.t('temp.contracts-core-fe.components.ContractsTable.f17570b038') },
  ];
  if (isGPEnabled) {
    types.push({ value: CONTRACT_TYPES.globalPayroll.type, label: CONTRACT_TYPES_MAPPED.global_payroll });
  }
  return types.sort((t1, t2) => (t1.label < t2.label ? -1 : 1));
};

export const CONTRACT_TYPE_OPTIONS = getNewContractTypeOptions();

export const getNewContractStateFilter = () => {
  return {
    client: [
      { value: 'all', label: i18n.t('temp.contracts-core-fe.components.ContractsTable.96e3d39826'), type: 'all' },
      {
        value: 'active',
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.4d3d769b81'),
        values: [CONTRACT_STATUSES.inProgress.type, 'processing_payment'],
      },
      {
        value: 'modifying',
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.53f1a8a1b8'),
        customKey: 'modifying',
        bool: true,
      },
      {
        value: CONTRACT_STATUSES.archived.type,
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.7d69b3cb4c'),
        customKey: 'archived',
        bool: true,
      },
      {
        value: 'cancelled',
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.0e22fe7d45'),
        values: [CONTRACT_STATUSES.cancelled.type],
      },
      {
        value: 'terminationStatus.inProgress',
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.5bdcc1f7f5'),
        customKey: 'terminationStatus',
        values: ['IN_PROGRESS'],
      },
      {
        value: 'terminationStatus.requested',
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.858024a3fa'),
        customKey: 'terminationStatus',
        values: ['REQUESTED'],
      },
      {
        value: CONTRACT_STATUSES.draft.type,
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.f03ab16cd5'),
        values: [CONTRACT_STATUSES.draft.type],
      },
      {
        value: 'ended',
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.0157fbb99b'),
        values: [CONTRACT_STATUSES.completed.type, CONTRACT_STATUSES.userCancelled.type],
      },
      {
        value: CONTRACT_STATUSES.expiring.type,
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.5ed8e32f10'),
        customKey: 'expiring',
        bool: true,
      },
      {
        value: 'onboarded',
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.f8e5c8ad5b'),
        values: [CONTRACT_STATUSES.onboarded.type],
      },
      {
        value: 'onboarding',
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.10bf8b475a'),
        values: [CONTRACT_STATUSES.onboarding.type],
      },
      {
        value: CONTRACT_STATUSES.rejected.type,
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.d37b1f6c05'),
      },
      {
        value: 'onProbation',
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.72955d96d2'),
        customKey: 'probation',
        bool: true,
      },
      {
        value: 'unsigned',
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.a1a914735b'),
        values: [
          CONTRACT_STATUSES.waitingForEORSign.type,
          CONTRACT_STATUSES.awaitingDepositPayment.type,
          CONTRACT_STATUSES.waitingForEmployeeSign.type,
          CONTRACT_STATUSES.waitingForClientSign.type,
          CONTRACT_STATUSES.waitingForContractorSign.type,
          CONTRACT_STATUSES.underReview.type,
          CONTRACT_STATUSES.new.type,
        ],
      },
      {
        value: 'awaiting_deposit_payment',
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.7cff9676cb'),
        values: [CONTRACT_STATUSES.awaitingDepositPayment.type],
      },
    ],
    eor: [
      { value: 'all', label: i18n.t('temp.contracts-core-fe.components.ContractsTable.6d28b9c928'), type: 'all' },
      {
        value: 'active',
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.4d3d769b81'),
        values: [CONTRACT_STATUSES.inProgress.type, 'processing_payment'],
      },
      {
        value: 'onProbation',
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.72955d96d2'),
        customKey: 'probation',
        bool: true,
      },
      {
        value: CONTRACT_STATUSES.archived.type,
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.7d69b3cb4c'),
        customKey: 'archived',
        bool: true,
      },
      {
        value: CONTRACT_STATUSES.waitingForClientSign.type,
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.bc71344207'),
      },
      {
        value: CONTRACT_STATUSES.waitingForEmployeeSign.type,
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.e55ad7c17f'),
      },
      {
        value: CONTRACT_STATUSES.waitingForEORSign.type,
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.e100b46404'),
      },
      {
        value: CONTRACT_STATUSES.awaitingDepositPayment.type,
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.99a82247b2'),
      },
      {
        value: CONTRACT_STATUSES.underReview.type,
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.15696c03c7'),
      },
      {
        value: 'cancelled',
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.0e22fe7d45'),
        values: [CONTRACT_STATUSES.cancelled.type],
      },
      {
        value: 'ended',
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.0157fbb99b'),
        values: [CONTRACT_STATUSES.completed.type, CONTRACT_STATUSES.userCancelled.type],
      },
      {
        value: CONTRACT_STATUSES.expiring.type,
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.5ed8e32f10'),
        customKey: 'expiring',
        bool: true,
      },
      {
        value: CONTRACT_STATUSES.new.type,
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.cc2e18804e'),
      },
      {
        value: CONTRACT_STATUSES.rejected.type,
        label: i18n.t('temp.contracts-core-fe.components.ContractsTable.d37b1f6c05'),
      },
    ],
  };
};

export const REFUND_STATUS_OPTIONS = [
  {
    value: 'AWAITING_INVOICE_PAYMENT',
    label: i18n.t('temp.contracts-core-fe.components.ContractsTable.af44a1dafb'),
    values: ['AWAITING_INVOICE_PAYMENT'],
  },
  {
    value: 'AWAITING_BANK_DETAILS',
    label: i18n.t('temp.contracts-core-fe.components.ContractsTable.aa6dc36547'),
    values: ['AWAITING_BANK_DETAILS'],
  },
  {
    value: 'AWAITING_DEPOSIT_ISSUE',
    label: i18n.t('temp.contracts-core-fe.components.ContractsTable.0de8f1406e'),
    values: ['AWAITING_DEPOSIT_ISSUE'],
  },
  {
    value: 'AWAITING_REFUND_AMOUNT_CONFIRMATION',
    label: i18n.t('temp.contracts-core-fe.components.ContractsTable.66937414a8'),
    values: ['AWAITING_REFUND_AMOUNT_CONFIRMATION'],
  },
];

// CONTRACT STATE FILTER LIST- SPLIT BY CLIENT AND EOR USERS
export const CONTRACT_STATE_FILTER = getNewContractStateFilter();

export const buildOptionObject = (options, items, key, forceAdd) => {
  const customKeyItems = items?.filter(({ customKey }) => customKey) || [];
  if (!items || !options || !key) return [];
  let res = options;
  if (
    !forceAdd &&
    (items.every(({ checked }) => !checked) || (items.every(({ checked }) => checked) && customKeyItems.length === 0))
  ) {
    delete res[key];
    customKeyItems.forEach((item) => delete res[item.customKey]);
  } else {
    const filteredOptionValues = items.filter(
      ({ checked, customKey, type }) => !customKey && checked && type !== 'all'
    );

    let optionValues = filteredOptionValues.map(({ value, values, type }) =>
      type === 'date' ? createDate(value).toISOString() : values || value
    );

    if (filteredOptionValues.length === 1 && filteredOptionValues[0].type === 'date') {
      optionValues = optionValues[0];
    }

    res = {
      ...res,
      [key]: optionValues,
    };

    // delete all customKeyItems to readd them
    customKeyItems.forEach((item) => delete res[item.customKey]);
    customKeyItems.map((item) => {
      if (item.bool && item.checked) {
        res = { ...res, [item.customKey]: item.checked };
        return;
      }
      if (item.values) {
        res = {
          ...res,
          [item.customKey]: item.checked
            ? [...(res[item?.customKey] || []), ...item.values]
            : [...(res[item?.customKey] || [])],
        };
        return;
      }
      res = {
        ...res,
        [item.customKey]: item.checked
          ? [...(res[item?.customKey] || []), item.value]
          : [...(res[item?.customKey] || [])],
      };
    });
  }

  // if the filter has 'all' option, remove its key and custom keys from the query
  if (items.find((item) => item.value === 'all' && item.checked)) {
    delete res[key];
    customKeyItems.forEach((item) => delete res[item.customKey]);
  }

  return res;
};
