import i18n from 'i18next';
import { DAYS_IN_MONTH, DAYS_IN_WEEK, HOURS_IN_DAY, MINUTES_IN_HOUR } from '../../constants/time';
import { getScaleNoun } from './main';
import { buildVatReport, getVat } from './bonus';
import { dateRangeFormat } from '../time';
import BigNumber from 'bignumber.js';
import { createDate, DateFormat, formatInUtc } from '@letsdeel/ui';
import { formatInTimeZone } from 'date-fns-tz';
import { format, isValid, parseISO } from 'date-fns';

// Takes statuses (ex. "active", "pending") and a contract. Returns all of the reports of that contract that contains
// those statuses.
const getReportsByStatus = (statuses, contract) => {
  if (!contract?.paymentCycles) return [];
  let reports = [];

  contract.paymentCycles.map((cycle) => {
    let cycleReports = [];

    // Add the cycle status to the report
    cycleReports = cycle.reports.map((report) => ({ ...report, cycleStatus: cycle.status, cycleId: cycle.id }));

    return (reports = reports.concat(cycleReports));
  });

  return reports.filter(({ status }) => statuses.has(status));
};

/** ================================================================
 * Returns all reports under a given contract
 * @param {object} contract
 * @param {bool} includeVAT Should include vat in the report result
 * @param {string | Array}  cycleStatus Cycle statuses to filter by
 * @returns {Array}
 * ================================================================= */
export const getSubmittedReports = (
  contract,
  includeVAT = false,
  cycleStatus = null,
  cycleId = null,
  reportStatuses = ['pending', 'approved', 'overdue', 'processing', 'declined']
) => {
  const reports = getReportsByStatus(new Set(reportStatuses), contract);

  if (includeVAT) {
    if (cycleId) {
      const vat = getVat(contract, null, cycleId);
      if (vat) reports.push(buildVatReport(vat));
    } else {
      (Array.isArray(cycleStatus) ? cycleStatus : [cycleStatus]).forEach((status) => {
        const vat = getVat(contract, status, cycleId);
        if (vat) reports.push(buildVatReport(vat));
      });
    }
  }

  if (cycleId) {
    return reports.filter((report) => report.cycleId === cycleId);
  } else if (cycleStatus) {
    if (Array.isArray(cycleStatus)) return reports.filter((report) => cycleStatus.includes(report.cycleStatus));
    return reports.filter((report) => report.cycleStatus === cycleStatus);
  }

  return reports;
};

export const getPendingReports = (contract) => getReportsByStatus(new Set(['pending']), contract);

export const getPendingReportIds = (contract) => getPendingReports(contract).map(({ id }) => id);

export const getReportTime = (
  amount,
  scale,
  customScaleName = '',
  /** @type {object | null} */ paymentCycle = null,
  /** @type {string | null} */ timezone = null
) => {
  if (Number(amount) === 0 || amount === undefined) return getCycleText(paymentCycle, true, timezone, ' ');
  if (scale === 'custom')
    return `${amount} ${customScaleName ? customScaleName?.toLowerCase() : 'task'}${
      amount !== 1 ? 's' : ''
    }  ${getCycleText(paymentCycle, true, timezone)}`;
  if (scale !== 'hourly')
    return `${amount} ${getScaleNoun(scale)}${amount !== 1 ? 's' : ''} ${getCycleText(paymentCycle, true, timezone)}`;

  const amounts = amount?.toString().split('.');

  const hours = amounts ? amounts[0] : 0;

  const scaleText = getScaleNoun(scale);

  let unitScale = 'min';
  let unitsOfScale = MINUTES_IN_HOUR;

  switch (scaleText) {
    case 'day':
      unitScale = 'hour';
      unitsOfScale = HOURS_IN_DAY;
      break;
    case 'week':
      unitScale = 'day';
      unitsOfScale = DAYS_IN_WEEK;
      break;
    case 'month':
      unitScale = 'day';
      unitsOfScale = DAYS_IN_MONTH;
      break;
  }

  const units = new BigNumber(`0.${amounts[1] || 0}`).multipliedBy(unitsOfScale).toFixed(0, 6);

  let timeText = `${hours !== 0 ? hours : ''} ${hours !== 0 ? scaleText : ''}${Number(hours) !== 1 ? 's' : ''} ${
    units > 0 ? `${units} ${unitScale}${units !== 1 ? 's' : ''}` : ''
  }`;
  if (paymentCycle) timeText += ` ${getCycleText(paymentCycle, true, timezone)}`;
  return timeText;
};

export const getCycleText = (
  paymentCycle,
  lowercase = false,
  timezone,
  /** @type {string | null} */ customPrefix = null,
  toUtc = false
) => {
  if (!paymentCycle) return '';

  const prefix = customPrefix
    ? customPrefix
    : lowercase
    ? i18n.t('temp.unknown.utils.contract.39e61d57e9').toLowerCase()
    : i18n.t('temp.unknown.utils.contract.39e61d57e9');
  let from = paymentCycle.start || paymentCycle.paidAt || paymentCycle.createdAt;
  let to = paymentCycle.end;

  if (toUtc) {
    if (!isValid(parseISO(from))) {
      console.error('Make sure you provided correct paymentCycle data');
      return '';
    }
    from = formatInUtc(parseISO(from), DateFormat.DATE_TIME);
    to = isValid(parseISO(to)) ? formatInUtc(parseISO(to), DateFormat.DATE_TIME) : undefined;
  }

  return `${prefix} ${dateRangeFormat(from, to, toUtc ? undefined : timezone)}`;
};

export const getOnDemandText = (date, timezone) => {
  if (!date) return '';
  return i18n.t('temp.unknown.utils.contract.914b0a97d1', {
    v0: formatMixedDate(date, DateFormat.DATE_YEAR, timezone),
  });
};

export const getOffCycleText = (date, timezone) => {
  if (!date) return '';
  return `One-off ${formatMixedDate(date, DateFormat.DATE_YEAR, timezone)} `;
};

export const getCreditNodeText = (date, timezone) => {
  if (!date) return i18n.t('temp.unknown.utils.contract.f338661e49');

  return i18n.t('temp.unknown.utils.contract.4a78f55b01', {
    v0: formatMixedDate(date, DateFormat.DATE_YEAR, timezone),
  });
};

export const getOverdueCyclesRange = (paymentCycles) => {
  const overdueReports = paymentCycles.filter(({ status }) => status === 'overdue');

  if (!overdueReports || !overdueReports?.length) return null;
  const start = overdueReports[overdueReports.length - 1]?.start;
  const end = overdueReports[0]?.end;

  return { start, end };
};

export const sumTimeWorked = (acc, current) => {
  const timeWorked = {
    weeks: (current?.weeks || 0) + (acc?.weeks || 0),
    days: (current?.days || 0) + (acc?.days || 0),
    hours: (current?.hours || 0) + (acc?.hours || 0),
    minutes: (current?.minutes || 0) + (acc?.minutes || 0),
  };

  if (timeWorked.minutes && timeWorked.minutes >= MINUTES_IN_HOUR) {
    const hours = parseInt(timeWorked.minutes / MINUTES_IN_HOUR);
    timeWorked.minutes -= hours * MINUTES_IN_HOUR;
    timeWorked.hours += hours;
  }

  if (timeWorked.hours && timeWorked.hours >= HOURS_IN_DAY) {
    const days = parseInt(timeWorked.hours / HOURS_IN_DAY);
    timeWorked.hours -= days * HOURS_IN_DAY;
    timeWorked.days += days;
  }

  return timeWorked;
};

export const getTimeWorkedText = (timeWorked, paymentCycle, timezone = null) => {
  if (!timeWorked) return;
  const { weeks, days, hours, minutes } = timeWorked;
  let res = '';

  if (weeks) res += `${weeks} weeks `;
  if (days) res += `${days} days `;
  if (hours) res += `${hours} hours `;
  if (minutes) res += `${minutes} minutes `;
  if (paymentCycle) res += getCycleText(paymentCycle, true, timezone);

  return res;
};

export const getUnpaidCycle = (contract) => {
  const activeCycle = contract.paymentCycles.find((paymentCycle) => paymentCycle.status === 'active');
  if (activeCycle && activeCycle.reports.some((report) => report.status !== 'paid')) {
    return activeCycle;
  }
  return undefined;
};

export const processShieldedOverdueReports = (contract) => {
  if (!contract.shieldAgreement) return;
  if (!contract?.paymentCycles?.length) return;

  contract?.paymentCycles?.forEach((cycle) => {
    if (cycle?.status !== 'overdue') return;
    const invoiceIds = cycle?.invoices
      ?.filter((invoice) => invoice?.status === 'processing')
      .map((invoice) => invoice.internalId);
    if (!invoiceIds?.length) return;

    cycle.reports = cycle.reports.filter((report) => {
      if (report.status === 'approved' && invoiceIds.includes(report.invoiceId)) return false;
      return true;
    });
  });

  contract.paymentCycles = contract?.paymentCycles?.filter((cycle) => {
    if (cycle?.status === 'overdue' && !cycle?.reports?.length) return false;
    return true;
  });
};

export const formatMixedDate = (date, dateFormat, tz) => {
  const dateObject = createDate(date);
  const hours = dateObject.getUTCHours();
  if (hours === 0) return format(dateObject, dateFormat);
  if (tz) {
    return formatInTimeZone(date, tz, dateFormat);
  } else {
    return format(dateObject, dateFormat);
  }
};
