import { addScript } from '@/external/utils';

const init = () => {
  addScript(
    'https://ob.withflowersea.com/i/6dbc8addd2d33178cb4b309463f5b34c.js',
    {
      'data-ch': 'app.deel',
      class: 'ct_clicktrue_49375',
      'data-jsonp': 'onCheqResponse',
    },
    undefined,
    'cheq'
  );
};

export default { init };
