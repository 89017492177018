import instance from '@/utils/api/instance';

export interface IPayrollEvent {
  id: string;
  start: string;
  lock: string;
  end: string;
  state: 'OPEN' | 'LOCKED' | 'CLOSED';
}

const getPayrollEventsByContractId = async (contractOid: string) =>
  (
    await instance.get(`/employment/payroll-events/eor-contract/${contractOid}?states=OPEN&states=LOCKED`, {
      suppressSnackbar: true,
    })
  ).data as IPayrollEvent[];

export default {
  getPayrollEventsByContractId,
};
