import { useCallback, useMemo } from 'react';
import useStores from '@/hooks/useStores';
import type { ITeamItem } from '@/types/Teams';

const useTeamRoles = () => {
  const { teams, user } = useStores();

  const getSingleTeamRolesAndSets = (team: ITeamItem) => {
    return team.permissionSets || [];
  };

  const allTeamsRoles: string[] = useMemo(() => {
    const availableRoles = new Set<string>();

    const currentOrgTeams = teams.teams.filter(
      (team: ITeamItem) =>
        user.organization?.id && team.organization?.id && team.organization?.id === user.organization?.id
    );

    for (const team of currentOrgTeams) {
      team.permissionSets?.forEach((permissionSet) => availableRoles.add(permissionSet as string));
    }
    return [...availableRoles];
  }, [teams.teams, user.organization?.id]);

  const getTeamRolesAndSets = useCallback(
    (teamId: number | string | null) => {
      const team = teamId ? teams.findTeamInTeams(teamId) : null;

      if ([0, '0', null, undefined, ''].includes(teamId)) return allTeamsRoles;

      if (!team) return [];

      return getSingleTeamRolesAndSets(team);
    },
    [teams, allTeamsRoles]
  );

  return { getTeamRolesAndSets };
};

export default useTeamRoles;
