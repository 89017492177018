export const RoleNameToIdMap = {
  NORMAL: -1,
  ORGANIZATION_ADMIN: -2,
  IT_DEVELOPER_ADMIN: -3,
  INTEGRATIONS_ADMIN_PEOPLE: -4,
  INTEGRATIONS_ADMIN_BOOKKEEPER: -5,
  ADMIN: -6,
  ONBOARDING_SPECIALIST: -8,
  ONBOARDING_SPECIALIST_ADJUSTER: -9,
  ONBOARDING_SPECIALIST_ADJUSTER_HRISAPPROVER: -10,
  ONBOARDING_SPECIALIST_APPROVER: -11,
  ONBOARDING_SPECIALIST_APPROVER_ADJUSTER: -12,
  ONBOARDING_SPECIALIST_APPROVER_ADJUSTER_HRISAPPROVER: -13,
  ONBOARDING_SPECIALIST_APPROVER_HRISAPPROVER: -14,
  ONBOARDING_SPECIALIST_HRISAPPROVER: -15,
  PAYER: -16,
  PAYER_ADJUSTER: -17,
  PAYER_APPROVER: -18,
  PAYER_APPROVER_ADJUSTER: -19,
  PEOPLE_MANAGER: -20,
  PEOPLE_MANAGER_ADJUSTER: -21,
  PEOPLE_MANAGER_ADJUSTER_HRISAPPROVER: -22,
  PEOPLE_MANAGER_AND_PAYER_ADJUSTER: -23,
  PEOPLE_MANAGER_AND_PAYER_ADJUSTER_HRISAPPROVER: -24,
  PEOPLE_MANAGER_AND_PAYER_APPROVER_ADJUSTER: -25,
  PEOPLE_MANAGER_AND_PAYER_APPROVER_ADJUSTER_HRISAPPROVER: -26,
  PEOPLE_MANAGER_APPROVER: -27,
  PEOPLE_MANAGER_APPROVER_ADJUSTER: -28,
  PEOPLE_MANAGER_APPROVER_ADJUSTER_HRISAPPROVER: -29,
  PEOPLE_MANAGER_APPROVER_HRISAPPROVER: -30,
  PEOPLE_MANAGER_HRISAPPROVER: -31,
  SALES: -32,
  VIEWER_NON_SENSITIVE: -33,
  VIEWER_SENSITIVE: -34,
  VIEWER_SENSITIVE_APPROVER: -35,
  VMS_ADMIN: -48,
};

export enum RoleId {
  OrganizationAdmin = -2,
  ItDeveloperAdmin = -3,
  IntegrationsAdminPeople = -4,
  IntegrationsAdminBookkeeper = -5,
  Normal = -1,
  PeopleManager = -20,
  PeopleManagerApprover = -27,
  PeopleManagerAdjuster = -21,
  PeopleManagerApproverAdjuster = -28,
  PeopleManagerHrisapprover = -31,
  PeopleManagerApproverHrisapprover = -30,
  PeopleManagerAdjusterHrisapprover = -22,
  PeopleManagerApproverAdjusterHrisapprover = -29,
  Payer = -16,
  PayerApprover = -18,
  PayerAdjuster = -17,
  PayerApproverAdjuster = -19,
  PeopleManagerAndPayerAdjuster = -23,
  PeopleManagerAndPayerApproverAdjuster = -25,
  PeopleManagerAndPayerAdjusterHrisapprover = -24,
  PeopleManagerAndPayerApproverAdjusterHrisapprover = -26,
  OnboardingSpecialist = -8,
  OnboardingSpecialistApprover = -11,
  OnboardingSpecialistAdjuster = -9,
  OnboardingSpecialistApproverAdjuster = -12,
  OnboardingSpecialistHrisapprover = -15,
  OnboardingSpecialistApproverHrisapprover = -14,
  OnboardingSpecialistAdjusterHrisapprover = -10,
  OnboardingSpecialistApproverAdjusterHrisapprover = -13,
  ViewerSensitive = -34,
  ViewerSensitiveApprover = -35,
  ViewerNonSensitive = -33,
  Sales = -32,
  Admin = -6,
  ViewerNonSensitiveAdjuster = -37,
  ViewerNonSensitiveApprover = -36,
  ViewerNonSensitiveApproverAdjuster = -38,
  ResellerEndClient = -44,
  DeelEngageAdmin = -45,
  EntityRoleReportsAccess = -46,
  VmsAdmin = -48,
}

export const adjusterRoles = [
  RoleId.Admin,
  RoleId.PeopleManagerAdjuster,
  RoleId.PeopleManagerApproverAdjuster,
  RoleId.PeopleManagerApproverAdjusterHrisapprover,
  RoleId.PeopleManagerAdjusterHrisapprover,
  RoleId.PayerAdjuster,
  RoleId.PayerApproverAdjuster,
  RoleId.PeopleManagerAndPayerAdjuster,
  RoleId.PeopleManagerAndPayerApproverAdjuster,
  RoleId.PeopleManagerAndPayerAdjusterHrisapprover,
  RoleId.PeopleManagerAndPayerApproverAdjusterHrisapprover,
  RoleId.OnboardingSpecialistAdjuster,
  RoleId.OnboardingSpecialistApproverAdjuster,
  RoleId.OnboardingSpecialistApproverAdjusterHrisapprover,
  RoleId.OnboardingSpecialistAdjusterHrisapprover,
  RoleId.ViewerNonSensitiveAdjuster,
  RoleId.ViewerNonSensitiveApproverAdjuster,
];

export const approverRoles = [
  RoleId.Admin,
  RoleId.PeopleManagerApprover,
  RoleId.PeopleManagerApproverAdjuster,
  RoleId.PeopleManagerApproverAdjusterHrisapprover,
  RoleId.PeopleManagerApproverHrisapprover,
  RoleId.PayerApprover,
  RoleId.PayerApproverAdjuster,
  RoleId.PeopleManagerAndPayerApproverAdjuster,
  RoleId.PeopleManagerAndPayerApproverAdjusterHrisapprover,
  RoleId.OnboardingSpecialistApprover,
  RoleId.OnboardingSpecialistApproverAdjuster,
  RoleId.OnboardingSpecialistApproverAdjusterHrisapprover,
  RoleId.OnboardingSpecialistApproverHrisapprover,
  RoleId.ViewerSensitiveApprover,
  RoleId.ViewerNonSensitiveApprover,
  RoleId.ViewerNonSensitiveApproverAdjuster,
];

export const hrisApproverRoles = [
  RoleId.Admin,
  RoleId.PeopleManagerAdjusterHrisapprover,
  RoleId.PeopleManagerApproverAdjusterHrisapprover,
  RoleId.PeopleManagerApproverHrisapprover,
  RoleId.PeopleManagerAndPayerAdjusterHrisapprover,
  RoleId.PeopleManagerAndPayerApproverAdjusterHrisapprover,
  RoleId.OnboardingSpecialistAdjusterHrisapprover,
  RoleId.OnboardingSpecialistApproverAdjusterHrisapprover,
  RoleId.PeopleManagerHrisapprover,
  RoleId.OnboardingSpecialistHrisapprover,
  RoleId.OnboardingSpecialistApproverHrisapprover,
];
