import React, { useState } from 'react';
import { parseJwt } from '@/utils/main';
import get from 'lodash/get';
import accountApi from '@/utils/api/account';
import { useModal, H3 } from '@letsdeel/ui';
import useStores from '@/hooks/useStores';
import useUserStore from '@/hooks/useUserStore';
import MultiStepPopup from '@/components/MultiStepPopup/MultiStepPopup';
import MultiStepPopupStep from '@/components/MultiStepPopup/MultiStepPopupStep';

import TFASetupDownload from './steps/TFASetupDownload';
import TFASetupLink from './steps/TFASetupLink';
import TFASetupEnable from '@/scenes/Settings/scenes/SecuritySettings/components/TFASetupPopup/steps/TFASetupEnable';
import InfoPopup from '@/components/InfoPopup';
import type { OtpPopupContentProps } from '@/components/OtpPopupContent/OtpPopupContent';
import { useTranslation } from 'react-i18next';

interface Props {
  show: boolean;
  partialLoginToken?: string;
  onHide: () => void;
  onSuccess?: () => void;
  onResetSuccess?: OtpPopupContentProps['onSubmit'];
  noSubmitAfterSmsReset?: boolean;
  totpValidatedToken?: string;
}

enum Steps {
  Download,
  Link,
  Enable,
  Success,
}

const TFASetupPopup = ({
  show,
  onHide,
  onSuccess,
  noSubmitAfterSmsReset,
  onResetSuccess,
  totpValidatedToken,
  partialLoginToken,
}: Props) => {
  const user = useUserStore();
  const { showSnackbar } = useStores();
  const [activeStep, setActiveStep] = useState<Steps>(Steps.Download);
  const [isLoading, setIsLoading] = useState(false);
  const [qrCode, setQrCode] = useState<string>();
  const [token, setToken] = useState<string>();
  const [secret, setSecret] = useState<string>();
  const [code, setCode] = useState(Array(6).fill(''));
  const [error, setError] = useState<string>();
  const { closeAllModals } = useModal();

  const { t } = useTranslation();

  const reset = () => {
    setActiveStep(Steps.Download);
    setIsLoading(false);
    setQrCode(undefined);
    setToken(undefined);
    setError(undefined);
    setCode(Array(6).fill(''));
  };

  const handleNext = () => {
    switch (activeStep) {
      case Steps.Download:
        return onConfirmSetUp();
      case Steps.Enable:
        return onSubmitTotp();
      default:
        setActiveStep((step) => step + 1);
    }
  };

  const onConfirmSetUp = async () => {
    setIsLoading(true);

    try {
      const { qrcode, totpSecretGeneratedToken } = await accountApi.getQRData({
        totpValidatedToken,
        isLoginScreen: !!partialLoginToken,
      });

      setQrCode(qrcode);
      setToken(totpSecretGeneratedToken);
      setSecret(parseJwt(totpSecretGeneratedToken)?.secret);

      setActiveStep(Steps.Link);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmitTotp = async () => {
    if (!token || !code) return;
    setIsLoading(true);

    try {
      await accountApi.setTotp({ totpSecretGeneratedToken: token, code: code.join(''), partialLoginToken });

      user.update({
        totp: true,
        otpOptions: ['app', ...user.otpOptions],
      });
      setActiveStep(Steps.Success);
    } catch (error: any) {
      switch (get(error, 'response.status', 0)) {
        case 500:
          showSnackbar(t('settings.authenticator.setupPopup.codeExpired'));
          setActiveStep(Steps.Download);
          return;
        case 400:
          return setError(t('settings.authenticator.setupPopup.incorrectCode'));
        default:
          return console.error(error);
      }
    } finally {
      setIsLoading(false);
      setCode(Array(6).fill(''));
    }
  };

  const beforeHide = () => {
    onHide();
    setTimeout(reset, 300); // to prevent flickering upon dismissal
  };

  const handleSuccess = () => {
    closeAllModals();
    beforeHide();
    if (onSuccess) setTimeout(onSuccess, 300);
    if (onResetSuccess && !noSubmitAfterSmsReset) setTimeout(() => onResetSuccess(code.join(''), 'app'), 300);
  };

  return activeStep < Steps.Success ? (
    <MultiStepPopup
      centered
      show={show}
      stepIndex={activeStep}
      onHide={beforeHide}
      title={t('settings.authenticator.setupPopup.enableUpAuthenticator')}
      subtitle={t('settings.authenticator.setupPopup.twoFactorAuthentication')}
      onNext={handleNext}
      setStepIndex={setActiveStep}
      isLoading={isLoading}
      className="TFA-setup"
      backButton={activeStep > Steps.Download}
      isNextDisabled={activeStep === Steps.Enable && code.length !== 6}
      closeButton
    >
      <MultiStepPopupStep
        popupTitle={t('settings.authenticator.setupPopup.enableAuthenticatorApp')}
        nextName={t('settings.authenticator.setupPopup.nextLinkAuthenticatorApp')}
        nextButtonText={t('settings.authenticator.setupPopup.next')}
      >
        <TFASetupDownload />
      </MultiStepPopupStep>

      <MultiStepPopupStep
        popupTitle={t('settings.authenticator.setupPopup.enableAuthenticatorApp')}
        nextName={t('settings.authenticator.setupPopup.nextLinkAuthenticatorApp')}
        nextButtonText={t('settings.authenticator.setupPopup.next')}
      >
        {secret && qrCode ? <TFASetupLink secret={secret} qrCode={qrCode} /> : null}
      </MultiStepPopupStep>

      <MultiStepPopupStep
        popupTitle={t('settings.authenticator.setupPopup.enableAuthenticatorApp')}
        nextButtonText={t('settings.authenticator.setupPopup.enable')}
        nextName={t('settings.authenticator.setupPopup.lastStep')}
      >
        <TFASetupEnable onCodeChange={setCode} error={error} isLoading={isLoading} code={code} />
      </MultiStepPopupStep>
    </MultiStepPopup>
  ) : (
    <InfoPopup
      show={activeStep === Steps.Success}
      onClick={handleSuccess}
      illustration="authenticator-success"
      title={t('settings.authenticator.setupPopup.successSetupAuthenticatorApp')}
    >
      <H3 color="neutral.darker">{t('settings.authenticator.setupPopup.maintainAccessAuthenticatorApp')} </H3>
    </InfoPopup>
  );
};

export default TFASetupPopup;
