import instance from '@/utils/api/instance';
export interface ScheduledEditParams {
  limit?: number;
  cursor?: string;
  search?: string;
  sort_by?: string;
  sort_order?: string;
  from_date?: string;
  to_date?: string;
}

export interface ScheduledEdit {
  value: string;
  field: string;
  effectiveFrom: Date;
  createdAt: Date;
  id: string;
  reason: string;
  modifierName: string;
  modifierPicUrl?: string;
  profileFirstName: string;
  profileLastName: string;
  profilePicUrl: string;
  previewsValue: string;
}

export interface ScheduledEditData {
  data: {
    data: ScheduledEdit[];
    cursor?: string;
  };
}

export default {
  getScheduledEdits: async (params: ScheduledEditParams): Promise<ScheduledEditData> => {
    return await instance.get('/hris/mass_edit/scheduled/list', {
      params,
    });
  },

  deleteScheduledEdit: async (oid: string): Promise<any> => {
    return await instance.delete(`/hris/mass_edit/scheduled/${oid}`);
  },
};
