import { parseJwt } from '@/utils/main';
import { EventActions, EventProducts } from '@/utils/analytics/constants';
import { addDataLayerMobile } from '@/utils/nativeDataLayer';

export interface State {
  from?: string;
  userType?: string;
  token?: string;
}

interface IGetSignupDataFromLocation {
  [key: string]: string;
}

export const getSignupDataFromLocation = (location: Location): IGetSignupDataFromLocation | null => {
  const urlSearchParams = new URLSearchParams(location.search);
  // Backward compatability
  const forceSignupUserType = urlSearchParams.get('force-signup-user-type');
  if (forceSignupUserType) {
    return { userType: forceSignupUserType };
  }
  // New query string param
  const signupData = urlSearchParams.get('signup');
  if (signupData) {
    try {
      return JSON.parse(atob(signupData));
    } catch (error) {
      console.error(error);
    }
  }

  const tokenData = urlSearchParams.get('token');

  if (tokenData) {
    const decoded = parseJwt(tokenData);
    if (decoded) {
      decoded.token = tokenData;
    }
    return decoded;
  }

  return null;
};

export const getOriginalLocationFromLocation = (location: Location): string =>
  `${location.pathname}${location.search}${location.hash}`;

export const getSignUpUserTypeFromState = (state?: State) => {
  const userType = state?.userType;
  if (userType === 'contractor') {
    return userType;
  }
  return null;
};

export const addSignupUserStateToURL = (href: string, userType: string) => {
  const url = new URL(href);
  url.searchParams.set('signup', btoa(JSON.stringify({ userType })));
  return url.href;
};

export const addNativeSSOEvent = (step_name: string) =>
  addDataLayerMobile({
    event_product: EventProducts.Login,
    event_action: EventActions.SSOFlow,
    step_name: step_name,
  });
