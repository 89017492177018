import { observable, reaction, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { lazy, Component, Suspense } from 'react';
import animate from '../../utils/animate';
import { CircularProgress, MuiBox, Stack } from '@letsdeel/ui';
import { withThemeContext } from '@/hocs/withThemeContext';

import ErrorBoundary, { ErrorBoundaryOwners } from '@/components/ErrorBoundary/ErrorBoundary';
import lightAnimation from '@/assets/animations/rebrand_logo_animation.json';
import darkAnimation from '@/assets/animations/white_deel_rebrand_logo_animation.json';
import { changeStatusBarColorAndroid, changeToDefaultThemeColorAndStyle } from '@/utils/nativeStatusBar';
import { WhiteLabelContext } from '@/context/WhiteLabel/WhiteLabelContext';
import DeelLoading from '@/components/Loading/components/DeelLoading';

const Lottie = lazy(() => import('lottie-react'));

const LoadingScreen = observer(
  class LoadingScreen extends Component {
    show = this.props.show;
    theme = this.props.themeContext.activeDeelUITheme;
    static contextType = WhiteLabelContext;

    constructor(props) {
      super(props);

      makeObservable(this, {
        show: observable,
      });
    }

    componentDidMount() {
      this.reaction = reaction(
        () => this.props.show,
        (show) => {
          if (show) return (this.show = show);
          setTimeout(() => {
            animate({
              ref: this.loadingScreen,
              duration: 0.25,
              animation: 'fadeOut',
              callback: () => {
                this.show = false;
              },
            });
          }, 100);
        }
      );
      changeStatusBarColorAndroid(this.theme === 'dark' ? '#1b1b1b' : '#f7f5f2', false);
    }

    componentWillUnmount() {
      this.reaction();
      changeToDefaultThemeColorAndStyle();
    }

    ref = (ref) => (this.loadingScreen = ref);

    render() {
      if (!this.show) return null;
      return (
        <MuiBox
          ref={this.ref}
          sx={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            top: 0,
            left: 0,
            backgroundColor: this.theme === 'dark' ? '#1b1b1b' : '#f7f5f2',
            zIndex: 999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          data-qa="page-loader"
        >
          <ErrorBoundary owner={ErrorBoundaryOwners.unknown} fallback={null}>
            <Suspense fallback={null}>
              {this.context.showCircularProgress ? (
                <Stack>{this.context.isWhiteLabelEnabled ? <CircularProgress size={30} /> : <DeelLoading />}</Stack>
              ) : null}
              {this.context.isWhiteLabelEnabled &&
              !this.context.showCircularProgress &&
              (this.context.logos?.lightMode?.md || this.context.logos?.darkMode?.md) ? (
                <Stack gap={2} alignItems="center" justifyContent="center">
                  <MuiBox sx={{ img: { maxWidth: '110px', height: 'auto' } }}>
                    <img
                      src={this.theme === 'dark' ? this.context.logos?.darkMode?.md : this.context.logos?.lightMode?.md}
                      alt="logo"
                    />
                  </MuiBox>
                  <CircularProgress size={30} />
                </Stack>
              ) : null}
              {!this.context.isWhiteLabelEnabled && !this.context.showCircularProgress ? (
                <Lottie
                  animationData={this.theme === 'dark' ? darkAnimation : lightAnimation}
                  loop
                  rendererSettings={{
                    preserveAspectRatio: 'xMaxYMax meet',
                  }}
                  style={{ width: '180px', height: '180px' }}
                />
              ) : null}
            </Suspense>
          </ErrorBoundary>
        </MuiBox>
      );
    }
  }
);

export default withThemeContext()(LoadingScreen);
