import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { Cell, H1, H4, Icon, Radio, Stack } from '@letsdeel/ui';
import Flex from '@/components/Flex';
import Button from '@/components/Button';

interface Props {
  isBackupEmailReset: boolean;
  onGoBack: () => void;
  setIsBackupEmailReset: (option: boolean) => void;
  confirmPreferredEmail: () => void;
}

const AlternativeOptions = ({ isBackupEmailReset, onGoBack, setIsBackupEmailReset, confirmPreferredEmail }: Props) => {
  const clearAndGoBack = () => {
    onGoBack();
    setIsBackupEmailReset(false);
  };

  return (
    <Stack gap={2}>
      <Stack gap={2}>
        <Button type="button" theme="link" onClick={clearAndGoBack} className="text-decoration-none">
          <Flex>
            <Icon.ArrowNew className="mr-5" size={15} />
            {i18n.t('temp.platform-fe.scenes.ResetPassword.0557fa923d').toString()}
          </Flex>
        </Button>
      </Stack>
      <H1 textAlign="center">{i18n.t('temp.platform-fe.scenes.ResetPassword.160eedc968').toString()}</H1>
      <H4 regular textAlign="center" color="text.secondary">
        {i18n.t('temp.platform-fe.scenes.ResetPassword.3f78e41755').toString()}
      </H4>
      <Cell onClick={() => setIsBackupEmailReset(false)}>
        <Radio name="primary-email" checked={!isBackupEmailReset} />
        {i18n.t('temp.platform-fe.scenes.ResetPassword.01742b0fa8').toString()}
      </Cell>
      <Cell onClick={() => setIsBackupEmailReset(true)}>
        <Radio name="backup-email" checked={isBackupEmailReset} />
        {i18n.t('temp.platform-fe.scenes.ResetPassword.568f23aba8').toString()}
      </Cell>
      <Button type="button" onClick={confirmPreferredEmail}>
        {i18n.t('temp.platform-fe.scenes.ResetPassword.a0bfb8e59e').toString()}
      </Button>
      <p className="text-center">
        <Trans
          i18nKey="temp.platform-fe.scenes.ResetPassword.29d8e3b832"
          components={[
            <a
              href="https://help.letsdeel.com/hc/en-gb/requests/new?ticket_form_id=360001676798"
              rel="noreferrer"
              target="_blank"
              key="7825056045_0"
            />,
          ]}
        />
      </p>
    </Stack>
  );
};

export default AlternativeOptions;
