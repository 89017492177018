import instance from '@/utils/api/instance';
import log from 'loglevel';

export default {
  getMyProfile: async () => {
    const { data } = await instance.get('/profiles/me', { cachedRequest: true });
    // load user company data
    if (data.companyId) {
      const { data: company } = await instance.get(`companies/${data.companyId}`);

      data.companyData = {
        ...company,
        // remove user from shareholder
        shareholders: (company?.shareholders || {}).filter(({ name }: { name: string }) => name !== data.name),
      };
    }
    // // @ts-ignore
    log.trace('me', data);

    // // @ts-ignore
    log.info(`signed in as ${data.email} (${data.id})`, data.profileType);
    return data;
  },

  updateUser: async (payload: any, headers?: any) => {
    const { data } = await instance.patch('profiles/me', payload, {
      withCredentials: true,
      headers,
    });

    return data;
  },

  deleteAvatar: async () => {
    const { data } = await instance.delete('profiles/me/avatar');
    return data;
  },

  deleteSignatureFile: async () => {
    const { data } = await instance.delete('profiles/me/signature-file');
    return data;
  },

  updateProfileCitizenship: async (payload: { contractOid: string; countries: string[] }) => {
    const { data } = await instance.patch('profile_citizenship', payload);
    return data;
  },
};
