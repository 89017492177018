import type { Employment } from './Employment';
import type { LegalEntity } from './LegalEntity';
import type { PayrollReportItem } from './PayrollReportItem';

export enum EmployeeStatus {
  ACTIVE = 'ACTIVE',
  STARTER = 'STARTER',
  LEAVER = 'LEAVER',
  AMENDED = 'AMENDED',
  ON_LEAVE = 'ON_LEAVE',
  TERMINATED = 'TERMINATED',
}

export interface GPContractData {
  id: number | string;
  oid: string;
  status: string;
  completionDate: string | null;
  contractType: 'peo' | 'global_payroll';
  HrisProfile?: { oid: string };
}

export interface EmploymentPayrollEvent {
  id: number;
  employmentId: string;
  payrollEventId: string;
  previousEventId: string;
  contract: GPContractData;
  employment: Employment;
  payrollReportItems: PayrollReportItem[];
  employeeStatus: EmployeeStatus;
  /**
   * Only available in the endpoint `/payroll_events/:payrolLEventId/employee_status_changes`
   * */
  previousEmployeeStatus: EmployeeStatus;
  createdAt: string;
  createdBy: string | null;
  updatedAt: string;
  previousPayrollReportItems?: PayrollReportItem[];
  g2nPayrollReportItems: any[];
  legalEntity?: LegalEntity;
  reducedData?: {
    [key: string]: {
      currentTotal: number;
      prevTotal: number | null;
      currentCurrencyTotal: number;
      currentPercentageOrDaysTotal: number;
    };
  };
}

export type UpdateCorrectionsParam = {
  employmentIds: string[];
  name: string;
  description: string;
  payrollEventId: string;
};

export interface Correction {
  payrollEventId: string;
  name: string;
  description: string;
  payrollsList: EmploymentPayrollEvent[];
}
