import instance from '@/utils/api/instance';

export interface Answer {
  [key: string]: string;
}

export default {
  loadAssessmentQuestions: async (msaContractOid: string | undefined) => {
    const { data } = await instance.get('assessments/questionnaires', {
      params: { type: 'shield_eligibility', msaContractOid },
    });
    return data;
  },

  sendAssessmentAnswers: async (answers: Array<Answer>) => {
    const { data } = await instance.post('/assessments/verify_eligibility', { type: 'shield_eligibility', answers });
    return data;
  },

  saveAssessment: async (contractId: number, answers: Array<Answer>, msaContractId: number) => {
    const { data } = await instance.post('/assessments', {
      type: 'shield_eligibility',
      contractId,
      answers,
      msaContractId,
    });
    return data;
  },

  connectContractToAgreement: async (contractId: number, agreementId: number) => {
    const { data } = await instance.post('/connect_contract_to_shielded_contracts', {
      contractId,
      agreementId,
    });
    return data;
  },

  getRecommendedCoverage: async (country: string, contractType: string) => {
    const { data } = await instance.get('/contract_coverage/recomendation', {
      params: { contractType, country },
    });
    return data;
  },

  getRefundMethod: async (contractId: string | number) => {
    const { data } = await instance.get(`shield/contracts/${contractId}/refund_method`);
    return data;
  },

  setRefundMethod: async (id: string, payload: { refundMethodId: string | null }) => {
    const { data } = await instance.put(`shield/contracts/${id}/refund_method`, payload);
    return data;
  },

  getDepositRefund: async (contractId: string | number) => {
    const { data } = await instance.get(`shield/contracts/${contractId}/deposit_refunds`);
    return data;
  },

  getOriginalContractFromUpgrade: async (contractId: number | string) => {
    const { data } = await instance.get(`shield/contracts/${contractId}/original_contract`);
    return data;
  },

  getMsaActiveCycle: async (contractId: number | string, msaContractOid: number | string) => {
    const { data } = await instance.get(`shield/contracts/${contractId}/msa_active_payment_cycle/${msaContractOid}`);
    return data;
  },
};
