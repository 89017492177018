import { useMemo } from 'react';
import { CONTACT_OTP_2FA_SUPPORT_LINK_PROPS } from '@/constants/support';
import useUserStore from '@/hooks/useUserStore';
import { useOpenChatbot } from '@/hooks/support/chatbot/useOpenChatbot';

const useSupportLinkProps = () => {
  const user = useUserStore();
  const openChatbot = useOpenChatbot();
  return useMemo(() => {
    if (!user.token) return { ...CONTACT_OTP_2FA_SUPPORT_LINK_PROPS };

    return {
      onClick: () => {
        openChatbot();
      },
    };
  }, [user.token, openChatbot]);
};

export default useSupportLinkProps;
