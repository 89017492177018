import { useEffect, useRef, useState } from 'react';
import { type EmailVerificationProps } from '@/scenes/Login/components/EmailVerification/types';

export const useEmailVerification = ({
  resend,
  goBackAction,
  setIsBackupEmailReset,
}: Pick<EmailVerificationProps, 'resend' | 'goBackAction' | 'setIsBackupEmailReset'>) => {
  const timerRef = useRef<NodeJS.Timeout>();

  const [confirmationWasSent, setConfirmationWasSent] = useState(false);
  const [isResending, setIsResending] = useState(false);

  useEffect(() => {
    const timer = timerRef.current;
    return () => clearTimeout(timer);
  }, []);

  const resendActionTimer = async (options?: { recaptcha?: string }) => {
    if (confirmationWasSent) return;

    try {
      setIsResending(true);
      const resendResult = await resend({ recaptcha: options?.recaptcha });

      if (resendResult) {
        setConfirmationWasSent(true);
        timerRef.current = setTimeout(() => {
          setConfirmationWasSent(false);
        }, 30 * 1000);
      }
    } finally {
      setIsResending(false);
    }
  };

  const onRecaptcha = (token: string) => resendActionTimer({ recaptcha: token });

  const clearAndGoBack = () => {
    goBackAction?.();
    setIsBackupEmailReset(false);
  };

  return {
    onRecaptcha,
    isResending,
    clearAndGoBack,
    confirmationWasSent,
    resendActionTimer,
  };
};
