import { useCallback } from 'react';
import userApi from '@/utils/api/user';
import { ROUTES } from '@/constants/routesV2';
import { useHistory } from 'react-router-dom';

const useCheckResetToken = (onSuccess?: () => void) => {
  const history = useHistory();
  const checkToken = useCallback(
    async (token: string, isLoggedIn?: boolean) => {
      try {
        await userApi.reset({
          token,
        });
        onSuccess?.();
      } catch (error: any) {
        const errorStatus =
          error?.response?.status === 410 || error?.response?.message?.includes('expired')
            ? 'expired'
            : error?.response?.status === 401
            ? 'notFound'
            : 'other';
        history.push(
          isLoggedIn
            ? ROUTES.settings.securityResetPasswordError.replace(':errorStatus', errorStatus)
            : ROUTES.resetPasswordError.replace(':status', errorStatus),
          { isLoggedIn }
        );
      }
    },
    [history, onSuccess]
  );

  return { checkToken };
};

export default useCheckResetToken;
