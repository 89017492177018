import type { AxiosRequestConfig } from 'axios';
import type { Key } from 'swr';
import type { MutationFetcher, SWRMutationConfiguration, SWRMutationResponse } from 'swr/mutation';
import useSWRMutation from 'swr/mutation';

import instance from '@/utils/api/instance';
type ExtraArg = unknown;

function getFetcher<Response, Payload = ExtraArg>(config: AxiosRequestConfig) {
  return async (url: string, { arg }: { [key: string]: Payload }) => {
    const res = await instance.request<Response>({ url, data: arg, ...config });
    return res.data;
  };
}

const useMutation = <Response, Payload = ExtraArg, ResponseError = Error>(
  url: Key,
  config: AxiosRequestConfig,
  options?: SWRMutationConfiguration<Response, ResponseError, Payload, Key>,
  fetcher: MutationFetcher<Response, Payload, Key> = getFetcher(config)
): SWRMutationResponse<Response, ResponseError, Payload> =>
  useSWRMutation<Response, ResponseError, Key, Payload>(url, fetcher, options);

export default useMutation;
