import type { ReactNode } from 'react';
import type { PopupProps } from '../Popup';
import Popup from '../Popup';
import './style.less';

export type PartiallyScrollingPopupProps = {
  contentBefore?: ReactNode;
  content?: ReactNode;
  children?: ReactNode;
  contentAfter?: ReactNode;
} & PopupProps;

const PartiallyScrollingPopup = ({
  contentBefore,
  content,
  children,
  contentAfter,
  ...rest
}: PartiallyScrollingPopupProps) => (
  <Popup centered {...rest} customFooter={contentAfter}>
    {contentBefore || null}
    {content || children}
  </Popup>
);

export default PartiallyScrollingPopup;
