import type { FC, PropsWithChildren } from 'react';

interface Props {
  popupTitle?: string;
  popupSubtitle?: string;
  currentName?: string;
  nextName?: string;
  nextButtonText?: string;
  onlyButton?: boolean;
  hideFooter?: boolean;
}

const MultiStepPopupStep: FC<PropsWithChildren<Props>> = ({ children }) => {
  return <>{children}</>;
};

export default MultiStepPopupStep;
