import type { ScopedMutator } from 'swr/_internal';
import type { RoleWithPermissions } from '@letsdeel/ui/dist/shared//NewRolesPermissionsList/RolesPermissionsList.props';
import type { OrganizationProfile } from '@/hooks/api/organizations/useFetchOrganizationProfiles';
import type { Address, LegalEntity } from './LegalEntity';

export type LegalEntityRequest = Partial<
  Omit<LegalEntity, 'address'> & {
    address: Partial<Address> | null;
  }
>;

export interface RoleWithPoliciesType extends RoleWithPermissions {
  isUsed: boolean;
  hrisOrgStructureId?: string | null;
  extra?: {
    approverPolicies?: Array<{ id: number; name: string; domain: string; description?: string | null }>;
  };
  rolePublicId?: string;
  hasMandatory2fa: boolean;
}

export interface IOrganizationsStore {
  legalEntities: LegalEntity[] | null;
  filteredLegalEntities: LegalEntity[];
  isShieldMsaOn: boolean;
  agreements: ShieldAgreement[];
  unsignedAgreements: ShieldAgreement[];
  defaultEntity: LegalEntity;
  activeOrgId: number | null;
  setMutate: (mutate: ScopedMutator) => ScopedMutator<any>;
  addEntity: (entity: LegalEntityRequest) => void;
  updateEntity: (entity: LegalEntityRequest) => void;
  loadProfiles: (id?: number) => Promise<void>;
  loadEntities: (
    id?: number,
    updateOrgIdListOnly?: boolean,
    withoutFilter?: boolean,
    allowReloadActiveOrg?: boolean
  ) => Promise<void>;
  loadEntitiesRaw: (id: number, withoutFilter?: boolean) => Promise<LegalEntity[] | undefined>;
  loadAgreements: () => Promise<void>;
  initialToggleShield: boolean;
  loadToggleShieldConfig: () => Promise<void>;
  deleteEntity: (id: number) => void;

  // @todo: add correct types here.
  loadUnsignedAgreements: () => Promise<void>;
  shieldProviders: any;
  profiles: OrganizationProfile[];
  roles: RoleWithPoliciesType[];
  loadRoles: (id?: number) => Promise<void>;
  hasMissingDetailsLabel(a: LegalEntity): boolean;
}

export type ShieldAgreement = {
  id: number;
  name: string;
  type: 'shield';
  msaContract: MsaContract;
  clientSignedAt: string | null;
  clientSignature: string | null;
  isSignedOffPlatform: boolean;
  clientLegalEntity: ClientLegalEntity;
  providerLegalEntity: ProviderLegalEntity;
  shieldedMinTerminationNoticeDays: number;
  sowRequiresSignature: boolean;
  honorNoticePeriod: boolean;
  fromAdmin: boolean;
};

type MsaContract = {
  id: number;
  oid: string;
  name: string;
  sow: Sow;
  timezone: string;
  status: string;
  clientSignedAt: string | null;
  effectiveDate: string | null;
};

type Sow = {
  setupFee: string;
  consultingFee: string;
  additionalFee: string;
  platformFee: string;
  platformFeeType: PlatformFeeType;
  minimumPlatformFee: string | null;
  depositFee: string;
  depositFeeType: DepositFeeType;
  minimumDepositFee: string | null;
};

export type PlatformFeeType = 'percentage' | 'fixed';

export type DepositFeeType = 'percentage' | 'fixed' | 'multiplier';

type ClientLegalEntity = {
  id: number;
  name: string;
};

type ProviderLegalEntity = {
  id: number;
  name: string;
};

export enum DeelProductEnum {
  EMPLOYER_OF_RECORD = 'EMPLOYER_OF_RECORD',
  CONTRACTOR_MANAGEMENT = 'CONTRACTOR_MANAGEMENT',
  DEEL_HR = 'DEEL_HR',
  GLOBAL_PAYROLL = 'GLOBAL_PAYROLL',
  DEEL_ENGAGE = 'DEEL_ENGAGE',
  PEO = 'PEO',
}

export type DeelProduct = keyof typeof DeelProductEnum;

export enum DeelHRIntentValue {
  NOT_SPECIFIED = 'not_specified',
  NOT_INTERESTED = 'not_interested',
  MAYBE = 'maybe',
  INTERESTED = 'interested',
}
