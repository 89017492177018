import i18n from 'i18next';
import type { H4Props, TypographyProps } from '@letsdeel/ui';
import { H4, Tooltip } from '@letsdeel/ui';
import { addDays, subMinutes, isValid } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import type { ReactElement } from 'react';

interface Props {
  date?: Date | string | null;
  format?: 'day' | 'daytime' | 'date' | 'datetime';
  prefix?: string | ReactElement;
  isBold?: boolean;
  component?: (props: H4Props | TypographyProps) => ReactElement;
}

export function UtcDateWithLocalTzTooltip({
  date,
  isBold = false,
  format = 'daytime',
  component: Component = H4,
  prefix,
}: Props) {
  if (!date || !isValid(new Date(date))) return null;

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const parsedDate = new Date(date);

  const isStartOfDayUtc =
    parsedDate.getUTCHours() === 0 && parsedDate.getUTCMinutes() === 0 && parsedDate.getUTCSeconds() === 0;

  let endOfDayInLocalTz;
  if (isStartOfDayUtc) {
    const almostNextDayUtc = subMinutes(addDays(parsedDate, 1), 1);
    endOfDayInLocalTz = utcToZonedTime(almostNextDayUtc, timeZone);
  } else {
    endOfDayInLocalTz = utcToZonedTime(parsedDate, timeZone);
  }

  const offsetInMinutes = parsedDate.getTimezoneOffset();
  const offsetInHours = offsetInMinutes / 60;
  // this hack is needed to display UTC+2 instead of GMT+2 as per the business requirements, i tried to stop them..
  const timezoneOffset = offsetInHours < 0 ? `+${Math.abs(offsetInHours)}` : `-${Math.abs(offsetInHours)}`;

  const utcFormats = {
    day: i18n.t('temp.unknown.components.UtcDateWithLocalTzTooltip.50af836811').toString(),
    daytime: i18n.t('temp.unknown.components.UtcDateWithLocalTzTooltip.4a0dedb632').toString(),
    date: i18n.t('temp.unknown.components.UtcDateWithLocalTzTooltip.1680e99f74').toString(),
    datetime: i18n.t('temp.unknown.components.UtcDateWithLocalTzTooltip.ea0115d19e').toString(),
  };

  const tzFormats = {
    day: i18n.t('temp.unknown.components.UtcDateWithLocalTzTooltip.57e11d857e').toString(),
    daytime: i18n.t('temp.unknown.components.UtcDateWithLocalTzTooltip.23e659a6cd').toString(),
    date: i18n.t('temp.unknown.components.UtcDateWithLocalTzTooltip.23e659a6cd').toString(),
    datetime: i18n.t('temp.unknown.components.UtcDateWithLocalTzTooltip.23e659a6cd').toString(),
  };

  const dateInUtc = formatInTimeZone(endOfDayInLocalTz, 'UTC', utcFormats[format]);
  const dateInLocalTz = formatInTimeZone(endOfDayInLocalTz, timeZone, tzFormats[format]) + ` (UTC${timezoneOffset})`;

  const isInGmt = offsetInMinutes === 0;

  const DateComponent = (
    <Component regular semiBold={isBold} sx={{ fontWeight: 'inherit', display: 'inline' }}>
      {prefix ? prefix : null}
      <span
        style={{
          textDecoration: isInGmt ? 'none' : 'underline',
          textDecorationStyle: isInGmt ? 'unset' : 'dotted',
          fontWeight: 'inherit',
          textUnderlineOffset: 4,
          textAlign: 'inherit',
        }}
      >
        {dateInUtc}
      </span>
    </Component>
  );

  if (isInGmt) return DateComponent;

  return (
    <Tooltip title={dateInLocalTz} arrow>
      {/* span is needed for Tooltip to show up, it doesnt work without the span wrapping the DateComponent, due to ref issues i guess */}
      <span style={{ display: 'inline-block' }}>{DateComponent}</span>
    </Tooltip>
  );
}
