import instance from '@/utils/api/instance';

export type WorkerRelationPayload = {
  parentName: string;
  childName: string;
};

export type WorkerRelation = {
  id: number;
  isDefault: boolean;
  parentName: string;
  childName: string;
};

export type WorkerRelationWithProfiles = {
  parentHrisProfileId?: string;
  childHrisProfileIds?: string[];
  hrisRelationshipTypeId: number;
};

export default {
  postWorkerRelations: async (payload: WorkerRelationPayload): Promise<WorkerRelation> => {
    const { data } = await instance.post('/worker_relations', payload);
    return data;
  },

  patchWorkerRelations: async (id: number, payload: WorkerRelationPayload): Promise<WorkerRelation> => {
    const { data } = await instance.patch(`/worker_relations/${id}`, payload);
    return data;
  },

  deleteWorkerRelations: async (id: number): Promise<void> => {
    await instance.delete(`/worker_relations/${id}`);
  },

  postProfilesToRelation: async (payload: WorkerRelationWithProfiles): Promise<void> => {
    await instance.post('/worker_relations/profile', payload);
  },

  patchProfileWorkerRelations: async (id: number, payload: WorkerRelationWithProfiles): Promise<WorkerRelation> => {
    const { data } = await instance.patch(`/worker_relations/profile/${id}`, payload);
    return data;
  },

  patchProfileParentWorkerRelation: async (
    oid: string,
    payload: { id: string | null; hrisRelationshipTypeId: number }
  ) => {
    const { data } = await instance.put(`/worker_relations/profile/${oid}/parent`, payload);
    return data;
  },

  patchProfileChildWorkerRelation: async (
    oid: string,
    payload: { ids: string[] | null; hrisRelationshipTypeId: number }
  ) => {
    const { data } = await instance.put(`/worker_relations/profile/${oid}/child`, payload);
    return data;
  },
};
