import { useMobile, useTheme } from '@letsdeel/ui';
import { useMemo } from 'react';
import { useCustomBrandingContext } from './CustomBrandingContext';

export const useCustomBrandingLogos = (forceMobile: boolean = false) => {
  const { palette } = useTheme();
  const isDarkModeEnabled = palette.mode === 'dark';

  const { isMobile } = useMobile();
  const { logos, isCustomBrandingEnabled } = useCustomBrandingContext();

  const customBrandingLogo = useMemo(() => {
    if (isMobile || forceMobile) {
      return isDarkModeEnabled ? logos?.darkMode?.sm : logos?.lightMode?.sm;
    }

    return isDarkModeEnabled ? logos?.darkMode?.md : logos?.lightMode?.md;
  }, [isDarkModeEnabled, isMobile, logos, forceMobile]);

  return {
    customBrandingLogo,
    showCustomBrandingLogo: isCustomBrandingEnabled && !!customBrandingLogo,
  };
};
