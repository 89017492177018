import React, { Fragment, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RootProviders } from '@letsdeel/ui';
import type { ThemeVariant, Zoom } from '@/hooks/api/dashboard/home/storage';
import {
  StorageKeysEnum,
  useFetchDashboardStorage,
  useMutateDashboardStorage,
} from '@/hooks/api/dashboard/home/storage';
import useUserStore from '@/hooks/useUserStore';
import { envShortKey } from '@/utils/environment';
import type { ThemeContextType, ThemeContextProps, ThemeValues } from './types';
import { ThemeContextInitialState } from './initialState';
import useLookupsStore from '@/hooks/useLookupsStore';
import { FeatureFlags } from '@/constants/featureFlags';
import { isPublicRoute } from '@/utils/routes';
import { useLocation } from 'react-router-dom';
import { TopnavUtilsContext, TopnavUtilsProvider } from '../TopnavUtilsContext';
import { changeToDefaultThemeColorAndStyle } from '@/utils/nativeStatusBar';
import { crossPlatormProps } from '@/utils/crossPlatform';
import { useBottomNavStyles } from '@/hooks/native/useBottomNavStyles';
import { useIsBottomNavigationEnabled } from '@/hooks/native/useIsBottomNavigationEnabled';
import isEmpty from 'lodash/isEmpty';
import { useCustomBrandingContext } from '../CustomBranding/CustomBrandingContext';
import { useWhiteLabelContext } from '../WhiteLabel/WhiteLabelContext';

export const LAST_USED_THEME_KEY = 'last_used_theme';
const REBRAND_ENABLED_KEY = 'rebrand_enabled_public_routes';
const DEFAULT_SPACING = 8;

export type Spacing = 6 | 8;

export const ThemeContext = createContext<ThemeContextType>(ThemeContextInitialState);

const ThemeProviderComponent = ({ children }: ThemeContextProps) => {
  const [isThemeChanging, setIsThemeChanging] = useState(false);
  const [bottomNavShown, setBottomNavShown] = useState(false);
  const [zoomLocal, setZoomLocal] = useState<Zoom>();
  const user = useUserStore();
  const isCustomDomain = (window as any).deelConfig;
  const lookups = useLookupsStore();
  const isRebrandEnabled = lookups.getFeatureFlag(FeatureFlags.RebrandEnabledOnPublicRoutes);
  const cachedTheme = localStorage.getItem(LAST_USED_THEME_KEY);
  const [cachedRebrandEnabled, setCachedRebrandEnabled] = useState(localStorage.getItem(REBRAND_ENABLED_KEY));
  const location = useLocation();

  const { data: densitySettings } = useFetchDashboardStorage(StorageKeysEnum.UI_DENSITY_SETTING, user.isLoggedIn);
  const isLocalOrDevThemeSelected =
    (envShortKey === 'local' || envShortKey === 'dev') && localStorage.getItem(StorageKeysEnum.ACTIVE_THEME);
  const { topnavOffset } = useContext(TopnavUtilsContext);
  const isBottomNavigationEnabled = useIsBottomNavigationEnabled();
  const { bottomNavHeight } = useBottomNavStyles();
  const [theme, setTheme] = useState<ThemeVariant>('light');
  const customBrandingData = useCustomBrandingContext();
  const whiteLabelData = useWhiteLabelContext();

  const { handleUpdate, isMutating, error } = useMutateDashboardStorage(StorageKeysEnum.ACTIVE_THEME);

  const { data: themeData, isLoading } = useFetchDashboardStorage(
    StorageKeysEnum.ACTIVE_THEME,
    !!(user.isLoggedIn && user?.token)
  );

  const zoom = zoomLocal || densitySettings?.value?.zoom;
  const handleUpdateTheme = useCallback(
    async (value: ThemeVariant) => {
      if (isLocalOrDevThemeSelected) {
        localStorage.removeItem(StorageKeysEnum.ACTIVE_THEME);
      }

      if (value === theme || isMutating) {
        return;
      }
      setIsThemeChanging(true);
      setTheme(value);
      setTimeout(() => {
        setIsThemeChanging(false);
      }, 200);
      await handleUpdate({ [StorageKeysEnum.ACTIVE_THEME]: value });
    },
    [theme, isMutating, isLocalOrDevThemeSelected, handleUpdate]
  );

  useEffect(() => {
    if (isRebrandEnabled !== undefined) {
      localStorage.setItem(REBRAND_ENABLED_KEY, isRebrandEnabled.toString());
      setCachedRebrandEnabled(isRebrandEnabled.toString());
    }

    if (themeData && themeData.value === null) {
      setTheme('light');
    }
  }, [isRebrandEnabled, themeData]);

  useEffect(() => {
    if (isLocalOrDevThemeSelected) {
      setTheme(localStorage.getItem(StorageKeysEnum.ACTIVE_THEME) as ThemeVariant);
      return;
    }

    if (themeData && themeData.value) {
      let activeTheme;
      // type check for storage response
      if (typeof themeData.value[StorageKeysEnum.ACTIVE_THEME] !== 'string') {
        if ((themeData.value.activeTheme as any)?.activeTheme) {
          activeTheme = (themeData.value.activeTheme as any)?.activeTheme;
        } else {
          activeTheme = 'system_default';
        }
      } else {
        activeTheme = themeData.value[StorageKeysEnum.ACTIVE_THEME];
      }

      localStorage.setItem(LAST_USED_THEME_KEY, activeTheme);
      setTheme(activeTheme);
      changeToDefaultThemeColorAndStyle(); // For native mobile: change status bar and theme
    }
  }, [themeData, isLocalOrDevThemeSelected, isLoading, isRebrandEnabled]);

  const selectedTheme = useMemo(() => {
    if (cachedRebrandEnabled) {
      if (isPublicRoute(location.pathname)) {
        if (cachedRebrandEnabled === 'true') {
          return 'light';
        }
        return 'light';
      }
    }

    if (cachedTheme && themeData === undefined) {
      const overrideTheme = isLocalOrDevThemeSelected;
      if (!overrideTheme) {
        if (cachedTheme === 'base') return 'light';

        if (['light', 'dark'].includes(cachedTheme)) {
          return cachedTheme as ThemeValues;
        }
      }
    }

    if (theme !== 'system_default') {
      return theme;
    } else {
      if (window.matchMedia?.('(prefers-color-scheme: dark)').matches) {
        return 'dark';
      }
      return 'light';
    }
  }, [cachedRebrandEnabled, cachedTheme, themeData, theme, location.pathname, isLocalOrDevThemeSelected]);

  const isSystemDefaultDarkMode = useMemo(
    () => window.matchMedia?.('(prefers-color-scheme: dark)').matches && theme === 'system_default',
    [theme]
  );
  const memoizedZoom = useMemo(() => (zoom ? zoom * DEFAULT_SPACING : DEFAULT_SPACING), [zoom]);
  const contextValue = useMemo<ThemeContextType>(
    () => ({
      isCustomDomain,
      selectedTheme: theme,
      activeDeelUITheme: selectedTheme,
      isUpdatingTheme: isMutating,
      isUpdateThemeError: !!error,
      handleUpdateTheme,
      setTheme,
      isThemeChanging,
      bottomNavigationState: {
        bottomNavShown,
        setBottomNavShown,
      },
      isSystemDefaultDarkMode,
      zoomLocalState: {
        zoomLocal,
        setZoomLocal,
      },
    }),
    [
      isCustomDomain,
      selectedTheme,
      theme,
      isMutating,
      error,
      handleUpdateTheme,
      setTheme,
      isThemeChanging,
      bottomNavShown,
      setBottomNavShown,
      isSystemDefaultDarkMode,
      zoomLocal,
    ]
  );

  useEffect(() => {
    const zowieFloatingButton = document.getElementById('zowieFloatingButton');
    if (selectedTheme === 'dark') {
      zowieFloatingButton?.classList.add('dark');
    } else {
      zowieFloatingButton?.classList.remove('dark');
    }
  }, [selectedTheme]);

  const brandingColors = useMemo(() => {
    if (whiteLabelData.isWhiteLabelEnabled && !isEmpty(whiteLabelData.primaryColor)) {
      if (selectedTheme === 'dark' || isSystemDefaultDarkMode) {
        return whiteLabelData.primaryColor.darkMode;
      }
      return whiteLabelData.primaryColor.lightMode;
    }

    if (customBrandingData.isCustomBrandingEnabled && !isEmpty(customBrandingData.primaryColor)) {
      if (selectedTheme === 'dark' || isSystemDefaultDarkMode) {
        return customBrandingData.primaryColor.darkMode;
      }
      return customBrandingData.primaryColor.lightMode;
    }

    return undefined;
  }, [customBrandingData, isSystemDefaultDarkMode, selectedTheme, whiteLabelData]);

  const brandingLogos = useMemo(() => {
    if (whiteLabelData.isWhiteLabelEnabled) {
      return {
        small: {
          light: whiteLabelData.logos?.lightMode?.sm,
          dark: whiteLabelData.logos?.darkMode?.sm,
        },
        full: {
          light: whiteLabelData.logos?.lightMode?.md,
          dark: whiteLabelData.logos?.darkMode?.md,
        },
      };
    }

    if (customBrandingData.isCustomBrandingEnabled) {
      return {
        small: {
          light: customBrandingData.logos?.lightMode?.sm,
          dark: customBrandingData.logos?.darkMode?.sm,
        },
        full: {
          light: customBrandingData.logos?.lightMode?.md,
          dark: customBrandingData.logos?.darkMode?.md,
        },
      };
    }

    return undefined;
  }, [customBrandingData, whiteLabelData]);

  const countries = useMemo(() => {
    return Object.fromEntries(Object.entries(lookups.countries).map(([key, value]) => [key, value.label]));
  }, [lookups.countries]);

  return (
    <ThemeContext.Provider value={contextValue}>
      <RootProviders
        crossPlatform={{ ...crossPlatormProps, isNativeBottomNavShown: isBottomNavigationEnabled }}
        offsetTopbar={topnavOffset}
        offsetBottomBar={bottomNavShown ? bottomNavHeight : 0}
        theme={selectedTheme}
        dictionary={{ countries }}
        brand={brandingLogos}
        brandColor={brandingColors}
        spacing={memoizedZoom as Spacing}
      >
        <Fragment key={`zoom-${zoomLocal ? 'static' : memoizedZoom}`}>{children}</Fragment>
      </RootProviders>
    </ThemeContext.Provider>
  );
};

export const ThemeProvider = ({ children }: ThemeContextProps) => {
  return (
    <TopnavUtilsProvider>
      <ThemeProviderComponent>{children}</ThemeProviderComponent>
    </TopnavUtilsProvider>
  );
};

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }
  return context;
};
