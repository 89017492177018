import type { INotificationItem, NotificationStore } from '@/stores/NotificationsStore';
import { useCallback } from 'react';
import { useNotificationOptions } from './useCustomNotificationOptions';
import useStores from '../useStores';

export interface ILocalStorageNotification {
  key: string;
  createdAt: string;
  seenAt: string | null;
  dismissed?: boolean;
}

export const LS_CUSTOM_NOTIFICATIONS_KEY = 'custom_notifications';

/**
 * @deprecated
 * this was a temporary solution and must not be used.
 * All the notifications must be stored in the server and not in the local storage.
 *
 * https://letsdeel.atlassian.net/browse/NOTIF-87
 */
const getCachedNotifications = () => {
  const localNotifications = localStorage.getItem(LS_CUSTOM_NOTIFICATIONS_KEY);
  const storedNotifications: ILocalStorageNotification[] = localNotifications
    ? (JSON.parse(localNotifications) as [])
    : [];

  return storedNotifications;
};

const setCachedNotifications = (notifications: ILocalStorageNotification[]) => {
  localStorage.setItem(LS_CUSTOM_NOTIFICATIONS_KEY, JSON.stringify(notifications));
};

/**
 * @deprecated
 * this was a temporary solution and must not be used.
 * All the notifications must be stored in the server and not in the local storage.
 *
 * https://letsdeel.atlassian.net/browse/NOTIF-87
 */
export const useCustomNotifications = () => {
  const notificationOptions = useNotificationOptions();
  const { notifications } = useStores<{ notifications: NotificationStore }>();
  const { loadNotifications } = notifications;

  type NotificationOptionKeys = keyof typeof notificationOptions;

  const getCustomNotifications = useCallback(() => {
    const cachedNotifications: ILocalStorageNotification[] = getCachedNotifications();
    const newNotifications: INotificationItem[] = [];

    cachedNotifications.map((notification) => {
      const customNotification = notificationOptions[notification.key as NotificationOptionKeys];
      if (notification.dismissed || !customNotification) return;
      if (customNotification.shouldDismiss?.()) {
        notification.dismissed = true;
        setCachedNotifications(cachedNotifications);
        return;
      }

      newNotifications.push({
        ...customNotification,
        onDismiss: () => {
          notification.dismissed = true;
          setCachedNotifications(cachedNotifications);
        },
        id: notification.key as string,
        createdAt: notification.createdAt,
        seenAt: notification.seenAt,
      });
    });

    return newNotifications;
  }, [notificationOptions]);

  const showCustomNotification = useCallback(
    async (key: NotificationOptionKeys) => {
      const storedNotifications: ILocalStorageNotification[] = getCachedNotifications();

      const stored = storedNotifications.find((stored) => stored.key === key);

      if (!stored) {
        storedNotifications.push({
          key,
          createdAt: new Date().toString(),
          seenAt: null,
        });

        setCachedNotifications(storedNotifications);
      }

      await loadNotifications({ otherNotifications: getCustomNotifications() });
    },
    [getCustomNotifications, loadNotifications]
  );

  const dismissCustomNotification = useCallback(
    async (key: NotificationOptionKeys) => {
      const storedNotifications: ILocalStorageNotification[] = getCachedNotifications();

      const stored = storedNotifications.find((stored) => stored.key === key);

      if (stored) {
        stored.dismissed = true;

        setCachedNotifications(storedNotifications);
      }
      await loadNotifications({ otherNotifications: getCustomNotifications() });
    },
    [getCustomNotifications, loadNotifications]
  );

  const dismissAllCustomNotifications = useCallback(() => {
    const stored = getCachedNotifications();
    setCachedNotifications(stored.map((notification) => ({ ...notification, dismissed: true })));
  }, []);

  const seenAllCustomNotifications = useCallback(() => {
    const stored = getCachedNotifications();
    setCachedNotifications(stored.map((notification) => ({ ...notification, seenAt: new Date().toString() })));
  }, []);

  return {
    dismissAllCustomNotifications,
    dismissCustomNotification,
    showCustomNotification,
    getCustomNotifications,
    seenAllCustomNotifications,
  };
};
