import instance from '@/utils/api/instance';

export interface Plugin {
  title: string;
  description: string;
  image: string;
  appId: string;
  installLink: string;
  installStatus: string;
  slackLink?: string;
}

export interface EngageSetupLink {
  url: string;
}

interface PaymentMethod {
  paymentMethod: string;
  isActive: boolean;
}

interface PricingInfo {
  standard: boolean;
  agreement: string | null;
  fee: string;
  type: string;
}

export interface EngageBillingInfo {
  hasActiveSubscription: boolean;
  paymentMethods: PaymentMethod[];
  price: PricingInfo;
  currentSeats: number | undefined;
  screeningStatus: 'APPROVED' | 'REJECTED';
}

export type EngageFeature = {
  slug:
    | 'performance'
    | 'learning'
    | 'career'
    | 'career_ai'
    | 'question_bank'
    | 'surveys'
    | 'learning_ai'
    | 'goals'
    | 'product_activation';
  isEnabled: boolean;
  enabledAt: string | null;
};

export type UserActiveResponse = {
  isActive: boolean;
  isClient: boolean;
};

export default {
  getPlugins: async (): Promise<Plugin[]> => {
    const { data } = await instance.get(`/engage/plugins`);
    return data as Plugin[];
  },

  getEngageSetupLink: async (): Promise<EngageSetupLink> => {
    const { data } = await instance.get<EngageSetupLink>(`/engage/setup/link`);
    return data;
  },

  getBillingInfo: async () => {
    const { data } = await instance.get<EngageBillingInfo>('/engage/subscription/billing_info');
    return data;
  },

  postFinishSetup: (): Promise<void> => {
    return instance.post(`/engage/setup/finish`);
  },

  postStartSubscription: () => {
    return instance.post('/engage/subscription/start');
  },

  getEngageFeatures: async () => {
    const { data } = await instance.get<{ features: EngageFeature[] }>('/engage_core/organization_features', {
      params: { version: '2024-06-24' },
    });
    return data.features ?? [];
  },

  updateEngageFeature: async (feature: EngageFeature['slug'], enabled: boolean) => {
    return instance.patch(`/engage_core/organization_features/${feature}`, { enabled });
  },

  getProductActivationUserStatus: async () => {
    const { data } = await instance.get<UserActiveResponse>('/product_activation/HR_SUITE/users/me');
    return data;
  },
};
