import type { ComponentProps, ReactNode} from 'react';
import { useEffect, useState, useRef, useMemo } from 'react';
import type { ModalProps as BSModalProps } from 'react-bootstrap';
import kebabCase from 'lodash/kebabCase';

import { cn } from '@/utils/main';

import BackButton from '../BackButton';
import Button from '../Button';
import Flex from '../Flex';

import debounce from 'lodash/debounce';
import { Modal, MuiBox, H4 } from '@letsdeel/ui';
import type { ModalRef } from '@letsdeel/ui';
import type { ModalClosingEvent, ModalClosingReasons, ModalProps } from '@letsdeel/ui/dist/components/Modal/Modal.props';

import './popup.less';

type Overflow = 'visible' | 'hidden' | 'scroll' | 'auto' | 'inherit';

export const updateBodyOverflow = (overflow: Overflow) => {
  document.body.style.overflow = overflow;
  document.documentElement.style.overflow = overflow;
  if (overflow === 'scroll') {
    document.body.style.overflowX = 'hidden';
    document.documentElement.style.overflowX = 'hidden';
  }
};

export interface PopupProps extends BSModalProps {
  children: ReactNode;
  subtitle?: ReactNode;
  title?: ReactNode;
  information?: string;
  className?: string;
  contentClassName?: string;
  headerClassName?: string;
  /**
   * @deprecated it doesn't effect the component anymore. If you have z-index problem, probably it should be better to handle other styles.
   */
  backdropClassName?: string;
  footer?: {
    button?: {
      onClick: (e: MouseEvent) => void;
    };
    text?: string;
  };
  customFooter?: ReactNode;
  onHide?: () => void;
  onBack?: () => void;
  onCloseButtonClick?: () => void;
  button?: ComponentProps<typeof Button>;
  show: boolean;
  hasHeader?: boolean;
  onBeforeClose?: () => Promise<boolean>;
  /**
   * @deprecated You shouldn't customize the header style. For very rare edge cases you can use headerClassName.
   */
  hasHeaderDefaultMargin?: boolean;
  backButton?: boolean;
  closeButton?: boolean;
  /**
   * @deprecated Modals are scrollable by default
   */
  scrollable?: boolean;
  fullScreen?: boolean;
  /**
   * @deprecated
   */
  animation?: boolean;
  /**
   * @deprecated modal are centered by default
   */
  centered?: boolean;
  backdrop?: boolean | 'static';
  // Used as true only in AddShareholdersPopup
  fixedMinHeight?: boolean;
  /**
   * @deprecated Footer is always on bottom
   */
  stickFooterToBottom?: boolean;
  isChildrenOnly?: boolean;
  modalProps?: ModalProps['modalProps'];
}

/**
 * @deprecated Use Deel/ui Modal instead
 */
const Popup = ({
  className,
  contentClassName,
  headerClassName,
  children,
  onHide,
  footer,
  customFooter,
  button,
  closeButton = true,
  backButton,
  onBack,
  subtitle,
  title,
  scrollable = true,
  hasHeader = true,
  fullScreen,
  information,
  show,
  backdrop = false,
  fixedMinHeight = false,
  // stickFooterToBottom = false,
  isChildrenOnly = false,
  onCloseButtonClick,
  size,
  onExited,
  modalProps,
  onBeforeClose,
}: PopupProps) => {
  const modalRef = useRef<ModalRef>();
  const contentRef = useRef<HTMLDivElement>(null);
  const [_fixedMinHeightValue, setFixedMinHeightValue] = useState<number>();

  useEffect(() => {
    const resetMinHeightValue = debounce(() => {
      setFixedMinHeightValue(undefined);

      // document?.documentElement?.style?.setProperty('--app-height', `${window.innerHeight}px`);
    }, 100);

    window.addEventListener('resize', resetMinHeightValue);
    return () => window.removeEventListener('resize', resetMinHeightValue);
  }, []);

  useEffect(() => {
    setFixedMinHeightValue(contentRef.current && show && fixedMinHeight ? contentRef.current.clientHeight : undefined);
  }, [show, fixedMinHeight]);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        modalRef.current?.open();
      });
    } else {
      modalRef.current?.closeExact();
    }
    const modalRefElement = modalRef.current;
    return () => modalRefElement?.closeExact();
  }, [show]);

  const hidePopup = (e: ModalClosingEvent, reason: ModalClosingReasons) => {
    if (reason === 'closeIconClick' && onCloseButtonClick) {
      onCloseButtonClick();
    }
    onHide?.();
  };

  const modalSubtitle = useMemo(() => {
    if ((!subtitle && !information) || hasHeader === false) {
      return null;
    }
    return (
      <>
        {typeof subtitle === 'string' ? (
          <H4 regular color="neutral.darker">
            {subtitle}
          </H4>
        ) : (
          subtitle
        )}
        {information ? (
          <H4 regular color="neutral.darker">
            {information}
          </H4>
        ) : null}
      </>
    );
  }, [information, subtitle, hasHeader]);

  const modalActions = useMemo(() => {
    if (customFooter) {
      return (
        <MuiBox
          sx={{
            width: '100%',
            '& > *:only-child': {
              width: '100%',
            },
          }}
        >
          {customFooter}
        </MuiBox>
      );
    }
    return footer ? (
      <Flex className="space-btwn mt-7">
        {footer?.button ? (
          <BackButton
            className="flex-1"
            /* @ts-ignore */
            onClick={footer?.button?.onClick || onHide}
          />
        ) : (
          <div className="flex-1" />
        )}
        <p className="flex-2 text-center p-gray">{footer?.text}</p>
        <div className="flex-1" />
      </Flex>
    ) : null;
  }, [customFooter, footer, onHide]);

  if (isChildrenOnly) return <div>{children}</div>;

  const customModalProps = modalProps || {};

  return (
    <Modal
      ref={modalRef}
      onClose={hidePopup}
      headerClassName={headerClassName}
      modalProps={{
        className: cn('popup', className, size && `modal-dialog-container-${size}`),
        fullScreen,
        PaperProps: {
          className: 'modal-dialog',
        },
        TransitionProps: { onExited },
        ...customModalProps,
      }}
      data-qa={typeof title === 'string' ? `${kebabCase(title)}-popup` : undefined}
      onBack={backButton ? onBack : undefined}
      title={hasHeader === false ? null : title}
      subtitle={modalSubtitle}
      closeButton={hasHeader === false ? false : closeButton}
      actions={modalActions}
      disableBackdropClick={backdrop === false || backdrop === 'static'}
      onBeforeClose={onBeforeClose}
    >
      <div className={cn(contentClassName, scrollable && 'popup-content-scroll')}>{children}</div>

      {button ? (
        <Button
          size="lg"
          full
          {...button}
          className={cn('mt-10', button?.className)}
          onClick={button?.onClick || onHide}
        />
      ) : null}
    </Modal>
  );
};

export default Popup;
