import type { PayrollSettings } from './PayrollSettings';
import type { EntityIdentifierType } from '@/utils/api/entities';
import type { CountryCode } from '@letsdeel/ui';
import type { ValueOf } from './utils';
import type { TaskListState } from '@/scenes/Settings/scenes/Entity/EntityDetails/EntityOnboardingTaskList/types';
import type { TASK_LIST_STEP_ACTION } from '@/scenes/Settings/scenes/Entity/EntityDetails/EntityOnboardingTaskList/EntityOnboardingTaskListStepAction';

export enum PaymentMethod {
  DEEL = 'DEEL',
  SELF = 'SELF',
}

export interface Address {
  country: string;
  state?: string;
  province?: string;
  city: string;
  zip: string;
  street: string;
}

export interface Organization {
  id: number;
  name: string;
  publicId: string;
}

export type ICPLegalEntity = {
  id: number;
  publicId: string;
  name: string;
  vatCountry: string;
  country: string;
  EOR?: { id: number };
  paymentMethod: PaymentMethod | null;
  payrollSettings: PayrollSettings[];
  Organization?: Organization | null;
};

export enum PAYROLL_DEPOSIT_STATUS {
  IN_REVIEW = 'IN_REVIEW',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  PROCESSING = 'PROCESSING',
  PAID = 'PAID',
  DISMISSED = 'DISMISSED',
  FAILED = 'FAILED',
}

export interface DepositInvoice {
  id: number;
  teamId: number;
  invoiceId: string;
  contractId: number;
  rootInvoiceId?: number;
  rootInvoicePublicId?: string;
  paidAt?: string;
}
export interface PayrollDeposit {
  amount?: number;
  dueDate?: string;
  startDate?: string;
  status?: PAYROLL_DEPOSIT_STATUS;
  alertType: 'SOFT' | 'OVERDUE' | null;
  isSupported: boolean;
  invoice?: DepositInvoice;
}

export type LegalEntityPublicId = string;

export interface PeoClient {
  deelLegalEntityPublicId: string;
  clientName: string;
  legalName: string;
  fedIdNumber: string;
  corporationType: string;
}

export enum RegistrationStatus {
  DRAFT = 'DRAFT',
  READY = 'READY',
  PROCESSING = 'PROCESSING',
}

export type LegalEntity = {
  /**
   * @deprecated - use publicId instead
   */
  id: number;
  publicId: LegalEntityPublicId;
  entityPublicId: string;
  name: string;
  localizedName?: string;
  country: CountryCode;
  vatCountry: string;
  legalType: string;
  entityType: string;
  logoUrl?: string | null;
  phone: string | null;
  registrationNumber: any;
  industryName: string | null;
  companyIdentifiers: EntityIdentifierType[];
  requiredRegistrationNumbers?: boolean;
  optedOutRegistrationNumber?: boolean;
  optedOutVatId?: boolean;
  vatId: any;
  useVatId: boolean;
  vatIdError: any;
  sicNumber?: string | number;
  useAddressAsPostalAddress: boolean;
  address: Address | null;
  postalAddress: Address | null;
  // TODO: check if can actually be an object
  registrationAddress: Address | null | string;
  isDeelShield: boolean;
  isASOEnabled?: boolean;
  contractsCount: number;
  peopleCount: {
    total: number;
    contractor: number;
    directEmployee: number;
    directEmployeePayroll: number;
    peo: number;
    eor: number;
  };
  isCurrentPayrollLocked: boolean;
  nextPayrollStart: string;
  canBeDeleted: boolean;
  organization: { id: number; name: string };
  individualProfile: null;
  payrollDeposit: PayrollDeposit;
  payrollSetting: PayrollSettings | null;
  payrollSettings: PayrollSettings | null;
  paymentMethod: PaymentMethod | null;
  isPeo?: boolean;
  isGpExpensesEnabled?: boolean;
  email?: string | null;
  hasGpIntegrationActive?: boolean;
  anyPendingChecklistStep: boolean;
  peoClient?: PeoClient;
  contractType?: string;
  taxedAs?: string;
  creator?: {
    email: string | null;
    name: string | null;
    phone: string | null;
  };
  externalScreeningStatus: (string & {}) | 'ACTIVE' | null;
  isCdd2Required: boolean;
  cdd2Screening: {
    id: number;
    status: string;
    createdAt: string;
    profile: {
      profilePublicId: string;
      id: number;
      name: string;
      email: string;
    };
  };
  embeddedPayrollEnabled?: boolean;
  naicsCode?: string;
  isFirstOrganizationEntity: boolean;
  payrollOnboardingStatus: PayrollOnboardingStatus;
  isPreHireAssessment?: boolean;
  legalEntityId?: string;
  status?: string;
  isGpActive: boolean;
  isSoftGPEnabled: boolean;
  isDraft?: boolean;
  shouldRunPayrollAtDeel?: boolean;
  taskList?: EntityTaskList;
  registrationStatus?: RegistrationStatus;
  businessType?: EEntityBusinessType;
  payrollType?: string;
  discoverySheetFileLink?: string;
};

export type LegalEntityDraft = {
  id: string;
  name?: string;
  address?: {
    country: string;
  };
  entityType?: string;
  registrationStatus: RegistrationStatus;
};

export type LegalEntityReportItem = {
  payrollReportColumnId: string;
  isMandatory?: boolean;
  //activationDate?: string;
};

export type CostCenter = {
  name: string;
  number: string;
  id?: number;
  archivedAt?: string;
};

export type CostCentersCount = Array<{
  legalEntityId: string;
  legalEntityName: string;
  costCentersCount: string;
}>;

export interface WorkingLocation {
  id: string;
  name: string;
  isWfh: boolean;
  localJurisdiction: string;
  stateJurisdiction: string | null;
  country: string;
  MailingAddress: Address;
  PhysicalAddress: Address;
}

export enum StepStatus {
  PENDING = 'PENDING',
  DONE = 'DONE',
}

export enum LegalEntityChecklistSteps {
  AddWorkers = 'ADD_WORKERS',
  UploadBusinessDocuments = 'UPLOAD_BUSINESS_DOCUMENTS',
  SubmitPayrollReport = 'SUBMIT_PAYROLL_REPORT',
  FirstPayrollCycleStatus = 'FIRST_PAYROLL_CYCLE_STATUS',
}

export const PAYROLL_ONBOARDING_STATUSES = {
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  ONBOARDING: 'ONBOARDING',
  COMPLETED: 'COMPLETED',
} as const;

export type PayrollOnboardingStatus = ValueOf<typeof PAYROLL_ONBOARDING_STATUSES>;

export interface GeoLocation {
  geoCode: string;
  city: string;
  county: string;
  state: string;
  stateCode: string;
  address?: string;
}

export interface EntityTaskList {
  id: string;
  legalEntityId: string;
  state: TaskListState;
  taskListConfig: {
    id: string;
    active: boolean;
    country: CountryCode;
    process: string;
  };
  taskListSteps: {
    id: string;
    state: TaskListState;
    customData: Record<string, string>;
    taskListConfigStep: {
      action: TASK_LIST_STEP_ACTION;
      dependencies: number[];
      order: number;
    };
  }[];
}

export type GeoLocationResponse =
  | GeoLocation[]
  | undefined
  | {
      name: string;
      message: string;
    };

export function isValidGeoLocationResponse(response: GeoLocationResponse): response is GeoLocation[] {
  return !!response && Array.isArray(response);
}

export enum EEntityBusinessType {
  DEFAULT = '',
  GENERAL_BUSINESS = 'GENERAL_BUSINESS',
  FINANCIAL_INSTITUTION = 'FINANCIAL_INSTITUTION',
}
