import { makeAutoObservable } from 'mobx';

import type { EmployeeComplianceDocument } from '@/scenes/EmployeeComplianceDocuments/types';
import type { OvertimeItem } from '@/scenes/Settings/scenes/TimeAttendance/RatePolicies/Overtimes/Modal/types';
import type { PublicIdLegalEntity } from '@/stores/PayrollStore';
import type { Employment } from '@/types/Employment';
import type { EmploymentTerm } from '@/types/EmploymentTerm';
import { EmploymentTermStatus } from '@/types/EmploymentTerm';
import type { ContractDataChange } from '@/types/GlobalPayroll/ContractDataChanges';
import globalPayrollApi from '@/utils/api/globalPayroll';
import type { CountryCode } from '@letsdeel/ui';
import { DateFormat } from '@letsdeel/ui';
import { endOfDay, format, isAfter, isWithinInterval, startOfDay } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import type { IPayrollEvent } from '../api/employment-service/payroll-events';
import type { UpdateContractDetails } from '../api/globalPayroll/gpDetails';
import type { Validation, AdditionalInfo } from '@/types/User';
import { toReportFormat } from '../time';
import type { OvertimeStatus } from '@/scenes/Create/components/DirectEmployeeComponents/types';
import type { PayrollSetting as GPPayrollSetting } from '@/types/Contract/GPContract';

export enum GPContractStatuses {
  new = 'new',
  onboarding = 'onboarding',
  onboarded = 'onboarded',
  inProgress = 'in_progress',
  completed = 'completed',
  cancelled = 'cancelled',
  user_cancelled = 'user_cancelled',
}

export type GPContractStatusesTypes = `${GPContractStatuses}`;

export interface Address {
  country: string;
  state: string;
  city: string;
  street: string;
  zip: string;
  updatedAt: Date;
  createdAt: Date;
  province?: string;
  newLine?: boolean;
  fullCountry?: boolean;
  effectiveDate?: string;
}

export interface EntityIntegrationSettings {
  id: string;
  fieldGroup: FieldGroupEnum;
  allowedSources: FieldUpdateSourceEnum[];
}

export interface LegalEntity {
  id: number;
  name: string;
  LegalEntityIntegrationSettings: EntityIntegrationSettings[];
  Organization?: {
    id: number;
    name: string;
    organizationPublicId: string;
  };
  isGpExpensesEnabled: boolean;
  vatCountry: CountryCode;
}

export interface CostCenter {
  number: string;
  name: string;
  id?: number;
}

export type BankDetailsItemPayload = Record<string, any>;

export interface BankDetailsItem {
  payload?: BankDetailsItemPayload | null;
  data?: BankDetailsItemPayload | null;
  status?: string;
  effectiveDate?: string;
}

export interface BankDetailsItemEnhanced extends BankDetailsItem {
  id: number;
  methodChainId: string;
  updatedAt?: string;
  fundsDistribution: number;
  uuid?: string;
  validations?: Validation[];
  additionalInfo?: AdditionalInfo;
}

export interface BankDetails {
  activeBankInfo?: BankDetailsItem | null;
  previousBankInfo?: BankDetailsItem | null;
  activeBankDetails?: BankDetailsItemEnhanced[] | null;
  previousBankDetails?: BankDetailsItemEnhanced[] | null;
  canUpdateBankInfo: boolean;
}

export interface PayrollSetting {
  LegalEntity: LegalEntity;
}

export interface Team {
  id: number;
  name: string;
  Organization?: {
    id: number;
    name: string;
  };
}

export interface IDraftContractOrigin {
  code: string;
  id: number;
  name: string;
  providerId: number;
}

export interface IDraftContract {
  origin: IDraftContractOrigin;
  id: number;
}

export interface PayrollEventDates {
  lock: string;
  start: string;
  end: string;
  reportSubmittedAt: string | null;
}

export type CustomField = {
  defaultValue: null | string;
  id: number;
  name: string;
  type: 'TEXT' | 'LIST';
  value: string;
};

export enum EmploymentTermAmendedScope {
  employment = 'employment',
  jobInfo = 'jobInfo',
}

export enum FieldUpdateSourceEnum {
  INTEGRATION = 'INTEGRATION',
  EMPLOYEE = 'EMPLOYEE',
  CLIENT = 'CLIENT',
}

export enum FieldGroupEnum {
  ADDRESS = 'address',
  BANK_INFORMATION = 'bankInformation',
}

export type HrisProfileDetailsProps = {
  oid: string;
  firstName: string;
  lastName: string;
  workEmail: string;
  email: string;
  nationality: string;
  country: string;
  state: string;
  dateOfBirth: string;
  workerId?: number;
  externalId?: string;
};

export type Schedule = {
  id: string;
  name: string;
};

export type RatePolicyContractInfo = {
  id: string;
  name: string;
  countryCodes: string[];
  rules: OvertimeItem[];
};

export type Seniority = {
  id: string;
  name: string;
};

export default class GlobalPayrollContractClass {
  id: string;
  createdAt: string = '';
  originalId: number;
  oid: string = '';
  name: string = '';
  type: string = '';
  teamId: number = 0;
  data: any = {};
  status: GPContractStatusesTypes = 'new';
  hasProfile: boolean = false;
  contractType: string = '';
  contractorId: number | null = null;
  team: Team;
  seniority: Seniority | null;
  additionalInfo?: any = {};
  documents: EmployeeComplianceDocument[] = [];
  draftContract: IDraftContract;
  changesInCurrentPayrollEvent: Record<'compensationStatus' | 'employmentStatus' | 'jobInformation', boolean> = {
    compensationStatus: false,
    employmentStatus: false,
    jobInformation: false,
  };
  HrisProfile?: HrisProfileDetailsProps;
  directEmployeeEditEnabled: boolean = false;
  startDate: Date = new Date();
  cancelledAt?: Date;
  completionDate: Date | null = null;
  timezone: string = '';
  currentPayrollEvent: PayrollEventDates | null = null;
  nextAvailablePayrollEvent: PayrollEventDates | null = null;
  nextUnsubmittedPayrollEvent?: PayrollEventDates | null = null;
  employment: Employment;
  address: Address;
  bankInfo: BankDetails;
  costCenter?: CostCenter;
  legalEntity: LegalEntity | PublicIdLegalEntity;
  payrollSettings?: GPPayrollSetting;
  firstEventData: Omit<IPayrollEvent, 'id'> | null = null;
  customFields: CustomField[] = [];
  documentTemplateId: number | null = null;
  timeTracking?: {
    gpSettings?: { submittingHours: 'upload' | 'clockInOut' };
    isHourly: boolean;
    hourlyRate: number;
    schedule?: Schedule;
    ratePolicy?: RatePolicyContractInfo;
    isEmbeddedPayroll?: boolean;
  };
  activeContractDataChanges: ContractDataChange[] | null;

  constructor(contract: any) {
    makeAutoObservable(this);
    // noinspection TypeScriptValidateTypes
    Object.assign(this, contract);
    this.additionalInfo = contract.data?.additionalInfo;
    this.team = contract.Team;
    this.seniority = contract.Seniority;
    this.id = contract.oid;
    this.originalId = contract.id;
    this.draftContract = contract.draftContract;
    this.employment = contract.employment;
    this.address = contract.address;
    this.bankInfo = contract.bankInfo;
    this.costCenter = contract.costCenter;
    this.legalEntity = contract.legalEntity;
    this.payrollSettings = contract.payrollSettings;
    this.HrisProfile = contract.HrisProfile;
    this.activeContractDataChanges = contract.activeContractDataChanges;
  }

  updateFromContract(contract: any) {
    // noinspection TypeScriptValidateTypes
    Object.assign(this, contract);
    this.additionalInfo = contract.data?.additionalInfo;
    this.team = contract.Team;
    this.seniority = contract.Seniority;
    this.id = contract.oid;
    this.originalId = contract.id;
    this.draftContract = contract.draftContract;
    this.employment = contract.employment;
    this.address = contract.address;
    this.bankInfo = contract.bankInfo;
    this.costCenter = contract.costCenter;
    this.legalEntity = contract.legalEntity;
    this.payrollSettings = contract.payrollSettings;
    this.HrisProfile = contract.HrisProfile;
    this.activeContractDataChanges = contract.activeContractDataChanges;
  }

  get isShield() {
    return false;
  }

  updateEmployeeDetails = async (newDetails: Record<string, any>) => {
    await globalPayrollApi.employee.updateEmployeeDetails(this.originalId, newDetails);
    this.employment.employee = { ...this.employment.employee, ...newDetails };
  };

  updateEmployeeAddress = async (newAddress: Record<string, any>) => {
    const updatedAddress = await globalPayrollApi.employee.updateEmployeeAddress(this.originalId, newAddress);
    this.address = { ...this.address, ...newAddress, ...updatedAddress };
  };

  updateContractDetails = async (newDetails: UpdateContractDetails) => {
    const { email, workVisa, employeeNumber, startDate, originalStartDate } = newDetails;
    const activeTerm = this.employment.employmentTerms.find((term) => term.status === 'ACTIVE');
    const formattedStartDate = toReportFormat(startDate);
    const formattedOriginalStartDate = originalStartDate && toReportFormat(originalStartDate);

    if (this.employment.email === email) {
      delete newDetails.email;
    }

    if (this.employment.employeeNumber === employeeNumber) {
      delete newDetails.employeeNumber;
    }

    if (this.employment.workVisa === workVisa) {
      delete newDetails.workVisa;
    }

    if (toReportFormat(activeTerm?.startDate) === formattedStartDate) {
      delete newDetails.startDate;
    } else {
      newDetails.startDate = formattedStartDate;
    }

    if (activeTerm?.desiredStartDate && toReportFormat(activeTerm?.desiredStartDate) === formattedOriginalStartDate) {
      delete newDetails.originalStartDate;
    } else {
      newDetails.originalStartDate = formattedOriginalStartDate;
    }
    await globalPayrollApi.gpDetails.updateContractDetails(this.originalId, newDetails);

    if (newDetails.email) {
      this.employment.email = newDetails.email;
    }
    if (newDetails.workVisa !== undefined) {
      this.employment.workVisa = Boolean(newDetails.workVisa);
    }
    if (newDetails.employeeNumber !== undefined) {
      this.employment.employeeNumber = newDetails.employeeNumber;
    }
    if (newDetails.deelStartDate !== undefined) {
      this.employment.deelStartDate = newDetails.deelStartDate;
    }

    if (activeTerm) {
      if (newDetails.startDate) {
        activeTerm.startDate = formattedStartDate;
      }
      if (newDetails.originalStartDate && formattedOriginalStartDate) {
        activeTerm.desiredStartDate = formattedOriginalStartDate;
      }
    }

    this.employment = { ...this.employment };
  };

  updateCompensation = async ({
    effectiveDate,
    changeData,
  }: {
    effectiveDate: string | Date;
    changeData: { rate: number; scale: string };
  }) => {
    if (!(effectiveDate instanceof Date)) {
      effectiveDate = parseISO(effectiveDate);
    }
    await globalPayrollApi.gpDetails.updateCompensation({
      contractOid: this.id,
      effectiveDate: format(effectiveDate, DateFormat.DATE_ONLY),
      changeData: changeData,
    });
  };

  updateOvertime = async ({
    effectiveDate,
    changeData,
  }: {
    effectiveDate: string | Date;
    changeData: { overtimeStatus: OvertimeStatus };
  }) => {
    await globalPayrollApi.gpDetails.updateOvertime({
      contractOid: this.id,
      effectiveDate: format(
        effectiveDate instanceof Date ? effectiveDate : parseISO(effectiveDate),
        DateFormat.DATE_ONLY
      ),
      changeData: changeData,
    });
  };

  updateEmploymentStatus = async (newDetails: Record<string, any>) => {
    this.employment = await globalPayrollApi.gpDetails.updateEmploymentStatus(this.originalId, newDetails);
  };

  updateJobInformation = async (newDetails: Record<string, any>) => {
    this.employment = await globalPayrollApi.gpDetails.updateJobInformation(this.originalId, newDetails);
  };

  updateRoleDetails = async (newDetails: Record<string, any>) => {
    this.seniority = await globalPayrollApi.gpDetails.updateRoleDetails(this.originalId, newDetails);
  };

  updateContractPTODetails = async (newDetails: Record<string, any>) => {
    await globalPayrollApi.gpDetails.updateContractPTODetails(this.originalId, newDetails);
    this.employment = { ...this.employment, ...newDetails };
  };

  updateCustomFieldsInformation = (updatedField: CustomField) => {
    if (!this.customFields.find(({ id }) => id === updatedField.id)) {
      this.customFields = [...this.customFields, updatedField];
      return;
    }
    this.customFields = this.customFields.map((field) => {
      if (field.id === updatedField.id) return { ...field, value: updatedField.value };
      return field;
    });
  };

  getEmploymentsByStatus = (status: EmploymentTermStatus) =>
    this.sortedEmploymentStatuses.filter((comp: EmploymentTerm) => comp.status === status);

  getJobInfosByStatus = (status: EmploymentTermStatus) =>
    this.sortedJobInfos.filter((comp: EmploymentTerm) => comp.status === status);

  getEmploymentTermInEffect = (type?: EmploymentTermAmendedScope): EmploymentTerm | null => {
    const getDefaultActiveTerm = () =>
      this.employment.employmentTerms.find((term) => term.status === EmploymentTermStatus.ACTIVE) || null;

    if (!type) {
      return getDefaultActiveTerm();
    }

    const nonFutureTerms = this.employment.employmentTerms
      .filter((term) => term.status !== EmploymentTermStatus.UPCOMING && term.amendedItems.includes(type))
      .sort((a, b) => (a.effectiveDate > b.effectiveDate ? -1 : 1));
    return nonFutureTerms[0] || getDefaultActiveTerm();
  };

  deleteEmploymentTerm = (id: string, type: EmploymentTermAmendedScope) => {
    this.employment.employmentTerms = this.employment.employmentTerms.reduce<EmploymentTerm[]>((filtered, item) => {
      if (item.id !== id) {
        filtered.push(item);
      } else {
        const newAmendedItems = item.amendedItems.filter((item) => item !== type);
        if (newAmendedItems.length) {
          filtered.push({
            ...item,
            amendedItems: newAmendedItems,
          });
        }
      }
      return filtered;
    }, []);
  };

  deleteCompensation = async (id: string) => {
    await globalPayrollApi.gpDetails.deleteCompensation(id);
  };

  deleteEmploymentStatus = async (id: string) => {
    await globalPayrollApi.gpDetails.deleteEmploymentStatus(this.originalId, id);
    this.deleteEmploymentTerm(id, EmploymentTermAmendedScope.employment);
  };

  deleteJobInformation = async (id: string) => {
    await globalPayrollApi.gpDetails.deleteJobInformation(this.originalId, id);
    this.deleteEmploymentTerm(id, EmploymentTermAmendedScope.jobInfo);
  };

  resendInvitation = async (email: string) => {
    await globalPayrollApi.gpDetails.resendInvitation(this.originalId);
    this.employment.email = email;
  };

  isInCurrentPayrollEventCycle = (date: string | Date) => {
    if (!this.currentPayrollEvent) {
      return false;
    }

    return isWithinInterval(new Date(date), {
      start: startOfDay(new Date(this.currentPayrollEvent?.start)),
      end: endOfDay(new Date(this.currentPayrollEvent?.end)),
    });
  };

  isDateAfterPayrollEventSubmitted = (date: string | Date) => {
    if (!this.currentPayrollEvent || this.currentPayrollEvent.reportSubmittedAt === null) {
      return false;
    }
    return isAfter(new Date(date), new Date(this.currentPayrollEvent.reportSubmittedAt));
  };

  get isGlobalPayroll() {
    return true;
  }

  get isEor() {
    return false;
  }

  get isFixed() {
    return false;
  }

  get isPayAsYouGo() {
    return false;
  }

  get isPaygTasks() {
    return false;
  }

  get isMilestone() {
    return false;
  }

  get isCommission() {
    return false;
  }

  get isAccruedRefund() {
    return false;
  }

  get isDepositRefunded() {
    return false;
  }

  get isRefundingRequested() {
    return false;
  }

  get isEmployeeAgreement() {
    return false;
  }

  get sortedEmploymentStatuses() {
    return this.employment.employmentTerms
      .filter((term) => term.amendedItems.includes(EmploymentTermAmendedScope.employment))
      .sort((a, b) => (a.effectiveDate > b.effectiveDate ? -1 : 1));
  }

  get sortedJobInfos() {
    return this.employment.employmentTerms
      .filter((term) => term.amendedItems.includes(EmploymentTermAmendedScope.jobInfo))
      .sort((a, b) => (a.effectiveDate > b.effectiveDate ? -1 : 1));
  }

  get upcomingEmploymentTerms(): EmploymentTerm[] {
    return (
      this.employment.employmentTerms
        ?.filter((term) => term.status === EmploymentTermStatus.UPCOMING)
        .sort((x, y) => new Date(x.effectiveDate).getTime() - new Date(y.effectiveDate).getTime()) || []
    );
  }
}
