import { StatusBar, Style } from '@capacitor/status-bar';
import { isNative, isNativeAndroid } from '@/utils/crossPlatform';
import { LAST_USED_THEME_KEY } from '@/context/ThemeContext/ThemeContext';

export const DEFAULT_STATUS_BAR_COLOR_LIGHT = '#ffffff';
export const DEFAULT_STATUS_BAR_COLOR_DARK = '#282828';

const isValidHexColor = (color: string): boolean => /^#([0-9A-F]{3}){1,2}$/i.test(color);

var lastUsedColor: string = '';

/**
 * Changes the color of the status bar on Android.
 * @param statusBarColor      Color to apply to the status bar.
 * @param saveAsLastUsedColor Save the color as the last used color for future use.
 */
export const changeStatusBarColorAndroid = (statusBarColor: string, saveAsLastUsedColor = true) => {
  if (isNativeAndroid()) {
    let color = statusBarColor;
    if (statusBarColor.startsWith('--')) {
      color = getComputedStyle(document.documentElement).getPropertyValue(statusBarColor).trim();
    }
    if (!color || !isValidHexColor(color)) {
      changeToDefaultThemeColorAndStyle();
      return;
    }

    if (saveAsLastUsedColor) {
      lastUsedColor = color;
    }
    StatusBar.setBackgroundColor({ color });
  }
};

/**
 * Changes the style of the status bar.
 * @param style Style to apply to the status bar.
 */
export const changeStatusBarStyle = (style: Style) => {
  if (isNative()) {
    StatusBar.setStyle({ style });
  }
};

/**
 * Changes the status bar color and style based on the user's preferred theme or the last used color.
 * @param overrideLastUsedColor If true, clears the last used color.
 */
export const changeToDefaultThemeColorAndStyle = (overrideLastUsedColor = false) => {
  if (!isNative()) return;
  if (overrideLastUsedColor) {
    lastUsedColor = '';
  }

  const theme = localStorage.getItem(LAST_USED_THEME_KEY);
  const prefersDarkMode = window.matchMedia?.('(prefers-color-scheme: dark)').matches;
  let statusBarColor, statusBarStyle;
  switch (theme) {
    case 'system_default':
      statusBarColor = prefersDarkMode ? DEFAULT_STATUS_BAR_COLOR_DARK : DEFAULT_STATUS_BAR_COLOR_LIGHT;
      statusBarStyle = prefersDarkMode ? Style.Dark : Style.Light; // Set style based on detected theme
      break;
    case 'light':
      statusBarColor = DEFAULT_STATUS_BAR_COLOR_LIGHT;
      statusBarStyle = Style.Light;
      break;
    case 'dark':
      statusBarColor = DEFAULT_STATUS_BAR_COLOR_DARK;
      statusBarStyle = Style.Dark;
      break;
    default:
      // Fallback if the theme is unknown or unsupported
      statusBarColor = DEFAULT_STATUS_BAR_COLOR_LIGHT;
      statusBarStyle = Style.Light;
      break;
  }

  if (lastUsedColor.length > 0) {
    statusBarColor = lastUsedColor;
  }

  // TODO: Remove this once the Safe Area issue is fixed, or the status bar text won't appear on iOS in Dark Mode.
  if (isNativeAndroid()) {
    StatusBar.setBackgroundColor({ color: statusBarColor });
    StatusBar.setStyle({ style: statusBarStyle }); // Set status bar style
  }
};
