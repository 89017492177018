import BigNumber from 'bignumber.js';
import UserStore from '@/stores/UserStore';
import LookupStore from '../stores/LookupStore';

export const toOrdinal = (n) => {
  let s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

/**
 * Returns a random number between {min} and {max}
 * @param {string | number} min
 * @param {string | number} max
 * @returns {number}
 */
export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.ceil(max) + 1;
  return Math.floor(Math.random() * (max - min)) + min;
};

/**
 * Casts {data} to a number, if the data is of type number will return the data.
 * otherwise, removes all non numeric chars and returns the output
 * @param {string | number} data
 * @returns {number}
 */
export const toNum = (data) => {
  if (!data) return 0;
  if (typeof data === 'number') return data;
  return Number(data.replace(/[^0-9.]/g, ''));
};

/**
 * Returns true if all characters after the decimal point are 0
 * @param {string | number | bignumber} price
 * @returns {bool}
 */
export const onlyContainsZeroAfterDecimal = (price) => {
  return `${price}`
    ?.split('.')[1]
    ?.split('')
    ?.every((char) => char === '0');
};

/**
 * Formatting a price with {roundDigits} digits after decimal.
 * If the number is a fraction, the mimunum numbers after decimal will be {roundDigits}.
 * Adds thousand separator as well.
 * if forceFractionDigits- shows fraction digits even if 0
 * @param {string | number | bignumber} price
 * @param {number}                      roundDigits
 * @param {bool}                      forceFractionDigits
 * @returns {string}
 */
export const toPriceFormat = (price, roundDigits = null, forceFractionDigits = false) => {
  if (!+price) return '0';
  if (!roundDigits && +price < 1) roundDigits = 3;
  if (!roundDigits || roundDigits < 0) roundDigits = 2;

  const options = {};

  if (`${price}`.includes('.') || forceFractionDigits) {
    if (onlyContainsZeroAfterDecimal(price) && !forceFractionDigits) {
      options.maximumFractionDigits = 0;
    } else {
      options.maximumFractionDigits = roundDigits;
      options.minimumFractionDigits = roundDigits;
    }
  }

  return new Intl.NumberFormat('en-US', options).format(price);
};

export const getCurrencySymbolFromStore = (currency, useShortSymbol = false) => {
  if (useShortSymbol)
    return (
      LookupStore.currencies[currency]?.shortSymbol ||
      LookupStore.currencies[currency]?.symbol ||
      getCurrencySymbol(currency)
    );

  return (
    LookupStore.cryptoCurrencies[currency]?.label ||
    LookupStore.currencies[currency]?.symbol ||
    getCurrencySymbol(currency)
  );
};

export const isCryptoCurrency = (currency) => {
  if (!currency) return false;
  return !!LookupStore.cryptoCurrencies[currency];
};

export const minimumAmountValidate = (amount, currency, min = 0.01) => {
  const value = Number(amount);
  if (!isCryptoCurrency(currency) && 0 < value && value < min) {
    return false;
  }
  return true;
};

export const maximumAmountValidate = (amount) => {
  return new BigNumber(amount).isLessThanOrEqualTo('9999999999');
};

export const getCurrencySymbol = (currency) => {
  // to prevent issues caused by the anon db
  const currencyToShow = currency && !!LookupStore.currencies?.[currency] ? currency : 'USD';

  return (0)
    .toLocaleString('en-US', {
      style: 'currency',
      currency: currencyToShow,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
};

export const getCurrencySymbolPosition = (currency) => {
  const { positionRight } = LookupStore.currencies[currency] || {};

  return !!positionRight;
};

/**
 * Returns the price with currency symbol
 * @param {string | number | bignumber} price
 * @param {string}                      currencySymbol
 * @param {bool}                        positionRight
 * @returns {string}
 */
const withCurrency = (price, currencySymbol, positionRight, isCrypto) => {
  const minus = price[0] === '-' ? '-' : '';
  const addSpace = isCrypto ? ' ' : '';
  return positionRight
    ? `${price}${addSpace}${currencySymbol}`
    : `${minus}${currencySymbol}${addSpace}${price.replace('-', '')}`;
};

/**
 * Returns the price with currency symbol
 * if forceFractionDigits- shows fraction digits even if 0
 * @param {string | number | bignumber} price
 * @param {string}                      currency
 * @param {number}                      roundDigits
 * @param {bool}                        forceFractionDigits
 * @param {bool}                        useShortSymbol
 * @returns {string}
 */
export const toPrice = (
  price,
  currency = 'USD',
  roundDigits = null,
  forceFractionDigits = false,
  useShortSymbol = false
) => {
  if (UserStore.isEmployee) {
    console.warn('please use useSensitiveConvertToPriceFactory hook to hide sensitive data');
  }

  if (!currency) currency = 'USD';

  const { symbol = '$', shortSymbol, positionRight } = LookupStore.currencies[currency] || {};

  const isCryptoCurrencyValue = isCryptoCurrency(currency);

  const nonCryptoCurrencySymbol = useShortSymbol && shortSymbol ? shortSymbol : symbol;

  const priceSymbol = isCryptoCurrencyValue ? currency : nonCryptoCurrencySymbol;

  return withCurrency(
    toPriceFormat(price, roundDigits, forceFractionDigits),
    priceSymbol,
    positionRight,
    isCryptoCurrencyValue
  );
};

export const toPriceShort = (num, currency, digits = 1, useShortSymbol = false) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'm' },
    { value: 1e9, symbol: 'b' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  const result = item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';

  if (currency === false) return result;

  const currencyInfo = LookupStore.currencies[currency || 'USD'] || {};
  let { symbol = '$' } = currencyInfo;

  if (useShortSymbol && 'shortSymbol' in currencyInfo) {
    symbol = currencyInfo.shortSymbol;
  }

  return `${symbol}${result}`;
};

/**
 * Separate credit card by 4 digits.
 * @param {string | number | bignumber} cardNumber
 * @returns {array}
 */
export const splitCreditCardNumber = (cardNumber) => {
  return cardNumber.toString().match(/(.{4})/g);
};

export const numberWithCommas = (x) => {
  return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getDecimalPlace = (number) => {
  if (!number) return 0;

  const [, decimal] = String(number).split('.');
  return decimal?.length || 0;
};

export const isNumericString = (value) => {
  const onlyNumbersRegEx = /^\d+(\.\d+)?$/;
  return onlyNumbersRegEx.test(value);
};

/**
 * Shorten number to thousands, millions and billions.
 * @param {number} number
 * @param {number} digits
 * @returns {string | number}
 */

export const shortenLargeNumber = (number, digits = 2) => {
  const units = ['K', 'M', 'B', 'T'];

  for (let i = units.length - 1; i >= 0; i--) {
    let decimal = Math.pow(1000, i + 1);

    if (number <= -decimal || number >= decimal) {
      return +(number / decimal).toFixed(digits) + units[i];
    }
  }

  return number;
};

/**
 * Get percentage between total and value
 * @param {number | bignumber} total
 * @param {number | bignumber} value
 * @returns {string}
 */

export const getPercentage = (total, value) => {
  const percentage = (value * 100) / total;
  const options = {};

  options.maximumFractionDigits = 2;
  options.minimumFractionDigits = 2;

  return new Intl.NumberFormat('en-US', options).format(percentage);
};

export const toPercentage = (num) => {
  if (Number.isInteger(Number(num))) {
    return num + '%';
  }

  function removeTrailingZeros(str) {
    if (!str || (str?.length && str[str.length - 1] !== '0')) {
      return str;
    }
    const newStr = str.slice(0, -1);
    return removeTrailingZeros(newStr);
  }

  const [int, decimal] = String(num).split('.');
  const newDecimal = removeTrailingZeros(decimal);
  const percentage = newDecimal ? `${int}.${newDecimal}%` : int + '%';
  return percentage;
};

export const numberFormat = (num, options) => new Intl.NumberFormat('en-US', options).format(num);

export const toMultiplierFormat = (num = 0) => {
  const multiplier = Number(num);
  return ['0.5', '1.5'].includes(multiplier.toFixed(1)) ? `${multiplier.toFixed(1)}x` : `${Math.ceil(multiplier)}x`;
};
