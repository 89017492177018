import type { ReactElement, ReactNode } from 'react';
import { P, match } from 'ts-pattern';
import {
  differenceInDays,
  differenceInHours,
  endOfDay,
  format,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  parseISO,
  startOfDay,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc, format as tzFormat, toDate } from 'date-fns-tz';
import PriceLabel from '@/components/PriceLabel/PriceLabel';
import { UtcDateWithLocalTzTooltip } from '@/components/UtcDateWithLocalTzTooltip/UtcDateWithLocalTzTooltip';
import { DEFAULT_PAYROLL_CUTOFF_TIMEZONE } from '@/constants/timezone';
import type { IUpdater, PaymentsTrackerInList, PayrollEvent } from '@/types';
import { PAYROLL_EVENT_STATE, PAYROLL_FUNDING_STATUS, ReviewStatus } from '@/types';
import type { Employment } from '@/types/Employment';
import { PayrollEventTypes } from '@/types/ICP/PayrollEvents';
import type { PAYROLL_DEPOSIT_STATUS } from '@/types/LegalEntity';
import { pluralString } from '@/utils/string';
import type { PermissionName } from '@/types/Authorization';
import { formatDate, getTimeFrom } from '@/utils/time';
import type { FeedbackPopupContentProps } from '@letsdeel/ui';
import { DateFormat, Stack, createDate, formatInUtc } from '@letsdeel/ui';
import type { GPCalendar, GPCalendarDate } from '@/hooks/api/globalPayroll/useFetchLegalEntityPayrollCalendar';
import {
  AWAITING_FUNDING_PAYROLL_EVENT_STATUS,
  PAYROLL_EVENT_LIST_STATE,
  awaitingFundingEventStatusByFundingStatus,
  awaitingFundingEventStatusByDepositStatus,
} from '@/scenes/Payroll/const';
import { useTranslation } from 'react-i18next';
import type { AdjustmentSummaryResponse } from '@/types/Adjustments/PayrollReport';

export const getEmployeesString = (count: number | undefined) =>
  count !== undefined ? `${count} ${pluralString('employee', count)}` : null;

export const getCostValue = (amount: number | undefined | string, currency: string) =>
  amount === undefined ? null : <PriceLabel amount={Number(amount)} currency={currency} />;

export const getPercentageDiff = (a: number, b: number) => {
  return 100 * ((b - a) / a) || 0;
};

export type PercentageDiff = 'UP' | 'DOWN';
export type CyclesDiff = { diffType: PercentageDiff; diff: number } | null;

export const getCyclesDiff = (prevTotal: number, currentTotal: number, diffThreshold: number = 0): CyclesDiff => {
  let diff;

  if (currentTotal === 0 && prevTotal !== 0) {
    diff = -100;
  } else if (prevTotal !== 0) {
    diff = getPercentageDiff(prevTotal, currentTotal);
  } else if (currentTotal === 0 && prevTotal === 0) {
    return null;
  } else {
    diff = 100;
  }

  const diffAbs = Math.abs(diff);

  if (diffAbs > diffThreshold) {
    return { diffType: diff >= 0 ? 'UP' : 'DOWN', diff: diffAbs };
  }

  return null;
};

type ReportRejectParam = {
  issuesReported: number;
  wasRejected: boolean;
};

export const parseReportRejectParam = (param: string | null): ReportRejectParam | undefined => {
  if (typeof param !== 'string') return undefined;
  if (!param.includes(',')) return undefined;

  const [issuesReported, wasRejected] = param.split(',');

  if (!Number.isInteger(Number(issuesReported)) || !['true', 'false'].includes(wasRejected)) return undefined;

  return {
    issuesReported: Number(issuesReported),
    wasRejected: wasRejected === 'true',
  };
};

export const getReportRejectFeedbackPopupProps = (
  params: ReportRejectParam | undefined
): FeedbackPopupContentProps | undefined => {
  if (!params) return undefined;
  if (params.issuesReported > 0) {
    const issuesPlural = pluralString('issue', params.issuesReported);
    const youHaveReported = `You have reported ${params.issuesReported} ${issuesPlural}`;
    const themIt = params.issuesReported === 1 ? 'it' : 'them';
    return {
      title: params.wasRejected ? `${youHaveReported} & rejected the payroll package` : youHaveReported,
      text: `You can see ${params.wasRejected ? themIt : `the ${issuesPlural}`} in the Payroll Support section`,
    };
  } else if (params.wasRejected) {
    return {
      title: 'You have rejected the payroll package',
      text: 'You can see the reported issues in the Payroll Support section',
    };
  } else {
    return undefined;
  }
};

export const getPayrollCycleText = (
  cycle?: { start: string | Date; end?: string | Date; type?: keyof typeof PayrollEventTypes } | null,
  showYear: boolean = true,
  showEndMonth = true
): string => {
  if (!cycle) {
    return '';
  }

  const startDate = createDate(cycle.start);
  const endDate = cycle.end ? createDate(cycle.end) : null;

  if (cycle.type === PayrollEventTypes.OFFCYCLE || !endDate || +startDate === +endDate) {
    return formatInUtc(startDate, showYear ? DateFormat.DATE_YEAR : DateFormat.DATE);
  }

  const startMonth = getMonth(utcToZonedTime(startDate, 'UTC'));
  const endMonth = endDate ? getMonth(utcToZonedTime(endDate, 'UTC')) : null;
  const startYear = getYear(utcToZonedTime(startDate, 'UTC'));
  const endYear = endDate ? getYear(utcToZonedTime(endDate, 'UTC')) : null;

  return `${format(utcToZonedTime(startDate, 'UTC'), `MMM do${startYear !== endYear ? ', yyyy' : ''}`)} - ${format(
    utcToZonedTime(endDate, 'UTC'),
    `${showEndMonth || startMonth !== endMonth ? 'MMM ' : ''}do${showYear || startYear !== endYear ? ', yyyy' : ''}`
  )}`;
};

export const getPayrollCycleStateText = (cycle: PayrollEvent) => {
  return match(cycle)
    .with(
      { PaymentSubmittedAt: P.string },
      ({ PaymentSubmittedAt }) => `Completed at ${format(new Date(PaymentSubmittedAt), DateFormat.TIME_DATE_TIMEZONE)}`
    )
    .with(
      { PayrollApprovedAt: P.string },
      ({ PayrollApprovedAt }) => `Approved at ${format(new Date(PayrollApprovedAt), DateFormat.TIME_DATE_TIMEZONE)}`
    )
    .otherwise(() => '');
};

export const getEmployeeName = (payrollEmployment?: Employment | null): string => {
  if (!payrollEmployment) return '';

  const {
    employee: { firstName, middleName, lastName },
  } = payrollEmployment;

  return `${firstName}${middleName ? ` ${middleName}` : ''} ${lastName}`;
};

export const getUpdaterDisplayName = (Updater?: IUpdater | null, userId?: number | string | null): string | null =>
  userId === Updater?.id
    ? 'you'
    : Updater?.email?.toLowerCase().includes('deel.com')
    ? 'Deel'
    : Updater?.Profiles[0]?.name || null;

export const getIsOverdue = (dateLimit?: string | Date | null, dateSent?: string | Date | null): boolean => {
  if (!dateLimit) return false;

  const limitDate = toDate(dateLimit ? getUTCEndOfDayFromUTCString(dateLimit) : dateLimit);

  let comparableDate = dateSent ? toDate(dateSent) : new Date();

  return isAfter(comparableDate, limitDate);
};

export const getDaysDiff = (
  fromDate: Date | string | undefined | null,
  toDate: Date | string | undefined | null
): number => {
  if (!fromDate) return 0;

  const start = createDate(fromDate);
  const end = toDate ? createDate(toDate) : new Date();

  return differenceInDays(end, start);
};

export const getDaysOverdue = (dueDate: string | Date, dateEnd?: string | Date | null) =>
  getDaysDiff(dueDate, dateEnd ? getUtcOrTzDate(dateEnd) : new Date());

const getDaysOverdueText = (dueDate?: string | Date | null, dateEnd?: string | Date | null): string => {
  const daysOverdue = getDaysDiff(dueDate, dateEnd || new Date());

  return daysOverdue > 0 ? `${daysOverdue} ${pluralString('Day', daysOverdue)} Overdue` : '';
};

const getIsLessThan24HoursLeft = (date: string) => {
  const now = new Date();
  const targetDate = parseISO(date);
  const hoursDiff = differenceInHours(targetDate, now);
  return hoursDiff < 24;
};

const hasLessThanXHoursLeft = (date: string, hours: number) => {
  const now = new Date();
  const targetDate = parseISO(date);
  const hoursDiff = differenceInHours(targetDate, now);
  return hoursDiff < hours;
};

const getDifferenceInDays = (date: string | Date) => {
  const today = startOfDay(new Date()); // This ensures the comparison is from the start of today.
  const inputDate = startOfDay(createDate(date));
  const dateDifferenceInDays = differenceInDays(inputDate, today);

  return `${dateDifferenceInDays} ${Math.abs(dateDifferenceInDays) === 1 ? 'day' : 'days'}`;
};

export const getDueDateInTimezone = ({
  date,
  showTimeLeftCountdown,
  showCountDownTimeLimit = 24,
  addPrefix = true,
  addSuffix = true,
  embeddedPayrollEnabled = false,
  format,
}: {
  date: string;
  showTimeLeftCountdown?: 'always' | 'with-date' | 'on-limit-reached';
  showCountDownTimeLimit?: number;
  addPrefix?: boolean;
  addSuffix?: boolean;
  format?: 'day' | 'daytime' | 'date' | 'datetime';
  timezone?: string;
  embeddedPayrollEnabled?: boolean;
}) => {
  let dueDate = <UtcDateWithLocalTzTooltip date={date} prefix={addPrefix ? <>&nbsp;&nbsp;</> : ''} format={format} />;

  const isOverdue = getIsOverdue(date);
  let timeLeftText = isOverdue ? '' : getTimeFrom(new Date(date), null, true) + (addSuffix ? ' left' : '');
  if (embeddedPayrollEnabled) {
    timeLeftText = isOverdue ? '' : getDifferenceInDays(new Date(date)) + (addSuffix ? ' left' : '');
  }

  if (!timeLeftText) return dueDate;

  if (!showTimeLeftCountdown) return dueDate;

  if (
    ['on-limit-reached', 'always'].includes(showTimeLeftCountdown) &&
    hasLessThanXHoursLeft(date, showCountDownTimeLimit)
  ) {
    return `${addPrefix ? 'in ' : ''}${timeLeftText}`;
  }

  if (['with-date', 'always'].includes(showTimeLeftCountdown)) {
    return (
      <Stack direction="row">
        {dueDate},&nbsp;{timeLeftText}
      </Stack>
    );
  }

  return dueDate;
};

export type AlertType = 'warning' | 'danger' | 'success' | 'grey' | 'default' | '' | 'error';

const getDueDateAlertType = (date: any) => (getDaysDiff(createDate(date), new Date()) > 0 ? 'danger' : 'warning');

export type EXTENDED_PAYROLL_EVENT_STATE =
  | Exclude<PAYROLL_EVENT_STATE, PAYROLL_EVENT_STATE.AWAITING_GP_PAYMENT>
  | AWAITING_FUNDING_PAYROLL_EVENT_STATUS;

export const getPayrollEventExtendedStatus = ({
  eventState,
  isPeo,
  depositStatus = null,
  fundingStatus = null,
  isParallelRun,
}: {
  eventState: PAYROLL_EVENT_STATE;
  isPeo: boolean;
  depositStatus?: PAYROLL_DEPOSIT_STATUS | null;
  fundingStatus?: PAYROLL_FUNDING_STATUS | null;
  isParallelRun?: boolean;
}): EXTENDED_PAYROLL_EVENT_STATE | null => {
  if (eventState === PAYROLL_EVENT_STATE.AWAITING_GP_PAYMENT) {
    if ((isParallelRun || isPeo) && fundingStatus) {
      return awaitingFundingEventStatusByFundingStatus[fundingStatus] || null;
    }

    if (depositStatus) {
      const stateByDepositStatus = awaitingFundingEventStatusByDepositStatus[depositStatus];

      if (stateByDepositStatus) {
        return stateByDepositStatus;
      }

      if (fundingStatus) {
        return awaitingFundingEventStatusByFundingStatus[fundingStatus];
      }
    }
    return null;
  }

  return eventState;
};

export interface EventData {
  getAlertType: (date?: string | null) => AlertType;
  label: string;
  getLabel?: ({ payrollEvent }: { payrollEvent?: PayrollEvent | null }) => string;
  dateProp?: 'lock' | 'PaymentSubmittedAt' | 'paymentDueDate' | 'ClientG2NReportSubmittedAt';
  getLabelSubtext?: (date?: string | null) => string | ReactElement;
  group: PAYROLL_EVENT_LIST_STATE;
}

export const getUtcOrTzDate = (date?: string | Date | null) => {
  if (!date) return '';
  const dateObj = createDate(date);
  const utcDate = zonedTimeToUtc(dateObj, 'UTC');
  const formattedUtcDate = format(utcDate, 'HH:mm:ss');

  if (formattedUtcDate === '00:00:00') {
    return format(utcDate, 'MMM do');
  } else {
    const timeZone = DEFAULT_PAYROLL_CUTOFF_TIMEZONE; // Example: 'America/New_York'
    const zonedDate = utcToZonedTime(dateObj, timeZone);
    return tzFormat(zonedDate, 'MMM d', { timeZone });
  }
};

export const getUtcDateWithTzTooltip = (
  date?: string | Date | null,
  format?: 'day' | 'daytime' | 'date' | 'datetime'
) => {
  const parsedDate = date ? createDate(date) : null;
  return parsedDate ? <UtcDateWithLocalTzTooltip isBold format={format} date={parsedDate} /> : '';
};

const getDueDate = (
  plannedDate?: string | Date | null,
  date?: string | Date | null,
  type?: string,
  ifOverdueUseThisDate?: string | Date | null
) => {
  if (!plannedDate || !date) return '';

  let dueDate = getUtcDateWithTzTooltip(plannedDate);

  if (type === 'REPORT_SUBMITTED') {
    return ifOverdueUseThisDate ? getUtcDateWithTzTooltip(ifOverdueUseThisDate) : dueDate;
  }

  if (!plannedDate) {
    return getIsOverdue(date) ? getDaysOverdueText(date) : dueDate;
  }

  return getDaysDiff(plannedDate, date) > 0 ? getDaysOverdueText(plannedDate, date) : dueDate;
};

export const getLabelSubtext = (
  dateLimit?: string | Date | null,
  dateSent?: string | Date | null,
  type?: AWAITING_FUNDING_PAYROLL_EVENT_STATUS | PAYROLL_EVENT_STATE
) => {
  if (!dateLimit) return '';

  const isOverdue = getIsOverdue(dateLimit, dateSent);
  let daysOverdue = getDaysDiff(dateLimit, dateSent || new Date());
  daysOverdue = isOverdue && daysOverdue === 0 ? 1 : daysOverdue;
  const isBeforeDeadline = daysOverdue < 0;
  const daysAbs = Math.abs(daysOverdue);

  const futureOrTodayDateText = isBeforeDeadline ? `in ${daysAbs} ${daysAbs >= 1 ? 'days' : 'day'}` : 'today';

  switch (type) {
    case PAYROLL_EVENT_STATE.REPORT_SUBMITTED:
      return isOverdue ? `Submitted ${daysOverdue} days late` : '';
    case PAYROLL_EVENT_STATE.PENDING_PACKAGE_APPROVAL:
      return isOverdue ? `Approval is ${daysOverdue} days late` : `Awaiting approval ${futureOrTodayDateText}`;
    case AWAITING_FUNDING_PAYROLL_EVENT_STATUS.AWAITING_DEPOSIT_PAYMENT:
      return isOverdue ? `Deposit is ${daysOverdue} days late` : `Awaiting deposit ${futureOrTodayDateText}`;
    case PAYROLL_EVENT_STATE.AWAITING_SELF_PAYMENT:
    case AWAITING_FUNDING_PAYROLL_EVENT_STATUS.AWAITING_FUNDING_PAYMENT:
      return isOverdue ? `Payment is ${daysOverdue} days late` : `Awaiting payment ${futureOrTodayDateText}`;
    case AWAITING_FUNDING_PAYROLL_EVENT_STATUS.PROCESSING_FUNDING_PAYMENT:
      return isOverdue ? `Paid ${daysOverdue} days late` : 'Processing funding';
    case AWAITING_FUNDING_PAYROLL_EVENT_STATUS.PROCESSING_DEPOSIT_PAYMENT:
      return isOverdue ? `Paid ${daysOverdue} days late` : '';
    case PAYROLL_EVENT_STATE.COMPLETE:
      return isOverdue ? `Cycle completed ${daysOverdue} days late` : 'Completed';
    default:
      return isOverdue ? `Submission is ${daysOverdue} days late` : `Submit ${futureOrTodayDateText}`;
  }
};

const getAlertType = (
  dateLimit?: string | null | Date,
  dateSent?: string | null | Date,
  type?: AWAITING_FUNDING_PAYROLL_EVENT_STATUS | PAYROLL_EVENT_STATE
) => {
  if (!dateLimit) return '';

  switch (type) {
    case PAYROLL_EVENT_STATE.COMPLETE:
      return getIsOverdue(dateLimit, dateSent) ? 'error' : 'success';
    case AWAITING_FUNDING_PAYROLL_EVENT_STATUS.PROCESSING_FUNDING_PAYMENT:
    case AWAITING_FUNDING_PAYROLL_EVENT_STATUS.PROCESSING_DEPOSIT_PAYMENT:
    default:
      return getIsOverdue(dateLimit, dateSent) ? 'error' : '';
  }
};

type EventDataByState = {
  dateProp?: 'lock' | 'PaymentSubmittedAt' | 'paymentDueDate' | 'end' | 'ClientG2NReportSubmittedAt';
  label: string | ReactNode;
  labelSubtext: string;
  actionLabel: string;
  actionLabelSubtext: string;
  alertType: AlertType;
  buttonLabel?: string;
  modalTitle?: string;
  modalText?: ReactNode;
  permission?: PermissionName;
};

export function getEmbeddedPayrollReviewStatus(event?: PayrollEvent, profileId?: number) {
  if (!event || !event.payrollSettings?.embeddedPayrollEnabled || event.actionState !== 'NEW' || !profileId) {
    return null;
  }

  let reviewStatus;

  try {
    const approverProfileIds = event.approvedBy?.map(({ payrollReviewer }) => payrollReviewer.profileId) || [];
    const userHasApproved = approverProfileIds.find((reviewerId) => reviewerId === profileId) || false;

    if (
      event.payrollSettings?.reviewSettings?.reviewSteps === event.missingApprovals?.length &&
      event.missingApprovals?.every((numApprovalsMissingInStep) => numApprovalsMissingInStep === 0)
    ) {
      reviewStatus = ReviewStatus.REPORT_SUBMITTED;
    } else if (userHasApproved) {
      reviewStatus = ReviewStatus.WAITING_FOR_SUBMISSION;
    } else {
      const userReviewer = event.payrollSettings.reviewSettings?.allowedReviewers?.find(
        (reviewer) => reviewer.profileId === profileId
      );

      if (userReviewer) {
        const isFinalApprover = userReviewer.reviewStep === event.payrollSettings.reviewSettings?.reviewSteps;
        const needsFinalApproval = event.missingApprovals
          ? event.missingApprovals.findIndex((x) => x > 0) === event.missingApprovals.length - 1
          : false;

        if (needsFinalApproval && isFinalApprover) {
          reviewStatus = ReviewStatus.CAN_SUBMIT;
        } else if (!needsFinalApproval && !isFinalApprover) {
          reviewStatus = ReviewStatus.CAN_APPROVE;
        } else {
          reviewStatus = ReviewStatus.WAITING_FOR_APPROVAL;
        }
      } else {
        reviewStatus = ReviewStatus.WAITING_FOR_APPROVAL;
      }
    }
  } catch (e) {
    reviewStatus = ReviewStatus.WAITING_FOR_APPROVAL;
  }

  return reviewStatus;
}

const getDueDateForSelfPayment = (
  payrollFundingDate: GPCalendarDate | undefined,
  payrollEventEndDate: GPCalendarDate | undefined,
  payslipsPublishedDate: GPCalendarDate | undefined,
  payDate: GPCalendarDate | undefined,
  paymentsTracker: PaymentsTrackerInList | undefined,
  isPeo: boolean
) => {
  if (isPeo) {
    return payrollEventEndDate;
  }

  if (paymentsTracker?.isLive) {
    if (paymentsTracker.dates.payslipPublication) {
      return payDate;
    } else {
      return payslipsPublishedDate;
    }
  }
  return payrollFundingDate;
};

export const useGetEventDateAndStatusActionState = (
  payrollEvent: PayrollEvent,
  profileId: number,
  payrollCalendar?: GPCalendar,
  embeddedPayrollEnabled = false,
  isParallelRun = false
): EventDataByState => {
  const { t } = useTranslation();
  const isPeo = payrollEvent.payrollSettings?.experienceType === 'PEO';

  const event = {
    eventState: payrollEvent.actionState,
    depositStatus: payrollEvent.PayrollDeposit?.status,
    fundingStatus: payrollEvent.PayrollFunding?.status,
    isParallelRun,
    isPeo,
  };

  const isSoftGPEnabled = payrollEvent.payrollSettings?.isSoftGPEnabled;
  const isHistorical = payrollEvent.type === PayrollEventTypes.HISTORICAL;
  const paymentsTracker = payrollEvent.paymentsTracker;

  const extendedStatus =
    isSoftGPEnabled || isHistorical
      ? PAYROLL_EVENT_STATE.PROCESSED_BY_EXTERNAL_PROVIDER
      : getPayrollEventExtendedStatus(event);

  const {
    payrollSubmissionDate,
    payrollProcessingDate,
    payrollApprovalDate,
    payrollFundingDate,
    paymentReceiptDate,
    payslipsPublishedDate,
    payDate,
    softGPG2NUploadDate,
  } = payrollCalendar || {};

  const { ReportSubmittedAt, ReportProcessedAt, PayrollApprovedAt, PackageApprovedAt } = payrollEvent;

  const datePaymentSubmittedAt = payrollEvent.PaymentSubmittedAt;
  const isOverDueReport = getDaysDiff(payrollSubmissionDate, new Date()) > 0;
  const isOverDuePayment = getDaysDiff(payrollApprovalDate, PayrollApprovedAt) > 0;
  const reviewStatus = embeddedPayrollEnabled ? getEmbeddedPayrollReviewStatus(payrollEvent, profileId) : null;

  const embeddedPayrollProps: Record<
    ReviewStatus,
    { buttonLabel?: string; actionLabel?: string; permission?: PermissionName }
  > = {
    [ReviewStatus.CAN_APPROVE]: {
      buttonLabel: 'Approve Payroll',
      permission: 'global_payroll.approve',
    },
    [ReviewStatus.CAN_SUBMIT]: {
      buttonLabel: embeddedPayrollEnabled ? 'View Report' : 'Submit Report',
      permission: 'global_payroll.submit',
    },
    [ReviewStatus.REPORT_SUBMITTED]: {
      actionLabel: 'Payment Processing',
    },
    [ReviewStatus.WAITING_FOR_SUBMISSION]: {
      actionLabel: 'Waiting for submission',
    },
    [ReviewStatus.WAITING_FOR_APPROVAL]: {
      actionLabel: 'Awaiting 1st Approval',
    },
  };

  const dueDateForSelfPayment = getDueDateForSelfPayment(
    payrollFundingDate,
    payrollEvent.end,
    payslipsPublishedDate,
    payDate,
    paymentsTracker,
    isPeo
  );
  const dueDateForComplete = isPeo ? payrollEvent.end : payrollEvent.PaymentSubmittedAt;

  const defaultEventData: EventDataByState = {
    label: '',
    labelSubtext: '',
    actionLabel: '',
    actionLabelSubtext: '',
    alertType: 'danger',
  };

  const { employerCost } = payrollEvent?.payrollCost ?? {};

  switch (extendedStatus) {
    case PAYROLL_EVENT_STATE.NEW:
      return {
        ...defaultEventData,
        label: embeddedPayrollEnabled ? (
          <UtcDateWithLocalTzTooltip date={payrollSubmissionDate} />
        ) : (
          getDueDate(payrollSubmissionDate, ReportSubmittedAt)
        ),
        labelSubtext: getLabelSubtext(payrollSubmissionDate, ReportSubmittedAt || new Date(), extendedStatus),
        alertType: isOverDueReport ? 'error' : '',
        buttonLabel: '',
        modalTitle: isOverDueReport ? t('payroll.payrollCycle.overview.submissionOverdue') : '',
        modalText: isOverDueReport ? t('payroll.payrollCycle.overview.takeActionWarning') : '',
        permission: 'global_payroll.start_review',
        ...(embeddedPayrollEnabled && reviewStatus
          ? embeddedPayrollProps[reviewStatus]
          : { buttonLabel: 'Start review' }),
      };
    case PAYROLL_EVENT_STATE.REPORT_PROCESSING:
      return {
        label: getDueDate(
          payrollProcessingDate,
          ReportSubmittedAt,
          PAYROLL_EVENT_STATE.REPORT_SUBMITTED,
          payrollEvent?.ReportSubmittedAt ? payrollEvent?.ReportSubmittedAt : undefined
        ),
        labelSubtext: getLabelSubtext(payrollProcessingDate, ReportSubmittedAt, PAYROLL_EVENT_STATE.REPORT_SUBMITTED),
        alertType: getAlertType(payrollProcessingDate, ReportSubmittedAt, PAYROLL_EVENT_STATE.REPORT_SUBMITTED),
        actionLabel: 'Processing Report',
        actionLabelSubtext: t('payroll.payrollCycle.overview.deelIsWorkingOnIt'),
        modalTitle: getIsOverdue(payrollProcessingDate, ReportProcessedAt)
          ? t('payroll.payrollCycle.overview.submissionOverdue')
          : '',
        modalText: getIsOverdue(payrollProcessingDate, ReportProcessedAt)
          ? `is being processed ${getDaysDiff(payrollProcessingDate, ReportProcessedAt)} days late by Deel`
          : '',
      };
    case PAYROLL_EVENT_STATE.REPORT_SUBMITTED:
      return {
        label: getDueDate(
          payrollProcessingDate,
          ReportSubmittedAt,
          extendedStatus,
          payrollEvent?.ReportSubmittedAt ? payrollEvent?.ReportSubmittedAt : undefined
        ),
        labelSubtext: getLabelSubtext(payrollProcessingDate, ReportSubmittedAt, extendedStatus),
        alertType: getAlertType(payrollProcessingDate, ReportSubmittedAt, extendedStatus),
        actionLabel: 'Processing Report',
        actionLabelSubtext: t('payroll.payrollCycle.overview.deelIsWorkingOnIt'),
        modalTitle: getIsOverdue(payrollProcessingDate, ReportProcessedAt)
          ? t('payroll.payrollCycle.overview.submissionOverdue')
          : '',
        modalText: t('payroll.payrollCycle.overview.takeActionWarning'),
      };
    case PAYROLL_EVENT_STATE.PENDING_REVIEW:
      return {
        label: getDueDate(payrollProcessingDate, ReportSubmittedAt, PAYROLL_EVENT_STATE.REPORT_SUBMITTED),
        labelSubtext: getLabelSubtext(payrollProcessingDate, ReportSubmittedAt, PAYROLL_EVENT_STATE.REPORT_SUBMITTED),
        alertType: getAlertType(payrollProcessingDate, ReportProcessedAt, PAYROLL_EVENT_STATE.REPORT_SUBMITTED),
        actionLabel: 'Processing Report',
        actionLabelSubtext: t('payroll.payrollCycle.overview.deelIsWorkingOnIt'),
        permission: 'global_payroll.upload_g2n',
        ...(isHistorical ? { buttonLabel: 'Upload G2N', actionLabel: '', actionLabelSubtext: '' } : {}),
      };
    case PAYROLL_EVENT_STATE.PENDING_PACKAGE_APPROVAL:
      return {
        ...defaultEventData,
        label: getDueDate(payrollApprovalDate, PackageApprovedAt),
        labelSubtext: getLabelSubtext(payrollApprovalDate, PackageApprovedAt || new Date(), extendedStatus),
        alertType: getAlertType(payrollApprovalDate, PackageApprovedAt, extendedStatus),
        buttonLabel: 'Review Approval',
        modalTitle: isOverDuePayment ? 'Approval overdue' : '',
        modalText: t('payroll.payrollCycle.overview.takeActionWarning'),
        permission: 'global_payroll.review_approval',
      };

    case PAYROLL_EVENT_STATE.AWAITING_SELF_PAYMENT:
      return {
        ...defaultEventData,
        label: getDueDate(dueDateForSelfPayment, new Date()),
        labelSubtext: getLabelSubtext(dueDateForSelfPayment, new Date(), extendedStatus),
        alertType: getAlertType(dueDateForSelfPayment, new Date()),
        buttonLabel:
          (paymentsTracker?.isLive && !paymentsTracker.dates.employeePayoutsRelease) || isPeo
            ? undefined
            : 'Mark As Paid',
        actionLabel: (() => {
          if (!paymentsTracker?.isLive) return '';

          return paymentsTracker?.isLive && !paymentsTracker.dates.payslipPublication
            ? 'Awaiting payslips publication'
            : 'Awaiting employee payouts release';
        })(),
        modalTitle: getIsOverdue(dueDateForSelfPayment, new Date())
          ? paymentsTracker?.isLive
            ? !paymentsTracker.dates.payslipPublication
              ? 'Payslips Publication Overdue'
              : 'Employee Payouts Release Overdue'
            : 'Payment overdue'
          : '',
        modalText: t('payroll.payrollCycle.overview.takeActionWarning'),
        permission: 'global_payroll.mark_as_paid',
      };
    case AWAITING_FUNDING_PAYROLL_EVENT_STATUS.AWAITING_DEPOSIT_PAYMENT:
      return {
        ...defaultEventData,
        label: getDueDate(payrollFundingDate, PackageApprovedAt),
        labelSubtext: getLabelSubtext(payrollFundingDate, PackageApprovedAt, extendedStatus),
        alertType: getAlertType(payrollFundingDate, PackageApprovedAt),
        buttonLabel: 'Pay Funding',
        modalTitle: payrollFundingDate
          ? getIsOverdue(payrollFundingDate, PackageApprovedAt)
            ? 'Funding overdue'
            : ''
          : '',
        modalText: t('payroll.payrollCycle.overview.takeActionWarning'),
        permission: 'global_payroll.pay_funding',
      };
    case AWAITING_FUNDING_PAYROLL_EVENT_STATUS.DEPOSIT_PAYMENT_FAILED:
      return {
        ...defaultEventData,
        label: datePaymentSubmittedAt
          ? getUtcDateWithTzTooltip(datePaymentSubmittedAt)
          : getDueDate(payrollFundingDate, PackageApprovedAt),
        labelSubtext: t('payroll.payrollCycle.overview.fundingFailed'),
        alertType: getAlertType(payrollFundingDate, datePaymentSubmittedAt!),
        buttonLabel: 'Retry Payment',
        modalTitle: t('payroll.payrollCycle.overview.fundingFailed'),
        modalText: t('payroll.payrollCycle.overview.takeActionWarning'),
        permission: 'global_payroll.retry_payment',
      };
    case AWAITING_FUNDING_PAYROLL_EVENT_STATUS.PROCESSING_DEPOSIT_PAYMENT:
      return {
        label: getIsOverdue(paymentReceiptDate, payrollEvent.PaymentSubmittedAt || new Date())
          ? getUtcDateWithTzTooltip(payrollEvent.PaymentSubmittedAt! || paymentReceiptDate)
          : '',
        labelSubtext: getLabelSubtext(paymentReceiptDate, payrollEvent.PaymentSubmittedAt!, extendedStatus),
        alertType: getAlertType(paymentReceiptDate, payrollEvent.PaymentSubmittedAt!, extendedStatus),
        actionLabel: 'Processing Payment',
        actionLabelSubtext: t('payroll.payrollCycle.overview.deelIsWorkingOnIt'),
      };
    case AWAITING_FUNDING_PAYROLL_EVENT_STATUS.AWAITING_FUNDING_PAYMENT:
      return {
        ...defaultEventData,
        label: getIsOverdue(payrollFundingDate, PackageApprovedAt)
          ? getDaysOverdueText(payrollFundingDate)
          : getUtcDateWithTzTooltip(payrollFundingDate),
        labelSubtext: getLabelSubtext(payrollFundingDate, PackageApprovedAt, extendedStatus),
        alertType: getAlertType(payrollFundingDate, PackageApprovedAt),
        buttonLabel: 'Pay Funding',
        modalTitle: getIsOverdue(payrollFundingDate, PackageApprovedAt) ? 'Funding overdue' : '',
        modalText: t('payroll.payrollCycle.overview.takeActionWarning'),
        permission: 'global_payroll.pay_funding',
      };
    case AWAITING_FUNDING_PAYROLL_EVENT_STATUS.PROCESSING_FUNDING_PAYMENT:
      return {
        label: getIsOverdue(paymentReceiptDate, payrollEvent.PayrollFunding?.Invoice?.processedAt || new Date())
          ? getUtcDateWithTzTooltip(payrollEvent.PayrollFunding?.Invoice?.processedAt || paymentReceiptDate)
          : '',
        labelSubtext: getLabelSubtext(
          paymentReceiptDate,
          payrollEvent.PayrollFunding?.Invoice?.processedAt || new Date(),
          extendedStatus
        ),
        alertType: getAlertType(
          paymentReceiptDate,
          payrollEvent.PayrollFunding?.Invoice?.processedAt || new Date(),
          extendedStatus
        ),
        actionLabel: (() => {
          if (employerCost !== undefined && payrollEvent.type === 'RECONCILIATION') {
            if (employerCost > 0) return 'Additional tax liabilities';
            if (employerCost < 0) return 'Refund';
          }

          if (!paymentsTracker?.isLive || payrollEvent.PayrollFunding?.status !== PAYROLL_FUNDING_STATUS.COMPLETE) {
            return 'Processing Funding';
          }

          return !paymentsTracker.dates.payslipPublication
            ? 'Awaiting payslips publication'
            : 'Awaiting employee payouts release';
        })(),
        actionLabelSubtext: (() => {
          if (employerCost !== undefined && payrollEvent.type === 'RECONCILIATION') {
            return 'Processed after quarter end filing';
          }

          if (!paymentsTracker?.isLive) {
            return t('payroll.payrollCycle.overview.deelIsWorkingOnIt');
          }

          if (payrollEvent.PayrollFunding?.status !== PAYROLL_FUNDING_STATUS.COMPLETE) {
            return !paymentsTracker.dates.payslipPublication
              ? 'Awaiting payslips publication'
              : !paymentsTracker.dates.employeePayoutsRelease
              ? 'Awaiting employee payouts release'
              : 'Awaiting funding receipt';
          } else return t('payroll.payrollCycle.overview.deelIsWorkingOnIt');
        })(),
      };
    case AWAITING_FUNDING_PAYROLL_EVENT_STATUS.FUNDING_PAYMENT_FAILED:
      return {
        ...defaultEventData,
        labelSubtext: t('payroll.payrollCycle.overview.fundingFailed'),
        label: getUtcDateWithTzTooltip(payrollEvent.PackageApprovedAt),
        alertType: getAlertType(payrollEvent.PackageApprovedAt!, new Date()),
        buttonLabel: 'Retry Payment',
        modalTitle: t('payroll.payrollCycle.overview.fundingFailed'),
        modalText: t('payroll.payrollCycle.overview.takeActionWarning'),
        permission: 'global_payroll.retry_payment',
      };
    case PAYROLL_EVENT_STATE.COMPLETE:
      return {
        ...defaultEventData,
        label: getUtcDateWithTzTooltip(dueDateForComplete || payDate),
        labelSubtext: getLabelSubtext(payDate, dueDateForComplete || new Date(), extendedStatus),
        alertType: getIsOverdue(payDate, dueDateForComplete || new Date()) ? 'error' : 'success',
        actionLabel: 'Completed',
        buttonLabel: '',
      };
    // For now, we don't have the action date so the labelSubtext and buttonLabel are mocked without the complete logic
    case PAYROLL_EVENT_STATE.PROCESSED_BY_EXTERNAL_PROVIDER:
      return {
        ...defaultEventData,
        label: getUtcDateWithTzTooltip(payrollEvent.ClientG2NReportSubmittedAt || softGPG2NUploadDate),
        actionLabel: 'Uploaded',
        actionLabelSubtext: payrollEvent.ClientG2NReportSubmittedBy || '',
        buttonLabel: payrollEvent.ClientG2NReportSubmittedBy ? '' : 'Upload G2N',
        permission: 'global_payroll.upload_g2n',
      };

    default:
      return defaultEventData;
  }
};

const getFinalLabelByStateAndDueDate = (
  state: EXTENDED_PAYROLL_EVENT_STATE,
  dueDate: string | Date,
  suffix: string
): string | any => {
  const isOverdue = getIsOverdue(dueDate, new Date());
  const withSuffix = `${isOverdue ? 'Overdue' : 'Awaiting'} ${suffix}`;

  const labels = {
    [PAYROLL_EVENT_STATE.NEW]: withSuffix,
    [PAYROLL_EVENT_STATE.REPORT_PROCESSING]: 'Processing Payroll',
    [PAYROLL_EVENT_STATE.REPORT_SUBMITTED]: 'Processing Payroll',
    [PAYROLL_EVENT_STATE.PENDING_REVIEW]: 'Processing Payroll',
    [PAYROLL_EVENT_STATE.PENDING_PACKAGE_APPROVAL]: withSuffix,
    [PAYROLL_EVENT_STATE.AWAITING_SELF_PAYMENT]: withSuffix,
    [PAYROLL_EVENT_STATE.AWAITING_GP_PAYMENT]: withSuffix,
    [PAYROLL_EVENT_STATE.COMPLETE]: '',

    [AWAITING_FUNDING_PAYROLL_EVENT_STATUS.AWAITING_FUNDING_PAYMENT]: withSuffix,
    [AWAITING_FUNDING_PAYROLL_EVENT_STATUS.AWAITING_DEPOSIT_PAYMENT]: withSuffix,
    [AWAITING_FUNDING_PAYROLL_EVENT_STATUS.DEPOSIT_PAYMENT_FAILED]: '',
    [AWAITING_FUNDING_PAYROLL_EVENT_STATUS.FUNDING_PAYMENT_FAILED]: '',
    [AWAITING_FUNDING_PAYROLL_EVENT_STATUS.PROCESSING_DEPOSIT_PAYMENT]: '',
    [AWAITING_FUNDING_PAYROLL_EVENT_STATUS.PROCESSING_FUNDING_PAYMENT]: '',

    [PAYROLL_EVENT_STATE.PROCESSED_BY_EXTERNAL_PROVIDER]: '',
  };

  return labels[state];
};

export const getEventDateByState = ({
  eventState,
  embeddedPayrollEnabled = false,
  depositStatus = null,
  fundingStatus = null,
  isSoftGPEnabled,
  fullEventData = null,
  isParallelRun = false,
}: {
  eventState: PAYROLL_EVENT_STATE;
  embeddedPayrollEnabled?: boolean;
  depositStatus?: PAYROLL_DEPOSIT_STATUS | null;
  fundingStatus?: PAYROLL_FUNDING_STATUS | null;
  isSoftGPEnabled?: boolean;
  fullEventData?: PayrollEvent | null;
  isParallelRun?: boolean;
}): EventData => {
  const eventExtendedStatus = isSoftGPEnabled
    ? PAYROLL_EVENT_STATE.PROCESSED_BY_EXTERNAL_PROVIDER
    : getPayrollEventExtendedStatus({
        eventState,
        depositStatus,
        fundingStatus,
        isParallelRun,
        isPeo: fullEventData?.payrollSettings.experienceType === 'PEO',
      });

  function getDueDateSubtext(date?: string | null) {
    if (!date) return '';

    const isLessThan24HoursLeft = getIsLessThan24HoursLeft(date);
    const dueDate = getDueDateInTimezone({ date, addPrefix: false, embeddedPayrollEnabled });

    return getIsOverdue(date) ? getDaysOverdueText(date) : isLessThan24HoursLeft ? dueDate : `Due ${dueDate}`;
  }

  const paymentsTracker = fullEventData?.paymentsTracker;

  const calendarDate = getUTCEndOfDayFromUTCString(fullEventData?.payrollCalendar?.payrollSubmissionDate || '');
  const lockDate = calendarDate || (fullEventData?.lock && new Date(fullEventData?.lock)) || null;

  const eventData: Record<EXTENDED_PAYROLL_EVENT_STATE, EventData> = {
    [PAYROLL_EVENT_STATE.NEW]: {
      dateProp: 'lock',
      getLabelSubtext: getDueDateSubtext,
      getAlertType: getDueDateAlertType,
      label: embeddedPayrollEnabled
        ? lockDate
          ? getFinalLabelByStateAndDueDate(PAYROLL_EVENT_STATE.NEW, lockDate, 'Approval')
          : 'Awaiting Approval'
        : lockDate
        ? getFinalLabelByStateAndDueDate(PAYROLL_EVENT_STATE.NEW, lockDate, !isSoftGPEnabled ? 'Report' : 'Upload G2n')
        : `Awaiting ${!isSoftGPEnabled ? 'Report' : 'G2N upload'}`,
      group: embeddedPayrollEnabled
        ? PAYROLL_EVENT_LIST_STATE.PAYROLL_APPROVAL
        : PAYROLL_EVENT_LIST_STATE.PAYROLL_SUBMISSION,
    },
    [PAYROLL_EVENT_STATE.REPORT_PROCESSING]: {
      getAlertType: () => 'grey',
      label: 'Processing Payroll',
      group: PAYROLL_EVENT_LIST_STATE.PAYROLL_PROCESSING,
    },
    [PAYROLL_EVENT_STATE.REPORT_SUBMITTED]: {
      getAlertType: () => 'grey',
      label: 'Processing Payroll',
      group: PAYROLL_EVENT_LIST_STATE.PAYROLL_PROCESSING,
    },
    [PAYROLL_EVENT_STATE.PENDING_REVIEW]: {
      getAlertType: () => 'grey',
      label: 'Processing Payroll',
      group: PAYROLL_EVENT_LIST_STATE.PAYROLL_PROCESSING,
    },
    [PAYROLL_EVENT_STATE.PENDING_PACKAGE_APPROVAL]: {
      dateProp: 'paymentDueDate',
      getAlertType: getDueDateAlertType,
      label: fullEventData?.paymentDueDate
        ? getFinalLabelByStateAndDueDate(
            PAYROLL_EVENT_STATE.PENDING_PACKAGE_APPROVAL,
            fullEventData.paymentDueDate,
            'Approval'
          )
        : 'Awaiting Approval',
      group: PAYROLL_EVENT_LIST_STATE.PAYROLL_APPROVAL,
    },

    [PAYROLL_EVENT_STATE.AWAITING_SELF_PAYMENT]: {
      label: (() => {
        if (fullEventData?.type === PayrollEventTypes.PARALLEL) return 'Awaiting Penny Test';
        if (!paymentsTracker?.isLive) return 'Awaiting funding';

        return !paymentsTracker.dates.payslipPublication ? 'Awaiting payslips publication' : 'Awaiting payouts';
      })(),
      getLabelSubtext: getDueDateSubtext,
      getAlertType: () => (paymentsTracker?.isLive && paymentsTracker.dates.payslipPublication ? 'warning' : 'grey'),
      dateProp: 'paymentDueDate',
      group: PAYROLL_EVENT_LIST_STATE.FUNDING_PAYMENT,
    },
    [AWAITING_FUNDING_PAYROLL_EVENT_STATUS.AWAITING_DEPOSIT_PAYMENT]: {
      getAlertType: () => 'danger',
      label: 'Unpaid Deposit',
      getLabelSubtext: () => 'Contact us for next steps',
      group: PAYROLL_EVENT_LIST_STATE.FUNDING_PAYMENT,
    },
    [AWAITING_FUNDING_PAYROLL_EVENT_STATUS.DEPOSIT_PAYMENT_FAILED]: {
      getAlertType: () => 'danger',
      label: 'Unpaid Deposit',
      getLabelSubtext: () => 'Contact us for next steps',
      group: PAYROLL_EVENT_LIST_STATE.FUNDING_PAYMENT,
    },
    [AWAITING_FUNDING_PAYROLL_EVENT_STATUS.PROCESSING_DEPOSIT_PAYMENT]: {
      getAlertType: () => 'warning',
      label: 'Processing Funding',
      getLabelSubtext: () => 'Deel will contact you for next steps',
      group: PAYROLL_EVENT_LIST_STATE.FUNDING_RECEIPT,
    },
    [AWAITING_FUNDING_PAYROLL_EVENT_STATUS.AWAITING_FUNDING_PAYMENT]: {
      dateProp: 'paymentDueDate',
      label: fullEventData?.paymentDueDate
        ? getFinalLabelByStateAndDueDate(
            AWAITING_FUNDING_PAYROLL_EVENT_STATUS.AWAITING_FUNDING_PAYMENT,
            fullEventData.paymentDueDate,
            'FUNDING'
          )
        : 'Awaiting FUNDING',
      getLabelSubtext: getDueDateSubtext,
      getAlertType: getDueDateAlertType,
      group: PAYROLL_EVENT_LIST_STATE.FUNDING_PAYMENT,
    },
    [AWAITING_FUNDING_PAYROLL_EVENT_STATUS.PROCESSING_FUNDING_PAYMENT]: {
      getAlertType: () => 'grey',
      label: (() => {
        if (paymentsTracker?.isLive && fundingStatus === PAYROLL_FUNDING_STATUS.COMPLETE) {
          return !paymentsTracker.dates.payslipPublication ? 'Awaiting payslips publication' : 'Awaiting payouts';
        }

        return isParallelRun ? 'Processing Payment' : 'Processing Funding';
      })(),
      group: PAYROLL_EVENT_LIST_STATE.FUNDING_RECEIPT,
    },
    [AWAITING_FUNDING_PAYROLL_EVENT_STATUS.FUNDING_PAYMENT_FAILED]: {
      getLabelSubtext: () => 'Retry payment to finalize cycle',
      getAlertType: () => 'danger',
      label: 'Funding Failed',
      group: PAYROLL_EVENT_LIST_STATE.FUNDING_PAYMENT,
    },
    [PAYROLL_EVENT_STATE.COMPLETE]: {
      dateProp: 'PaymentSubmittedAt',
      getLabelSubtext: (date) => `On ${formatDate.shortMonthDay(date)}`,
      getAlertType: () => 'success',
      label: 'Done',
      group: PAYROLL_EVENT_LIST_STATE.PAYMENT_DONE,
    },
    [PAYROLL_EVENT_STATE.PROCESSED_BY_EXTERNAL_PROVIDER]: {
      dateProp: 'ClientG2NReportSubmittedAt',
      label: '',
      getLabelSubtext: (date) => `On ${formatDate.shortMonthDay(date)}`,
      getAlertType: () => 'success',
      getLabel: ({ payrollEvent } = {}) =>
        payrollEvent?.ClientG2NReportSubmittedAt ? 'Uploaded' : 'Awaiting G2N Upload',
      group: PAYROLL_EVENT_LIST_STATE.PROCESSED_BY_EXTERNAL_PROVIDER,
    },
  };

  return eventExtendedStatus
    ? eventData[eventExtendedStatus]
    : { label: 'Unknown Status', getAlertType: () => 'danger', group: PAYROLL_EVENT_LIST_STATE.PAYROLL_SUBMISSION };
};

/**
 * Function to get the end of the day in UTC time from a given UTC string.
 * Used to get the correct of hour of time sensitive payroll steps.
 * @param utcString
 * @returns
 */
export const getUTCEndOfDayFromUTCString = (utcString: string | Date) => {
  if (typeof utcString !== 'string' || !utcString.includes('T')) return utcString;

  return endOfDay(utcToZonedTime(utcString, 'UTC'));
};

const getReceiptLink = (invoice: {
  id: number;
  rootInvoiceId: number | null;
  rootInvoicePublicId: string | null;
}): string => `/receipt/${invoice.rootInvoicePublicId || invoice.rootInvoiceId}`;

const getInvoiceLink = (invoice: { invoiceId: string; id: number; rootInvoiceId: number | null }): string =>
  `/invoice/${invoice.invoiceId || invoice.id}`;

export const getStatementLinkByFundingState: Record<
  PAYROLL_FUNDING_STATUS,
  (invoice: {
    invoiceId: string;
    id: number;
    rootInvoiceId: number | null;
    rootInvoicePublicId: string | null;
  }) => string
> = {
  [PAYROLL_FUNDING_STATUS.AWAITING_DEPOSIT]: getInvoiceLink,
  [PAYROLL_FUNDING_STATUS.READY]: getInvoiceLink,
  [PAYROLL_FUNDING_STATUS.PROCESSING]: getReceiptLink,
  [PAYROLL_FUNDING_STATUS.FAILED]: getInvoiceLink,
  [PAYROLL_FUNDING_STATUS.COMPLETE]: getReceiptLink,
};

export const getEventsForVarianceComparison = (
  activePayrollEvent: PayrollEvent | null,
  entityPayrollEvent: PayrollEvent[],
  avoidStatuses: PAYROLL_EVENT_STATE[]
) => {
  if (!activePayrollEvent) {
    return [];
  }

  // Assuming 'activePayrollEvent.start' and 'item.start' are in ISO string format or Date objects
  const activeEventStartDate = createDate(activePayrollEvent.start);

  return entityPayrollEvent.filter((item) => {
    const itemStartDate = createDate(item.start);
    return (
      item.id !== activePayrollEvent.id &&
      isBefore(itemStartDate, activeEventStartDate) &&
      !avoidStatuses.includes(item.actionState)
    );
  });
};

export const getAlertTypeColor = (alertType: AlertType) => {
  switch (alertType) {
    case 'danger':
    case 'error':
      return 'error.dark';
    case 'success':
      return 'success.main';
    case 'grey':
    case 'warning':
      return 'text.disabled';
    default:
      return '';
  }
};

export function numberAbbreviation(num: number, precision = 2) {
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
    { suffix: '', threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);

  return found ? (num / found.threshold).toFixed(precision) + found.suffix : num;
}

export const getEmploymentSalaryData = ({
  activeSalaryData,
  activeTerm,
}: Pick<Employment, 'activeSalaryData' | 'activeTerm'>) => {
  const scale = activeSalaryData?.scale || activeTerm?.scale;
  const rate = activeSalaryData?.rate || activeTerm?.rate;
  const payCurrency = activeSalaryData?.payCurrency || activeTerm?.payCurrency;

  return { scale, rate, payCurrency };
};

export const sumExpensesAmount = (data: AdjustmentSummaryResponse | undefined) => {
  let total = 0;

  for (const key in data) {
    total += parseFloat(data[key].totalAmount);
  }

  return total;
};
