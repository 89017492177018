import { lazy, Suspense } from 'react';

import { Button, H4, Icon, Link, P, Stack } from '@letsdeel/ui';
import { useEmailVerification } from '@/scenes/Login/components/EmailVerification/useEmailVerification';
import { NativeContainer } from '@/native/NativeContainer';
import mailAnimation from '@/assets/animations/mail_animation.json';
import Recaptcha from '@/components/Recaptcha/Recaptcha';
import type { EmailVerificationProps } from './types';
import { useTranslation } from 'react-i18next';
import { Edges } from '@/types/Edges';
import ErrorBoundary, { ErrorBoundaryOwners } from '@/components/ErrorBoundary';

const Lottie = lazy(() => import('react-lottie-player'));

export const NativeEmailVerification = ({
  goBackAction,
  resend,
  mainTitle,
  subTitle,
  enableRecaptcha,
  hasBackupEmail,
  setShowAlternativeOptions,
  setIsBackupEmailReset,
}: EmailVerificationProps) => {
  const { t } = useTranslation();

  const { onRecaptcha, isResending, clearAndGoBack, confirmationWasSent, resendActionTimer } = useEmailVerification({
    resend,
    setIsBackupEmailReset,
    goBackAction,
  });

  const footer = {
    infoText: hasBackupEmail
      ? t('authentication.emailVerification.footer.textForBackupEmail')
      : t('authentication.emailVerification.footer.text'),
    linkText: hasBackupEmail
      ? t('authentication.emailVerification.footer.linkTextForBackupEmail')
      : t('authentication.emailVerification.footer.linkText'),
    linkProps: hasBackupEmail
      ? {
          onClick: setShowAlternativeOptions,
        }
      : {
          href: 'https://help.letsdeel.com/hc/en-gb/requests/new?ticket_form_id=360001676798',
          target: '_blank',
          rel: 'noreferrer',
        },
  };

  return (
    <NativeContainer edges={[Edges.TOP, Edges.BOTTOM]} sx={{ backgroundColor: 'background.paper' }}>
      <Stack px={2} sx={{ flex: 1 }}>
        <Icon.BackArrow onClick={clearAndGoBack} size={24} />
        <Stack sx={{ flex: 1, justifyContent: 'flex-end' }} pb={1}>
          <ErrorBoundary owner={ErrorBoundaryOwners.Mobile} fallback={null}>
            <Suspense fallback={null}>
              <Lottie
                animationData={mailAnimation}
                loop={false}
                play
                style={{ width: '200px', height: '200px', alignSelf: 'center' }}
              />
            </Suspense>
          </ErrorBoundary>

          <P
            mt={3}
            mb={2}
            sx={{
              fontFamily: 'Bagoss Condensed',
              fontWeight: 500,
              fontSize: '28px',
              lineHeight: '40px',
              letterSpacing: '-0.015em',
              textAlign: 'center',
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
            }}
          >
            {mainTitle}
          </P>
          <P
            mb={17}
            sx={{
              color: 'text.secondary',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '22.4px',
              letterSpacing: '-0.005em',
              textAlign: 'center',
            }}
          >
            {subTitle}
          </P>
          {enableRecaptcha ? <Recaptcha onRecaptcha={onRecaptcha} onError={console.error} /> : null}
          <Button
            loading={isResending}
            disabled={confirmationWasSent || isResending}
            onClick={() => resendActionTimer()}
          >
            {t('authentication.emailVerification.resend')}
          </Button>
          <H4 sx={{ fontWeight: 400, textAlign: 'center' }} mt={2}>
            {footer.infoText} <Link {...footer.linkProps}>{footer.linkText}</Link>
          </H4>
        </Stack>
      </Stack>
    </NativeContainer>
  );
};
