import { type PropsWithChildren } from 'react';
import { Stack, type SxProps, type Theme } from '@letsdeel/ui';
import { type Edges } from '@/types/Edges';
import { isNative } from '@/utils/crossPlatform';
import { useSafeAreaClasses } from '@/hooks/useSafeAreaClasses';

type Props = {
  edges?: Edges[];
  className?: string;
  sx?: SxProps<Theme>;
};
export const NativeContainer = ({ children, edges, className, sx }: PropsWithChildren<Props>) => {
  const safeAreaClasses = useSafeAreaClasses(edges);

  if (!isNative()) {
    return <>{children}</>;
  }

  return (
    <Stack
      className={`${safeAreaClasses} ${className}`}
      sx={{
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        display: 'flex',
        height: `calc(100vh - 16px)`, // 16px is coming from Modal aka DialogContainer from deel/ui
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
};
