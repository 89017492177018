import i18n from 'i18next';
import { Trans } from 'react-i18next';
import type { ModalRef } from '@letsdeel/ui';
import { Button, Link, Modal, Stack, useFeedbackPopup, useModal, H6 } from '@letsdeel/ui';
import React, { forwardRef, useState } from 'react';
import ConfirmPhoneNumber from '@/components/ResetAuthenticator/ConfirmPhoneNumber';
import useUserStore from '@/hooks/useUserStore';
import type { FormikHelpers } from 'formik';
import { useFormik } from 'formik';
import ChoosePhoneNumber from '@/components/ResetAuthenticator/ChoosePhoneNumber';
import * as Yup from 'yup';
import OtpPopup from '@/components/OtpPopup';
import TFASetupPopup from '@/scenes/Settings/scenes/SecuritySettings/components/TFASetupPopup/TFASetupPopup';
import accountApi from '@/utils/api/account';
import type { OtpPopupContentProps } from '@/components/OtpPopupContent/OtpPopupContent';
import useSupportLinkProps from '@/components/OtpPopupContent/useSupportLinkProps';

interface Props {
  onResetSuccess?: OtpPopupContentProps['onSubmit'];
  noSubmitAfterSmsReset?: boolean;
  partialLoginToken?: string;
  onHide?: () => void;
  afterSmsVerifySuccess?: ({ totpValidatedToken }: { totpValidatedToken: string }) => void;
}

enum ResetMode {
  Unauthorized,
  Authorized,
}

const SUBTITLE = {
  [ResetMode.Unauthorized]: i18n.t('temp.unknown.components.ResetAuthenticator.e9f120e148'),
  [ResetMode.Authorized]: undefined,
};

const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string().min(4).required(i18n.t('temp.unknown.components.ResetAuthenticator.cf70ae2901')),
});
const ResetAuthenticator = forwardRef<ModalRef, Props>(
  ({ afterSmsVerifySuccess, noSubmitAfterSmsReset, onHide, onResetSuccess, partialLoginToken }, ref) => {
    const user = useUserStore();
    const mode: ResetMode = user.isLoggedIn ? ResetMode.Authorized : ResetMode.Unauthorized;

    const [showOtpPopup, setShowOtpPopup] = useState(false);
    const [isSetupPopupOpen, setIsSetupPopupOpen] = useState(false);
    const [totpValidatedToken, setTotpValidatedToken] = useState('');

    const [phoneNumber, setPhoneNumber] = useState('');
    const { openFeedbackPopup, closeFeedbackPopup } = useFeedbackPopup();
    const { closeAllModals } = useModal();

    const onSmsVerifySuccess = () => {
      openFeedbackPopup({
        title: i18n.t('temp.unknown.components.ResetAuthenticator.7cf25bc6d6').toString(),
        text: i18n.t('temp.unknown.components.ResetAuthenticator.3590693f2d').toString(),
        variant: 'success',
        button: {
          onClick: () => {
            closeFeedbackPopup();
            setShowOtpPopup(false);
            setIsSetupPopupOpen(true);
          },
          children: i18n.t('temp.unknown.components.ResetAuthenticator.458457a4bc').toString(),
        },
      });
    };

    const requestCode = async (phoneNumber: string, setSubmitting: FormikHelpers<any>['setSubmitting']) => {
      try {
        setSubmitting(true);
        await accountApi.sendOtp({
          otpProvider: 'sms',
          phoneNumberLastDigits: phoneNumber?.replace(/\D/g, ''),
          partialLoginToken,
        });
        setSubmitting(false);
        setShowOtpPopup(true);
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
      }
    };

    const formik = useFormik({
      initialValues: {
        phoneNumber: '',
      },
      onSubmit: async (values, { setSubmitting }) => {
        await setPhoneNumber(values.phoneNumber);
        if (mode === ResetMode.Unauthorized) {
          await requestCode(values.phoneNumber, setSubmitting);
        } else {
          setSubmitting(false);
          setShowOtpPopup(true);
        }
      },
      isInitialValid: false,
      validationSchema,
    });

    const onSmsPopupClose = () => {
      closeAllModals();
      onHide?.();
    };

    const handleVerify = async (code: string) => {
      // This shouldn't be in try block as handling errors is in OtpPopupContent
      const response = await accountApi.validateOtp({ code, otpProvider: 'sms', partialLoginToken });
      if (response?.data?.totpValidatedToken) {
        setTotpValidatedToken(response?.data?.totpValidatedToken);
        if (afterSmsVerifySuccess) {
          afterSmsVerifySuccess({ totpValidatedToken: response?.data?.totpValidatedToken });
        } else onSmsVerifySuccess();
      } else setTotpValidatedToken('');
      return response;
    };

    const supportLinkProps = useSupportLinkProps();

    return (
      <>
        <Modal
          ref={ref}
          title={i18n.t('temp.unknown.components.ResetAuthenticator.0109c396e8').toString()}
          subtitle={SUBTITLE[mode]}
          onClose={onSmsPopupClose}
          actions={
            <Stack sx={{ width: '100%' }} spacing={2} alignItems={'center'} justifyContent={'stretch'}>
              <Button onClick={formik.submitForm} fullWidth disabled={!formik.isValid} loading={formik.isSubmitting}>
                {mode === ResetMode.Unauthorized
                  ? i18n.t('temp.unknown.components.ResetAuthenticator.70d9be9b13')
                  : i18n.t('temp.unknown.components.ResetAuthenticator.8dab622763')}
              </Button>
              <H6 regular>
                <Trans
                  i18nKey="temp.unknown.components.ResetAuthenticator.41aaf9df20"
                  components={[<Link {...supportLinkProps} key="c08c272bc5_0" />]}
                />
              </H6>
            </Stack>
          }
        >
          {mode === ResetMode.Unauthorized ? (
            <ConfirmPhoneNumber
              getFieldProps={formik.getFieldProps}
              setFieldValue={formik.setFieldValue}
              setFieldTouched={formik.setFieldTouched}
            />
          ) : (
            <ChoosePhoneNumber
              getFieldProps={formik.getFieldProps}
              setFieldValue={formik.setFieldValue}
              setFieldTouched={formik.setFieldTouched}
            />
          )}
        </Modal>
        <TFASetupPopup
          show={isSetupPopupOpen}
          totpValidatedToken={totpValidatedToken}
          onHide={() => {
            setIsSetupPopupOpen(false);
            onSmsPopupClose();
          }}
          onResetSuccess={onResetSuccess}
          partialLoginToken={partialLoginToken}
          noSubmitAfterSmsReset={noSubmitAfterSmsReset}
        />
        <OtpPopup
          sendCodeOnMount={mode === ResetMode.Authorized}
          onSubmit={handleVerify}
          show={showOtpPopup}
          onHide={onSmsPopupClose}
          enforceSms
          phoneNumber={phoneNumber}
          partialLoginToken={partialLoginToken}
        />
      </>
    );
  }
);

export default ResetAuthenticator;
