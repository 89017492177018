export const ID_FIELD = 'id';

export enum STEP {
  ONE = 'Confirm',
  TWO = 'Final Step',
}

export enum ClientDeleteAccountEvents {
  EVENT_CATEGORY = 'Platform',
  EVENT_PRODUCT = 'Contractor Delete Account',
  DELETE_ACCOUNT_CLICK = 'Click on Delete Account',
  DELETE_ACCOUNT_VIEW_REASONS_LIST = 'View Unselected Reasons',
  DELETE_ACCOUNT_CONTINUE = 'Click on Continue Delete Account Reasons',
  DELETE_ACCOUNT_CONFIRMATION = 'Click on Delete Account Confirmation',
  DELETE_ACCOUNT_SUCCESS = 'Click on Ok',
  DELETE_ACCOUNT_OTP = 'View Delete Account OTP Verification',
  CANNOT_DELETE_ACCOUNT_VALIDATION = 'View Cannot Delete Validation',
  DELETE_ACCOUNT_REQUEST_SUPPORT_CLICK = 'Click on Request Support',
  DELETE_ACCOUNT_REQUEST_SUPPORT_VIEW = 'View Cannot Delete Account Request support',
  DELETE_ACCOUNT_LEARN_MORE = 'Click on Learn More',
}

export const REASON_OPTIONS = [
  {
    label: 'I no longer need your service',
    id: 'noServiceNeeded',
  },
  {
    label: 'I want to use this email in another account',
    id: 'useEmailInAnotherAccount',
  },
  {
    label: 'I did not register correctly',
    id: 'wrongRegistraction',
  },
  {
    label: `I'm not satisfied with the service`,
    id: 'noSatisfied',
  },
  {
    label: 'I switched to a different platform',
    id: 'switchedToAnotherPlatfrom',
  },
  {
    label: 'Other (Please specify)',
    id: 'other',
  },
];

export const cantDeleteAccountReasons = [
  'Make sure there are no active contracts and all payments are settled.',
  'Finish any ongoing withdrawal processes.',
  'Verify your Deel Card and account have zero balance.',
  'Look for any pending Deel Advance repayments.',
];

export const afterDeleteAccountList = [
  'Deleting your contractor account is irreversible.',
  'Only your contractor account will be deleted. Any other profiles linked to your email will not be removed; these require individual deletion requests (e.g client or employee).',
  'You’ll no longer have access to Deel’s features and services.',
];
