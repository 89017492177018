import { createContext, useCallback, useEffect, useState } from 'react';
import { useHeader } from '@letsdeel/ui';
import type { HeaderContextData } from '@letsdeel/ui/dist/providers/HeaderProvider/HeaderProvider';
import type { PropsWithChildren } from 'react';
import { Keyboard } from '@capacitor/keyboard';
import { isNative } from '@/utils/crossPlatform';
import { useThemeContext } from '../ThemeContext/ThemeContext';

interface BottomNavigationState {
  bottomNavShown: boolean;
  setBottomNavShown: (state: boolean) => void;
  backButtonProps: HeaderContextData['nativeBackButton'];
  isKeyboardVisible: boolean;
  titleOverride?: {
    title: string;
  };
  setKeyboardVisible: (state: boolean) => void;
}

const initalBottomNavigationState: BottomNavigationState = {
  bottomNavShown: true,
  setBottomNavShown: () => {},
  backButtonProps: {},
  isKeyboardVisible: false,
  titleOverride: undefined,
  setKeyboardVisible: () => {},
};

export const BottomNavigationContext = createContext(initalBottomNavigationState);

const BottomNavigationProvider = ({ children }: PropsWithChildren) => {
  const [bottomNavShown, setBottomNavShown] = useState<boolean>(true);
  const {
    bottomNavigationState: { setBottomNavShown: setThemeBottomNavShown },
  } = useThemeContext();
  const { nativeBackButton: backButtonProps, nativeTitleOverride: titleOverride } = useHeader();

  const [isKeyboardVisible, setKeyboardVisible] = useState<boolean>(false);
  const handleKeyboardWillShow = useCallback(() => setKeyboardVisible(true), []);
  const handleKeyboardWillHide = useCallback(() => setKeyboardVisible(false), []);

  useEffect(() => {
    if (isNative()) {
      Keyboard.addListener('keyboardWillShow', handleKeyboardWillShow);
      Keyboard.addListener('keyboardWillHide', handleKeyboardWillHide);

      return () => {
        Keyboard.removeAllListeners();
      };
    }
  }, [handleKeyboardWillShow, handleKeyboardWillHide]);

  useEffect(() => {
    setThemeBottomNavShown(bottomNavShown);
  }, [bottomNavShown, setThemeBottomNavShown]);

  return (
    <BottomNavigationContext.Provider
      value={{
        bottomNavShown,
        setBottomNavShown,
        backButtonProps,
        titleOverride,
        isKeyboardVisible,
        setKeyboardVisible,
      }}
    >
      {children}
    </BottomNavigationContext.Provider>
  );
};

export default BottomNavigationProvider;
