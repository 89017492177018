import OpenReplay from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import * as Sentry from '@sentry/react';
import { envShortKey } from '@/utils/environment';

interface IUserTraits {
  name: string;
  email: string;
}

type TrackEventProperties = {
  revenue?: number;
  [key: string]: string | number | boolean | string[] | number[] | boolean[] | undefined;
};

let openReplaySessionID: string;

let tracker: OpenReplay;
const getSessionID = () => openReplaySessionID;

const getProjectKey = (isDeeler: boolean): string => {
  const overrideKey = localStorage.getItem('openReplayProjectKey');
  if (typeof overrideKey === 'string' && overrideKey) return overrideKey;
  if (envShortKey === 'prod') return isDeeler ? '1N5Q3qXRkBr3mXSlNwj3' : '3AJc63x4yDtP2iazpygW';
  return envShortKey === 'demo' ? '3vDf5EBihniRwAIi7fkR' : isDeeler ? 'cu8hkPy03mUuAOWVQYJp' : 'Z4CfE0n8TyxhHTiurbBV';
};

const init = ({ isDeeler }: { isDeeler: boolean }) => {
  if (tracker?.isActive()) return;
  tracker = new OpenReplay({
    projectKey: getProjectKey(isDeeler),
    __DISABLE_SECURE_MODE: location.hostname === 'localhost',
    ingestPoint: 'https://openreplay-new.deel.tools/ingest',
    captureIFrames: false,
    disableStringDict: true,
    network: {
      captureInIframes: false,
      sessionTokenHeader: false,
      capturePayload: true,
      failuresOnly: false,
      ignoreHeaders: false,
      sanitizer: (data) => {
        if (data.request.headers['x-auth-token']) {
          data.request.headers['x-auth-token'] = 'SANITISED';
        }

        return data;
      },
    },
    onStart: ({ sessionToken }) => {
      Sentry.setTag('openReplaySessionToken', sessionToken);

      Sentry.setTag('openReplaySessionURL', tracker.getSessionURL());
    },
  });

  tracker.use(trackerAssist());

  tracker
    .start()
    .then(({ sessionID }: any) => (openReplaySessionID = sessionID))
    .catch(() => {});
};

const identify = async (_uid: string, traits: IUserTraits) => {
  if (!tracker) return;
  tracker.setUserID(traits.email);
};

const track = async (eventName: string, eventProperties?: TrackEventProperties) => {
  if (!tracker) return;
  tracker.event(eventName, eventProperties);
};

const setMetadata = async (name: string, value: string) => {
  if (!tracker) return;
  tracker.setMetadata(name, value);
};

export default {
  init,
  identify,
  track,
  getSessionID,
  setMetadata,
};
