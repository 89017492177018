import { getIsBiometricAppPinSaved } from '@/native/AppPin/appPin';
import { getBiometricCredentials, verifyBiometrics } from '@/utils/biometric';
import { showAlert } from '@/utils/nativeDialog';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function useInitVerifyPin(
  setInitialValues: (values: string[]) => void,
  remainingAttempts: number,
  closeModal: (token: string | undefined) => void
) {
  const { t } = useTranslation();

  const checkBiometricCredentials = useCallback(async () => {
    const hasBiometricData = await getIsBiometricAppPinSaved();
    if (!hasBiometricData) {
      return;
    }

    const isBiometricsVerified = await verifyBiometrics();
    if (!isBiometricsVerified) return;

    const biometricCredentials = await getBiometricCredentials();

    if (biometricCredentials && biometricCredentials.password && biometricCredentials.password.length === 4) {
      setInitialValues(biometricCredentials.password.split(''));
    }
  }, [setInitialValues]);

  useEffect(() => {
    checkBiometricCredentials();
  }, [checkBiometricCredentials]);

  const handleClearAppPin = useCallback(async () => {
    await showAlert(t('authentication.nativeAppPin.request.reachedMaxAttempts'));
    await closeModal(undefined);
  }, [t, closeModal]);

  useEffect(() => {
    if (remainingAttempts === 0) {
      handleClearAppPin();
    }
  }, [remainingAttempts, handleClearAppPin]);
}
