import { Grid, Icon, useMediaQuery } from '@letsdeel/ui';
import { LoginPromoHeader } from '@/scenes/Login/components/LoginPromoHeader';
import { LoginPromoText } from '@/scenes/Login/components/LoginPromoText';
import './login-text.less';
import type { MouseEvent } from 'react';
import { useCallback } from 'react';

interface PropTypes {
  title: string;
  subtitle?: string;
  position: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  };
  transform?: string;
  cta?: { text: string; url: string };
  onCtaClick?: (url: string) => void;
}

export const LoginText = ({ title, subtitle, position, transform, cta, onCtaClick }: PropTypes) => {
  const { top, bottom, right, left } = position;
  const is4k = useMediaQuery('(min-width: 2560px)');

  const handleCtaClick = useCallback(() => {
    if (onCtaClick && cta) onCtaClick(cta.url);
  }, [onCtaClick, cta]);

  return (
    <Grid
      className="login-promo-text-wrapper"
      position="fixed"
      top={top}
      left={left}
      bottom={bottom}
      right={right}
      sx={{ transform }}
    >
      <LoginPromoHeader>{title}</LoginPromoHeader>
      {subtitle ? <LoginPromoText>{subtitle}</LoginPromoText> : null}
      {cta ? <Grid item container alignItems="center" mt="16px">
          <a
            className="login-promo-text-cta"
            href={cta.url}
            target="_blank"
            rel="noreferrer"
            onClick={handleCtaClick}
            // GRO-541 Prevent link active state on user click
            onMouseDown={(event: MouseEvent<HTMLAnchorElement>) => {
              event.preventDefault();
            }}
          >
            {cta.text}

            <Icon.NewTab size={is4k ? 48 : 24} color="white" className="ml-4" />
          </a>
        </Grid> : null}
    </Grid>
  );
};
