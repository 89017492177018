import type { CircularProgressProps} from '@letsdeel/ui';
import { CircularProgress, MuiBox } from '@letsdeel/ui';
import { useMemo } from 'react';

interface SpinnerProps extends Omit<CircularProgressProps, 'color'> {
  color?: string;
}

const sizeMapper = {
  xxs: 20,
  xs: 28,
  sm: 32,
  md: 38,
  lg: 42,
};

const Spinner = ({ size, color, ...rest }: SpinnerProps) => {
  const elementSize = useMemo(() => {
    if (size && typeof size === 'string' && ['xxs', 'xs', 'sm', 'md', 'lg'].includes(size)) {
      return sizeMapper[size as 'xxs' | 'xs' | 'sm' | 'md' | 'lg'];
    }
    return size;
  }, [size]);

  return (
    <MuiBox display="flex" flex={1} alignItems="center" justifyContent="center">
      <CircularProgress size={elementSize} sx={{ color: color || 'primary.main' }} {...rest} />
    </MuiBox>
  );
};

export default Spinner;
