import instance from '@/utils/api/instance';

export type QuickEmployeeDetails = {
  email: string;
  workEmail: string;
  firstName: string;
  lastName: string;
  nationality: string;
  country: string;
  state: string | undefined;
};

export type QuickEmployeeJobInformation = {
  jobTitleName: string;
};

export type QuickCreateEmployeeData = {
  compensation: {
    currency: string;
    grossSalary: number;
  };
  contract: {
    employeeNumber: string;
    employmentType: string;
    startDate: string;
  };
  employeeDetails: QuickEmployeeDetails;
  jobInformation: QuickEmployeeJobInformation;
  teamInformation: {
    legalEntityId: number;
    teamId: number;
  };
};

export type QuickCreateEmployeeResponse = {
  createdEmployees: QuickCreateEmployeeData[];
  failedEmployees: (QuickCreateEmployeeData & { errors: string[] })[];
};

export type MissingEmployee = {
  employeeNumber: string;
  errors: string[];
  firstName?: string;
  lastName?: string;
  name?: string;
};

export default {
  uploadClientG2NReportFile: async ({
    csv,
    payrollEventId,
    legalEntityId,
  }: {
    csv: File;
    payrollEventId: string;
    legalEntityId: string;
  }) => {
    const formData = new FormData();
    formData.append('csv', csv);
    formData.append('payrollEventId', payrollEventId.toString());
    formData.append('legalEntityId', legalEntityId);

    return instance.post('/global_payroll/soft_gp/report/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  createDeelG2N: async ({ payrollEventId }: { payrollEventId: string }) => {
    return instance.post(`/global_payroll/g2n_reports/generate/${payrollEventId}`);
  },

  createEmployeesOids: async ({ payrollEventId }: { payrollEventId: string }) => {
    return instance.post(`/global_payroll/soft_gp/employee/convert_number_to_oid`, { payrollEventId });
  },

  getG2NImportTemplate: async ({ legalEntityId, icpId }: { legalEntityId: string; icpId: number | undefined }) => {
    return instance.get(`/global_payroll/soft_gp/report/g2n_template?legalEntityId=${legalEntityId}&icpId=${icpId}`);
  },
};
