import type { HomeBackendFeatureFlags } from '@/constants/featureFlags/home';
import { useMemo } from 'react';
import { useFetchHomeTeamFeatureFlags } from '../api/home/useFetchHomeTeamFeatureFlags';

export function useHomeFeatureFlag(featureFlag: HomeBackendFeatureFlags): boolean {
  const { data, error } = useFetchHomeTeamFeatureFlags();

  const isFeatureEnabled = useMemo(() => (data ? data[featureFlag] : false), [data, featureFlag]);

  return error ? false : isFeatureEnabled;
}
