import i18n from 'i18next';
import { Trans } from 'react-i18next';
import Button from '@/components/Button';
import Flex from '@/components/Flex';
import { H1, H6, Icon, Illustration, TextField, VStack } from '@letsdeel/ui';
import type { FormikHelpers } from 'formik';
import { Field, Form, Formik } from 'formik';
import Footer from './Footer';
import { FORGOT_PASSWORD_VALIDATION_SCHEMA, FORGOT_PASSWORD_INITIAL_VALUES } from '../consts';
import { type ForgotPasswordInitialValues } from '../types';

interface Props {
  goBackAction?: () => void;
  token?: string;
  isLoading: boolean;
  customDomainHostName?: string;
  onSubmitForgotPassword: (
    values: ForgotPasswordInitialValues,
    options?: FormikHelpers<ForgotPasswordInitialValues>
  ) => void;
}

const TypeEmailForm = ({ goBackAction, isLoading, customDomainHostName, onSubmitForgotPassword }: Props) => {
  return (
    <Formik
      key="forgot-form"
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={FORGOT_PASSWORD_INITIAL_VALUES}
      validationSchema={FORGOT_PASSWORD_VALIDATION_SCHEMA}
      onSubmit={onSubmitForgotPassword}
    >
      {({ isSubmitting, errors }) => (
        <>
          <VStack spacing={16}>
            {goBackAction ? (
              <Button
                type="button"
                theme="link"
                disabled={isSubmitting || isLoading}
                onClick={goBackAction}
                className="text-decoration-none"
              >
                <Flex>
                  <Icon.ArrowNew className="mr-5" size={15} />
                  {i18n.t('temp.platform-fe.scenes.ResetPassword.0557fa923d').toString()}
                </Flex>
              </Button>
            ) : null}
            <H1 className="semi-bold text-center">
              {i18n.t('temp.platform-fe.scenes.ResetPassword.6832ac5936').toString()}
            </H1>
            {customDomainHostName ? (
              <H6 className="text-center">
                <Trans
                  i18nKey="temp.platform-fe.scenes.ResetPassword.6336ed957a"
                  components={[<span className="capitalize" key="60a112e6e5_0" />]}
                  values={{ v0: customDomainHostName }}
                />
              </H6>
            ) : null}
          </VStack>

          <Flex className="justify-content-center flex-column align-items-center">
            <Illustration illustration="email" />
          </Flex>

          <Form>
            <VStack spacing={24}>
              <Field
                label={i18n.t('temp.platform-fe.scenes.ResetPassword.b357b524e7').toString()}
                name="email"
                error={!!errors.email}
                helperText={errors.email}
                as={TextField}
              />

              <Button type="submit" full size="lg" loading={isSubmitting || isLoading}>
                RESET PASSWORD
              </Button>

              <Footer />
            </VStack>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default TypeEmailForm;
