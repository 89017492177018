import type { ComponentProps, MouseEvent} from 'react';
import { useMemo } from 'react';

import { cn } from '@/utils/main';

import ICONS from '@/constants/icons';

import Button from '@/components/Button';
import Flex from '@/components/Flex';
import type { AllowCustomString } from '@/types/helpers';

import './icon.less';

export type SizeType = 'auto' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
type ThemeType = 'light' | 'icon';
type Direction = 'right' | 'left' | 'down';
type ButtonType = 'button' | 'reset' | 'submit';

export type IconType = keyof typeof ICONS;

interface Props {
  type: AllowCustomString<IconType>;
  size?: SizeType;
  sizeValue?: number;
  theme?: ThemeType | ComponentProps<typeof Button>['theme'];
  color?: string;
  direction?: Direction;

  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  href?: string;
  to?: string;
  buttonType?: ButtonType;
  name?: string;
}

const Icon = ({
  type,
  size = 'md',
  sizeValue,
  color,
  onClick,
  className,
  href,
  to,
  buttonType = 'button',
  theme = 'icon',
  direction,
  ...rest
}: Props) => {
  const sizeStyle = useMemo(() => {
    if (!sizeValue) return;
    const val = `${sizeValue}px`;
    return {
      minHeight: val,
      maxWidth: val,
      minWidth: val,
      maxHeight: val,
    };
  }, [sizeValue]);

  return onClick || href || to ? (
    // @ts-ignore
    (<Button
      type={buttonType}
      containerClassName="w-100"
      onClick={onClick as (e: MouseEvent<HTMLButtonElement>) => void}
      to={to}
      href={href}
      theme={theme as any}
      className={className}
      {...rest}
    >
      <Flex
        className={cn('align-items-center justify-content-center w-100 icon', color ? `color-${color}` : '')}
        direction={direction}
      >
        {ICONS[type as IconType]}
      </Flex>
    </Button>)
  ) : (
    <div
      // @ts-ignore
      theme={theme}
      className={cn('icon unselectable', color ? `color-${color}` : '', className, onClick ? 'clickable' : null)}
      sizetype={sizeValue ? 'auto' : size}
      direction={direction}
      style={sizeStyle}
      {...rest}
    >
      {ICONS[type as IconType]}
    </div>
  );
};

export default Icon;
