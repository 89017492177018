import i18n from 'i18next';
import { Link, Stack, styled, H2, H4, H6 } from '@letsdeel/ui';

const StyledStep = styled(Stack)`
  padding-top: 16px;
  min-height: 400px;
`;

const TFASetupDownload = () => {
  return (
    <StyledStep direction="column" spacing={3}>
      <Stack direction="column" spacing={2}>
        <H2>{i18n.t('temp.platform-fe.scenes.Settings.835e30c7c6').toString()}</H2>
        <H4 regular color="neutral.darker">
          {i18n.t('temp.platform-fe.scenes.Settings.ifYouHaveAppAlready').toString()}
          <br />
          {i18n.t('temp.platform-fe.scenes.Settings.ifYouHaveNoApp').toString()}
        </H4>
      </Stack>
      <Link href="https://help.letsdeel.com/hc/en-gb/articles/14115157574417" newTab>
        <H6 regular color="primary.main" className="mr-2">
          {i18n.t('temp.platform-fe.scenes.Settings.38319189e9').toString()}
        </H6>
      </Link>
    </StyledStep>
  );
};

export default TFASetupDownload;
