import { registerPlugin } from '@capacitor/core';
import userApi from '@/utils/api/user';
import { IS_COOKIE_TOKEN } from '@/constants/main';
import * as Sentry from '@sentry/react';

export interface KeyPairPluginProps {
  generateKeyPair(options: { pinCode: string }): Promise<{ publicKey: string }>;
  retrieveKeys(options: { pinCode: string }): Promise<{ publicKey: string }>;
  deleteKeys(): Promise<void>;
  signChallenge(options: { pinCode: string; challenge: string }): Promise<{ signature: string }>;
}

class NativeSessionService {
  private static instance: NativeSessionService;
  private KeyPairPlugin: KeyPairPluginProps;

  private constructor() {
    this.KeyPairPlugin = registerPlugin<KeyPairPluginProps>('KeyPair');
  }

  public static getInstance(): NativeSessionService {
    if (!NativeSessionService.instance) {
      NativeSessionService.instance = new NativeSessionService();
    }
    return NativeSessionService.instance;
  }

  public async generateKeys(pinCode: string) {
    try {
      await this.deleteKeys();
      const { publicKey } = await this.KeyPairPlugin.generateKeyPair({ pinCode });
      return { publicKey };
    } catch (error) {
      console.error('[NativeSessionService] - Error generating keys:', error);
      throw error;
    }
  }
  public async getPublicKey(pinCode: string) {
    try {
      const { publicKey } = await this.KeyPairPlugin.retrieveKeys({ pinCode });
      return publicKey;
    } catch (error) {
      console.error('[NativeSessionService] - Error retrieving existing public key:', error);
      throw error;
    }
  }

  public async signChallenge(challenge: string, pinCode: string) {
    try {
      const { signature } = await this.KeyPairPlugin.signChallenge({ challenge, pinCode });
      return signature;
    } catch (error) {
      console.error('[NativeSessionService] - Error signing challenge:', error);
      throw error;
    }
  }

  public async deleteKeys() {
    try {
      await this.KeyPairPlugin.deleteKeys();
    } catch (error) {
      console.error('[NativeSessionService] - Error deleting key pair:', error);
      return null;
    }
  }

  public async registerKey(pinCode: string) {
    try {
      const { publicKey } = await this.generateKeys(pinCode);
      await userApi.mobileSession.registerKey(publicKey);
    } catch (error) {
      console.error('[NativeSessionService] - Error registering key:', error);
      Sentry.captureException(error);
      throw error;
    }
  }

  public async renewToken(pinCode: string) {
    try {
      const publicKey = await this.getPublicKey(pinCode);
      const challenge = await userApi.mobileSession.getChallenge(publicKey);
      const signature = await this.signChallenge(challenge, pinCode);
      const token = await userApi.mobileSession.renewToken({ challenge, signature });

      if (!IS_COOKIE_TOKEN) {
        localStorage.setItem('token', token);
      }
      return token;
    } catch (error) {
      console.error('[NativeSessionService] - Error renewing token:', error);
      throw error;
    }
  }
}
export default NativeSessionService.getInstance();
