import type { hofyEquipmentRentalTerm } from '@/scenes/Hofy/types';
import type { IClientMethod } from '@/utils/api/payments/mainV2';
import type {
  NoticePeriodRuleType,
  NoticePeriodTimeUnit,
  NoticePeriodType,
  TenureRule,
} from '@/types/EOR/NoticePeriod';
import type { VariableCompensation } from '../EOR';
import type { ContractWorkspace, Reporter, SigningBonusInvoice } from './Contract';
import type { Adjustment, PaymentCycleStatus, Report, Seniority, Template, User } from './Contract';
import type { CountryCode } from '@letsdeel/ui';
import type { EligibleForRehire } from '@/utils/api/contract';
import type { QuoteRejectionReasonsEnum } from '@/utils/contract/quoteRejection';
import type { WorkArrangementType } from '@/components/ContractForm/fields/WorkArrangementField/types';

export enum EmployeeAdjustmentEnum {
  EXPENSE = 'expense',
  BONUS = 'bonus',
  ALLOWANCE = 'allowance',
}

export enum EORAdjustmentEnum {
  EXPENSE = 'expense',
  DEDUCTION = 'deduction',
  BONUS = 'bonus',
  ALLOWANCE = 'allowance',
  SALARY = 'salary',
  OTHER = 'other',
  MANAGEMENT_FEE = 'management_fee',
  EMPLOYER_COST = 'employer_cost',
  NON_STATUTORY_EMPLOYER_COST = 'non_statutory_employer_cost',
  HEALTH_BENEFIT = 'health_benefit',
  WORK = 'work',
  SIGNING_BONUS = 'signing_bonus',
  SIGNING_BONUS_EMPLOYER_COST = 'signing_bonus_employer_cost',
  ADDITIONAL_FEE = 'additional_fee',
  PENSION = 'pension',
  HEALTH_ALLOWANCE = 'health_allowance',
  HEALTH_INSURANCE_FEE = 'health_insurance_fee',
  DEPOSIT = 'deposit',
  FOREIGN_CURRENCY_CONTRACT_FEE = 'foreign_currency_contract_fee',
  ACCRUED_HOLIDAY = 'accrued_holiday',
  SEVERANCE_PAY = 'severance',
}

export const MonthlyCostReportsTypes = [
  EORAdjustmentEnum.EMPLOYER_COST,
  EORAdjustmentEnum.NON_STATUTORY_EMPLOYER_COST,
  EORAdjustmentEnum.HEALTH_BENEFIT,
  EORAdjustmentEnum.MANAGEMENT_FEE,
  EORAdjustmentEnum.WORK,
  EORAdjustmentEnum.SIGNING_BONUS,
  EORAdjustmentEnum.SIGNING_BONUS_EMPLOYER_COST,
  EORAdjustmentEnum.ADDITIONAL_FEE,
  EORAdjustmentEnum.PENSION,
  EORAdjustmentEnum.HEALTH_INSURANCE_FEE,
  EORAdjustmentEnum.DEPOSIT,
  EORAdjustmentEnum.FOREIGN_CURRENCY_CONTRACT_FEE,
  EORAdjustmentEnum.ACCRUED_HOLIDAY,
  EORAdjustmentEnum.SEVERANCE_PAY,
];

export enum ApprovedStatus {
  approved = 'approved',
}

export enum DeclinedStatus {
  declined = 'declined',
}

export enum PendingStatus {
  pending = 'pending',
}

export enum AdjustmentStatus {
  pending = 'pending',
  declined = 'declined',
  approved = 'approved',
}

export interface EORPaymentCycle {
  id: number;
  start: string;
  end: string;
  isLocked: boolean;
  status: PaymentCycleStatus;
  payrolls?: EorPayroll[];
  reports: EORAdjustmentResponse[];
}

export type AdjustmentStatusType = `${AdjustmentStatus}`;

export enum NextPaymentAdjustmentTypes {
  EXPENSE = 'expense',
  BONUS = 'bonus',
  ALLOWANCE = 'allowance',
  DEDUCTION = 'deduction',
}

export interface Reviewer {
  id: number;
  name: string;
  picUrl: string | null;
  profileType: string;
}
export interface EORAdjustmentResponse {
  id: number;
  isLocked?: boolean;
  type: EORAdjustmentEnum;
  total: string;
  bonus: string;
  attachments?: {
    filename: string;
    signedUrl?: string;
  }[];
  filename?: string;
  status: AdjustmentStatus;
  payrollIds: number[] | null;
  recurring: boolean;
  description: string;
  when: string;
  reporter?: Reporter | null;
  reviewer?: Reviewer | null;
  reviewReason?: string;
  submittedBy?: Reporter | null;
  reviewedBy?: Reviewer | null;
  reviewedAt?: string | null;
  createdAt?: string | null;
  isDeleted: boolean;
  tax?: number;
  offCycleId: null | number;
  isRecurring: boolean;
  payrollEventId: string | null;
}

export enum PayrollStatus {
  LOCKED = 'locked',
  ACTIVE = 'active',
}

export interface Review {
  status: AdjustmentStatusType;
  reason?: string | null;
}

export interface EorPayroll {
  id: number;
  status: PayrollStatus;
  start: string;
  lock: string;
  end: string;
  EORContractId: number;
  PaymentCycleId?: number;
  netPayment: any;
  amended: boolean;
  modifierUserId: number | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  payStart?: string;
  isFirstPayroll?: boolean;
}

export interface EX_REVIEW {
  exAssigneeId: number;
  isLegalInputRequired: boolean;
  isCSMInputRequired: boolean;
}
export interface CSM_REVIEW {
  notes: string;
}

export interface LEGAL_REVIEW {
  legalFeeState: string;
  legalFeeAmount: number;
  endingType: string;
  terminationType: string;
  earliestEndDate: string;
  latestFinalPaymentDate: string;
  severanceAmount: number;
  severanceType: string;
}

export interface END_DETAILS {
  endDate: string;
  endingType: string;
  terminationType: string;
  finalInvoiceType: string;
}
export interface OFFBOARDING_PAYMENTS {
  ptoState: string;
  ptoAmount: number;
  ptoTimeOffDaysUsed: number;
  ptoConfirmedAt: string;
  severanceState: string;
  severanceAmount: number;
  SeveranceConfirmedAt: string;
}
export interface DOCUMENTS {
  isEmployeeInformed: boolean;
  status: string;
}
export interface UNENROLLMENT {
  health: string;
  healthUnenrollmentConfirmedAt: string;
}
export interface FEE_AND_ADJUSTMENTS {
  pass: boolean;
}
export interface OFFCYCLE_INVOICE {
  pass: boolean;
}
export interface DEPOSIT_REFUND {
  amount: number;
}

export type TerminationReasonsType =
  | 'PERFORMANCE_ISSUES'
  | 'ATTENDANCE_ISSUES'
  | 'REORGANIZATION_DOWNSIZING_BUDGET_OR_REDUCTION_OF_WORKFORCE_OR_ROLE_BECAME_REDUNDANT_OR_ROLE_CHANGED'
  | 'ROLE_BECAME_REDUNDANT_OR_ROLE_CHANGED'
  | 'OFFER_WAS_REVOKED'
  | 'EXITING_THE_MARKET'
  | 'NO_LONGER_WISH_TO_WORK_WITH_DEEL'
  | 'COMPANY_ACQUIRED'
  | 'STEALING'
  | 'MISCONDUCT'
  | 'FALSIFYING'
  | 'HARASSMENT'
  | 'VIOLENCE'
  | 'EMPLOYEE_IS_MOVING_TO_ANOTHER_COUNTRY'
  | 'MOVING_EMPLOYEE_TO_INTERNAL_ENTITY'
  | 'MOVING_TO_INTERNAL_ENTITY_WITH_DEEL_GLOBAL_PAYROLL'
  | 'MOVING_TO_INTERNAL_ENTITY_WITH_OTHER_PROVIDER'
  | 'DISSATISFIED_WITH_DEEL_SERVICE'
  | 'DISSATISFIED_WITH_DEEL_PRICING'
  | 'DISSATISFIED_WITH_DEEL_PRODUCT'
  | 'EMPLOYEE_DID_NOT_TAKE_THE_OFFER'
  | 'EMPLOYEE_FOUND_ANOTHER_JOB'
  | 'EMPLOYEE_NOT_HAPPY_WITH_BENEFITS'
  | 'EMPLOYEE_NOT_HAPPY_WITH_SALARY'
  | 'EMPLOYEE_NOT_HAPPY_WITH_THE_ROLE'
  | 'EMPLOYEE_NOT_HAPPY_WITH_COMPANY_CULTURE'
  | 'EMPLOYEE_NOT_HAPPY_WITH_DEEL'
  | 'EMPLOYEE_PROJECT_ENDED'
  | 'EMPLOYEE_SWITCHING_TO_OTHER_EOR_PROVIDER'
  | 'EMPLOYEE_LEFT_ROLE_FOR_PERSONAL_MATTERS'
  | 'EMPLOYEE_MOVING_FROM_EOR_TO_CONTRACTOR_OR_FREELANCE'
  | 'UNAUTHORIZED_ABSENCES_OR_LACK_OF_ATTENDANCE'
  | 'DEEL_COLLECTIONS'
  | 'EMPLOYEE_PROVIDED_NO_REASON'
  | 'STATUS_CHANGE'
  | 'RELOCATION'
  | 'COMPENSATION'
  | 'RETIREMENT'
  | 'JOB_ABANDONMENT'
  | 'WORK_RELATED'
  | 'PERSONAL'
  | 'OTHER_OPPORTUNITY'
  | 'NON_RENEWAL'
  | 'PROBATION'
  | 'ASSIGNMENT_ENDED'
  | 'POSITION_ELIMINATION'
  | 'FORCE_REDUCTION'
  | 'DEATH'
  | 'FOR_CAUSE'
  | 'PERFORMANCE'
  | 'MEDICAL'
  | 'STANDARD_RESELLER_PRACTICE';

export interface REQUEST {
  reason: TerminationReasonsType;
  employeeNotificationDetail: string;
  isEmployeeNotified: boolean;
  timeOffUsedDaysByYear: {
    [key: string]: number;
  };
  severanceAmount: number;
  severanceType: 'DAYS' | 'WEEKS' | 'MONTHS' | 'CASH';
  reasonDetail: string;
  hasClientAcceptedResignationRequest?: boolean;
  declineReason?: string;
  declineReasonDetail?: string;
  desiredEndDate: string;
  submittedAt?: string;
  isEmployeeResignation?: boolean;
  clientSubmittedPtoAt?: string;
  clientReviewerName?: string;
  clientReviewerId?: number;
  ptoAutoconfirmed?: boolean;
  ptoAutoconfirmedAt?: string;
  attachments: any[];
  type: 'TERMINATION' | 'RESIGNATION';
  customTerminationReason?: {
    id: string;
    name: string;
    description: string;
  };
  terminationImpact?: {
    id: string;
    title: string;
  };
  eligibleForRehire?: EligibleForRehire;
  eligibleForRehireReason?: string;
}

export interface EorTerminationStep {
  TemplateId?: any;
  confirmedAt: string | null;
  data: REQUEST | null;
  label: 'REQUEST' | 'RESIGNATION';
  isTemplateManagerFlow: boolean; //true for the new templates, false for the old
}

export interface EorTerminationFinalPayroll {
  end: string;
  lock: string;
  start: string;
}

export type EORTerminationStatus =
  | 'CREATED'
  | 'AWAITING_TRIAGE'
  | 'AWAITING_PTO'
  | 'PROCESSING'
  | 'AWAITING_REFUND'
  | 'COMPLETED'
  | 'CANCELLED';

export interface EorTerminationDocument {
  filename: string;
  id: string;
}

export interface EORTerminationDocumentsAwaitingClientReview {
  explanation: string;
  documents: EorTerminationDocument[];
  notificationInstructions?: string;
  isEmployeeNotificationRequired?: boolean;
  isApprovalRequested: boolean;
}

export interface EORTerminationDocumentsApprovedByClient {
  documents: EorTerminationDocument[];
}

export interface EORTerminationDocumentsAwaitingEmployeeReview {
  explanation: string;
  documents: EorTerminationDocument[];
}
export interface EorTermination {
  supportingAttachments?: { filename: string; id: string }[];
  endingAttachments?: { filename: string; id: string }[];
  createdAt: string;
  endDate: string | null;
  displayEndDate: boolean | null;
  finalInvoiceType: string;
  id: number;
  severanceAmount: string;
  severanceType: string;
  status: EORTerminationStatus;
  steps: EorTerminationStep[];
  currentStatuses: { id: string; name: string }[];
  /** @deprecated remove and use currentStatuses */
  tags: string[];
  type: 'TERMINATION' | 'RESIGNATION';
  desiredEndDate?: string;
  payroll?: EorTerminationFinalPayroll;
  requesterName?: string;
  requestData?: REQUEST;
  documentsAwaitingReview?: EORTerminationDocumentsAwaitingClientReview;
  documentsApprovedByClient?: EORTerminationDocumentsApprovedByClient;
}

export type EmployeeResignationAttachment = { id: string; filename: string };

export interface EorEmployeeResignationRequest {
  id: number;
  currentStatuses: { id: string; name: string }[];
  desiredEndDate: string | null;
  signedForDesiredEndDate?: string;
  reason: string | null;
  reasonDetail: string | null;
  attachments: EmployeeResignationAttachment[];
  submittedAt: string | null;
  status: EORTerminationStatus;
}

export interface EorIncentivePlanDeposit {
  id: string;
  amount: string;
  dueDate: null | string | Date;
  isOverdue: boolean;
  paidAt: null | string | Date;
  status: 'processing' | 'paid' | 'pending';
  reports: [
    {
      type: string;
      total: string;
      description: string;
      id: string;
    }
  ];
}

/* ================================================================================= *
 * Auto generated interface using MOCK data                                          *
 * Some properties might be missing                                                  *
 * Using any for properties I could not find any information on                      *
 * ================================================================================= */

export interface Eor {
  signingBonusInvoice?: SigningBonusInvoice;
  originalStartDate: string;
  eorContractOid?: string;
  endingType: string | null;
  endingAdditionalInfo: EndingAdditionalInfo;
  endingApprovalStatus: string | null;
  endingApprovedAt: string | null;
  endDateConfirmedAt: string | null;
  endingDocumentsConfirmedAt: string | null;
  endingFinalPaymentConfirmedAt: string | null;
  ResignationLetterTemplateId: string | null;
  resignationLetterAttachment: string | null;
  resignationLetterSignedAt: string | null;
  resignationLetterSignature: string | null;
  isOnboardingOnHold: boolean;
  hasSingingBonusInvoice: boolean;
  healthAllowance: string;
  healthAllowanceCurrency: string;
  healthPlan: HealthPlan;
  employeeSelectedHealthPlan: HealthPlan;
  healthPlanId?: number;
  sickDays: SickDays;
  sickLeaveDays: number;
  id: number;
  jobTitle: string;
  data: EorData;
  employeeLegalName: string;
  employeeFirstName: string;
  employeeLastName: string;
  employeePicUrl: string | null;
  employeeEmail: string;
  employeeAddress: any;
  employeeNationality: string;
  employmentCountry: CountryCode;
  countryDataVersionId: number;
  employmentState: string;
  employeeId: string;
  employmentType: string;
  startDate: string;
  endDate: string | null;
  signingBonus: string | null;
  firstMonthSalary: string;
  firstMonthEmployerCost: string;
  workVisa: boolean;
  depositMonths: string;
  quote: string | null;
  exclusivityEnd: string | null;
  signFees: SignFees;
  EmployeeId: number;
  monthlyGrossSalary: string;
  salary: string;
  monthlyEmployerCost: string;
  monthlyEorManagementFeeUSD: string;
  monthlyHealthInsurance: string | null;
  variableCompensation: string | null;
  variableCompensationType: 'PERCENTAGE' | 'FIXED' | null;
  variableCompensations: VariableCompensation[];
  incentivePlanDepositInvoice: EorIncentivePlanDeposit;
  workHoursPerWeek: number;
  holidays: number | null;
  probationPeriod: number;
  probationPeriodTypeForDefinite: ProbationPeriodTypeForDefinite;
  scope: string;
  employeeLateSignStart: string;
  seniority: HealthPlan;
  EAReviewedByProfile?: {
    id: number;
    name: string;
  };
  eaClientReviewedAt?: string;
  eaRequestChangesReason?: string;
  timeOffType: string;
  teamId?: number;
  clientLegalEntityId: number;
  EOR: EOR;
  employeeContractId: string;
  EmployeeContract?: EmployeeContract;
  employeeSignedAt: string;
  depositAccrual: DepositAccrual | null;
  canAdjustOn: CanAdjustOn;
  canRemindEmployeeToSign: boolean;
  addons: Addon[];
  additionalFees: any[];
  signingBonusEmployerCost: string | null;
  payrolls: EorPayroll[];
  isExclusivityEnabled: boolean;
  isEarlyInvoiceEnabled: boolean;
  adjustments: Adjustment[];
  payrollType: string;
  hasActivePayslips: boolean;
  refundMethod: any;
  defaultClientMethod?: IClientMethod;
  depositRefund: DepositRefund;
  healthInsurancePlanStatus?: 'OPTED_OUT' | 'PENDING' | 'SIGNED_UP';
  healthInsuranceOfferStatus?: 'OFFERED' | 'NOT_OFFERED';
  termination?: EorTermination;
  employeeResignationRequest?: EorEmployeeResignationRequest;
  // employee sign off documents
  documentsAwaitingReview?: EORTerminationDocumentsAwaitingEmployeeReview;
  hasSensitiveTermination?: boolean;
  terminationRequesterName?: string;
  healthInsuranceLastIngestAt?: string;
  healthInsuranceUpdatedAt?: string;
  firstPayrollLock?: number;
  healthSettings?: {
    healthCoverAll: boolean;
    healthCoverDependents: boolean;
  };
  healthInsuranceAmountRealized?: number;
  healthAllowanceRealized?: number;
  healthInsuranceAmountPaid?: number;
  calculatedHolidays: string;
  noticePeriodType: 'STANDARD' | 'CUSTOM';
  noticePeriod: NoticePeriod;
  refundedOffPlatformAt: string | null;
  employeeAgreementSentAt?: string;
  benefits: {
    id: number;
    name: string;
    clientSelectedProvider: {
      id: number;
      name: string;
      Benefit: {
        name: string;
        id: number;
      };
    };
    clientContributionTotal: number;
    calculatedClientContribution: number;
    clientSelectedPlanId: number | null;
    coverAll: boolean;
    coverDependents: boolean;
    benefitFee: number;
  }[];
  eaReviewEnabled: boolean;
  refundUsingClientBalance: boolean;
  workspace?: ContractWorkspace;
  refundClientBalanceCurrency?: string;
  wetInkFee: { wetInkFeeLocalCurrency: string; wetInkFeeUSD: string };
  fixedAdjustmentIds: string[];
  isConsultantContract?: boolean;
  lastQuoteRejection: { rejectionType: string; rejectionReason: QuoteRejectionReasonsEnum; rejectionMessage: string };
  jobRequirements: string | null;
  seniorityDate: string | null;
  isTransfer?: boolean;
  workArrangement?: WorkArrangementType;
  workArrangementDescription?: string;
}

interface NoticePeriod {
  appliedValue: number;
  timeUnit: NoticePeriodTimeUnit;
  ruleType: NoticePeriodRuleType;
  settingsForDuringProbation: {
    value: number;
    type: NoticePeriodType;
  };
  settingsForAfterProbation: {
    value?: number;
    type: NoticePeriodType;
    tenureRules?: TenureRule[];
  };
}

export interface EmployeeContract {
  id: string;
  status: string;
  employeeSignedAt: string | null;
  counterSignedAt: string | null;
}

export interface EOR {
  id?: string;
  country: string;
  isEmployeeOnPlatform: boolean;
  healthInsurance: string;
  depositAccrualEnabled: boolean;
  isDeel: boolean;
  isAffiliate: boolean;
  user: User;
  adjustments: any[];
  invoiceIssueDate: number;
  firstPayrollLock?: number;
  legalEntity: {
    id: number;
    name: string;
  };
  isInternal?: boolean;
  cityName: string | null;
}

interface DepositRefund {
  due: string;
  isOverdue: boolean;
  depositPaidReports: Array<Report>;
  amountRefunded: string;
  refundedAt: string;
}

export interface DepositAccrual {
  totalMonths: number;
  monthsLeft: number;
  refundedAt: string;
  total: string;
  monthlyAmount: number;
  amountLeft: string;
  invoicedAlready: string;
}

export interface Addon {
  id: number;
  type: string;
  effectiveDate: string;
  signedAt: string;
  clientSignedAt: string;
  contractorSignedAt: string;
  templateName: string;
  attachment: string | null;
  annexNumber: number | null;
  originalMSAContract: string | null;
  isWithoutSignature?: boolean;
}

export interface CanAdjustOn {
  start: string;
  end: string;
}

export interface EorData {
  clientPatchedFields: null | { [key: string]: boolean };
  startDatePushedAt?: null | Date;
}

export interface HealthPlan {
  id: number;
  name: string;
  currency?: string;
  HealthPlanGroup?: any;
  attachments?: any;
}

export interface SickDays {
  min: string;
  max: string;
}

export interface SignFees {
  additionalFees: any[];
  signingBonusEmployerCost: string | null;
}

export interface EndingAdditionalInfo {
  requestedTerminationDate: string;
  reasonForChangingDate: string;
}

export interface EorDepositStatusTotalItem {
  currency: string | null;
  total: number;
}

export interface EorDepositStatus {
  currency: string;
  depositAccrualId: number[] | null;
  depositAccrualTotal: EorDepositStatusTotalItem[];
  depositSingleTotal: EorDepositStatusTotalItem[];
  depositsTotal: EorDepositStatusTotalItem[];
  refundsTotal: EorDepositStatusTotalItem[];
  totalInContractCurrency: number;
  deposits: {
    id: number;
    createdAt: string;
    invoiceId: number;
    invoiceStatus: string;
    paidAt: string;
    total: string;
  }[];
  totalSeverancePaid: EorDepositStatusTotalItem[];
  totalStandardPaid: EorDepositStatusTotalItem[];
}

export interface EorAmendmentHistory {
  startDate: string;
  originalStartDate: string;
  id: number;
  status: string;
  amendmentFlowId: string;
  eorClientSignedAt: string | null;
  eorClientSignature: string | null;
  eorEmployeeSignedAt: string | null;
  eorEmployeeSignature: string | null;
  clientSignedAt: string | null;
  clientSignature: string | null;
  employeeSignedAt: string | null;
  employeeSignature: string | null;
  jobTitle: string;
  employeeEmail: string;
  employeeAddress: string;
  employeeNationality: string;
  employeePersonalId: string;
  employmentType: string;
  effectiveDate: null | string;
  fixedAdjustments: any[];
  salary: string;
  scope: string;
  endDate: string | null;
  managementFeeUSD: string;
  amendedItems: string[];
  sentToClientAt: string | null;
  sentToEmployeeAt: string | null;
  initial: any;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  lateStart: string;
  holidays: number;
  probationPeriod: number;
  probationPeriodTypeForDefinite: ProbationPeriodTypeForDefinite;
  workHoursPerWeek: number;
  timeOffType: string;
  variableCompensation: string | null;
  EORSOWAttachments: any[];
  monthlyEmployerCost: null | string;
  monthlyManagementFee: string;
  monthlyHealthInsurance: string | null;
  employeeTemplate: Template;
  clientTemplate: Template;
  employeeId: string;
  monthlyGrossSalary: string;
  seniority: Seniority | null;
  depositAmount: string | null;
  sowTitle: string;
  eaTitle: string;
  variableCompensations: VariableCompensation[];
  voidDeadline: string | null;
  voidDeadlineType: string | null;
  isCreatedByAdmin?: boolean;
  documentType: string | null;
}

export interface CountryValidations {
  holiday: CountryValidationsHoliday;
  sickDays: CountryValidationsSickDays;
  salary: CountryValidationsSalary;
  probation: CountryValidationsProbation;
  workSchedule: CountryValidationsWorkSchedule;
  insuranceFee?: any;
  currency: string;
  sameMonthPayment: boolean;
  additionalInfo: number;
  complianceDocs: number;
  countryFullName: string;
  hiringGuideCountryName: string;
  severanceAccrualDeposit1stYearMonthsToPay: number | null;
  severanceAccrualDeposit2ndYearMonthsToPay: number | null;
  severanceAccrualDeposit3rdYearMonthsToPay: number | null;
}
export interface CountryValidationsHoliday {
  min: string;
}
export interface CountryValidationsSickDays {
  min: string;
  max: string;
}
export interface CountryValidationsSalary {
  min: string;
  max: string;
}
export interface CountryValidationsProbation {
  min: number;
  max: number;
}
export interface CountryValidationsWorkScheduleDays {
  max: string;
}
export interface CountryValidationsWorkScheduleHours {
  max: string;
}
export interface CountryValidationsWorkSchedule {
  days: CountryValidationsWorkScheduleDays;
  hours: CountryValidationsWorkScheduleHours;
}

export interface EquipmentIntent {
  variantId: string | undefined;
  rentalTerm: hofyEquipmentRentalTerm;
  productName: string;
  category: string;
  brand: string;
}
export interface CreateEOREquipmentIntentsRequestBody {
  contractOid: string;
  intents: EquipmentIntent[];
}

export enum ProbationPeriodTypeForDefinite {
  STANDARD = 'STANDARD',
  CUSTOM = 'CUSTOM',
  REMOVE = 'REMOVE',
}
