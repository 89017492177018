import useStores from '@/hooks/useStores';
import type { IUserStore } from '@/types/User';

const useUserStore = () => {
  const { user } = useStores<{ user: IUserStore }>();

  return user;
};

export default useUserStore;
