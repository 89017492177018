import type { ReactNode, MouseEvent } from 'react';
import { useMemo } from 'react';
import type { ButtonProps } from '@letsdeel/ui';
import { Button } from '@letsdeel/ui';

export type ButtonSizeType = 'xs' | 'sm' | 'md' | 'lg';
export type ButtonThemeType =
  | 'icon'
  | 'icon-light'
  | 'icon-dark'
  | 'dropdown-icon'
  | 'close-icon'
  | 'icon-light'
  | 'icon-dark'
  | 'primary'
  | 'error'
  | 'secondary'
  | 'gray-secondary'
  | 'secondary-error'
  | 'outline'
  | 'outline-gray'
  | 'grey-secondary'
  | 'outline-error'
  | 'link'
  | 'error'
  | 'error-light'
  | 'text-error'
  | 'text'
  | 'text-primary'
  | 'text-primary'
  | 'text-success';

type ButtonType = 'button' | 'reset' | 'submit';

interface Props {
  children: ReactNode;
  size?: ButtonSizeType;
  className?: string;
  full?: boolean;
  loading?: boolean;
  theme?: ButtonThemeType;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  minWidth?: boolean;
  type?: ButtonType;
  'data-qa'?: string | null;
}

const DeelUIButtonWrap = ({
  children,
  size = 'lg',
  full,
  loading,
  theme = 'primary',
  disabled,
  minWidth,
  type,
  ...rest
}: Props) => {
  const mappedColorVariantProps: Partial<ButtonProps> = useMemo(() => {
    // Ignored themes so far:
    // ICONS : icon, icon-light, icon-dark, dropdown-icon, close-icon, icon-light, icon-dark,
    // link  : It is just plain text
    // text : it is text but the color is lighter
    // text-succes : it is text but the color is success
    const map: Record<string, Partial<ButtonProps>> = {
      primary: { color: 'primary', variant: 'contained' },
      secondary: { color: 'secondary', variant: 'contained' },
      error: { color: 'error', variant: 'contained' },
      // @todo [DES-2635]: This is a temporary fix for the secondary error button
      //@ts-expect-error -> This will be fixed on deel/ui when merging this PR https://github.com/letsdeel/ui/pull/2087
      'secondary-error': { color: 'errorSecondary', variant: 'contained' },
      'grey-secondary': { color: 'primary', variant: 'outlined' },
      outline: { color: 'primary', variant: 'outlined' },
      'outline-gray': { color: 'primary', variant: 'text' },
      'outline-error': { color: 'error', variant: 'outlined' },
      //@ts-expect-error -> This will be fixed on deel/ui when merging this PR https://github.com/letsdeel/ui/pull/2087
      'error-light': { color: 'errorSecondary', variant: 'contained' },
      'text-error': { color: 'error', variant: 'text' },
      'text-primary': { color: 'primary', variant: 'text' },
    };

    return map[theme] ? map[theme] : {};
  }, [theme]);

  const mappedSize: ButtonProps['size'] = useMemo(() => {
    const map: Record<ButtonSizeType, ButtonProps['size']> = {
      xs: 'small',
      sm: 'medium',
      md: 'small',
      lg: 'large',
    };

    return map[size] ? map[size] : 'medium';
  }, [size]);

  return (
    <Button
      size={mappedSize}
      loading={loading}
      type={type}
      disabled={disabled}
      {...mappedColorVariantProps}
      {...rest}
      className={loading ? `loading ${rest.className}` : rest.className}
      sx={{
        minWidth: 'auto',
      }}
      data-qa={rest['data-qa'] ? rest['data-qa'] : ''}
    >
      {children}
    </Button>
  );
};

export default DeelUIButtonWrap;
