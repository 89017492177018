import { match } from 'ts-pattern';
import {
  parse,
  differenceInYears,
  startOfDay,
  isAfter,
  differenceInDays,
  addDays,
  format,
  isWithinInterval,
  isBefore,
} from 'date-fns';
import type { ChipColor } from '@letsdeel/ui';
import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import type { IContractBenefit, IEmployeeBenefitsContract } from '@/utils/api/benefits/benefits';
import type {
  Benefit,
  BenefitProviderPlan,
  ClientBenefitsEnrollmentGridItem,
  ClientBenefitsEnrollmentStats,
  ClientBenefitsEnrollmentValues,
  DependentAddressFormFields,
  DependentFormFields,
  EnrollmentFormDependent,
} from '@/scenes/Benefits/types/interfaces';
import {
  BenefitTypes,
  EnrollmentStatuses,
  QualifyingLifeEventStatuses,
  QualifyingLifeEventTypes,
  RelationTypes,
} from '@/scenes/Benefits/types/enums';
import type { DetailsMessagesByBenefit } from '../types/interfaces';
import {
  FSA_BENEFIT_TITLE,
  FSA_DEPENDENT_CARE_BENEFIT_TITLE,
  FSA_PARKING_BENEFIT_TITLE,
  FSA_TRANSIT_BENEFIT_TITLE,
  HSA_BENEFIT_TITLE,
  LONG_TERM_BENEFIT_TITLE,
  SHORT_TERM_BENEFIT_TITLE,
} from './content';
import upperFirst from 'lodash/upperFirst';
import type { Theme } from '@letsdeel/ui';

export const getDetailsMessagesByBenefit = (
  benefitName: string,
  period: 'monthly' | 'yearly' = 'monthly'
): DetailsMessagesByBenefit[string] | undefined =>
  ({
    Healthcare: {
      employeeMonthlyCost: `Standard ${period} cost of plan`,
      clientMonthlyCost: `Standard employer’s ${period} contribution`,
      employeeLastMonthlyCost: 'Current cycle cost of plan',
      clientLastMonthlyCost: 'Current cycle employer’s contribution',
    },
    Pension: {
      employeeMonthlyCost: `Standard ${period} employee contribution`,
      clientMonthlyCost: `Standard ${period} employer contribution`,
      employeeLastMonthlyCost: 'Current cycle employee contribution',
      clientLastMonthlyCost: 'Current cycle employer contribution',
    },
    'Life Insurance': {
      employeeMonthlyCost: `Standard ${period} cost of plan`,
      clientMonthlyCost: `Standard employer’s ${period} contribution`,
      employeeLastMonthlyCost: 'Current cycle cost of plan',
      clientLastMonthlyCost: 'Current cycle employer’s contribution',
    },
    'Accident and Disability': {
      employeeMonthlyCost: `Standard ${period} cost of plan`,
      clientMonthlyCost: `Standard employer’s ${period} contribution`,
      employeeLastMonthlyCost: 'Current cycle cost of plan',
      clientLastMonthlyCost: 'Current cycle employer’s contribution',
    },
    'Meal Voucher': {
      employeeMonthlyCost: `Standard ${period} cost of plan`,
      clientMonthlyCost: `Standard employer’s ${period} contribution`,
      employeeLastMonthlyCost: 'Current cycle cost of plan',
      clientLastMonthlyCost: 'Current cycle employer’s contribution',
    },
  }[benefitName] ?? {
    employeeMonthlyCost: `Standard ${period} cost of plan`,
    clientMonthlyCost: `Standard employer’s ${period} contribution`,
    employeeLastMonthlyCost: 'Current cycle cost of plan',
    clientLastMonthlyCost: 'Current cycle employer’s contribution',
  });

export const getBenefitStatusText = (benefit: IContractBenefit | undefined) => {
  if (benefit?.clientOfferEndedAt) return 'Not Offered';
  return capitalize(benefit?.status.replaceAll('_', ' '));
};

export const filterUniqueBenefitByProvider = (contracts?: IEmployeeBenefitsContract[]): IEmployeeBenefitsContract[] => {
  if (!contracts) return [];

  return contracts.map((contract) => {
    const benefitsMap: { [providerId: string]: IContractBenefit } = {};

    contract.benefits.forEach((benefit) => {
      const provider = benefit.employeeSelectedProvider || benefit.clientSelectedProvider;

      if (!provider) return;

      const existingBenefit = benefitsMap[provider.id];

      if (!existingBenefit || isAfter(new Date(benefit.createdAt), new Date(existingBenefit.createdAt))) {
        benefitsMap[provider.id] = benefit;
      }
    });

    const filteredContract = {
      ...contract,
      benefits: Object.values(benefitsMap),
    };

    return filteredContract;
  });
};

export const benefitAvatarUrl = (benefitName: string) => {
  if (benefitName.toLocaleLowerCase().includes('pension')) {
    return 'https://s3.amazonaws.com/media.letsdeel.com/images/benefits/pension.svg';
  }

  return 'https://s3.amazonaws.com/media.letsdeel.com/images/benefits/healthcare.svg';
};

export const benefitTypesToRoutes = {
  [BenefitTypes.HEALTHCARE]: 'healthcare',
  [BenefitTypes.DENTAL]: 'dental',
  [BenefitTypes.VISION]: 'vision',
  [BenefitTypes.HSA]: 'hsa',
  [BenefitTypes.FSA]: 'fsa',
  [BenefitTypes.LIFE_INSURANCE]: 'life-insurance',
  [BenefitTypes.VOLUNTARY_LIFE_INSURANCE]: 'voluntary-life-insurance',
  [BenefitTypes.K_401]: '401-k',
  [BenefitTypes.LONG_TERM_DISABILITY]: 'long-term-disability',
  [BenefitTypes.SHORT_TERM_DISABILITY]: 'short-term-disability',
  [BenefitTypes.PENSION]: 'pension',
  [BenefitTypes.MEAL_VOUCHER]: 'meal-voucher',
  [BenefitTypes.ACCIDENT_INSURANCE]: 'accident-insurance',
  [BenefitTypes.CRITICAL_ILLNESS]: 'critical-illness',
  [BenefitTypes.ACCIDENT_AND_DISABILITY]: 'accident-disability',
  [BenefitTypes.FSA_DEPENDENT_CARE]: 'fsa-dependent-care',
  [BenefitTypes.FSA_TRANSIT]: 'fsa-transit',
  [BenefitTypes.FSA_PARKING]: 'fsa-parking',
};

export const routesToBenefitTypes = {
  healthcare: BenefitTypes.HEALTHCARE,
  dental: BenefitTypes.DENTAL,
  vision: BenefitTypes.VISION,
  hsa: BenefitTypes.HSA,
  fsa: BenefitTypes.FSA,
  'life-insurance': BenefitTypes.LIFE_INSURANCE,
  'voluntary-life-insurance': BenefitTypes.VOLUNTARY_LIFE_INSURANCE,
  '401-k': BenefitTypes.K_401,
  'long-term-disability': BenefitTypes.LONG_TERM_DISABILITY,
  'short-term-disability': BenefitTypes.SHORT_TERM_DISABILITY,
  pension: BenefitTypes.PENSION,
  'meal-voucher': BenefitTypes.MEAL_VOUCHER,
  'accident-insurance': BenefitTypes.ACCIDENT_INSURANCE,
  'critical-illness': BenefitTypes.CRITICAL_ILLNESS,
  'accident-disability': BenefitTypes.ACCIDENT_AND_DISABILITY,
  'fsa-dependent-care': BenefitTypes.FSA_DEPENDENT_CARE,
};

export const pipe =
  (...fns: any[]) =>
  (x: any) =>
    fns.reduce((v, f) => f(v), x);

export const mapEnrollmentStatusToChipTheme: Record<EnrollmentStatuses, ChipColor> = {
  [EnrollmentStatuses.AWAITING_CONTRACT_ACTIVATION]: 'warning',
  [EnrollmentStatuses.AWAITING_ENROLLMENT]: 'warning',
  [EnrollmentStatuses.ENROLLED]: 'success',
  [EnrollmentStatuses.OPTED_OUT]: 'default',
  [EnrollmentStatuses.EXPIRED]: 'default',
  [EnrollmentStatuses.COVERAGE_DECLINED]: 'error',
  [EnrollmentStatuses.REQUIRE_HEALTHCARE_ENROLLMENT]: 'default',
  [EnrollmentStatuses.UPCOMING]: 'default',
  [EnrollmentStatuses.NOT_ENROLLED]: 'error',

  // Transitory statuses that are generally ignored
  [EnrollmentStatuses.OPTED_OUT_PENDING_PROCESS]: 'default',
  [EnrollmentStatuses.ENROLLED_PENDING_PROCESS]: 'default',
};

export const mapQleStatusToChipTheme: Record<QualifyingLifeEventStatuses, ChipColor> = {
  [QualifyingLifeEventStatuses.AWAITING_APPROVAL]: 'warning',
  [QualifyingLifeEventStatuses.APPROVED]: 'success',
  [QualifyingLifeEventStatuses.REJECTED]: 'error',
};

export const updateTransitoryStatus = (status: EnrollmentStatuses) => {
  switch (status) {
    case EnrollmentStatuses.ENROLLED_PENDING_PROCESS:
      return EnrollmentStatuses.ENROLLED;
    case EnrollmentStatuses.OPTED_OUT_PENDING_PROCESS:
      return EnrollmentStatuses.OPTED_OUT;
    default:
      return status;
  }
};

export const getFirstThree = (arr: any[]) => [...arr.slice(0, 3)];

export const toQueryString = (values: Record<string, any>): string => {
  const params = new URLSearchParams();

  Object.entries(values).forEach(([key, value]) => {
    params.append(key, value.toString());
  });

  return params.toString();
};

export const hasEmptyKeys = (obj: { [key: string]: any }): string[] | [] => {
  return Object.entries(obj).reduce((acc: string[], [key, value]) => {
    if (value === '' || typeof value === 'undefined') {
      return [...acc, key];
    }

    if (Array.isArray(value) && value.length === 0) {
      return [...acc, key];
    }

    return [...acc];
  }, []);
};

export const getAge = (dateOfBirth: string): number => {
  const today = new Date();
  const birthDate = parse(dateOfBirth, 'YYYY-MM-DD', new Date());
  const age = differenceInYears(today, new Date(birthDate));
  return age;
};

export const benefitTypeToDisplayName = (benefitType?: string | undefined): string => {
  return match(benefitType)
    .with(BenefitTypes.HSA, () => HSA_BENEFIT_TITLE)
    .with(BenefitTypes.FSA, () => FSA_BENEFIT_TITLE)
    .with(BenefitTypes.LONG_TERM_DISABILITY, () => LONG_TERM_BENEFIT_TITLE)
    .with(BenefitTypes.SHORT_TERM_DISABILITY, () => SHORT_TERM_BENEFIT_TITLE)
    .with(BenefitTypes.FSA_DEPENDENT_CARE, () => FSA_DEPENDENT_CARE_BENEFIT_TITLE)
    .with(BenefitTypes.FSA_TRANSIT, () => FSA_TRANSIT_BENEFIT_TITLE)
    .with(BenefitTypes.FSA_PARKING, () => FSA_PARKING_BENEFIT_TITLE)
    .with(undefined, () => '')
    .otherwise((benefitType) => startCase(benefitType.toLowerCase().replaceAll('_', ' ')));
};

export const qleTypeToDisplayName = (qleType: string): string => {
  return startCase(qleType.toLowerCase().replaceAll('_', ' '));
};

export const isTodayAfterDate = (date: Date): boolean => {
  const today = startOfDay(new Date());
  const targetDate = startOfDay(new Date(date));
  const result = isAfter(today, targetDate);

  return result;
};

export const toSlug = (benefitType: BenefitTypes): string => {
  const slug = benefitType.toLowerCase().replace(/_/g, '-');
  return slug;
};

export const mapSlugToBenefitType = (slug: string): BenefitTypes => {
  const benefitType = slug.toUpperCase().replace(/-/g, '_');
  return benefitType as BenefitTypes;
};

export const parseStringToFloat = (value?: string | number | null): number => {
  if (value === undefined || value === null) return 0;
  if (typeof value !== 'string') return value;

  const parsedValue = parseFloat(value);

  if (Number.isNaN(parsedValue)) return 0;

  return parsedValue;
};

export const isDisabilityBenefit = (benefit: Benefit): boolean => {
  return [BenefitTypes.SHORT_TERM_DISABILITY, BenefitTypes.LONG_TERM_DISABILITY].includes(benefit.Provider.benefitType);
};

export const hasPlanInformation = (plan: BenefitProviderPlan): boolean => {
  return !!plan?.providerInformation?.coverageInformation?.length;
};

export const filterAvailablePlansBySelectedPlans = (
  availablePlans: BenefitProviderPlan[],
  selectedPlans: BenefitProviderPlan[]
): BenefitProviderPlan[] => {
  return availablePlans.filter((plan) => !selectedPlans.some((selectedPlan) => selectedPlan.id === plan.id)) || [];
};

export const checkIfIsHSACompatibleEnrolled = (benefits: Benefit[]) => {
  return !!benefits.find(
    (benefit) =>
      benefit.Provider?.benefitType === BenefitTypes.HEALTHCARE &&
      benefit.status === EnrollmentStatuses.ENROLLED &&
      benefit.Plan?.isHSACompatible
  );
};

export const checkIfHSAIsEnrolled = (benefits: Benefit[]) => {
  return !!benefits.find(
    (benefit) => benefit.Provider?.benefitType === BenefitTypes.HSA && benefit.status === EnrollmentStatuses.ENROLLED
  );
};

export const checkIfFSAIsEnrolled = (benefits: Benefit[]) => {
  return !!benefits.find(
    (benefit) => benefit.Provider?.benefitType === BenefitTypes.FSA && benefit.status === EnrollmentStatuses.ENROLLED
  );
};

export const benefitTypeToInitials = (benefitType: string): string => {
  if (benefitType === BenefitTypes.HSA) {
    return 'HSA';
  }

  if (benefitType === BenefitTypes.FSA) {
    return 'FSA';
  }

  return '';
};

export const isTodayBetween = (startDate: Date | string, endDate: Date | string): boolean => {
  startDate = new Date(startDate);
  endDate = new Date(endDate);

  const today = new Date();

  if (isBefore(endDate, startDate)) return false;

  return isWithinInterval(today, { start: startOfDay(startDate), end: startOfDay(endDate) });
};

export const getQualifyingLifeEventDeadline = (eventType?: QualifyingLifeEventTypes, eventDate?: string) => {
  if (!eventDate || !eventType) return null;

  const currentDate = new Date();
  const eventDateInstance = new Date(eventDate);

  const daysToAdd = eventType === QualifyingLifeEventTypes.LOSE_GOVERNMENT_PROVIDED_COVERAGE ? 60 : 30;

  const deadline = addDays(eventDateInstance, daysToAdd);
  const daysLeft = differenceInDays(deadline, currentDate);

  return {
    daysLeft,
    deadlineDate: format(deadline, 'yyyy/MM/dd'),
  };
};

export const getQLEDaysLeftTheme = (daysLeft: number) => {
  return daysLeft <= 0 ? 'error.main' : daysLeft > 0 && daysLeft <= 7 ? 'warning.main' : 'text.disabled';
};

export const getQLEDaysLeft = (daysLeft: number) => {
  return daysLeft <= 0 ? 'Window closed' : `${daysLeft} days left`;
};

export const getOpenEnrollmentChipTheme = (startDate: string): ChipColor =>
  isAfter(new Date(startDate), new Date()) ? 'info' : 'neutral';

export const getOpenEnrollmentChipContent = (startDate: string, endDate: string) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  return isAfter(start, new Date())
    ? `Open Enrollment starts on ${format(start, 'MMM dd, yyyy')}`
    : `Open Enrollment until ${format(end, 'MMM dd, yyyy')}`;
};

export const getBaseUrlPath = (path: string) => (path.includes('benefits-admin') ? '/benefits-admin' : '/benefits');

export const clearAddressFields = (country?: string): DependentAddressFormFields => ({
  address: '',
  addressLine2: '',
  zip: '',
  city: '',
  state: '',
  country: country || '',
});

export const updateFormValuesByRelationType = (
  relationType: RelationTypes.SPOUSE | RelationTypes.CHILD | RelationTypes.DOMESTIC_PARTNER,
  currentValues: DependentFormFields,
  dependentSpouseAddress?: Partial<DependentFormFields>
): DependentFormFields => {
  return match(relationType)
    .with(RelationTypes.SPOUSE, () => ({
      ...currentValues,
      ...dependentSpouseAddress,
      relationType,
    }))
    .with(RelationTypes.CHILD, () => ({
      ...currentValues,
      ...clearAddressFields(currentValues?.country),
      relationType,
    }))
    .with(RelationTypes.DOMESTIC_PARTNER, () => ({
      ...currentValues,
      relationType,
    }))
    .exhaustive();
};

export const relationTypeToDisplayName = (relationType: RelationTypes): string => {
  return match(relationType)
    .with(RelationTypes.SPOUSE, () => 'Spouse')
    .with(RelationTypes.CHILD, () => 'Child')
    .with(RelationTypes.DOMESTIC_PARTNER, () => 'Domestic Partner')
    .otherwise(() => '');
};

export const enrollmentStatusToHumanReadable = (status: EnrollmentStatuses): string => {
  return capitalize(status.toLowerCase().replaceAll('_', ' '));
};

export const underscoredCapitalTextToHumanReadable = (text?: string | null): string => {
  if (!text) return '';

  return upperFirst(text.toLowerCase().replaceAll('_', ' '));
};

export const isCustomBenefit = (benefitName: string): boolean => {
  const formattedBenefitName = benefitName.toUpperCase().replace(/\s+/g, '_');
  return !Object.keys(BenefitTypes).includes(formattedBenefitName);
};

export const getMonthWithMaxBenefits = (statsData: Array<Record<string, any>>) => {
  let highestBenefits = 0;
  let highestBenefitsMonth = 0;
  for (let i = 0; i < statsData.length; i++) {
    let monthLength = Object.keys(statsData[i]).length;
    if (monthLength > highestBenefits) {
      highestBenefits = monthLength;
      highestBenefitsMonth = i;
    }
  }
  return statsData[highestBenefitsMonth];
};

export const getBenefitsColorMapping = (theme: Theme, benefit: BenefitTypes): string => {
  const benefitsColorMapping = {
    [BenefitTypes.HEALTHCARE]: theme.palette.dataVisualization?.category7.main,
    [BenefitTypes.DENTAL]: theme.palette.dataVisualization?.category5.main,
    [BenefitTypes.VISION]: theme.palette.dataVisualization?.category2.main,
    [BenefitTypes.HSA]: theme.palette.dataVisualization?.category9.main,
    [BenefitTypes.FSA]: theme.palette.dataVisualization?.category9.dark,
    [BenefitTypes.LIFE_INSURANCE]: theme.palette.dataVisualization?.category1.main,
    [BenefitTypes.VOLUNTARY_LIFE_INSURANCE]: theme.palette.dataVisualization?.category3.main,
    [BenefitTypes.K_401]: theme.palette.dataVisualization?.category8.main,
    [BenefitTypes.LONG_TERM_DISABILITY]: theme.palette.dataVisualization?.category6.main,
    [BenefitTypes.SHORT_TERM_DISABILITY]: theme.palette.dataVisualization?.category6.light,
    [BenefitTypes.PENSION]: theme.palette.dataVisualization?.category4.main,
    [BenefitTypes.MEAL_VOUCHER]: theme.palette.dataVisualization?.category4.darker,
    [BenefitTypes.ACCIDENT_INSURANCE]: theme.palette.dataVisualization?.category8.darker,
    [BenefitTypes.CRITICAL_ILLNESS]: theme.palette.dataVisualization?.category7.darker,
    [BenefitTypes.ACCIDENT_AND_DISABILITY]: theme.palette.dataVisualization?.category5.darker,
    [BenefitTypes.FSA_DEPENDENT_CARE]: theme.palette.dataVisualization?.category1.darker,
    [BenefitTypes.FSA_TRANSIT]: theme.palette.dataVisualization?.category3.darker,
    [BenefitTypes.FSA_PARKING]: theme.palette.dataVisualization?.category2.darker,
  };
  return benefitsColorMapping[benefit] as string;
};

export const getEnrollmentStatusColor = (theme: Theme, status: keyof ClientBenefitsEnrollmentStats): string => {
  const statusColors = {
    [EnrollmentStatuses.AWAITING_ENROLLMENT]: theme.palette.dataVisualization?.category5.main,
    [EnrollmentStatuses.ENROLLED]: theme.palette.dataVisualization?.category8.main,
    [EnrollmentStatuses.ENROLLED_PENDING_PROCESS]: theme.palette.dataVisualization?.category2.main,
    [EnrollmentStatuses.OPTED_OUT_PENDING_PROCESS]: theme.palette.dataVisualization?.category1.main,
    [EnrollmentStatuses.EXPIRED]: theme.palette.dataVisualization?.category9.main,
  };
  return statusColors[status] as string;
};

export const constructEnrollmentGridList = (
  enrollmentList: ClientBenefitsEnrollmentValues[]
): ClientBenefitsEnrollmentGridItem[] => {
  const enrollmentGridItems: ClientBenefitsEnrollmentGridItem[] = [];

  enrollmentList.forEach((enrollment) => {
    enrollmentGridItems.push({
      id: enrollment.id,
      benefitCategory: `${enrollment.firstName} ${enrollment.lastName}`,
      benefitsLength: enrollment.contracts.benefits.length,
      hierarchy: [enrollment.id],
      jobTitleName: enrollment.contracts.jobTitleName,
    });
    const benefitsGridItems = enrollment.contracts.benefits.map((benefit) => ({
      id: benefit.id,
      benefitCategory: benefit.Benefit.name,
      carrier: benefit.Provider.name,
      plan: benefit.Plan.name,
      dependents: benefit.numberOfDependents,
      coverageStartDate: benefit.coverageStartDate,
      monthlyCost: benefit.totalContributionSum,
      status: benefit.status,
      hierarchy: [enrollment.id, benefit.id],
    }));
    enrollmentGridItems.push(...benefitsGridItems);
  });

  return enrollmentGridItems;
};

export const getFullName = ({ firstName, lastName }: EnrollmentFormDependent) => `${firstName} ${lastName}`;
