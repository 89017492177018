import type { FiltersForContractorsAndEorInvoices, FiltersForGpInvoices } from '@/constants/masspay';
import type { ContractStatus, PaymentCycleStatus } from '@/types/Contract';
import type {
  IReceipt as IReceiptPayload,
  InvoiceStatus,
  InvoiceType,
  PendingContractorsAndEorInvoice,
  PendingGpInvoice,
  PostPayPayload,
  PostPayResponse,
  PrepareInvoicesResponse,
  PrepareShieldInvoicesResponse,
  ServiceReport,
} from '@/types/Invoice';
import type { ApplyPaymentMethodResponse, PaymentMethodType, PaymentMethodsEnum } from '@/types/MassPay';
import type { RecentPaymentStatus } from '@/utils/api/dashboard';
import instance from '@/utils/api/instance';
import type { AxiosRequestConfig } from 'axios';
import qs from 'query-string';

export interface ReceiptInvoice {
  contractOid?: string;
  invoiceId?: string;
  name: string;
  type?:
    | 'hris'
    | 'shield'
    | 'contract'
    | 'unknown'
    | 'hofy'
    | 'one_off_service_fee'
    | 'background_check'
    | InvoiceType.EOR_ONE_OFF_MANAGEMENT_FEE
    | InvoiceType.EOR_MANAGEMENT_FEE;
  picUrl?: string | null;
  contractor?: {
    id: number;
    isShieldProvider: boolean;
    name: string;
    picUrl: string | null;
  };
  shieldedContractor?: {
    id: number;
    name: string;
    picUrl: string | null;
  } | null;
}

export interface IInvoice {
  id: number;
  internalId: number;
  invoiceId: string;
  hasBreakdown: boolean;
  attachments: any;
  isOverdue: boolean;
  isEarlyPaid: boolean;
  canEarlyInvoiceRelease?: boolean;
  status: 'overdue' | 'paid' | 'processing' | 'pending' | 'open';
  paidAt: string;
  amount: string;
  total: string;
  currency: string;
  isPaidToContractor: boolean;
  label: string;
  paymentCycle: any;
  createdAt: string;
  processedAt: string;
  isOffcycle: boolean;
  isOnDemandInvoice: boolean;
  isUpfrontAdjustmentInvoice: boolean;
  contract: any;
  type: any;
  filename: string | null;
  eorContract?: any;
  client?: any;
  contractor?: any;
  shieldedContractor?: {
    id: number;
    name: string;
    displayName: string;
    picUrl: string | null;
  } | null;
  paymentMethod: PaymentMethodsEnum;
  serviceReports: ServiceReport[];
  description: string;
  dueDate: string | null;
  title?: string;
  convertedTotal: string | null;
  reports: any[];
  vat: any;
  cycle: any;
  invoiceLabel: string;
  rootInvoiceId: any;
  rootInvoicePublicId: any;
  receiptLabel: any;
  hasG2N: boolean;
  isG2NAllowed: boolean;
  issuedAt: string | null;
  creditReason?: string | null;
  paymentDisplayStatus: {
    key: InvoiceStatus;
    label: string;
  };
  isPayableServiceFeeInvoice: boolean;
}

export interface IReceipt {
  attachments: { id: number }[];
  status: RecentPaymentStatus;
  contractors: any[];
  invoices?: ReceiptInvoice[];
  invoicesForEarlyRelease?: ReceiptInvoice[];
  createdAt: string;
  total: any;
  paymentMethod: any;
  paymentCurrency: string;
  label: string;
  dueDate: string | null;
  moneyReceivedAt?: string | null;
  processedAt?: string | null;
  paymentAmountDue?: string | null;
  paymentBalanceAmount?: string | null;
  paymentMethodType: PaymentMethodType;
  invoiceId: string;
  feeCreditApplied?: string | null;
}

interface UpcomingInvoicesParams {
  limit: number;
  offset: number;
  orderBy?: string;
  orderDirection?: string;
}

export interface UpcomingInvoiceItem {
  invoiceId: string;
  status: InvoiceStatus;
  amount: string;
  paidAt: string | null;
  moneyReceivedAt: string | null;
  currency: string;
  issuedAt: string;
  clientName: string;
  dueDate: string;
  isOverdue: boolean;
  isOffcycle?: boolean;
  contract: {
    oid: string;
    name: string;
    timezone: string;
    status: ContractStatus;
    clientLegalEntity: {
      organization?: {
        logoUrl: string | null;
      };
    };
    client: {
      id: number;
      name: string;
      picUrl: string;
      Organization?: {
        logoUrl: string;
      };
    };
  };
  paymentCycles?: {
    start: string;
    end: string;
    due: string;
    status: PaymentCycleStatus;
  }[];
}

export interface UpcomingInvoicesData {
  count: number;
  rows: UpcomingInvoiceItem[];
}

export interface ExpenseCategory {
  name: string;
  id?: number;
  settings: ExpenseCategorySettings;
  adjustmentCategoryGroupId: string | null;
  referenceAdjustmentCategoryId: string | null;
  categoryGroupName?: string;
  reportType?: string;
}

export interface OrganizationExpensesSettings {
  allowICExpenses: boolean;
}

export interface ExpenseCategorySettings {
  limit?: number;
  isAttachmentRequired: boolean;
  allowRecurringSubmissions: boolean;
  clientOnly: boolean;
  forceNameAsDescription: boolean;
}

export type VarianceReason = { note: string; title: string };

export interface Variance {
  amount: number;
  fromAmount: number | null;
  label: string;
  percentage: number;
  percentageLabel: string;
  toAmount: number | null;
  type: string;
  reasons: VarianceReason[] | null;
}

export interface InvoiceVariance {
  invoiceToLabel: string;
  invoiceFromLabel: string;
  invoiceToTotal: string;
  invoiceFromTotal: string;
  differenceAmount: string;
  differencePercentage: string;
  differencePercentageLabel: string;
  currency: string;
  variances: Variance[];
}

export interface InvoicesVariance {
  [invoiceId: string]: InvoiceVariance;
}

export interface CreateOneOffFundingStatementPayload {
  currency: string;
  amount: string;
  description?: string;
}

// We specifed only the fields we use here. Feel free to extend response type.
export interface CreateOneOffFundingStatementResponse {
  invoiceId: string;
}

export default {
  finalize: async (id: string) => {
    const { data } = await instance.patch(`/invoices/${id}/issue_invoice_early`);
    return { data };
  },

  cancel: async (id: string, reason: string) => {
    const { data } = await instance.post(`/invoices/${id}/cancel`, { reason });
    return data;
  },

  uncancel: async (id: string) => {
    const { data } = await instance.post(`/invoices/${id}/uncancel`);
    return data;
  },

  getInvoiceExample: async (wait = 1) => {
    const { data } = await instance.get(`/invoices/sample_pdf?noredirect=1&wait=${wait}`);
    return data;
  },

  getPendingReceipt: async (id: string, payload: any, wait = 1) => {
    const { data } = await instance.post(
      `/client_payments/receipts/${id}/preview_pdf?noredirect=1&wait=${wait}`,
      payload
    );
    return data;
  },

  getPendingGuestReceiptPdf: async (
    id: string | number,
    summaryOnly: boolean,
    linkToken: string,
    wait: number,
    includeInvoices: boolean
  ) => {
    const { data } = await instance.get<{ url: string } | { wait: boolean }>(
      `/guest/client_payments/receipts/${id}/pdf?noredirect=1&wait=${wait}&summaryOnly=${summaryOnly}&includeInvoices=${includeInvoices}&linkToken=${linkToken}`
    );
    return data;
  },

  getPendingGuestReceiptCsv: async (id: string | number, linkToken: string, wait: number) => {
    const { data } = await instance.get<string>(
      `/guest/client_payments/receipts/${id}/csv?noredirect=1&wait=${wait}&linkToken=${linkToken}`
    );
    return data;
  },

  getEarlyPayouts: async (payload: any) => {
    const { data } = await instance.get('/invoices/early_payouts', { params: payload });
    return data;
  },

  getContractorsAndEorInvoices: async (params?: FiltersForContractorsAndEorInvoices) => {
    const { data } = await instance.get<PendingContractorsAndEorInvoice[]>('invoices', {
      params: {
        ...params,
        massPayType: 'DEFAULT',
      },
    });
    return data?.filter((invoice) => {
      if (invoice.type === 'SHIELD_DEPOSIT') {
        return invoice.contract.status !== 'waiting_for_client_sign' && invoice.contract.status !== 'new';
      }
      return true;
    });
  },

  getGpInvoices: async (params?: FiltersForGpInvoices) => {
    const { data } = await instance.get<PendingGpInvoice[]>('invoices', {
      params: { ...params, contractTypes: ['gp_client_agreement'], massPayType: 'GLOBAL_PAYROLL' },
    });
    return data;
  },

  getUsInvoices: async () => {
    const { data } = await instance.get<PendingGpInvoice[]>('invoices', {
      params: { massPayType: 'US_PAYROLL' },
    });
    return data;
  },

  getInvoice: async (id: string) => {
    const { data } = await instance.get(`invoices/${id}`);
    return data;
  },

  getInvoiceExtended: async (id: string) => {
    const { data } = await instance.get(`invoices/${id}/extended`);
    return data;
  },

  updateInvoice: async (id: string, payload: Partial<IReceiptPayload>, config?: AxiosRequestConfig) => {
    const { data } = await instance.put<{ sharingEnabled: boolean }>(
      `client_payments/receipts/${id}/payment_sharing`,
      payload,
      config
    );
    return data;
  },

  prepareInvoices: async (ids: string[], config?: AxiosRequestConfig, allTeamMode?: boolean) => {
    const { data } = await instance.post<PrepareInvoicesResponse>(`/client_payments/invoices/prepare`, ids, {
      ...config,
      params: {
        ...(config?.params || {}),
        allTeamMode: allTeamMode ? 'true' : undefined,
        skipPaymentFeesCalculation: 'true',
      },
    });
    return data;
  },

  prepareShieldInvoices: async (ids: string[], msaContractId: number) => {
    const { data } = await instance.post<PrepareShieldInvoicesResponse | PrepareShieldInvoicesResponse[]>(
      'shield/invoices/prepare',
      {
        ids,
        msaContractId,
      }
    );
    return data;
  },

  deel: async () => {
    const { data } = await instance.get('/invoices/history/deel');
    return data;
  },

  getEquipmentOrCoworkingInvoices: async (
    contractId: string,
    deelInvoiceItemTypes: 'HOFY_EQUIPMENT' | 'WEWORK_ACCESS'
  ) => {
    const { data } = await instance.get('/invoices/history/deel', {
      params: {
        contractId,
        deelInvoiceItemTypes,
      },
    });
    return data;
  },

  getProcessing: async () => {
    const { data } = await instance.get('/invoices/history', { params: { statuses: ['processing'] } });
    return data;
  },

  getPaid: async () => {
    const { data } = await instance.get('/invoices/history', { params: { statuses: ['paid'] } });
    return data;
  },

  getUpcoming: async (payload: UpcomingInvoicesParams) => {
    const params = qs.stringify(payload, { skipEmptyString: true, arrayFormat: 'bracket' });
    const { data } = await instance.get(`/invoices/upcoming?${params}`);
    return data as UpcomingInvoicesData;
  },

  deletePdf: async (invoiceId: string) => {
    await instance.delete(`invoices/${invoiceId}/pdf`);
  },

  earlyPayout: async (invoiceId: string, payload = {}) => {
    const { data } = await instance.post(`invoices/${invoiceId}/early_payout`, payload);
    return data;
  },

  getHistory: async ({
    contractId,
    limit = 50,
    offset = 0,
    fromDate,
    toDate,
    statuses,
    includeFilterValues = false,
    includeShielded = false,
    excludeTypes,
    search,
    filters = {},
  }: {
    contractId?: string;
    limit?: number;
    offset?: number;
    fromDate?: any;
    toDate?: any;
    statuses?: any;
    excludeTypes?: Array<string>;
    includeFilterValues?: boolean;
    includeShielded?: boolean;
    search?: string;
    filters?: Record<string, any>;
  }) => {
    const { data } = await instance.get(`invoices/history`, {
      params: {
        contractId,
        limit,
        offset,
        fromDate,
        toDate,
        statuses,
        includeFilterValues,
        includeShielded,
        search,
        excludeTypes,
        ...filters,
      },
    });
    return data;
  },

  create: async (tokens: string[]) => {
    const { data } = await instance.post('invoices', tokens);
    return data;
  },

  createIntent: async (
    id: string,
    payload: { paymentMethodId: number; clientBalanceCurrency?: string; clientBalanceAmount?: string }
  ) => {
    const { data } = await instance.post<{ client_secret: string; accountCountry?: string }>(
      `/client_payments/invoices/${id}/create_intent`,
      payload
    );
    return data;
  },

  pay: async (
    { invoiceId, ...payload }: PostPayPayload & { invoiceId: string | number },
    config?: AxiosRequestConfig
  ) => {
    const { data } = await instance.post<PostPayResponse>(
      `/client_payments/invoices/${invoiceId}/pay`,
      payload,
      config
    );
    return data;
  },

  uploadPdf: async (invoiceId: string | number, key: string, filename: string) => {
    const { data } = await instance.post(`invoices/${invoiceId}/pdf`, {
      key,
      filename,
    });
    return data;
  },

  getPdfNoRedirect: async (invoiceId: string, wait = false) => {
    const { data } = await instance.get(`invoices/${invoiceId}/pdf?noredirect=1${wait ? '&wait=1' : ''}`);
    return data;
  },

  getPdfPreview: async (invoiceId: string) => {
    const { data } = await instance.get(`invoices/${invoiceId}/preview`);
    return data;
  },

  getBreakdownPdfNoRedirect: async (invoiceId: string, wait = false) => {
    const { data } = await instance.get(`invoices/breakdown/${invoiceId}/pdf?noredirect=1${wait ? '&wait=1' : ''}`);
    return data;
  },

  getBreakdownPreview: async (invoiceId: string) => {
    const { data } = await instance.post(`/breakdown/${invoiceId}/preview`);
    return data;
  },

  createReportCategory: async (reportType: string, values: ExpenseCategory) => {
    const { data } = await instance.post(`/invoice_report_category/${reportType}`, values, { suppressSnackbar: true });
    return data;
  },

  updateReportCategory: async (id: number, values: ExpenseCategory) => {
    const { data } = await instance.patch(`/invoice_report_category/${id}`, values, { suppressSnackbar: true });
    return data;
  },

  deleteReportCategory: async (id: number) => {
    const { data } = await instance.delete(`/invoice_report_category/${id}`);
    return data;
  },

  getExpenseCategoriesByContract: async (contractOid: string) => {
    const { data } = await instance.get(`/invoice_report_category/contract/${contractOid}`);
    return data;
  },

  fetchReceiptBeforeDueAccessStatus: async (invoiceId: string, config?: AxiosRequestConfig) => {
    const { data } = await instance.get<void>(`/receipts/${invoiceId}/pay_before_due`, config);
    return data;
  },

  payInvoiceBeforeDue: async (invoiceId: string, config?: AxiosRequestConfig) => {
    const { data } = await instance.post<void>(`/invoices/${invoiceId}/pay_before_due`, {}, config);
    return data;
  },

  payReceiptBeforeDue: async (invoiceId: string, config?: AxiosRequestConfig) => {
    const { data } = await instance.post<void>(`/receipts/${invoiceId}/pay_before_due`, {}, config);
    return data;
  },

  applyPaymentMethod: async (
    {
      paymentMethodId,
      paymentMethod,
      payload,
      invoiceId,
      clientBalanceCurrency,
      clientBalanceAmount,
    }: {
      paymentMethodId?: number | undefined;
      paymentMethod: string;
      payload?: { country: string; currency: string };
      invoiceId: number | string;
      clientBalanceCurrency?: string;
      clientBalanceAmount?: string;
    },
    config?: AxiosRequestConfig
  ) => {
    const { data } = await instance.post<ApplyPaymentMethodResponse>(
      `/client_payments/invoices/${invoiceId}/apply_payment_method`,
      {
        paymentMethod,
        paymentMethodId,
        payload,
        clientBalanceCurrency,
        clientBalanceAmount,
      },
      config
    );

    return data;
  },

  getInvoices: async (ids: string[], wait = false) => {
    const { data } = await instance.post('invoices/pdf/bulk_download', { ids, wait });
    return data;
  },

  getInvoicePdf: async (id: number) =>
    (await instance.get<{ url: string }>(`/icp/invoices/${id}/pdf?wait=1&noredirect=1`)).data.url,
  getReceipts: async (ids: string[], wait = false) => {
    const { data } = await instance.post('client_payments/receipts/pdf/bulk_download', {
      ids,
      wait,
      summaryOnly: false,
    });
    return data;
  },
  getInvoicesVarianceData: async (invoiceIds: string[]) => {
    const { data } = await instance.post<InvoicesVariance>(
      'invoices/variances',
      {
        invoiceIds,
      },
      { suppressSnackbar: true }
    );
    return data;
  },
};

export const createOneOffFundingInvoice = async (entityId: string, payload: CreateOneOffFundingStatementPayload) => {
  const { data } = await instance.post<CreateOneOffFundingStatementResponse>(
    `/invoices/one_off_funding_statement/${entityId}`,
    payload,
    {
      suppressSnackbar: true,
    }
  );
  return data;
};

interface GetInvoiceEndpoints {
  invoiceId?: string | null;
}

export const getInvoiceEndpoints = (params: GetInvoiceEndpoints = {}) => {
  const prefix = '/invoices';
  const { invoiceId } = params;

  if (!invoiceId) {
    return { getFilters: `${prefix}/filters` };
  }

  return {
    getById: `${prefix}/${invoiceId}/extended`,
    getDetailsById: `${prefix}/${invoiceId}`,
    getFilters: `${prefix}/filters`,
  };
};
