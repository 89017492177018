import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';

import type { IStores } from '@/types/Stores';

export default function useStores<StoresType = IStores>(): StoresType {
  const context = useContext(MobXProviderContext);

  return context as StoresType;
}
