import type { ChangeEvent, ReactNode } from 'react';
import { Children } from 'react';

import Flex from '@/components/Flex';

import { cn } from '@/utils/main';

interface Props {
  className?: string;
  children: ReactNode;
  space?: string | number;
  horizontal?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Stack = ({ className, children, space, horizontal, ...rest }: Props) => {
  const childrenArray = Children.toArray(children);

  if (!childrenArray.length) return null;

  return (
    <Flex className={cn(horizontal ? null : 'flex-dir-col', className)} {...rest}>
      {childrenArray
        .filter((child) => !!child)
        .map((child, i) => {
          if (i === 0) {
            return child;
          } else {
            return (
              <div className={`m${horizontal ? 'l' : 't'}-${space}`} key={i}>
                {child}
              </div>
            );
          }
        })}
    </Flex>
  );
};

export default Stack;
