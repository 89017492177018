import type { AlertType } from '@/components/PaydaysPopup';
import type { ShareholderTypes } from '@/constants/contractor';
import type { ShareholderKycStatus } from '@/constants/kyc';
import type { ProvisioningPolicyType } from '@/scenes/Create/components/ActivateGoogleWorkspaceBox/useGoogleWorkspace';
import type { EquityBoxContractCreationValue } from '@/scenes/Equity/ContractCreation/types';
import type { VariableCompensationItem } from '@/scenes/People/PersonView/VariableCompensation/types';
import type { OrgStructureField } from '@/scenes/People/Profile/components/General/EmploymentInformation/components/OrgStructureFields/utils';
import type { TimeTrackingConfig } from '@/scenes/TimeAttendance/types';
import type { EmploymentTermStatus } from '@/types/EmploymentTerm';
import type { LegalEntity, PayrollOnboardingStatus } from '@/types/LegalEntity';
import type { ShieldAgreement } from '@/types/Organization';
import type { BankDetails, HrisProfileDetailsProps } from '@/utils/contract/globalPayrollContract';
import type { CountryCode } from '@letsdeel/ui';
import type { InvoiceStatus } from '../Invoice';
import type { Company, Personal } from '../User';
import type { Eor, EorAmendmentHistory, EorPayroll, Reviewer } from './EORContract';
import type { VariableCompensation } from './VariableCompensation';

export enum ContractStatus {
  NEW = 'new',
  ONBOARDING = 'onboarding',
  ONBOARDED = 'onboarded',
  UNDER_REVIEW = 'under_review',
  WAITING_FOR_EMPLOYEE_CONTRACT = 'waiting_for_employee_contract',
  WAITING_FOR_CLIENT_SIGN = 'waiting_for_client_sign',
  WAITING_FOR_PROVIDER_SIGN = 'waiting_for_provider_sign',
  PROCESSING_PAYMENT = 'processing_payment',
  WAITING_FOR_CONTRACTOR_SIGN = 'waiting_for_contractor_sign',
  WAITING_FOR_EOR_SIGN = 'waiting_for_eor_sign',
  WAITING_FOR_EMPLOYEE_SIGN = 'waiting_for_employee_sign',
  AWAITING_DEPOSIT_PAYMENT = 'awaiting_deposit_payment',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  USER_CANCELLED = 'user_cancelled',
  REJECTED = 'rejected',
  WAITING_FOR_CLIENT_PAYMENT = 'waiting_for_client_payment',
}

export enum ContractType {
  /**deprecated */
  MILESTONES = 'milestones',
  /**deprecated */
  TIME_BASED = 'time_based',
  ONGOING_TIME_BASED = 'ongoing_time_based',
  PAY_AS_YOU_GO_TIME_BASED = 'pay_as_you_go_time_based',
  UNKNOWN = 'unknown',
  COMMISSION = 'commission',
  PAYG_MILESTONES = 'payg_milestones',
  PAYG_TASKS = 'payg_tasks',
  EOR = 'eor',
  EOR_MSA = 'eor_msa',
  EMPLOYEE = 'employee',
  GLOBAL_PAYROLL = 'global_payroll',
  PAYG_DEFAULT = 'payg_default',
  SHIELD_MSA = 'shield_msa',
  GP_CLIENT_AGREEMENT = 'gp_client_agreement',
  SERVICES_DEFAULT = 'services_default',
  HRIS_SERVICE_AGREEMENT = 'hris_service_agreement',
  NETX_FEE = 'netx_fee',
  HRIS_DIRECT_EMPLOYEE = 'hris_direct_employee',
  HOFY_STANDALONE_PAYMENT = 'hofy_standalone_payment',
  ENGAGE_MONTHLY_SUBSCRIPTION_FEE = 'engage_monthly_subscription_fee',
  BENEFITS_ADMIN_MONTHLY_PLATFORM_FEE = 'benefits_admin_monthly_platform_fee',
  MISSING_SAAS_FEES = 'missing_saas_fees',
  PREPAID_BILLING = 'prepaid_billing',
  PEO = 'peo',
  PEO_CSA = 'peo_csa',
  CONTRACTOR_OUTSIDE_DEEL = 'contractor_outside_deel',
  PREPAID_FUNDING_STATEMENT = 'prepaid_funding_statement',
}

export const ContractorContractTypes = [
  ContractType.PAY_AS_YOU_GO_TIME_BASED,
  ContractType.PAYG_DEFAULT,
  ContractType.MILESTONES,
  ContractType.PAYG_MILESTONES,
  ContractType.PAYG_TASKS,
  ContractType.ONGOING_TIME_BASED,
  ContractType.CONTRACTOR_OUTSIDE_DEEL,
  ContractType.COMMISSION,
];

export enum PaymentCycleStatus {
  ACTIVE = 'active',
  OVERDUE = 'overdue',
  UPCOMING = 'upcoming',
  COMPLETED = 'completed',
  AWAITING_PAYMENT = 'awaiting_payment',
  DELETED = 'deleted',
}

// TODO: @ivan.malets rename to InvoiceCycleScale?
export enum CycleScale {
  WEEKLY = 'weekly',
  BIWEEKLY = 'biweekly',
  SEMIMONTHLY = 'semimonthly',
  MONTHLY = 'monthly',
  CALENDAR_MONTH = 'calendar-month',
  EOR_TWICE_PER_MONTH = 'eor-twice-per-month',
  CUSTOM = 'custom',
}

export enum PaymentScale {
  HOURLY = 'hourly',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  TASK = 'custom',
}

/* ================================================================================= *
 * Auto generated interface using MOCK data                                          *
 * Some properties might be missing                                                  *
 * Using any for properties I could not find any information on                      *
 * ================================================================================= */
export type IContractStore = {
  isGeneralContract: boolean;
  hasContractEnded: boolean;
  isDeleted: boolean;
  isAccruedRefund: boolean;
  isDepositRefunded: boolean;
  isRefundingRequested: boolean;
  isEmployeeAgreement: boolean;
  isEor: boolean;
  isFixed: boolean;
  isShield: boolean;
  isGlobalPayroll: boolean;
  isPayAsYouGo: boolean;
  isPaygTasks: boolean;
  isPAYG: boolean;
  isMilestone: boolean;
  isCommission: boolean;
  hasEnded: boolean;
  isTerminationAllowedByEOR: boolean;
  isTerminationRequested: boolean;
  isTerminationUnderReview: boolean;
  isAmending: boolean;
  isSigned: boolean;
  canSignResignationLetter: boolean;
  isResignationLetterSigned: boolean;
  hasPendingAmendment: boolean;
  hasResignationLetterAttachment: boolean;
  isEmployeeOnPlatform: boolean;
  isCancellable: boolean;
  isCancelled: boolean;
  isInProgress: boolean;
  activePayroll?: EorPayroll;
  workStatement: WorkStatement;
  pendingWS?: WorkStatement;
  EORDisplayedInvoiceDate: number | null;
  proRataDetails?: any;
  hasWeWork?: boolean;
  realId?: string;
  contractType: ContractType;
  shieldAgreement?: ShieldAgreement;
  backgroundCheckData?: {
    waitingForBackGroundCheck: boolean;
  };
  address?: {
    country: string;
  };
  workspace?: ContractWorkspace;
  isWithholdingTaxSupported?: boolean;
  hrisProfileId?: string;
  draftContract?: {
    id?: string;
  };
  isShieldFromCoveragePicker?: boolean;
  equity?: EquityBoxContractCreationValue[];
  jobScopeUpdateOid: string;
  existingBackgroundCheckId?: string;
  unpaidDepositMessage?: { message: string } | null;
} & ContractResponse;

// TODO: Split into different contract types
export interface ContractResponse {
  id: string;
  eorContractOid?: string;
  name: string;
  type: ContractType;
  currency: string;
  workStatements: WorkStatement[];
  paymentCycles: PaymentCycle[];
  bankInfo?: BankDetails;
  offCycles: any[];
  overdueCount: number;
  status: string;
  eorOnboardingStatus: string;
  Seniority?: any;
  backgroundCheckData?: {
    waitingForBackGroundCheck: boolean;
  };
  client: Entity;
  contractor: Entity;
  amending: boolean | null;
  pdf: string | null;
  canBeCancelled: boolean;
  canBeRejected: boolean;
  canBeReinstated: boolean;
  pendingWorkStatement: boolean;
  customFields: any[];
  country: string;
  cancelledAt: string | null;
  clientSignature: string;
  clientSignedAt: string;
  commissions: any[];
  completedAt: string | null;
  completedPaymentCycles: string;
  completionDate: string | null;
  completionPlainDate: string | null;
  contractorSignature: string;
  contractorSignedAt: string;
  missingOnboardingDataRejectReason?: string;
  activePayroll?: EorPayroll;
  createdAt: string;
  data: {
    additionalInfo?: { [key: string]: string };
    quoteAdditionalFields?: { [key: string]: string };
    terminator?: any;
    revokedAt?: Date;
    docsAreMandatory?: boolean;
    requestOptionalDocuments?: boolean;
  };
  quoteAdditionalFields?: { [key: string]: string };
  description: string;
  effectiveDate: string;
  effectivePlainDate?: string;
  isPaidOutsideOfDeel: boolean;
  firstSign: FirstSign;
  invitedClientEmail: string;
  invitedContractorEmail: string | null;
  isArchived: boolean;
  isClient: boolean;
  isDeleted: boolean;
  isContractor: boolean;
  isCreator: boolean;
  isCreatorContractor: boolean;
  isMainIncome: boolean;
  jobTitle: JobTitle;
  needsReportApproval: boolean;
  note: string | null;
  organization: {
    id: number;
    name: string;
  };
  previousCycleDates: PreviousCycleDates;
  seniority: string | null | { id: string; name: string };
  signedCompletionDate: string | null;
  signedDate: string;
  specialClause: string | null;
  state: string | null;
  taxForm: any;
  team: Team;
  teamId: number;
  terminationNoticeDays: number;
  timeOffReports: any[];
  timeOffs: any[];
  timeTracking?: TimeTrackingConfig;
  timezone: string;
  total: Total;
  vat: any[];
  wasCreatedByProfileParty: boolean;
  withholdingTaxPercentage: number | null;
  scope: string;
  earlyPayoutInvoices: any[];
  finalPayment?: FinalPayment;
  EORSignature: string | null;
  EORSignedAt: string | null;
  employeeSignature: string | null;
  employeeSignedAt: string | null;
  additionalInfo: { [key: string]: any };
  employee: { company: Company; id: number; name: string; picUrl: string | null; jobTitle?: string; timezone?: string };
  employment: {
    id: string;
    country: string;
    deelStartDate?: string;
    employeeNumber?: string;
    ptoYearlyAllowance?: number;
    ptoAccrualStartDate?: string;
    employmentTerms?: {
      startDate?: string;
      status?: EmploymentTermStatus;
      payCurrency?: string;
      jobTitle?: string;
      scale?: string;
      effectiveDate?: string;
      rate?: string;
      division?: string;
      department?: string;
      type?: string;
      fullTimePercentage?: number;
    }[];
    employee?: {
      firstName: string;
      lastName?: string;
      birthDate?: string;
      nationality?: string;
    };
  };
  employerDetails: EmployerDetails;
  rejectReason: string | null;
  template: Template;
  templateId: number | null;
  cutoffMessage: CutoffMessage;
  salaryChangeMessage: SalaryChangeMessage;
  healthPlan: any;
  depositAmountBeforeContractSigned: number;
  clientLegalEntity?: ClientLegalEntity;
  clientLegalEntityId?: number;
  contractorLegalEntity?: any;
  legalEntity?: LegalEntity | null;
  canSkipDeposit: boolean;
  isProcessing: boolean;
  eorAmendmentHistory: EorAmendmentHistory[];
  dpas: Dpas;
  dpaTemplates: any[];
  milestones: Milestone[];
  eor: Eor;
  integration?: { source: string; url: string };
  hrisInfo?: HrisInfoType | null;
  contractorHrisProfile?: {
    firstName: string;
    lastName: string;
    id: number;
    oid: string;
  };
  HrisProfile?: HrisProfileDetailsProps;
  oid?: string;
  address?: {
    country: string;
    state: string;
    city?: string;
    street?: string;
    zip?: string;
  };
  shieldAgreement?: ShieldAgreement;
  initialEffectivePlainDate: string;
  contractorExpectedFirstName: string;
  contractorExpectedLastName: string;
  expectedContractorEmail: string;
  nextAvailablePayrollEvent?: { start: string; end: string };
  currentPayrollEvent?: { start: string; end: string; reportSubmittedAt: string | null };
  poNumber: string;
  isRevertAvailable: boolean;
  documentTemplateId?: string;
  documentTemplate?: {
    id: string;
  };
  contractOid?: string;
  hrisServiceProvider?: {
    id: string;
    name: string;
  };
  isForeignCurrencyContract: boolean;
  creator?: {
    name?: string;
    firstname?: string;
    email?: string;
  };
  variableCompensations?: VariableCompensationItem[];
  hrisIsActive?: boolean;
  canSubmitExpenses?: boolean;
  invitedContractorHasContractorProfile?: boolean;
  documents?: { id: string; title: string; filename: string }[];
  sow?: { id: number };
  existingBackgroundCheckId?: string;
  isEor?: boolean;
  backgroundCheckIncluded?: boolean;
  isCommission?: boolean;
  isCancellationAllowed?: boolean;
  attachmentFilename?: string;
  attachment?: string | null;
}

export interface SigningBonusInvoice {
  amount: string;
  employeePaymentStatus: string;
  invoiceId: string;
  dueDate: string;
  id: number;
  paidAt: string;
  processedAt: string;
  status: InvoiceStatus;
}

export interface ContractWorkspace {
  googleWorkspaceEmail: string;
  provisioningPolicy: ProvisioningPolicyType;
  workEmailCreationDate: string;
  workEmail?: string;
  isGoogleWorkspaceEnabled: boolean;
  domain: string;
  integrationId: number;
}

export interface SignatureType {
  firstName: string;
  lastName: string;
  signedAt: Date;
  status: string;
}

export interface HrisInfoType {
  externalContract?:
    | {
        file: File;
        key: string;
      }
    | string;
  vacationYearlyPolicy?: number;
  vacationAccrualStartDate?: string;
  endDate?: string;
  startDate?: string;
  originalStartDate?: string;
  employmentType?: 'FULL_TIME' | 'PART_TIME';
  employeeNumber?: string;
  partTimePercentage?: number;
  contractTermType?: string;
  clientSignature?: SignatureType | string;
  contractorSignature?: SignatureType;
  signedByDeel?: boolean;
  unlimitedPTO?: boolean;
  hrisProfileOid?: string;
  email?: string;
  variableCompensations?: VariableCompensationItem[];
  orgStructures?: OrgStructureField[];
  workerId?: string;
}

export interface FinalPayment {
  start: string;
  end: string;
  reports: Report[];
  customAmount: string;
}

export type Agreement = {
  id: number;
  name: string;
  shieldedContracts: number;
  msaContract: {
    id: number;
    oid: string;
    status: string;
  };
};

export interface Entity {
  firstname: string;
  id: number;
  username: string;
  name: string;
  email: string;
  picUrl: string | null;
  company: ClientCompany | ContractorCompany;
  timezone: string;
  country: string;
  jobTitle: string;
}

export interface EmployerDetails {
  zip: string;
  city: string;
  type: string;
  phone: Phone;
  street: string;
  province?: string;
  citizen: EmployerCitizen;
  country: string;
  invoice: Invoice;
  lastName: string;
  personal: Personal;
  timezone: string;
  birthDate: string;
  firstName: string;
  entityType: string;
  registration: {};
  shareholders: Shareholder[];
  legalCompanyName: string;
  registeredNumber: string;
  shareHolderAmount: string;
  saveInvoiceAddress: boolean;
  savePersonalAddress: boolean;
}

export interface EmployerCitizen {
  label: string;
  value: string;
  currency: Currency;
}

export interface Currency {
  label: string;
  value: string;
  symbol: string;
}

export interface ClientCompany {
  name?: string;
  type: string;
  registration: {};
  invoice: Invoice;
  entityType: string;
  country: string;
  province: string;
  street: string;
  city: string;
  zip: string;
  legalCompanyName: string;
  shareholders: any[];
  phone: string;
  timezone: string;
  personal: Personal;
  postal?: Address;
}

export interface ContractorCompany {
  zip: string;
  city: string;
  type: string;
  phone: Phone;
  street: string;
  country: string;
  invoice: Invoice;
  personal: Personal;
  postal?: Address;
  province: string;
  timezone: string;
  entityType: string;
  registration: {};
  shareholders: Shareholder[];
  legalCompanyName: string;
  registeredNumber: string;
  shareHolderAmount: string;
  saveInvoiceAddress: boolean;
  savePersonalAddress: boolean;
}

export interface Invoice {
  legalCompanyName: string;
  firstName: string;
  lastName: string;
  street: string;
  city: string;
  country: string;
  zip: string;
  phone: string;
  vatId: string;
}

export interface ClientLegalEntity {
  id: number;
  publicId: string;
  name: string;
  legalType: string;
  entityType: string;
  registrationNumber: string | null;
  vatId: string;
  useAddressAsPostalAddress: boolean;
  address: Address;
  postalAddress: Address;
  registrationAddress: string | null;
  isDeelShield: boolean;
  isEorLocalPaymentsEnabled: boolean;
  eorLocalPaymentsPercentage: string | null;
  payrollOnboardingStatus: PayrollOnboardingStatus;
  isDraft?: boolean;
}

export interface Address {
  zip: string;
  city: string;
  state: string | null;
  street: string;
   
  country: CountryCode;
  province: string | null;
}

export interface Phone {
  code: string;
  number: string;
  country: string;
}

export interface Shareholder {
  id: string;
  name: string;
  entityName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  citizenship?: string;
  countryOfRegistration?: string;
  kyc: ShareholderKycStatus;
  entity: any;
  type: ShareholderTypes;
  parentShareholderId: any;
  percentage?: number;
  Screening?: Screening;
  CustomVerificationScreening?: {
    screeningAt: string;
    status: string;
    statusUpdatedAt: string;
  };
  IdentityProfile?: { id: number };
  ShareholderBeneficialOwners: ShareholderBeneficialOwner[];
}

export interface Screening {
  status: string;
}

export interface ShareholderBeneficialOwner {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  citizenship: string;
  Screening?: Screening;
  CustomVerificationScreening?: {
    screeningAt: string;
    status: string;
    statusUpdatedAt: string;
  };
}

export interface CutoffMessage {
  message: string;
  type: AlertType;
  additionalInfo: AdditionalInfo;
}

export interface SalaryChangeMessage {
  message: string;
}

export interface AdditionalInfo {
  messages: string[];
  info: Array<Info[]>;
}

export interface Info {
  label: string;
  value: string;
}

export interface Dpas {
  count: number;
  rows: any[];
  currentPrice: CurrentPrice;
}

export interface CurrentPrice {
  value: number;
  currency: string;
}

export interface User {
  id: number;
  name: string;
  company: ContractorCompany;
  email: string;
}

export interface Adjustment {
  type: string;
  amount: string;
  label: string;
  value: number;
}

export interface Template {
  id: string;
  title: string;
  createdAt: string;
}

export interface Seniority {
  id: number;
  name: string;
  enabled: boolean;
  level: number;
}

export enum FirstSign {
  Client = 'client',
  Contractor = 'contractor',
}

export interface JobTitle {
  name: string | null;
  id?: string | null;
}

export interface PaymentCycle {
  id: number;
  end: string;
  start: string;
  status: string;
  due: string;
  total: CycleTotal;
  payrolls?: EorPayroll[];
  isLocked?: boolean;
  reports: Report[];
  isProcessing: boolean;
  invoice?: PaymentCycleInvoice;
  invoices?: PaymentCycleInvoice[];
  invoiceIssueDate?: string;
}

export interface PaymentCycleInvoice {
  id: string;
  status: PaymentStatus;
  internalId: number;
  invoiceLabel: string;
  approvedBy: any[];
  currency: string;
  issuedAt: string;
  dueDate: string;
}

export enum Status {
  Approved = 'approved',
  Pending = 'pending',
  Decline = 'declined',
  NotPayable = 'not_payable',
  New = 'new',
  Processing = 'processing',
  Paid = 'paid',
}

export type PaymentStatus = 'open' | 'pending' | 'approved' | 'paid' | 'processing' | 'denied' | 'declined';

export interface ReportProRata {
  calculationType: 'CUSTOM_AMOUNT' | 'WORK_DAYS' | 'CALENDAR_DAYS';
  customAmount?: string | number;
  cycleWorkDays?: string | number;
  dailyRate?: number | string;
  cycleEnd?: string;
  cycleStart?: string;
  total?: number | string;
  workWeekEnd?: number;
  workWeekStart?: number;
  remainingDays?: number;
  completionDate?: string;
  cycleEndPlainDate?: string;
  cycleStartPlainDate?: string;
  originalCycleStartPlainDate?: string;
}

export interface ReportFinalPayment {
  calculationType: 'CUSTOM_AMOUNT' | 'WORK_DAYS' | 'CALENDAR_DAYS';
  filename?: string;
  completionDate: string;
  originalCycleStart: string;
  customAmount?: string | number;
  cycleWorkDays?: string | number;
  dailyRate: number | string;
  cycleEnd: string;
  cycleStart: string;
  rate: number | string;
  total: number | string;
  workWeekEnd: number;
  workWeekStart: number;
  remainingDays: number;
}

export interface ReportApprover {
  name: string;
  email: string;
  approved: boolean;
}

export interface Report {
  id: number;
  cycleId?: number;
  status: Status;
  description: string;
  amount: number;
  bonus: string;
  type: string;
  when: string;
  currency: string;
  filename: null | string;
  tax?: any;
  total: string;
  title: string | null;
  reviewReason: string | null;
  reviewedAt: string | null;
  invoiceId: number | null;
  accountingIntegrationsData: any;
  createdAt?: string;
  offCycleId: string | null;
  reviewer?: Reviewer | null;
  reporter?: Reporter;
  timeOffs?: any[];
  payrollIds: number[];
  percentage: number;
  recurring: boolean;
  isRecurring: boolean;
  proRata?: ReportProRata;
  finalPayment?: ReportFinalPayment;
  isFinalPaymentDraft?: boolean;
  approvers?: ReportApprover[];
  isSealed?: boolean;
}

export interface Reporter {
  id: number;
  name: string;
  picUrl: string | null;
  profileType: FirstSign;
  employee?: boolean;
}

export interface CycleTotal {
  amount: string;
  bonus: string;
}

export interface PreviousCycleDates {
  start: string;
  startPlainDate: string;
  end: string;
  due: string;
}

export interface Team {
  id: number;
  name: string;
  picUrl: string | null;
  company: TeamCompany;
  country: string;
  invoiceReviewDays: number;
  massApprove: any;
  Organization: ContractOrganization;
}

export interface ContractOrganization {
  id: number;
  name: string;
  canSkipDeposit: boolean;
  isExemptFromDeposit: boolean;
  eorAccrualMonths: number;
  eorDepositMonths: string;
  eorDepositType: string;
  managementFeeUSD: string;
  skipAdjustmentsUpfrontPayment?: boolean;
  shouldSignDsAddendum?: boolean;
  icOrganizationSettings: IcOrganizationSettings;
}

export interface IcOrganizationSettings {
  milestones: {
    canContractorAddMilestones: boolean;
    canContractorMarkMilestonesAsComplete: boolean;
    enableMilestoneDueDateReminder: boolean;
    milestoneReminderDueDays?: number;
  };
  terminations: {
    canContractorSetTermination: boolean;
  };
  payg?: {
    canClientSubmitPaygWorkReports: boolean;
    canContractorSubmitPaygWorkReports: boolean;
    enablePaygDueDateReminder: boolean;
    paygReminderDueDays?: number;
  };
}

export interface TeamCompany {
  type: string;
  registration: {};
  invoice: Invoice;
  entityType: string;
  country: string;
  province: string;
  street: string;
  city: string;
  zip: string;
  legalCompanyName: string;
}

export interface Total {
  active: CycleTotal;
  overdue: CycleTotal;
  upcoming: CycleTotal;
  awaitingPayment: CycleTotal;
  reports: Reports;
  amount: string;
}

export interface Reports {
  pending: string;
}

export interface HourlyReportPreset {
  hourlyReportRootPreset: { id: string };
  description?: string;
  id: string;
  rate: number;
  title?: string;
}

export interface WorkStatement {
  id?: string;
  signedAt: string;
  version: number;
  status: string;
  currency: string;
  rate: number;
  firstPayment: number | null;
  scale: PaymentScale;
  customName: string | null;
  effectiveDate: string;
  effectivePlainDate: string;
  toBeAppliedDate: string;
  toBeAppliedTimestamp?: string;
  completionDate: string;
  completionPlainDate: string;
  terminationNoticeDays: number;
  scope: string;
  specialClause: string | null;
  cycleScale: CycleScale.WEEKLY | CycleScale.BIWEEKLY | CycleScale.SEMIMONTHLY | CycleScale.MONTHLY;
  cycleEnd: number | string | null;
  cycleEndType: string | null;
  paymentDueType: string;
  paymentDueDays: number;
  payBeforeWeekends: boolean;
  firstPayDate: string;
  firstPayPlainDate?: string;
  clientSignedAt: string | null;
  shieldedClientSignStatusChangedAt: string | null;
  contractorSignedAt: string;
  createdAt: string;
  firstCycleDay: string | null;
  secondCycleDay: string | null;
  clientSignature: string;
  clientId: number;
  jobTitle: JobTitle;
  jobTitleName: null | string;
  jobTitleId: null | string;
  seniority: { id: string | null; name?: string | null };
  invite?: {
    /**FIX: different format from different endpoints*/
    id?: string;
    delegatee: { email: string; name: string };
    /**FIX: some endpoints return invitee, some invitee*/
    inviter: { email: string; name: string };
    /**FIX: some endpoints return invitee, some invitee*/
    invitee?: { email: string; name: string };
  };
  attachment?: string | null;
  proRata?: ReportProRata;
  finalPayment?: ReportFinalPayment;
  attachmentFilename?: string;
  attachmentUpdatedAt?: string;
  attachmentUrl?: string;
  lastEditedByProfile?: Reporter;
  lastEditedAt?: string;
  signedByClientProfile?: { email: string; id: number; name: string; picUrl: null | string };
  shieldedClientSignStatusChangedByProfile?: { email: string; id: number; name: string; picUrl: null | string };
  signedByContractorProfile?: { email: string; id: number; name: string; picUrl: null | string };
  signStatus?: string;
  clientSignStatus?: string;
  contractorSignStatus?: string;
  contractorSignStatusChangedAt?: string;
  contractorSignNotes?: string;
  isShielded?: boolean;
  shieldedClientSignStatus?: string;
  shieldedClientSignedAt?: string | null;
  signAvailability: string;
  variableCompensations: VariableCompensation[];
  hourlyReportPresets?: HourlyReportPreset[];
  shieldWorkStatement: {
    SignedByShieldedClientProfile: {
      name: string;
      email: string;
      profilePublicId: string;
    };
    shieldedClientSignature: string;
    shieldedClientSignedAt: string;
  } | null;
}

export enum HofyContractType {
  EOR = ContractType.EOR,
  FIXED = 'fixed', // for fixed rate, pay as you go and milestone contracts
  HRIS_DIRECT_EMPLOYEE = ContractType.HRIS_DIRECT_EMPLOYEE,
  GLOBAL_PAYROLL = ContractType.GLOBAL_PAYROLL,
  PEO = ContractType.PEO,
  SHIELD_MSA = ContractType.SHIELD_MSA,
}

export enum MilestoneStatus {
  NEW = 'new',
  PENDING = 'pending',
  APPROVED = 'approved',
}

export interface Milestone {
  amount: string;
  approveRequested: boolean;
  approvers?: ReportApprover[];
  createdAt: string;
  currency: string;
  description: string;
  filename: string | null;
  id: string;
  invoice: { id: string; internalId: number; isSealed: boolean; label: string; status: InvoiceStatus } | null;
  reporter?: Reporter;
  reviewer: { id: number; name: string } | null;
  reviewedAt: string | null;
  status: MilestoneStatus;
  title: string;
  dueDate?: string | null | Date;
}
