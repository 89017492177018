import instance from '@/utils/api/instance';

export enum ExpireDays {
  OneWeek = 7,
  TwoWeeks = 14,
  ThreeWeeks = 21,
  FourWeeks = 28,
}

export const DEFAULT_EXPIRY_DAYS = ExpireDays.OneWeek;

export default {
  async deleteTrustedDevice(device?: string) {
    const { data } = await instance.delete(`trusted_devices${device ? `/${device}` : ''}`, { withCredentials: true });
    return data;
  },

  async updateExpireDate(expiryDays: ExpireDays) {
    const { data } = await instance.post(
      `trusted_devices/update_expiry_days`,
      { expiryDays },
      { withCredentials: true }
    );
    return data;
  },
};
