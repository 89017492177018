import { useCallback } from 'react';
import useStores from '@/hooks/useStores';
import uniq from 'lodash/uniq';

const useEntityRoles = () => {
  const { user } = useStores();

  const getEntityPermissionSets = useCallback(
    (entityId: string | null) => {
      if (entityId === null) {
        const permissionSets = uniq(
          user?.organization?.legalEntities?.map(({ permissionSets }) => permissionSets).flat()
        );
        return permissionSets || [];
      }

      const entity = entityId ? user?.organization?.legalEntities?.find(({ id }) => id === entityId) : null;

      if (!entity) return [];

      return entity.permissionSets || [];
    },
    [user?.organization?.legalEntities]
  );

  return { getEntityPermissionSets };
};

export default useEntityRoles;
