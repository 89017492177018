export enum FeatureFlags {
  HofyExplanationExperimentId = 'hofyExplanationExperimentId',
  EorShorterSignUpExperimentId = 'eorShorterSignUpExperimentId',
  OnboardingFrameworkV2 = 'onboardingFrameworkV2',
  TaxIdCountryList = 'deelCard.taxIdCountryList',
  DeelCardPoa = 'deel_card_poa',
  DisplayProofAddressBanner = 'deelCard.displayProofAddressBanner',
  HideReconfirmPoaDialog = 'deelCard.hideReconfirmPoaDialog',
  ShowReplacementCards = 'deelCard.showReplacementCards',
  ShowReplacementCardsByProfileId = 'deelCard.showReplacementCardsByProfileId',
  DisplayPreOrderBanner = 'deelCard.displayPreOrderBanner',
  DisplayAddFundsFromAdvance = 'deelCard.displayFundFromAdvance',
  DisablePhysicalCardOrdering = 'deelCard.disablePhysicalCardOrdering',
  CardOverviewRefresh = 'deelCard.cardOverviewRefresh',
  LimitPhyCountriesOnlyToDeeler = 'deelCard.limitPhyCountriesOnlyToDeeler',
  DeelCardEor = 'deelCard.eor',
  DeelCardAlviere = 'deelCard.alviere',
  EarnedWageAccessMainFeature = 'earnedWagedAccessMainFeature',
  AdvanceSplitYourPayCheck = 'advance.splitYourPayCheck',
  AdvanceAccountUnsubscribedList = 'advance.accountUnsubscribedList',
  HideAdvanceEligibilityCriteria = 'advance.hideEligibilityCriteria',
  AdvanceEarlyExternalRepaymentEnabled = 'advance.earlyExternalRepayment.enabled',
  AdvanceEarlyExternalRepaymentDisabledCountries = 'advance.earlyExternalRepayment.disabledCountries',
  AdvanceNoLongerAvailableForUserList = 'advance.noLongerAvailableForUserList',
  AdvanceRevamp = 'advance.revamp',
  IsHRISIdentityVerificationEnabled = 'isHRISIdentityVerificationEnabled',
  MassOnboardingHrisDirectEmployee = 'massOnboardingHrisDirectEmployee',
  TrustedDevices = 'platformTrustedDevice',
  MFARecommendation = 'platformMfaRecommendation',
  ClientProductOfInterest = 'isClientProductOfInterestEnabled',
  NewAccountSettings = 'newAccountSettings',
  recaptchaDisabledOrganizations = 'recaptchaDisabledOrganizations',
  captchaEnabledRoutes = 'platform.captchaEnabledRoutes.routes',
  disableCaptcha = 'platform.disableCaptcha',
  CustomRoles = 'platformCustomRoles',
  CustomRolesOrgScope = 'platformCustomRolesOrganizationScope',
  CustomRolesEntityScope = 'platformCustomRolesEntityScope',
  GPEntityRolesEnabled = 'gpEntityRolesEnabled',
  ResellerEndClientRole = 'resellerEndClientRole',
  OrgStructureRolesEnabled = 'platformOrgStructureScopeRoles',
  EmployeeAppForOrgLevel = 'globalPayroll.employeeAppForOrgLevel',
  i9Organizations = 'kyc.i9.directEmployees.allowedOrganizations',
  MobilityDeelSolutions = 'mobility.deelSolutions',
  MobilityEorVisaApplicationCase = 'mobility.eorVisaApplicationCase',
  ComplianceDocumentsTracker = 'complianceDocumentsTrackerEnabled',
  DocumentsOverwriteCustomDocumentPermission = 'overwriteManageCustomDocumentsPermission',
  CustomImmigrationDocument = 'mobility.customImmigrationDocument',
  InvoiceImmutabilityBanner = 'contracts.invoiceImmutabilityBanner',
  eorVsEntityService = 'eorVsEntityService',
  HRIS_Amendment = 'isHRISAmendmentsEnabled',
  GP_Amendment = 'isGPAmendmentsEnabled',
  TimeAttendanceOnCallEnabledFor = 'timeAttendanceOnCallEnabledFor',
  TimeAttendanceOvernightCountryList = 'timeAttendance.overnightCountryList',
  TimeAttendanceKITEnabledFor = 'timeAttendanceKITEnabledFor',
  timeAttendanceOnCallLateSubmissionEnabledFor = 'timeAttendanceOnCallLateSubmissionEnabledFor',
  timeAttendanceTimeInconsistenciesEnabledFor = 'timeAttendanceTimeInconsistenciesEnabledFor',
  TimeAttendanceWithRatePolicies = 'timeAttendance.ratePolicies.enabled',
  TimeAttendanceEorWorkScheduleCountriesEnabled = 'timeAttendance.TimeAttendanceEorWorkScheduleCountriesEnabled',
  TimeAttendanceRestDaysEnabled = 'timeAttendanceRestDaysEnabledFor',
  TimeAttendanceSubmitShiftCSAT = 'timeAttendance.submitShiftCSAT.enabled',
  TimeAttendanceKITAvailabilityCountriesEnabled = 'timeAttendance.TimeAttendanceKITAvailabilityCountriesEnabled',
  TimeAttendanceBlockClientFromAddingHoursCountryList = 'timeAttendance.blockClientFromAddingHoursCountryList',
  TimeTrackingTimeClockEnabled = 'timeTrackingTimeClockEnabled',
  DeelFillableTestOrgs = 'deelFillable.enableTestModeOrgs',
  isDeelCalendarEnabled = 'isDeelCalendarEnabled',
  isEquipmentNewChipEnabled = 'newEquipment.isNewChipEnabled',
  isKeptStatusEnabled = 'newEquipment.isKeptStatusEnabled',
  customDomainWindowTitleEnabledFor = 'customDomains.windowTitle.enabledFor',
  scopeOfWorkV2 = 'framework.ic.scopeOfWorkV2',
  inviteExternal = 'inviteExternalPerson',
  gpMassAmendments = 'framework.gp.massAmendments',
  hrisMassAmendments = 'framework.hris.massAmendments',
  peoEnableElectionFlow = 'peoEnableElectionFlow',
  PEOEnableAutomaticPayroll = 'PEOEnableAutomaticPayroll',
  PEOEnableGarnishments = 'PEOEnableGarnishments',
  PEOEnableTerminationFlow = 'PEOEnableTerminationFlow',
  dataGridCustomFieldsEnabledForAll = 'dataGridCustomFieldsEnabledForAll',
  dataGridCustomFieldsEnabledFor = 'dataGridCustomFieldsEnabledFor',
  dataGridOrgStructureEnabledFor = 'dataGridOrgStructureEnabledFor',
  dataGridWorkerRelationsEnabledFor = 'dataGridWorkerRelationsEnabledFor',
  EnableI9Validation = 'peoEnableI9Validation',
  peoOpenBenefitsOnIframe = 'peoOpenBenefitsOnIframe',
  visaSupportedCountries = 'immigration.visaSupportedCountries',
  contractsEnabledWorkLocation = 'contractsEnabledWorkLocation',
  peopleListDataGridDisabledFor = 'peopleListDataGridDisabledFor',
  HofyTerminationFlow = 'leasedEquipment.hofyTerminationFlow.betatesters',
  ProvidedEquipmentTerminationFlow = 'providedEquipment.terminationFlow.betatesters',
  peoProvidedEquipment = 'providedEquipment.peo.betatesters',
  ShowLinkToReportInMassPay = 'showLinkToReportInMassPay',
  ShowInvoicePdfPreviewInMassPay = 'showInvoicePdfPreviewInMassPay',
  peoNewRUFlow = 'peoNewRUFlow',
  peoDocsCollectionEnabled = 'peoDocsCollectionEnabled',
  peoDocsCollectionStateForm = 'peoDocsCollectionStateForm',
  customFieldsPrivacyEnabledFor = 'customFieldsPrivacyEnabledFor',
  customFieldsImprovementsEnabledFor = 'customFieldsImprovementsEnabledFor',
  contractsEnableAutofill = 'contractsEnableAutofill',
  TrackerProcesses = 'trackerProcesses',
  PremiumPricing = 'contracts.premiumPricing',
  HeartRelocationEnabled = 'heartRelocationEnabled',
  MigrationInfoPopupEnabled = 'migrationInfoPopupEnabled',
  BlockActionsOnMigratedCasesClient = 'blockActionsOnMigratedCasesClient',
  DisableOldUISwitch = 'disableOldUISwitch',
  // Benefits Feature Flags
  CLIENT_OPEN_ENROLLMENT = 'benefitsClientOpenEnrollment',
  CLIENT_PROVIDERS_MANAGEMENT = 'benefitsClientProvidersManagement',
  CLIENT_QLE = 'benefitsClientQLE',
  CLIENT_BENEFITS = 'benefitsClientBenefits',
  BENEFITS_CLIENT_APP = 'benefitsClientApp',
  BENEFITS_CLIENT_APP_OVERVIEW_TAB = 'benefitsClientAppOverviewTab',
  BENEFITS_CLIENT_APP_CARRIER_AND_PLANS_TAB = 'benefitsClientAppCarrierAndPlansTab',
  BENEFITS_CLIENT_APP_COMPANY_TAB = 'benefitsClientAppCompanyTab',
  BENEFITS_CLIENT_APP_EMPLOYEES_TAB = 'benefitsClientAppEmployeesTab',
  BENEFITS_CLIENT_APP_CHANGE_LOGS_TAB = 'benefitsClientAppChangeLogsTab',
  BENEFITS_CLIENT_APP_FULFILLMENT_TAB = 'benefitsClientAppFulfillmentTab',
  BENEFITS_CLIENT_APP_REPORTS_TAB = 'benefitsClientAppReportsTab',
  BENEFITS_CLIENT_APP_QLE_TAB = 'benefitsClientAppQLETab',
  BENEFITS_CLIENT_APP_EMPLOYEE_DETAILS = 'benefitsClientAppEmployeeDetails',
  BENEFITS_CLIENT_APP_EMPLOYEE_DETAILS_OVERVIEW_TAB = 'benefitsClientAppEmployeeDetailsOverviewTab',
  BENEFITS_CLIENT_APP_EMPLOYEE_DETAILS_COVERAGES_TAB = 'benefitsClientAppEmployeeDetailsCoveragesTab',
  BENEFITS_CLIENT_APP_EMPLOYEE_DETAILS_ACTIVITY_LOG_TAB = 'benefitsClientAppEmployeeDetailsActivityLogTab',
  BENEFITS_CLIENT_APP_EMPLOYEE_DETAILS_ELIGIBILITY_TAB = 'benefitsClientAppEmployeeDetailsEligibilityTab',
  DISPLAY_BENEFITS = 'benefitsDisplayBenefits',
  DISPLAY_EMPLOYEE_DEPENDENTS = 'benefitsDisplayEmployeeDependents',
  EMPLOYEE_BENEFITS = 'benefitsEmployeeBenefits',
  EMPLOYEE_QLE = 'benefitsEmployeeQLE',
  EOR_EMPLOYEE_QLE = 'benefitsEOREmployeeQLE',
  PEO_BENEFITS = 'benefitsPeoBenefits',
  SHOW_BENEFIT_DETAILS_MODAL_DISCLAIMER = 'benefitsShowBenefitDetailsModalDisclaimer',
  SHOW_BENEFIT_DETAILS_MODAL_DISCLAIMER_FIRST_MESSAGE = 'benefitsShowBenefitDetailsModalDisclaimerFirstMessage',
  SHOW_BENEFIT_DETAILS_MODAL_DISCLAIMER_SECOND_MESSAGE = 'benefitsShowBenefitDetailsModalDisclaimerSecondMessage',
  SHOW_PROVIDER_PLAN_COMPARISON = 'benefitsShowProviderPlanComparison',
  BENEFITS_UNIFIED_ENROLLMENT = 'benefitsUnifiedEnrollment',
  // -----
  MULTI_RATE_CONTRACTS = 'multirateContracts',
  CONTRACT_EXTENSION = 'contractExtension',
  peoDisableArbitrationAgreement = 'peoDisableArbitrationAgreement',
  kycFlowEnabledFor = 'kycFlowEnabledFor',
  localizedPayrollEnabledFor = 'localizedPayrollEnabledFor',
  hideLocationSectionsFor = 'hideLocationSectionsFor',
  PeoEmployeeMultipleBankAccounts = 'peoEmployeeMultipleBankAccounts',
  hiringWorkerAssessment = 'shieldHiringWorkerAssessment',
  PeoUsBannerEnabled = 'peoUsBannerEnabled',
  PeoHideGlobalPayrollFor = 'peoHideGlobalPayrollFor',
  PeoCsaContractDetailsEnabled = 'peoCsaContractDetailsEnabled',
  shieldBannerUpgradeExperimentEnabled = 'shieldBannerUpgradeExperimentEnabled',
  PeoEmployeeMineralTask = 'peoEmployeeMineralTask',
  PeoClientMineralTask = 'peoClientMineralTask',
  PeoMineralTaskDisabledFor = 'peoMineralTaskDisabledFor',
  OptimizelyExperimentDisabled = 'optimizely.disabled',
  WorkerOrgSwitcherEnabledFor = 'workerOrgSwitcherEnabledFor',
  HrisNewProfileEnabledFor = 'hrisNewProfileEnabledFor',
  enableDataChangePolicies = 'enableDataChangePolicies',
  enablePersonalInformationModificationReason = 'enablePersonalInformationModificationReason',
  RebrandEnabledOnPublicRoutes = 'home.redesign.v1.publicRoutes',
  RejectedWithdrawalsBannerEnabled = 'withdrawals.showRejectedWithdrawalsBanner',
  UpgradeToEorExperimentId = 'eor.upgradeToEorExperimentId',
  OrgChartGroupByCustomField = 'orgChartSortBarEnabledFor',
  OrgStructuresSettings = 'orgStructuresSettingsEnabledFor',
  pendingRequests = 'pendingRequests',
  TimesheetsApprovalFlow = 'approvalFlow.timesheets',
  ChangeTimesheetsApprovalPolicy = 'approvalFlow.changeTimesheetsApprovalPolicy',
  massOnboardingNewErrorHandlingEnabledFor = 'massOnboardingNewErrorHandlingEnabledFor',
  newUIWorkerOrgSwitcherEnabledFor = 'newUIWorkerOrgSwitcherEnabledFor',
  newPersonalAccountSettings = 'newPersonalAccountSettings',
  directManagerHomeWidget = 'directManagerHomeWidget',
  coworkerPermissions = 'coworkerPermissions',
  hideSeniorityLevelProfile = 'hideSeniorityLevelProfile',
  fuzzyMatchingEnabledFor = 'fuzzyMatchingEnabledFor',
  demographicFieldsPerCountry = 'demographicFieldsPerCountry',
  supportEscalationWarning = 'supportEscalationWarning',
  myProfileRedesign = 'myProfileRedesign',
  // Payouts Feature Flags
  AstropayWithdrawals = 'withdrawals.astropay',
  DolarAppWithdrawals = 'withdrawals.dolarapp',
  SupportDolarApp = 'dolarapp',
  RefundRecallWithdrawal = 'refundRecallWithdrawal',
  PixWithdrawals = 'withdrawals.pix',
  SupportPix = 'pix',
  BVNKWithdrawals = 'withdrawals.bvnk',
  DeelWalletWithdrawals = 'withdrawals.deelWallet',
  DeelCardWithdrawals = 'withdrawals.deelCard',
  deelWallet = 'deelWallet',
  isBVNKEnabledForUser = 'bvnk',
  profilesSupportedByPaysend = 'profilesSupportedByPaysend',
  orderFormsBannerShown = 'contractsOrderFormsBannerShown',
  learningEngage = 'engage.learning.isEnabled',
  Performance = 'engagePerformance',
  hiringIsEnabled = 'hiring.isEnabled',
  passwordRotation = 'passwordRotation',
  mobilityCaseManagement = 'mobilityCaseManagement',
  mobilityPreHireAssessment = 'mobilityPreHireAssessment',
  mobilityDisableHourlyEor = 'mobilityDisableHourlyEor',
  mobilityCaseIntroductoryModal = 'mobilityCaseIntroductoryModal',
  agnosticImmigrationFlow = 'mobility.agnosticImmigrationFlow',
  mobilityStandaloneMVP3 = 'mobilityStandaloneMVP3',
  eorAutoSave = 'eorAutoSave',
  useEdmForAdditionalData = 'useEdmForAdditionalData',
  adjustInvoiceRowTitle = 'adjustInvoiceRowTitle',
  // Integrations Feature Flags
  integrationsQboGrowthFunnelUsersList = 'integrationsQboGrowthFunnelUsersList',
  // Extra2 Feature Flags
  CoworkingService = 'coworkingService',
  // Time Off Flags
  EnableTopOffPayment = 'timeOff.enableTopOffPayment',
  LongTermLeaveEnabled = 'timeOff.isLongTermLeaveEnabled',
  ParentalLeaveTimeOffTypes = 'timeOff.parentalLeaveTimeOffTypes',
  TimeOffPayoutsConfigEnabled = 'timeOff.payoutsConfigEnabled',
  EnableConsecutiveTimeOffLimits = 'timeOff.enableConsecutiveLimits',
  TimeOffRequestsEnabled = 'timeOff.enableTimeOffRequests',
  BalanceExpiryEnabled = 'timeOff.enableBalanceExpiry',
  SegmentedAllowancePolicyTypes = 'timeOff.segmentedAllowancePolicyTypes',
  // payment/invoices - AlertBillingSection control
  standaloneFeeInvoices = 'standaloneFeeInvoices',
  ShieldMassAssessment = 'shieldMassAssessment',
  ShieldMassEdit = 'shieldMassEdit',
  // PMORG FF
  orgChartEnabledFor = 'orgChartEnabledFor',
  orgChartUpselling = 'orgChartUpselling',
  orgChartClientsAsManagers = 'orgChartClientsAsManagers',
  orgChartHideSeniority = 'orgChartHideSeniority',
  orgChartGroupByCountry = 'orgChartGroupByCountry',
  orgStructureOnContractCreation = 'isOrgStructureOnContractCreation',
  orgChartNewEntryPoint = 'orgChartNewEntryPoint',
  dynamicGroupsEntryPoint = 'dynamicGroupsEntryPoint',
  hasRoleWithoutPositionSection = 'hasRoleWithoutPositionSection',
  orgChartEnabledOnMobile = 'orgChartEnabledOnMobile',
  // Login as deel admin Feature Flag
  loginAsDeelAdminPermission = 'loginAsDeelAdminPermission',
  contractShieldDelegateSignature = 'contractShieldDelegateSignature',
  NewAuditLogs = 'newAuditLogs',
  EmailLogs = 'emailLogs',
  isPeoLeasedEquipmentEnabled = 'isPeoLeasedEquipmentEnabled',
  PeoCoworking = 'peoCoworking',
  contractEORDelegateSignature = 'contractEORDelegateSignature',
  TAI_Projections = 'taiProjections',
  TAI_ProjectionsEnabledForCountries = 'tai.projectionsEnabledForCountries',
  isBottomNavigationEnabled = 'mobile.isBottomNavigationEnabled',
  isNativeHomeCustomizationEnabled = 'mobile.isNativeHomeCustomizationEnabled',
  legacyOnboarding = 'useLegacyOnboarding',
  FocusFlowReviewAndSign = 'FocusFlowReviewAndSign',
  VendorManagementSystem = 'vendorManagementSystem',
  timeOffBalanceAdjustmentEnabledFor = 'timeOffBalanceAdjustmentEnabledFor',
  // Salary Insights Feature Flags
  SalaryInsightsDashboardSensitiveAccessClient = 'showDashboardToSensitiveAccessClient',
  SalaryInsightsTopHiredRoles = 'showTopHiredRoles',
  SalaryInsightsOverTime = 'showSalaryOverTime',
  hidePayslipsOverview = 'hidePayslipsOverview',
  splitCreateAndSignGP = 'splitCreateAndSignGP',
  splitCreateAndSignHRIS = 'splitCreateAndSignHRIS',
  // Localization
  LocalizationTest = 'localizationTest',
  hidePRMPortal = 'hidePRMPortal',
  showDataUpdates = 'showDataUpdates',
  hideEmployeeListRevamp = 'hideEmployeeListRevamp',
  shieldHighlightCoverage = 'shieldHighlightCoverage',
  isDemoWorkerImpersonateEnabled = 'isDemoWorkerImpersonateEnabled',
  notificationSettingsForWorkers = 'notificationSettingsForWorkers',
  // EOR
  EORContractCreationReasonsDropdownRandomize = 'eorContractCreationReasonsDropdownRandomize',
  EORTerminationReasonsDropdownRandomize = 'eorTerminationReasonsDropdownRandomize',
  showAdjustmentsRevamp = 'showAdjustmentsRevamp',
  serviceProposalLetter = 'shield.serviceProposalLetter',
  PEOEnablePayrollCheckJournalReport = 'peoEnablePayrollCheckJournalReport',
  PEOEnablePayrollBillingSummaryReport = 'peoEnablePayrollBillingSummaryReport',
  PEOEnableCompleteAgreements = 'peoEnableCompleteAgreements',
  eorIsExpediteExperienceEnabled = 'eor.isExpediteExperimentEnabled',
  // SHIELD
  shieldEssentials = 'shieldEssentials',
  eorBillingFeesEnabled = 'eor.billingFeesEnabled',
  // EOR Cost Calculator
  isCostCalculatorEnabled = 'eor.isCostCalculatorEnabled',
  PostQAReports = 'postQAReports',
  // Transition
  contractTransition = 'contractTransition',
  // Deel IT
  deelITNavEnabled = 'deelIT.navEnabled',
  // Deel API
  DeelApiHideSensitiveData = 'deelApiHideSensitiveData',
  // white label
  isWhiteLabelSetupWizardEnabled = 'whiteLabel.isSetupWizardEnabled',
  // misclassification
  misclassificationHomePageBanner = 'misclassificationHomePageBanner',
  misclassificationHomePageWidget = 'misclassificationHomePageWidget',
  misclassificationPeoplePageBanner = 'misclassificationPeoplePageBanner',
  misclassificationIndividualPageAlert = 'misclassificationIndividualPageAlert',
  isWhiteLabelFeaturesTabEnabled = 'whiteLabel.isFeaturesTabEnabled',
  // Contracts
  NewMassUpdateAll = 'contracts.newMassUpdateFlow.enabledFor.enabledForEveryone',
  NewMassUpdateFor = 'contracts.newMassUpdateFlow.enabledFor.enabledForOrganizationIds',
  ContractCreationAutoSave = 'contractCreationAutoSave',
  // ---
  PeoDocumentsMigrationEnabled = 'peoDocumentsMigrationEnabled',
  PeoEnableMassOnboarding = 'peoEnableMassOnboarding',
  // Analytics and reporting
  isNewReportCreationFlowEnabled = 'isNewReportCreationFlowEnabled',
  isImpersonateSharerPermissionEnabled = 'isImpersonateSharerPermissionEnabled',
  isReportViewLiteEnabled = 'isReportViewLiteEnabled',
  isMonthRangeFilterEnabled = 'isMonthRangeFilterEnabled',
  // Workflows
  ShouldShowTriggerEditButton = 'workflows.shouldShowTriggerEditButton',
  HiddenPeopleFieldTriggers = 'workflows.hiddenPeopleFieldTriggers',
  // Mobile
  isAppBasedMFAEnabled = 'mobile.isAppBasedMFAEnabled',
  //Knowledge hub
  KnowledgeHub = 'knowledgeHub',
}
