import LookupStore from '@/stores/LookupStore';
import { isCryptoCurrency } from '@/utils/numbers';
import {
  useSensitiveConvertToPriceFormat,
  useSensitiveConvertToPriceFormatFactory,
} from './useSensitiveConvertToPriceFormat';
import { useCallback } from 'react';
import type BigNumber from 'bignumber.js';

/**
 * Returns the price with currency symbol
 */
const withCurrency = (
  price: string,
  currencySymbol: string,
  positionRight: boolean = false,
  isCrypto: boolean = false
): string => {
  const minus = price[0] === '-' ? '-' : '';
  const addSpace = isCrypto ? ' ' : '';
  return positionRight
    ? `${price}${addSpace}${currencySymbol}`
    : `${minus}${currencySymbol}${addSpace}${price.replace('-', '')}`;
};

/**
 * Returns the price with currency symbol
 * if forceFractionDigits- shows fraction digits even if 0
 */
export const useSensitiveConvertToPrice = (
  price?: string | number | BigNumber | null,
  currency: string = 'USD',
  roundDigits: number | null = null,
  forceFractionDigits: boolean = false,
  useShortSymbol: boolean = false
): string => {
  if (!currency) currency = 'USD';
  const { symbol = '$', positionRight, shortSymbol } = LookupStore.currencies[currency] || {};

  const isCryptoCurrencyValue = isCryptoCurrency(currency);

  const nonCryptoCurrencySymbol = useShortSymbol && shortSymbol ? shortSymbol : symbol;

  const priceSymbol = isCryptoCurrencyValue ? currency : nonCryptoCurrencySymbol;
  // returns '*****' if sensitive info is hidden
  const priceOrSymbols = useSensitiveConvertToPriceFormat(price, roundDigits, forceFractionDigits);

  return withCurrency(priceOrSymbols, priceSymbol, positionRight, isCryptoCurrencyValue);
};

export const useSensitiveConvertToPriceFactory = () => {
  const toPriceFormat = useSensitiveConvertToPriceFormatFactory();

  return useCallback(
    (
      price?: string | number | BigNumber | null,
      currency: string = 'USD',
      roundDigits: number | null = null,
      forceFractionDigits: boolean = false,
      useShortSymbol: boolean = false
    ): string => {
      if (!currency) currency = 'USD';
      const { symbol = '$', shortSymbol, positionRight } = LookupStore.currencies[currency] || {};

      const isCryptoCurrencyValue = isCryptoCurrency(currency);

      const nonCryptoCurrencySymbol = useShortSymbol && shortSymbol ? shortSymbol : symbol;

      const priceSymbol = isCryptoCurrencyValue ? currency : nonCryptoCurrencySymbol;
      // returns '*****' if sensitive info is hidden
      const priceOrSymbols = toPriceFormat(price, roundDigits, forceFractionDigits);

      return withCurrency(priceOrSymbols, priceSymbol, positionRight, isCryptoCurrencyValue);
    },
    [toPriceFormat]
  );
};
