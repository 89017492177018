/**
  We need to use this function instead of window.open(url)
  to avoid the issue when Safari blocking the page in a new tab.
 */
export const redirect = (url: string, target = '_blank') => {
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('target', target);
  element.style.display = 'none';

  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};
