import { Button, CloseButton, HStack, Icon, MuiBox, P, VStack } from '@letsdeel/ui';
import { useState, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { UserDetails } from './UserDetails';
import { VerifyAppPin } from './VerifyAppPin';
import { useInitRequestPin } from './hooks/useInitRequestPin';
interface Props {
  closeModal: (token: string | undefined) => void;
}

export const allowedPinAttempts = 7;
export const RequestAppPin: FunctionComponent<Props> = ({ closeModal }) => {
  const { t } = useTranslation();
  const [remainingAttempts, setRemainingAttempts] = useState(allowedPinAttempts - 1);

  const { isKeyboardVisible, handleCloseDialog, handleForgotPin } = useInitRequestPin(closeModal);

  return (
    <VStack padding={0} margin={0} alignItems={'center'} paddingBottom={!isKeyboardVisible ? '32px' : '16px'}>
      <HStack display={'flex'} gap={'8px'} justifyContent={'space-between'} width={'100%'} paddingX={'8px'}>
        <MuiBox width={'24px'}></MuiBox>
        <P
          fontFamily={'Inter'}
          justifyContent={'center'}
          alignItems={'center'}
          fontSize={'18px'}
          fontStyle={'normal'}
          fontWeight={500}
          lineHeight={'32px'}
        >
          {t('authentication.nativeAppPin.request.title')}
        </P>
        <MuiBox width={'24px'} justifyContent={'center'} alignItems={'center'} padding={'4px'}>
          <CloseButton onClick={handleCloseDialog} />
        </MuiBox>
      </HStack>
      <UserDetails />
      <VerifyAppPin
        allowedPinAttempts={allowedPinAttempts}
        remainingAttempts={remainingAttempts}
        setRemainingAttempts={setRemainingAttempts}
        closeModal={closeModal}
      />
      <Button
        size="small"
        startIcon={<Icon.Key size={24} />}
        variant="outlined"
        fullWidth
        sx={{
          height: '40px',
          marginTop: '16px',
          padding: '12px 16px',
          borderColor: 'neutral.main',
          borderRadius: '8px',
          fontSize: '14px',
          fontFamily: 'Inter',
          fontWeight: 500,
          lineHeight: '24px',
        }}
        onClick={() => handleForgotPin(allowedPinAttempts - remainingAttempts)}
      >
        {t('authentication.nativeAppPin.request.forgotPinButton')}
      </Button>
    </VStack>
  );
};
