import isEqual from 'lodash/isEqual';
import { stringify } from 'query-string';
import type { AxiosError } from 'axios';
import { isAxiosError } from 'axios';
import { dateFormat } from '@/utils/time';

export const buildQueryString = (queryObj: Record<string, any>) => {
  let res = '';
  Object.keys(queryObj)?.map((key) => {
    const value = queryObj[key];
    if (
      (value !== null && value !== undefined && value !== '' && !Array.isArray(value)) ||
      (Array.isArray(value) && value.length > 0)
    ) {
      if (res.length) res += '&';
      else res += '?';

      if (Array.isArray(value)) {
        value.forEach((item, i) => {
          res += `${key}=${item}${i === value.length - 1 ? '' : '&'}`;
        });
      } else {
        res += `${key}=${value}`;
      }
    }
  });

  return res;
};

export const buildChangedValuesPayload = (values: Record<string, any>, compareTo: Record<string, any>) => {
  if (!compareTo || !values) return values || {};

  const payload: Record<string, any> = {};
  const contractTermChanged = !isEqual(values['contractTerm'], compareTo['contractTerm']);
  const probationPeriodTypeForDefiniteChanged = !isEqual(
    values['probationPeriodTypeForDefinite'],
    compareTo['probationPeriodTypeForDefinite']
  );

  Object.keys(values).map((key) => {
    const skipProbationPeriod =
      key === 'probationPeriod' && (contractTermChanged || probationPeriodTypeForDefiniteChanged);
    if (typeof values[key]?.getMonth === 'function' && typeof compareTo[key]?.getMonth === 'function') {
      if (dateFormat(values[key]) !== dateFormat(compareTo[key])) {
        payload[key] = values[key];
      }
    } else if (!isEqual(values[key], compareTo[key]) || skipProbationPeriod) {
      payload[key] = values[key];
    }
  });

  return payload;
};

export const getUrlWithParams = (url: string, params: Record<string, any>) => {
  const keys = Object.keys(params);
  if (!keys.length) return url;

  return `${url}?${stringify(params, { arrayFormat: 'bracket' })}`;
};

export const convertDateToLocalTimezone = (val: string) => new Date(val.split('T')[0].replaceAll('-', '/')).toString();

export const getApiErrorMessage = (err: AxiosError | unknown, keepArray = false): string | string[] | undefined => {
  if (isAxiosError(err)) {
    if (Array.isArray(err.response?.data)) {
      const errors = err.response.data.map((error: { message: string }) => error.message);
      return keepArray && errors.length > 1 ? errors : errors.join(', ');
    }

    if (typeof err.response?.data?.error === 'string') return err.response.data.error;

    if (Array.isArray(err.response?.data?.errors)) {
      const errors = err.response.data.errors.map((error: { message: string }) => error.message);
      return keepArray && errors.length > 1 ? errors : errors.join(', ');
    }
  }

  return undefined;
};
