import i18next from 'i18next';
import uniqueId from 'lodash/uniqueId';
import { match } from 'ts-pattern';
import { getCurrencySymbolFromStore, shortenLargeNumber, toPercentage } from '@/utils/numbers';
import type { TopnavDatagridStylesOverrideValues } from '@/hooks/useTopnavUtils';
import { topNavFirstLayerHeight } from '@/components/TopNav/constants';
import type { WidgetChartData } from '@/scenes/Analytics/scenes/ReportsHrisDashboard/types';
import type { OverviewWidget } from '@/scenes/Analytics/scenes/ReportsHrisOverview/types';
import { ChartDataType } from '@/components/Chart/types';
import { useSensitiveConvertToPrice as sensitiveConvertToPrice } from '@/hooks/numbers/useSensitiveConvertToPrice';
import { SENSITIVE_DATA_PLACEHOLDER } from '../Settings/scenes/SecuritySettings/utils/SensitiveData';

export const formatNumberFromChartDataType = (total: number, type: string, currency?: string) => {
  const sensitiveConvertedPrice = sensitiveConvertToPrice(total, currency);
  switch (type) {
    case ChartDataType.CURRENCY:
      return sensitiveConvertedPrice.indexOf(SENSITIVE_DATA_PLACEHOLDER) >= 0
        ? sensitiveConvertedPrice
        : `${getCurrencySymbolFromStore(currency)}${shortenLargeNumber(total)}`;
    case ChartDataType.PERCENTAGE:
      return toPercentage(total);
    case ChartDataType.NUMBER:
      return total.toLocaleString();
    default:
      return total.toString();
  }
};

export const getEmptyStateMessage = (subject?: string) => {
  return i18next.t('analytics.misc.noDataMessage', {
    subject: subject?.toLowerCase() || i18next.t('analytics.misc.data'),
  });
};

const getRandomInteger = (min = 0, max = 100) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getWidgetDummyData = (chartType?: string): OverviewWidget => {
  return match(chartType)
    .with('pie', () => getPieChartWidgetDummyData())
    .otherwise(() => getAreaLineWidgetDummyData(chartType));
};

export const getAreaLineWidgetDummyData = (chartType = 'area'): OverviewWidget => {
  const colors = ['#519AF4', '#35A7AB', '#42C3E3', '#9A417D', '#958BF4'];
  const diffs: {
    orientation: 'up' | 'down';
    color: 'success' | 'error';
  }[] = [
    { orientation: 'up', color: 'success' },
    { orientation: 'down', color: 'error' },
  ];

  const key = uniqueId();
  let currentDate = new Date();

  const data: Array<WidgetChartData> = [...Array(12)].map((_): any => {
    const formattedDate = currentDate.toISOString().slice(0, 10);

    currentDate.setMonth(currentDate.getMonth() + 1);

    return {
      [key]: getRandomInteger(),
      name: formattedDate,
      xAxisData: formattedDate,
    };
  });

  const color = colors[getRandomInteger(0, colors.length - 1)];
  const diff = diffs[getRandomInteger(0, diffs.length - 1)];

  return {
    data: data,
    headline: {
      title: 'Title',
      total: {
        value: data.reduce((acc, item) => acc + item[key], 0),
        type: ChartDataType.NUMBER,
      },
      diff: {
        signal: '',
        value: getRandomInteger(),
        type: ChartDataType.PERCENTAGE,
        ...diff,
      },
    },
    chartMetadata: {
      chartType: chartType,
      subject: {
        singularName: 'subject',
        pluralName: 'subjects',
      },
      currency: 'USD',
      dataType: ChartDataType.NUMBER,
      axis: [
        {
          key: key,
          name: 'name',
          color: color,
          fill: [
            {
              offset: 0,
              stopColor: color,
              stopOpacity: 0.7,
            },
            {
              offset: 0.6,
              stopColor: color,
              stopOpacity: 0.3,
            },
            {
              offset: 1,
              stopColor: '#FFFFFF',
              stopOpacity: 0.1,
            },
          ],
        },
      ],
    },
  };
};

const getPieChartWidgetDummyData = (): OverviewWidget => {
  const colors = ['#F76D5A', '#35A7AB', '#F899AC', '#9A417D'];

  return {
    widgetMetadata: {},
    data: [
      colors.reduce((data: any, color) => {
        data[color] = getRandomInteger();
        return data;
      }, {}),
    ],
    chartMetadata: {
      currency: 'USD',
      chartType: 'pie',
      dataType: ChartDataType.NUMBER,
      axis: colors.map((color) => ({
        name: color,
        key: color,
        color: color,
      })),
      subject: {
        singularName: 'Singular',
        pluralName: 'Plural',
      },
    },
    headline: {
      title: 'Title',
      total: {
        value: getRandomInteger(),
        type: ChartDataType.NUMBER,
      },
    },
  };
};

export const sanitizeParameters = (parameters?: any) => {
  if (!parameters) {
    return parameters;
  }
  const sanitized: any = {};
  Object.keys(parameters).forEach((key) => {
    if (Array.isArray(parameters[key])) {
      sanitized[key] = parameters[key].filter((item: any) => item !== null);
    } else {
      sanitized[key] = parameters[key];
    }
  });
  return sanitized;
};

// TODO: fix this in deel ui since it's not a good fix for the long term
export const topNavOverrideValues: TopnavDatagridStylesOverrideValues = {
  controls: {
    topnavSwitchEnabled: {
      mobileValue: topNavFirstLayerHeight + 57,
      value: topNavFirstLayerHeight + 49,
    },
    mobileValue: topNavFirstLayerHeight - 8,
    value: topNavFirstLayerHeight - 8,
  },
  overhead: {
    topnavSwitchEnabled: {
      mobileValue: 259,
      value: 210,
    },
    mobileValue: 194,
    value: 161,
  },
  columnHeaders: {
    topnavSwitchEnabled: {
      mobileValue: 309,
      value: 260,
    },
    mobileValue: 244,
    value: 211,
  },
};
