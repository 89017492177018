import { getUrlWithParams } from '@/utils/apiHelpers';
import instance from '@/utils/api/instance';
import type { CSATRating } from '@/components/CustomerSatisfaction/CustomerSatisfaction';
import { forceRecaptcha } from '@/components/Recaptcha/Recaptcha';
import type { CSATProductVertical, CSATTeam } from '@/components/CustomerSatisfaction/products';
import { Capacitor } from '@capacitor/core';

export interface ReviewSubmitParams {
  product: string;
  action: string;
  category?: string;
  recaptcha?: string;
  rating: CSATRating;
  message?: string;
  data?: object;
  allowContact?: boolean;
  openReplaySessionId?: string;
  topics?: string[];
  teamName: CSATTeam;
  productVertical: CSATProductVertical;
  messageCategory?: string;
  createTicket?: boolean;
}

export interface FeedbackSubmitParams {
  message: string;
  messageCategory: string;
  productReviewId: number;
}

export default {
  show: async (product: string, action: string, category?: string, extraParams: Record<string, any> = {}) => {
    const { data } = await instance.get(
      getUrlWithParams(`/review/show`, { product, action, category, ...extraParams }),
      {
        suppressSnackbar: true,
      }
    );
    return data;
  },

  submit: async (params: ReviewSubmitParams) => {
    const { data } = await instance.post(
      `/review`,
      { ...params, ...forceRecaptcha, platform: Capacitor.getPlatform() },
      { suppressSnackbar: true }
    );
    return data;
  },

  updateReview: async (params: FeedbackSubmitParams) => {
    const { productReviewId, ...restParams } = params;
    const { data } = await instance.patch(`/review/${params.productReviewId}`, restParams);
    return data;
  },
};
