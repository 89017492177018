import type { CountryCode } from '@letsdeel/ui';
import instance from '@/utils/api/instance';

export type ClientWithoutHrisProfile = {
  id: string;
  display_name: string;
  first_name: string;
  last_name: string;
  country?: CountryCode;
  pic_url?: string;
  email: string;
};

export default {
  getClientWithoutHrisProfile: async (id: string): Promise<ClientWithoutHrisProfile> => {
    const url = `/hris/org_chart/clients_without_hris_profiles/${id}`;
    const { data } = await instance.get(url);
    return data;
  },
};
