export const CONTACT_SUPPORT_LINK_PROPS = {
  href: 'https://help.letsdeel.com/hc/en-gb/requests/new',
  target: '_blank',
  rel: 'noreferrer',
};

export const CONTACT_OTP_2FA_SUPPORT_LINK_PROPS = {
  href: 'https://help.letsdeel.com/hc/en-gb/requests/new?ticket_form_id=360001676798&tf_6074219325713=contractor::otp/2fa_issues',
  target: '_blank',
  rel: 'noreferrer',
};

export const GLOBAL_PAYROLL_SUPPORT_TICKET_FORM_ID = 14964039106961;
export const GLOBAL_PAYROLL_SUPPORT_TICKET_FORM_CUSTOM_FIELD_MAPPINGS = {
  userEmail: 'anonymous_requester_email',
  yourInquiryIsRelatedTo: '14963896864017',
  entityName: '15000042659217',
  entityCountry: '4627042919697',
  eventCycle: '15000045605521',
};
