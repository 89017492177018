import type { FeatureFlags } from '@/constants/featureFlags';
import useLookupsStore from './useLookupsStore';
import useUserStore from './useUserStore';

/** Enum with the kind of feature flags */
export enum EFeatureFlag {
  /**
   * Array of profiles to enable the feature flag
   * @recommendation Set of admin profiles to handle the feature flag
   * @example [123456, 123456]
   */
  PROFILES_BASED,
  /**
   * Array of organizationIds to enable the feature flag
   * @recommendation Set of organizations to handle the feature flag
   * @example [123456, 123456]
   */
  ORGANIZATIONS_BASED,
  /**
   * Boolean to enable or disable the feature
   * @recommendation Handle global/common user features
   * @example true
   */
  BOOLEAN_BASED,
  /**
   * Please, read this documentation: https://wiki.deel.network/i/11924
   * @recommendation Handle global/common user features with a backend strategy
   */
  BACKEND_BASED,
}

/**
 * Get the feature flag status according of the strategy associated to (kind of) the feature flag.
 * @param featureFlag String with the name of the feature flag
 * @param _kindOf Specify the kind of feature flag, default is EFeatureFlag.BACKEND_BASED
 * @returns True if FF is enabled and false if not
 */
const useFeatureFlag = (
  featureFlag: string | typeof FeatureFlags,
  _kindOf: EFeatureFlag = EFeatureFlag.BACKEND_BASED
): boolean => {
  const user = useUserStore();
  const lookups = useLookupsStore();
  const enabledTo = lookups?.getFeatureFlag(featureFlag as string) || [];

  if (_kindOf === EFeatureFlag.ORGANIZATIONS_BASED) return enabledTo?.includes(user?.organization?.id);
  if (_kindOf === EFeatureFlag.BOOLEAN_BASED) return lookups?.getFeatureFlag(featureFlag as string) ?? false;
  if (_kindOf === EFeatureFlag.BACKEND_BASED) return user.isFeatureEnabled(featureFlag as FeatureFlags) ?? false;
  if (_kindOf === EFeatureFlag.PROFILES_BASED) return enabledTo?.includes(user?.id) ?? false;

  return false;
};

export default useFeatureFlag;
