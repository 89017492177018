import type { ReactNode, SyntheticEvent } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { inject } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
import type { ModalRef } from '@letsdeel/ui';
import {
  Alert,
  Button,
  CheckboxCell,
  Link,
  CodeInput,
  Stack,
  useFeedbackPopup,
  useSnackbar,
  H4,
  H6,
  Illustration,
} from '@letsdeel/ui';
import useUserStore from '@/hooks/useUserStore';
import accountApi from '@/utils/api/account';
import { PopupHeader } from '@/components/Popup';
import PopupStickyElement from '@/components/PopupStickyElement';
import type { PopupHeaderProps } from '@/components/Popup/components/PopupHeader/PopupHeader';
import './OtpPopupContent.less';
import type { OtpProvider } from '@/types/User';
import { CONTACT_OTP_2FA_SUPPORT_LINK_PROPS } from '@/constants/support';
import ResetAuthenticator from '@/components/ResetAuthenticator/ResetAuthenticator';
import PhoneNumberTroubleshoot from '@/components/ResetAuthenticator/PhoneNumberTroubleshoot';
import { useFetchPhoneNumbers } from '@/components/ResetAuthenticator/useFetchPhoneNumbers';
import ResendButton from '@/components/OtpPopupContent/ResendButton';
import useSupportLinkProps from '@/components/OtpPopupContent/useSupportLinkProps';
import { OTPCodeCTA, OTPCodeEventActions, OTPCodeProducts, trackMfaEvent } from '@/utils/analytics/otpCode';
import dataLayer from '@/utils/dataLayer';
import { EventActions, EventCategories, EventProducts } from '@/utils/analytics/constants';
import { Trans, useTranslation } from 'react-i18next';
import { useCheckIfShouldShowTopNav } from '@/hooks/home/useCheckIfShouldShowTopNav';

export interface OtpPopupContentProps extends Pick<PopupHeaderProps, 'backButton' | 'onBack' | 'onHide'> {
  stickyHeader?: boolean;
  errorMessage?: string | null;
  userEmail?: string;
  otpOptions?: string[];
  allowEmailFallback?: boolean;
  sendCodeOnMount?: boolean;
  onSubmit: (otp: string, otpProvider: string, shouldTrustDevice?: boolean) => Promise<any>;
  noSubmitAfterSmsReset?: boolean;
  afterSmsVerifySuccess?: ({ totpValidatedToken }: { totpValidatedToken: string }) => void;
  onResendCode?: ({
    otpProvider,
    phoneNumberLastDigits,
  }: {
    otpProvider: OtpProvider;
    phoneNumberLastDigits?: string;
  }) => Promise<any>;
  onSuccess?: () => void;
  onFail?: (err: string) => void;
  enforceSms?: boolean;
  hasPhoneNumber?: boolean;
  hasBackupEmail?: boolean;
  phoneNumber?: string;
  partialLoginToken?: string;
  trustedDeviceExpiryDays?: number;
  withTrusted?: boolean;
  isLoginScreen?: boolean;
  withoutFooter?: boolean;
}

function OtpPopupContent({
  stickyHeader,
  errorMessage,
  userEmail,
  otpOptions,
  allowEmailFallback = false,
  sendCodeOnMount = true,
  onSubmit,
  onResendCode = accountApi.sendOtp,
  onSuccess,
  onFail,
  enforceSms = false,
  hasPhoneNumber,
  hasBackupEmail,
  phoneNumber,
  partialLoginToken,
  noSubmitAfterSmsReset,
  afterSmsVerifySuccess,
  withTrusted = false,
  trustedDeviceExpiryDays = 7,
  isLoginScreen = false,
  withoutFooter = false,
  ...rest
}: OtpPopupContentProps) {
  const user = useUserStore();

  const { data: phoneNumbers } = useFetchPhoneNumbers();

  const { showSnackbar } = useSnackbar();

  const reset2faModalRef = useRef<ModalRef>();
  const supportModalRef = useRef<ModalRef>();

  const { openFeedbackPopup, closeFeedbackPopup } = useFeedbackPopup();

  const evaluatedOtpOptions = isEmpty(user?.otpOptions) ? otpOptions || [] : user?.otpOptions;
  const hasAppOption = enforceSms ? false : evaluatedOtpOptions?.includes('app');
  const enforceApp = hasAppOption && evaluatedOtpOptions?.length === 1;

  const firstUpdate = useRef(true);
  const [activeOtpProvider, setActiveOtpProvider] = useState<OtpProvider>(
    enforceSms ? 'sms' : hasAppOption ? 'app' : 'email'
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [innerErrorMessage, setInnerErrorMessage] = useState<ReactNode | null>(null);
  const [shouldTrustDevice, setShouldTrustDevice] = useState(false);
  const [otp, setOtp] = useState<string[]>(Array(6).fill(''));
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [backupEmailVerification, setBackupEmailVerification] = useState<boolean>(false);

  const isUsingAppOption = activeOtpProvider === 'app';
  const { t } = useTranslation();
  const shouldShowTopNav = useCheckIfShouldShowTopNav();

  const handleClickSendNewCode = () => {
    if (isLoginScreen) {
      trackMfaEvent(OTPCodeEventActions.SendNewCode, OTPCodeProducts.Login);
    }
    onResendCodeClick();
  };

  const onResendCodeClick = async (snackbar?: boolean) => {
    try {
      setIsResending(true);
      await onResendCode({
        otpProvider: activeOtpProvider,
        ...(backupEmailVerification ? { shouldUseBackupEmail: true } : {}),
        ...(enforceSms
          ? {
              phoneNumberLastDigits: phoneNumber?.replace(/\D/g, ''),
              partialLoginToken,
            }
          : {}),
      });
      snackbar && showSnackbar(t('settings.authenticator.otpPopup.newCodeSent'), 'success');
      setInnerErrorMessage(null);
    } catch (error) {
      console.error(error);
      setInnerErrorMessage(t('settings.authenticator.otpPopup.failedToSendNewCode'));
    } finally {
      setIsResending(false);
    }
  };

  useEffect(() => {
    setInnerErrorMessage(null);

    if (activeOtpProvider === 'email' && (sendCodeOnMount || !firstUpdate.current)) {
      onResendCodeClick();
    }

    if (activeOtpProvider === 'sms' && (sendCodeOnMount || !firstUpdate.current)) {
      onResendCodeClick();
    }

    if (firstUpdate.current) {
      firstUpdate.current = false;
    }

    dataLayer.push({
      event_category: EventCategories.Platform,
      event_product: EventProducts.TrustedDevices,
      event_action: EventActions.LoginVerificationRequired,
      otp_provider: activeOtpProvider,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOtpProvider]);

  const formattedNumber = useMemo(() => {
    if (!phoneNumber) return undefined;
    if (phoneNumber?.length === 4) {
      return t('settings.authenticator.otpPopup.smsSentToNumber');
    } else {
      const regex = /^(\D*)(\d{2})(\d*)(\d{4})(\D*)$/;
      return phoneNumber?.replace(regex, (_, prefix, firstTwo, middle, lastFour, suffix) => {
        const hiddenDigits = middle.replace(/\d/g, '*');
        return t('settings.authenticator.otpPopup.enterCodeSentToPhoneNumber', {
          phoneNumber: `${prefix}${firstTwo}${hiddenDigits}${lastFour}${suffix}`,
        });
      });
    }
  }, [phoneNumber, t]);

  const handleContactSupport = () => {
    if (isLoginScreen) {
      trackMfaEvent(OTPCodeEventActions.ContactSupport, OTPCodeProducts.Login);
    }
  };

  const handleSubmissionError = (error: any) => {
    setInnerErrorMessage(t('settings.authenticator.otpPopup.incorrectCodeEntered'));

    const errorMessage = (error?.response?.data?.errors && error?.response?.data?.errors[0]?.message) || error?.message;
    if (error?.response?.status === 403 || error?.response?.data?.code === '2FA_INVALID_CODE') {
      return;
    }

    if (error?.response?.data?.code === '2FA_TOO_MANY_FAILED_ATTEMPTS') {
      setInnerErrorMessage(
        <>
          Too many failed attempts. Please <Link {...CONTACT_OTP_2FA_SUPPORT_LINK_PROPS}>contact support</Link>.
        </>
      );
      onFail?.(t('settings.authenticator.otpPopup.contactSupport'));
    } else {
      onFail?.(errorMessage || t('settings.authenticator.otpPopup.serverCallFailed'));
    }
  };

  const handleSubmit = async () => {
    if (isLoginScreen) {
      trackMfaEvent(OTPCodeEventActions.AuthenticationCTA, OTPCodeProducts.Login, {
        enabled: !!shouldTrustDevice,
      });
    }

    setIsLoading(true);
    setInnerErrorMessage(null);

    try {
      await onSubmit(otp.join(''), activeOtpProvider, shouldTrustDevice);
      onSuccess?.();
    } catch (error: any) {
      handleSubmissionError(error);
    } finally {
      setOtp(Array(6).fill(''));
      setIsLoading(false);
    }
  };

  const handleClosePopup = () => {
    if (isLoginScreen) {
      trackMfaEvent(OTPCodeEventActions.ClosePopUp, OTPCodeProducts.Login, { cta_name: OTPCodeCTA.CloseIcon });
    }
  };

  const header = (
    <PopupHeader
      closeButton
      onCloseButtonClick={handleClosePopup}
      hasDefaultMargin={false}
      className={'text-center'}
      {...rest}
      title={t('settings.authenticator.otpPopup.verificationRequired')}
      subtitle={
        enforceSms
          ? formattedNumber
          : isUsingAppOption
          ? t('settings.authenticator.otpPopup.enterCodeFromAuthenticatorApp')
          : backupEmailVerification
          ? t('settings.authenticator.otpPopup.enterCodeSentToBackupEmail')
          : user?.email || userEmail
          ? t('settings.authenticator.otpPopup.enterCodeSentToEmail', { email: user?.email || userEmail })
          : t('settings.authenticator.otpPopup.enterCodeSentToYourEmail')
      }
    />
  );

  const supportLinkProps = useSupportLinkProps();

  const onTroubleClick = () => {
    hasPhoneNumber || phoneNumbers?.length
      ? reset2faModalRef?.current?.open()
      : openFeedbackPopup({
          title: t('settings.authenticator.otpPopup.resetAuthenticator'),
          closeButton: true,
          onClose: () => rest.onHide?.(),
          button: {
            onClick: () => {
              closeFeedbackPopup();
              supportModalRef?.current?.open();
            },
            children: t('settings.authenticator.otpPopup.contactSupportButton'),
          },
        });
  };

  const isAppOptionWithTrusted = withTrusted && isUsingAppOption;

  useEffect(() => {
    if (isLoginScreen) {
      trackMfaEvent(OTPCodeEventActions.ModalVisualised, OTPCodeProducts.Login);
    }
  }, [isLoginScreen]);

  useEffect(() => {
    if (backupEmailVerification && !codeSent) {
      setCodeSent(true);
      onResendCodeClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backupEmailVerification]);

  const popupIllustration = enforceSms ? 'sms' : isUsingAppOption ? 'authenticator' : 'email';

  return (
    <div className="otp-popup-content flex flex-dir-col flex-1">
      {stickyHeader ? <PopupStickyElement>{header}</PopupStickyElement> : <div className="pb-7">{header}</div>}
      <form onSubmit={(e) => e.preventDefault()} className="flex flex-1">
        <Stack direction="column" spacing={3} flex={1}>
          <Stack alignItems="center">
            <Illustration
              width={128}
              height={128}
              illustration={`${popupIllustration}${errorMessage || innerErrorMessage ? '-error' : ''}`}
            />
          </Stack>

          {errorMessage || innerErrorMessage ? (
            <Alert severity="error">{errorMessage || innerErrorMessage}</Alert>
          ) : (
            !enforceSms &&
            !isUsingAppOption && (
              <Alert severity="info">
                {t(
                  shouldShowTopNav
                    ? 'settings.authenticator.otpPopup.encourageAuthenticatorApp'
                    : 'settings.authenticator.otpPopup.encourageAuthenticatorAppAccount'
                )}{' '}
              </Alert>
            )
          )}

          <div className="otp-popup-content-input-wrapper">
            <CodeInput
              className="small-device-gap"
              disabled={isLoading}
              autoFocus={true}
              values={otp}
              onChange={setOtp}
              onSubmit={isAppOptionWithTrusted ? undefined : handleSubmit}
            />
          </div>

          {!!isAppOptionWithTrusted && (
            <CheckboxCell
              checked={shouldTrustDevice}
              onChange={(_: SyntheticEvent<Element, Event>, checked: boolean) => setShouldTrustDevice(checked)}
              disabled={isLoading}
              label={
                <div className="pt-7 pb-7">
                  <H4 regular>
                    {t('settings.authenticator.otpPopup.dontAskAgainForWeek', { count: trustedDeviceExpiryDays / 7 })}
                  </H4>
                  <H6>{t('settings.authenticator.otpPopup.noVerificationCodeOnTrustedDevice')} </H6>
                </div>
              }
            />
          )}

          <Stack spacing={2} direction="column" flex={1}>
            {enforceSms ? (
              <ResendButton onClick={() => onResendCodeClick(true)} isLoading={isLoading} />
            ) : (
              <Stack direction="row" spacing={2}>
                {!isUsingAppOption && (
                  <ResendButton
                    onClick={() => handleClickSendNewCode()}
                    isLoading={isLoading}
                    resendCodeText={t('settings.authenticator.otpPopup.sendNewCode')}
                    timeoutInSeconds={120}
                    buttonProps={{ fullWidth: true }}
                  />
                )}
                {!!hasBackupEmail && !isUsingAppOption && (
                  <Button
                    disabled={isLoading}
                    loading={isResending}
                    variant="outlined"
                    onClick={() => setBackupEmailVerification(!backupEmailVerification)}
                    fullWidth
                    className="mt-4 mb-7"
                  >
                    {backupEmailVerification
                      ? t('settings.authenticator.otpPopup.verifyViaPrimaryEmail')
                      : t('settings.authenticator.otpPopup.verifyViaBackupEmail')}
                  </Button>
                )}
                {!!isAppOptionWithTrusted && (
                  <Button
                    disabled={otp.join('').length < 6}
                    loading={isLoading}
                    fullWidth
                    onClick={handleSubmit}
                    type="submit"
                  >
                    {t('settings.authenticator.otpPopup.completeLogin')}{' '}
                  </Button>
                )}
              </Stack>
            )}

            {!withoutFooter && (
              <>
                {enforceSms ? (
                  <H6 regular color={'gray'} onClick={handleContactSupport}>
                    <Trans
                      i18nKey="settings.authenticator.otpPopup.troubleReceivingCode"
                      t={t}
                      components={{
                        to: <Link {...supportLinkProps} />,
                      }}
                    />
                  </H6>
                ) : isUsingAppOption ? (
                  <H4 medium color="neutral.darker">
                    {!enforceApp && allowEmailFallback ? (
                      <Trans
                        i18nKey="settings.authenticator.otpPopup.cantAccessAuthenticatorApp"
                        t={t}
                        components={{
                          to: <Link onClick={() => setActiveOtpProvider('email')} />,
                        }}
                      />
                    ) : (
                      <Button variant={'text'} fullWidth onClick={onTroubleClick}>
                        {t('settings.authenticator.otpPopup.noAccessToAuthenticatorApp')}{' '}
                      </Button>
                    )}
                  </H4>
                ) : (
                  <H6 regular color={'gray'} onClick={handleContactSupport}>
                    <Trans
                      i18nKey="settings.authenticator.otpPopup.cantAccessAccount"
                      t={t}
                      components={{
                        to: <Link {...supportLinkProps} />,
                      }}
                    />
                  </H6>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </form>
      <ResetAuthenticator
        ref={reset2faModalRef}
        noSubmitAfterSmsReset={noSubmitAfterSmsReset}
        onResetSuccess={onSubmit}
        partialLoginToken={partialLoginToken}
        onHide={rest.onHide}
        afterSmsVerifySuccess={afterSmsVerifySuccess}
      />
      <PhoneNumberTroubleshoot ref={supportModalRef} buttonProps={supportLinkProps} />
    </div>
  );
}

export default inject('lookups', 'user')(OtpPopupContent);
