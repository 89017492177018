import instance from '@/utils/api/instance';

interface G2NRetrieveInputs {
  workCycle: any;
  companyRunId?: string;
  legalEntityId: string | number | undefined;
}

interface PayrollRunInputs {
  legalEntityId: any;
  workCycleStart: Date | undefined;
  workCycleEnd: Date | undefined;
}

const buildQueryString = <T extends Record<string, any>>(params: T): string => {
  return Object.keys(params)
    .filter((key) => params[key] !== undefined)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(String(params[key]))}`)
    .join('&');
};

export default {
  uploadFile: async (type: string) => {
    const { data } = await instance.post('upload', { type });
    return data;
  },

  retrieveFile: async (params: G2NRetrieveInputs) => {
    const queryString = buildQueryString(params);
    const res = await instance.get(`payroll_packages/external_g2n_report_items?${queryString}`);
    return res;
  },

  getPayrollRun: async (params: PayrollRunInputs) => {
    const queryString = buildQueryString(params);
    const res = await instance.get(`payroll_packages/payroll_run_options?${queryString}`);
    return res.data;
  },

  // fileSizeLimit - max number of bytes
  uploadForm: async (filename: string, type?: string, fileSizeLimit?: number) => {
    const { data } = await instance.post('upload/form', { filename, ...(type && { type }), fileSizeLimit });
    return data;
  },

  uploadFormMobility: async (filename: string, type?: string) => {
    const { data } = await instance.post('/mobility/upload/form', { filename, type });
    return data;
  },

  uploadFormSorted: async (filename: string, type?: string) => {
    const { data } = await instance.post('/sorted/upload/form', { filename, type });
    return data;
  },

  uploadFormEorExperience: async (filename: string, type?: string) => {
    const { data } = await instance.post('/eor-experience/upload/form', { filename, type });
    return data;
  },

  uploadToFormBuilder: async (fileName: string, fileType?: string) => {
    const { data } = await instance.post('/form_builder/uploads', { fileName, fileType });
    return data;
  },

  uploadFormPayrollInitRun: async (fileName: string, fileType?: string) => {
    const { data } = await instance.post('/embedded_payroll/init_run/upload/form', { fileName, type: fileType });
    return data;
  },
};
