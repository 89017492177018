import React from 'react';

import { H1, H2, H3, H4, H6 } from "@letsdeel/ui";
import './heading.less';

const Heading = ({ size, children, className, onClick, ...rest }) => {
  switch (size) {
    case 2:
      return (
        <H2 className={className} onClick={onClick} {...rest}>
          {children}
        </H2>
      );
    case 3:
      return (
        <H3 className={className} onClick={onClick} {...rest}>
          {children}
        </H3>
      );
    case 4:
      return (
        <H4 className={className} onClick={onClick} {...rest}>
          {children}
        </H4>
      );
    case 5:
      return (
        <H6 className={className} onClick={onClick} {...rest}>
          {children}
        </H6>
      );
    default:
      return (
        <H1 className={className} onClick={onClick} {...rest}>
          {children}
        </H1>
      );
  }
};

export default Heading;
