/**
 * This is a workaround to prevent app from crashing when users
 * translate the page using google translate extension.
 * Ideally we reiterate in the future and find a proper fix.
 *
 * Github issue: https://github.com/facebook/react/issues/11538#issuecomment-417504600
 *
 * Sentry error: https://deel.sentry.io/issues/4169395501/?referrer=slack
 */
function googleTranslateExtensionWorkaround() {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function <T extends Node>(this: Node, child: T) {
      if (child.parentNode !== this) {
        if (console) {
          console.error('Cannot remove a child from a different parent', child, this);
        }
        return child;
      }
      return originalRemoveChild.apply(this, [child]) as T;
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function <T extends Node>(this: Node, node: T, child: Node | null) {
      if (child && child.parentNode !== this) {
        if (console) {
          console.error('Cannot insert before a reference node from a different parent', child, this);
        }
        return node;
      }
      return originalInsertBefore.apply(this, [node, child]) as T;
    };
  }
}

export default googleTranslateExtensionWorkaround;
