import type { ReactNode } from 'react';

import Anchor from '@/components/Anchor/Anchor';
import Icon from '@/components/Icon';

import { cn } from '@/utils/main';

import './back-button.less';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  to?:
    | string
    | Partial<{
        pathname: string;
        search: string;
        hash: string;
        state: object;
      }>;
  href?: string;
  newTab?: boolean;
  children?: ReactNode;
  colorClass?: string;
  onClick?: () => void;
}

const BackButton = ({ className, to, href, newTab, children, colorClass = 'color-gray-dark', ...rest }: Props) => {
  const { t } = useTranslation();

  return (
    <Anchor to={to} href={href} newTab={newTab}>
      <div className={cn('back-button', className)} {...rest}>
        <Icon type="arrow-new" size="sm" className={`mr-5 back-button-icon ${colorClass}`} />
        <p className={colorClass}>{children || t('common.actions.back')}</p>
      </div>
    </Anchor>
  );
};

export default BackButton;
