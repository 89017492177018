import { ROUTES } from '@/constants/routesV2';

const routes = [
  {
    path: ROUTES.login.main,
    loadComponent: () => import('./LoginScaffold'),
  },
  {
    path: ROUTES.login.okta,
    loadComponent: () => import('./OktaLogin'),
  },
  {
    path: ROUTES.xeroSignup,
    loadComponent: () => import('./XeroLogin'),
  },
  {
    path: ROUTES.resetPasswordToken,
    loadComponent: () => import('./LoginScaffold'),
  },
  {
    path: ROUTES.loginWithCode,
    loadComponent: () => import('./LoginScaffold'),
  },
  {
    path: ROUTES.resetPasswordError,
    loadComponent: () => import('./ResetPasswordErrorPage'),
  },
  {
    path: ROUTES.nativeSSO,
    loadComponent: () => import('./NativeLogin/SSOLogin/NativeSSOLogin'),
  },
  {
    path: ROUTES.nativeOnboarding,
    loadComponent: () => import('./native/Onboarding/NativeOnboarding'),
  },
];

export default routes;
