import i18n from 'i18next';
import LookupStore from '@/stores/LookupStore';
import BigNumber from 'bignumber.js';
import pickBy from 'lodash/pickBy';
import { makeAutoObservable, runInAction } from 'mobx';

import { CONTRACT_STATUSES, CONTRACT_TYPES } from '@/constants/contract';
import { DAYS_OF_WEEK, ONE_DAY, ONE_MONTH, ONE_WEEK } from '@/constants/time';

import { EOR_AMENDMENT_STATUSES } from '@/scenes/Contract/components/EorContract/components/Amendments/utils';

import UserStore from '../../stores/UserStore';

export const CONTRACT_DATA_KEYS = ['docsAreMandatory', 'isMainIncome', 'usingSavedSchedule', 'firstPayment'];
import contractApi from '@/utils/api/contract';
import employmentServiceApi from '@/utils/api/employment-service';
import eorApi from '@/utils/api/eor';
// Takes in an array of contracts, returns an array of all the contracts in progress
export const getContractsInProgress = (contracts = []) => {
  return contracts.filter(
    ({ status }) => status === CONTRACT_STATUSES.inProgress.type || status === CONTRACT_STATUSES.processingPayment.type
  );
};

export const getAllAvailableContracts = (contracts = []) => {
  return contracts.filter(({ status }) => status !== CONTRACT_STATUSES.deleted.type);
};

export const getProviderType = (contract) => {
  return contract.providerType || 'contractor';
};

export const getStatusCheckingForShieldContracts = (status) => {
  return {
    isActive: [CONTRACT_STATUSES.inProgress.type, CONTRACT_STATUSES.processingPayment.type].includes(status),
    shieldContractStatusIncluded: [
      CONTRACT_STATUSES.waitingForClientSign.type,
      CONTRACT_STATUSES.waitingForContractorSign.type,
      CONTRACT_STATUSES.waitingForProviderSign.type,
      CONTRACT_STATUSES.awaitingDepositPayment.type,
    ].includes(status),
  };
};

// Checks if the contract is able to be deleted and return true or false
export const isContractDeletable = (contract) => {
  if (!contract) return null;

  return {
    [CONTRACT_STATUSES.new.type]: true,
    [CONTRACT_STATUSES.cancelled.type]: true,
    [CONTRACT_STATUSES.userCancelled.type]: true,
    [CONTRACT_STATUSES.completed.type]: true,
    [CONTRACT_STATUSES.waitingForContractorSign.type]: true,
    [CONTRACT_STATUSES.waitingForClientSign.type]: true,
    [CONTRACT_STATUSES.archived.type]: true,
  }[contract.status];
};

export const isContractArchivable = (contract) => {
  return (
    !!contract &&
    {
      [CONTRACT_STATUSES.cancelled.type]: true,
      [CONTRACT_STATUSES.userCancelled.type]: true,
      [CONTRACT_STATUSES.completed.type]: true,
    }[contract.status]
  );
};

export const isContractUnarchivable = (contract) => {
  return (
    !!contract &&
    {
      [CONTRACT_STATUSES.archived.type]: true,
    }[contract.status]
  );
};

// Checks if the contract is able to be cancelled and return true or false
export const isContractCancellable = (contract) => {
  return {
    [CONTRACT_STATUSES.inProgress.type]: true,
    [CONTRACT_STATUSES.waitingForClientPayment.type]: true,
    [CONTRACT_STATUSES.new.type]: true,
  }[contract.status];
};

// Takes in a contract and returns it's cancel date as a timestamp
export const getCancelDate = (contract) => {
  if (contract?.completionDate) {
    const { scale, howMany } = contract.completionDate;

    return Number(new Date(contract.createdAt)) + howMany * { day: ONE_DAY, week: ONE_WEEK, month: ONE_MONTH }[scale];
  }
};

export const getScaleNoun = (scale, firstCycleDate) => {
  return (
    {
      hourly: 'hour',
      daily: 'day',
      weekly: DAYS_OF_WEEK[(firstCycleDate + 6) % 7] || 'week',
      biweekly: firstCycleDate
        ? `other ${DAYS_OF_WEEK[(firstCycleDate + 6) % 7]}`
        : i18n.t('temp.unknown.utils.contract.a9adf02fea'),
      semimonthly: i18n.t('temp.unknown.utils.contract.3a4ff5dae3'),
      monthly: 'month',
    }[scale] || ''
  );
};

// Returns true if the user has at least 1 pay-as-you-go contract that's in progress
export const payAsYouGoOrFixedContractsExist = (contracts) => {
  return contracts.some(
    ({ type, status }) =>
      ({ [CONTRACT_TYPES.payAsYouGo.type]: true, [CONTRACT_TYPES.fixed.type]: true }[type] &&
      status === CONTRACT_STATUSES.inProgress.type)
  );
};

// Returns true if the contract is processing
export const isContractProcessing = (contract) => {
  if (contract.isClient) return contract.status === CONTRACT_STATUSES.processingPayment.type;
  if (contract.isContractor) return !!contract.isProcessing;
  return false;
};

// Returns the number of days in each cycle
export const getDaysInCycle = (contract) => {
  const { scale } = contract.workStatement;

  if (scale === 'biweekly') return 14;
  if (scale === 'monthly') return 30;
  if (scale === 'semimonthly') return 15;
  return 7;
};

export const removeNulls = (payload) => {
  return pickBy(payload, (value) => value !== null && value !== undefined);
};

export const removeJunk = (payload) => {
  return pickBy(payload, (value) => value !== null && value !== undefined && value !== '');
};

export const trimString = (str, index) => (str?.length > index ? `${str?.substr(0, index - 1)}...` : str);

export const EOR_MAX_SCOPE_LENGTH = 10000;
export const EOR_MAX_JOB_TITLE_LENGTH = 255;
export const EOR_MIN_SCOPE_LENGTH = 100;
export const EOR_MAX_QUALIFICATIONS_LENGTH = 10000;
export const EOR_MIN_QUALIFICATIONS_LENGTH = 100;
export const EOR_MAX_REQUIREMENTS_LENGTH = 10000;
export const EOR_MIN_REQUIREMENTS_LENGTH = 100;

// PEO
export const PEO_MIN_SCOPE_LENGTH = 100;
export const PEO_MAX_SCOPE_LENGTH = 10000;
export const PEO_MAX_JOB_TITLE_LENGTH = 64;
export const PEO_MIN_JOB_DESCRIPTION_LENGTH = 300;
export const PEO_MAX_JOB_DESCRIPTION_LENGTH = 2000;
export const PEO_MAX_QUALIFICATIONS_LENGTH = 10000;
export const PEO_MIN_QUALIFICATIONS_LENGTH = 100;

class Contract {
  draftContract = null;
  eorContractOid = ''; // reference to client contract oid for employee contract for EOR
  id = '';
  name = '';
  type = '';
  teamId = 0;
  currency = 'USD';
  offCycles = [];
  workStatements = [];
  paymentCycles = [];
  overdueCount = 0;
  status = '';
  client = {};
  contractor = {};
  amending = null;
  pdf = null;
  canBeCancelled = false;
  canBeRejected = false;
  canBeReinstated = false;
  pendingWorkStatement = false;
  customFields = [];
  country = '';
  clientSignature = '';
  clientSignedAt = '';
  commissions = [];
  commission = null;
  completedAt = null;
  completedPaymentCycles = '0';
  completionDate = '';
  contractorSignature = '';
  contractorSignedAt = '';
  createdAt = '';
  data = {};
  description = '';
  effectiveDate = '';
  firstSign = 'client';
  invitedClientEmail = '';
  invitedContractorEmail = '';
  isArchived = false;
  isClient = false;
  isContractor = false;
  isCreator = false;
  isCreatorContractor = false;
  isMainIncome = false;
  isRevertAvailable = false;
  jobTitle = {};
  milestones = [];
  needsReportApproval = false;
  note = null;
  previousCycleDates = {};
  seniority = null;
  signedCompletionDate = '';
  signedDate = '';
  specialClause = '';
  state = '';
  taxForm = null;
  team = {};
  terminationNoticeDays = 10;
  timeOffReports = [];
  timeOffs = [];
  timeTracking = {};
  timezone = '';
  total = {};
  vat = [];
  wasCreatedByProfileParty = false;
  withholdingTaxPercentage = null;
  scope = '';
  earlyPayoutInvoices = [];
  finalPayment = null;
  integration = null;
  alternativeEmails = [];
  proRataDetails = null;
  clientLegalEntity = { id: 0, name: '' };
  shieldAgreement = null;
  inviteContractorUri = null;
  invitedContractorHasContractorProfile = null;
  backgroundCheckData = null;

  // EOR
  eor = null;
  EORSignature = null;
  EORSignedAt = null;
  employeeSignature = null;
  employeeSignedAt = null;
  additionalInfo = null;
  employee = {};
  employerDetails = {};
  rejectReason = null;
  template = null;
  templateId = null;
  cutoffMessage = '';
  salaryChangeMessage = '';
  payrollEvents = null;
  healthPlan = null;
  depositAmountBeforeContractSigned = 0;
  eorAmendmentHistory = [];
  isDeleted = false;
  initialEffectivePlainDate = null;
  contractType = null;
  cancelledAt = null;
  shieldShieldedStatus = null;
  effectivePlainDate = null;
  expectedContractorEmail = null;
  contractorExpectedFirstName = null;
  contractorExpectedLastName = null;
  contractorLegalEntity = null;
  canSubmitExpenses = true;
  creator = null;
  contractorHrisProfile = null;
  isEorContract = null;
  contractorAlternativeEmails = null;
  canSkipDeposit = null;
  recommendedManagementFeeUSD = null;
  ContractIntegrations = null;
  pendingOffcycleReports = null;
  hrisInfo = null;
  mobility = null;
  organization = {};
  sow = null;
  revokedAt = null;
  poNumber = '';
  isPaidOutsideOfDeel = false;

  constructor(contract) {
    if (!contract) return;

    /**
     * Avoid setting properties from `contact` that are computed in this class.
     * This is possible because `Object.keys` doesn't return keys from computed properties (like `get activePaymentCycle`).
     */
    Object.keys(this).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(contract, key)) {
        this[key] = contract[key];
      }
    });

    // DEV-3774: Preset workstatements for contracts page
    if (!this.workStatements) Object.assign(this, { workStatements: [{ completionDate: null }] });

    this.isProcessing = contract.isContractor
      ? contract.isProcessing
      : contract.status === CONTRACT_STATUSES.processingPayment.type;
    makeAutoObservable(this);
  }

  get activePaymentCycle() {
    return this.paymentCycles?.find((cycle) => cycle.status === 'active');
  }

  get upcomingPaymentCycle() {
    return this.paymentCycles?.find((cycle) => cycle.status === 'upcoming');
  }

  get workStatement() {
    if (this.type === 'commission') {
      const workStatements = this.commission?.sourceContract?.workStatements;
      if (workStatements) {
        return workStatements[workStatements.length - 1];
      } else {
        return {};
      }
    } else {
      if (this.workStatements?.length) {
        return this.workStatements[this.workStatements.length - 1];
      }
    }
    return null;
  }

  get invoiceId() {
    return (
      this.paymentCycles.some(
        (cycle) => cycle.invoiceId || (cycle.status === 'overdue' && (+cycle.total.amount || +cycle.total.bonus))
      ) ||
      (+this.total.active.amount && this.isPayAsYouGo)
    );
  }

  get currentPrimaryCycleStatus() {
    const hasPendingInvoiceForCycleStatus = (status) =>
      this.paymentCycles.some(
        (cycle) =>
          cycle.invoice?.id &&
          cycle.invoice?.status === 'pending' &&
          cycle.status === status &&
          BigNumber.sum(cycle.total.amount, cycle.total.bonus) > 0
      );

    if (hasPendingInvoiceForCycleStatus('active')) return 'active';
    if (hasPendingInvoiceForCycleStatus('awaiting_payment')) return 'awaiting_payment';
    if (hasPendingInvoiceForCycleStatus('overdue')) return 'overdue';

    return null;
  }

  get isMember() {
    return !!(this.isClient || this.isContractor || this.isCreator);
  }

  get loggedUserHasPermissionToAccessContract() {
    if (this.isClient) {
      const clientBelongsToATeamWithAccess = !!this.team?.Profiles?.find(({ id }) => this.client?.id === id);
      return clientBelongsToATeamWithAccess;
    }
    return true;
  }

  get isSigned() {
    if (this.isEmployeeAgreement) {
      return (this.EORSignature || this.EORSignedAt) && (this.employeeSignature || this.employeeSignedAt);
    }

    if (this.shieldAgreement?.msaContract?.oid) {
      // This is how we make sure a shield contract is truly signed

      const { isActive, shieldContractStatusIncluded } = getStatusCheckingForShieldContracts(this.status);

      // Old shield contracts doesn't have SOW signature
      const isOldContractSigned = Boolean(
        this.clientSignedAt && this.contractorSignedAt && !shieldContractStatusIncluded
      );

      if ((isActive || isOldContractSigned) && !(this?.amending || this?.pendingWorkStatement)) return true;

      return !!(
        this.shieldAgreement?.clientSignedAt &&
        this.contractorSignedAt &&
        !(this.amending || this.pendingWorkStatement) &&
        ![CONTRACT_STATUSES.awaitingDepositPayment.type, CONTRACT_STATUSES.waitingForProviderSign.type].includes(
          this.status
        )
      );
    }

    return !!(this.client && this.contractor && !this.amending);
  }

  get isCommission() {
    return this.type === CONTRACT_TYPES.commission.type;
  }

  get isGeneralContract() {
    return this.isFixed || this.isMilestone || this.isPAYG;
  }

  get isFixed() {
    return this.type === CONTRACT_TYPES.fixed.type;
  }

  get isPAYG() {
    return this.isPayAsYouGo || this.isPaygTasks;
  }

  get isPayAsYouGo() {
    return this.type === CONTRACT_TYPES.payAsYouGo.type;
  }

  get isNewAmendmentFlowAvailable() {
    return this.isGeneralContract;
  }

  get isPaygTasks() {
    return this.type === CONTRACT_TYPES.paygTasks.type;
  }

  get isMilestone() {
    return this.type === CONTRACT_TYPES.paygMilestones.type || this.type === CONTRACT_TYPES.milestones.type;
  }

  get isEor() {
    return this.type === CONTRACT_TYPES.eor.type;
  }

  get isShield() {
    return this.type === CONTRACT_TYPES.shieldMSA.type;
  }

  get isGlobalPayroll() {
    return this.type === CONTRACT_TYPES.globalPayroll.type;
  }

  get isEmployeeAgreement() {
    return this.type === CONTRACT_TYPES.employeeAgreement.type;
  }

  get isWithholdingTaxSupported() {
    return !(isNaN(this.withholdingTaxPercentage) || this.withholdingTaxPercentage === null);
  }

  get isNetX() {
    return !!this.workStatement?.paymentDueDays;
  }

  get isPeo() {
    return this.type === CONTRACT_TYPES.peo.type;
  }

  get showWithholdingTaxCard() {
    return (
      LookupStore.isWithholdingTaxSupported(this.clientLegalEntity?.id || this.clientLegalEntityId) &&
      { ongoing_time_based: true, pay_as_you_go_time_based: true, payg_tasks: true }[this.type]
    );
  }

  get hasContractEnded() {
    return {
      [CONTRACT_STATUSES.completed.type]: true,
      [CONTRACT_STATUSES.userCancelled.type]: true,
    }[this.status];
  }

  get isInProgress() {
    return this.status === 'in_progress';
  }

  /* ================================================================ *
                            EOR ONLY PROPS
   * ================================================================ */

  get isDeelEor() {
    return this.isEor && this.eor?.EOR?.isDeel;
  }

  get isEmployeeOnPlatform() {
    return this.isEor && this.eor?.EOR?.isEmployeeOnPlatform;
  }

  get isDeelEorOrAffiliate() {
    return this.isEor && (this.eor?.EOR?.isDeel || this.eor?.EOR?.isAffiliate);
  }

  get didEmployeeSign() {
    return !!(this.employeeSignedAt || this.eor?.employeeSignedAt);
  }

  get isDepositAccrued() {
    return !!this.eor.depositAccrual;
  }

  get isBimonthlyCountry() {
    const { country } = this.eor?.EOR || {};
    return { US: true, CA: true, MX: true }[country];
  }

  // TERMINATION FLOW STARTED //
  get isTerminationAllowedByEOR() {
    return ['in_progress', 'awaiting_deposit_payment', 'processing_payment'].includes(this.status);
  }

  get isTerminationUnderReview() {
    return this.eor?.endingApprovalStatus === 'under_review';
  }

  get isTerminationRequested() {
    return !!this.eor?.endingApprovalStatus;
  }

  get isRefundingRequested() {
    return !!this.eor?.depositRefund;
  }

  get isDepositRefunded() {
    return !!this.depositRefund?.refundedAt;
  }

  get isAccruedRefund() {
    return this.eor?.depositAccrual;
  }

  get hasEnded() {
    return [CONTRACT_STATUSES.cancelled.type, CONTRACT_STATUSES.userCancelled.type].includes(this.status);
  }

  get isResignationEndType() {
    return this.eor?.endingType === 'resignation';
  }

  get hasResignationLetterAttachment() {
    return !!this.eor?.ResignationLetterTemplateId;
  }

  get isResignationLetterSigned() {
    return !!this.eor?.resignationLetterSignedAt;
  }

  get canSignResignationLetter() {
    return this.eor?.canSignResignationLetter;
  }
  // TERMINATION ENDED //

  // CANCELLATION FLOW STARTED //

  /* True if
   *  1) No deposit paid at all
   *  2) The deposit was paid, but it is not accrued
   *  3) The EA was not signed yet by employee
   *  4) Allied only to deelEor and Affiliates
   */
  get isCancellable() {
    return (
      {
        under_review: true,
        waiting_for_eor_sign: true,
        waiting_for_client_sign: true,
        waiting_for_employee_sign: true,
        awaiting_deposit_payment: true,
        waiting_for_employee_contract: true,
      }[this.status] && this.isDeelEorOrAffiliate
    );
  }

  get isCancelled() {
    return this.status === 'cancelled';
  }

  // CANCELLATION FLOW ENDED //

  get activePayroll() {
    return this.payrollEvents?.find(({ state }) => state === 'OPEN');
  }

  getAmendmentHistory = async () => {
    try {
      const amendments = await eorApi.amendment.getHistory({ eorContractId: this.eor?.id });

      runInAction(() => {
        if (!amendments || !amendments.length) {
          this.eorAmendmentHistory = [];
        } else {
          this.eorAmendmentHistory = amendments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  get pendingAmendment() {
    if (!this.eorAmendmentHistory?.length) return null;

    return this.eorAmendmentHistory.find(({ status }) => {
      const statusIndex = EOR_AMENDMENT_STATUSES[status]?.index;
      return statusIndex > 0 && statusIndex < EOR_AMENDMENT_STATUSES.ACTIVE.index;
    });
  }

  get hasPendingAmendment() {
    return !!this.pendingAmendment;
  }

  get platformOrManagementText() {
    return this.isDeelEorOrAffiliate
      ? i18n.t('temp.unknown.utils.contract.419f3742e6')
      : i18n.t('temp.unknown.utils.contract.fe4dbcab9b');
  }

  get isEORContractEASigned() {
    return !!this.paymentCycles?.length;
  }

  getCutoffMessage = async () => {
    this.cutoffMessage = await eorApi.cutoffMessage(this.id);
  };

  getSalaryChangeMessage = async () => {
    this.salaryChangeMessage = await eorApi.salaryChangeMessage(this.id);
  };

  getUnpaidDepositMessage = async () => {
    this.unpaidDepositMessage = await eorApi.unpaidDepositMessage(this.id);
  };

  fetchPayrollEvents = async () => {
    if (!this.didEmployeeSign) {
      this.payrollEvents = [];
      return;
    }

    try {
      this.payrollEvents = await employmentServiceApi.payrollEvents.getPayrollEventsByContractId(
        this.eorContractOid || this.id
      );
    } catch (e) {
      this.payrollEvents = [];
    }
  };

  calculateDepositDifference = (amendment) => {
    if (!amendment) return 0;

    const sumDepositValues = (values) => {
      if (!values) return 0;

      return Object.values(values).reduce((acc, cur) => BigNumber.sum(acc, cur || 0), 0);
    };

    const oldValues = {
      salary: this.eor?.monthlyGrossSalary,
      employerCost: this.eor?.monthlyEmployerCost,
    };

    const newValues = {
      salary: amendment.monthlyGrossSalary,
      employerCost: amendment.monthlyEmployerCost,
    };

    const depositDifference = new BigNumber(sumDepositValues(newValues)).minus(sumDepositValues(oldValues));

    return depositDifference < 0 ? 0 : depositDifference;
  };

  getDeposit = async () => {
    if (
      [
        CONTRACT_STATUSES.new.type,
        CONTRACT_STATUSES.underReview.type,
        CONTRACT_STATUSES.waitingForClientSign.type,
        CONTRACT_STATUSES.waitingForEORSign.type,
      ].includes(this.status)
    ) {
      const deposit = await eorApi.deposit(this.id);
      this.depositAmountBeforeContractSigned = deposit?.total || 0;
    }
  };

  get EORDisplayedInvoiceDate() {
    const invoiceIssueDate = this.eor?.EOR.invoiceIssueDate;
    return invoiceIssueDate ? Number(invoiceIssueDate) + 1 : null;
  }

  get canCopyLink() {
    if (this.hasEnded) return true;
    // contract is signed
    if (this.client && this.contractor) return true;

    if (UserStore.isClient && this.invitedContractorEmail) return true;
    if (UserStore.isContractor && this.invitedClientEmail) return true;
    return false;
  }

  getAlternativeEmails = async () => {
    this.alternativeEmails = await contractApi.getAlternativeEmails(this.id);
  };
}

export { Contract };
