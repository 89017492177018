import i18n from 'i18next';
import { forwardRef } from 'react';
import type { ModalRef } from '@letsdeel/ui';
import { H4, Modal, Stack } from '@letsdeel/ui';

interface Props {
  value: string;
}

const ScopeCellModal = forwardRef<ModalRef, Props>(({ value }, ref) => {
  return (
    <Modal title={i18n.t('temp.eor.scenes.RequestChanges.5d113f2038').toString()} ref={ref}>
      <Stack
        spacing={2}
        sx={{
          backgroundColor: 'neutral.lighter',
          whiteSpace: 'pre-wrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          padding: 2,
          borderColor: 'tertiary.main',
          borderRadius: 1,
        }}
      >
        <H4 regular>{i18n.t('temp.eor.scenes.RequestChanges.5d113f2038').toString()}</H4>
        <H4
          regular
          sx={{
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
          }}
        >
          {value}
        </H4>
      </Stack>
    </Modal>
  );
});

export default ScopeCellModal;
