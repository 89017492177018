import type { HrisWorkflowSettingTypes, HrisWorkflowSettingValueTypes } from '@/scenes/Workflows/types';
import instance from '@/utils/api/instance';

export interface WorkflowsType {
  limit?: number;
  cursor?: string;
  search?: string;
  sort_by?: string;
  sort_order?: string;
  name?: string;
}

export interface WorkflowPayloadType {
  name: string;
  description?: string;
  triggerType: string;
  actions?: any[];
}

export interface ITriggerType {
  description: string;
  displayName: string;
  icon: string;
  id: string;
  name: string;
  ordinal: string;
  schema: any[];
}

export interface ITriggerTypeSchema {
  data: {
    description: string;
    displayName: string;
    icon: string;
    id: string;
    name: string;
    ordinal: string;
    schema: any[];
    dynamicFields: {
      value: string;
      title: string;
      id: string;
    }[];
  };
}

export type WorkflowStatus = 'enable' | 'disable';

export interface WorkflowSettings {
  settings: [
    {
      settingType: HrisWorkflowSettingTypes;
      value: string[];
      valueType: HrisWorkflowSettingValueTypes;
    }
  ];
  workflowId?: string | null;
}

export default {
  getWorkflowList: async (params: WorkflowsType): Promise<any> => {
    return await instance.get('/hris/workflows', {
      params,
      suppressSnackbar: true,
    });
  },
  getWorkflow: async (oid: string): Promise<any> => {
    return await instance.get(`/hris/workflows/${oid}`, {
      suppressSnackbar: true,
    });
  },

  getWorkflowHistory: async (params: WorkflowsType): Promise<any> => {
    return await instance.get(`/hris/workflow_histories`, {
      params,
      suppressSnackbar: true,
    });
  },

  getWorkflowTemplates: async (params: WorkflowsType): Promise<any> => {
    return await instance.get(`/hris/workflows/templates`, {
      params,
      suppressSnackbar: true,
    });
  },

  getTriggerList: async (): Promise<any> => {
    return await instance.get(`/hris/workflows/trigger_types`);
  },

  getTriggerTypeSchema: async (oid?: string, assignedEvent?: string): Promise<ITriggerTypeSchema> => {
    return await instance.get(
      `/hris/workflows/trigger_types/${oid}${assignedEvent ? `?assigned_event=${assignedEvent}` : ''}`
    );
  },

  getActionTypes: async (): Promise<any> => {
    return await instance.get(`/hris/workflows/action_types`);
  },

  getActionTypeSchema: async (oid?: string): Promise<any> => {
    return await instance.get(`/hris/workflows/action_types/${oid}`);
  },

  postWorkflow: async (payload: WorkflowPayloadType): Promise<any> => {
    return await instance.post('/hris/workflows', payload);
  },

  patchWorkflowStatus: async (status: WorkflowStatus, oid?: string): Promise<any> => {
    return await instance.patch(`/hris/workflows/${oid}/${status}`);
  },

  updateWorkflow: async (oid: string, payload: WorkflowPayloadType): Promise<any> => {
    return await instance.put(`/hris/workflows/${oid}`, payload);
  },

  getSlackChannels: async (endpoint: string): Promise<any> => {
    return await instance.get(endpoint);
  },

  patchSettings: async (payload: any): Promise<any> => {
    return await instance.patch(`/hris/workflow_settings`, payload);
  },
  deleteWorkflow: async (oid?: string): Promise<any> => {
    return await instance.delete(`/hris/workflows/${oid}`);
  },
};
