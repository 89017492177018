import type { ReactNode } from 'react';
import { createContext, useState } from 'react';

export type TopnavUtilsContextType = {
  topnavOffset: number;
  setTopnavOffset: (value: number) => void;
};

export const TopnavUtilsContext = createContext<TopnavUtilsContextType>({
  topnavOffset: 64, // Default is always 64
  setTopnavOffset: (_value: number) => {},
});

export const TopnavUtilsProvider = ({ children }: { children: ReactNode }) => {
  const [topnavOffset, setTopnavOffset] = useState<number>(0);

  return (
    <TopnavUtilsContext.Provider value={{ topnavOffset, setTopnavOffset }}>{children}</TopnavUtilsContext.Provider>
  );
};
