import NativeSessionService from '@/native/NativeSession/NativeSessionService';
import { MuiBox } from '@letsdeel/ui';
import { type FunctionComponent, type Dispatch, type SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventActions } from '@/utils/analytics/constants';
import { useInitVerifyPin } from './hooks/useInitVerifyPin';
import AppPinInput from '@/native/AppPin/components/Input/AppPinInput';
import { addPinCodeDataLayer } from '@/native/AppPin/utils';

interface VerifyAppPinProps {
  allowedPinAttempts: number;
  remainingAttempts: number;
  setRemainingAttempts: Dispatch<SetStateAction<number>>;
  closeModal: (token: string | undefined) => void;
}

export const VerifyAppPin: FunctionComponent<VerifyAppPinProps> = ({
  allowedPinAttempts,
  remainingAttempts,
  closeModal,
  setRemainingAttempts,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<string[]>(Array(4).fill(''));
  const [hasError, setHasError] = useState(false);

  useInitVerifyPin(setInitialValues, remainingAttempts, closeModal);

  const handleOnAppPinInputSubmit = async (values: string[]) => {
    addPinCodeDataLayer(EventActions.IncorrectPinCode, {
      pin_attempts: (allowedPinAttempts - remainingAttempts).toString(),
    });
    setLoading(true);
    try {
      const token = await NativeSessionService.renewToken(values.join(''));
      closeModal(token);
    } catch (e) {
      console.error(e);
      setHasError(true);
      setRemainingAttempts(remainingAttempts - 1);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MuiBox marginTop={'16px'} alignItems={'center'}>
      <AppPinInput
        pinInputKey="verifyPin"
        justifyContent="center"
        initialValues={initialValues}
        isLoading={loading}
        hasError={hasError}
        setHasError={setHasError}
        errorMessage={
          remainingAttempts > 0 ? t('authentication.nativeAppPin.request.incorrect_attempt', { remainingAttempts }) : ''
        }
        onSubmit={handleOnAppPinInputSubmit}
      />
    </MuiBox>
  );
};
