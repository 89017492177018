import { ROUTES } from '@/constants/routesV2';
import { matchPath } from 'react-router-dom';

const openRoutes = [
  { path: ROUTES.login.main },
  { path: ROUTES.login.okta },
  { path: ROUTES.xeroSignup },
  { path: ROUTES.resetPasswordToken },
  { path: ROUTES.nativeSSO },
  { path: ROUTES.loginWithCode },
  { path: ROUTES.nativeOnboarding },
  { path: ROUTES.signUp.withToken },
  { path: ROUTES.createProfileToken },
  { path: ROUTES.createSecondaryProfile },
  { path: ROUTES.notContractMember },
  { path: ROUTES.paymentSummaryPublicLanding },
  { path: ROUTES.login.zendesk },
  { path: ROUTES.employeeVerificationLetter },
  { path: ROUTES.integrations.disconnected },
  { path: ROUTES.verifyBackupEmail },
  { path: ROUTES.create.account },
  { path: ROUTES.createProfile },
  { path: ROUTES.create.accountCompany },
  { path: ROUTES.create.accountIndividual },
  { path: ROUTES.createOrganization },
  { path: ROUTES.contractorType },
  { path: ROUTES.setup2FA.main },
  { path: ROUTES.setup2FA.initial },
  { path: ROUTES.setup2FA.add },
  { path: ROUTES.setup2FA.fallback },
  { path: ROUTES.externalClientSignature.main },
  { path: ROUTES.externalClientSignature.signing },
];

export function isPublicRoute(route: string) {
  return openRoutes.map((openRoute) => openRoute.path).some((openRoute) => matchPath(route, openRoute)?.isExact);
}
