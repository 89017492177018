import { Keyboard } from '@capacitor/keyboard';
import { isNative } from './crossPlatform';

/**
 * Hides the keyboard on native platforms.
 */
export const hideNativeKeyboard = () => {
  if (isNative()) {
    Keyboard.hide();
  }
};

/**
 * Shows the keyboard on native platforms.
 */
export const showNativeKeyboard = () => {
  if (isNative()) {
    Keyboard.show();
  }
};
