import type { ReactNode} from 'react';
import { createContext, useState, useEffect } from 'react';
import { allSeasons } from '@/constants/seasons';
import type { Seasons } from '@/constants/seasons/seasons';

export interface Season {
  name: Seasons;
  seasonStart: Date;
  seasonEnd: Date;
}

export interface ISeasonsContext {
  currentSeason: Seasons | undefined;
}

export const SeasonsContext = createContext<ISeasonsContext>({ currentSeason: undefined });

export const SeasonsProvider = ({ children }: { children: ReactNode }) => {
  const [season, setSeason] = useState<Seasons | undefined>(undefined);

  useEffect(() => {
    const today = new Date();
    for (const season of allSeasons) {
      if (today >= season.seasonStart && today <= season.seasonEnd) {
        setSeason(season.name);
        return;
      }
    }
  }, []);

  return (
    <SeasonsContext.Provider
      value={{
        currentSeason: season,
      }}
    >
      {children}
    </SeasonsContext.Provider>
  );
};
