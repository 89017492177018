import type {
  GLColumn,
  GeneralLedgerTemplateWizardValues,
  GeneralLedgerReportDimensionMapping,
  CONSOLIDATION_SETTING_KEYS,
  FILE_GENERATION_FORMAT_KEYS,
  POSTING_DATE_KEYS,
  POSTING_DATE_FORMAT_KEYS,
  NUMBER_SEPARATOR_KEYS,
  POSTING_DATE_SEPARATOR_KEYS,
} from '@/types/GlobalPayroll/GeneralLedger';
import instance from '@/utils/api/instance';
import { getUrlWithParams } from '@/utils/apiHelpers';

type ValidationDataParams = {
  additionalSettings: {
    fileGenerationFormat: FILE_GENERATION_FORMAT_KEYS;
  };
  consolidationSettings: {
    consolidationSetting: CONSOLIDATION_SETTING_KEYS;
  };
  columns: GLColumn[];
};

export type GetSampleGLTemplateRequest = {
  legalEntityId: string;
  formData: ValidationDataParams;
};

export type ValidateGLTemplateRequest = {
  fileKey?: string;
  currentRows?: Record<string, string>[];
} & GetSampleGLTemplateRequest;

export type ValidateResultMapping = Pick<
  GeneralLedgerReportDimensionMapping,
  | 'creditAccountId'
  | 'debitAccountId'
  | 'payrollReportColumnId'
  | 'creditSpendCategory'
  | 'debitSpendCategory'
  | 'neededForGL'
>;

export type ValidateResultRow = {
  id?: string;
  mappings: ValidateResultMapping;
  dimensions: Record<string, string>;
  metadata: {
    includeDebitAccount: boolean;
    includeCreditAccount: boolean;
  };
};

export type DimensionMissingMappings = {
  dimension: string;
  missingMappings: {
    g2nItem: string;
    missingOptions: string[];
  }[];
};

export type ValidateGLTemplateResponse = {
  isValid: boolean;
  g2nItemsWithoutAccountAssignment?: string[];
  dimensionsMissingMapping?: DimensionMissingMappings[];
  warnings?: string[];
  g2nItemsIgnored?: string[];
  errors?: string[];
  rows: ValidateResultRow[];
  originalRows: Record<string, string>[];
};

export type CreateGLTemplateRequest = {
  mappingRows: Record<string, string>[];
  legalEntityId: string;
  formData: GeneralLedgerTemplateWizardValues;
};

export type CreateTemplateData = {
  consolidationSetting: CONSOLIDATION_SETTING_KEYS;
  fileGenerationFormat: FILE_GENERATION_FORMAT_KEYS;
  columns: Omit<GLColumn, 'id'>[];
};

export type UpdateGLColumn = Omit<GLColumn, 'id'> & { itemOrder: number; id?: string };

export const convertGLColumnsToPayload = (columns: GLColumn[], keepId = false): UpdateGLColumn[] => {
  return columns.map(
    (
      {
        id,
        appearOnReport,
        name,
        type,
        value,
        dimensionType,
        customFieldId,
        runningNumberInterval,
        runningNumberPrefix,
        runningNumberStart,
      },
      i
    ) => ({
      appearOnReport,
      name,
      type,
      value,
      dimensionType,
      customFieldId,
      itemOrder: i,
      runningNumberInterval,
      runningNumberPrefix,
      runningNumberStart,
      ...(id && keepId ? { id } : {}),
    })
  );
};

export const buildValidationData = (
  validationData: ValidationDataParams
): {
  consolidationSetting: CONSOLIDATION_SETTING_KEYS;
  fileGenerationFormat: FILE_GENERATION_FORMAT_KEYS;
  columns: Omit<GLColumn, 'id'>[];
} => ({
  fileGenerationFormat: validationData.additionalSettings.fileGenerationFormat,
  consolidationSetting: validationData.consolidationSettings.consolidationSetting,
  columns: convertGLColumnsToPayload(validationData.columns),
});

export const buildCreationData = (
  creationData: GeneralLedgerTemplateWizardValues
): {
  name: string;
  category: string;
  postingDate: POSTING_DATE_KEYS;
  postingDateFormat: POSTING_DATE_FORMAT_KEYS;
  postingDateSeparator: POSTING_DATE_SEPARATOR_KEYS;
  numberSeparator: NUMBER_SEPARATOR_KEYS;
  consolidationSetting: CONSOLIDATION_SETTING_KEYS;
  fileGenerationFormat: FILE_GENERATION_FORMAT_KEYS;
  columns: Omit<GLColumn, 'id'>[];
} => ({
  ...buildValidationData(creationData),
  name: creationData.details.name,
  category: creationData.details.category,
  postingDate: creationData.additionalSettings.postingDate,
  postingDateFormat: creationData.additionalSettings.postingDateFormat,
  postingDateSeparator: creationData.additionalSettings.postingDateSeparator,
  numberSeparator: creationData.additionalSettings.numberSeparator,
});

export const generalLedgerEndpoints = {
  chartAccounts: {
    list: '/employment/global-ledger-chart-account/list',
    accountTypes: '/employment/global-ledger-chart-account/account-types',
    countPerEntity: '/employment/global-ledger-chart-account/count-per-legal-entity',
  },
  template: {
    base: '/global_payroll/general_ledger/template/base_template',
    list: '/global_payroll/general_ledger/template',
    availablePayrollColumns: '/global_payroll/general_ledger/template/available_payroll_columns',
  },
  fixedColumns: '/employment/global-ledger/fixed-columns',
};

export default {
  getSampleGLTemplate: async ({ legalEntityId, formData: validationData }: GetSampleGLTemplateRequest) => {
    return instance.post(`/global_payroll/general_ledger/template/generate_sample`, {
      legalEntityId,
      validationData: buildValidationData(validationData),
    });
  },

  generateErrorCSV: async ({
    legalEntityId,
    formData: validationData,
    fileKey,
    currentRows,
  }: ValidateGLTemplateRequest) => {
    return instance.post(`/global_payroll/general_ledger/template/generate_error_csv`, {
      fileKey,
      legalEntityId,
      validationData: buildValidationData(validationData),
      currentRows,
    });
  },

  validateTemplate: async (
    { legalEntityId, formData: validationData, fileKey, currentRows }: ValidateGLTemplateRequest,
    { suppressSnackbar }: { suppressSnackbar?: boolean } = {}
  ) => {
    return instance.post<ValidateGLTemplateResponse>(
      `/global_payroll/general_ledger/template/validate`,
      {
        fileKey,
        legalEntityId,
        validationData: buildValidationData(validationData),
        currentRows,
      },
      {
        suppressSnackbarForStatuses: [406],
        suppressSnackbar,
      }
    );
  },

  createGLTemplate: async ({ legalEntityId, formData: creationData, mappingRows }: CreateGLTemplateRequest) => {
    return instance.post(
      `/global_payroll/general_ledger/template/create`,
      {
        mappingRows,
        legalEntityId,
        creationData: buildCreationData(creationData),
      },
      { suppressSnackbar: true }
    );
  },

  updateGLTemplate: async ({
    templateId,
    mappingRows,
    templateData,
  }: {
    templateId: string;
    mappingRows: Record<string, string>[];
    templateData: {
      name?: string;
      columns?: UpdateGLColumn[];
      postingDateFormat?: POSTING_DATE_FORMAT_KEYS;
      postingDateSeparator?: POSTING_DATE_SEPARATOR_KEYS;
    };
  }) => {
    return instance.put(`/global_payroll/general_ledger/template/${templateId}`, {
      mappingRows,
      templateData,
    });
  },

  getGlTemplates: async ({ legalEntityId }: { legalEntityId: number }) => {
    return instance.get(`/global_payroll/general_ledger/template`, {
      params: {
        legalEntityId,
      },
    });
  },

  deleteGlTemplate: async ({ templateId }: { templateId: string }) => {
    return instance.delete(`/global_payroll/general_ledger/template/${templateId}`);
  },

  validateTemplateById: async ({ templateId, fileKey }: { templateId: string; fileKey: string }) => {
    return instance.post<ValidateGLTemplateResponse>(
      `/global_payroll/general_ledger/template/${templateId}/validate`,
      {
        fileKey,
      },
      {
        suppressSnackbarForStatuses: [406],
      }
    );
  },

  insertMappingsToTemplateById: async ({ templateId, fileKey }: { templateId: string; fileKey: string }) => {
    return instance.post<{
      rows: unknown[];
    }>(`/global_payroll/general_ledger/template/${templateId}/insert_template_mappings`, {
      fileKey,
    });
  },

  generateErrorCSVById: async ({ templateId, fileKey }: { templateId: string; fileKey: string }) => {
    return instance.post(`/global_payroll/general_ledger/template/${templateId}/generate_error_csv`, {
      fileKey,
    });
  },

  getSampleGLTemplateById: async ({
    templateId,
    payrollReportColumnIdsMissing,
  }: {
    templateId: string;
    payrollReportColumnIdsMissing: string[];
  }) => {
    return instance.post(`/global_payroll/general_ledger/template/${templateId}/generate_sample`, {
      payrollReportColumnIdsMissing,
    });
  },

  upsertItemsToTemplateById: async ({
    templateId,
    items,
  }: {
    templateId: string;
    items: {
      [key: string]: string | undefined;
    }[];
  }) => {
    return instance.post(
      `/global_payroll/general_ledger/template/${templateId}/upsert_mappings`,
      {
        items,
      },
      {
        suppressSnackbarForStatuses: [406],
      }
    );
  },

  deleteItemsFromTemplateById: async ({ templateId, mappingIds }: { templateId: string; mappingIds: string[] }) => {
    return instance.post(`/global_payroll/general_ledger/template/${templateId}/delete_mappings`, {
      mappingIds,
    });
  },

  generateGeneralLedgerReport: async ({
    templateId,
    payrollEventIds,
  }: {
    templateId: string;
    payrollEventIds: string[];
  }) => {
    return instance.post(
      `/global_payroll/general_ledger/report/generate`,
      {
        reportTemplateId: templateId,
        payrollEventIds,
      },
      { suppressSnackbar: true }
    );
  },

  getFilledTemplateCSV: async ({
    templateId,
    payrollReportColumnIdsMissing,
  }: {
    templateId: string;
    payrollReportColumnIdsMissing?: string[];
  }) => {
    return instance.get(`/global_payroll/general_ledger/template/${templateId}/generate_filled_csv`, {
      params: { payrollReportColumnIdsMissing },
    });
  },

  getAuditFileCSV: async ({ templateId, payrollEventIds }: { templateId: string; payrollEventIds: string[] }) => {
    return instance.get(
      getUrlWithParams(`/global_payroll/general_ledger/report/audit_file`, {
        reportTemplateId: templateId,
        payrollEventIds,
      })
    );
  },
};
