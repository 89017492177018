import { API_URL } from '../constants/main';
import filesApi from '@/utils/api/files';
import { isNative } from '@/utils/crossPlatform';
import { downloadFile } from './nativeFileSystem';
import { Browser } from '@capacitor/browser';
type DownloadParam = 'true' | '2';

export const toDownload = (url: string, downloadParam: DownloadParam = '2') => {
  const symbol = url.includes('?') ? '&' : '?';
  const u = url.startsWith('/') ? url.substring(1) : url;
  return `${API_URL}${API_URL.endsWith('/') ? '' : '/'}${u}${symbol}download=${downloadParam}`;
};

export const appendApiUrl = (url: string) => {
  const u = url.startsWith('/') ? url.substring(1) : url;
  return encodeURI(`${API_URL}${API_URL.endsWith('/') ? '' : '/'}${u}`);
};

export const downloadFileFromLocation = async (url: string, newTab?: boolean) => {
  const res = await filesApi.getFileLocation(url);
  if (isNative()) {
    Browser.open({ url: res, presentationStyle: 'popover' });
  } else {
    downloadFileFromUrl(res, newTab);
  }
};

export const downloadFileFromUrl = async (url: string, newTab: boolean = true) => {
  if (url) {
    if (newTab) {
      window.open(url, '_blank');
      return;
    }
    window.location.assign(url);
  }
};

export interface fileData {
  name: string;
  type: string;
}

export const prepareBlobUrl = (url: string): string => url.replace('download=2', '').replace('urlOnly=true', '');

export const downloadBlobFile = async (url: string, { name, type }: fileData, postBody?: Record<string, unknown>) => {
  const blob = postBody
    ? await filesApi.getFileUsingPostRequest(url, postBody)
    : await filesApi.getFileBlob(prepareBlobUrl(url));

  const data = new Blob([blob], { type });
  if (!isNative()) {
    const fileUrl = window.URL.createObjectURL(data);

    const tempLink = document.createElement('a');
    tempLink.href = fileUrl;
    tempLink.setAttribute('download', name);
    tempLink.click();
  } else {
    downloadFile(data, name, type);
  }
};

export const getBlobFile = async (
  url: string,
  options?: { type?: string; onProgress?: (percentCompleted?: number) => void }
) => {
  const { type = 'application/pdf', onProgress } = options || {};
  const blob = await filesApi.getFileBlob(prepareBlobUrl(url), onProgress);
  return new Blob([blob], { type });
};

export const openBlobFile = async (url: string, options: { newTab: boolean; isMobile: boolean; name: string }) => {
  const fileUrl = window.URL.createObjectURL(await getBlobFile(url));
  const tempLink = document.createElement('a');
  tempLink.href = fileUrl;

  if (options.isMobile) {
    tempLink.setAttribute('download', options.name);
  }

  if (options.newTab) {
    tempLink.setAttribute('target', '_blank');
  }

  tempLink.click();
};
