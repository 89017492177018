export const topNavMaxWidth = 1520;

export const topNavHeight = 122;
export const topNavFirstLayerHeight = 65;
export const topNavSecondLayerHeight = 56;
export const topNavMobileSecondLayerHeight = 49;

export const topNavSwitchMobileMarginTop = 66;
export const topnavSwitchMobilePaddingTop = 57;
export const topnavSwitchPaddingTop = 49;

export const topnavSwitchOverheadMobile = topNavFirstLayerHeight + topnavSwitchMobilePaddingTop;
export const topnavSwitchOverhead = topNavFirstLayerHeight + topnavSwitchPaddingTop;

export const onboardingSwitchTabletStart = '767px';
export const onboardingSwitchTabletEnd = '600px';

export const gapForGetStartedButton = 20;

export enum VariationType {
  avatar = 'Avatar',
  dropdown = 'Dropdown',
}
