import { Avatar, getAvatarProps, P, VStack } from '@letsdeel/ui';
import { useLoggedInUserInfo } from './hooks/useLoggedInUserInfo';

export const UserDetails = () => {
  const userInfo = useLoggedInUserInfo();

  if (!userInfo) return null;

  return (
    <VStack alignItems={'center'} gap={'8px'} alignSelf={'stretch'} marginTop={'12px'}>
      {userInfo.picUrl || userInfo.name ? (
        <Avatar {...getAvatarProps(userInfo.name, userInfo?.picUrl ?? undefined)} />
      ) : null}
      {userInfo.email ? (
        <P
          textAlign={'center'}
          fontSize={'14px'}
          fontStyle={'normal'}
          fontWeight={400}
          fontFamily={'Inter'}
          lineHeight={'24px'}
          letterSpacing={'0.07px'}
        >
          {userInfo.email}
        </P>
      ) : null}
    </VStack>
  );
};
