export enum FileFormats {
  CSV = 'csv',
  PDF = 'pdf',
  XLSX = 'xlsx',
}

export const maxRowsPerFormat: {
  [key: string]: number;
} = {
  csv: 45000,
  xlsx: 45000,
  pdf: 7000,
};

export const PDF_DOWNLOAD_MAX_ATTEMPTS = 120;
export const RECORDS_LIMIT = 100;
export const MAX_PDF_COLUMNS = 8;
