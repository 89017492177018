import { LS_ORPHAN_WARNING_SHOWN } from '@/scenes/OrganizationChart/components/OrganizationChartTree/constants';
import { LS_ESCALATION_BANNER_VISIBILITY } from '@/utils/escalation';
import { LS_VISA_IMMIGRATION_CSAT_SHOWN } from '@/components/CustomerSatisfaction/constants';
import { LS_DEEL_AI_SEARCH_CONVERSATION_KEY } from '@/components/Sidebar/components/SearchButton/components/DeelAI/constants';
import { LS_CUSTOM_NOTIFICATIONS_KEY } from '@/hooks/notifications/useCustomNotifications';
import { LS_WORKFORCE_SIZE_SKIPPED_KEY } from '@/components/WorkforceSizeReminderModal/WorkforceSizeReminderModal';
import { LS_INTEREST_SURVEY_SKIPPED_KEY } from '@/hooks/home/useOrganizationInterestSurvey';

export const LS_CLEAR_ON_LOGOUT_KEYS: string[] = [
  LS_ESCALATION_BANNER_VISIBILITY,
  LS_ORPHAN_WARNING_SHOWN,
  LS_VISA_IMMIGRATION_CSAT_SHOWN,
  LS_DEEL_AI_SEARCH_CONVERSATION_KEY,
  LS_CUSTOM_NOTIFICATIONS_KEY,
  LS_WORKFORCE_SIZE_SKIPPED_KEY,
  LS_INTEREST_SURVEY_SKIPPED_KEY,
];
