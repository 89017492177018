import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { FeedbackPopupContent, Link, Modal, P, type ModalRef } from '@letsdeel/ui';
import dataLayer from '@/utils/dataLayer';
import { ClientDeleteAccountEvents } from '@/scenes/Settings/components/DeleteContractorAccountPopup/deleteAccountConstants';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

export type LoginModalsRefs = {
  openLoginAgainModal: () => void;
  openShowFeedbackModal: () => void;
};
export const LoginModals = forwardRef<LoginModalsRefs>((_, ref) => {
  const loginAgainModalRef = useRef<ModalRef>(null);
  const showFeedbackModalRef = useRef<ModalRef>(null);
  const showTempLockedRef = useRef<ModalRef>(null);
  const [lockoutTime, setLockoutTime] = useState(0);

  const history = useHistory();

  useImperativeHandle(ref, () => ({
    openLoginAgainModal: () => loginAgainModalRef.current?.open(),
    openShowFeedbackModal: () => showFeedbackModalRef.current?.open(),
    openTempLockedModal: (lockoutTime: number) => {
      setLockoutTime(Math.ceil(lockoutTime / 60));
      showTempLockedRef.current?.open();
    },
  }));
  return (
    <>
      <Modal ref={loginAgainModalRef} closeButton={false}>
        <FeedbackPopupContent
          title={i18n.t('temp.platform-fe.scenes.Login.69fccfa7f8').toString()}
          text={i18n.t('temp.platform-fe.scenes.Login.5e32b28b7b').toString()}
          variant="error"
          button={{
            children: 'OK',
            sx: { width: '100px' },
            onClick: () => {
              loginAgainModalRef.current?.close();
            },
          }}
        />
      </Modal>
      <Modal
        ref={showFeedbackModalRef}
        closeButton={false}
        modalProps={{
          TransitionProps: {
            onExited: () => {
              history.replace({
                search: '',
              });
            },
          },
        }}
      >
        <FeedbackPopupContent
          title={i18n.t('temp.platform-fe.scenes.Login.431c174fb3').toString()}
          text={i18n.t('temp.platform-fe.scenes.Login.0351d71eeb').toString()}
          variant="info"
          button={{
            children: 'OK',
            onClick: () => {
              dataLayer.push({
                event_category: ClientDeleteAccountEvents.EVENT_CATEGORY,
                event_product: ClientDeleteAccountEvents.EVENT_PRODUCT,
                event_action: ClientDeleteAccountEvents.DELETE_ACCOUNT_SUCCESS,
              });
              showFeedbackModalRef.current?.close();
              history.replace({
                search: '',
              });
            },
          }}
        />
      </Modal>

      <Modal ref={showTempLockedRef} closeButton={false}>
        <FeedbackPopupContent
          title={i18n.t('temp.platform-fe.scenes.Login.eac9604506').toString()}
          text={
            lockoutTime ? (
              <P>
                <Trans
                  i18nKey="temp.platform-fe.scenes.Login.6f5621ea7a"
                  components={[
                    <Link
                      sx={{ display: 'inline' }}
                      target="_blank"
                      newTab
                      href="https://help.letsdeel.com/hc/en-gb/articles/21231762160529-How-To-Troubleshoot-Issues-With-Two-Factor-Authentication-2FA"
                      key="4feba18777_0"
                    />,
                  ]}
                  values={{ v0: lockoutTime, v1: lockoutTime === 1 ? '' : 's' }}
                />
              </P>
            ) : (
              i18n.t('temp.platform-fe.scenes.Login.62c579e7e2').toString()
            )
          }
          variant="error"
          button={{
            children: 'OK',
            sx: { width: '100px' },
            onClick: () => {
              loginAgainModalRef.current?.close();
            },
          }}
        />
      </Modal>
    </>
  );
});
